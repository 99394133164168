import { createSelector } from "reselect";
import _ from "lodash";
import { getFromLS } from "/src/lib/utils/helperMethods";
// import lodash from "lodash";

const empty = {}
const defaultEmptyArray = [];
const defaultZero = 0;
const defaultSpeechToTextLanguage = getFromLS("speechToTextLanguage") || 'en-US';

const activeSegmentIdState = (state, props) => {
  return state.segments.get("activeSegmentId", null);
}

export const activeSegmentIdSelector = () =>
  createSelector([activeSegmentIdState], (segmentId) => segmentId);

const activeSegmentState = (state, props) => {
  const activeSegmentId = activeSegmentIdState(state)
  if (!activeSegmentId) {
    return empty
  }
  return state.segments.get("segments", []).find((item) => item.id === parseInt(activeSegmentId)) || empty
}

export const activeSegmentSelector = () =>
  createSelector([activeSegmentState], (activeSegment) => activeSegment);

const segmentsState = (state) => {
  return state.segments.get("segments", []);
};

const aiSegmentsState = (state) => {
  return state.aisegments.get("segments", defaultEmptyArray);
};

export const getSegmentByIdSelector = (segmentId) =>
  createSelector([segmentsState], (segments) => {
    console.log("getSegmentByIdSelector ====>", segmentId);
    const currentSegment = segments.find((item) => item.id === parseInt(segmentId)) || {}
    return currentSegment
  });

const propsParentId = (state, props = {}) => {
  console.log("line number 41 parent id==>", props)
  return props.parentId
}
const propsNestingLevel = (state, props = {}) => {
  return props.nestingLevel
}

const getNestedSegments = (finalSegments, parentId, allDescendants) => {
  let nestedSegments = [];
  finalSegments.forEach(item => {
    if (item.parent_id === parentId) {
      nestedSegments.push(item);
      if (allDescendants) {
        nestedSegments = nestedSegments.concat(getNestedSegments(finalSegments, item.id));
      }
    }
  });
  return nestedSegments;
};

export const segmentsSelector = (parent_id, allDescendants) => 
  createSelector([segmentsState, propsParentId], (segments, parentId) => {
    
    let finalSegments = []
    try {
      finalSegments = segments.toJS();
    } catch (error) {
      finalSegments = segments;
    }

    if (parent_id || parentId) {
      if (allDescendants) {
        finalSegments = getNestedSegments(finalSegments, parent_id || parentId, allDescendants);
      } else {
        finalSegments = finalSegments.filter((item) => item.parent_id === parent_id || item.parent_id === parentId) 
      }
    }
    
    console.log("finalSegments ==>", parentId, parent_id, finalSegments, segments)
    // finalSegments = _.orderBy(finalSegments, ["position"], ["asc"])
    // NOTE: @SHIKHAR Added this to handle same position for segments having different parent_id
    // NOTE: Ordering by parent_id does not guarantee topics ordered, but helps to group all segments having same parent_id
    finalSegments = _.orderBy(finalSegments, ["parent_id", "position"], ["asc", "asc"])  
    return finalSegments
  });

const librarySegmentsState = (state) => {
  return state.librarysegments.get("segments", []);
};

export const librarySegmentsSelector = () => 
createSelector([librarySegmentsState, propsParentId, propsNestingLevel], (segments, parentId, nestingLevel) => {
  let finalSegments = []
  try {
    finalSegments = segments.toJS();
  } catch (error) {
    finalSegments = segments;
  }

  if(nestingLevel === 1){
    finalSegments = finalSegments.filter((item) => item.nesting_level === parseInt(nestingLevel))
  }
  if (parentId) {
    finalSegments = finalSegments.filter((item) => item.parent_id === parseInt(parentId))
  }
  
  console.log("librarySegmentsSelector finalSegments ==>", parentId, finalSegments, nestingLevel)
  finalSegments = _.orderBy(finalSegments, ["position"], ["asc"])
  return finalSegments
});

// TODO: Improve selector when APL-5649 done
export const aiSegmentsSelector = (parent_id) => 
  createSelector([aiSegmentsState, propsParentId, propsNestingLevel], (segments, parentId, nestingLevel) => {
    let finalSegments = []
    try {
      finalSegments = segments.toJS();
    } catch (error) {
      finalSegments = segments;
    }

    if (parent_id || parentId) {
      finalSegments = finalSegments.filter((item) => item.parent_id === parent_id || item.parent_id === parentId) 
    } else {
      finalSegments = finalSegments.filter((item) => item.parent_id === null)
      console.log("line number 107==>", parentId, parent_id, nestingLevel, finalSegments, segments)
    }
    
    console.log("line number 108==>", parentId, parent_id, nestingLevel, finalSegments, segments)
    // finalSegments = _.orderBy(finalSegments, ["position"], ["asc"])
    // NOTE: @SHIKHAR Added this to handle same position for segments having different parent_id
    // NOTE: Ordering by parent_id does not guarantee topics ordered, but helps to group all segments having same parent_id
    finalSegments = _.orderBy(finalSegments, ["parent_id", "position"], ["asc", "asc"])  
    return finalSegments
  });

  
// OLD
// export const segmentsSelector1 = () =>
//   createSelector([segmentsState, activeTopicIdState], (segments, activeTopic) => {
//     if(activeTopic){
//       console.log( "filtering topics 72" );
//       return segments.filter((item) => item.parent_id === parseInt(activeTopic));
//     }
//     console.log("segmentsSelector filtered segments ==>", segments)  
//     return segments;
//   });

const pinnedSegmentsState = (state, props) => {
  let pinnedSegments = state.segments.get("pinnedSegments");
  console.log("state pinned segments ==>", state)
  return pinnedSegments
}

export const pinnedSegmentsSelector = () => 
  createSelector([pinnedSegmentsState], (pinnedSegments) => pinnedSegments);

const isPinnedState = (state) => {
  return state.segments.get("isPinned", false)
}

export const isPinnedSelector = () => 
  createSelector([isPinnedState], (isPinned) => isPinned);

const segmentLayoutState = (state) => {
  return state.segments.get("segmentLayout", "list")
}

export const segmentLayoutSelector = () => 
  createSelector([segmentLayoutState], (segmentLayout) => segmentLayout);

const segmentModeState = (state) => {
  return state.segments.get("segmentMode", null)
}

export const segmentModeSelector = () => 
  createSelector([segmentModeState], (segmentMode) => segmentMode);

const activeMediaIdState = (state) => {
  return state.segments.get("activeMediaId", null)
}

export const activeMediaIdSelector = () => 
  createSelector([activeMediaIdState], (activeMediaId) => activeMediaId);

const duplicateLoadingState = (state, props) => {
  return state.segments.get("duplicateloading");
};
export const duplicateLoadingSelector = () =>
  createSelector([duplicateLoadingState], (loading) => loading);

const deleteLoadingState = (state, props) => {
  return state.segments.get("deleteloading");
};
export const deleteLoadingSelector = () =>
  createSelector([deleteLoadingState], (loading) => loading);

const activeLogsHistoryForSegmentIdState = (state, props) => {
  return state.segments.get("activeLogsHistoryForSegmentId", null);
};
export const activeLogsHistoryForSegmentIdSelector = () =>
  createSelector([activeLogsHistoryForSegmentIdState], (activeLogsHistoryForSegmentId) => activeLogsHistoryForSegmentId);

const activeSegmentParentIdxsState = (state, props) => {
  return state.segments.get("activeSegmentParentIdxs", [0]);
};
export const activeSegmentParentIdxsSelector = () =>
  createSelector([activeSegmentParentIdxsState], (activeSegmentParentIdxs) => activeSegmentParentIdxs);

const segmentsCreateLoadingState = (state, props) => {
  return state.segments.get("createloading", false);
};
export const segmentsCreateLoadingSelector = () =>
  createSelector([segmentsCreateLoadingState], (createloading) => createloading);

const editModeForSegmentIdState = (state) => {
  return state.segments.get("editModeForSegmentId", defaultZero)
}

export const editModeForSegmentIdSelector = () =>
  createSelector([editModeForSegmentIdState], (editModeForSegmentId) => editModeForSegmentId);

const showSegmentFormState = (state) => {
  return state.segments.get("showSegmentForm", defaultZero)
}

export const showSegmentFormSelector = () =>
  createSelector([showSegmentFormState], (showSegmentForm) => showSegmentForm);
const speechToTextLanguageState = (state) => {
  return state.segments.get("speechToTextLanguage", defaultSpeechToTextLanguage);
}

export const speechToTextLanguageSelector = () =>
  createSelector([speechToTextLanguageState], (speechToTextLanguage) => speechToTextLanguage);
