// const stringManipulator = (arr, splitter, operation) => {
//   array.forEach(currentItem => {});
//   arr.map((u, i) => {});
// };

import debounce from "lodash.debounce";
import queryString from "query-string";
import { realtimeDB } from "../../config/initializers";
import {countries} from './countries'
import mudder from "mudder";
import html2canvas from "html2canvas";
import * as Sentry from "@sentry/react";
// import { get, post, head } from "axios";
import _, { cloneDeep, toLower, forEach, isArray } from "lodash";
import axios from "axios";
import LogoImage from "../../assets/images/logo.png";
import IBLogoOSC from "../../assets/images/ib_logo_osc.svg";
import PamojaIcon from "../../assets/images/pamoja_icon.png";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import interval_worker from "./interval_worker.js";
import { message } from "/src/components/UI/AntdAppHelper";
import { parse } from 'best-effort-json-parser';
import { getAppVersion, getDeviceInfo, getLatestAppVersion, isNewAppVersion } from "/src/App/OfflineApp/offlineAppHelper";

export const getIpClient = async () => {
  try {
    const response = await axios.get("https://api.ipify.org?format=json");
    console.log('inside helper checking==>', response.data.ip)
    return response.data.ip;
  } catch (error) {
    console.error(error);
  }
};


export const encodeURLCustom = (url, exportType) => {
  let splitArr = url.split("/");
  let fileName = splitArr.pop();
  let finalURL = url;
  if (fileName && exportType !== "all_student_grades") {
    // all_student_grades excel url is from BE rails - already encoded
    finalURL = url.replace(fileName, "") + encodeURIComponent(fileName).toString();
  }
  console.log(
    "exportModalConfig.exportUrl",
    encodeURIComponent(url),
    finalURL
  );
  return finalURL;
}


export const getPrintOptionTitle = (key, mrIntl) => {
  const printOptionsTitle = {
    with_questions: mrIntl("ExportModal.questions"),
    with_answer_explanation: mrIntl("ExportModal.teachers_explanation"),
    with_student_responses: mrIntl("ExportModal.student_responses"),
    with_space_for_answers: mrIntl("ExportModal.space_for_student_answers"),
    with_markscheme: mrIntl("ExportModal.markscheme"),
    with_tsc: mrIntl("CommonText.task_specific_clarification"),
    with_print_copies_for_each_student: mrIntl("ExportModal.print_copies_respective_to_each_student")
  }
  return printOptionsTitle[key]
}

export const getFormattedPrintOptionsTitle = (arr) => {
  const arrLength = arr.length
  if (arrLength === 0) {
    return "";
  } else if (arrLength === 1) {
    return arr[0];
  } else {
    const lastOption = arr.pop();
    return arr.join(", ") + " and " + lastOption;
  }
}

export const experienceNameWithAccessCode = (experience) => {
  return `${experience.name} (Join Code: ${experience.access_code})`
}

export const returnQueryString = (obj) => {
  let str = [];
  for (let p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
};

export const timeInMiliSeconds = (focusLostTime) => { // Rename function name
  var date = new Date(focusLostTime);
  var hour = date.getUTCHours();
  var min = date.getUTCMinutes();
  var sec = date.getUTCSeconds();
  var formatedHour = hour > 9 ? hour : "0" + hour;
  var formatedMinute = min > 9 ? min : "0" + min;
  var formatedSecond = sec > 9 ? sec : "0" + sec;
  var timeStamps;
  if (hour > 0) {
    timeStamps =
      hour > 1
        ? formatedHour + ":" + formatedMinute + ":" + formatedSecond + " hours"
        : formatedHour + ":" + formatedMinute + ":" + formatedSecond + " hour";
  } else if (min > 0) {
    timeStamps =
      min > 1
        ? formatedMinute + ":" + formatedSecond + " minutes"
        : formatedMinute + ":" + formatedSecond + " minute";
  } else {
    timeStamps =
      sec > 1 ? formatedSecond + " seconds" : formatedSecond + " second";
  }
  return timeStamps;
};
    
// export const customDebounce = function (a, b, c) {
//   var d, e;
//   return function () {
//     function h() {
//       (d = null), c || (e = a.apply(f, g));
//     }
//     var f = this,
//       g = arguments;
//     return (
//       clearTimeout(d), (d = setTimeout(h, b)), c && !d && (e = a.apply(f, g)), e
//     );
//   };
// };

export const debounceEventHandler = (...args) => {
  const debounced = debounce(...args);
  return function (e) {
    e.persist();
    return debounced(e);
  };
};

export const setQueryParams = (options) => {
  let { url, history, location, newParams, removeParams } = options;
  let updatedNewParams = {};
  for (const key in newParams) {
    if (newParams.hasOwnProperty(key)) {
      const element = newParams[key];
      if (newParams[key] === null || newParams[key] === "null") {
        if (!removeParams) {
          removeParams = [];
        }
        removeParams.push(key);
      } else {
        updatedNewParams[key] = element;
      }
    }
  }

  // const queryParams = {
  //   ...queryString.parse(location.search),
  //   ...newParams
  // };
  const queryParams = {
    ...queryString.parse(location.search),
    ...updatedNewParams,
  };

  if (removeParams) {
    removeParams.forEach((element) => {
      if (queryParams.hasOwnProperty(element)) {
        delete queryParams[element];
      }
    });
  }
  // let { queryParamsWithRemovedParams, ...removeParams } = queryParams;

  // let finalQueryParams = queryString.stringify(queryParamsWithRemovedParams);
  let finalQueryParams = queryString.stringify(queryParams);
  console.log("url", url);
  history.push(`${url}?${finalQueryParams}`);
  // history.push({ pathname: url, search: `?${finalQueryParams}` });
  console.log("finalQueryParams", finalQueryParams);
};
export const returnFinalValue = (event, controlName) => {
  let finalValue;
  if (controlName === "object") {
    finalValue = event;
  } else if (typeof event == "string") {
    finalValue = event;
  } else if (Array.isArray(event)) {
    finalValue = event;
  } else {
    if (event.hasOwnProperty("target")) {
      finalValue = event.target.value;
    } else {
      finalValue = event;
    }
  }
  return finalValue;
};

/*TODO Currently copy Image to CLipboard is not supported in Mac-Os or ExecCommand is deprecated */ 

// function getBlobFromImageSource(imageSource) {
//   return new Promise(function (resolve, reject) {
//     try {
//       var xhr = new XMLHttpRequest();
//       xhr.open("GET", imageSource);
//       xhr.responseType = "blob";
//       xhr.onerror = function () {
//         reject("Network error.");
//       };
//       xhr.onload = function () {
//         if (xhr.status === 200) {
//           resolve(xhr.response);
//         } else {
//           reject("Loading error:" + xhr.statusText);
//         }
//       };
//       xhr.send();
//     } catch (err) {
//       reject(err.message);
//     }
//   });
// }


export const getCanvasSizeObj = (e, handleScroll = false) => {
  var e_width = e.offsetWidth + 15;
  var e_height = e.offsetHeight + 15;
  // x and y values are cropping the text of image, that's why the text is not appearing inside the image in Latest version That's why we gave  x and y to 0.
  var e_x_offset = handleScroll ? window.scrollX : 0;
  var e_y_offset = handleScroll ? window.scrollY : 0;
  let sizeObj = {
    scale: 1,
    width: e_width,
    height: e_height,
    x: e_x_offset,
    y: e_y_offset,
    allowTaint: true,
    useCORS: true,
  };
  return sizeObj;
};

export const uploadImageAndGetUrl = async (src) => {
  
  if (!src) {
    return null;
  }

  const token = localStorage.getItem("token");
  const config = {
    headers: {
      "content-type": "multipart/form-data",
      token: token,
    },
  };
  const url = (import.meta.env.VITE_API_URL || "/backend/api/v1/") + "attachments";

  const formData = new FormData();
  formData.append("url", src);

  try {
    const response = await axios.post(url, formData, config);
    return response.data?.url;
  } catch (error) {
    console.log("error in uploading attachment", error);
    throw error;
  }
}
export const getHtml2CanvasUrl = async ({
  element: providedElement,
  className: captureClassName,
}) => {
  try {
    const element = captureClassName
      ? document.querySelector(`.${captureClassName}`)
      : providedElement;

    if (!element) {
      throw new Error("Element not found");
    }

    const canvasSizeObj = getCanvasSizeObj(element);
    const canvas = await html2canvas(element, canvasSizeObj);
    const imgData = canvas.toDataURL("image/png");
    const imageUrl = await uploadImageAndGetUrl(imgData);

    if (!imageUrl) {
      throw new Error("Failed to upload image and get URL.");
    }
    console.log("imageUrl check ==>", imageUrl);
    return imageUrl;

    // imageInsertFromUrl(imgData)
  } catch (error) {
    console.error("Error in getHtml2CanvasUrl:", error);
  }
};

// export const copyLinkWithoutPrmoise = async (calcSetting, graphingCalculator) => {
//   if (navigator.clipboard) {
//     if (calcSetting.type === "desmos_graphing_calc") {
//       const blob = await getBlobFromImageSource(
//         graphingCalculator.screenshot()
//       );
//       const clipboardItem = new window.ClipboardItem({
//         ["image/png"]: blob,
//       });
//       try {
//         await navigator.clipboard.write([clipboardItem]).then(()=>{
//           showCalcSuccessMsg()
//         });
//       } catch (err) {
//           showCalcErrorMsg()
//       }
//     } else {
//       var e = document.getElementsByClassName("dcg-basic-list")[0];

//       if (e) {
//         const canvasSizeObj = getCanvasSizeObj(e);
//         showCalcLoader(true);
//         html2canvas(e, canvasSizeObj).then(async (canvas) => {
//           canvas.toBlob(async function (blob) {
//             try {
//               const cbi = new window.ClipboardItem({
//                 ["image/png"]: blob,
//               });
//               await navigator.clipboard.write([cbi]).then(()=>{
//                 showCalcSuccessMsg()
//               });
//               showCalcLoader(false);
//             } catch (error) {
//               copyLinkWithExecCommand(calcSetting, graphingCalculator).catch((error) => {
//                 showCalcErrorMsg()
//               });
//             }
//           });
//         });
//       }
//     }
//   } else {
//     throw new Error("Something went wrong in copying from calculator.");
//   }
// };

// export const copyLinkWithPromise = async (calcSetting, graphingCalculator) => {
//   if (navigator.clipboard) {
//     navigator.clipboard
//       .write([
//         new window.ClipboardItem({
//           "image/png": new Promise(async (resolve) => {
//             if (calcSetting.type === "desmos_graphing_calc") {
//               const blob = getBlobFromImageSource(
//                 graphingCalculator.screenshot()
//               );
//               resolve(await blob);
//             } else {
//               var e = document.getElementsByClassName("dcg-basic-list")[0];

//               if (e) {
//                 const canvasSizeObj = getCanvasSizeObj(e);
//                 showCalcLoader(true);
//                 html2canvas(e, canvasSizeObj)
//                   .then(async (canvas) => {
//                     canvas.toBlob(async function (blob) {
//                       resolve(blob);
//                     });
//                     showCalcLoader(false);
//                   })
//                   .catch((error) => {
//                     showCalcErrorMsg()
//                     showCalcLoader(false);
//                   });
//               }
//             }
//           }),
//         }),
//       ]).then(()=>{
//         showCalcSuccessMsg()
//       })
//       .catch(function (e) {
//         throw e;
//       });
//   } else {
//     throw new Error("Something went wrong in copying from calculator.");
//   }
// };

// export const copyFromDomSelection = async (className, imgSrc) => {
//   document.getElementsByClassName("copyImage")[0].src = imgSrc;
//   var imageElem = document.getElementById("copy-image");
//   const selection = window.getSelection();
//   selection.removeAllRanges();
//   const range = await document.createRange();
//   await range.selectNodeContents(imageElem);
//   await selection.addRange(range);
//   try {
//     var successful = document.execCommand("copy");
//     window.getSelection().removeAllRanges();
//     if (successful) {
//      showCalcSuccessMsg()
//     } else {
//       throw new Error(
//         "Something went wrong in copying from calculator."
//       );
//     }
//   } catch (err) {
//     showCalcErrorMsg()
//     window.getSelection().removeAllRanges();
//   }
// };

// export const copyLinkWithExecCommand = async (calcSetting, graphingCalculator) => {
//   if (calcSetting.type === "desmos_graphing_calc") {
//     copyFromDomSelection(".calc-image", graphingCalculator.screenshot(), true);
//   } else {
//     showCalcLoader(true);
//     var e = document.getElementsByClassName("dcg-basic-list")[0];
//     if (e) {
//       const canvasSizeObj = getCanvasSizeObj(e);
//       html2canvas(e, canvasSizeObj).then((canvas) => {
//         copyFromDomSelection(".calcImage", canvas.toDataURL());
//         showCalcLoader(false);
//       });
//     } else {
//       showCalcLoader(false);
//       showCalcErrorMsg()
//     }
//   }
// };

// function showCalcSuccessMsg() {
//   message.success("Expression image Copied");
// }

// function showCalcErrorMsg(){
//   message.error("Something went wrong in copying from calculator.");
// }

// export const copyLink = async (calcSetting, graphingCalculator) => {
//   if (navigator.clipboard) {
//     copyLinkWithPromise(calcSetting, graphingCalculator).catch((error) => {
//         copyLinkWithoutPrmoise(calcSetting, graphingCalculator).catch((error) => {
//             copyLinkWithExecCommand(calcSetting, graphingCalculator).catch(
//               (error) => {
//                 showCalcErrorMsg()
//               }
//             );
//           });
//       });
//   } else {
//     copyLinkWithExecCommand(calcSetting, graphingCalculator).catch((error) => {
//       showCalcErrorMsg()
//     });
//   }
// };

export const getScreenshot = (e, callback) => {
  if (e) {
    const canvasSizeObj = getCanvasSizeObj(e, true);
    html2canvas(e, canvasSizeObj).then((canvas) => {
      callback(canvas.toDataURL())
    });
  }
}

export const getQueryParams = (options) => {
  let { location } = options;
  let finalQueryParams = queryString.parse(location.search);
  return finalQueryParams;
};

export const displayRole = (role) => {
  if (role === "superadmin") {
    return "Superadmin";
  } else if (role === "admin") {
    return "Admin";
  } else if (role === "teacher") {
    return "Teacher";
  } else if (role === "student") {
    return "Student";
  }
};

export const buildOptionsArr = (options) => {
  let optionsArr = [];
  options.map((value, index) => {
    optionsArr.push({ label: value, value: value });
  });
  // let optionsArr = [
  //   {
  //     label: "Yes",
  //     value: "Yes",
  //   },
  //   {
  //     label: "No",
  //     value: "No",
  //   },
  // ]
  return optionsArr;
};
export const getSessionId = (len = 4) => {
  return `${Date.now()}-${getUniqueId(len)}`
}
export const getUniqueId = (len = 16) => {
  let possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let objectId = "";
  let i = 0;
  while (i < len) {
    objectId += possible.charAt(Math.floor(Math.random() * possible.length));
    i++;
  }
  return objectId;
};

export const getActiveKeyFromPathname = (pathname) => {
  console.log("pathname", pathname, pathname.split("/").pop());
  return pathname.split("/").pop();
};

export const checkMob = () => {
  let check = false;

  if (import.meta.env.VITE_MODE == 'production' || true) {
    (function (a) {
      
      if (
        // /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|playbook|silk/i.test(
          a
        ) ||
        // eslint-disable-next-line
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    
    check = check || window.matchMedia('(max-width: 768px)').matches;
    // Added matchMedia because checkMob wsn't triggering inside iframe in MB.
  } else {
    if (window.matchMedia) {
      check = window.matchMedia('(max-width: 600px)').matches;
    }
  }
  console.log("checking mob", check)

  return check;
};

export const checkScreen = (d = 'mobile') => {
  let check = false
  let maxWidth = 600

  if (d === 'tab') {
    maxWidth = 900
  }

  if (window.matchMedia) {
    check = window.matchMedia(`(max-width: ${maxWidth}px)`).matches;
  }
  return check
}

export const checkIPAD = () => {
  console.log("typeof(window.navigator.standalone)===>",typeof(window.navigator.standalone))
  return /iPad/.test( navigator.userAgent ) || (/Macintosh/.test( navigator.userAgent ) && (typeof(window.navigator.standalone) !== "undefined") && "ontouchend" in document) || /AssessPrepIOS/.test( navigator.userAgent )
  // added AssessPrepIOS to detect ipad app - webview was not matching others

  // var standalone = window.navigator.standalone,
  //   userAgent = window.navigator.userAgent.toLowerCase(),
  //   safari = /safari/.test( userAgent ),
  //   ios = /iphone|ipod|ipad/.test( userAgent );

  //   if( ios ) {
        
  //       if ( !standalone && safari ) {
            
  //           document.getElementById( 'where-am-i' ).textContent = 'browser';
            
  //       } else if ( standalone && !safari ) {
            
  //           document.getElementById( 'where-am-i' ).textContent = 'standalone';
            
  //       } else if ( !standalone && !safari ) {
            
  //           document.getElementById( 'where-am-i' ).textContent = 'uiwebview';
            
  //       };
        
  //   } else {
        
  //       document.getElementById( 'where-am-i' ).textContent = 'not iOS';
        
  //   };
};

export const convertMs = (time) => {
  console.log("time==>", time);
  let ms = Number(time) * 60 * 1000;
  return ms;
};

export const getMinutes = (seconds) => {
  console.log("seconds==>", seconds);
  let minutes = parseInt(Number(seconds)/60);
  if(minutes < 10){
    minutes = "0" + minutes.toString()
  }
  return minutes
};
export const getSeconds = (seconds) => {
  console.log("seconds==>", seconds);
  let secs = parseInt(Number(seconds)%60);
  if(secs < 10){
    secs = "0" + secs.toString()
  }
  return secs
};
export const convertToHoursAndMinutes = (minutes = 0) => {
  if (minutes < 60) {
    return `${minutes}m`;
  }
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return remainingMinutes === 0 ? `${hours}h` : `${hours}h ${remainingMinutes}m`;
};

// eslint-disable-next-line 
// https is must to avoid file and chrome extension protocalls
// export const whitelistedUrls = "^https:\/\/((assessprep\.(com|cn))|(.*?\.assessprep\.(com|cn))|(.*?\.managebac\.(com|cn))|(.*?\.faria\.(org))|(.*?\.fariaedu\.(com))|(.*?\.vimeo\.com)|(vimeo\.com)|(.*?\.jit\.si)|(login\.(microsoftonline|live)\.com)|(.*?\.zoom\.*?)|(meet\.(google)\.(com|cn))|(apps\.(google)\.(com|cn))|(accounts\.(google|youtube)\.(co(m|(\.([a-z]{2}))))))(\/.*)?$"
// export const whitelistedUrls = "^https:\/\/((.*?\.assessprep\.(com|cn))|(.*?\.managebac\.(com|cn))|(.*?\.faria\.(org))|(.*?\.fariaedu\.(com))|(.*?\.vimeo\.com)|(vimeo\.com)|(.*?\.jit\.si)|(login\.(microsoftonline|live)\.com)|(accounts\.(google|youtube)\.(co(m|(\.([a-z]{2}))))))(\/.*)?$"

//(apps\.(google)\.(com|cn)) is for allow google account login page before initiate the first meet call 

export const extractDomain = (url) => {
  const domainAndPath = url.replace(/^(https?:\/\/)?(www\.)?/, '');
  const domain = domainAndPath.split('/')[0]
  return domain;
};

// export const getWhiteListUrls = (experienceSettings) => {
//   // Still getting used for the schools for which dynamic flag not enabled
//   const whitelistedUrlsRegex =
//     "^https://((assessprep.(com|cn))|(.*?.assessprep.(com|cn))|(.*?.managebac.(com|cn))|(.*?.faria.(org))|(.*?.fariaedu.(com))|(.*?.vimeo.com)|(vimeo.com)|(.*?.jit.si)|(login.(microsoftonline|live).com)|(.*?.zoom.*?)|(meet.(google).(com|cn))|(apps.(google).(com|cn))|(accounts.(google).(co(m|(.([a-z]{2}))))))(/.*)?$";

//   const lockdownConfig = experienceSettings.lockdown_config || {};
//   const finalWhitelistRegex =
//     lockdownConfig.whitelist_urls_regex || whitelistedUrlsRegex;

//   return finalWhitelistRegex;
// };


// export const getWhiteListUrls = (experienceSettings) => {
//   //pre defined whiteList sample
//   //const whitelistedUrls = "^https:\/\/((assessprep\.(com|cn))|(.*?\.assessprep\.(com|cn))|(.*?\.managebac\.(com|cn))|(.*?\.faria\.(org))|(.*?\.fariaedu\.(com))|(.*?\.vimeo\.com)|(vimeo\.com)|(.*?\.jit\.si)|(login\.(microsoftonline|live)\.com)|(.*?\.zoom\.*?)|(meet\.(google)\.(com|cn))|(apps\.(google)\.(com|cn))|(accounts\.(google|youtube)\.(co(m|(\.([a-z]{2}))))))(\/.*)?$"

//   const prefixHttpsRegex = "^https://(";
//   ///////////////////////////////////////////
//   ///// IMPORTANT: FOR ANY CHANGES IN THE BELOW REGEX, PLEASE UPDATE THE REGEX IN BACKEND AS WELL TO SUPPORT SEB 
//   ////////////////////////////////////////////
//   // const defaultRegex = [
//   //   "assessprep\\.(com|cn)",
//   //   ".*?\\.assessprep\\.(com|cn)",
//   //   ".*?\\.managebac\\.(com|cn)",
//   //   ".*?\\.faria\\.(org)",
//   //   ".*?\\.fariaedu\\.(com)",
//   //   ".*?\\.vimeo\\.com",
//   //   "vimeo\\.com",
//   //   ".*?\\.jit\\.si",
//   //   "login\\.(microsoftonline|live)\\.com",
//   //   ".*?\\.zoom.*?",
//   //   "meet\\.google\\.(com|cn)",
//   //   "apps\\.google\\.(com|cn)",
//   //   "accounts\\.(google|youtube)\\.(co(m|\\.([a-z]{2})))"
//   // ].join("|");

//   const defaultRegex = [
//     "assessprep\\.(com|cn)",
//     ".*?\\.assessprep\\.(com|cn)",
//     ".*?\\.managebac\\.(com|cn)",
//     ".*?\\.faria\\.(org)",
//     ".*?\\.fariaedu\\.(com)",
//     ".*?\\.jit\\.si",
//     "login\\.(microsoftonline|live)\\.com",
//     ".*?\\.zoom.*?",
//     "meet\\.google\\.(com|cn)",
//     "apps\\.google\\.(com|cn)",
//     "accounts\\.(google)\\.(co(m|\\.([a-z]{2})))"
//   ].join("|");

//   const postFixRegex = ")(/.*)?$";

//   const lockdownConfig = experienceSettings.lockdown_config || {};
//   const whitelistUrls = lockdownConfig.whitelist_urls || [];

//   const defaultWhitelistURLSRegex =
//     prefixHttpsRegex + defaultRegex + postFixRegex;

//   if (whitelistUrls.length === 0) {
//     return defaultWhitelistURLSRegex;
//   }

//   const domains = whitelistUrls.map((url) => extractDomain(url));

//   const whiteListUrlsRegex = domains
//     .map((domain) => {
//       const domainParts = domain.split(".");
//       // `domainParts.length > 2` indicates that the URL is a subdomain URL, such as `app.assessprep.com`.
//       // If it is a subdomain URL, we match it as is (`(${domain})`). Otherwise, we match it as a global URL using a wildcard (`(.*?\\.${domain})`).
//       return `(.*?.${domain})|(${domain})`; //domainParts.length > 2 ? `(.*?.${domain})` : `(${domain})`;
//     })
//     .join("|");

//   const finalRegex = `${prefixHttpsRegex}${defaultRegex}|${whiteListUrlsRegex}${postFixRegex}`
//    // prefixHttpsRegex + defaultRegex + "|" + whiteListUrlsRegex + postFixRegex;
   
//    console.log("finalRegex====>>>", finalRegex);
//   return finalRegex;
// };

export const removeHTMLTagsFromText = (text, onlyTags = false) => {
  if (text) {
    if (onlyTags) {
      return text.replace(/(<([^>]+)>|\n)/g, "");
    } else {
      return text
        .replace(/(<([^>]+)>)/gi, "")
        .replace(/[\r\s]+/g, "")
        .replace('&nbsp;', "")
        .toLowerCase();
    }
  } else {
    return "";
  }
};

// export const renderMathInElement = (element) => {
//   console.log("renderMathInElement", element)
//   window.renderMathInElement(element, [
//     { left: "$$", right: "$$", display: true },
//     { left: "\\(", right: "\\)", display: true },
//     { left: "\\[", right: "\\]", display: true }
//   ]);
// }

export const renderMathInElement = async (element, renderUsingWiris=true) => {
  console.log("renderMathInElementMathJax", element);

  let MathJax = window.MathJax
  if (element) {
    if (MathJax && MathJax.typeset) {
      setTimeout(() => {
        MathJax.typeset([element]);
      }, 10)
      // }, renderUsingWiris ? 2000 : 10)
    }
    // doing this to render Mathjax style only in case of MB
    if (renderUsingWiris) {
      let wirisViewer = window.com && window.com.wiris.js.JsPluginViewer
      if (wirisViewer) {
        wirisViewer.parseElement(element, true, function () { })
      }
    }
  }
};

export const userRoleMap = {
  "superadmin": "SuperAdmin",
  "support": "Support",
  "admin": "Admin",
  "qb_author": "Questionbank Author",
  "teacher": "Teacher",
  "parent": "Parent",
  "student": "Student",
}

export const getAlphabetAtIndex = (index) => {
  let alphabetArr =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("")
  return alphabetArr[index]
}

export const setToLS = (key, value) => {
  localStorage.setItem(key, value);
}

export const getFromLS = (key) => {
  return localStorage.getItem(key);
}
export const deleteFromLS = (key) => {
  return localStorage.removeItem(key);
}

export const maxCharacters = 50000
export const validateText = (text, rules = {}) => {
  const maxChars = rules.maxCharacters || maxCharacters
  const requiredMessage = rules.requiredMessage || 'Required'
  const maxCharsMessage = rules.maxCharsMessage || 'Limit reached. text will not be saved!'
  const validation = {
    success: false,
    messages: []
  }

  let hasRequiredError, hasLimitError
  if (rules.required && !text) {
    hasRequiredError = true
    validation.messages.push(requiredMessage)
  } else if (text && text.length >= maxChars) {
    hasLimitError = true
    validation.messages.push(maxCharsMessage)
  }


  if (!hasRequiredError && !hasLimitError) {
    validation.success = true
  }

  console.log('validation of text ==>', text, validation, hasRequiredError, hasLimitError)
  return validation
}

export const getServerTimeOffset = (callback) => {
  let appRegion = import.meta.env.VITE_REGION
  const token = localStorage.getItem("token");
  let config = {}
  if (appRegion === "china") {
    config.headers = {
      token: token, // for china rails
    }
  } else {
    config.headers = {
      'Authorization': token, // for prod go
    }
  }

  // On global, we use the Go BE for this, in china, we use the Rails BE
  const baseUrl = appRegion === "china" ? import.meta.env.VITE_API_URL : getAIServiceBaseURL()
  const serverTimeOffsetURL = baseUrl + "time_offset?client_time=" + Date.now();

  axios.get(serverTimeOffsetURL, config).then((response) => {
    console.log("getServerTimeOffset then", response);
    callback(response.data.offset)
  }).catch((e) => {
    console.log("getServerTimeOffset catch", e);
    Sentry.captureException(e);
    // callback(1000000)
    callback(0) // IMP: if any error in this request, then we are allowing test start
  })


  // if(realtimeDB && false){
  //   // // NOTE: serverTimeOffset only sets when we first create connection - so cycling through goOffline/goOnline to refresh serverTimeOffset
  //   // realtimeDB.goOffline()
  //   // realtimeDB.goOnline()
  //   let offsetRef = realtimeDB.ref(".info/serverTimeOffset");
  //   offsetRef.once("value", function (snap) {
  //     let offset = snap.val();
  //     let estimatedServerTimeMs = new Date().getTime() + offset;
      
  //     let date = new Date(estimatedServerTimeMs);
  //     let hours = date.getHours();
  //     let minutes = "0" + date.getMinutes();
  //     let seconds = "0" + date.getSeconds();
  //     let formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
  //     console.log("formattedTime", formattedTime);
  //     console.log("checkIfStartTime offset", new Date().getTime(), offset, estimatedServerTimeMs, formattedTime);

  //     if(callback){
  //       callback(offset)
  //     }
  //   });
  // } else {
  //   // for global and china now
  //   const token = localStorage.getItem("token");
  //   const config = {
  //     headers: {
  //       'Authorization': token,
  //     },
  //   };

  //   // const serverTimeOffsetURL = (import.meta.env.VITE_API_URL || "/backend/api/v1/") + "server_time_offset";
  //   const serverTimeOffsetURL = import.meta.env.VITE_AI_SERVICE_URL + "time_offset";
    
  //   // const data = {
  //   //   client_time: Date.now()
  //   // }

  //   axios.get(serverTimeOffsetURL + "?client_time=" + Date.now(), config).then((response) => {
  //     callback(response.data.offset)
  //   }).catch(() => {
  //     callback(0)
  //   })
  // }
  
}

export const checkOffsetInterval = (status = "on", callback) => {
  // console.log("checkOffsetInterval ==>", status)
  const intervalWorker = new Worker(interval_worker);
  intervalWorker.postMessage({ turn: status, interval: 1000 });
  
  let currentTime = Date.now()
  intervalWorker.onmessage = ({ data: { time } }) => {
    // console.log("interval time ==>", time);
    const offset = currentTime - Date.now()
    if (Math.abs(offset) > 120000) {
      // console.log("offset ==>", offset);
      // Note: setting offset greater than 2 minutes, as we are giving margin of error of 2 minutes in currentTimeValidWrtServerTime
      callback(offset)
    }
    currentTime = Date.now()
  };

  // let currentTime = Date.now()
  // return setInterval(() => {
  //   const offset = currentTime - Date.now()
  //   // Note: When tab is inactive then some browsers throttle intervals for 1 minute so we need to check offset of atleast 1 minute
  //   if (Math.abs(offset) > 120000) {
  //     console.log("offset ==>", offset);
  //     // Note: setting offset greater than 2 minutes, as we are giving margin of error of 2 minutes in currentTimeValidWrtServerTime
  //     callback(offset)
  //   }
  //   currentTime = Date.now()
  // }, 1000);
}

export const currentTimeValidWrtServerTime = (serverTimeOffset) => {
  if(Math.abs(serverTimeOffset) > 120000){
    // 2 mins buffer
    console.log("checkIfStartTime Please set your system clock to the correct time")
    return false
  }
  else{
    return true
  }
}

export const checkIfStartTime = (experience) => {

  // let currentTimeStamp = new Date().getTime() // in UTC
  // let startTime = experience.settings.start_at_datetime
  // // in UTC

  // console.log("checkIfStartTime startTime currentTimeStamp", startTime, currentTimeStamp)
  // if (!startTime) {
  //   return true
  // }
  // else {
  //   let offsetTime = serverTimeOffset
  //   if(Math.abs(offsetTime) > 60000){
  //     console.log("checkIfStartTime Please set your system clock to the correct time")
  //     return false
  //   }
  //   else{
  //     let startTimeStamp = Date.parse(startTime)
  //     console.log("checkIfStartTime startTimeStamp", startTimeStamp)
  //     if (startTimeStamp > currentTimeStamp) {
  //       // not time yet
  //       return false
  //     }
  //     else {
  //       // can start
  //       return true
  //     }
  //   }
  // }

  
  let currentTimeStamp = new Date().getTime() // in UTC
  let startTime = experience.settings.start_at_datetime
  let startTillTime = experience.settings.start_till_datetime
  // in UTC

  console.log("checkIfStartTime startTime currentTimeStamp", startTime, currentTimeStamp)
  if (!startTime) {
    return true
  }
  else {
    let startTimeStamp = Date.parse(startTime)
    
    console.log("checkIfStartTime startTimeStamp", startTimeStamp)
    // if (startTimeStamp > currentTimeStamp) {
    if (startTimeStamp > currentTimeStamp) {
      // not time yet
      return false
    }
    else {
      if(!startTillTime){
        // can start
        return true
      }
      else{
        let startTillTimeStamp = Date.parse(startTillTime)
        if (startTillTimeStamp < currentTimeStamp) {
          // time to take has gone past
          return false
        }
        else{
          // in range - can start
          return true
        }
      }
      
    }
  }
}

export function fetchTimeoutWrapper(ms, promise) {
  return new Promise(function(resolve, reject) {
    setTimeout(function() {
      reject(new Error("timeout"))
    }, ms)
    promise.then(resolve, reject)
  })
}


export const getKeysFromObject = (object, options = {}) => {
  const finalArr = [];
  for (const key in object) {
    if (object.hasOwnProperty(key)) {
      const element = object[key];
      let finalKey = key;
      if(options.parseInt){
        finalKey = parseInt(key);
      }
      if(element !== false){
        if(options.value){
          finalArr.push(element);
        } else {
          finalArr.push(finalKey);
        }
      }
    }
  }
  return finalArr;
}

// export const findStandardsByGrade = (data, gradeName) => {
// leaving support for gradeLable as well
export const findStandardsByGrade = (data, gradeLabel = null, gradeNumber = null, gradeType = "grade") => {
  console.log("findStandardsByGrade data ===>", data, gradeLabel, gradeNumber, gradeType );

  if (!(gradeNumber || gradeLabel)) {
    return data;
  }
  let findBy = "grade_number"
  if (!gradeNumber && gradeLabel) {
    findBy = "grade_label"
  }


  const findRelatedItems = (item, relatedItems) => {
    if (!item) return;
    relatedItems.push(item);

    const parent = data.find((parentItem) => parentItem.id === item.parent_id);
    if (
      parent &&
      !relatedItems.some((relatedItem) => relatedItem.id === parent.id)
    ) {
      findRelatedItems(parent, relatedItems);
    }
  };

  // Filter standards first
  const standards = data.filter(
    (item) => 
      // item.type_c === "standard" &&
      item.type_c === "topic" &&
      !item.has_children &&
      ((item.relations &&
        // item.relations.some((relation) => relation.label === gradeName)) ||
        item.relations.some((relation) => ((findBy === "grade_number" && relation.grade_number === gradeNumber) || (findBy === "grade_label" && relation.label === gradeLabel)) && relation.type_c === gradeType)) ||
        !item.relations ||
        item.relations.length === 0)
  );

  const relatedItems = [];

  console.log("findStandardsByGrade standards", standards)
  standards.forEach((standard) => findRelatedItems(standard, relatedItems));

  return relatedItems;
};

export const sortListByPosition = (list) => {
  if (!list || !list.length) return list; 

  const sortedList = _.sortBy(list, "position");

  sortedList.forEach((item) => {
    if (item.children && item.children.length) {
      item.children = sortListByPosition(item.children);
    }
  });

  return sortedList;
};

export const convertToNestedList = (flattenedArray) => {
  const nestedStructures = [];
  const nodeMap = {};

  flattenedArray.forEach((node) => {
    nodeMap[node.id] = { ...node, children: [] };
  });

  flattenedArray.forEach((node) => {
    if (node.parent_id && nodeMap[node.parent_id]) {
      nodeMap[node.parent_id].children.push(nodeMap[node.id]);
    } else {
      nestedStructures.push(nodeMap[node.id]);
    } 
  });

  return nestedStructures;
};

export const getBrowser = () => {
  const userAgent = navigator.userAgent;
  let browser = "unknown";
  // Detect browser name
  browser = (/ucbrowser/i).test(userAgent) ? 'UCBrowser' : browser;
  browser = (/edg/i).test(userAgent) ? 'Edge' : browser;
  browser = (/googlebot/i).test(userAgent) ? 'GoogleBot' : browser;
  browser = (/chromium/i).test(userAgent) ? 'Chromium' : browser;
  browser = (/firefox|fxios/i).test(userAgent) && !(/seamonkey/i).test(userAgent) ? 'Firefox' : browser;
  browser = (/; msie|trident/i).test(userAgent) && !(/ucbrowser/i).test(userAgent) ? 'IE' : browser;
  browser = (/chrome|crios/i).test(userAgent) && !(/opr|opera|chromium|edg|ucbrowser|googlebot/i).test(userAgent) ? 'Chrome' : browser;;
  browser = (/safari/i).test(userAgent) && !(/chromium|edg|ucbrowser|chrome|crios|opr|opera|fxios|firefox/i).test(userAgent) ? 'Safari' : browser;
  browser = (/opr|opera/i).test(userAgent) ? 'Opera' : browser;


  // detect browser version
  switch (browser) {
      case 'UCBrowser': return `${browser}/${getBrowserVersion(userAgent,/(ucbrowser)\/([\d\.]+)/i)}`;
      case 'Edge': return `${browser}/${getBrowserVersion(userAgent,/(edge|edga|edgios|edg)\/([\d\.]+)/i)}`;
      case 'GoogleBot': return `${browser}/${getBrowserVersion(userAgent,/(googlebot)\/([\d\.]+)/i)}`;
      case 'Chromium': return `${browser}/${getBrowserVersion(userAgent,/(chromium)\/([\d\.]+)/i)}`;
      case 'Firefox': return `${browser}/${getBrowserVersion(userAgent,/(firefox|fxios)\/([\d\.]+)/i)}`;
      case 'Chrome': return `${browser}/${getBrowserVersion(userAgent,/(chrome|crios)\/([\d\.]+)/i)}`;
      case 'Safari': return `${browser}/${getBrowserVersion(userAgent,/(Version)\/\d+([^\s]+)/gi, true)}`;
      case 'Opera': return `${browser}/${getBrowserVersion(userAgent,/(opera|opr)\/([\d\.]+)/i)}`;
      case 'IE': const version = getBrowserVersion(userAgent,/(trident)\/([\d\.]+)/i);
          // IE version is mapped using trident version
          // IE/8.0 = Trident/4.0, IE/9.0 = Trident/5.0
          return version ? `${browser}/${parseFloat(version) + 4.0}` : `${browser}/7.0`;
      default: return `unknown/0.0.0.0`;
  }
}

export const getBrowserName = (browser) => {
  let browserName = "";
  if (browser) {
    browserName = browser.match(/^(\w+)/g) && browser.match(/^(\w+)/g)[0];
  } else {
    browserName =
      getBrowser().match(/^(\w+)/g) && getBrowser().match(/^(\w+)/g)[0];
  }

  return browserName;
};

export const getBrowserVersion = (
  userAgent,
  regex,
  removeExtraInfo,
  index = 2
) => {
  if (removeExtraInfo) {
    const browserVersion = userAgent.match(regex) && userAgent.match(regex)[0];
    return browserVersion ? browserVersion.replace(/Version\//g, "") : "";
  }
  return userAgent.match(regex) ? userAgent.match(regex)[index] : null;
};

export const getMobileOperatingSystem = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    if (/iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "IOS";
    }
    // IPad Browser no longer has userAgent IOS - instead is now Macintosh - so handling that to identify iPad
    if (checkIPAD()) {
      return "IPad OS";
    }

    return "unknown";
}

export const getOS = () => {
    var platform = window.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (!os && /Linux/.test(platform)) {
    const userAgent = navigator.userAgent || window.navigator.userAgent;
    if(userAgent && userAgent.indexOf("CrOS") !== -1){
      os = "ChromeOS"
    }else{
      os = 'Linux';
    }
  }

  // return "Windows"
  return os;
}

// export const getAppNativeVars = (appType) => {
//   // TODO: should only return appNativeVars and not 598
//   const userAgent = navigator.userAgent || window.navigator.userAgent;
//   let appNativeVars = {}
//   if (appType === "seb") {
//     appNativeVars.appVersion = userAgent.match(/SEB\/([^\s]+)/g) && userAgent.match(/SEB\/([^\s]+)/g)[0].replace(/SEB\//g, '');
//   }
//   if (appType === "mac" || appType === "ios") {
//     let localAppNativeVars = localStorage.getItem("apNativeVars");
//     if (localAppNativeVars && localAppNativeVars != undefined && localAppNativeVars != "undefined") {
//       appNativeVars = JSON.parse(localAppNativeVars);
//     }
//   }
//   return appNativeVars;
// };


// export const getAppVersion = (appType) => {
//   const userAgent = navigator.userAgent || window.navigator.userAgent;
//   let appVersion = '';
//   if (appType === "seb") {
//     appVersion = userAgent.match(/SEB\/([^\s]+)/g) && userAgent.match(/SEB\/([^\s]+)/g)[0].replace(/SEB\//g, '');
//   }
//   if (appType === "mac" || appType === "ios") {
//     let appNativeVars = getAppNativeVars(appType)
//     appVersion = appNativeVars.appVersion
//   }
//   return appVersion;
// };

// export const getOSVersion = (appType, systemInfo, finalBrowserVersion = '') => {
//   let finalVersion = "";
//   // @nitin why Mac OS also here? can remove?
//   if (appType === "Mac OS" || appType === "mac" || appType === "ios") {
//     if (systemInfo) {
//       let version =
//         systemInfo.match(/Version \d.+ \(B/gi) &&
//         systemInfo.match(/Version \d.+ \(B/gi)[0];
//       if (version) {
//         finalVersion = version.replace(/Version | \(B/gi, "");
//       }
//     }
//   }
//   console.log("getOSVersion appType", appType);
//   if(appType == "cros"){
//     // In case of ChromeOS, OS version = Chrome browser version
//     finalVersion = finalBrowserVersion
//   }
//   return finalVersion;
// };

// export const supportsMacAACSecurity = (appType) => {
//   let supportsAAC = false
//   if (appType === "ios") {
//     supportsAAC = true;
//   } else if(appType === "mac"){
//     const deviceInfo = getDeviceInfo(appType);
//     // const currentAppVersion = "10.0.0"
//     // const currentAppVersion = "9.0.2"
//     // const deviceOsVersion = "12.5"; // done: issue here when comparing 12.5 to 10.15.4 in isNewAppVersion
//     const currentAppVersion = getAppVersion(appType)
//     const deviceOsVersion = deviceInfo.osVersion;
//     if (isNewAppVersion(currentAppVersion, "10.0.0") && isNewAppVersion(deviceOsVersion, "10.15.4")){ 
//       supportsAAC = true
//     }
//   }
//   return supportsAAC
// }

export const generateOpenInAppUrl = (item, token, openExperience = false, withToken = false) => {
  const viteBaseUrl = import.meta.env.VITE_WEB_URL; //TODO check VITE_WEB_URL always available or not
  let urlWithoutProtocol = viteBaseUrl.split("https://")[1]; //'192.168.68.64:3000'; // for localhost
  let expUrl = `assessprep://${urlWithoutProtocol}`;

  if (withToken) {
    // for iOS enforcing login if start in App is clicked
    expUrl += `?token=${token}`
  }

  if (openExperience) {
      // The appLogin parameter is used to handle the changeSecurity method after a redirect and the changeSecurity method call as per appLogin == true, if macapp open from dynamic config .
      // This is necessary because the `token` will be removed from the query parameters after the redirect.
      expUrl = `assessprep://${urlWithoutProtocol}/e/tests/${item.uid}?firstLoad=true&appLogin=true&token=${token}`;
  }
  console.log("expUrl===============>>",expUrl);
  return expUrl;
}

// export const supportsDynamicConfig = (appType) => {
//   let dynamicConfigSupported = false;
//   const deviceInfo = getDeviceInfo(appType);
//   const currentAppVersion = getAppVersion(appType);
//   const deviceOsVersion = deviceInfo.osVersion;

//   if (appType === "ios" && isNewAppVersion(currentAppVersion, "3.2.0")) {
//     return true;
//   }

//   if (appType === "mac") {
//     if (
//       isNewAppVersion(currentAppVersion, "10.2.0") &&
//       isNewAppVersion(deviceOsVersion, "10.15.4")
//     ) {
//       dynamicConfigSupported = true;
//     }
//   }
//   return dynamicConfigSupported;
// }

// export const isTabFeatureSupported = (appType) => {
//   var allowNewtab = false
//   const currentSystemAppVersion = getAppVersion(appType)
//   const deviceInfo = getDeviceInfo(appType);
//   const deviceOsVersion = deviceInfo.osVersion;
//   console.log("os======>",deviceOsVersion)
//   // NO ios support yet
//   //  if (appType === "ios" && isNewAppVersion(appVersion,"2.0.1")) {
//   //   allowNewtab = true
//   // }
//   // const latestAppVersion = getLatestAppVersion(appType)

//   // if(!latestAppVersion || !currentSystemAppVersion){
//   //   return false;
//   // }
//   if (appType === "ios") {
//     allowNewtab = false;
//   }
//   else if (
//          appType === "mac" &&
//          isNewAppVersion(currentSystemAppVersion, "10.1.0") &&
//          isNewAppVersion(deviceOsVersion, "11.0.0") 
//        ) {
//          //We are allowing the new tab feature from 10.1.0 for mac app
//          allowNewtab = true;
//        } else if (
//          appType === "seb" &&
//          isNewAppVersion(currentSystemAppVersion, "3.3.2")
//        ) {
//          //We are allowing the new tab feature from 3.3.2 for seb app
//          allowNewtab = true;
//        }
   
//   return allowNewtab;
// };

// export const isNewAppVersionLegacy = (appVersion,compareVersion) =>{

//   if(!appVersion || !compareVersion){
//     return false
//   }
  
//  //app budle version formate 1.0.1
//   var appVersionBundle = appVersion.replace(".","") 
//   var compareVersionBundle = compareVersion.replace(".","")
//   var isNewer = false
//   if(appVersionBundle >= compareVersionBundle){
//     isNewer = true
//   }
//   return isNewer;
// }

// export const isNewAppVersion = (appVersion,compareVersion) =>{
//   // desired output: appVersion >= compareVersion ? true : false
//   if(!appVersion || !compareVersion){
//     return false
//   }
  
//   const appVersionComponents = appVersion.split('.');
//   const compareVersionComponents = compareVersion.split('.');

//   // Compare the components in turn
//   for (let i = 0; i < appVersionComponents.length; i++) {
//     const appVersionComponent = parseInt(appVersionComponents[i] || 0); // default to 0 if there are no more components in appVersion
//     const compareVersionComponent = parseInt(compareVersionComponents[i] || 0); // default to 0 if there are no more components in compareVersionVersion
    
//     if (appVersionComponent > compareVersionComponent) {
//       return true;
//     } else if (appVersionComponent < compareVersionComponent) {
//       return false;
//     }
//   }
  
//   // If all components are equal, the versions are the same
//   return true;

// }

// export const getDeviceInfo = (appType = '') => {
//   console.log("calling getDeviceInfo");
//   // var browserName = getBrowser().match(/\w.+\//g) && getBrowser().match(/\w.+\//g)[0];

//   var finalBrowserName = getBrowserName();
//   var browserVersion;
//   if (getBrowser().includes('Safari')) {
//     browserVersion = getBrowserVersion(getBrowser(), /\/\d+([^\s]+)/g, null, 0) 
//     // getBrowser().match(/\/\d+([^\s]+)/g) && getBrowser().match(/\/\d+([^\s]+)/g)[0];
//   } else {
//     browserVersion = getBrowserVersion(getBrowser(), /\/\d+/g, null, 0)
//     // getBrowser().match(/\/\d+/g) && getBrowser().match(/\/\d+/g)[0];
//   }


//   var finalBrowserVersion = browserVersion ? browserVersion.replace(/\//g, "") : "";
//   console.log('inside get browser==>', finalBrowserVersion);

//   let appNativeVars = getAppNativeVars(appType)
//   let osVersion = getOSVersion(appType, appNativeVars.systemInfo, finalBrowserVersion);
//   let appVersion = getAppVersion(appType)
//   // const deviceInfo = checkMob() ? getMobileOperatingSystem() : getOS() + " " + finalBrowserName + "-" + finalBrowserVersion;
//   const deviceInfo = {
//     os: (checkMob() || checkIPAD()) ? getMobileOperatingSystem() : getOS(),
//     browser: finalBrowserName + "-" + finalBrowserVersion,
//     osVersion: osVersion,
//     appVersion: appVersion,
//     app_type: appType,
//     ...appNativeVars
//   }
//   return deviceInfo;
// }


export const b64toBlob = (base64String, sliceSize) => {
  var block = base64String.split(";");
  var contentType = block[0].split(":")[1];
  var b64Data = block[1].split(",")[1];

  // console.log("block, contentType, b64Data", block, contentType, b64Data)
  contentType = contentType || '';
  sliceSize = sliceSize || 512;

  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, {type: contentType});
  return blob;
}

export const getBase64Image = (imgUrl) => {
  
  return new Promise((resolve, reject) => {
    var img = new Image();
    img.setAttribute('crossOrigin', 'anonymous');
    img.src = imgUrl;
  
    img.onload = function() {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      var dataURL = canvas.toDataURL("image/png");
      resolve(dataURL)
      // resolve(dataURL.replace(/^data:image\/(png|jpg);base64,/, ""));
    }

    img.onerror = function() {
      reject("The image could not be loaded.");
    }
  })
}

export const buildCountriesOptionsArr = () => {
  let options = countries.countries
  let optionsArr = [];
  options.map((value, index) => {
    optionsArr.push({ label: value.name, value: value.code });
  });
  // let optionsArr = [
  //   {
  //     label: "Yes",
  //     value: "Yes",
  //   },
  //   {
  //     label: "No",
  //     value: "No",
  //   },
  // ]
  return optionsArr;
}

export const openUrl = (url) => {
  if(url){
    window.location.href = url
  }
}

export const displayPoints = (points, truncated = false, cleaned = true) => {
  if(points === null || points === undefined){
    // console.log("displayPoints - return empty", points)
    return "";
  }
  let pts = parseFloat(points).toFixed(1)
  // Truncate not needed anymore
  // if(truncated && import.meta.env.VITE_MODE === "production"){
  //   // pts = parseFloat(points).toFixed(0)
  //   pts = Math.trunc(parseFloat(points))
  // }
  if(cleaned){
    let splitPts = pts.split(".")
    console.log("displayPoints cleaned", cleaned, pts, splitPts)
    if(splitPts.length > 1 && splitPts[1] && parseInt(splitPts[1]) === 0){
      pts = splitPts[0]
    }
  }
  return pts;
}

// export const featureCheck = (featureCode, currentUser) => {
//   let featureEnabled = false
//   let featuresEnabled = currentUser.org.org_features || [] // check for presence in this array of feature codes
//   let featureOverrides = currentUser.org.feature_overrides || [] // check for true/false in this array of key value pairs
//   if(featuresEnabled.indexOf(featureCode) > -1){
//     featureEnabled = true
//   }
//   // checking in overrides
//   console.log("featureCheck featureOverrides", featureOverrides)
//   let overridePresent = featureOverrides.find((item) => item.feature_code == featureCode)
//   if(overridePresent){
//     if(overridePresent.feature_enabled){
//       featureEnabled = true
//     }else{
//       featureEnabled = false
//     }
//   }
//   // to be uncommented when ready to use
//   return featureEnabled
  
//   // for now
//   // return true

// }

export const writeToClipboardPolyfill = async (str, html = false) => {
  return new Promise(async (resolve, reject) => {
    console.log("writeToClipboardPolyfill ===> called", str, html);
    // try {
    var success = false;

    function listener(e) {
      let format = "text/plain";
      if (html) {
        format = "text/html";
      }
      console.log(
        "writeToClipboardPolyfill ===> inside listener",
        str,
        html,
        format
      );
      e.clipboardData.setData(format, str);
      e.preventDefault();
      success = true;
    }

    document.addEventListener("copy", listener);
    document.execCommand("copy");
    document.removeEventListener("copy", listener);

    if (
      navigator.clipboard &&
      navigator.clipboard.writeText &&
      navigator.clipboard.write &&
      success !== true
    ) {
      // Clipboard API is supported
      if (html) {
        // If value is HTML, use write() method with ClipboardItem
        const item = new window.ClipboardItem({
          ["text/html"]: new Blob([str], {
            type: "text/html",
          }),
        });
        console.log(
          "writeToClipboardPolyfill ===> html item blob",
          item,
          str,
          html
        );
        await navigator.clipboard.write([item]);
      } else {
        // If value is plain text, use writeText() method
        await navigator.clipboard.writeText(str);
      }
      console.log(
        "writeToClipboardPolyfill ===> Copied to clipboard successfully."
      );
      success = true;
    }
    if (!success) {
      if (str.length > 0) {
        message.error("Failed to copy");
      }
      reject(new Error("Failed to clear clipboard"));
    } else {
      resolve();
    }
  });
};    

export const dropDownTrigger = checkIPAD() || checkMob() ? 'click' : 'hover';


export const getLabelFromIndexPath = (segmentIndexPath = []) => {
  let label = []
  segmentIndexPath.forEach((index, l) => {
    // segmentIndexPath has indexes, need number so adding 1
    label.push(getQuestionNumber(index + 1, l))
  })
  return label.join(".")
}

export const currentAlphabetByIndex = (index) => {
  const str ='abcdefghijklmnopqrstuvwxyz'.charAt(index);
  return str
}

// TODO move these 2 Fns to ExperienceHelper
export const getQuestionNumber = (num, level = 1) => {
  var str = '';
  if(level === 3){
    var roman = {
      M: 1000,
      CM: 900,
      D: 500,
      CD: 400,
      C: 100,
      XC: 90,
      L: 50,
      XL: 40,
      x: 10,
      ix: 9,
      v: 5,
      iv: 4,
      i: 1
    };
  
    for (var i of Object.keys(roman)) {
      var q = Math.floor(num / roman[i]);
      num -= q * roman[i];
      str += i.repeat(q);
    }
  
    return "("+str+")";
  }else if(level === 2){
    str ='abcdefghijklmnopqrstuvwxyz'.charAt(num-1);
  
    return "("+str+")";
  }else if(level === 1){
    return num;
  }else {
    // Level 0 = topics
    // return A, B, C
    // str ='ABCDEFGHIJKLMNOPQRSTUVWXYZ'.charAt(num-1);
    // return str;

    // doing 1,2,3 for now
    return num;
  }

}

export const getDestinationPositionLex = (prevPosition, nextPosition = null) => {
  if(!prevPosition){
    prevPosition = "0"
  }
  console.log("getDestinationPositionLex prevPosition, nextPosition", prevPosition, nextPosition)
  // var hex = new mudder.SymbolTable('0123456789abcdef');
  // var hexstrings = hex.mudder(prevPosition, nextPosition, 3);
  // OR
  
  // *We just need 1 string between the 2
  // *base62 already sets the SymbolTable to use 0-9A-Za-Z
  // TODO: add try catch here
  let destinationPosition = "0"
  try {
    let hexstrings = mudder.base62.mudder(prevPosition, nextPosition); // *Returns an array of strings
    console.log("getDestinationPositionLex hexstrings", hexstrings)
    destinationPosition = hexstrings[0]
  } catch (error) {
    console.log("getDestinationPositionLex mudder failed", prevPosition, nextPosition)
    // Sentry.captureException(error);
  }

  console.log("final destionaltionPosition==>", destinationPosition)

  return destinationPosition

}

export const getPermissions = (constraints, {successCallback, errorCallback}) => {
  if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(successCallback, errorCallback)
  } else {
    navigator.getUserMedia = navigator.getUserMedia ||
    navigator.webkitGetUserMedia ||
    navigator.mozGetUserMedia;
    if (navigator.getUserMedia) {
      navigator.getUserMedia(constraints, successCallback, errorCallback)
    } else {
      console.log("getUserMedia is not supported in this browser", navigator)
    }
    navigator.mediaDevices = {getUserMedia: navigator.getUserMedia}
  }
}

// export const checkIfURLOfType = (type, url) => {
//   if (
//     (type == 'vimeo' && url.match(/(https:\/\/\S*vimeo\S*)\w+/g)) || 
//     (type == 's3_url' && url.match(/direct_uploads\/attachments/g)) || 
//     (type == 'youtube' && url.match(/(https:\/\/\S*youtu\S*)\w+/g))
//   ) {
//     return true;
//   }
//   return false;
// };

export const checkURLSource = (url) => {
  if (url) {
    if (url.match(/(https:\/\/\S*vimeo\S*)\w+/g)) {
      return 'vimeo'
    } else if (url.match(/(https:\/\/\S*youtu\S*)\w+/g)) {
      return 'yt'
    } else if (url.match(/(https:\/\/\S*youku\S*)\w+/g)) {
      return 'youku'
    } else if (url.match(/direct_uploads\/attachments/g)) {
      return 's3'
    } else {
      return ""
    }
  } else {
    return ""
  }
}

export const checkURLExist = (props, checkProgressInterval) => {
  const { url, interval, successCallback, errorCallback = () => {} } = props;
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      token: token,
    },
  };

  let checkURLInterval = null
  const headRequest = () => {
    axios.head(url, config)
      .then((value) => {
        console.log("axios checking if url exists success ==>", value);
        clearInterval(checkURLInterval);
        clearInterval(checkProgressInterval);
        successCallback(value);
      })
      .catch((error) => {
        console.log("axios checking if url exists error ==>", error);
        clearInterval(checkURLInterval);
        errorCallback(error);
      });
  }
  headRequest()
  checkURLInterval = setInterval(headRequest, interval || 30000);
}

export const  checkProcessingProgress = (props) => {
  console.log("checkProcessingProgress===>", props);
  const { job_id, interval, successCallback, errorCallback, intervalRef } = props
  const token = localStorage.getItem("token");
  console.log("aq token",token);
  const config = {
    headers: {
      "x-access-token": token,
    },
  };
  if(job_id){
    const url = `${import.meta.env.VITE_NODE_SERVICE_BASE_URL}api/auth/job/${job_id}`
    console.log("aq url", url);
    let checkProgressInterval = null
    const getRequest = () => {
      console.log("aq get request running");
      axios.get(url, config)
        .then((value) => {
          console.log("axios checking progress ==>", value);
          successCallback(value.data, checkProgressInterval)
          if (value.data.progress === 100) {
            clearInterval(checkProgressInterval);
          }
        })
        .catch((error) => {
          console.log("axios checking progress error ==>", error);
          errorCallback(error, checkProgressInterval)
        })
    }
    getRequest()
    checkProgressInterval = setInterval(getRequest, interval || 30000);
    intervalRef.current = checkProgressInterval;
  }

}

// window.getDestinationPositionLex1 = (prevPosition, nextPosition = null) => {
//   if(!prevPosition){
//     prevPosition = "0"
//   }
//   console.log("getDestinationPositionLex prevPosition, nextPosition", prevPosition, nextPosition)
//   // var hex = new mudder.SymbolTable('0123456789abcdef');
//   // var hexstrings = hex.mudder(prevPosition, nextPosition, 3);
//   // OR
  
//   // *We just need 1 string between the 2
//   // *base62 already sets the SymbolTable to use 0-9A-Za-Z
//   let hexstrings = mudder.base62.mudder(prevPosition, nextPosition, 200); // *Returns an array of strings
//   console.log("getDestinationPositionLex hexstrings", hexstrings)

//   return hexstrings[0]

// }

export const createUpdateSelectAttrsBeforeSubmit = (itemable_type, existing_items, modified_ids) => {
  console.log("createUpdateSelectAttrsBeforeSubmit", existing_items, modified_ids)
  // currently only for tag_items - can further generalise
  // both params are arrays
  let items_attributes = [];

  let toCreate = null;
  let toRemove = null;
  let toUnarchive = null;
  let existing = null;
  let modified = null;
  // create/update
  if (modified_ids) {
    if(typeof(modified_ids) == "number"){
      modified = [modified_ids]; // to handle case where single select, modified_ids not an array in this case
    }else{
      modified = modified_ids; // modified_ids is an array
    }
  }
  if (existing_items) {
    existing = _.map(existing_items, "tag_id");
  }
  toCreate = _.difference(modified, existing);
  toRemove = _.difference(existing, modified);
  toUnarchive = _.intersection(existing, modified);

  console.log("toCreate, toRemove ", toCreate, toRemove);
  if (toCreate) {
    toCreate.map((selectItemId, i) => {
      items_attributes.push({
        itemable_type: itemable_type,
        tag_id: selectItemId, // may need to change when we offer custom syllabus topics per org - based on qbMode
      });
    });
  }
  if (toRemove) {
    toRemove.map((selectItemId, i) => {
      console.log(
        "selectItemId, existing ",
        selectItemId,
        existing,
        existing_items
      );
      let selectItem = _.find(existing_items, [
        "tag_id",
        selectItemId,
      ]);
      items_attributes.push({
        id: selectItem.id,
        itemable_type: itemable_type,
        tag_id: selectItemId,
        archived: true,
      });
    });
  }
  if (toUnarchive) {
    toUnarchive.map((selectItemId, i) => {
      console.log(
        "selectItemId, existing ",
        selectItemId,
        existing,
        existing_items
      );
      let selectItem = _.find(existing_items, [
        "tag_id",
        selectItemId,
      ]);
      items_attributes.push({
        id: selectItem.id,
        itemable_type: itemable_type,
        tag_id: selectItemId,
        archived: false,
      });
    });
  }

  return items_attributes
}

export const getZoomJoinUrlForEmbedded = (url) => {
  var zoomJoinUrl = url
  const meetingId = getZoomMeetingId(url)
  //uncomment code if password needed 
  //APL-1744 ref url : https://support.digiexam.se/hc/en-us/articles/360017885500-Zoom-meeting-and-remote-exams
  //const queryParam = new URL(url).searchParams.get('pwd');
  //const joinWithPassword = (queryParam)? "?pwd="+queryParam+"/join" : "/join"
  if(meetingId){
    // zoomJoinUrl = "https://us04web.zoom.us/wc/"+`${meetingId}`+ "/join"
    zoomJoinUrl = "https://us04web.zoom.us/wc/join/"+`${meetingId}` // zoom has changed url structure
  }
  return zoomJoinUrl;
};

export const getRegexExpFor = (key) => {
  let regexExpressionsMap = {
    "zoom": "^https:\/\/((zoom\.(com|cn|us))|(.*?\.zoom\.(com|cn|us)))",
    "zoom_or_meet": "^https:\/\/((zoom\.(com|cn|us))|(.*?\.zoom\.(com|cn|us))|(meet\.(google)\.(com|cn)))",
  }
  return regexExpressionsMap[key] || ""
}

// export const isVideoUrlAllowedInsideApp = (url, appType) => {
//   let isNewTabSupported = appType === "web" ? true : isTabFeatureSupported(appType)
//   let isVideoUrlAllowed = false
//   const videoPlatformUrlsRegExp = {
//     "ios": getRegexExpFor("zoom"),
//     "mac": getRegexExpFor("zoom_or_meet"),
//     "seb": getRegexExpFor("zoom"),
//     // "seb": getRegexExpFor("zoom_or_meet"),
//     // "cros": "" // not supporting for now
//   }
//   const videoPlatformUrlsRegExpForAppType = new RegExp(videoPlatformUrlsRegExp[appType])
//   if(videoPlatformUrlsRegExpForAppType){
//     isVideoUrlAllowed = videoPlatformUrlsRegExpForAppType.test(url)
//   }
//   return isNewTabSupported && isVideoUrlAllowed;
// }

export const isValidHttpsUrl = (url) =>{
  if(!url){
    return false;
  }
  var matcher = /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/;
  const isValidHttpsUrl = matcher.test(url);
  return isValidHttpsUrl;
}

export const isValidZoomOrMeetUrl = (url) =>{
  const zoomRegExp = new RegExp(getRegexExpFor("zoom_or_meet"))
  return zoomRegExp.test(url);
}

export const isValidZoomUrl = (url) =>{
  const zoomRegExp = new RegExp(getRegexExpFor("zoom"))
  return zoomRegExp.test(url);
}

//List of valid Urls
//https://us06web.zoom.us/wc/join/2773777737?pwd=4535
//https://us06web.zoom.us/j/2773777737?pwd=4535
//https://us06web.zoom.us/wc/join/2773777737
//https://us06web.zoom.us/j/2773777737
//https://us04web.zoom.us/wc/2773777737/join

export const getZoomMeetingId = (url) => {
  const defaultZoomRegex = new RegExp("^https://.*.zoom.us/.*/.*");
  let meetingId = "";
  if (defaultZoomRegex.test(url)) {
    let regexValue = url.match(/wc.*|j.*/g);
    let finalRegex =
      regexValue &&
      regexValue[0] &&
      regexValue[0].replace(/join|wc|\/|j|\?.*/g, "");
    meetingId = finalRegex ? finalRegex : false;
  }
  console.log("meeting id===>", meetingId);
  return meetingId;
};

export const getAPColorPallete = () => {
  // Currently using in charts - also add to css

  let colors = {
    "blue": "#1B9CFC",
    "lightblue": "#25CCF7",
    "aliceblue": "aliceblue",
    "navyblue": "#182C61",
    "purple": "#82589F",
    "red": "#FC427B",
    "magenta": "#B33771",
    "peach": "#FD7272",
    "pink": "#D6A2E8",
    "orange": "#F97F51",
    "lightorange": "#EAB543",
    "yellow": "#F8EFBA",
    "green": "#58B19F",
    "lightgreen": "#55E6C1",
    "grey": "#cccccc",
    "lightgrey": "#eeeeee",
    // "skyblue": "#00A6BD",
    "skyblue": "#78CFDB",
  }

  return colors;
}

export const extraTimeValues = [
    // "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "12",
    "15",
    "20",
    "30",
    "40",
    "50",
    "60",
    "70",
    "80",
    "90",
    "100",
    "110",
    "120",
  ];
export const getQBIcon = (code) => {
  const qbIconMap = {
    "ib_dp": IBLogoOSC,
    "osc_dp": PamojaIcon,
    "ap_dp": LogoImage,
    "ap_myp": LogoImage,
    "ap_myp_standard": LogoImage,
  }
  return qbIconMap[code]
}

export const getQBDesc = (code, mrIntl = () => {}) => {
  // const mrIntlFunction = () => {
  // }
  // const mrIntlNew = mrIntl || mrIntlFunction
  const qbDescMap = {
    // "school": "All assessments authored by teachers in your school",
    // "ib_dp": "Past IB exams",
    // "osc_dp": "Realistic, full exams created by Pamoja authors",
    // "ap_myp": "Mock eAssessments and topic wise formatives created by AssessPrep authors",
    // "ap_myp_standard": "Mock eAssessments and topic wise formatives created by AssessPrep authors",
    "school": mrIntl("helperMethods.all_assessments_authored_by_teachers"),
    "ib_dp": mrIntl("helperMethods.past_ib_exams"),
    "osc_dp": mrIntl("helperMethods.realistic_full_exams_created_by_pamoja"),
    "ap_myp": mrIntl("helperMethods.mock_eassessments_and_topic_wise_formatives"),
    "ap_myp_standard": mrIntl("helperMethods.mock_eassessments_and_topic_wise_formatives"),
    "ap_dp": mrIntl("helperMethods.realistic_full_exams_created_by_assessprep_and_pamoja"),
  }
  return qbDescMap[code]
}

export const getSubjectIconMap = () => {

  // https://ap-v2-prod.s3.eu-central-1.amazonaws.com/manual_uploads/icons/sebo_icons/Biology.png
  // https://v2-res1.assessprep.com/manual_uploads/icons/sebo_icons/Biology.png

  const subjectIconMap = {
    "biology": "Biology.png",
    "chemistry": "Chemistry.png",
    "physics": "Physics.png",
    "sciences": "Sciences.png",
    "mathematics": "Mathematics.png",
    "mathematics: analysis and approaches": "Mathematics A&I.png",
    "mathematics: applications and interpretation": "Mathematics A&A.png",
    "standard mathematics": "Standard-mathematics.png",
    "extended mathematics": "Extended-mathematics.png",
    "economics": "Economics.png",
    "geography": "Geography.png",
    "history": "History.png",
    "integrated humanities": "Integrated-humanities.png",
    "business management": "Business-&-Management.png",
    "english": "English A Language & Literature.png",
    "french": "French A Language & Literature.png",
    "dance": "Dance.png",
    "computer science": "Computer Science.png",
    "psychology": "Psychology.png",
    "social and cultural anthropology": "Social And Cultural Anthropology.png",
    "world religions": "World Religions.png",
  }

  return subjectIconMap;
}

// const appVersionsList = {
//   seb: [
//     // { version: "3.8.0.999", status: "latest" },
//     { version: "3.8.0", status: "latest" },
//     { version: "3.5.0", status: "latest" },
//     { version: "3.4.1", status: "old" },
//     { version: "3.4.0", status: "old" },
//     { version: "3.3.2", status: "old" },
//     { version: "3.3.1", status: "unsupported" },
//     { version: "3.3.0", status: "unsupported" },
//     { version: "3.2.0", status: "unsupported" },
//     { version: "3.1.1", status: "unsupported" },
//     { version: "3.1.0", status: "unsupported" },
//     { version: "3.0.1", status: "unsupported" },
//     { version: "3.0.0", status: "unsupported" },
//     { version: "2.4.1", status: "unsupported" },
//   ],
//   mac: [
//     // { version: "11.1.0", status: "latest" }, // TODO : remove when merge to prod, now add due to check auto update feature
//     { version: "11.0.0", status: "latest" }, // Feature: Auto app update
//     // { version: "10.2.0", status: "old" },
//     { version: "10.2.0", status: "latest" },
//     { version: "10.1.0", status: "unsupported" }, //AAC mode app
//     { version: "10.0.0", status: "unsupported" }, //IMP: v10.0.0 released for only few schools
//     { version: "9.0.2", status: "old" }, //Major running app (without ACC mode) before release v10.1.0
//     { version: "9.0.1", status: "unsupported" },
//     { version: "9.0.0", status: "unsupported" },
//     { version: "8.0.1", status: "unsupported" },
//     { version: "8.0.0", status: "unsupported" },
//     { version: "7.0.1", status: "unsupported" },
//     { version: "7.0.0", status: "unsupported" },
//   ],
//   ios: [
//     { version: "3.2.0", status: "latest" },
//     { version: "2.0.5", status: "old" },
//     { version: "1.0.2", status: "old" },
//     { version: "1.0.1", status: "unsupported" },
//     { version: "1.0.0", status: "unsupported" },
//   ],
//   cros: [{ version: "107", status: "latest" }],
//   web: {
//     chrome: [{ version: 84, status: "unsupported" }],
//     firefox: [{ version: 81, status: "unsupported" }],
//     safari: [{ version: 14, status: "unsupported" }],
//   },
// };


// export const getAppVersionInfo = (appType, version, browser) => {
//   const data = appVersionsList;
//   if (!version || !appType) {
//     return {};
//   }
//   if (appType === "web") {
//     if (!browser || !data[appType][browser]) {
//       return {};
//     }
//     return data[appType][browser].find((item) => item.version === version);
//   }
//   return data[appType].find((item) => item.version === version);
// };

// export const checkUserAppVersionStatus = (appType, deviceInfo, enabledFeatures = {}) => {
//   let appVersionStatus = {};
//   if (deviceInfo) {
//     if (appType === "web" || appType === "cros") {
//       let browserVersion = getBrowserVersion(deviceInfo.browser, /\d.+/g, null, 0);
//       let browserName = toLower(getBrowserName(deviceInfo.browser));
//       appVersionStatus = getAppVersionInfo(appType, browserVersion, browserName);
//     } else {
//       let getLatestVersionOfApp = getLatestAppVersion(appType, enabledFeatures);
//       appVersionStatus = getAppVersionInfo(appType, deviceInfo.appVersion);
//       if (appType === "mac" && getLatestVersionOfApp == deviceInfo.appVersion) {
//         //Checking because mac app latest version according to feature flag
//         appVersionStatus = { ...appVersionStatus, status: "latest" };
//       }
//     }
//   }
//   return appVersionStatus;
// };
// export const browserUnsupported = (appType, deviceInfo) => {
//   if (appType !== "web") return false;

//   const browserName = getBrowserName()?.toLowerCase();
//   const browserVersion = getBrowserVersion(deviceInfo.browser, /\d.+/g, null, 0);
//   const browserData = appVersionsList.web?.[browserName]?.[0];

//   return browserData?.status === 'unsupported' && browserVersion <= browserData.version;
// };
// export const getLatestAppVersion = (
//   appType,
//   enabledFeatures = {},
//   returnAAC = false
// ) => {
//   let latestVersion = appVersionsList[appType] ? appVersionsList[appType][0]["version"] : null;

//   // We implemented a feature flag for 10.2.0 app that its app was accessible/download to only a limited number of schools and now publish for all
//   // if (appVersionsList[appType] && appType === "mac") {
//   //   latestVersion = appVersionsList[appType][1]["version"];
//   // }

//   return latestVersion
// };


// export const macAppsToClose = [
//   "com.wiheads.paste",
//   "com.wiheads.paste-setapp",
//   "com.sublimetext.3",
//   "com.microsoft.Word",
//   "com.microsoft.Powerpoint",
//   "com.microsoft.Excel",
//   "com.microsoft.Outlook",
//   "com.apple.mail",
//   "com.fiplab.clipboard",//free copy clip app
//   "com.fiplab.copyclip2",//version of 2 copy clip paid app
//   "net.maxbor.CopyLess",//free copy clip app
//   "com.goodsnooze.MenuGPT",//mac gpt app
//   "com.apple.shortcuts", // default shortcut app , its not work/app close now
//   "com.apple.shortcuts.remote.help", // shortcut app , its not work/app close now
//   "com.kittenyang.ios.ChatGPT-Mac-MenuBar",
//   "com.vincelwt.chatgptmac",
//   "com.Ai-writer.gpt3",
//   "com.app.menubarx",
//   "me.tanmay.AnyGPT",
//   "com.smileonmymac.textexpander",//text expanders app, apple default text replacement didn't work
//   "com.oslash.macapp",
//   "com.bartelsmedia.PhraseExpressOSX",
//   "com.federicoterzi.espanso",
//   "com.typeit4me.TypeIt4MeMenu",
//   "com.trankynam.aText",
//   "com.macility.typinator2",
//   "com.typedesk.app",
//   "com.stairways.keyboardmaestro.editor",
//   "com.runningwithcrayons.Alfred",
//   "com.openai.chat",
// ];


export const getOKPingUrl = (appRegion) => {
  let url = "https://v2res1.assessprep.com/manual_uploads/OK.txt"
  // let url = "https://v2res1-stag.assessprep.com/manual_uploads/OK.txt"
  // let url = "https://v2res1-stag.assessprep.com/manual_uploads/1px.png"
  if(appRegion === "china"){
    url = "https://production-eks-assets.assessprep.cn/manual_uploads/OK.txt"
  }
  return url
}

export function calculatePercentage(numerator, denominator) {
  console.log("numerator, denominator", numerator, denominator);
  if(!denominator || !parseFloat(denominator)){
    return 0;
  }
  return (numerator * 100) / denominator;
}

// export const findMissingExperienceUserIds = (requestedExperienceUserIds, experienceUsers, userResponses) => {
//   const missingExperienceUserIds = []
//   const missingUserIds = []

//   requestedExperienceUserIds.forEach((eu_id) => {
//     // NOTE: if atleast one user_response exists then we are not fetching
//     let existingUserResponse = userResponses.find((ur) => ur.experience_user_id == eu_id)
//     if (!existingUserResponse) {
//       let experienceUser = experienceUsers.find((eu) => eu.id == eu_id)
//       missingExperienceUserIds.push(eu_id)
//       missingUserIds.push(experienceUser.user_id)
//     }
//   })
//   return {missingExperienceUserIds, missingUserIds};
// }

export const isStringEmpty = (str) => {
  str = removeHTMLTagsFromText(str)
  console.log("isStringEmpty", str, !str, (str && str.trim().length === 0) );
  return (!str || (str && str.trim().length === 0) );
}

export const isKeyCodeValid = (str) => {
  return (str && /^[a-z0-9_]+$/.test(str));
}

export const isStringEmptyOrHasBlankSpace = (str) => {
  return (str === "" || /\s/.test(str));
};

export const focusElement = (selector) => {
  const firstInputBox = document.querySelector(selector)
  if (firstInputBox) {
    firstInputBox.focus();
  }
}

export const checkParentElementHasClassNames = (element, classNames) => {
  const parentElement = element.parentElement;
  if (parentElement) {
    const classList = parentElement.classList;
    for (const className of classNames) {
      if (classList.contains(className)) {
        return true;
      }
    }
    return checkParentElementHasClassNames(parentElement, classNames);
  }
  return false;
};


// type = "startOf", "endOf", daysDiff, format = nil 
export const getUTCDate = (obj = {}) => {
  let {daysDiff = 0, monthDiff = 0, yearDiff = 0, type, format} = obj
  dayjs.extend(utc);
  let dateToReturn = dayjs()
  if(type){
    dateToReturn = dateToReturn[type]('day')
  }
  if(daysDiff){
    dateToReturn = dateToReturn.add(daysDiff, "days")
  }

  if (monthDiff){
    dateToReturn = dateToReturn.add(monthDiff, "month")
  }

  if (yearDiff){
    dateToReturn = dateToReturn.add(yearDiff, "year")
  }
  
  dateToReturn = dateToReturn.utc()
  
  if(format){
    dateToReturn = dateToReturn.format(format)
  }
  console.log("dateToReturn ==>", dateToReturn, obj);
  return dateToReturn
  // dayjs().startOf('day').utc()
}

export const forceHideAnnotation = () => {
  const annotationPopup = document.querySelectorAll(".annotator-viewer")
  if(annotationPopup) {
    annotationPopup.forEach((popup) => {
      popup.classList.add("annotator-hide")
    })
  }
}

export const convertTimetoObject = time => {
  const [hours, minutes, seconds] = time.split(':').map(Number);
  return { hours, minutes, seconds }
}

export const isSelectorSupported = (selector) => {
  try {
      document.querySelector(selector)
      return true
  } catch (error) {
      return false
  }
}


export const getAIServiceBaseURL = () => {
  return import.meta.env.VITE_AI_SERVICE_URL || "https://ai-staging.assessprep.com/api/v1/"
}

export const allowedPasteCharactersLimit = 100;

export const isImageOrPdfFileType = (file) => {
  const supportedContentTypes = ["image", "pdf",];
  let fileType;
  supportedContentTypes.map((ct) => {
    if (
      (file.type && file.type.indexOf(ct) >= 0) ||
      (file.content_type && file.content_type.indexOf(ct) >= 0)
    ) {
      fileType = ct;
    }
  });
  return fileType
};

export const onDownload = (file) => {
  const link = document.createElement('a');
  link.href = file.url;
  link.download = file.name;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  message.info("Preview not supported. Please download the file for access");
};


export const mypCurriculumSubjectMap = {
  "key_concepts": {
    // Subject group wise
    "Language Acquisition": ["Communication", "Culture", "Creativity", "Connections"],
  },
  "related_concepts": {
    // Some subject wise and some subject group wise
    "Language Acquisition": ["Accent", "Audience"],
    "Economics": ["Choice", "Consumption"],
  }
}

export const mapStrandLabelsToLevelDescriptors = (aiStrands) => {
  // Iterate through each criteria in aiStrands
  let strandLevelDescriptorsMap = {};
  forEach(aiStrands, (strand) => {
    let newLevelDescriptors = [];
    let levelDescriptor = isArray(strand.level_descriptors)
      ? strand.level_descriptors[0]
      : strand.level_descriptors;
    forEach(levelDescriptor, (desc, level) => {
      let levels = level.split("-");

      // can use range if levels come in more then difference of 1 like 2-4 and not 3-4
      // levels = range(levels[0], levels[1] ? levels[1] + 1 : levels[0] + 1)

      forEach(levels, (level) => {
        newLevelDescriptors.push({
          desc,
          level: parseInt(level),
        });
      });
    });

    strandLevelDescriptorsMap[strand.strand_label] =
      newLevelDescriptors;
  });

  return strandLevelDescriptorsMap;
};


const processStrands = (criteria, criterium_associations, relation_items) => {
  const criteriaStrands = criteria.strands || [];
  const finalStrands = [];

  criterium_associations.forEach((ca) => {
    let currentGradeStrands = [];
    if (relation_items.length > 0 && criteriaStrands.length > 0) {
      currentGradeStrands = criteriaStrands.filter((s) => s.grade_code === relation_items[0].grade_code);
    }

    if (currentGradeStrands.length > 0 && currentGradeStrands[0].strands) {
      currentGradeStrands[0].strands.forEach((cgs) => {
        ca.strands.length && ca.strands.forEach((cas) => {
          if (cas.label === cgs.label) {
            finalStrands.push(cgs);
          }
        });
      });
    }

    console.log("Local Criterium Associations ===>>", finalStrands, currentGradeStrands);
  });

  return finalStrands;
}

export const getStrandsFromRubricsWithUpdatedCA = (criterium_associations, criteria, relation_items, aiGeneratedLevelDescriptors) => {
  const newFinalStrands = []
  let newCriteriumAssociations = cloneDeep(criterium_associations)
  criteria && criteria.forEach((criteria) => {
    const finalStrands = processStrands(criteria, criterium_associations, relation_items);
    newFinalStrands.push(...finalStrands)
  });
  let strandLevelDescriptorsMap = {};
  if (aiGeneratedLevelDescriptors) {
    strandLevelDescriptorsMap = mapStrandLabelsToLevelDescriptors(aiGeneratedLevelDescriptors)
  }
  
  newCriteriumAssociations.forEach((ca) => {
    ca.strands.length && ca.strands.forEach((s) => {
      newFinalStrands.forEach((fs) => {
        if (s.label === fs.label) {
          s.level_descriptors = strandLevelDescriptorsMap[fs.label] || fs.level_descriptors
          s.title = fs.title
          s.desc = fs.desc
        }
      })
    })
  })

  return newCriteriumAssociations
}

export const experienceUsersSorter = (a, b, studentNameFormat, sortOrder = "ascend") => {
  console.log("experienceUsersSorter ==>", a, b, studentNameFormat, sortOrder)
  // Compare based on studentNameFormat
  const nameA = 
    studentNameFormat === "last_name" 
      ? a.name_by_last_name 
      : a.name;

  const nameB = 
    studentNameFormat === "last_name" 
      ? b.name_by_last_name 
      : b.name;

  const nameComparison = nameA.localeCompare(nameB);

  if (nameComparison !== 0) {
    return nameComparison;
  }

  // Compare based on studentNameFormat
  const reviewerNameA = 
    (studentNameFormat === "last_name" 
      ? a.custom_fields["reviewer_name_by_last_name"] 
      : a.custom_fields["reviewer_name"]) || "";
      
  const reviewerNameB = 
    (studentNameFormat === "last_name" 
      ? b.custom_fields["reviewer_name_by_last_name"] 
      : b.custom_fields["reviewer_name"]) || "";

  console.log("reviewerNameA, reviewerNameB ==>", reviewerNameA, reviewerNameB);

  if (sortOrder === "ascend") {
    return reviewerNameA.localeCompare(reviewerNameB);
  } else {
    return reviewerNameB.localeCompare(reviewerNameA);
  }
}

// export const checkIfSEBHasDynamicConfig = (appType) => {
//   let hasDynamicConfig = false
//   if (appType === "seb") {
//     let userAgent = window.navigator.userAgent;
//     if (userAgent && userAgent.indexOf("Dynamic_AssessPrepSEB_HDC5hjdqmjypb3qxn") > -1) {
//       hasDynamicConfig = true
//     } 
//   }
//   return hasDynamicConfig
// }

export const replaceAndExtractAltTextFromImage = (text) => {
  const srcRegex = /\ssrc\s*=\s*(['"])(.*?)\1\sdata-mathml=\s*(['"])(.*?)\3/g;
  const altRegex = /<img [^>]*alt="([^"]+)"[^>]*>/g;

  let cleanText = text.replace(srcRegex, '');
  cleanText = cleanText.replace(altRegex, (match, altText) => altText);

  return cleanText.replace(/space/g, "");
}

export const getSelectedHtml = () => {
  const selection = window.getSelection();
  if (selection && selection.rangeCount > 0) {
    const range = selection.getRangeAt(0);
    const serializer = new XMLSerializer();
    let html = '';

    // Loop through nodes in the selection and serialize to HTML
    range.cloneContents().childNodes.forEach(node => {
      const serializedNode = serializer.serializeToString(node);
      html += serializedNode;
    });
    // Remove xmlns attribute from <span> elements because TTS do not interpret them as XML namespaces
    html = html.replace(/xmlns="[^"]*"/g, '');
    return replaceAndExtractAltTextFromImage(html);
  }

  return '';
}

export const getSHA256Hash = async (input) => {
  const textAsBuffer = new TextEncoder().encode(input);
  const hashBuffer = await window.crypto.subtle.digest("SHA-256", textAsBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hash = hashArray
    .map((item) => item.toString(16).padStart(2, "0"))
    .join("");
  return hash;
};


export const checkIfSEBConfigIsValid = (configKeyFromJSAPI, sebConfigKeys) => {
  let href = window.location.href;
  const host = window.location.protocol + "//" + window.location.host + "/";
  const urls = [href, host, host + "auth/login", host + "u/tests", host + "guest/join"]
  let validKey = false;
  const hashedKeys = []
  sebConfigKeys.forEach(function(configKey) {
    urls.forEach( async (url) => {
      let concatenated = url + configKey;
      let sha2 = await getSHA256Hash(concatenated);
      if (sha2 === configKeyFromJSAPI) {
        validKey = true;
      }
      hashedKeys.push({sha2: url + '-' + sha2, concatenated, sebConfigKey: configKey})
    })
  });
  return {validKey, hashedKeys};
  // return validKeyFound
}

export const loadScript = (url, callback) => {
  const script = document.createElement('script');
  script.src = url;
  script.onload = callback;
  document.body.appendChild(script);
};

export const availableLanguageOptionsForAI = ["English", "French", "Spanish", "German", "Korean", "Hindi", "Japanese", "Chinese", "Greek", "Arabic", "Russian", "Norwegian", "Portuguese", "Dutch", "Italian", "Swedish", "Polish", "Turkish", "Thai", "Indonesian", "Vietnamese", "Malay", "Danish", "Finnish", "Urdu", "Swahili"];


export const getTimeRemainingToTargetTime = (targetTime, returnType) => {
  const currentTime = new Date().getTime()
  let remainingTime = Date.parse(targetTime) - currentTime;

  let convertedTime = null
  if (returnType === "seconds") {
    convertedTime = Math.floor(remainingTime / 1000);
  } else if (returnType === "minutes") {
    convertedTime = Math.floor(remainingTime / 60000);
  } else if (returnType === "hours") {
    convertedTime = Math.floor(remainingTime / 3600000);
  } else {
    convertedTime = remainingTime
  }
  
  return convertedTime
}

export const checkPageReload = () => {
  // NOTE: checkPageReload is not supported in Mac OS Big Sur (11.7.2)
  return new Promise((resolve, reject) => {
    // Check for Performance Navigation Timing API support
    if (typeof window.PerformanceNavigationTiming !== "undefined" && typeof PerformanceObserver !== "undefined") {
      console.info("Performance Navigation Timing API ==> works fine on this browser");

      // Use PerformanceObserver to get navigation entries
      const observer = new PerformanceObserver((list) => {
        const entries = list.getEntriesByType("navigation");

        if (entries.length > 0) {
          const navigationEntry = entries[0];

          // Check if the entry is an instance of PerformanceNavigationTiming
          if (navigationEntry instanceof PerformanceNavigationTiming) {
            // Check the type of navigation
            if (navigationEntry.type === 'reload') {
              console.info("performance ==> This page is reloaded");
              resolve(true);
            } else {
              console.info("performance ==> This page is not reloaded");
              resolve(false);
            }
          } else {
            resolve(false);
          }
        } else {
          resolve(false);
        }
      });

      observer.observe({ type: "navigation", buffered: true });
    } else {
      console.info("Performance Navigation Timing API ==> is not supported on this browser");
      reject(new Error("Performance Navigation Timing API is not supported on this browser"));
    }
  });
};
export const scrollToElementWithOffset = (element, offset = 0) => {
  const elementPosition = element.getBoundingClientRect().top + window.pageYOffset; // Get the element's top position relative to the document
  const offsetPosition = elementPosition - offset; // Apply the desired offset

  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth"
  });
}

export const sortBySegmentIndexPath = (items) => {
  // const array = [[0, 0], [2, 0], [0, 2], [1, 2], [2, 2, 3], [1, 3], [1, 0, 0], [1, 0, 1]]
  // Sorted Array = [[0, 0], [0, 2], [1, 0, 0], [1, 0, 1], [1, 2], [2, 0], [2, 2, 3]]
  const sortedItems = items.sort((a, b) => {
    const aPath = a.segment_index_path || [99]; // We need to sort the items which doesn't have segment_index_path at the end
    const bPath = b.segment_index_path || [99];
    for (let i = 0; i < Math.max(aPath.length, bPath.length); i++) {
      if (aPath[i] !== bPath[i]) {
        return (aPath[i] || 0) - (bPath[i] || 0);
      }
    }
    return 0;
  });

  return sortedItems;
};

export const hasSyllabusItemsInSegments = (segments) => {
  if (!Array.isArray(segments)) {
    return false;
  }

  if (!segments.length) return false;

  const segment = segments.find(segment =>
    segment.relation_items?.[0]?.syllabus_items?.length > 0
  );
  if (segment) {
    return true;
  }
  return false;
};

export const setUserInfoOnBeforeEndSession = (actionFrom, setUserInfo, appType) => {
  // On the native side, in the event of 'Session End.' taping on this option triggers a function to save the log information.
  let actionType = (appType == "mac") ? "mac_native_quit" : "lockdown_disabled"
  let lockdownDisableAction = ""
  switch (actionFrom) {
    // TODO: separate components for each view
    case "joined":
      lockdownDisableAction = `${actionType}_from_join_view`
      break;

    case "startTest":
      lockdownDisableAction = `${actionType}_from_start_test`
      break;

    case "resumeTest":
      lockdownDisableAction = `${actionType}_from_resume_view`
      break;

    case "endTest":
      lockdownDisableAction = `${actionType}_from_end_view`
      break;

    case "submittedTest": 
      lockdownDisableAction = `${actionType}_from_submit_view`
      break;
    
    case "errorInPage": 
      lockdownDisableAction = `${actionType}_while_error_in_page`
      break;

    default:
       lockdownDisableAction = `${actionType}`
      break;
  }

  setUserInfo(
    {},
    {
      log: {
        logging: true,
        action: lockdownDisableAction
      },
    }
  );
};

export const getCurrentPlanStatus = (currentUser, options = {}) => {
  let isStandardPlan = false;
  let hasMaxOrPlusPlan = false;
  let isAPDPMAXPlan = false;
  let isAPMAXPlan = false;
  const currentUserOrg = currentUser.org || {}
  const orgSubscriptions = currentUserOrg.org_subscriptions_attributes || [];

  for (const plan of orgSubscriptions) {
    const isPlanTrialOrCustomer = ["Trial", "Customer"].includes(plan.status);
    if (["APSTD"].includes(plan.code) && isPlanTrialOrCustomer) {
      isStandardPlan = true;
    }

    if (["APMAX", "APPLUS"].includes(plan.code) && isPlanTrialOrCustomer) {
      hasMaxOrPlusPlan = true;
    }

    if (["APDPMAX"].includes(plan.code) && isPlanTrialOrCustomer) {
      isAPDPMAXPlan = true;
    }

    if (["APMAX"].includes(plan.code) && isPlanTrialOrCustomer) {
      isAPMAXPlan = true;
    }
  }

  const isNonDPProgramWithOnlyDPMaxPlan = isAPDPMAXPlan && !isAPMAXPlan && options.programmeLabel !== "IB Diploma"
  
  // return {
  //   isStandardPlan: true,
  //   hasMaxOrPlusPlan: false,
  //   isNonDPProgramWithOnlyDPMaxPlan: true
  // };
  return {
    isStandardPlan,
    hasMaxOrPlusPlan,
    isNonDPProgramWithOnlyDPMaxPlan
  };
}

export const getQuestionContent = (questionType, questionSegmentAttributes = {}) => {
  let questionContent = questionSegmentAttributes.content // default for cke_subjective
  if (questionType === "mcq_single" || questionType === "mcq_multiple") {
    const teacherJson = questionSegmentAttributes.teacher_json || {}
    const segmentData = teacherJson.segment_data || {}
    const options = segmentData.options || []
    if (options.length > 0) {
      questionContent += "\n\nAnswer options:\n"
      options.forEach((option, index) => {
        questionContent += `${getAlphabetAtIndex(index)}. ${option.content} ${option.checked ? "[Correct option]" : ""}\n`
      })
    }

  }

  return questionContent
}

export const getStudentAnswer = (questionType, userResponse) => {
  console.log("getStudentAnswer item", userResponse);
  let studentAnswer = userResponse.text_answer
  if (questionType === "mcq_single") {
    const responseJSON = userResponse.response_json || {}
    let answerOptions = responseJSON?.segment_data?.options || []
    let studentAnswerIndex = answerOptions.findIndex((option) => option.checked)
    studentAnswer = studentAnswerIndex !== -1 ? `${getAlphabetAtIndex(studentAnswerIndex)}. ${answerOptions[studentAnswerIndex].content}` : ""
  }
  return studentAnswer
}