import React, { useEffect, useState, useRef } from "react";

import { Row, Col, Image, Tooltip, Button, Collapse, Card, Alert, Space, Modal, message } from "antd";
import { CheckBox, ShowText, ShowTextContent } from "/src/components/UI/Segment/UIHelper";
import CKETextContent from "/src/views/Segments/CKETextContent/CKETextContent";
import { getParsedJSONObject } from "/src/views/Segments/InteractiveHelpers";
import { getAIServiceBaseURL, getFromLS, getUniqueId, removeHTMLTagsFromText } from "/src/lib/utils/helperMethods";
import {
  currentUserSelector,
  enabledFeaturesSelector,
  partnerNameSelector,
} from "/src/views/Auth/Login/selector";
import { useDispatch, useSelector } from "react-redux";
import RenderHtml from "/src/components/UI/RenderHtml/RenderHtml";
import { activeAdjustedExperienceSelector, aiAssistantSettingsSelector, printModeSelector, printOptionsSelector } from "/src/views/Experiences/selector";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { ImMagicWand } from "react-icons/im";
import { FaRegStopCircle } from "react-icons/fa";
import axios from "axios";
import MagicButton from "../GenerateAI/MagicButton";
import { syllabusTopicsSelector } from "/src/App/genericSelector";
import { PlusOutlined } from "@ant-design/icons";
import RateAiResult from "../GenerateAI/RateAiResult";
import UseAISolutionsButton from "../GenerateAI/UseAISolutionButton";
import { aiModelSelector } from "/src/views/Orgs/selector";
import TypingEffect from "/src/views/Experiences/ExperienceShow/Components/TypingEffect/TypingEffect";
import { EditOutlined } from "@ant-design/icons";
import { actions as segmentActions } from "/src/views/Segments/redux";
import { fetchWithStream } from "../GenerateAI/generateAIHelperMethods";
import { SolarMarkscheme } from "/src/components/UI/Icons/SolarIcons";
import AIContentImprovementFooter from "../Explanation/AIContentImprovementFooter/AIContentImprovementFooter";

const findSyllabusTopicKey = (topics, topicId, returnKey) => {
  let syllabusTopicKey;

  for (const tag of topics) {
    if (tag.id === topicId) {
      syllabusTopicKey = tag[returnKey];

      break;
    } else if (tag.children) {
      syllabusTopicKey = findSyllabusTopicKey(tag.children, topicId, returnKey);
      if (syllabusTopicKey) {
        break;
      }
    }
  }

  return syllabusTopicKey;
};

const Markscheme = (props) => {
  console.log("Markscheme props", props);
  const { setRenderMath, questionType, segmentFooterAttributes = {}, experienceViewMode, modal = false } = props;
  const { Panel } = Collapse;
  let { createMode, presentationMode } = getParsedJSONObject(props);
  const mrIntl = useTranslate()
  const activeExperience = useSelector(activeAdjustedExperienceSelector());
  let markscheme;
  if (createMode) {
    markscheme = props.value || "";
  } else {
    markscheme = props.markscheme;
  }


  const [state, setState] = useState(markscheme);
  const [loading, setLoading] = useState(false)
  const [stateUpdatedCount, setStateUpdatedCount] = useState(0);
  const [markschemeLoading, setMarkschemeLoading] = useState(false);
  const [showMarkschemeForm, setShowMarkschemeForm] = useState(true);
  const printMode = useSelector(printModeSelector());
  const allSyllabusTopics = useSelector(syllabusTopicsSelector())
  const enabledFeatures = useSelector(enabledFeaturesSelector());
  const aiModel = useSelector(aiModelSelector());
  const currentUser = useSelector(currentUserSelector());
  const aiAssistantSettings = useSelector(aiAssistantSettingsSelector());
  const sseRef = useRef(null);
  const dispatch = useDispatch();
  const [triggerCKEDataUpdate, setTriggerCKEDataUpdate] = useState()
  const printOptions = useSelector(printOptionsSelector());
  const [streamData, setStreamData] = useState({
    showCustomPrompt: false,
    showAIText: false,
    content: null,
    responseCompleted: false
  })
  const [showModal, setShowModal] = useState(false)

  let experienceRelationItems =  activeExperience && activeExperience.relation_items && activeExperience.relation_items[0]
  const segment = segmentFooterAttributes.segment || {};
  const currentQuestionContent = props.questionContent || segment.question_segment_attributes?.content || "";
  console.log("currentQuestionContent==>", segment);
  
  const showMarkschemeEditButton = activeExperience.status != "draft"
  && experienceViewMode === "apEditTest"
  && activeExperience.submissions_count > 0;

  let customFields = props.formInstance && props.formInstance.getFieldValue(["custom_fields"]);
  let promptList = ["Make concise", "Make detailed", "Emphasize keywords"];
  let allowImproveAiContent = enabledFeatures.allow_improve_ai_content;
  let showAiFooter = allowImproveAiContent ? (state && !streamData.isStreaming) : (streamData.showAIText && !streamData.isStreaming);
  let aiGeneratedContent = allowImproveAiContent ? state : streamData.content;


  let content;
  if (presentationMode) {
    content = props.markscheme;
  } else {
    content = state;
  }
  const isQuestionContentExist = currentQuestionContent !== "" ? true : false;
  useEffect(() => {
    if (setRenderMath) {
      setRenderMath(getUniqueId());
    }
  }, []);

  useEffect(() => {
    if (createMode) {
      if (props.onChange) {
        props.onChange(state);
      }
    }
  }, [stateUpdatedCount]);

  const openModal = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setShowModal(true)
  }

  const saveState = (newState) => {
    setState(newState);
    setStateUpdatedCount((stateUpdatedCount) => stateUpdatedCount + 1);
  };

  const onTextChanged = (data) => {
    const newState = data;
    console.log("onTextChanged==>", data);
    saveState(newState);
  };

  const handleChange = () => {
    if (setRenderMath) {
      setRenderMath(getUniqueId())
    }
  }

  const stopGeneratingMarkscheme = () => {
    setStreamData((prevData) => ({
      ...prevData,
      isStreaming: false,
      showAIText: false,
    }));
    setMarkschemeLoading(false)
    if (sseRef && sseRef.current) {
      sseRef.current.abortSse();
    }
  };
  
  const handleCancel = () => {
    setShowModal(false)
    stopGeneratingMarkscheme()
    setState(markscheme);
  }

  const generateMarkscheme = async (extraDataToPost = {}) => {
    setMarkschemeLoading(true);
    const token = getFromLS("token");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    let dataToPost = {
      solution_type: "markscheme",
      question_content: currentQuestionContent,
      // question_content: removeHTMLTagsFromText(props.questionContent, true),
    };

    let questionType = props.formInstance?.getFieldValue([
      "question_segment_attributes",
      "question_type",
    ]) || segment.question_segment_attributes?.question_type;
    let points = props.formInstance?.getFieldValue(["points"]) ?? segment.points;
    if (questionType?.includes("mcq")) {
      let mcqOptions = props.formInstance?.getFieldValue([
        "question_segment_attributes",
        "teacher_json",
        "segment_data",
        "options",
      ]);
      let formattedOptions = mcqOptions
        .map((option, index) => `${index + 1}. ${option.content}`)
        .join("\n");
      dataToPost.question_content = `
        ${dataToPost.question_content}
        ${formattedOptions}
      `;
    }

    if (enabledFeatures.ai_assistant_settings && aiAssistantSettings?.authoring) {
      dataToPost.output_language = aiAssistantSettings?.authoring?.output_language;
      // TBD: Add markscheme length
      // dataToPost.explanation_length = aiAssistantSettings?.authoring?.explanation_length;
    }

    dataToPost = {
      ...dataToPost,
      ...extraDataToPost,
      question_type: questionType,
      points: parseFloat(points),
    };

    let syllabusTopicLabel;
    let syllabusTopicUUID;
    let segmentRelationItems = props.formInstance?.getFieldValue(["relation_items"]) ?? segment.relation_items;
    if (segmentRelationItems && segmentRelationItems[0]) {
      if (
        segmentRelationItems[0].syllabus_content_ids &&
        segmentRelationItems[0].syllabus_content_ids[0]
      ) {
        syllabusTopicUUID = findSyllabusTopicKey(
          allSyllabusTopics,
          segmentRelationItems[0].syllabus_content_ids[0],
          "uuid"
        );
        syllabusTopicLabel = findSyllabusTopicKey(
          allSyllabusTopics,
          segmentRelationItems[0].syllabus_content_ids[0],
          "label"
        );
        dataToPost = {
          ...dataToPost,
          syllabus_topic_label: syllabusTopicLabel,
          syllabus_topic_uuid: syllabusTopicUUID,
        };
      }
    }
    let criteriumAssociationsAttributes = props.formInstance?.getFieldValue([
      "criterium_associations_attributes",
    ]) ?? segment.criterium_associations_attributes
    let criteria;
    if (criteriumAssociationsAttributes) {
      criteria = criteriumAssociationsAttributes.map((item) => ({
        id: item.criterium_id,
        label: item.custom_fields.label,
        points: parseFloat(item.points),
      }));
    }
    if (criteria) {
      dataToPost = {
        ...dataToPost,
        criteria: criteria,
      };
    }
    if (experienceRelationItems) {
      dataToPost = {
        ...dataToPost,
        programme_id: experienceRelationItems.programme_id,
        subject_id: experienceRelationItems.subject_id,
        subject_label: experienceRelationItems.subject_items[0].label,
        programme_label: activeExperience.questionbank
          ? activeExperience.questionbank.custom_fields.programme_name
          : activeExperience.custom_fields.org_programme_item_label,
      };
    }

    if(aiModel) {
      dataToPost.model = aiModel
    }

    if(streamData.showCustomPrompt) {
      dataToPost.prompt = streamData.customPromptContent;
    }
    
    try {
      const fetchOptions = {
        url: `${getAIServiceBaseURL()}/generate-solutions`,
        dataToPost: dataToPost,
        setLoading: setMarkschemeLoading,
        content: "",
        sseRef: sseRef,
        // onTextChanged: onTextChanged,
        successCallback: (data, responseStatus) => {
          setStreamData((prevData) => ({
            showAIText: true,
            content: data,
            isStreaming: true,
            responseCompleted: responseStatus === "stop"
          }))
        },
        setTriggerCKEDataUpdate: setTriggerCKEDataUpdate,
      };
      fetchWithStream(fetchOptions);
      let newCustomFieldsObject = customFields && typeof customFields === 'object' ? {...customFields} : {}
      newCustomFieldsObject = {
        ...newCustomFieldsObject,
        ai_used: {
          ...newCustomFieldsObject.ai_used,
          markscheme: true
        }
      }
      props.formInstance?.setFieldValue(["custom_fields"], newCustomFieldsObject);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDiscard = () => {
    setStreamData({ ...streamData, showAIText: false });
  };

  const handleMagicButtonClick = (extraDataToPost = {}) => {
    if (streamData.isStreaming) {
      stopGeneratingMarkscheme();
    } else {
      generateMarkscheme(extraDataToPost);
    }
  };

  const handleUseButtonClick = () => {
    setStreamData && setStreamData((prevData) => ({
      ...prevData,
      showAIText: false,
    }));
  };

  let finalRender = [];
  if ((createMode && showMarkschemeForm) || showModal) {
    if (questionType === "cke_subjective" && (enabledFeatures.generate_markscheme_with_ai || import.meta.env.VITE_MODE === "development")) {
      if (streamData.showAIText) {
        // when text is streaming from AI then show it in a box and provide a button to use it in the markscheme CKE
        if (streamData.isStreaming) {
          // Doing this to render the typing effect again when streamData gets false or when response completed, as it was previously storing the local state of the text/streamData.content which we were sending as a propdue to which the content was not getting cleared when click generate again
          finalRender.push(
            <TypingEffect
              isStreamStarted={streamData.isStreaming}
              text={streamData.content}
              typingSpeed={20}
              setIsStreamStarted={() => {
                if (allowImproveAiContent) {
                  onTextChanged(streamData.content)
                  setStreamData((prevData) => ({
                    ...prevData,
                    showAIText: false,
                    isStreaming: false,
                  }));
                } else {
                  setStreamData((prevData) => ({
                    ...prevData,
                    isStreaming: false,
                  }))
                }
              }}
              className={"text-streaming-box"}
              responseCompleted={streamData.responseCompleted}
            />
          );
        } else {
          finalRender.push(
            <RenderHtml
              text={streamData.content}
              extraClassname="text-streaming-box"
            />
          );
        }
      } else {
        finalRender.push(
          <CKETextContent
            ckeConfig={{ triggerCKEDataUpdate: triggerCKEDataUpdate }}
            value={content}
            placeholder={mrIntl("Markscheme.provide_grading_guidelines_key_points_and_criteria_for_awarding_marks")}
            onChange={(data) => onTextChanged(data)}
          ></CKETextContent>
        );
      }
      if (streamData.showCustomPrompt) {
        finalRender.push(
          <CKETextContent
            ckeConfig={{ triggerCKEDataUpdate: triggerCKEDataUpdate }}
            placeholder={mrIntl("TextAnswerExplanation.add_custom_prompt")}
            value={streamData.customPromptContent}
            onChange={(data) => setStreamData({
              ...streamData,
              customPromptContent: data
            })}
          ></CKETextContent>
        );
      }
      finalRender.push(
        <div>
          {showAiFooter ? <AIContentImprovementFooter
            onTextChanged={onTextChanged}
            handleUseButtonClick={handleUseButtonClick}
            onMagicButtonClick={handleMagicButtonClick}
            handleDiscard={handleDiscard}
            promptList={promptList}
            showImproveButton={aiGeneratedContent && !streamData.isStreaming && isQuestionContentExist}
            aiGeneratedContent={aiGeneratedContent}
            isStreaming={streamData.isStreaming}
            showUseAndDiscardButton={!allowImproveAiContent}
            improveInputPlaceholder={mrIntl("Markscheme.type_instructions_to_improve_the_markscheme_or_select_from_the")}
          /> :
            <>
              {currentUser.role === "qb_author" && <CheckBox onChange={(e) => setStreamData({
                ...streamData,
                showCustomPrompt: e.target.checked
              })} />}
              <MagicButton
                tooltipTitle={streamData.isStreaming ? mrIntl("CommonText.stop_generating") : !isQuestionContentExist ? mrIntl("Markscheme.question_content_required_to_generate_marking_scheme") : mrIntl("Markscheme.auto_generate_markscheme")}
                loading={markschemeLoading}
                shape="default"
                type="text"
                feature_code="generate_markscheme_with_ai"
                onClick={() => handleMagicButtonClick()}
                icon={
                  streamData.isStreaming ? <FaRegStopCircle /> : <ImMagicWand />
                }
                disabled={!isQuestionContentExist}
                extraClassName="float-right"
              />
            </>}
        </div>
        // <div className="ai-generated-info-msg">
        //   <Alert
        //     showIcon
        //     type="info"
        //     message={mrIntl("Markscheme.use_aigenerated_mark_schemes_as_guides_review_and_adjust_to")}
        //   />
        //   <Space>
        //     {streamData.showAIText && (
        //       <>
        //         {!streamData.isStreaming && <RateAiResult generatedData={streamData.content} action="generate_solutions" />}
        //         {!streamData.isStreaming && (
        //           <UseAISolutionsButton 
        //           loading={streamData.isStreaming} 
        //           setStreamData={setStreamData} 
        //           onTextChanged={() => onTextChanged(streamData.content)} 
        //           />
        //         )}
        //         {/* <Button
        //           type="primary"
        //           shape="round"
        //           loading={streamData.isStreaming}
        //           style={{ marginRight: "5px" }}
        //           icon={<PlusOutlined />}
        //           onClick={() => {
        //             setStreamData((prevData) => ({
        //               ...prevData,
        //               showAIText: false,
        //             }))
        //             onTextChanged(streamData.content)
        //           }}>{mrIntl("Markscheme.use")}</Button> */}
        //       </>)}
        //     {currentUser.role === "qb_author" && <CheckBox onChange={(e) => setStreamData({
        //       ...streamData,
        //       showCustomPrompt: e.target.checked
        //     })} />}
        //     <MagicButton
        //       tooltipTitle={streamData.isStreaming ? mrIntl("CommonText.stop_generating") : !isQuestionContentExist ? mrIntl("Markscheme.question_content_required_to_generate_marking_scheme") : mrIntl("Markscheme.auto_generate_markscheme")}
        //       loading={markschemeLoading}
        //       disabled={!isQuestionContentExist}
        //       icon={
        //         streamData.isStreaming ? <FaRegStopCircle /> : <ImMagicWand />
        //       }

        //       type="text"
        //       feature_code="generate_markscheme_with_ai"
        //       onClick={() => streamData.isStreaming ? stopGeneratingMarkscheme() : generateMarkscheme()}
        //     />
        //   </Space>
        // </div>
      )
    } else {
      finalRender.push(
        <CKETextContent
          ckeConfig={{ triggerCKEDataUpdate: triggerCKEDataUpdate }}
          value={content}
          placeholder={mrIntl("Markscheme.provide_grading_guidelines_key_points_and_criteria_for_awarding_marks")}
          onChange={(data) => onTextChanged(data)}
        ></CKETextContent>
      );
    }
  } else {
    if (content) {
      if (printMode) {
        if (printOptions.with_markscheme === "true") {
          finalRender.push(
            <Card key={`markscheme-card-${props.id}`} title="Markscheme" className="print-markscheme">
              <RenderHtml text={markscheme} />
            </Card>
          );
        }
      } else if (showModal) {
        finalRender = <>{finalRender}</>;
      } else {
        const panelHeader = <>
          {mrIntl("Markscheme.markscheme_header")}
          {/* {showMarkschemeEditButton && <Button className={"markscheme-edit-button"} type="text" icon={<EditOutlined />} onClick={(e) => openModal(e)}></Button>} */}
        </>
        finalRender.push(
          <Collapse
            key={`markscheme-collapse-${props.id}`}
            defaultActiveKey={printMode ? ["1"] : []}
            ghost
            className={`teacher-explanation-accordion`}
            onChange={(e) => handleChange()}
          >
            <Panel
              header={panelHeader}
              key="1"
              forceRender={true}
            >
              <ShowText style={{ marginLeft: "10px" }}>
                <RenderHtml text={content} />
              </ShowText>
            </Panel>
          </Collapse>
        );
      }
    }
  }
  console.log("state value berfor return====>>>", state)

  return (
    modal ? (
      <>
        <Tooltip title={props.markscheme ? mrIntl("Markscheme.edit_markscheme") : mrIntl("Markscheme.add_markscheme")}>
          <Button
            type="text"
            shape="circle"
            className={`markscheme-button`}
            icon={<SolarMarkscheme />}
            onClick={(e) => openModal(e)}
          >
          </Button>
        </Tooltip>
        {showModal ? <Modal
          title={mrIntl("Markscheme.markscheme_header")}
          open={showModal}
          okButtonProps={{
            disabled: streamData.isStreaming,
            loading: loading
          }}
          okText="Save"
          onOk={() => {
            setLoading(true)
            dispatch(segmentActions.update(
              {
                id: segment.id,
                question_segments_attributes: [{
                  ...segment.question_segment_attributes,
                  markscheme: state
                }],
                settings: {
                  ...segment.settings,
                  include_markscheme: state ? true : false // Need to set the include_markscheme false if we try to save empty field
                }
              },
              {
                success: {
                  showMessage: true,
                  message: mrIntl("CommonText.item_successfully_updated"),
                },
                successCallback: () => {
                  setShowModal(false);
                  stopGeneratingMarkscheme();
                  setLoading(false)
                },
                errorCallback: (error) => {
                  console.log("errors in updating item", error);
                  setLoading(false)
                },
                error: {
                  showMessage: true,
                  message: mrIntl("CommonText.error_in_updating_item"),
                }
              }
            ));
          }}
          onCancel={handleCancel}
          className="teachers-explanation-markscheme-modal"
        >
          {finalRender}
        </Modal> : null}
      </>
    ) : (
      finalRender
    )
  )
};
export default Markscheme;
