import React, { useState } from "react";

import { Button, Space, Tooltip } from "antd";
import { MediaButtonsContainer } from '/src/components/UI/Segment/UIHelper'
import MediaUI from "/src/components/UI/Media/MediaUI"
import { PictureOutlined, AudioOutlined } from "@ant-design/icons"
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const MediaButtons = props => {
  console.log("Media Buttons props", props);
  const { mediaButtonsConfig = {image: true, audio: true}, setPopoverSetting, onSaveMedia } = props
  const mrIntl = useTranslate();

  const imageConfig = {
    from: "computer",
    title: mrIntl("MediaButtons.insert_image"),
    okText: mrIntl("CommonText.add_image"),
    content_type: "image",
    supportedTypes: "image/jpg, image/jpeg, image/png, image/gif, image/bmp",
    supportedFormats: "jpg, jpeg, png, gif, bmp",
  };

  const audioConfig = {
    from: "audiorecorder",
    title: mrIntl("CommonText.insert_audio"),
    okText: mrIntl("CommonText.add_audio_button"),
    content_type: "audio",
    supportedTypes: "audio/mp3, audio/ogg, audio/mpeg",
    supportedFormats: "mp3, ogg",
  };

  const mediaFormInitialState = {
    modal: true,
    loading: false,
    visible: false,
    from: 'computer',
    currentMediaType: '',
    okDisabled: true,
    triggerSaveAction: 0,
    limit: 1,
  }
  const [mediaFormSetting, setMediaFormSetting] = useState(mediaFormInitialState)

  const getMediaButtonConfig = (mediaType) => {
    if (mediaType === "image") {
      return imageConfig;
    }
    else if (mediaType === "audio") {
      return audioConfig;
    }
    return {}
  }

  const openMediaModal = (type) => {

    setMediaFormSetting(prev => ({
      ...prev,
      okDisabled: type !== prev.type,
      visible: true,
      currentMediaType: type,
      cancelText: mrIntl("CommonText.cancel"),
      selectConfig: {
        multiple: false,
        maxSize: "20 MB",
      },
      ...(getMediaButtonConfig(type))
    }));

    setPopoverSetting({
      visible: false
    })
  }

  const beforeOnSaveMedia = (files) => {
    setMediaFormSetting({
      ...mediaFormSetting,
      visible: false,
      triggerSaveAction: 0,
    })

    const limit = mediaFormSetting.limit
    if (limit) {
      files = files.slice(-limit)
    }
    console.log("Before save media modal ==>", files, mediaFormSetting)
    onSaveMedia(files)
  }

  const getMediaForm = (type) => {
    let audiorecorder = false
    let computer = true
    let url = true

    if (type == 'audio') {
      audiorecorder = true
      url = false
    }

    return (<MediaUI
      audiorecorder={audiorecorder}
      computer={computer}
      url={url}
      mediaFormSetting={mediaFormSetting}
      setMediaFormSetting={(data) => setMediaFormSetting(data)}
      onSaveMedia={(files) => beforeOnSaveMedia(files)}
    ></MediaUI>)
  }

  console.log("media buttons -- media form setting before render ==>", mediaFormSetting)
  return (
    <span>
      {mediaFormSetting.visible && getMediaForm(mediaFormSetting.currentMediaType)}
      <MediaButtonsContainer
        className='media-buttons'
      >
        <Space direction="vertical">
          {mediaButtonsConfig.image &&
            <Tooltip title={mrIntl("MediaButtons.insert_image")} placement="left">
              <Button
                onClick={(e) => openMediaModal('image')}
                // disabled={props.disabled}
                // type="primary"
                type="text"
                shape="circle"
                size="large"
              // ghost
              >
                {/* <AiOutlinePicture /> */}
                <PictureOutlined />
              </Button>
            </Tooltip>
          }
          {mediaButtonsConfig.audio &&
            <Tooltip title={mrIntl("CommonText.insert_audio")} placement="left">
              <Button
                onClick={(e) => openMediaModal('audio')}
                // disabled={props.disabled}
                type="text"
                // type="primary"
                shape="circle"
                // ghost
                size="large"
              >
                {/* <AiOutlineAudio /> */}
                <AudioOutlined />
              </Button>
            </Tooltip>
          }
        </Space>
      </MediaButtonsContainer>
    </span>
  );
}
export default MediaButtons;