import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb, Row, Col, Pagination, Button, List, Space, Dropdown, Menu, Input, Modal, Alert, Tag, Typography, Tooltip, message, Skeleton, Select } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { DownOutlined, PlusOutlined, DownloadOutlined, FilterOutlined } from '@ant-design/icons';
// import Spinner from '../../../UI/Spinner/Spinner';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import MrTranslate, { useTranslate } from '/src/lib/MrTranslate/MrTranslate';
import { dropDownTrigger, userRoleMap } from '/src/lib/utils/helperMethods';
import { useDispatch, useSelector } from 'react-redux';
import { actions as userReduxActions } from "/src/views/Users/redux";
import { actions as groupReduxActions } from "/src/views/Groups/redux";
import { capitalize } from "lodash";
import { orgProgrammesSelector } from '/src/views/OrgProgrammes/selector';
import { gradesRequestSelector, orgsRequestSelector } from '/src/App/genericSelector';
import { bulkLoadingSelector } from '/src/views/Users/selector';
import BulkImportUsers from '/src/views/Users/BulkImportUsers/BulkImportUsers';
import BulkImportGroups from '/src/views/Groups/BulkImportGroups/BulkImportGroups';
import {actions} from "../Users/redux";
import { SelectGrade, SelectProgram } from "/src/components/AppSpecific/SelectSubject/SelectSubject";
import SelectRoleDropdown from '/src/components/AppSpecific/SelectRole/SelectRoleDropdown';
import { enabledFeaturesSelector } from '/src/views/Auth/Login/selector';
import DataEmptyState from './DataEmptyState';
const { Text } = Typography;


const BulkArchiveButton = (props) => {
  const { config } = props
  const { params, resourceName, pagination } = config
  const paramsToUse = {...params}
  delete paramsToUse.page  // add more to ignore here
  const { role, by_grade_ids, by_org_programme_id, by_org_id } = paramsToUse
  const [showBulkArchiveModal, setShowBulkArchiveModal] = useState(false);
  const [showArchiveAllButton, setShowArchiveAllButton] = useState(false);
  const dispatch = useDispatch()
  const { Text, Paragraph } = Typography;
  const orgProgrammesFromGenericRedux = useSelector(orgProgrammesSelector())
  const orgsFromGenericRedux = useSelector(orgsRequestSelector())
  const orgGradesFromGenericRedux = useSelector(gradesRequestSelector())
  const bulkLoading = useSelector(bulkLoadingSelector());
  const mrIntl = useTranslate();
  

  let finalRender = []
  

  const orgId = parseInt(by_org_id) // in school case, get from currentUser.org_id, hide archive button if no orgId

  const getLabel = (k, v) => {
    console.log("getLabel", k, v);
    if(k === "by_org_id"){
      return (orgsFromGenericRedux.find((org) => org.id === parseInt(v)) || {}).name
    } else if (k === "by_role"){
      return capitalize(v)
    } else if (k === "by_org_programme_id"){
      return (orgProgrammesFromGenericRedux.find((op) => op.id === parseInt(v)) || {}).name
    } else if (k === "by_grade_ids"){
      // return (orgGradesFromGenericRedux.filter((g) => g.id === parseInt(v)) || {}).label
      let matchedGrades = orgGradesFromGenericRedux.filter((g) => v.map((id) => parseInt(id)).includes(g.id) )
      return matchedGrades.map(grade => grade.label).join(", ")
    } else if (k === "search"){
      return `Search: ${v}`
    }
  }

  const bulkArchive = () => {
    // Post to org route to bulkArchive with org_id, resourceName and relevant params based on resourceName 
    let actionsToUse
    if(resourceName === "users"){
      actionsToUse = userReduxActions
    }else if(resourceName === "groups"){
      actionsToUse = groupReduxActions
    }
    console.log("BulkActions calling bulkArchive redux action", paramsToUse);
    dispatch(actionsToUse.bulkDelete(
      {paramsToUse}, 
      {
        successCallback: () => {setShowBulkArchiveModal(false)},
        errorCallback: () => {setShowBulkArchiveModal(false)}
      }
    ))
  }
  
  let bulkArchiveModal = <Modal
    key="bulk-archive-modal"
    title="Bulk archive"
    open={showBulkArchiveModal}
    footer={null}
    onOk={() => setShowBulkArchiveModal(false)}
    onCancel={() => setShowBulkArchiveModal(false)}
    // width={700}
  >
    {showBulkArchiveModal && <Row>
      {orgId ? 
        <Space direction={"vertical"}>
          <Alert 
            message="Make sure you have selected the items you want to archive. Only School, role, program, grade, search will be considered"
            showIcon
            type={"error"}
          />

          <h3>Archiving {pagination.totalCount} {resourceName} based on these filters:</h3>
          <Space wrap={true}>{Object.keys(paramsToUse).map((k) => { return paramsToUse[k] ? <Tag>{getLabel(k, paramsToUse[k])}</Tag> : ""})}</Space>
          
          <Paragraph>
            {/* <blockquote>Only School, role, program, grade, search will be considered</blockquote> */}
            <blockquote>{JSON.stringify(paramsToUse)}</blockquote>
            {/* <pre>{JSON.stringify(paramsToUse)}</pre> */}
          </Paragraph>


          {/* <Alert
            message={`${capitalize(resourceName)} successfully archived.`}
            showIcon
            type="success"
          /> */}
          <Space.Compact>
            <Input disabled={bulkLoading} placeholder="Type here" addonBefore="Type 'Confirm' to proceed" onChange={(e) => {console.log("input e", e, e.target.value); if(e.target.value === "Confirm"){ setShowArchiveAllButton(true) } else { setShowArchiveAllButton(false) }  }} />
            <Button type="primary" danger loading={bulkLoading} disabled={!showArchiveAllButton} onClick={() => bulkArchive()}>{mrIntl("CustomExperienceCrudList.archive_all")}</Button>
          </Space.Compact>

        </Space>
      :
        "Select org and relevant filters first"
      }
    </Row>}
  </Modal>

  finalRender.push(bulkArchiveModal)
  
  finalRender.push(
    <Button
      key="menu-item-archive-all"
      title={"Archive all"}
      type="text"
      style={{backgroundColor: 'transparent'}}
      disabled={pagination.totalCount > 0 ? false : true}
      // icon={<CheckCircleTwoTone twoToneColor="#52c41a" />}
      onClick={() => {
        setShowBulkArchiveModal(true)
      }}
    >
      {mrIntl("CustomExperienceCrudList.archive_all_filter_first")}
    </Button>
    // <Button
    //   type="text"
    //   onClick={() => {
    //     setShowBulkArchiveModal(true)
    //   }}
    // >
    //   Archive all (Filter first)
    // </Button>
  )

  return finalRender
}

const ExportUsersUsageData = ({ config }) => {
  const { params, resourceName, pagination, currentUser } = config;
  const { by_org_id } = params;
  
  const orgId = by_org_id ? parseInt(by_org_id) : currentUser.org_id;
  
  const [downloadExportFileUrl, setDownloadExportFileUrl] = useState(null);
  const [showBulkUserModal, setShowBulkUserModal] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);

  const mrIntl = useTranslate();
  const dispatch = useDispatch();

  const handleCancel = () => {
    setShowBulkUserModal(false);
    setDownloadExportFileUrl(null);
    setShowBulkUserModal(false);
  }

  const handleOk = () => {
    setExportLoading(true);
    const paramsToSend = { by_org_id: orgId };

    dispatch(
      actions.exportUsersUsageToExcel(paramsToSend, {
        successCallback: (url) => {
          setExportLoading(false);
          setDownloadExportFileUrl(url);
          message.success(mrIntl("CustomExperienceCrudList.users_exported_successfully"));
        },
        errorCallback: (error) => {
          console.error("Error fetching users:", error);
          setExportLoading(false);
          setDownloadExportFileUrl(null);          
        }
      })
    );
  }

  return (
    <>
      <Button
        key="menu-item-export-usage"
        title={mrIntl("CustomExperienceCrudList.export_usage")}
        type="text"
        style={{ backgroundColor: 'transparent' }}
        onClick={() => setShowBulkUserModal(true)}
      >
        {mrIntl("CustomExperienceCrudList.export_usage_data")}
      </Button>

      <Modal
        key="bulk-export-user-modal"
        title={mrIntl("CustomExperienceCrudList.export_users_usage_data")}
        open={showBulkUserModal}
        onCancel={handleCancel}
        footer={[
          <Button key="bulk-export-cancel-button" onClick={handleCancel}>
            {mrIntl("CustomExperienceCrudList.cancel")}
          </Button>,
          <Button
            key="bulk-export-ok-button"
            type="primary"
            icon={downloadExportFileUrl && <DownloadOutlined />}
            loading={exportLoading}
            href={downloadExportFileUrl || "#"}
            onClick={handleOk}
          >
            {downloadExportFileUrl ? "Download" : "Export"}
          </Button>
        ]}
      >
        {mrIntl("CustomExperienceCrudList.click_the_export_button_to_export_the_users_usage_data")}
      </Modal>
    </>
  );
};

const BulkExportButton =  (props) => {
  console.log("BulkExportButton props", props);
  const { config } = props;

  const { params, resourceName, pagination, currentUser, location } = config
  const paramsToUse = { ...params }
  const { by_org_id, by_role } = paramsToUse;
  
  const dispatch = useDispatch()
  const orgsFromGenericRedux = useSelector(orgsRequestSelector())
  const [showBulkUserModal, setShowBulkUserModal] = useState(false);
  const [downloadExportFileUrl, setDownloadExportFileUrl] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [selectedGrade, setSelectedGrade] = useState([]);
  const [exportLoading, setExportLoading] = useState(false);

  const mrIntl = useTranslate();

  let finalRender = []
  const orgId = by_org_id ? parseInt(by_org_id): currentUser.org_id;

  const handleCancel = () => {
    setShowBulkUserModal(false);
    setSelectedRole(null);
    setSelectedProgram(null);
    setSelectedGrade([]);
    setDownloadExportFileUrl(null);
  }

  const handleOk = () => {
    setExportLoading(true);
    const paramsToSend = {
      by_org_id: orgId,
      by_role: selectedRole,
    };
    if (selectedRole === "student") {
      if (selectedProgram) {
        paramsToSend.by_org_programme_id = selectedProgram;
      }
      if (selectedProgram && selectedGrade && selectedGrade.length > 0) {
        paramsToSend.by_grade_ids = selectedGrade;
      }
    }
    if (downloadExportFileUrl) {
      setExportLoading(false);
      return;
    }
    dispatch(
      actions.exportUsersToExcel(paramsToSend, {
        successCallback: (url) => {
          setExportLoading(false);
          console.log("url =====>", url);
          setDownloadExportFileUrl(url);
          message.success(mrIntl("CustomExperienceCrudList.users_exported_successfully"));
        },
        errorCallback: (error) => {
          console.error("Error fetching users:", error);
          setExportLoading(false);
          setDownloadExportFileUrl(null);          
        }
      })
    );
  };


  let bulkExportModal = (
    <Modal
      key="bulk-export-user-modal"
      title="Bulk export users"
      open={showBulkUserModal}
      footer={[
        <Button
          key={`bulk-export-cancel-button-${orgId}`}
          onClick={() => handleCancel()}
        >
          {mrIntl("CustomExperienceCrudList.cancel")}
        </Button>,
        <Button
        type="primary"
        icon={downloadExportFileUrl && <DownloadOutlined />}
        loading={exportLoading}
        disabled={(selectedRole === null || selectedRole === undefined)}
        href={downloadExportFileUrl ? downloadExportFileUrl : "#"}
        onClick={() => handleOk()}
      >
        {downloadExportFileUrl ? "Download" : "Export"}
      </Button>
      ]}
      onOk={() => setShowBulkUserModal(false)}
      onCancel={handleCancel}
    >
      {showBulkUserModal && (
        <Row>
          {orgId ? (
            <Space direction={"vertical"}>
              <SelectRoleDropdown
                value={selectedRole}
                onChange={(value) => {
                  setDownloadExportFileUrl(null);
                  setSelectedGrade([]);
                  setSelectedProgram(null);
                  setSelectedRole(value);
                }} 
              />
              {selectedRole === "student" && (
                <SelectProgram
                  value={selectedProgram}
                  onChange={(value) => {
                    setDownloadExportFileUrl(null);
                    setSelectedGrade([]);
                    setSelectedProgram(value);
                  }}
                  config={{
                    params: { by_org_id: orgId },
                    widgetConfig: {
                      style: { width: "100%" },
                    },
                  }}
                />
              )}
              {selectedProgram && (
                <SelectGrade
                  value={selectedGrade}
                  onChange={(value) => {
                    if (downloadExportFileUrl) {
                      setDownloadExportFileUrl(null);
                    }
                    setSelectedGrade(value);
                  }}
                  config={{
                    params: { by_org_programme_id: selectedProgram },
                    widgetConfig: {
                      style: { width: "100%" },
                      mode: "multiple",
                    },
                  }}
                />
              )}
            </Space>
          ) : (
            mrIntl("CustomExperienceCrudList.select_org_and_relevant_filters_first")
          )}
        </Row>
      )}
    </Modal>
  );

  finalRender.push(bulkExportModal)
  console.log("checking values==>", location.search)
  // const role = location.search.replace(/\?by_role=/, "")
  finalRender.push(
    <Tooltip>
      <Button
      key="menu-item-export-all"
      type="text"
      title={"Export all"}
      style={{backgroundColor: 'transparent'}}
      // disabled={location.search === "" ? true : false}
      onClick={() => {
        setShowBulkUserModal(true)
      }}
    >{mrIntl("CustomExperienceCrudList.export_users")}</Button>
    </Tooltip>
  )

  return finalRender
}


const BulkSendWelcomeEmail = ({ config }) => {
  const { params, currentUser } = config;
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedRoles, setSelectedRole] = useState([]);
  const dispatch = useDispatch();
  const mrIntl = useTranslate();
  const orgId = params.by_org_id ? parseInt(params.by_org_id) : currentUser.org_id;

  const handleCancel = () => {
    setSelectedRole([]);
    setShowWelcomeModal(false);
  };

  const handleOk = () => {
    setLoading(true);
    const paramsToSend = { role: selectedRoles, bulk: true };
    dispatch(
      userReduxActions.sendWelcomeEmail(paramsToSend, {
        successCallback: () => {
          setLoading(false);
          setShowWelcomeModal(false);
        },
        errorCallback: (error) => {
          setLoading(false);
        },
      })
    );
  };

  return (
    <>
      {showWelcomeModal && (
        <Modal
          key="welcome-modal"
          title={mrIntl("CustomExperienceCrudList.send_welcome_email_to_selected_users")}
          open={showWelcomeModal}
          onCancel={handleCancel}
          footer={[
            <Button key="cancel" onClick={handleCancel}>
              {mrIntl("CommonText.cancel")}
            </Button>,
            <Button key="confirm-email-button" loading={loading} disabled={selectedRoles.length === 0} type="primary" onClick={handleOk}>
              {mrIntl("CustomExperienceCrudList.send")}
            </Button>,
          ]}
        >
          <Row className="m-t-10">
            <Col span={20}>
              <div>{mrIntl("CustomExperienceCrudList.select_roles")}</div>
              <SelectRoleDropdown onChange={setSelectedRole} mode="multiple" style={{ width: '300px' }} />
            </Col>
          </Row>
        </Modal>
      )}
      <Tooltip>
        <Button
          key="menu-item-welcome-email"
          type="text"
          title={mrIntl("CustomExperienceCrudList.send_welcome_emails")}
          style={{ backgroundColor: "transparent" }}
          onClick={() => setShowWelcomeModal(true)}
        >
          {mrIntl("CustomExperienceCrudList.send_welcome_emails")}
        </Button>
      </Tooltip>
    </>
  );
};

const BulkActions = (props) => {
  const { params, resourceName, pagination, currentUser, location } = props;
  let finalRender = [];
  const mrIntl = useTranslate();
  const enabledFeatures = useSelector(enabledFeaturesSelector());
  const isAdminWithManagePermission = currentUser.role === "admin" && currentUser.permission?.manage_permissions?.list;

  const showArchiveAll = enabledFeatures.archive_all_users || currentUser.role === "superadmin";
  const showExportAllUsers = (enabledFeatures.export_users || import.meta.env.VITE_MODE === "development") && resourceName === "users";  
  const showExportUsageData = (enabledFeatures.export_users_usage_data || import.meta.env.VITE_MODE === "development") && resourceName === "users"; 
  const showBulkImport = (enabledFeatures.bulk_import_users || import.meta.env.VITE_MODE === "development") && resourceName === "users";
  const showWelcomeEmail = enabledFeatures.send_welcome_email_to_user && isAdminWithManagePermission && resourceName === "users";

  const showBulkImportClasses = (enabledFeatures.bulk_import_groups || import.meta.env.VITE_MODE === "development") && resourceName === "groups";

  const showActionsDropdown = showArchiveAll || showExportAllUsers || showBulkImport || showExportUsageData || showBulkImportClasses || showWelcomeEmail;

  const actionsMenu = (
    <Menu className='bulk-actions-menu'>
      {showArchiveAll && <Menu.Item
        key="menu-item-archive-all"
        title={"Archive all"}
        // icon={<CheckCircleTwoTone twoToneColor="#52c41a" />}
      >
        <BulkArchiveButton config={{ params, resourceName, pagination }} />
      </Menu.Item>}
      {/* <BulkArchiveButton config={{params, resourceName, pagination}}/>  Returns menu item */}
      {/* TODO: add bulk export action here as well (will send params to BE and export just like archive)  */}
      {showBulkImport && <Menu.Item key="bulk-import-users-menu-item" className="bulk-import-users-menu-item">
        {/* We have to improve bulk import classes commented for now */}
        {/* {resourceName === "users" ? <BulkImportUsers singleResourceName={resourceName} /> : <BulkImportGroups singleResourceName={resourceName} />} */}
        <BulkImportUsers singleResourceName={resourceName} isMenuItem={true} />
      </Menu.Item>}

      {showBulkImportClasses ? <Menu.Item key="bulk-import-classes-menu-item">
        {/* We have to improve bulk import classes  */}
        <BulkImportGroups singleResourceName={resourceName} isMenuItem={true} />
      </Menu.Item> : null}
      
      {/* <BulkExportButton  config={{params, resourceName, pagination, currentUser}}/> */}
      {showExportAllUsers && <Menu.Item
        key="menu-item-export-all"
        title={"Export all"}
      >
        <BulkExportButton config={{ params, resourceName, pagination, currentUser, location }} />
      </Menu.Item>}
      {showExportUsageData && <Menu.Item
        key="menu-item-export-all-usage-data"
        title={mrIntl("CustomExperienceCrudList.export_usage_data")}
      >
        <ExportUsersUsageData config={{ params, resourceName, pagination, currentUser, location }} />
      </Menu.Item>}

      {showWelcomeEmail && <Menu.Item 
        key="menu-item-welcome-email"
        title={mrIntl("CustomExperienceCrudList.welcome_email")}
      >
        <BulkSendWelcomeEmail config={{ params, currentUser }} />
      </Menu.Item>}

    </Menu>
  );

  if (showActionsDropdown) {
    finalRender.push(<Dropdown
      overlay={actionsMenu}
      placement="bottomRight"
      trigger={[dropDownTrigger]}
      key="bulk-actions-dropdown"
    >
      <Button type="text" ghost={false}>
        {mrIntl("CustomExperienceCrudList.actions")} <DownOutlined />
      </Button>
    </Dropdown>)
  }

  return finalRender
}

// export const CYButton = styled(Button)`
//   background: ${(props) => props.theme.button.background};
//   color: ${(props) => props.theme.main.colors.primary};
// `;
export const CYButton = styled(Button)`
`;

const CustomExperienceCrudList = (props) => {
  console.log('CustomExperienceCrudList==>', props)
  const {
    resourceName,
    singleResourceName,
    filter,
    forms,
    MrFilter,
    displayName,
    resourceForm,
    CustomListItem,
    ActionBtns,
    params,
    setParams,
    showFormVal,
    showForm,
    loading,
    item = {},
    handleOk,
    handleCancel,
    refresh,
    dataSource,
    pagination,
    onPageChange,
    edited,
    deleted,
    permission,
    currentUser,
    ListHeader,
    resourceUrl,
    parentInstance,
    hocProps,
    selectorProps,
    renderList,
    renderForm,
    manualFormRender,
    crudMode,
    
    formMode,
    config,
    duplicateResource,
    crudListData,
    showEmptyStateLayout,
    showPreviewItem,
  } = props;
  // let { path, url } = useRouteMatch();
  // const history = useHistory();
  const mrIntl = useTranslate();
  const enabledFeatures = useSelector(enabledFeaturesSelector());
  const history = props.history;
  const path = props.match.path;
  const url = props.match.url;
  const CrudListData = crudListData;
  const ItemPreview = config.itemPreview.widget
  let customHeaderCreateSearchRender = []
  let isRoute = false;
  let showActions = false;
  const hasDataSource = dataSource.length > 0
  const configStyle = config?.filter?.style;
  const isFilterAlignedRight = configStyle && configStyle?.align === "right";
  const isFilterNotAlignedRight = configStyle && configStyle?.align !== "right";
  const hasNoParams = Object.keys(params).length === 0;
  const hasParams = Object.values(params).every(value => {
    return (
      value === null ||
      value === undefined ||
      (Array.isArray(value) && value.length === 0)
    );
  });
  const isEmptyParams = hasNoParams || hasParams
  const newUiForEmptyList = enabledFeatures.new_ui_for_empty_list;
  const displayEmptyState = !loading && showEmptyStateLayout && isEmptyParams && !hasDataSource && newUiForEmptyList && (currentUser.role === "admin" || currentUser.role === "teacher");
  const shouldShowFilter = !newUiForEmptyList || (newUiForEmptyList && !loading);
  const dataEmptyStateConfig = {
    content: "assessment",
  }
  console.log("permission==>", currentUser.permission);

  if (config.filter.config && config.filter.config.actions && config.filter.config.actions.show && ((currentUser.permission && currentUser.permission && currentUser.permission.manage_permissions && currentUser.permission.manage_permissions.list) || (currentUser.role === "superadmin"))) {
    showActions = true;
  }
  // let showActions = true
  let supportFiltersAlign = currentUser.role === "student" || currentUser.role === "parent" ? false : true

  if(!supportFiltersAlign){
    delete(config.filter.style)
    config.filter.config.search.show = true
  }
  
  
  // if(dataSource.length === 0) return null;
  if (hocProps.isRoute && props.isRoute) {
    isRoute = true;
  }
  // if (!item) {
  //   item = {};
  // }
  
  let newItem = {
    ...item,
  };
  
  const resourcePermission = permission[singleResourceName] || {};
  let toRender;
  let content = [];

  let visible = false;
  // if (loading && item && item.size === 0) {
  //   // debugger;
  //   return <Spinner />;
  // }
  
  if (showFormVal === resourceName) {
    visible = true;
  }
  
  let extraFilters = [];

  if (filter) {
    extraFilters = filter({
      ...params,
      ...selectorProps,
      changed: setParams,
      url,
      setParams,
      resourceName,
      singleResourceName,
      parentProps: {
        ...props
      }
    });
  }
  const filterProps = {
    parentProps: {
      ...props
    },
    name: displayName,
    singleResourceName,
    extraFilters,
    ...selectorProps,
    config: config.filter.config || {}
  };
  const Filter = MrFilter;
  
  
  // const intl = {
  //   formatMessage: () => {}
  // }
  
  // if (!manualFormRender) {
  //   const formHelperProps = {
  //     currentUser,
  //     permission,
  //     selectorProps,
  //     forms,
  //     handleOk,
  //     handleCancel,
  //     newItem,
  //     loading,
  //     displayName,
  //     showFormVal,
  //     resourceName,
  //   };
    
  //   const form = <MrFormRenderHelper {...formHelperProps}/>;

  //   content.push(form);
  // }
  let createBtn = null;
  
  let totalColWidth = 24;
  
  
  if (
    permission &&
    permission[singleResourceName] &&
    permission[singleResourceName].create
  ) {
    if(config.list.createBtn.component) {
      createBtn = config.list.createBtn.component;
    } else {
      // CreateBtnComponent = CYButton;
      createBtn = function CreateBtn(parentProps) {
        return <CYButton
          key={"createBtn"}
          // onClick={() => showForm({ name: resourceName })}
          type="primary"
          onClick={() => history.push(`${url}/create`)}
          icon={<PlusOutlined />}
        >
          <FormattedMessage
            id="general.create"
            // defaultMessage="Create"
            defaultMessage={mrIntl("CommonText.create")}
            // defaultMessage={`{text}`}
            // description="Create"
            // values={{ text: "Create" }}
          />
        </CYButton>
      }
    }
    console.log("config.filter", config.filter);
    if(config.filter.config && config.filter.config.create && config.filter.config.create.show) {
      const CreateBtnFinal = createBtn
      customHeaderCreateSearchRender.push(<CreateBtnFinal key="experience-main-create-btn" />)
    }
    // content.push(createBtn);
  }

  const previewSidebar = showPreviewItem ?  
    <Col 
      span={12} 
      key={`preview-sidebar-${singleResourceName}-main-container`}
      className='preview-container'
      >
      <Row className='preview-container-card'>
        <ItemPreview />
      </Row>
    </Col> 
  : null;

  const finalFilter = config.filter.show && !showPreviewItem ? (<Col 
    span={config.filter.style ? config.filter.style.width : totalColWidth} 
    className={supportFiltersAlign ? `filter-container-wrapper`: ""}
    key={`filter${singleResourceName}MainContainer`}
    >
        <Row>
          {/* <CreateBtn></CreateBtn>
          <Search></Search> */}
          <Filter
            key={`filter${singleResourceName}Main`}
            {...params}
            changed={setParams}
            setParams={setParams}
            refresh={refresh}
            // createBtn={createBtn} // IMP: removing from Filters component and rendering separately below with Search above the list
            {...filterProps}
            // create={() => this.showForm({ name: resourceName })}
          />
        </Row>
      </Col>) : null;
  // if (isRoute) {
  //   content.push(
      
  //   );
  // }

  if(supportFiltersAlign){
    customHeaderCreateSearchRender.push(props.renderSearchComponent({setParams: props.setParams, search: filterProps.search, ...params}))

    // pass through config (using this as a common layout now) and fix the create new test count issue
    // const experienceCountMsg = pagination.totalCount > 1 ? mrIntl("CustomExperienceCrudList.assessments_found", {totalCount: pagination.totalCount}) : mrIntl("CustomExperienceCrudList.assessment_found", {totalCount: pagination.totalCount})

    // !loading && customHeaderCreateSearchRender.push(<span>{experienceCountMsg}</span>)

  } else {
    customHeaderCreateSearchRender.push(finalFilter)
  }

  const Loader = config.list.loader;

  const listWrapperWidth = showPreviewItem ? 12 : (config.filter.style ? config.filter.style.width < 12 ? (totalColWidth - config.filter.style.width) : totalColWidth : totalColWidth);

  console.log( "listWrapperWidth - width", listWrapperWidth );
  const list = <CrudListData key={`mainCrudList${resourceName}`} {...props}/>
  
  let listWrapper = displayEmptyState
    ? <Col span={24}>
      <DataEmptyState dataEmptyStateConfig={dataEmptyStateConfig}/>
    </Col>
    : (
      <Col span={listWrapperWidth} key={`mainList${resourceName}Container`}>
        <Row className='custom-main-crud-list-header'>
          <Col span={showActions ? 21 : 24}>
            <Space>
              {shouldShowFilter ? customHeaderCreateSearchRender : null}
            </Space>
          </Col>
          {/* NOTE be careful using this span - join code search on student side was breaking on ipad because of overlapping div on top of join button */}
          <Col span={showActions ? 3 : 0} className='text-right'>
            <Space>
              {showActions && <BulkActions {...props} />}
              {/* <Button>Sort</Button> */}
              {/* <Button icon={<FilterOutlined />}> Filters</Button> */}
            </Space>
          </Col>
        </Row>

        <Row>
          {loading ? <>
            {[...Array(3)].map((_, index) => (
              <Skeleton
                className="active-skeleton-loading"
                key={index}
                active
                avatar
                title={true}
                paragraph={{ rows: 2 }}
              />
            ))}
          </> : <>
            {list}
            {hocProps.isRoute && config.pagination.show ? <Col span={24}>
              <Row className='m-t-20'>
                <Pagination
                  key={"mainPagination"}
                  // showQuickJumper
                  total={pagination.totalCount}
                  pageSize={pagination.pageSize}
                  // showTotal={total => `Total ${total} items`}
                  onChange={onPageChange}
                  current={pagination.page}
                  // to allow to disable show page size
                  {...config.pagination.config}
                // defaultCurrent={params.page}
                />
              </Row>
            </Col> : null}
          </>}
        </Row>
      </Col>
    );
  
  

  content.push(ListHeader);
  content.push(listWrapper);


  
  // content.push(pagination.totalPages);
  // if (hocProps.isRoute) {
  //   content.push(
  //     config.pagination.show ? () : null
  //   );
  // }
  // let breadcrumb = (
  //   <Breadcrumb key={"mainBreadCrumb"}>
  //     <Breadcrumb.Item>
  //       <a href="">Home</a>
  //     </Breadcrumb.Item>
  //     <Breadcrumb.Item>{displayName}</Breadcrumb.Item>
  //   </Breadcrumb>
  // );
  toRender = (
    // <Row key={"mainRowCRUDLayout"} loading={loading}>
    <Row key={"mainRowCRUDLayout"} >
      {content}
    </Row>
  );
 

  // IMP: filter.create.show - is controlling create button in both list header and filter. filter.search.show is only controlling search in filter. showing search in list header by default in this custom layout
  return (<React.Fragment>
      {/* <Row className='custom-main-crud-list-header'>
        <Col span={18}>
          <Space>
            {customHeaderCreateSearchRender}
          </Space>
        </Col>
        <Col span={6} className='text-right'>
          <Space>
            <Button>Sort</Button>
            <Button>Filters</Button>
          </Space>
        </Col>
      </Row> */}
      <Row>
        {isFilterNotAlignedRight ? finalFilter : null}
        {content}
        {config.filter.style && config.filter.style.align === "right" ? finalFilter : null}
        {previewSidebar}
      </Row>
  </React.Fragment>);




  // CUSTOM SS
  // const [filtersVisible, setFiltersVisible] = useState(true);
  // const toggleFilterVisibility = () => {
  //   setFiltersVisible(!filtersVisible);
  // };
  
  // // Default component + let override
  // const Search = () => {
  //   let finalRender = []
  //   finalRender.push(<Input placeholder={"Search"}/>)
  //   return finalRender;
  // }
  // // Default component + let override
  // const CreateBtn1 = createBtn
  // // Default component + let override
  // const SearchExtra = () => {
  //   let finalRender = []
  //   // finalRender.push(<Button onClick={() => {toggleFilterVisibility()}}>Filters</Button>)
  //   finalRender.push(
  //     <Filter
  //       key={`filter${singleResourceName}Main`}
  //       {...params}
  //       changed={setParams}
  //       setParams={setParams}
  //       refresh={refresh}
  //       createBtn={createBtn}
  //       {...filterProps}
  //     />
  //   )
  //   finalRender.push(<Button>Reset filters</Button>)
  //   return finalRender;
  // }
  // // Default component + let override
  // const Filters = () => {
  //   let finalRender = []
  //   finalRender.push(finalFilter)
  //   return finalRender;
  // }
  // // Default component + let override
  // const List1 = () => {
  //   let finalRender = []
  //   finalRender.push(content)
  //   return finalRender;
  // }

  // config.filters = {
  //   ...config.filters,
  //   type: "side", // or "compact" for search dropdown filters
  //   width: 8, // only with side
  //   align: "right" // only with side
  // }
  

//   return (<React.Fragment>
//     <Row>
//       <Space>
//         <CreateBtn1 />
//         {/* <Search /> */}
//         {/* <SearchExtra /> */}
//       </Space>
//     </Row>
//     <Row>
//       {/* {config.filter.style.align !== "right" && filtersVisible ? <Filters /> : null} */}
//       <List1 />
//       {/* {config.filter.style.align === "right" && filtersVisible ? <Filters /> : null} */}
//     </Row>
//   </React.Fragment>);

};

// CustomExperienceCrudList.defaultProps = {}

CustomExperienceCrudList.propTypes = {}
if(import.meta.env.NODE_ENV === 'development'){
  CustomExperienceCrudList.whyDidYouRender = true;
}
export default CustomExperienceCrudList;