import React from "react";
import { Space, Skeleton, Alert, Button, Col } from "antd";
import { isEmpty } from "lodash";
import RenderHtml from "/src/components/UI/RenderHtml/RenderHtml";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import {
  getAIServiceBaseURL,
  getFromLS,
} from "/src/lib/utils/helperMethods";
import axios from "axios";
import { message } from "/src/components/UI/AntdAppHelper";
import MagicButton from "../../Segments/GenerateAI/MagicButton";
import { FaRegStopCircle } from "react-icons/fa";
import { actions as experienceReduxActions } from "/src/views/Experiences/redux";
import { useDispatch, useSelector } from "react-redux";
import { activeAdjustedExperienceSelector, aiAssistantSettingsSelector } from "../../Experiences/selector";
import { activeExperienceUserSelector } from "../selector";
import { actions as experienceUsersActions } from "/src/views/ExperienceUsers/redux";
import { dbUserResponsesSelector } from "../../UserResponses/selector";
import { updateConfigHeaderForDemoUseStagingAI } from "../../Segments/GenerateAI/generateAIHelperMethods";
import { enabledFeaturesSelector } from "../../Auth/Login/selector";

export const getCriteriaWiseQuestions = (
  topics,
  currentUserResponses,
  experience
) => {
  const criteriaWiseQuestions = [];

  // Pre-build lookup maps for `currentUserResponses` and `experience.criterium_associations_attributes`
  const userResponseMap = new Map(
    currentUserResponses.map((ur) => [ur.segment_id, ur])
  );

  const criteriumAssociationsMap = new Map(
    experience.criterium_associations_attributes.map((ca) => [
      ca.criterium_id,
      ca,
    ])
  );

  topics?.forEach((topic) =>
    topic?.children?.forEach((segment) => {
      if (segment.segment_type !== "question") return;

      const userResponse = userResponseMap.get(segment.id);
      const questionType = segment.question_segment_attributes.question_type;
      let questionObject = {
        question_text: segment.question_segment_attributes.content,
      };

      const handleOptions = (optionsData) => {
        const options = optionsData.map((option) => option.content);
        const student_answers = optionsData
          .filter((option) => option.checked)
          .map((option) => option.content);
        return { options, student_answers };
      };

      const handleStatements = (statementsData) => {
        return statementsData.map((statement) => ({
          content: statement.content,
          statement_is: statement.statementIs,
          student_answer: statement.correct
            ? statement.statementIs
            : !statement.statementIs,
        }));
      };

      const handleGaps = (gapsData, teacherGaps) => {
        return teacherGaps.map((gap) => {
          const studentAnswer =
            gapsData.find((urGap) => urGap.id === gap.id)?.studentAnswer || {};
          return {
            correct_options: gap.items.map((item) => ({
              id: item.id,
              content: item.content,
            })),
            student_answer: {
              id: studentAnswer.id,
              content: studentAnswer.content,
            },
          };
        });
      };

      const handleColumns = (columnsData, teacherColumns) => {
        return teacherColumns.map((column) => {
          const studentMatches =
            columnsData
              .find((urColumn) => urColumn.id === column.id)
              ?.items.map((item) => item.content) || [];
          return {
            column_title: column.title,
            correct_matches: column.items.map((item) => item.content),
            student_matches: studentMatches,
          };
        });
      };

      switch (questionType) {
        case "cke_subjective":
          questionObject.answer_text =
            userResponseMap.get(segment.id)?.text_answer || "";
          questionObject.question_type = "long_answer";
          break;
        case "mcq_single":
        case "mcq_multiple":
          const optionsData = handleOptions(
            userResponse.response_json.segment_data.options
          );
          questionObject.options = optionsData.options;
          questionObject.student_answers = optionsData.student_answers;
          questionObject.question_type = "mcq";
          break;
        case "true_false":
          questionObject.statements = handleStatements(
            userResponse.response_json.segment_data.statements
          );
          questionObject.question_type = "true_false";
          break;
        case "cke_fill_text":
          questionObject.gaps = handleGaps(
            userResponse.response_json.segment_data.gaps,
            segment.question_segment_attributes.teacher_json.segment_data.gaps
          );
          questionObject.question_text =
            segment.question_segment_attributes.teacher_json.segment_data.content;
          questionObject.question_type = "fill_text";
          break;
        case "cke_fill_dropdown":
          questionObject.gaps = handleGaps(
            userResponse.response_json.segment_data.gaps,
            segment.question_segment_attributes.teacher_json.segment_data.gaps
          );
          questionObject.question_text =
            segment.question_segment_attributes.teacher_json.segment_data.content;
          questionObject.question_type = "fill_dropdown";
          break;
        case "rbd_inline_matching":
          questionObject.columns = handleColumns(
            userResponse.response_json.segment_data.columns,
            segment.question_segment_attributes.teacher_json.segment_data
              .columns
          );
          questionObject.question_type = "match";
          break;
        case "rbd_inline_classify":
          questionObject.columns = handleColumns(
            userResponse.response_json.segment_data.columns,
            segment.question_segment_attributes.teacher_json.segment_data
              .columns
          );
          questionObject.question_type = "classify";
          break;
        case "rbd_inline_sorting":
          questionObject.correct_order =
            segment.question_segment_attributes.teacher_json.segment_data.columns.map(
              (column) => column.items[0].content
            );
          questionObject.student_order =
            userResponse.response_json.segment_data.columns.map(
              (column) => column.items[0].content
            );
          questionObject.question_type = "sort";
          break;
        default:
          break;
      }

      segment.criterium_associations_attributes.forEach(
        ({ criterium_id, custom_fields }) => {
          const existing = criteriaWiseQuestions.find(
            (item) => item.criteria_id === criterium_id
          );

          if (existing) {
            existing.questions.push(questionObject);
          } else {
            const associatedStrands =
              criteriumAssociationsMap.get(criterium_id).strands;
            const criteriaLabel = `${custom_fields.title}: ${custom_fields.label}`;
            criteriaWiseQuestions.push({
              criteria_id: criterium_id,
              criteria_label: criteriaLabel,
              questions: [questionObject],
              strands: associatedStrands,
            });
          }
        }
      );
    })
  );

  console.log("criteriaWiseQuestions ===>", criteriaWiseQuestions);
  return criteriaWiseQuestions;
};

export const handleOverallAIGrading = async (
  topicsToProcess,
  sseRef,
  experience,
  currentUserResponses,
  aiAssistantSettings,
  enabledFeatures,
  mrIntl,
  options
) => {
  const { successCallback, errorCallback } = options;
  const modalElement = document.querySelector(".ant-modal-body");
  if (modalElement) {
    modalElement.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  try {
    const token = getFromLS("token");
    const abortController = new AbortController();

    if (sseRef) {
      sseRef.current = {
        abortSse: () => abortController.abort(),
      };
    }

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal: abortController.signal,
    };
    config = updateConfigHeaderForDemoUseStagingAI(config);
    const experienceCustomFields = experience?.custom_fields || {};

    const subjectLabel = experienceCustomFields.subject_item_label;
    const gradeLabel = experienceCustomFields.grade_item_label;
    const programmeLabel = experienceCustomFields.org_programme_item_label;

    const criteriaWiseQuestions = getCriteriaWiseQuestions(
      topicsToProcess,
      currentUserResponses,
      experience
    );

    const dataToPost = {
      subject_label: subjectLabel,
      grade: gradeLabel,
      programme: programmeLabel,
      criteria_wise_questions: criteriaWiseQuestions,
      rubric_type: "myp_al",
      myp_al_overall_grading: true,
    };

    if (enabledFeatures.ai_assistant_settings && aiAssistantSettings?.grading) {
      const gradingSettings = aiAssistantSettings.grading;
      dataToPost.strictness = gradingSettings.strictness;
      dataToPost.feedback_language = gradingSettings.feedback_language;
      dataToPost.feedback_length = gradingSettings.feedback_length;
      dataToPost.feedback_tone = gradingSettings.feedback_tone;
      dataToPost.additional_instructions = gradingSettings.additional_instructions;
      dataToPost.use_seeds_for_grading = gradingSettings.use_seeds_for_grading;
    }


    const response = await axios.post(
      `${getAIServiceBaseURL()}/grade`,
      dataToPost,
      config
    );
    const data = response.data;
    successCallback?.(data);
  } catch (error) {
    console.error("Error during overall grading:", error);
    if (error.code !== "ERR_CANCELED") {
      message.error(mrIntl("CommonText.something_went_wrong_please_try_again"));
    }
    errorCallback?.(error);
    // setSuggestedGrades({});
    // setGradingAILoading(false)
  }
};

export const OverallAIGradingMagicButton = ({
  gradingAILoading,
  setGradingAILoading,
  sseRef,
  suggestedGrades,
  setSuggestedGrades,
  setShowOverallAIGradingSuggestionColumn,
}) => {
  const dispatch = useDispatch();
  const mrIntl = useTranslate();
  const experience = useSelector(activeAdjustedExperienceSelector());
  const experienceUser = useSelector(activeExperienceUserSelector());
  const aiAssistantSettings = useSelector(aiAssistantSettingsSelector());
  const enabledFeatures = useSelector(enabledFeaturesSelector())
  const currentUserResponses = useSelector(
    dbUserResponsesSelector(experience.id, experienceUser.user_id)
  );

  const handleClick = () => {
    if (gradingAILoading) {
      setGradingAILoading(false);
      setShowOverallAIGradingSuggestionColumn(false);
      if (sseRef.current) {
        sseRef.current.abortSse();
      }
    } else {
      setGradingAILoading(true);
      setShowOverallAIGradingSuggestionColumn(true);
      let topicsToProcess = experience.topics;
      if (!topicsToProcess || topicsToProcess.length === 0) {
        dispatch(
          experienceReduxActions.topicsAndSegmentsFetch(
            { id: experience.id },
            {
              successCallback: (data) => {
                console.log("topicsToProcess ===>1", data);
                topicsToProcess = data.topics;
                handleOverallAIGrading(
                  topicsToProcess,
                  sseRef,
                  experience,
                  currentUserResponses,
                  aiAssistantSettings,
                  enabledFeatures,
                  mrIntl,
                  {
                    successCallback: (data) => {
                      setSuggestedGrades(data);
                      setGradingAILoading(false);
                    },
                    errorCallback: (error) => {
                      setSuggestedGrades({});
                      setGradingAILoading(false);
                    },
                  }
                );
              },
              errorCallback: (error) => {
                console.log("failed to fetch topics and segments", error);
              },
            }
          )
        );
      } else {
        handleOverallAIGrading(
          topicsToProcess,
          sseRef,
          experience,
          currentUserResponses,
          aiAssistantSettings,
          enabledFeatures,
          mrIntl,
          {
            successCallback: (data) => {
              setSuggestedGrades(data);
              setGradingAILoading(false);
            },
            errorCallback: (error) => {
              setSuggestedGrades({});
              setGradingAILoading(false);
            },
          }
        );
      }
    }
  };

  return (
    isEmpty(suggestedGrades) && (
      <MagicButton
        shape="default"
        size="medium"
        ghost={true}
        text={
          gradingAILoading ? (
            <Space>
              <span style={{ display: "flex", alignItems: "center" }}>
                <FaRegStopCircle />
              </span>
              {mrIntl("CommonText.stop")}
            </Space>
          ) : (
            mrIntl("OverallAIGrading.grade_with_ai")
          )
        }
        tooltipTitle={gradingAILoading ? mrIntl("CommonText.stop") : ""}
        tooltipPlacement="bottom"
        feature_code="overall_grading_with_ai"
        onClick={handleClick}
        extraClassName="m-l-10"
        // true during loading to not show magicWand icon
        icon={gradingAILoading && true}
      />
    )
  );
};

export const OverallAIGradingSuggestionColumn = ({
  suggestedGrades,
  gradingAILoading,
}) => {
  const mrIntl = useTranslate();
  return (
    <Col span={14}>
      {gradingAILoading ? (
        <Skeleton className="p-20" active />
      ) : !isEmpty(suggestedGrades) ? (
        <Space direction="vertical" className="p-20">
          <span>
            <Space direction="vertical">
              {suggestedGrades?.overall_points?.map(
                (c) => `${c.criteria_label}: ${c.points}`
              )}
            </Space>
          </span>
          <span>
            <b>{mrIntl("CheckGradeWithAI.comments_modal_text")}</b>
            <br />
            <RenderHtml text={suggestedGrades?.overall_comments} />
          </span>
        </Space>
      ) : (
        <Alert
          className="m-l-20"
          type="warning"
          message={mrIntl("CommonText.something_went_wrong_please_try_again")}
        />
      )}
    </Col>
  );
};

export const OverallAIGradingModalFooter = ({
  suggestedGrades,
  setSuggestedGrades,
  gradingAILoading,
  setGradingAILoading,
  setShowOverallGradingForm,
  sseRef,
  setShowOverallAIGradingSuggestionColumn,
  enabledFeatures,
  aiAssistantSettings
}) => {
  console.log("suggestedGrades ==>", suggestedGrades);
  const mrIntl = useTranslate();
  const dispatch = useDispatch();
  const experience = useSelector(activeAdjustedExperienceSelector());
  const experienceUser = useSelector(activeExperienceUserSelector());
  const currentUserResponses = useSelector(
    dbUserResponsesSelector(experience.id, experienceUser.user_id)
  );

  const applytOverallAlGradingResult = () => {
    const updatedScaa =
      experienceUser.submission_criterium_associations_attributes.map((ca) => {
        const matchedCa = suggestedGrades.overall_points.find(
          (rca) => rca.criteria_id === ca.criterium_id
        );
        return matchedCa ? { ...ca, points: matchedCa.points } : ca;
      });

    const totalPoints = updatedScaa.reduce(
      (sum, ca) => sum + (parseInt(ca.points) || 0),
      0
    );

    dispatch(
      experienceUsersActions.update(
        {
          id: experienceUser.id,
          comments: suggestedGrades.overall_comments,
          submission_criterium_associations_attributes: updatedScaa,
          points: totalPoints.toString(),
        },
        {
          success: {
            showMessage: false,
          },
          successCallback: () => {
            message.success(
              mrIntl(
                "ExperienceUserOverallGradingForm.overall_grading_added_successfully"
              )
            );
            setGradingAILoading(false);
            setShowOverallAIGradingSuggestionColumn(false);
            setSuggestedGrades({});
            setShowOverallGradingForm(false);
            let timeout = setTimeout(() => {
              setShowOverallGradingForm(true);
              clearTimeout(timeout);
            }, 1);
          },
          errorCallback: () => {
            setGradingAILoading(false);
            setSuggestedGrades({});
          },
        }
      )
    );
  };

  return !isEmpty(suggestedGrades) ? (
    <Space className="p-r-20">
      <Alert
        showIcon
        message={mrIntl(
          "CheckGradeWithAI.assistant_can_make_mistakes_please_check_generated_results"
        )}
        type="warning"
      />
      <Button
        type="primary"
        ghost
        size="medium"
        onClick={() => {
          console.log("loading checje hfkae ===>", {
            gradingAILoading,
            sseRef,
          });
          if (!gradingAILoading) {
            setGradingAILoading(true);
            handleOverallAIGrading(
              experience.topics,
              sseRef,
              experience,
              currentUserResponses,
              aiAssistantSettings,
              enabledFeatures,
              mrIntl,
              {
                successCallback: (data) => {
                  setSuggestedGrades(data);
                  setGradingAILoading(false);
                },
                errorCallback: (error) => {
                  setGradingAILoading(false);
                },
              }
            );
          } else if (sseRef.current) {
            sseRef.current.abortSse();
          }
        }}
      >
        {gradingAILoading ? (
          <Space>
            <span style={{ display: "flex", alignItems: "center" }}>
              <FaRegStopCircle />
            </span>
            {mrIntl("CommonText.stop")}
          </Space>
        ) : (
          mrIntl("CommonText.try_again")
        )}
      </Button>
      <Button
        type="primary"
        size="medium"
        onClick={() => applytOverallAlGradingResult()}
        disabled={gradingAILoading}
      >
        {mrIntl("ExperienceUserOverallGradingForm.use_this")}
      </Button>
    </Space>
  ) : null;
};
