import React, {useState} from 'react';
import {Row, Card, List, Space, Button, Tooltip, Modal, Col} from "antd";
import {useSelector, useDispatch} from "react-redux";
import { PlusOutlined, CloseOutlined, VerticalAlignMiddleOutlined, VideoCameraOutlined, AudioOutlined, QuestionOutlined, AppstoreAddOutlined, SoundOutlined, DownCircleOutlined, SortAscendingOutlined, TableOutlined, TagOutlined, LineChartOutlined, FormatPainterOutlined, AimOutlined, DatabaseOutlined, GroupOutlined, DisconnectOutlined, CheckSquareOutlined, FilePptOutlined, FileWordOutlined, FileAddOutlined } from "@ant-design/icons";
// import { GrTextAlignLeft, GrRadialSelected} from "react-icons/gr";
import { 
  // BsChevronExpand, 
  // BsCollection, 
  // BsCursorText, 
  BsCardImage, 
  BsCardText, 
  // BsCheckBox,
  BsCardChecklist, 
  BsTextareaT 
} from "react-icons/bs";
import { FaRegFilePdf, FaCode } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { RiDragDropLine, RiCheckboxMultipleLine } from "react-icons/ri";
import { MdLabelOutline } from "react-icons/md";
import {actions as experienceActions} from "/src/views/Experiences/redux"
import { activeAdjustedExperienceSelector, experienceConfigSelector } from '/src/views/Experiences/selector';
import { currentUserSelector, enabledFeaturesSelector } from "/src/views/Auth/Login/selector";
import { checkMob, getDestinationPositionLex } from "/src/lib/utils/helperMethods";
import {actions as topicActions} from "/src/views/Segments/Topics/redux";
import {actions as segmentActions} from "/src/views/Segments/redux";
import { topicsSelector } from '../Topics/selector';
import { segmentsSelector, showSegmentFormSelector } from '../selector';
import _ from 'lodash';
import { MrTranslate, mrTranslate, useTranslate } from "/src/lib/MrTranslate/MrTranslate"

import { actions as firestoreInteractionActions } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/redux";
// import _, { upperFirst } from 'lodash';
import { updateSegmentTypeCount } from '/src/views/Experiences/ExperienceHelperMethods';
import MagicButton from '../GenerateAI/MagicButton';
import { initializeAiGenerateQuestionsConfig, isNewGenerateQuestionsWithAI } from '../GenerateAI/generateAIHelperMethods';
import { aiGenerateLoadingSelector } from '../GenerateAI/selector';
import { actions as orgActions } from '../../Orgs/redux';

// import { VscSymbolBoolean } from "react-icons/vsc";
// import { CgFormatText } from "react-icons/cg";
// import { BiBookContent, BiSelectMultiple } from "react-icons/bi";
// import { ImFilePlay, ImImage } from "react-icons/im";


// const CancelButton = (props) => {
//   return (
//     <Tooltip title="Cancel">
//       <Button shape="circle" ghost={true} type="danger" icon={<CloseOutlined />} onClick={(event) => {switchMode("cancel"); props.showForm({name: undefined})}}/>
//     </Tooltip>
//   )
// }

const AddSegmentSelector = (props) => {
  console.log("AddSegmentSelector props ==>", props)
  const {initValuesForLibraryForm, isLibrary, idx, isSegmentsLimitReached, segmentsLength, parentId, nestingLevel, prevPosition, nextPosition, libraryModalProps = {}} = props

  const { libraryModalConfig, setLibraryModalConfig } = libraryModalProps

  const previousId = props.sId

  const [showAddButton, setShowAddButton] = useState(1);
  const [segmentGroup, setSegmentGroup] = useState(0);
  const [showSegmentGroups, setShowSegmentGroups] = useState(0);
  const [showSegmentTypes, setShowSegmentTypes] = useState(0);
  const [segmentType, setSegmentType] = useState(0);
  
  let formName = isLibrary ? "librarySegments" : "segments"
  const aiGenerateLoading = useSelector(aiGenerateLoadingSelector())
  const currentUser = useSelector(currentUserSelector());
  const activeExperience = useSelector(activeAdjustedExperienceSelector())
  const experienceConfigFromRedux = useSelector(experienceConfigSelector());
  // let showAddFromLibrary = activeExperience && activeExperience.rubric && activeExperience.rubric.type_c == "points" ? true : false
  let showAddFromLibrary = !isLibrary && nestingLevel === 1 && currentUser.role !== "qb_author" // qb_author condn temp until add and filters fixed
  
  const enabledFeatures = useSelector(enabledFeaturesSelector())
  const topics = useSelector(topicsSelector())
  const segments = useSelector(segmentsSelector());
  const showSegmentForm = useSelector(showSegmentFormSelector());
  const isMobile = checkMob();
  
  const dispatch = useDispatch()
  const mrIntl = useTranslate();
  const newGenerateQuestionsWithAI = isNewGenerateQuestionsWithAI(enabledFeatures);
  
  let addFromLibraryDisabled = enabledFeatures.special_ui_for_dns
  const showOnlyMcqQuestion = enabledFeatures.show_only_mcq_question;
  // if(parentSegment && parentSegment.segment_type === "question" && parentSegment.question_segment_attributes.question_type === "group" && parentSegment.settings.is_game && enabledFeatures.games){
  //   addButtonConfig = {
  //     showLevels: true,
  //   }
  // }

  let addRender = []

  const handleResetForm = (initializeGenerateWithAI=false) => {
    setShowSegmentGroups(0);
    setShowSegmentTypes(0);
    setShowAddButton(1);
    setSegmentGroup(0);
    if (
      newGenerateQuestionsWithAI &&
      initializeGenerateWithAI
    ) {
      initializeAiGenerateQuestionsConfig(dispatch);
    }
  };

  const handleCloseSegmentForm = () => {
    handleResetForm();
    dispatch(segmentActions.setShowSegmentFormSuccess({ showSegmentForm: 0 }));
    setSegmentType(0);
  };

  // const { isStandardPlan, hasMaxOrPlusPlan } = getCurrentPlanStatus(currentUser);
  const isTeacherOrAdmin = currentUser && ["teacher", "admin"].includes(currentUser.role);

  const handleGenerateWithAIClick = () => {
    let payload = {
      generateMode: true,
      createCondition: "new",
      prevPosition: prevPosition,
      nextPosition: nextPosition,
      previousId: previousId,
      createAtIndex: idx,
      key: "generate-button",
      parentId: parentId,
      nestingLevel: nestingLevel,
      extractFromPdf: false,
      uploadedPdf: null,
      
    }
    if(newGenerateQuestionsWithAI) {
      payload = {
        ...payload,
        activeAISegmentPosition: {idx: idx, parentId: parentId}}
        setSegmentGroup(0);
        setShowSegmentTypes(0)
    }
    initializeAiGenerateQuestionsConfig(dispatch, payload);
  }
  // addRender.push(
  //   <UpgradePlanModal
  //   currentUser = {currentUser}
  //   featureCode = {featureCode}
  //   />
  // );
  const contentTypes = [
    {
      id: "text",
      title: mrIntl("CommonText.text"),
      // icon: <GrTextAlignLeft />
      icon: <BsCardText />
    },
    {
      id: "accordion",
      // title: "Accordion",
      title: mrIntl("CommonText.accordion"),
      icon: <VerticalAlignMiddleOutlined />
    },
    {
      id: "image",
      title: mrIntl("CommonText.image"),
      // title: "Image",
      icon: <BsCardImage />
    },
    {
      id: "pdf",
      title: mrIntl("CommonText.pdf"),
      // title: "PDF",
      icon: <FaRegFilePdf />
    },
    {
      id: "video",
      title: mrIntl("CommonText.video"),
      // title: "Video",
      icon: <VideoCameraOutlined />
    },
    {
      id: "audio",
      title: mrIntl("CommonText.audio"),
      icon: <SoundOutlined />
    },
    {
      id: "simulation",
      title: mrIntl("CommonText.simulation"),
      // title: "Simulation",
      icon: <FaRegFilePdf />
    },
  ]

  if (enabledFeatures.content_types_embed) {
    contentTypes.push({
      id: "embed",
      title: mrIntl("CommonText.embed"),
      icon: <FaCode/>
    })
  }
  
  if (import.meta.env.VITE_REGION === "global") {
    contentTypes.push(
      {
        id: "doc",
        title: mrIntl("AddSegmentSelector.document"),
        icon: <FileWordOutlined />,
      },
      {
        id: "ppt",
        title: mrIntl("AddSegmentSelector.ppt"),
        icon: <FilePptOutlined />,
      }
    );
  }

  const mcqQuestionType = [
    {
      id: "mcq_single",
      title: mrIntl("CommonText.mcq"),
      // title: "MCQ",
      icon: <CheckSquareOutlined />
    },
  ]

  const questionTypes = [
    {
      id: "cke_subjective",
      title: mrIntl("CommonText.long_answer"),
      // title: "Long answer",
      icon: <FiEdit />
    },
    {
      id: "mcq_single",
      title: mrIntl("CommonText.mcq"),
      // title: "MCQ",
      icon: <CheckSquareOutlined />
    },
    {
      id: "mcq_multiple",
      title: mrIntl("CommonText.mcq_multiple"),
      // title: "MCQ multiple",
      icon: <RiCheckboxMultipleLine />
    },
    {
      id: "true_false",
      title: mrIntl("CommonText.true_false"),
      // title: "True/False",
      icon: <AimOutlined />
    },
    {
      id: "cke_fill_text",
      title: mrIntl("CommonText.fill_text"),
      // title: "Fill Text",
      icon: <BsTextareaT />
      // icon: <BsCursorText />
    },
    {
      id: "cke_fill_dropdown",
      title: mrIntl("CommonText.fill_dropdown"),
      // title: "Fill Dropdown",
      icon: <DownCircleOutlined />
    },
    // {
    //   id: "cke_fill_dnd",
    //   title: "Fill - DnD",
    //   icon: <DragOutlined />
    // },
    {
      id: "rbd_inline_matching",
      title: mrIntl("CommonText.match"),
      // title: "Match",
      icon: <RiDragDropLine />
    },
    {
      id: "rbd_inline_sorting",
      title: mrIntl("CommonText.sort"),
      // title: "Sort",
      icon: <SortAscendingOutlined />
    },
    {
      id: "rbd_inline_classify",
      title: mrIntl("CommonText.classify"),
      // title: "Classify",
      icon: <RiDragDropLine />
    },
    {
      id: "rds_table",
      title: mrIntl("CommonText.table"),
      // title: "Table",
      icon: <TableOutlined />
    },
    {
      id: "fabric_drawing",
      title: mrIntl("CommonText.drawing"),
      // title: "Drawing",
      icon: <FormatPainterOutlined />
    },
    {
      id: "fabric_dnd_label",
      title: mrIntl("CommonText.label_drag"),
      // title: "Label Drag",
      icon: <MdLabelOutline />
    },
    {
      id: "fabric_fill_label",
      title: mrIntl("CommonText.label_fill"),
      // title: "Label Fill",
      icon: <MdLabelOutline />
    },
    {
      id: "fabric_hotspot",
      title: mrIntl("CommonText.hotspot"),
      // title: "Hotspot",
      icon: <TagOutlined />
    },
    {
      id: "ggb_graph",
      title: mrIntl("CommonText.geogebra_graph"),
      // title: "Geogebra Graph",
      icon: <LineChartOutlined />
    },
    {
      id: "desmos_graph",
      title: mrIntl("CommonText.desmos_graph"),
      // title: "Desmos Graph",
      icon: <LineChartOutlined />
    },
    // {
    //   id: "rtc_audio_recording",
    //   title: "Audio",
    //   icon: <AudioOutlined />
    // },
    // {
    //   id: "mini_quiz",
    //   title: mrIntl("CommonText.mini_quiz"),
    //   // title: "Mini Quiz",
    //   icon: <BsCardChecklist />
    // },
    // {
    //   id: "flash_card",
    //   title: "Flash Card",
    //   icon: <BsCardText />
    // },
    {
      id: "number_line",
      title: mrIntl("CommonText.number_line"),
      // title: "Number line",
      icon: <FiEdit />
    },
  ]

  const gameTypes = [
    {
      id: "mcq_single",
      title: mrIntl("CommonText.mcq"),
      icon: <CheckSquareOutlined />
    },
    {
      id: "mcq_multiple",
      title: mrIntl("CommonText.mcq_multiple"),
      icon: <RiCheckboxMultipleLine />
    },
    {
      id: "true_false",
      title: mrIntl("CommonText.true_false"),
      icon: <AimOutlined />
    },
    {
      id: "cke_fill_text",
      title: mrIntl("CommonText.fill_text"),
      icon: <BsTextareaT />
    },
    {
      id: "cke_fill_dropdown",
      title: mrIntl("CommonText.fill_dropdown"),
      icon: <DownCircleOutlined />
    },
    {
      id: "rbd_inline_matching",
      title: mrIntl("CommonText.match"),
      icon: <RiDragDropLine />
    },
    {
      id: "rbd_inline_sorting",
      title: mrIntl("CommonText.sort"),
      icon: <SortAscendingOutlined />
    },
    {
      id: "rbd_inline_classify",
      title: mrIntl("CommonText.classify"),
      icon: <RiDragDropLine />
    },
    {
      id: "fabric_dnd_label",
      title: mrIntl("CommonText.label_drag"),
      icon: <MdLabelOutline />
    },
    {
      id: "fabric_fill_label",
      title: mrIntl("CommonText.label_fill"),
      icon: <MdLabelOutline />
    },
    {
      id: "fabric_hotspot",
      title: mrIntl("CommonText.hotspot"),
      icon: <TagOutlined />
    },
    {
      id: "group",
      title: mrIntl("CommonText.group"),
      icon: <GroupOutlined />
    },
  ]

  const sectionTypes = [
    {
      id: "sub-section",
      title: mrIntl("CommonText.sections"),
      icon: <AppstoreAddOutlined />
    }
  ]
  
  if ((currentUser.role == 'qb_author' || import.meta.env.VITE_MODE != 'production') && formName === "librarySegments") {
    // only in All resources tab, flash card as a question type is not attemptable.. should ideally be a content
    questionTypes.push({
      id: "mini_quiz",
      title: mrIntl("CommonText.mini_quiz"),
      // title: "Mini Quiz",
      icon: <BsCardChecklist />
    })
    
    questionTypes.push({
      id: "flash_card",
      title: mrIntl("CommonText.flash_card"),
      icon: <BsCardText />
    })
  }

  if (enabledFeatures.question_type_connect) {
    questionTypes.push({
      id: "connect",
      title: mrIntl("CommonText.connect"),
      icon: <DisconnectOutlined />
    },)
  }

  // const relationItems = activeExperience?.relation_items || [];
  // const isGroupQuestionTypeEnabled = [5, 27, 30, 31].includes(relationItems[0]?.programme_id);
  
  // only for DP right now and inside experiences == open for all programmes now
  // if (isGroupQuestionTypeEnabled && nestingLevel <= 2) {
  if (!_.isEmpty(activeExperience) && nestingLevel <= 2) {
    questionTypes.push({
      id: "group",
      title: mrIntl("CommonText.group"),
      icon: <GroupOutlined />
    },)
  }

  if (enabledFeatures.question_type_audio_input) {
    questionTypes.push({
      id: "audio_input",
      title: mrIntl("CommonText.audio"),
      icon: <AudioOutlined />
    })
  }

  if (
    enabledFeatures.question_type_video_input
  ) {
    questionTypes.push({
      id: "video_input",
      title: mrIntl("AddSegmentSelector.video"),
      icon: <VideoCameraOutlined />,
    });
  }
  if (enabledFeatures.question_type_file_upload) {
    questionTypes.push({
      id: "file_upload",
      title: mrIntl("CommonText.file_upload"),
      icon: <FileAddOutlined />,
    });
  }

  if (
    enabledFeatures.question_type_highlight_words
  ) {
    questionTypes.push({
      id: "highlight_words",
      title: mrIntl("CommonText.highlight_words"),
      icon: <LineChartOutlined />
    },);
  }
  
  if(showAddButton){
    addRender.push(
      <Tooltip title={isSegmentsLimitReached ? mrIntl("AddSegmentSelector.a_section_can_have_max_50_items_title") : mrIntl("CommonText.add")}>
        <Button className="ant-btn-icon-m-t-4 add-segment-plus" type="primary" shape={segmentsLength !== 0 && "circle"} size={nestingLevel === 1 ? "large" : "small"} icon={<PlusOutlined />} onClick={(event) => {setShowSegmentGroups(1); setShowAddButton(0)}} disabled={isSegmentsLimitReached}>{segmentsLength === 0 && mrIntl("AddSegmentSelector.add_item_btn")}</Button>
      </Tooltip>
    )
  }
  else{
    addRender.push(
      <Tooltip title={mrIntl("CommonText.cancel")}>
        <Button
          className="ant-btn-icon m-t-4"
          danger
          shape="circle"
          ghost={true}
          size="large"
          icon={<CloseOutlined />}
          onClick={(event) => handleResetForm(true)}
        />
      </Tooltip>
    );
  }

  if(showSegmentGroups){
    const generateWithAIPromptProps = {
      createCondition: "new",
      destinationPosition: getDestinationPositionLex(prevPosition, nextPosition),
      nextPosition: nextPosition,
      previousId: idx === 0 ? 0 : previousId,
      createAtIndex: idx,
      key: "generate-button",
      parentId: parentId,
      nestingLevel: nestingLevel,
    };

    addRender.push(
      <Row style={{"marginTop": "10px"}}>
        <Space style={{"margin":"0 auto"}}>
          {/* {(import.meta.env.VITE_MODE != 'production' && import.meta.env.VITE_MODE != 'china_production') && <Button type="primary" size="medium" ghost={segmentGroup == "section" ? false : true} onClick={() => {setSegmentGroup("section"); setShowSegmentTypes(1)}}><AppstoreAddOutlined /> Section</Button>} */}
          <Button type="primary" size="medium" ghost={segmentGroup !== "content"} onClick={() => {setSegmentGroup("content"); setShowSegmentTypes(1); newGenerateQuestionsWithAI && initializeAiGenerateQuestionsConfig(dispatch);}}><AppstoreAddOutlined /><MrTranslate id={"CommonText.content"}/></Button> 
          <Button type="primary" size="medium" ghost={segmentGroup !== "question"} onClick={() => {setSegmentGroup("question"); setShowSegmentTypes(1); newGenerateQuestionsWithAI && initializeAiGenerateQuestionsConfig(dispatch)}}>
            <QuestionOutlined /><MrTranslate id={"CommonText.question"}/></Button> 
          {experienceConfigFromRedux.manage.create.segments.create.games.enabled && <Button type="primary" size="medium" ghost={segmentGroup === "game" ? false : true} onClick={() => {setSegmentGroup("game"); setShowSegmentTypes(1)}}><QuestionOutlined /><MrTranslate id={"CommonText.game_button"}/></Button>}
          {/* <Button type="primary" size="medium" ghost={true} onClick={() => setSegmentGroup("game")}><PlusOutlined /> Game</Button>  */}
          {showAddFromLibrary && 
            <Tooltip title={enabledFeatures.special_ui_for_dns ? "Disabled for school" : ""}>
              <Button 
                type="primary" 
                ghost={true} 
                size="medium" 
                disabled={addFromLibraryDisabled}
                onClick={() => {
                    setLibraryModalConfig((libraryModalConfig) => {
                      return {
                        ...libraryModalConfig,
                        modalVisible: true,
                        addFromLibAtIndex: idx,
                        addFromLibPreviousId: previousId,
                        addFromLibPrevPosition: prevPosition,
                        addFromLibNextPosition: nextPosition,
                        addFromLibDestinationPosition: getDestinationPositionLex(prevPosition, nextPosition),
                      };
                    });
                    newGenerateQuestionsWithAI && initializeAiGenerateQuestionsConfig(dispatch);
      
                    // libraryModalProps.setLibraryModalVisible(true);
                    // libraryModalProps.setAddFromLibAtIndex(idx); 
                    // libraryModalProps.setAddFromLibPreviousId(previousId); 
                    // libraryModalProps.setAddFromLibPrevPosition(prevPosition); 
                    // libraryModalProps.setAddFromLibNextPosition(nextPosition); 
                    // libraryModalProps.setAddFromLibDestinationPosition(getDestinationPositionLex(prevPosition, nextPosition));
                  } 
                }
              >
                  <DatabaseOutlined /> {isMobile ? mrIntl("CommonText.library") : mrIntl("CommonText.from_library")} 
              </Button>
            </Tooltip>
          }

          {/* {(enabledFeatures.ai_generate_questions_with_prompt || import.meta.env.VITE_MODE === "development" || (currentUser && currentUser.role === "qb_author" && currentUser.custom_fields && currentUser.custom_fields.can_view_all_experiences)) && <GenerateAIWithPrompt {...generateWithAIPromptProps}/>} */}
          {/* nesting level === 1 becuse not showing generate with ai button inside group question */}
          {(isTeacherOrAdmin || import.meta.env.VITE_MODE === "development" || (currentUser && currentUser.role === "qb_author" && currentUser.custom_fields && currentUser.custom_fields.can_view_all_experiences)) && <MagicButton
            shape="default"
            disabled={newGenerateQuestionsWithAI ? aiGenerateLoading : false}
            feature_code = 'ai_generate_questions_with_prompt'
            size="medium"
            ghost={true}
            onClick={() => {
              if (enabledFeatures.ai_generate_questions_with_prompt) {
                handleGenerateWithAIClick();
               }
              // if (!enabledFeatures.ai_generate_questions_with_prompt && isStandardPlan && !hasMaxOrPlusPlan) {
              //   dispatch(orgActions.setShowUpgradePlanModalSuccess({
              //     show: true,
              //     feature_code : "ai_generate_questions_with_prompt",
              //   }))
              // } else {
              //   handleGenerateWithAIClick();
              // }
            }}
            text={
              <MrTranslate
                id={"GenerateAIWithPrompt.generate_with_ai_button"}
              />
            }
          />}
        </Space>
      </Row>
    )
  }
  
  let dataSource = [];
  if(segmentGroup === "content"){
    dataSource = contentTypes
  }
  else if(segmentGroup === "question"){
    dataSource = (showOnlyMcqQuestion ? mcqQuestionType : questionTypes);
  }
  else if(segmentGroup === "game"){
    dataSource = gameTypes
  }
  else if(segmentGroup === "section"){
    dataSource = sectionTypes
  }
  
  if(showSegmentTypes){
    addRender.push(
      <Row style={{"marginTop": "10px"}}>
        <List
          className={`add-segment-grid add-segment-grid-${segmentGroup}`}
          grid={{
            gutter: 16,
            // column: 6
            xs: 2,
            sm: 3,
            md: 4,
            lg: 6,
            xl: 6,
            xxl: 6,
          }}
          dataSource={dataSource}
          renderItem={item => (
            <List.Item>
              {/* <Card title={item.title}>Card content</Card> */}
              <Button className="segment-type-button" type="text" size="medium" ghost={false} onClick={() => {setSegmentType(item.id); dispatch(segmentActions.setShowSegmentFormSuccess({ showSegmentForm: 1 }));}} icon={item.icon}> {item.title} </Button>
            </List.Item>
          )}
        />
      </Row>
    )
  }

  if(showSegmentForm && segmentGroup){
    console.log("showing form first add")
    // addRender.push("shikhar")
    // setShowSegmentGroups(0)
    // showSegmentTypes = false
    let segment_type = "";
    // let question_type = "";
    let newSegment = {
      // id: 1,
      settings: {}
    }
    let nested_attributes = {}
    let extra_props = {};
    if(segmentGroup === "content"){
      segment_type = (segmentType === "text" ? "text" : "resource")
      nested_attributes = {
        resource_type: segmentType,
      }
      extra_props = {
        destinationPosition: getDestinationPositionLex(prevPosition, nextPosition),
        previousId: idx === 0 ? 0 : previousId,
        prevPosition: prevPosition,
        nextPosition: nextPosition,
        createAtIndex: idx,
        key: "generate-button",
        parentId: parentId,
        nestingLevel: nestingLevel,
        showSegmentForm: showSegmentForm,
      }
    }
    else if(segmentGroup === "question"){
      segment_type = segmentGroup
      nested_attributes = {
        question_type: segmentType,
      }
    }
    else if(segmentGroup === "game"){
      segment_type = "question"
      nested_attributes = {
        question_type: segmentType,
        // game_type: gameType // TODO
      }
      newSegment.settings.is_game = true
    }
    else if(segmentGroup === "section"){
      segment_type = segmentGroup
      nested_attributes = {
        // question_type: segmentType
      }
    }

    newSegment.segment_type = segment_type
    newSegment[`${segment_type}_segment_attributes`] = nested_attributes;
    newSegment[`${segment_type}_segment_attributes`].extra_props = extra_props;

    let extra = (
      <Tooltip title={mrIntl("CommonText.cancel")}>
        <Button
          shape="circle"
          ghost={true}
          danger
          icon={<CloseOutlined />}
          onClick={(event) => handleCloseSegmentForm()}
        />
      </Tooltip>
    );

    
    if(isLibrary){
      // initing from params
      newSegment.questionbank_id = initValuesForLibraryForm.questionbank_id
      newSegment.subject_list = [initValuesForLibraryForm.subject_name]
      newSegment.grade_list = [initValuesForLibraryForm.grade_name]
    }
    const addForm = props.renderForm({
      name: formName,
      disablePrompt: true, 
      config: {
        isModal: false,  
        handleCancelCallback: () => handleCloseSegmentForm(), 
        actions: {submitText: mrIntl("CommonText.save"), showCancelBtn: true}, 
        modal: {width: "900px", wrapClassName: "segment-form-modal", title: mrIntl("AddSegmentSelector.add_edit_item_title")}, 
        visible: true, 
        submitConfig: {
          createAtIndex: props.idx,
          parentId: parentId,  // !keep all values above successCallback funonly - working when placed above for some reason !?? framework config merge issue with function - will fix with update
          previousId: previousId, 
          destinationPosition: getDestinationPositionLex(prevPosition, nextPosition),
          hideForm: true, 
          success: {showMessage: true, message: mrIntl("AddSegmentSelector.item_created_message")}, 
          error: {showMessage: true, message: mrIntl("AddSegmentSelector.error_in_creating_item_message")}, 
          successCallback: (data) => {
            // let msg = `Segment created by ${currentUser.name} (${upperFirst(currentUser.role)})`;
            handleCloseSegmentForm();
            const experience = activeExperience
            let updatedSegment = data.data.segment.data.attributes
            if(activeExperience && !_.isEmpty(activeExperience)){
              dispatch(segmentActions.setParentsPoints({ updatedSegment }));
              updateSegmentTypeCount(data, dispatch, experience);
              // dispatch(
              //   firestoreInteractionActions.setLogs({
              //     logging: true,
              //     log_type: "segment",
              //     msg: msg,
              //     itemId: updatedSegment.id,
              //     experienceId: activeExperience.id,
              //     segmentAction: "created",
              //     segmentType: updatedSegment.segment_type,
              //     questionType:
              //       updatedSegment.segment_type === "question"
              //         ? updatedSegment.question_segment_attributes.question_type
              //         : updatedSegment.segment_type === "resource"
              //         ? updatedSegment.resource_segment_attributes.resource_type
              //         : "",
              //   })
              // );
              // dispatch(
              //   firestoreInteractionActions.setUserInfo(
              //     {
              //       id: currentUser.id,
              //       // experienceId: item.id,
              //     },
              //     {
              //       log: {
              //         logging: true,
              //         log_type: "experience",
              //         msg: msg,
              //       },
              //     }
              //   )
              // );
            }
          }}, 
          
        },
      resource: newSegment
    })
    addRender = 
      <Card type="inner" title="" extra={extra}>
        <div>
          {addForm}
        </div>
      </Card>
    
    
  }

  // switchMode("create", previousId)
  // props.showForm({name: "segments"})
  

  return (
    <React.Fragment key="add-segment-selector">
      {addRender}
    </React.Fragment>
  )
}


AddSegmentSelector.defaultProps = {}
AddSegmentSelector.propTypes = {}
export default AddSegmentSelector;

