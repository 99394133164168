import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, Col, Modal, Row, Skeleton, Space, Tag, Tooltip } from "antd";
import axios from "axios";
import { getAIServiceBaseURL, getFromLS, getHtml2CanvasUrl, getUniqueId, removeHTMLTagsFromText, renderMathInElement, uploadImageAndGetUrl } from "/src/lib/utils/helperMethods";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { activeAdjustedExperienceSelector, aiAssistantSettingsSelector } from "/src/views/Experiences/selector";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import RenderHtml from "/src/components/UI/RenderHtml/RenderHtml";
import { ShowTextContent } from "/src/components/UI/Segment/UIHelper";
import MagicButton from "../GenerateAI/MagicButton";
import { FaRegStopCircle } from "react-icons/fa";
import RateAiResult from "../GenerateAI/RateAiResult";
import { aiModelSelector, aiModelTempSelector } from "/src/views/Orgs/selector";
import {  updateConfigHeaderForDemoUseStagingAI } from "../GenerateAI/generateAIHelperMethods";
import { currentUserSelector, enabledFeaturesSelector } from "../../Auth/Login/selector";
import AIAssistantSettings from "../GenerateAI/AIAssistantSettings";
import { SettingOutlined } from "@ant-design/icons";
import AIGradingSettingsModal from "./AIGradingSettingsModal";
import { actions as orgActions } from "../../Orgs/redux";
import MediaShow from "/src/components/UI/Media/MediaShow";

const ModalFooter = ({
  loading,
  suggestedGrade,
  handleCancel,
  getSuggestedGrades,
  sseRef,
  handleOk,
  isSuggestedGradeValid
}) => {
  const mrIntl = useTranslate();  
  return (
    <Row className="modal-footer">
        <Col span={12}>
          {!loading && <Alert showIcon message={mrIntl("CheckGradeWithAI.assistant_can_make_mistakes_please_check_generated_results")} type="warning" />}
        </Col>
        <Col span={12} className="grading-footer-actions">
          <Space>
            {(!loading && !isEmpty(suggestedGrade) && <RateAiResult action={"grading"} generatedData={suggestedGrade} />)}
            {!loading && <Button
              size="medium"
              onClick={handleCancel}
            >
              {mrIntl("CommonText.cancel")}
            </Button>}
            <Tooltip title={""}>
              <Button
                type={loading ? "default" : "primary"}
                ghost={!loading}
                danger={loading}
                className={loading ? "stop-button" : ""}
                size="medium"
                onClick={() => {
                  console.log("loading checje hfkae ===>", {loading, sseRef});
                  if (!loading) {
                    getSuggestedGrades()
                  } else if (loading && sseRef.current) {
                    console.log("loading is it in ===>", {loading, sseRef});
                    sseRef.current.abortSse();
                  }
                }}
                // disabled={loading}
                icon={loading && <FaRegStopCircle />}
              >
                {loading ? "Stop" : "Try again"}
              </Button>
            </Tooltip>
            {!loading && isSuggestedGradeValid(suggestedGrade) && <Tooltip title={""}>
              <Button
                type={"primary"}
                size="medium"
                onClick={() => {
                    handleOk()
                }}
              >
                {mrIntl("CheckGradeWithAI.use_this")}
              </Button>
            </Tooltip>}
          </Space>
        </Col>
      </Row>
  );
};

const CheckGradeWithAI = ({
  rubricType,
  questionType,
  questionText,
  studentAnswer,
  maxPoints,
  onSuccess,
  answerExplanation,
  markscheme,
  studentAnswerImages,
  scas,
  parentQuestionText,
  userResponse,
  segmentId
}) => {
  console.log("CheckGradeWithAI==>", {
    rubricType,
    questionText,
    studentAnswer,
    maxPoints,
    onSuccess,
    answerExplanation,
    parentQuestionText,
    studentAnswerImages,
    segmentId
  })

  const studentAnswerAttachmentUrls = []
  if(studentAnswerImages?.length > 0) {
    studentAnswerImages.forEach(image => {
      if(image?.s3_url) {
        studentAnswerAttachmentUrls.push(image.s3_url)
      }
    })
  }
  // Getting audio and video input from userResponse(not required as not grading audio and video quesitons for now)
  // const userResponseAttachments = userResponse?.response_json?.segment_data?.attachments
  const currentUser = useSelector(currentUserSelector());
  const isTeacherOrAdmin = currentUser && ["teacher", "admin"].includes(currentUser.role);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [strictnessValue, setStrictnessValue] = useState("moderate");
  const [suggestedGrade, setSuggestedGrades] = useState({});
  const [loading, setLoading] = useState(false);
  const [studentAnswerHTML, setStudentAnswerHTML] = useState(studentAnswer);
  const [previewImages, setPreviewImages] = useState([]);

  
  const userResponseId = userResponse?.id;

  let question = questionText;
  let answer = removeHTMLTagsFromText(studentAnswer, true);

  if(parentQuestionText) {
    let parentQuestion = removeHTMLTagsFromText(parentQuestionText, true)
    question = parentQuestion + "\n" + question;
  }
  // let firstAttachmentUrl = attachmentsJson.images && attachmentsJson.images[0] && attachmentsJson.images[0].s3_url
  let firstAttachmentUrl = studentAnswerImages && studentAnswerImages[0] && studentAnswerImages[0].s3_url
  const activeExperience = useSelector(activeAdjustedExperienceSelector());
  const aiModel = useSelector(aiModelSelector());
  const aiModelTemp = useSelector(aiModelTempSelector());
  const enabledFeatures = useSelector(enabledFeaturesSelector())
  const aiAssistantSettings = useSelector(aiAssistantSettingsSelector());
  const mrIntl = useTranslate()
  const chooseAIModalEnabled = enabledFeatures.choose_ai_model;

  if (!enabledFeatures.with_question_images_in_grading) {
    markscheme = removeHTMLTagsFromText(markscheme, true);
    answerExplanation = removeHTMLTagsFromText(answerExplanation, true);
    question = removeHTMLTagsFromText(questionText, true);
  }
  
  if (questionType === "mcq_single"){
    rubricType = "comments_only" // overriding to generate comments only - we already have marks for MCQ - regardless for MYP/DP/Non-IB
  }
  
  let isMYP = rubricType && (rubricType === "myp_achievement_level" || rubricType === "criteria_with_points");
  let isGradeWithAIAllowed = markscheme || answerExplanation;
  
  const sseRef = useRef(null);
  const generatedGradesRef = useRef(null);
  // const { isStandardPlan, hasMaxOrPlusPlan } = getCurrentPlanStatus(currentUser);
  
  const showModal = () => {
    if (enabledFeatures.grade_with_ai) {
      setIsModalOpen(true);
    }
  };
  const handleOk = () => {
    if(isMYP){
      onSuccess(suggestedGrade.comments, null, suggestedGrade.criteria);
    } else if (rubricType === "comments_only") {
      onSuccess(suggestedGrade.comments, null, []);
    }
    else {
      // points_with_comments or points_with_detailed_comments
      onSuccess(suggestedGrade.comments, suggestedGrade.points, []);
      
      // TODO: handle points_with_detailed_comments comments later
      // const comments = (suggestedGrade.comments.overall && suggestedGrade.comments.overall[0] && suggestedGrade.comments.overall[0].comment) || suggestedGrade.comments.overall[0]
      // onSuccess(comments, suggestedGrade.points, []);
    }
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showCustomTooltip = (id) => {
    console.log("hovered", id);
  }
  const addClassToPhrasesInStudentAnswer = (studentAnswer, commentsByPhrases, className) => {
    for (let i = 0; i < commentsByPhrases.length; i++) {
      let phrase = commentsByPhrases[i].phrase;
      let comment = commentsByPhrases[i].comment;
      // Escape special characters in the phrase for regex
      let escapedPhrase = phrase.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
      // Create regex with global flag to replace all occurrences
      let regex = new RegExp(escapedPhrase, 'g');
      let uniqueId = getUniqueId(10)
      // Replace occurrences with wrapped version (adding class)
      studentAnswer = studentAnswer.replace(regex, `<span class="${className} custom-highlight" onmouseover="document.getElementById('${uniqueId}').style.visibility = 'visible';" onmouseout="document.getElementById('${uniqueId}').style.visibility = 'hidden';">${phrase}<span class="custom-tooltip" id="${uniqueId}">${comment}</span></span>`);
      // studentAnswer = studentAnswer.replace(regex, `<customtooltip class="${className} custom-highlight" title="${comment}">${phrase}</customtooltip>`);
      console.log("studentAnswer phrase", escapedPhrase, studentAnswer);
    }
    return studentAnswer;
  }

  const getSuggestedGrades = async () => {

    setLoading(true);
    
    let rubricTypeMap = {
      "criteria_with_points": "myp_cwp",
      "myp_achievement_level": "comments_only", // or set to myp_cwp if we want per question levels also
      "comments_only": "comments_only",
      "points": "points_with_comments", // or set to points_with_detailed_comments if we want detailed comments
    }

    let rubric_type = rubricTypeMap[rubricType] || "comments_only" // setting default
    let data = {
      rubric_type: rubric_type, 
      question_text: question,
      student_answer: answer,
      // sample_answer: sampleAnswer
      // max_points: Number(maxPoints),
      // student_answer_url: firstAttachmentUrl,
      student_answer_files: studentAnswerAttachmentUrls,
      subject_label: activeExperience.custom_fields.subject_item_label,
    }
    let updatedPreviewImages = [...studentAnswerAttachmentUrls];
    if (questionType === "rds_table") {
      // For json
      // const responseJSON = userResponse.response_json || {}
      // data.student_answer = responseJSON?.segment_data?.table_data

      // for image
      const tableElement = document.querySelector(`[data-id="segment-id-${segmentId}"] .rds-table`);
      const imageUrl = await getHtml2CanvasUrl({ element: tableElement });
      updatedPreviewImages.push(imageUrl);
    }
    if (questionType === "fabric_drawing") {
      const canvasElement = document.querySelector(`${`[data-id="segment-id-${segmentId}"]`} .canvas-container`);
      const imageUrl = await getHtml2CanvasUrl({ element: canvasElement });
      updatedPreviewImages.push(imageUrl);
    }
    if (questionType === "ggb_graph" || questionType === "desmos_graph") {
      const responseJSON = userResponse.response_json || {};
      const png64data = responseJSON?.segment_data?.pngBase64;
      const imageUrl = await uploadImageAndGetUrl(png64data);
      if(imageUrl) {
      updatedPreviewImages.push(imageUrl);
      }
    }
    if (questionType === "audio_input" || questionType === "video_input") {
      const responseJSON = userResponse.response_json || {};
      studentAnswer = responseJSON?.segment_data?.attachments[0]?.url;
    } 

    setPreviewImages(updatedPreviewImages);

    data.student_answer_files = updatedPreviewImages;
    if (markscheme) {
      data.markscheme = markscheme;
    }

    if (answerExplanation) {
      data.answer_explanation = answerExplanation;
    }

    if (enabledFeatures.ai_assistant_settings && aiAssistantSettings?.grading) {
      const gradingSettings = aiAssistantSettings.grading;
      data.strictness = gradingSettings.strictness;
      data.feedback_language = gradingSettings.feedback_language;
      data.feedback_length = gradingSettings.feedback_length;
      data.feedback_tone = gradingSettings.feedback_tone;
      data.additional_instructions = gradingSettings.additional_instructions;
      data.use_seeds_for_grading = gradingSettings.use_seeds_for_grading;
      data.decimal_values_in_points = gradingSettings.decimal_values_in_points;
    }

    if(aiAssistantSettings?.grading?.use_seeds_for_grading && (questionType === "cke_subjective" || questionType === "mcq_single")) {
      data.use_seeds_for_grading = true;
      data.user_response_id = userResponseId;
      data.question_type = questionType;
    }
    if (enabledFeatures.with_question_images_in_grading) {
      data = {
        ...data,
        with_images: true
      }
    }

    if(isMYP){
      let criteria = []
      scas.map((ca, i) => {
        criteria.push({
          id: ca.criterium_id,
          title: `${ca.custom_fields.title}-${ca.custom_fields.label}`,
          marks: parseInt(ca.custom_fields.max_points),
        });
      })
      data.criteria = criteria
    }
    if(rubricType === "points"){
      data.max_points = Number(maxPoints)
    }
    if(aiModel) {
      data.model = aiModel
    }
    if(chooseAIModalEnabled) { // temporary open only for Korea Jeju University experiment
      data.temperature = 0.5
    }
    if(aiModelTemp) { // override temperature if set
      data.temperature = aiModelTemp
    }
    console.log("finalData==>", data);
    const token = getFromLS("token");
    const abortController = new AbortController()
    if(sseRef) {
      sseRef.current = {
        abortSse : () => {
          abortController.abort();
        }
      }
    }

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal: abortController.signal
    };
    config = updateConfigHeaderForDemoUseStagingAI(config)
    try {
      const response = await axios.post(
        `${getAIServiceBaseURL()}/grade`,
        data,
        config
      );
      console.log("setSuggestedGrades==>", response);

      
      if(rubric_type === "points_with_detailed_comments"){
        // let responseData = {
        //   "comments": {
        //       "grammar": [
        //           {
        //               "comment": "Incorrect grammar, should be 'Ultrasound refers to sound waves'.",
        //               "phrase": "Ultrasound refers to sond waves"
        //           },
        //           {
        //               "comment": "Incorrect grammar, should be 'Ultrasound-based sonar systems reduce the impact'",
        //               "phrase": "Ultrasound-based sonar systems the impact"
        //           }
        //       ],
        //       "logic": [
        //           {
        //               "comment": "Incorrect logic. Ultrasound actually has a limited range in water due to increased absorption at higher frequencies.",
        //               "phrase": "Due to short wavelength, ultrasound travels greater distance with less diffraction"
        //           }
        //       ],
        //       "overall": [
        //           "The student provided a comprehensive response exhibiting a good understanding of the advantages of using ultrasound in sonar systems. However, there were a few spelling and grammar mistakes, as well as one incorrect logic statement. The provided answer satisfies the requirements of the mark scheme and thus receives full points."
        //       ],
        //       "spelling": [
        //           {
        //               "comment": "Incorrect spelling, should be 'sound waves'.",
        //               "phrase": "sond waves"
        //           },
        //           {
        //               "comment": "Incorrect spelling, should be 'higher frequency'.",
        //               "phrase": "highr frequency"
        //           },
        //           {
        //               "comment": "Incorrect spelling, should be 'enables'.",
        //               "phrase": "enabes"
        //           },
        //           {
        //               "comment": "Incorrect spelling, should be 'various'.",
        //               "phrase": "vaious"
        //           }
        //       ]
        //   },
        //   "points": 2
        // }

        let grammarComments = response.data.comments.grammar
        let spellingComments = response.data.comments.spelling
        let logicComments = response.data.comments.logic
        let studentAnswerToUse = firstAttachmentUrl ? response.data.studentAnswerFromFile : studentAnswer
        
        let modifiedAnswer = addClassToPhrasesInStudentAnswer(studentAnswerToUse, grammarComments, "grammar-mistakes-highlight")
        modifiedAnswer = addClassToPhrasesInStudentAnswer(modifiedAnswer, spellingComments, "spelling-mistakes-highlight")
        modifiedAnswer = addClassToPhrasesInStudentAnswer(modifiedAnswer, logicComments, "logic-mistakes-highlight")

        // modifiedAnswer = parse(modifiedAnswer, {
        //   replace: (item) => {
        //     console.log("studentAnswer phrase item", item);
        //     if (item.name == "customtooltip") {
        //       return (
        //         <Tooltip
        //           {...item.attribs}
        //         ></Tooltip>
        //       );
        //     }
        //   },
        // })
        console.log("studentAnswer phrase modifiedAnswer", modifiedAnswer);
        setStudentAnswerHTML(modifiedAnswer)
      }

      setSuggestedGrades(response.data);
      setLoading(false)
      setTimeout(() => {
        console.log("Rendering math");
        renderMathInElement(generatedGradesRef.current, true);
      }, 100);

    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (isModalOpen) {
      getSuggestedGrades()
    }
  }, [isModalOpen]);


  const isSuggestedGradeValid = (suggestedGrade) => {
    console.log("isSuggestedGradeValid==>", suggestedGrade);
    if(isEmpty(suggestedGrade)){
      return false
    }
    if(!suggestedGrade.comments){
      return false
    }
    if(rubricType === "criteria_with_points"){
      if(!suggestedGrade.criteria || suggestedGrade.criteria.length === 0){
        return false
      }
    }
    if(rubricType === "points"){
      if(suggestedGrade.points == null || suggestedGrade.points === "undefined"){
        return false
      }
      // TODO: later for rubricType === "points_with_detailed_comments"
      // if(!suggestedGrade.comments.overall || !suggestedGrade.comments.overall[0] || !suggestedGrade.comments.overall[0].comment){
      //   return false
      // }
    }
    return true
  }

  const Modaltitle = () => {
    return (
      <Space>
        {mrIntl("CheckGradeWithAI.assistant_autograde")}
        <Tag className="ai-header-beta-tag">{mrIntl("CommonText.beta")}</Tag>
        {enabledFeatures.ai_assistant_settings && <AIAssistantSettings  defaultActiveTab={"grading"} disabled={loading} showTransparentIconOnly={true}/>}
      </Space>
    );
  }

  const onMenuClick = ({ item, key }) => {
    console.log('click', item, key);
    setStrictnessValue(key);
  };
  const items = [
    {
      key: 'lenient',
      label: 'Lenient',
    },
    {
      key: 'moderate',
      label: 'Moderate',
    },
    {
      key: 'strict',
      label: 'Strict',
    },
  ];

  return (
    <>
      <Space>
        <MagicButton
          tooltipTitle={
            // mrIntl("CheckGradeWithAI.grade_with_ai")
            enabledFeatures.grade_with_ai && !isGradeWithAIAllowed // enabledFeatures.grade_with_ai - added this condition to show the tooltip only when grade_with_ai is enabled and sampleAnswer is not present - otherwise allow clicking the button to open upgrade plan modal
            ? mrIntl("CheckGradeWithAI.no_explanation_cannot_suggest_grades")
            : mrIntl("CheckGradeWithAI.grade_with_ai")
        }
          shape="default"
          // type="text"
          // ghost={true}
          onClick={showModal}
          disabled={enabledFeatures.grade_with_ai && !isGradeWithAIAllowed} // enabledFeatures.grade_with_ai - added this condition to show the tooltip only when grade_with_ai is enabled and sampleAnswer is not present - otherwise allow clicking the button to open upgrade plan modal
          text={mrIntl("CheckGradeWithAI.grade_with_ai")}
          feature_code="grade_with_ai"
        />
        {enabledFeatures.ai_assistant_settings ? (
          <AIAssistantSettings
            disabled={!isGradeWithAIAllowed}
            defaultActiveTab={"grading"} 
          />
        ) : null}
        {/* <Dropdown menu={{
          items: items,
          onClick: onMenuClick
          }} >
          <Button style={{textTransform: "capitalize"}}>
          {strictnessValue}
          <DownOutlined />
          </Button>
          </Dropdown> */}
      </Space>
      <Modal
        title={<Modaltitle />}
        open={isModalOpen}
        width={1000}
        wrapClassName={"grading-with-ai-modal"}
        // okText={mrIntl("CheckGradeWithAI.use_this")}
        onOk={handleOk}
        onCancel={handleCancel}
        // okButtonProps={{
        //   disabled: loading,
        // }}
        footer={
          <ModalFooter
            loading={loading}
            suggestedGrade={suggestedGrade}
            handleCancel={handleCancel}
            handleOk={handleOk}
            getSuggestedGrades={getSuggestedGrades}
            sseRef={sseRef}
            isSuggestedGradeValid={isSuggestedGradeValid}
          />
        }
      >
        {loading ? (
          <Skeleton active />
        ) : (
          <Row>
            <Col span={14}>
              <h5>
                <MrTranslate id={"CKETextAnswer.students_answer_heading"} />
              </h5>
              {/* {firstAttachmentUrl ? 
                <img src={firstAttachmentUrl} alt="student answer" style={{maxWidth: "100%"}} /> */}
              {studentAnswer && (
                <ShowTextContent
                  className="show-text-answer"
                  dangerouslySetInnerHTML={{ __html: studentAnswerHTML }}
                  // dangerouslySetInnerHTML={{ __html:
                  //   parse(studentAnswerHTML, {
                  //     replace: (item) => {
                  //       console.log("studentAnswer phrase item", item);
                  //       if (item.name == "customtooltip") {
                  //         return (
                  //           <Tooltip
                  //             {...item.attribs}
                  //           ></Tooltip>
                  //         );
                  //       }
                  //     },
                  //   })
                  // }}
                  // style={{ padding: "50px" }}
                />
              )}
              {/* Using previewImages instead of studentAsnwerAttachmentUrls as it has html2canvas images */}
              {previewImages && previewImages.length > 0
                ? previewImages.map((url, i) => {
                    return (
                      <img
                        key={`student-answer-attachment-${i}`}
                        src={url}
                        alt="student answer"
                        style={{ maxWidth: "100%" }}
                      />
                    );
                  })
                : null}

                {/* Not showing userRespinse as not grading audio and video questions for not */}
              {/* : userResponseAttachments &&
                userResponseAttachments.length > 0 && (
                userResponseAttachments?.map((attachment, i) => (
                  <MediaShow
                    key={i}
                    file={attachment}
                    mediaType={
                      questionType === "audio_input"
                        ? "audio"
                        : questionType === "video_input" && "video"
                    }
                  />
                ))
              )} */}
              {/* {studentAnswerAttachmentUrls?.map((url, index) => (
                <img key={`student-answer-attachment-${index}`} src={url} alt="student answer" style={{ maxWidth: "100%" }} />
              ))} */}
            </Col>
            <Col span={10} className="p-20">
              {isSuggestedGradeValid(suggestedGrade) ? (
                <Space direction="vertical" ref={generatedGradesRef}>
                  {(rubricType === "points" ||
                    rubricType === "criteria_with_points") && (
                    <span>
                      <b>
                        {/* No AL level grading for now */}
                        {/* {rubricType === "myp_achievement_level"
                        ? mrIntl("CheckGradeWithAI.level_modal")
                        : rubricType === "criteria_with_points" 
                        ? "Criteria points"
                        : mrIntl("CheckGradeWithAI.points_modal")} */}
                        {rubricType === "criteria_with_points"
                          ? "Criteria points"
                          : mrIntl("CheckGradeWithAI.points_modal")}

                        {!isMYP && ": "}
                      </b>
                      {isMYP && <br></br>}
                      {!isEmpty(suggestedGrade) &&
                        (isMYP ? (
                          <Space direction="vertical">
                            {suggestedGrade.criteria.map((c) => {
                              return `${c.title}: ${c.points}`;
                            })}
                          </Space>
                        ) : (
                          suggestedGrade.points
                        ))}
                    </span>
                  )}
                  <span>
                    <b>{mrIntl("CheckGradeWithAI.comments_modal_text")}</b>
                    <br></br>
                    <span>
                      {!isEmpty(suggestedGrade) &&
                        !isEmpty(suggestedGrade.comments) && (
                          <RenderHtml text={suggestedGrade.comments} />
                        )}
                    </span>

                    {/* TODO handle detailed comments later */}
                    {/* {!isEmpty(suggestedGrade) &&
                        !isEmpty(suggestedGrade.comments) &&
                        (isMYP ? suggestedGrade.comments : (suggestedGrade.comments.overall && suggestedGrade.comments.overall[0] && suggestedGrade.comments.overall[0].comment) || suggestedGrade.comments.overall[0])} */}
                  </span>
                </Space>
              ) : (
                <Alert
                  type="warning"
                  message={mrIntl(
                    "CommonText.something_went_wrong_please_try_again"
                  )}
                />
              )}
            </Col>
          </Row>
        )}
      </Modal>
    </>
  );
};
export default CheckGradeWithAI;
