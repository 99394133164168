import "./highlight-words.scss";
import React, { useEffect, useState } from "react";

import { Button, Checkbox, Flex, Radio, Switch, Tooltip } from "antd";
import { getUniqueId } from "/src/lib/utils/helperMethods";
import { getParsedJSONObject } from "/src/views/Segments/InteractiveHelpers";
import { cloneDeep, debounce } from "lodash";
import SegmentItemFooter from "/src/views/Segments/SegmentItemDetail/SegmentItemFooter";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  getEmbeddedSelector,
  partnerNameSelector,
} from "/src/views/Auth/Login/selector";
import { actions as firestoreInteractionActions } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/redux";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { DeleteOutlined } from "@ant-design/icons";
import HighlightEditor from "./HighlightEditor";
import HighlightSelector from "./HighlightSelector";

export function wrapSelectedWordInSpan(
  text,
  selectedTextRanges,
  callback,
) {
  // Validate input
  if (!text || !Array.isArray(selectedTextRanges) || selectedTextRanges.length === 0) {
    return text;
  }

  const sortedRanges = selectedTextRanges
    .filter(
      (range) =>
        range.startIndex >= 0 &&
        range.endIndex <= text.length &&
        range.startIndex <= range.endIndex
    )
    .sort((a, b) => b.startIndex - a.startIndex);

  // Process each range from end to start
  let result = text;
  result = callback(sortedRanges, result);
  return result;
}

const HighlightWords = (props) => {
  const {
    question_type,
    showCorrectAnswerSwitch,
    segmentSettings,
    triggerStateUpdate,
    setRenderMath,
    segmentFooterAttributes,
    experienceViewMode,
  } = props;
  let {
    parsedJSONObject,
    createMode,
    answerMode,
    presentationMode,
    autoCheckMode,
  } = getParsedJSONObject(props);

  const dispatch = useDispatch();
  const partnerName = useSelector(partnerNameSelector());
  const embedded = useSelector(getEmbeddedSelector());
  const mrIntl = useTranslate();

  let textAnswerString = props.text_answer || "";
  const propsAttemptStatus = props.attempt_status || {};
  let attemptStatusObject = {
    reset_count: propsAttemptStatus.reset_count || 0,
    json_attempted: propsAttemptStatus.json_attempted || false,
  };

  if (createMode) {
    parsedJSONObject = {
      segment_data: {
        sentence: "",
        options: [],
        multiSelect: false,
      },
      segment_version: "1.0.1",
    };
    if (question_type === "highlight_words") {
      parsedJSONObject = props.value || parsedJSONObject;
    }
  }

  if (answerMode && !props.response_json) {
    const resettedAnswers = resetAnswers(parsedJSONObject.segment_data.options);
    parsedJSONObject.segment_data.options = resettedAnswers;
  }

  if (autoCheckMode) {
    let question_json = cloneDeep(props.teacher_json);
    let answer_json = cloneDeep(parsedJSONObject);
    parsedJSONObject = checkResponses(question_json, answer_json);
  }

  const [state, setState] = useState(parsedJSONObject ?? {});
  const [textAnswer, setTextAnswer] = useState(textAnswerString);
  const [attemptStatus, setAttemptStatus] = useState(attemptStatusObject);
  const [stateUpdatedCount, setStateUpdatedCount] = useState(0);
  const [stateSetting, setStateSetting] = useState({
    autoCheckMode: autoCheckMode,
    quickCheckModeEnabled: false,
    showCorrectAnswerSwitch: showCorrectAnswerSwitch,
    showCorrectAnswerSwitchValue: false,
  });
  const [myAnswer, setMyAnswer] = useState(state);
  const screenSettings = props.screenSettings || {};
  const { segment_data: stateSegment = {} } = state;
  const { multiSelect } = stateSegment;

  let currentJSON, text_answer, attempt_status;
  if (presentationMode && !stateSetting.showCorrectAnswerSwitchValue) {
    currentJSON = parsedJSONObject;
    text_answer = textAnswerString;
    attempt_status = attemptStatusObject;
  } else {
    currentJSON = state;
    text_answer = textAnswer;
    attempt_status = attemptStatus;
  }

  const segment_data = currentJSON?.segment_data || {};
  const options = segment_data.options ? segment_data.options : [];

  const optionsEnabled = createMode || answerMode;

  useEffect(() => {
    if (setRenderMath) {
      setRenderMath(getUniqueId());
    }
  }, []);

  useEffect(() => {
    checkAttemptStatus();
  }, [stateSegment?.options]);

  useEffect(() => {
    if (createMode) {
      if (props.onChange) {
        let teacher_json = cloneDeep(state);
        props.onChange(teacher_json);
      }
      if (props.onSubmit) {
        // let teacher_json = cloneDeep(state)
        // props.onSubmit(teacher_json)
        console.log("On submit is available now", props.onSubmit);
      }
    } else if (answerMode && stateUpdatedCount > 0) {
      if (props.onChange) {
        let result = {
          response_json: cloneDeep(state),
          // text_answer: cloneDeep(textAnswer),
          attempt_status: cloneDeep(attemptStatus),
        };

        props.onChange(result);
      }
    }
  }, [stateUpdatedCount]);

  const checkAttemptStatus = () => {
    const isAtleastOneSelected = stateSegment.options.some((ca) => ca.checked);

    if (answerMode) {
      if (isAtleastOneSelected != attemptStatus.json_attempted) {
        setAttemptStatus({
          ...attemptStatus,
          json_attempted: isAtleastOneSelected,
        });
      }

      if (partnerName === "osc") {
        setStateSetting({
          ...stateSetting,
          allowCheckAnswer: true,
        });
      }
    }
  };

  const saveState = (state) => {
    setState(state);
    setStateUpdatedCount((stateUpdatedCount) => stateUpdatedCount + 1);
    console.log("stateUpdated count ==>", stateUpdatedCount);
  };

  const saveTextAnswer = (data) => {
    setTextAnswer(data);
    setStateUpdatedCount((stateUpdatedCount) => stateUpdatedCount + 1);
  };

  function checkResponses(question_json, answer_json) {
    if (!question_json || !answer_json) {
      return;
    }
    let q_segment_data = question_json.segment_data || {};
    let a_segment_data = answer_json.segment_data || {};

    let q_options = q_segment_data.options || [];
    let a_options = a_segment_data.options || [];

    let correct_status = { all_correct: false };
    if (a_options) {
      let i = 0;
      while (i < q_options.length) {
        let q_option = q_options[i];
        let a_option =
          a_options.find((option) => option.id === q_option.id) || {};

        if (q_option.checked && a_option.checked) {
          a_option.correct = true;
          correct_status.all_correct = true;
        }

        if (partnerName === "osc" && q_option && q_option.checked) {
          a_option.correct = true;
        }

        i++;
      }
    }

    if (!autoCheckMode && props.onAutoCheck) {
      props.onAutoCheck(correct_status);
    }

    if (partnerName === "osc" && embedded) {
      // postMessage to parent iframe to let osc know if correct or not when student attempts
      window.parent.postMessage({ correct: correct_status.all_correct }, "*");
    }

    return answer_json;
  }

  function resetAnswers(ans) {
    return ans.map((item) => ({ ...item, checked: false }));
  }

  const checkAnswer = (check = false) => {
    if (check) {
      let question_json = cloneDeep(props.teacher_json);
      let answer_json = cloneDeep(state);
      const newState = checkResponses(question_json, answer_json);

      if (partnerName === "osc") {
        // To make sure only sending to BE on checkAnswer click, not on answer select and try again
        console.log("updating userResponseConfig");
        dispatch(
          firestoreInteractionActions.setUserResponseConfigSuccess({
            userResponseConfig: { saveToAPDB: true },
          })
        );
      }
      setMyAnswer(state);
      saveState(newState);
    }

    let doNotShowExplanation = false;
    if (partnerName === "osc") {
      doNotShowExplanation = true;
    }

    // if(partnerName !== "osc"){
    setStateSetting({
      ...stateSetting,
      quickCheckModeEnabled: check,
      doNotShowExplanation: doNotShowExplanation,
      autoCheckMode: true,
    });
    // }

    if (setRenderMath) {
      setRenderMath(getUniqueId());
    }
  };

  const showCorrectAnswer = (value) => {
    let question_json, answer_json;
    if (value) {
      question_json = cloneDeep(props.teacher_json);
      answer_json = cloneDeep(props.teacher_json);
    } else {
      question_json = cloneDeep(props.teacher_json);
      answer_json = cloneDeep(myAnswer);
    }

    const newState = checkResponses(question_json, answer_json);
    saveState(newState);
    setStateSetting({
      ...stateSetting,
      quickCheckModeEnabled: true,
      showCorrectAnswerSwitchValue: value,
    });
    if (setRenderMath) {
      setRenderMath(getUniqueId());
    }
  };

  const resetQuestion = () => {
    let student_json = cloneDeep(props.student_json);
    const { options } = student_json.segment_data;
    student_json.segment_data.options = resetAnswers(options);

    if (partnerName === "osc") {
      // To make sure only sending to BE on checkAnswer click, not on answer select and try again
      console.log("updating userResponseConfig");
      dispatch(
        firestoreInteractionActions.setUserResponseConfigSuccess({
          userResponseConfig: { saveToAPDB: false },
        })
      );
    }

    setAttemptStatus({
      ...attemptStatus,
      reset_count: attemptStatus.reset_count + 1,
      json_attempted: false,
    });
    saveState(student_json);
    if (partnerName === "osc") {
      setStateSetting({
        ...stateSetting,
        allowCheckAnswer: false,
        quickCheckModeEnabled: false,
        doNotShowExplanation: false,
        autoCheckMode: false,
      });
    } else {
      setStateSetting({
        ...stateSetting,
        quickCheckModeEnabled: false,
      });
    }
    if (setRenderMath) {
      setRenderMath(getUniqueId());
    }
    if (props.onTryAgain) {
      props.onTryAgain();
    }
  };

  const toggleAnswer = () => {
    setStateSetting({
      ...stateSetting,
      quickCheckModeEnabled: !stateSetting.quickCheckModeEnabled,
      doNotShowExplanation: !stateSetting.doNotShowExplanation,
    });
  };

  const handleInput = debounce((innerText) => {
    const newState = {
      ...state,
      segment_data: {
        ...state.segment_data,
        sentence: innerText,
      },
    };
    saveState(newState);
  }, 1000);

  const handleDeleteClick = (id) => {
    const newState = {
      ...state,
      segment_data: {
        ...state.segment_data,
        options: state.segment_data.options.filter((ca) => id !== ca.id),
      },
    };

    saveState(newState);
  };

  const toggleMultiSelect = () => {
    const newState = {
      ...state,
      segment_data: {
        ...state.segment_data,
        multiSelect: !multiSelect,
        options: stateSegment.options.map((ca) => ({
          ...ca,
          checked: false,
        })),
      },
    };

    saveState(newState);
  };

  const toggleChecked = (id, checked) => {
    const { multiSelect } = stateSegment;

    if (multiSelect) {
      return stateSegment.options.map((ca) =>
        id === ca.id ? { ...ca, checked: checked } : ca
      );
    }
    return stateSegment.options.map((ca) => ({
      ...ca,
      checked: id === ca.id && checked,
    }));
  };

  const handleCheck = (id, e) => {
    const { checked } = e.target;

    const newState = {
      ...state,
      segment_data: {
        ...state.segment_data,
        options: toggleChecked(id, checked),
      },
    };
    saveState(newState);
  };

  const classNameByAssessment = (ans) => {
    if (!presentationMode) return "";

    if (ans.correct) return "correct-option";
    if (ans.checked && (!ans.hasOwnProperty("correct") || !ans.correct)) {
      return "incorrect-option";
    }
    return "";
  };

  const handleHotCheck = (uniqueId, selectedText, startIndex, endIndex) => {
    const newState = {
      ...state,
      segment_data: {
        ...state.segment_data,
        options: [
          ...state.segment_data.options,
          { id: uniqueId, label: selectedText, startIndex, endIndex },
        ],
      },
    };

    saveState(newState);
  };

  const handleAnswerSelect = (id) => {
    let { options } = state.segment_data;

    if (multiSelect) {
      options = options.map((option) => {
        if (id === option.id) {
          return { ...option, checked: !option.checked };
        } else return option;
      });
    } else {
      options = options.map((option) => {
        if (id === option.id) {
          return { ...option, checked: true };
        } else return { ...option, checked: false };
      });
    }

    const newState = {
      ...state,
      segment_data: {
        ...state.segment_data,
        options,
      },
    };

    saveState(newState);
  };

  return (
    <div className="text-initial w-full">
      <>
        <div className={createMode ? "" : "m-t-25"}>
          {mrIntl(
            "HighlightWords.highlight_words_in_the_text_to_create_student_answer_options"
          )}
        </div>
        {answerMode ? (
          <HighlightSelector
            textContent={stateSegment.sentence}
            options={stateSegment.options}
            onSelect={handleAnswerSelect}
          />
        ) : (
          <HighlightEditor
            textContent={stateSegment.sentence}
            options={stateSegment.options}
            onInput={handleInput}
            onCheck={handleHotCheck}
            presentationMode={presentationMode}
            gradingMode={"apGradeTest" === experienceViewMode}
            createMode={createMode}
          />
        )}

        {createMode && (
          <>
            <Flex align="center" justify="space-between">
              <div>{mrIntl("HighlightWords.select_the_correct_answers")}</div>
                <Flex gap="8px" align="center">
                  <Switch
                    checked={multiSelect}
                    size={"small"}
                    onChange={toggleMultiSelect}
                    disabled={!optionsEnabled}
                  />
                  <div>{mrIntl("HighlightWords.multiselect")}</div>
                </Flex>
            </Flex>

            <Flex vertical gap="6px">
              {stateSegment.options?.map((option) => (
                <Flex
                  key={`correct-answer-${option.id}`}
                  align="center"
                  className={`highlight-word-options ${classNameByAssessment(
                    option
                  )}`}
                >
                  {multiSelect ? (
                    <Checkbox
                      onChange={(e) => handleCheck(option.id, e)}
                      checked={option?.checked}
                      disabled={!optionsEnabled}
                      className="m-r-10"
                    />
                  ) : (
                    <Radio
                      onChange={(e) => handleCheck(option.id, e)}
                      checked={option?.checked}
                      disabled={!optionsEnabled}
                    />
                  )}
                  <div className="highlight-words-option">{option.label}</div>
                  {createMode && (
                    <Tooltip
                      title={mrIntl("CommonText.delete")}
                      placement="bottom"
                    >
                      <Button
                        onClick={() => handleDeleteClick(option.id)}
                        type="text"
                        shape="circle"
                        icon={<DeleteOutlined />}
                      />
                    </Tooltip>
                  )}
                </Flex>
              ))}
            </Flex>
          </>
        )}
      </>

      <SegmentItemFooter
        createMode={createMode}
        answerMode={answerMode}
        presentationMode={presentationMode}
        segmentFooterAttributes={segmentFooterAttributes}
        segmentSettings={segmentSettings}
        segmentStateSettings={stateSetting}
        screenSettings={screenSettings}
        setRenderMath={setRenderMath}
        saveTextAnswer={saveTextAnswer}
        // resetQuestion={partnerName === "osc" ? null : resetQuestion}
        resetQuestion={resetQuestion}
        checkAnswer={checkAnswer}
        showCorrectAnswer={partnerName === "osc" ? null : showCorrectAnswer}
        toggleAnswer={partnerName === "osc" ? toggleAnswer : null}
        questionType={partnerName === "osc" ? "mcq_single" : null}
      ></SegmentItemFooter>
    </div>
  );
};

export default HighlightWords;
