import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, Card, Col, Input, List, message, notification, Row, Space, Spin, Tag, Tooltip } from "antd";
import {
  MessageOutlined,
  CloseCircleOutlined,
  SendOutlined,
  CopyOutlined,
  FullscreenOutlined,
  FullscreenExitOutlined,
  CloseOutlined,
  ReloadOutlined
} from "@ant-design/icons";
import { ImMagicWand } from "react-icons/im";
import { FaRegStopCircle } from "react-icons/fa";
import axios from "axios";
import { getAIServiceBaseURL, getFromLS, renderMathInElement, writeToClipboardPolyfill } from "/src/lib/utils/helperMethods";
import { swearWords } from "/src/lib/utils/swearWords";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import MagicButton from "/src/views/Segments/GenerateAI/MagicButton";
import Spinner from "/src/components/UI/Spinner/Spinner";
import TypingEffect from "./TypingEffect/TypingEffect";
import RenderHtml from "/src/components/UI/RenderHtml/RenderHtml";
import "./TalkToGPT.scss"
import { useSelector } from "react-redux";
import { getInternetStatusSelector } from "/src/views/Experiences/selector";
import RateAiResult from "/src/views/Segments/GenerateAI/RateAiResult";
import { aiModelSelector } from "/src/views/Orgs/selector";
import { fetchWithStream } from "/src/views/Segments/GenerateAI/generateAIHelperMethods";
import AIAssistantSettings from "/src/views/Segments/GenerateAI/AIAssistantSettings";
import { currentUserSelector, enabledFeaturesSelector } from "/src/views/Auth/Login/selector";
const { TextArea } = Input;

const CopyResponse = ({ text, index, handleCopyClick }) => {
  const [copied, setCopied] = useState(false);
  const mrIntl = useTranslate()

  // const handleCopyClick = () => {
  //   // const elementHtml = `<span className="copied-span">${text}</span>`
  //   writeToClipboardPolyfill(text);
  //   message.success("Copied")
  //   // const htmlElement = document.querySelector(`.message-content-${index}`);
  //   // htmlElement.classList.add('copied-span');
  //   // const range = document.createRange();
  //   // range.selectNode(htmlElement);

  //   // // Create a new selection and add the range to it
  //   // const selection = window.getSelection();
  //   // selection.removeAllRanges();
  //   // selection.addRange(range);
  //   // console.log('CopyResponse==>', htmlElement, range, selection)

  //   // // Copy the selected HTML to the clipboard
  //   // document.execCommand("copy");

  //   // // Deselect the HTML element
  //   // selection.removeAllRanges();
  // };
  // const copyToClipboard = () => {
  //   const element = elementRefs.current[index].current;
  //   console.log("CopyResponse==>", element);
  //   if (!element) {
  //     console.error("Error: element not found");
  //     return;
  //   }

  //   const elementHtml = element.outerHTML;
  //   navigator.permissions
  //     .query({ name: "clipboard-write" })
  //     .then((result) => {
  //       if (result.state === "granted" || result.state === "prompt") {
  //         navigator.clipboard
  //           .writeText(elementHtml)
  //           .then(() => {
  //             console.log(`Copied ${elementHtml} to clipboard`);
  //           })
  //           .catch((err) => {
  //             console.error(
  //               `Error copying ${elementHtml} to clipboard: `,
  //               err
  //             );
  //           });
  //       } else {
  //         console.error("Clipboard write permission denied");
  //       }
  //     });
  // };

  return (
    <>
      <MrTranslate id={"TalkToGPT.assistant"} />{" "}
      <span>
        <Tooltip title={mrIntl("TalkToGPT.copy_text")} placement="right">
          {copied ? (
            <MrTranslate id={"TalkToGPT.copied"} />
          ) : (
            <Button
              type="text"
              icon={<CopyOutlined />}
              onClick={(event) => {
                handleCopyClick(text);
                event.stopPropagation();
              }}
            />
          )}
        </Tooltip>
      </span>
    </>
  );
};

const MessageListItem = ({item, index, handleCopyClick, finalClass, senderName}) => {
  const messageListItemRef = useRef();

  useEffect(() => {
    if (messageListItemRef && messageListItemRef.current) {
      renderMathInElement(messageListItemRef.current, true);
    }
  }, []);

  return (
    <List.Item key={`message-${index}`} className={`ap-chat-bubble ${finalClass}`}>
      <Row className="message-container" ref={messageListItemRef}>
        <Col
          className="message-inner"
          span={finalClass === "right" ? 20 : 20}
          offset={finalClass === "right" ? 4 : 0}
        >
          <span className={`sender-name`}>{senderName}</span>
         {item.role !== "user" && index !== 0 && <span className="rate-ai-results"><RateAiResult generatedData={item.content} key={`message-${index}-rate-ai`} action="chat" /></span>}
          <br></br>
          <span
            className={`message-content-${index}`}
          >
            {/* {item.content} */}
            <RenderHtml text={item.content} />
          </span>
          <br></br>
        </Col>
      </Row>
    </List.Item>
  )
}

const StreamingItem = ({ item, index, handleCopyClick, streamData, messages, chatTxt, initStreaming, setMessages, setStreamData, setLoading, chatBottomRef, setError, sseRef }) => {

  const [currentMsg, setCurrentMsg] = useState(null)
  const aiModel = useSelector(aiModelSelector());



  function scrollToBottomOfChat(options = {}) {
    console.log("scrollToBottomOfChat =====>", options, chatBottomRef);
    if (chatBottomRef && chatBottomRef.current) {
      chatBottomRef.current.scrollIntoView(options);
    }
  }

  useEffect(() => {
    scrollToBottomOfChat({ behavior: "smooth", block: "start", inline: "end" });
  }, [currentMsg]);

  let finalClass = "left";
  let senderName;

  if (item.role === "user") {
    finalClass = "right";
    senderName = "You";
  } else {
    senderName = <CopyResponse text={item.content} index={index} handleCopyClick={handleCopyClick} />;
  }

  useEffect(() => {
    // if(isStreamStarted) {
      // const finalMsg = {
      //   role: "assistant",
      //   content: "",
      // };
      
      // removing the last index here because don't want to send the last message to the server because it is empty and we need the empty message to show the typing effect
      const finalMsgList = [...messages].slice(0, -1).slice(1);
      console.log("finalMsgList ====>", finalMsgList);
      let dataToPost = { messages: finalMsgList }
      if(aiModel) {
        dataToPost.model = aiModel
      }
      let prevMsg = "";
      try {
        const fetchOptions = {
          url: `${getAIServiceBaseURL()}/chat`,
          dataToPost: dataToPost,
          content: prevMsg,
          setLoading: setLoading,
          setError: setError,
          sseRef: sseRef,
          removeVerticalCursor: true,
          successCallback: (data, responseStatus) => {
            console.log("data ======>", data);
            if(responseStatus === "stop") {
              const newObject = {
                role: "assistant",
                content: data,
              };
              setMessages((prevMessage) => {
                prevMessage[prevMessage.length - 1] = newObject
                return [...prevMessage]
              })
              setStreamData((prevData) => ({
                ...prevData,
                responseCompleted: true,
              }));
            } else {
              setCurrentMsg(data)
              console.log("currentMsg ====>", currentMsg, data);
            }
          },
          errorCallback: () => {
            setStreamData(prevData => ({
              ...prevData,
              isStreaming: false
            }))
            setMessages(messages => messages.slice(0, -1));
          }
        }
        fetchWithStream(fetchOptions);
      } catch (error) {
        console.error(error);
      }
    // }
  }, [])

  return (
    currentMsg ? <List.Item key={`message-${index}`} className={`ap-chat-bubble ${finalClass}`}>
      <Row className="message-container">
        <Col
          className="message-inner"
          span={finalClass === "right" ? 20 : 20}
          offset={finalClass === "right" ? 4 : 0}
          onClick={() => handleCopyClick(item.content)}
        >
          <span className={`sender-name`}>{senderName}</span>
          <br></br>
          <span className={`message-content-${index}`}>
            {/* <RenderHtml text={currentMsg} /> */}
            <TypingEffect text={currentMsg} typingSpeed={10} isStreamStarted={streamData.isStreaming} setIsStreamStarted={(value) => {
              setStreamData((prevData) => ({
                ...prevData,
                isStreaming: value,
              }));
            }} responseCompleted={streamData.responseCompleted} />
          </span>
          <br></br>
        </Col>
      </Row>
    </List.Item> : null
  );
};

const TalkToGPT = (props) => {
  const [visible, setVisible] = useState(false);
  const defaultMsg = [
    {
      content: "Hi, How can I help you?",
      role: "assistant",
    },
  ]
  const [messages, setMessages] = useState(defaultMsg);
  const chatBottomRef = useRef(null);
  const inputRef = useRef(null);
  const [chatTxt, setChatTxt] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("")
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [streamData, setStreamData] = useState({
    isStreaming: false,
    responseCompleted: false,
  })
  
  const mrIntl = useTranslate();
  const internetStatus = useSelector(getInternetStatusSelector())

  // const abortControllerRef = useRef(new AbortController());
  // const abortController = new AbortController();

  const sseRef = useRef(null);


  function scrollToBottomOfChat(options = {}) {
    console.log("scrollToBottomOfChat =====>", options, chatBottomRef);
    if (chatBottomRef && chatBottomRef.current) {
      chatBottomRef.current.scrollIntoView(options);
    }
  }
  const handleModalClose = (e) => {
    if (e.target === e.currentTarget) {
      setError("")
      setVisible(false);
      setIsFullScreen(false);
      if(streamData.isStreaming) {
        stopStreaming()
      }
    }
  };
  // useEffect(() => {
  //   inputRef && inputRef.current.focus()
  // }, [])
  // useEffect(() => {
  //   scrollToBottomOfChat({ behavior: "smooth", block: "start", inline: "end" });
  // }, [messages]);

 

  useEffect(() => {
    if (loading || visible) {
      scrollToBottomOfChat({ block: "end", inline: "end" });
    }
  }, [loading, visible]);

  const sendMessage = async () => {
    if (!chatTxt || chatTxt.trim() == "") {
      return;
    }
    let containsExactWord = false;
    for (let i = 0; i < swearWords.length; i++) {
      let word = swearWords[i];
      let regex = new RegExp("\\b" + word + "\\b", "i"); // create a regular expression to match the exact word
      if (regex.test(chatTxt)) {
        containsExactWord = true;
        break;
      }
    }
    if (containsExactWord) {
      setError(
        mrIntl("TalkToGPT.we_cannot_provide_any_response_for_your_question")
      );
      scrollToBottomOfChat({
        behavior: "smooth",
        block: "end",
        inline: "end",
      });
      return;
    } else {
      setError("");
    }
  
    setLoading(true);
  
    const token = getFromLS("token");
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
  
    // here checking if chatTxt has new line or not and if it has then adding <br> tag in place of new line
    // in the second replace function we are checking if there are more than 2 spaces then we are replacing it with &nbsp;
    // we are doing this because the textarea is not preserving if we give new line or more than 2 spaces
    const formattedValue = chatTxt.replace(/\n/g, '<br>').replace(/ {2,}/g, (match) => '&nbsp;'.repeat(match.length));

    const finalMsg = {
      role: "user",
      content: formattedValue,
    };

    
  
    const finalMsgList = [...messages, finalMsg, {
      role: "assistant",
      content: "",
    }];
    console.log("finaklMsgList ====> line 334", finalMsgList);
    setMessages(finalMsgList);
    setChatTxt("");
    let prevMsg = "";
    setStreamData({
      ...streamData,
      isStreaming: true,
      responseCompleted: false
    })

    // try {
    //   scrollToBottomOfChat({ behavior: "smooth", block: "end", inline: "nearest" });

    //   const fetchOptions = {
    //     url: `${import.meta.env.VITE_AI_SERVICE_URL}/chat`,
    //     dataToPost: { messages: finalMsgList },
    //     setLoading: setLoading,
    //     content: prevMsg,
    //     successCallback: (data, responseStatus) => {
    //       if(responseStatus === "stop") {
    //         const newObject = {
    //           role: "assistant",
    //           content: data,
    //         };
    //         setMessages([...messages, finalMsg, newObject]);
    //         setIsStreamStarted(false)
    //       } else {
    //         // console.log("messages =====>", messages);
    //         // if(messages[messages.length - 1].role === "user") {
    //         //   const newObject = {
    //         //     role: "assistant",
    //         //     content: "",
    //         //   };
    //         //   setMessages([...messages, finalMsg, newObject]);
    //         // }
    //         setIsStreamStarted(true)
    //       }
          
    //     }
    //   }
    //   fetchWithStream(fetchOptions);
    //   // fetchWithStream(`${import.meta.env.VITE_AI_SERVICE_URL}/chat`, { messages: finalMsgList }, setLoading, null, null, prevMsg, (data) => {
        
    //   // })

    //   // fetchEventSource(`${import.meta.env.VITE_AI_SERVICE_URL}/chat`, {
    //   //   method: 'POST',
    //   //   ...config,
    //   //   body: JSON.stringify({
    //   //     stream: true,
    //   //     messages: finalMsgList,
    //   //   }),
    //   //   onmessage: (event) => {
    //   //     setLoading(false);
    //   //     const data = JSON.parse(event.data);
    //   //     if (data.choices[0].delta.content) {
    //   //       prevMsg += data.choices[0].delta.content
    //   //       const newObject = {
    //   //         role: "assistant",
    //   //         content: prevMsg,
    //   //         index: finalMsgList.length,
    //   //       };
    //   //       setMessages([...messages, finalMsg, newObject]);
    //   //     }     
    //   //   },        
    //   // });
    // } catch (error) {
    //   console.error(error);
    // }
  };

  const stopStreaming = () => {
    setStreamData({
      ...streamData,
      isStreaming: false,
    })
    if(loading) {
      setLoading(false);
    }
    console.log("stopStreaming ====>", sseRef.current);
    // setIsStreamStarted(false);
    if (sseRef && sseRef.current) {
      sseRef.current.abortSse();
    }
    // abortController.abort("User stopped generation");
    const finalMsgList = [...messages];
    // console.log("finalMsgList ====>", finalMsgList);

    finalMsgList.pop()
    setMessages(finalMsgList);
    // setLoading(false);
    // const fetchOptions = {
    //   url: `${import.meta.env.VITE_AI_SERVICE_URL}/chat`,
    //   dataToPost: { messages: finalMsgList },
    //   content: "",
    //   setLoading: setLoading,
    //   setError: setError,
    //   abortController: abortController,
    //   successCallback: (data, responseStatus) => {
    //     console.log("responseStatus ======>", data, responseStatus);
    //     // console.log("data ======>", data);
    //     // if(responseStatus === "stop") {
    //     //   const newObject = {
    //     //     role: "assistant",
    //     //     content: data,
    //     //   };
    //     //   setIsStreamStarted(false)
    //     //   setMessages((prevMessage) => {
    //     //     prevMessage[prevMessage.length - 1] = newObject
    //     //     return [...prevMessage]
    //     //   })
    //     // } else {
    //     //   setCurrentMsg(data)
    //     //   console.log("currentMsg ====>", currentMsg, data);
    //     // }
    //   }
    // }
    // fetchWithStream(fetchOptions);
  }
  
  // const sendMessage = async () => {
  //   if (!chatTxt) {
  //     return;
  //   }
  //   setLoading(true);
  //   const finalMsg = {
  //     role: "user",
  //     content: chatTxt,
  //     index: messages.length
  //   };
  //   const token = getFromLS("token");
  //   let prevMsg = ""
  //   const config = {
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   };
  //   const finalMsgList = [...messages, finalMsg];
  //   setMessages(finalMsgList);
  //   setChatTxt("");
  //   try {
  //     scrollToBottomOfChat({ behavior: "smooth", block: "end", inline: "nearest" });
  //     // const response = await axios.post(
  //     //   `${import.meta.env.VITE_AI_SERVICE_URL}/chat`,
  //     //   { messages: finalMsgList },
  //     //   config
  //     // );
  //     // const data = response.data;
  //     // setMessages([...finalMsgList, data]);
  //     fetchEventSource(`${import.meta.env.VITE_AI_SERVICE_URL}/chat`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization: `Bearer ${token}`,
  //       },
  //       body: JSON.stringify({
  //         stream: true,
  //         messages: finalMsgList
  //       }),
  //       // signal: ctrl.signal,
  //       onmessage: (event) => {
  //         const newObject = {
  //           role: "assistant",
  //           content: prevMsg, 
  //           index: finalMsgList.length,
  //           pending: true
  //         };
  //         const newList = [...messages, finalMsg, newObject]; // Add the new AI message to the list
  //         setIsTyping(true)
  //         setMessages(newList);          
  //         const data = JSON.parse(event.data);
  //         if (data.choices[0].finish_reason === "stop") {
  //           const list = [...newList];
  //           list[list.length - 1].pending = false
  //           setMessages(list)
  //           setIsTyping(false)
  //         }
  //         if (data.choices[0].delta.content) {
  //           prevMsg = prevMsg + data.choices[0].delta.content  
  //           const list = [...newList];
  //           list[list.length - 1].content = prevMsg
  //           setMessages(list)
  //           setLoading(false);
  //           // scrollToBottomOfChat({ behavior: "smooth", block: "end", inline: "nearest" });
  //         }
  //       },
  //     });
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };
  
  const handleCopyClick = (text) => {
    // const elementHtml = `<span className="copied-span">${text}</span>`
    writeToClipboardPolyfill(text, true); // we want html to be copied
    message.success("Copied");
  };

  let chatGPTInputRender = visible && (
    <>
      <div className={"AP-MrChat talk-to-gpt-chat"}>
        <div className={"chat-header"}>
          <Space direction="vertical" className="chatHeaderSpace">
            <Row>
              <Col span={isFullScreen ? 20 : 21} className="chat-header-inner">
                <Space>
                  <MrTranslate id={"TalkToGPT.assistant"} />
                  <Tag className="ai-header-beta-tag">
                    {mrIntl("CommonText.beta")}
                  </Tag>
                </Space>
              </Col>
              <Col className="m-l-5" span={1}>
                {messages.length > 1 && (
                  <Tooltip title={mrIntl("TalkToGPT.clear_chat")}>
                    <Button
                      disabled={loading}
                      icon={<ReloadOutlined />}
                      onClick={() => setMessages(defaultMsg)}
                      className="font-white-float-right p-t-5"
                      type="text"
                    />
                  </Tooltip>
                )}
              </Col>
              <Col className="m-l-5" span={1}>
                <Button
                  type="text"
                  className="font-white-float-right p-t-5"
                  icon={
                    !isFullScreen ? (
                      <FullscreenOutlined />
                    ) : (
                      <FullscreenExitOutlined />
                    )
                  }
                  onClick={() => setIsFullScreen(!isFullScreen)}
                ></Button>
              </Col>
              {isFullScreen && (
                <Col className="m-l-5" span={1}>
                  <Button
                    type="text"
                    className="font-white-float-right p-t-5"
                    icon={<CloseOutlined />}
                    onClick={() => {
                      setError("");
                      setVisible(!visible);
                      setIsFullScreen(!isFullScreen);
                      if (loading) {
                        setLoading(false);
                      }
                      if (streamData.isStreaming) {
                        stopStreaming();
                      }
                    }}
                  ></Button>
                </Col>
              )}
            </Row>
          </Space>
        </div>
        <div className={"chatFeed"}>
          <>
            <Alert
              showIcon
              type="info"
              message={mrIntl(
                "TalkToGPT.assistant_can_make_mistakes_please_check_generated_results"
              )}
            />
            <List
              locale={{ emptyText: mrIntl("TalkToGPT.no_messages_yet") }}
              split={false}
              dataSource={messages}
              renderItem={(item, index) => {
                let finalClass = "left";
                let senderName;
                if (item.role === "user") {
                  finalClass = "right";
                  senderName = "You";
                } else {
                  senderName = (
                    <CopyResponse
                      text={item.content}
                      index={index}
                      handleCopyClick={handleCopyClick}
                    />
                  );
                }

                if (index !== messages.length - 1) {
                  return (
                    <MessageListItem
                      item={item}
                      index={index}
                      handleCopyClick={handleCopyClick}
                      finalClass={finalClass}
                      senderName={senderName}
                    />
                  );
                } else {
                  if (streamData.isStreaming) {
                    // ai chat streaming component
                    console.log("isStreamStarted ====>", streamData.isStreaming);
                    return (
                      <StreamingItem
                        item={item}
                        index={index}
                        key={`message-${index}-stream`}
                        handleCopyClick={handleCopyClick}
                        streamData={streamData}
                        messages={messages}
                        setMessages={setMessages}
                        setStreamData={setStreamData}
                        chatTxt={chatTxt}
                        setLoading={setLoading}
                        chatBottomRef={chatBottomRef}
                        setError={setError}
                        // abortController={abortController}
                        sseRef={sseRef}
                      />
                    );
                  } else {
                    return (
                      <MessageListItem
                        item={item}
                        index={index}
                        handleCopyClick={handleCopyClick}
                        finalClass={finalClass}
                        senderName={senderName}
                      />
                    );
                  }
                }
              }}
            />
            {/* {loading && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  bottom: 0,
                  width: "100%",
                }}
              >
                <Spin />
              </div>
            )} */}
            <div ref={chatBottomRef}>
              {loading && <Spinner />}
              <div
                className={`error ${
                  isFullScreen
                    ? "error-fullscreen-width"
                    : "error-default-width"
                }`}
              >
                {error}
              </div>
            </div>
          </>
        </div>
        {(messages.length > 10 && !streamData.isStreaming) ? (
          <Button
            type="primary"
            className="magic-button-primary"
            onClick={() => setMessages(defaultMsg)}
          >
            Start a new conversation
          </Button>
        ) : (
          <div className={"chatFooter"}>
            <div className="chat-footer-container">
              <div className="textarea-container">
                <TextArea
                  className="textarea-style"
                  autoFocus={true}
                  ref={inputRef}
                  placeholder={mrIntl(
                    "TalkToGPT.type_message_do_not_share_any_personal_data"
                  )}
                  rows={1}
                  showCount
                  maxLength={1000}
                  autoSize={{ minRows: 2, maxRows: 4 }}
                  value={chatTxt}
                  // onPressEnter={() => sendMessage()}
                  onChange={(e) => {
                    //
                    setError("");
                    const value = e.target.value;

                    setChatTxt(value);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter" && !e.shiftKey && !streamData.isStreaming) {
                      e.preventDefault();
                      sendMessage();
                    }
                  }}
                />
                <Tooltip title={internetStatus === "online" ? mrIntl("TalkToGPT.send") : mrIntl("TalkToGPT.check_your_internet_connection")}>
                  <Button
                    type="primary"
                    className={`magic-button-primary btn-position ${
                      isFullScreen ? "textarea-f-screen" : "textarea-s-screen"
                    }`}
                    onClick={() =>
                      streamData.isStreaming ? stopStreaming() : sendMessage()
                    }
                    // loading={loading}
                    disabled={loading || error || internetStatus === "offline"}
                  >
                    {streamData.isStreaming ? <FaRegStopCircle /> : <SendOutlined />}
                  </Button>
                </Tooltip>
              </div>
            </div>

            {/* <Row className="chat-footer-container">
            <Col span={24}>

            </Col> */}
            {/* <SendOutlined
                onClick={() => sendMessage()}
                className=""
                style={{fontSize: "20px"}}
                // loading={loading}
                disabled={loading || error}/> */}
            {/* <Col> */}
            {/* <Input
                autoFocus={true}
                ref={inputRef}
                placeholder={`${mrIntl("TalkToGPT.type_a_message")}..`}
                rows={1}
                showCount
                maxLength={1000}
                autoSize={false}
                value={chatTxt}
                onPressEnter={() => sendMessage()}
                onChange={(e) => {
                  //
                  const value = e.target.value;
                  let containsExactWord = false;
                  for (let i = 0; i < swearWords.length; i++) {
                    let word = swearWords[i];
                    let regex = new RegExp("\\b" + word + "\\b", "i"); // create a regular expression to match the exact word
                    if (regex.test(value)) {
                      containsExactWord = true;
                      break;
                    }
                  }
                  if (containsExactWord) {
                    setError(mrIntl("TalkToGPT.we_cannot_provide_any_response_for_your_question"))
                    scrollToBottomOfChat({ behavior: "smooth", block: "end", inline: "end" });
                  } else {
                    setError("");
                  }
                  setChatTxt(value);
                }}
              /> */}
            {/* </Col> */}
            {/* <Col className="send-button"></Col> */}
            {/* </Row> */}
          </div>
        )}
      </div>
    </>
  );

  return (
    <div className={` 
      ${props.experienceViewMode === "apEditTest"
        ? "talk-to-gpt-wrapper-teacher"
        : "talk-to-gpt-wrapper-student"}
    `}>
      <div
      onClick={handleModalClose}
        className={`
          ${props.experienceViewMode === "apEditTest"
            ? "talk-to-gpt-for-teacher"
            : "talk-to-gpt-for-student"}
          ${isFullScreen && "full-screen-talk-to-gpt"}
        `}
      >
        <div>{chatGPTInputRender}</div>
      </div>
      <div className="talk-to-gpt-button-wrapper">
          {!isFullScreen && <MagicButton
            tooltipTitle="Assistant"
            size="large"
            icon={visible ? <CloseCircleOutlined /> : <ImMagicWand />}
            feature_code="allow_chatgpt_for_teachers"
            onClick={() => {
              setVisible(!visible);
              setError("")
              if (!visible){
                setIsFullScreen(true)
              } else {
                if(streamData.isStreaming) {
                  stopStreaming()
                }
              }
            }}
          />}
          {/* <Button
            shape="circle"
            size={"large"}
            icon={visible ? <CloseCircleOutlined /> : <ImMagicWand />}
            onClick={() => {
              setVisible(!visible);
            }}
          >
          </Button> */}
        </div>
    </div>

  );
};

export default TalkToGPT;
