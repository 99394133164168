import React, { useEffect, useRef, useState } from "react";
import { FabricCanvas, globalCanvasConfig, useFabric } from "/src/components/UI/Canvas/FabricCanvas";
import FabricTools from "/src/components/UI/Canvas/FabricTools";
import {
  updateImagesSRCForCanvas,
  getParsedJSONObject,
} from "/src/views/Segments/InteractiveHelpers";
import { getUniqueId, removeHTMLTagsFromText } from "/src/lib/utils/helperMethods";
import { cloneDeep, shuffle } from "lodash";
import { Segment, SegmentData } from "/src/components/UI/Segment/UIHelper";
import SegmentItemFooter from "/src/views/Segments/SegmentItemDetail/SegmentItemFooter";
import SegmentItemHeader from "/src/views/Segments/SegmentItemDetail/SegmentItemHeader";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
const { shapeStyle } = globalCanvasConfig;

const FabricLabel = (props) => {
  console.log("Fabric Label props", props);
  const {
    question_type,
    showCorrectAnswerSwitch,
    segmentSettings,
    triggerStateUpdate,
    setRenderMath,
    segmentFooterAttributes,
    unique_segment_identifier,
  } = props;
  let {
    parsedJSONObject,
    createMode,
    answerMode,
    presentationMode,
    autoCheckMode,
  } = getParsedJSONObject(props);

  let { json_uuid } = parsedJSONObject;
  const mrIntl = useTranslate()
  let textAnswerString = props.text_answer || "";
  const propsAttemptStatus = props.attempt_status || {};
  let attemptStatusObject = {
    reset_count: propsAttemptStatus.reset_count || 0,
    json_attempted: propsAttemptStatus.json_attempted || false,
  };

  if (createMode) {
    parsedJSONObject = {
      segment_data: {},
      segment_version: "1.0.0",
    };
    if (
      question_type == "fabric_dnd_label" ||
      question_type == "fabric_fill_label"
    ) {
      parsedJSONObject = props.value || parsedJSONObject;
    }
  }

  // if (answerMode) {
  // 	parsedJSONObject = shuffleItems(parsedJSONObject)
  // }

  if (autoCheckMode) {
    let question_json = cloneDeep(props.teacher_json);
    let answer_json = cloneDeep(parsedJSONObject);
    parsedJSONObject = checkResponses(question_json, answer_json);
  }

  // parsedJSONObject = updateImagesSRCForCanvas(parsedJSONObject, props.offlineView)
  const [state, setState] = useState(parsedJSONObject);
  const [textAnswer, setTextAnswer] = useState(textAnswerString);
  const [attemptStatus, setAttemptStatus] = useState(attemptStatusObject);
  const [stateUpdatedCount, setStateUpdatedCount] = useState(0);
  const [stateSetting, setStateSetting] = useState({
    updatedCount: 0,
    autoCheckMode: autoCheckMode,
    quickCheckModeEnabled: false,
    showCorrectAnswerSwitch: showCorrectAnswerSwitch,
    showCorrectAnswerSwitchValue: false,
    showLoader: false,
    canvasLoadedFromJSON: false,
  });
  const [myAnswer, setMyAnswer] = useState(state);
  const screenSettings = props.screenSettings || {};

  let currentJSON, text_answer, attempt_status;
  if (presentationMode && !stateSetting.showCorrectAnswerSwitchValue) {
    currentJSON = parsedJSONObject;
    text_answer = textAnswerString;
    attempt_status = attemptStatusObject;
  } else {
    currentJSON = state;
    text_answer = textAnswer;
    attempt_status = attemptStatus;
  }

  const segment_data = currentJSON?.segment_data || {};
  const responses = currentJSON.segment_responses || {};
  const cd = segment_data.canvas_data;
  const canvas_data = (typeof cd == "string" ? JSON.parse(cd) : cd) || {};

  const [fabricReady, setCanvasFabricReady] = useState(false);
  const canvasRef = useRef();
  const fabricRef = useFabric((currentCanvas) => {
    if (currentCanvas) {
      console.log("Current canvas in useFabric ==>", currentCanvas);
      currentCanvas._customEventListeners = {
        onObjectMoving: (options) => {
          let object = options.target;
          if (answerMode) {
            if (question_type == "fabric_dnd_label") {
              currentCanvas.makeHolderReadyToGetFilled(object);
            }
          }
        },
        onMouseUp: (options) => {
          let object = options.target;
          if (answerMode) {
            if (question_type == "fabric_dnd_label") {
              currentCanvas.setObjectPosOnMouseUp(object);
            }
          }
        },
        onAddTextLabel: (object) => {
          if (question_type == "fabric_dnd_label") {
            // setResponses(object)
          }
        },
        onSetObjectPosOnMouseUp: (object) => {
          if (question_type == "fabric_dnd_label") {
            // setResponses(object)
          }
        },
        onTextEditingExited: (object) => {
          if (question_type == "fabric_fill_label") {
            // setResponses(object)
          }
        },
      };
      currentCanvas.toolType = {}; // TO Avoid sharing of tools between all canvas of page initialized
      currentCanvas.canvasInstancesForUndo = [cd];
      currentCanvas.canvasInstancesForRedo = [];
      canvasRef.current = currentCanvas;
      setCanvasFabricReady(true);
    }
  }, []);

  const canvas = canvasRef.current || {};
  canvas.fabricReady = fabricReady
  canvas.role = createMode ? "teacher" : "student"

  if (!createMode && !answerMode) {
    canvas.notEvented = true;
  } else {
    canvas.notEvented = false;
  }

  useEffect(() => {
    setCanvasFabricReady(false)
  }, [unique_segment_identifier])

  useEffect(() => {
    if (canvas.fabricReady) {
      canvas.resizeCanvas();
      updateCanvasData(parsedJSONObject);
    }
  }, [canvas.fabricReady]);

  // Presentation mode watching data change - use case question wise grading, live responses
  useEffect(() => {
    if (canvas.fabricReady && presentationMode && json_uuid) {
      updateCanvasData(parsedJSONObject);
    }
  }, [json_uuid]);

  // When parent component want to update its state - use case reading mode, pause mode
  useEffect(() => {
    if (canvas.fabricReady && triggerStateUpdate) {
      updateCanvasData(parsedJSONObject);
    }
  }, [triggerStateUpdate]);

  useEffect(() => {
    if (createMode) {
      if (props.onChange) {
        let teacher_json = cloneDeep(state);
        props.onChange(teacher_json);
      }
    } else if (answerMode && stateUpdatedCount > 1) {
      if (props.onChange && stateSetting.canvasLoadedFromJSON) {
        let result = {
          response_json: cloneDeep(state),
          // text_answer: cloneDeep(textAnswer),
          attempt_status: cloneDeep(attemptStatus),
        };

        props.onChange(result);
      }
    }
  }, [stateUpdatedCount]);

  const saveState = (state) => {
    if (!presentationMode) {
      setState(state);
      setStateUpdatedCount((stateUpdatedCount) => stateUpdatedCount + 1);
    }
    console.log("Label Save state is called ==>", state, stateSetting);
  };

  const saveTextAnswer = (data) => {
    setTextAnswer(data);
    setStateUpdatedCount((stateUpdatedCount) => stateUpdatedCount + 1);
  };

  function shuffleItems(json) {
    // const segment_data = json.segment_data || {}
    // const possible_responses = segment_data.possible_responses || {}
    // possible_responses.items = shuffle(possible_responses.items)

    return json;
  }

  function checkResponses(question_json, answer_json) {
    console.log(
      "Quesiton and answer responses ==>",
      question_json,
      answer_json
    );
    if (!question_json || !answer_json) {
      return;
    }
    let q_responses = question_json.segment_responses || {};
    let a_responses = answer_json.segment_responses || {};

    if (question_type == "fabric_dnd_label") {
      Object.keys(a_responses).map((key) => {
        if (a_responses[key].answer) {
          if (
            q_responses[key] &&
            q_responses[key].answer == a_responses[key].answer
          ) {
            a_responses[key].correct = true;
          } else {
            a_responses[key].correct = false;
          }
        }
      });
    } else if (question_type == "fabric_fill_label") {
      Object.keys(a_responses).map((key) => {
        if (a_responses[key].answer) {
          if (
            q_responses[key] &&
            removeHTMLTagsFromText(q_responses[key].answer) ==
              removeHTMLTagsFromText(a_responses[key].answer)
          ) {
            a_responses[key].correct = true;
          } else {
            a_responses[key].correct = false;
          }
        }
      });
    }
    return answer_json;
  }

  const setResponses = () => {
    let isAtleastOneAnswerUpdated = false;
    const newResponses = {};
    const canvas = canvasRef.current;
    canvas.forEachObject((object) => {
      if (question_type == "fabric_dnd_label") {
        if (object.name == "Label Holder") {
          const answer = object.holdingObjectId ? object.holdingObjectId : "";
          newResponses[object.id] = {
            id: object.id,
            answer: answer,
            name: object.name,
          };
          if (object.holdingObjectId != "") {
            isAtleastOneAnswerUpdated = true;
          }
        }
      } else if (question_type == "fabric_fill_label") {
        if (object.name == "Label Text") {
          const answer = object.text ? object.text : "";
          newResponses[object.id] = {
            id: object.id,
            answer: answer,
            name: object.name,
          };
          if (object.text != object.placeholder) {
            isAtleastOneAnswerUpdated = true;
          }
        }
      }
    });

    if (answerMode) {
      setAttemptStatus((attemptStatus) => {
        return {
          ...attemptStatus,
          json_attempted: isAtleastOneAnswerUpdated,
        };
      });
    }

    return newResponses;
  };

  function updateCanvasData(state) {
    // If something went wrong earlier, then reseting when section switch or page refresh
    if (answerMode && state.errorInLoadFromJSON) {
      resetQuestion();
      return;
    }
    console.log("state json_uuid ==>", state.json_uuid);
    const segment_data = state.segment_data || {};
    const responses = state.segment_responses || {};
    const cd = segment_data.canvas_data;
    let canvas_data = (typeof cd == "string" ? JSON.parse(cd) : cd) || {};

    if (canvas_data && canvas_data.objects) {
      // let allowCanvasToUpdate = checkIfCanvasNeedToUpdated(props)
      setStateSetting((stateSetting) => {
        return {
          ...stateSetting,
          showLoader: true,
          errorInLoadFromJSON: false,
          canvasLoadedFromJSON: false,
        };
      });
      if (!createMode && question_type == "fabric_dnd_label") {
        canvas.noHeightUpdate = true;
      }
      console.log("label canvas_data ==>", canvas_data);
      canvas_data = updateImagesSRCForCanvas(
        canvas_data,
        state.segment_version
      );
      canvas.clearAll();
      canvas.loadFromJSON(
        canvas_data,
        function () {
          // if ($rootScope.printingStarted) {
          //   console.log('label', $rootScope.printWaitForInitQuestionsCount);
          //   $rootScope.printWaitForInitQuestionsCount += 1;
          // }
          canvas.resizeCanvas();
          updateToSupportOldLabelQuestion();
          updateCanvasObjects(responses);
          // canvas.renderAll.bind(canvas);
          setStateSetting((stateSetting) => {
            return {
              ...stateSetting,
              showLoader: stateSetting.errorInLoadFromJSON,
              canvasLoadedFromJSON: !stateSetting.errorInLoadFromJSON,
            };
          });
        },
        function (o, object) {
          if (!object) {
            console.log("Something wrong with this object ==>", o, object);
            setStateSetting({
              ...stateSetting,
              errorInLoadFromJSON: true,
            });
          }
          console.log("fabric canvas loading with objects ==>", o, object)
          // if (!props.answerMode && object) {
          //   object.hasControls = false;
          //   object.selectable = false;
          //   object.evented = false;
          // }
        }
      );
    }
  }

  function updateCanvasObjects(responses) {
    let objectList = [];
    canvas.forEachObject(function (object) {
      console.log("updated canvas objects ==>", object.name, object);
      // if (question_type === 'fabric_dnd_label' && (object.name == 'Label Text' || object.name == 'Label Image') && !object.objectPlaced) {
      //   objectList.push(object);
      // }

      if (answerMode && !stateSetting.quickCheckModeEnabled) {
        object.selectable = false;
        // object.hasControls = false;
        // object.hasBorders = false;
        object.evented = false;
        object.customDisableUpdate = true;
        if (object.role == "teacher") {
          object.notDeletable = true;
        }
        if (question_type === "fabric_dnd_label") {
          // dragAndDropObject(object);
          if (object.name == "Label Text" || object.name == "Label Image") {
            object.editable = false;
            object.selectable = true;
            object.evented = true;
            object.hasControls = false;
            object.hasBorders = false;
            object.customDisableUpdate = false;
            // if (!object.objectPlaced) {
            //   objectList.push(object);
            // }
          } else if (object.name === "Label Holder") {
            let holder = object;
            if (!holder.holderFilled) {
              holder.set("holderFilled", false);
              holder.set("holdingObjectId", "");
              holder.updateCustomStyle("default");
            }
          }
        } else if (question_type === "fabric_fill_label") {
          // writeTextInCloze(object);
          const objectsToUpdate = ["Polyline Arrow", "Polyline Arrow Control", "Label Holder"];
          objectsToUpdate.forEach((name) => {
            if (object.name === name) {
              object.customDisableUpdate = false;
            }
          });
          
          if (object.name === "Label Text") {
            object.evented = true;
            object.editable = true;
            object.selectable = true;
            object.lockMovementX = true;
            object.lockMovementY = true;
            object.hasControls = false;
            object.hasBorders = false;
            object.hoverCursor = "text";
            object.showPlaceholderText();
            object.updatePlaceholderColor();
            object.updateLabelHolder();
            object.updateContainerRect();
          }
        }
      }

      if (presentationMode) {
        object.selectable = false;
        object.evented = false;
      }

      if (autoCheckMode || stateSetting.quickCheckModeEnabled) {
        object.selectable = false;
        object.evented = false;
        if (responses.hasOwnProperty(object.id)) {
          let response = responses[object.id];
          if (response.answer) {
            let holder;
            if (
              object.name == "Label Holder" &&
              question_type == "fabric_dnd_label"
            ) {
              holder = object;
            } else if (
              object.name == "Label Text" &&
              question_type == "fabric_fill_label"
            ) {
              holder = object.getConnectedObjectsByAttributes({
                name: "Label Holder",
              })[0];
            }
            console.log("holder and response ==>", holder, response);
            if (holder) {
              if (response.correct) {
                holder.correctAnswerStyle();
              } else {
                holder.wrongAnswerStyle();
              }
            }
          }
        }
      }

      if (
        question_type == "fabric_dnd_label" &&
        (object.name == "Label Text" || object.name == "Label Image")
      ) {
        if (!object.objectPlaced) {
          objectList.push(object);
        }
      }
    });
    if (objectList.length) {
      objectList = shuffle(objectList);
      canvas.possibleObjectListPos(objectList);
    }
    canvas.renderAll();
  }

  function updateToSupportOldLabelQuestion() {
    if (state.segment_version != "old_version") {
      return;
    }
    canvas.getObjectsByAttr({ name: "Label Text" }).map((text) => {
      canvas
        .getObjectsByAttr({ id: text.id, name: "Polyline Arrow" })
        .map((polylineArrow) => {
          let polylineArrowId = polylineArrow.id;
          let polylineConnectedObjectsIds = [];
          polylineArrow.id = getUniqueId();
          text.updateContainerRect([text.id]);
          text.updateLabelHolder([polylineArrow.id, text.id]);
          text.set("connectedObjectsIds", [
            polylineArrow.id,
            text.containerRect.id,
            text.labelHolder.id,
          ]);
          canvas
            .getObjectsByAttr({
              id: polylineArrowId,
              name: "Polyline Arrow Control",
            })
            .map((control) => {
              control.id = getUniqueId();
              control.customType = "custom-control";
              control.controlIndx = control.polylineArrowControlIndx;
              control.connectedObjectsIds = [polylineArrow.id];
              polylineConnectedObjectsIds.push(control.id);
            });
          canvas
            .getObjectsByAttr({
              id: polylineArrowId,
              name: "Polyline Arrow Head",
            })
            .map((arrowHead) => {
              arrowHead.id = getUniqueId();
              arrowHead.customType = "arrow-head";
              arrowHead.connectedObjectsIds = [polylineArrow.id];
              polylineConnectedObjectsIds.push(arrowHead.id);
            });
          polylineArrow.set("connectedObjectsIds", polylineConnectedObjectsIds);

          // if (answerMode && question_type == 'fabric_fill_label') {
          //   text.set('connectedObjectsIds', [text.containerRect.id])
          // }
        });
    });
  }

  const saveCanvasData = (data) => {
    const newResponses = setResponses();
    const newCanvasData = {
      ...canvas_data,
      ...data,
    };

    const newSegmentData = {
      ...segment_data,
      canvas_data: newCanvasData,
    };

    const newState = {
      ...state,
      segment_data: newSegmentData,
      segment_responses: newResponses,
    };

    saveState(newState);
  };

  const checkAnswer = (check = false) => {
    if (check) {
      let question_json = cloneDeep(props.teacher_json);
      let answer_json = cloneDeep(state);
      const newState = checkResponses(question_json, answer_json);

      setMyAnswer(state);
      saveState(newState);
      updateCanvasData(newState);
    }

    stateSetting.quickCheckModeEnabled = check;
  };

  const showCorrectAnswer = (value) => {
    let question_json, answer_json;
    if (value) {
      question_json = cloneDeep(props.teacher_json);
      answer_json = cloneDeep(props.teacher_json);
    } else {
      question_json = cloneDeep(props.teacher_json);
      answer_json = cloneDeep(myAnswer);
    }

    const newState = checkResponses(question_json, answer_json);
    saveState(newState);
    stateSetting.quickCheckModeEnabled = true;
    stateSetting.showCorrectAnswerSwitchValue = value;
    // setStateSetting({
    //   ...stateSetting,
    //   quickCheckModeEnabled: true,
    //   showCorrectAnswerSwitchValue: value
    // })
    updateCanvasData(newState);
  };

  const resetQuestion = () => {
    let student_json = cloneDeep(props.student_json);
    const newState = student_json;

    setAttemptStatus({
      ...attemptStatus,
      reset_count: attemptStatus.reset_count + 1,
      json_attempted: false,
    });
    saveState(newState);
    stateSetting.quickCheckModeEnabled = false;
    // setStateSetting({
    //   ...stateSetting,
    //   quickCheckModeEnabled: false,
    // })
    updateCanvasData(newState);
  };

  console.log(
    "Fabric label state before render ==>",
    state,
    stateSetting,
    canvasRef,
    canvas
  );

  console.log("unique key of segment ==>", `${unique_segment_identifier}`)
  return (
    <Segment>
      {/* <SegmentItemHeader
        segmentStateSettings={stateSetting}
        showCorrectAnswer={(value) => showCorrectAnswer(value)}
      >
      </SegmentItemHeader> */}
      <SegmentData
        key={`segment-data-${unique_segment_identifier}`}
        className="interactive-questions"
        tabIndex={-1}
        onKeyDown={(e) => {
          if (!presentationMode) {
            canvas.onKeyDown(e);
          }
        }}
      >
        <FabricCanvas
          key={`fabric-canvas-${unique_segment_identifier}`}
          fabricRef={fabricRef}
          canvas={canvas}
          saveCanvasData={saveCanvasData}
          showLoader={stateSetting.showLoader}
          loaderMessage={
            stateSetting.errorInLoadFromJSON &&
            mrIntl("CommonText.something_wrong_reset_question_msg")
          }
        />
        {createMode && (
          <FabricTools
            canvas={canvas}
            toolBarConfig={{
              toolBar: [
                "move",
                "textLabel",
                "bgImage",
                "bringToFront",
                "sendToBack",
                "undo",
                "redo",
                "delete",
                "clearAll",
              ],
              drawMode: true,
            }}
          />
        )}
      </SegmentData>
      <SegmentItemFooter
        createMode={createMode}
        answerMode={answerMode}
        presentationMode={presentationMode}
        segmentFooterAttributes={segmentFooterAttributes}
        segmentSettings={segmentSettings}
        segmentStateSettings={stateSetting}
        screenSettings={screenSettings}
        setRenderMath={setRenderMath}
        saveTextAnswer={saveTextAnswer}
        resetQuestion={resetQuestion}
        checkAnswer={checkAnswer}
        showCorrectAnswer={showCorrectAnswer}
      ></SegmentItemFooter>
    </Segment>
  );
};
export default FabricLabel;
