import { useState, useEffect, useRef } from "react";

function useIntersectionObserver(finalRender) {
  //State to help load data through observer
  const [visibleCount, setVisibleCount] = useState(1);
  // New state for showing skeleton when data loads through observer
  const [loadingDataThroughObserver, setLoadingDataThroughObserver] = useState(false); 
  const observer = useRef(null);
  let timeoutId;

  const handleIntersection = (entries) => {
    if (entries[0].isIntersecting && !loadingDataThroughObserver) {
      setLoadingDataThroughObserver(true);
      
      setVisibleCount((prev) => {
        if (prev < finalRender.length) {
          return prev + 1;
        }
        return prev;
      });
    }
    timeoutId = setTimeout(() => {
      // if(visibleCount >= finalRender.length){
      // }
      if(loadingDataThroughObserver) {
        setLoadingDataThroughObserver(false);
      }
    }, 500); // Simulate load delay
  };
  
  useEffect(() => {
    observer.current = new IntersectionObserver(handleIntersection, {
      root: null, // Use the viewport as the root
      rootMargin: "10px", // Add a margin around the root
      threshold: 0.1, // Trigger the callback when 10% of the target is visible
    });
    
    if(visibleCount >= finalRender.length) {
      observer.current.disconnect();
      if(loadingDataThroughObserver) {
        setLoadingDataThroughObserver(false)
      }
    } else {
      const lastElement = document.querySelector(".last-visible");
      if (lastElement) {
        observer.current.observe(lastElement);
      }
    }


    return () => {
      clearTimeout(timeoutId);
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [finalRender, visibleCount, loadingDataThroughObserver]);

  return { visibleCount, loadingDataThroughObserver, setVisibleCount };
}

export default useIntersectionObserver;
