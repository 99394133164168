import { useState, useCallback } from "react";

export const usePinUnpin = () => {
  const [isPinned, setIsPinned] = useState(true);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);

  const togglePin = useCallback(() => {
    setIsPinned((prev) => !prev);
    setPosition({ x: 0, y: 0 });
  }, []);

  const handleDrag = useCallback((_, data) => {
    setPosition({ x: data.x, y: data.y });
    setIsDragging(true);
  }, []);

  const handleStop = useCallback(() => setIsDragging(false), []);

  return { isPinned, position, isDragging, togglePin, handleDrag, handleStop };
};