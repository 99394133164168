import React from 'react';
import { Empty } from 'antd';
import { useTranslate } from '/src/lib/MrTranslate/MrTranslate';
import "./AccessDeniedMessage.scss";

const AccessDeniedMessage = ({ description }) => {
  const mrIntl = useTranslate()

  return (
    <Empty
      className="access-denied-message"
      description={description || mrIntl("AccessDeniedMessage.access_denied")}
    />
  );
};

export default AccessDeniedMessage;