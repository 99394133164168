import React, { useState, useEffect } from "react";
import {
  Button,
  Tooltip,
  Row,
  Space,
  Menu,
  Divider,
  Dropdown,
  Alert
} from "antd";
import { message } from "/src/components/UI/AntdAppHelper";
import {
  CheckOutlined,
  SwapOutlined,
  MinusOutlined,
  UsergroupAddOutlined,
  UserAddOutlined,
  PrinterOutlined,
  CommentOutlined,
  CloseOutlined,
  CheckCircleTwoTone,
  MinusCircleTwoTone,
  CloseCircleTwoTone,
  EyeOutlined,
  DownOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import { availableLanguageOptionsForAI, checkMob, dropDownTrigger, writeToClipboardPolyfill } from "/src/lib/utils/helperMethods";
import ExperiencePageHeader from "/src/views/Experiences/ExperienceShow/ExperiencePageHeader/ExperiencePageHeader";
import DuplicateExperienceButton from "/src/views/Experiences/ExperienceShow/Components/DuplicateExperienceButton";
import FeedbackModal from "/src/views/Feedbacks/FeedbackModal";
// import ResourceModal from "/src/views/Experiences/ExperienceShow/Components/ResourceModal";
import ResourceModal from "/src/views/Experiences/ExperienceShow/Components/ResourceModal/ResourceModal";
import TaskSpecificClarification from "/src/views/Experiences/ExperienceShow/ExperienceManage/TaskSpecificClarification/TaskSpecificClarification"
import ExportModal from "/src/views/Experiences/ExperienceShow/Components/ExportModal";
import {
  Route,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { useDispatch } from "react-redux";
import ExperienceGrade from "/src/views/Experiences/ExperienceShow/ExperienceManage/ExperienceGrade/ExperienceGrade";
import ExperienceDeliver from "/src/views/Experiences/ExperienceShow/ExperienceManage/ExperienceDeliver/ExperienceDeliver";
import ExperienceMonitor from "/src/views/Experiences/ExperienceShow/ExperienceManage/ExperienceMonitor/ExperienceMonitor";
import ExperienceCreate from "/src/views/Experiences/ExperienceShow/ExperienceManage/ExperienceCreate/ExperienceCreate";
import ExternalVideoCallButton from "/src/views/Experiences/ExperienceShow/Components/ExternalVideoCallButton";
import FirestoreInteractions from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/FirestoreInteractions";
import { useSelector } from "react-redux";
import { currentUserSelector, enabledFeaturesSelector } from "/src/views/Auth/Login/selector";
import { actions as experienceActions } from "/src/views/Experiences/redux";
import { actions as firestoreInteractionActions } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/redux";
import { showConfirmModal } from "/src/components/UI/Segment/UIHelper";
import AuditLogs from "../Components/AuditLogs/AuditLogs";
import { includes, upperFirst } from 'lodash'
import { BiMessageRoundedError } from "react-icons/bi";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import ExperienceAnalytics from "./ExperienceAnalytics/ExperienceAnalytics";
import TalkToGPT from "../Components/TalkToGPT";
import './TaskSpecificClarification/TaskSpecificClarification.scss';
import { editModeForSegmentIdSelector, showSegmentFormSelector } from "/src/views/Segments/selector";
import { actions as segmentActions } from "/src/views/Segments/redux";
import { getLatestAppVersion } from "/src/App/OfflineApp/offlineAppHelper";
import AIAssistantSettings from "/src/views/Segments/GenerateAI/AIAssistantSettings";
import TranslateAssessment from "/src/views/Segments/GenerateAI/TranslateAssessment";
import TranslationProgressModal from "./TranslationProgressModal";

const ExperienceManage = (props) => {
  let { experienceProps } = props;
  const {
    experience,
    experienceSettings,
    duplicateResource,
    embedded,
    editAllowed,
    experienceViewMode,
    fromResourceName,
    fromUrl,
    setParams,
    setExperienceViewMode,
    renderForm,
    showForm,
    showFormVal,
    updateResource,
  } = experienceProps;
  
  console.log("ExperienceManage===>>", experience);
  const dispatch = useDispatch();
  const location = useLocation();
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const isMobile = checkMob();
  const currentUser = useSelector(currentUserSelector());
  const enabledFeatures = useSelector(enabledFeaturesSelector())
  const editModeForSegmentId = useSelector(editModeForSegmentIdSelector())
  const showSegmentForm = useSelector(showSegmentFormSelector());
  const [activeKey, setActiveKey] = useState(null);
  const [rearrangeMode, setRearrangeMode] = useState(false);
  const [exportModalConfig, setExportModalConfig] = useState({});
  const mrIntl = useTranslate();
  const [loading, setLoading] = useState(false);
  const isStudentReviewerMode = experience.student_reviewer_mode
  const isAdminOrTeacherWithQB = experience.questionbank && (currentUser.role === "admin" || currentUser.role === "teacher")
  

  const logMessages = {printCancelled: `${
      exportModalConfig.exportType === "teacher_experience"
        ? `Assessment ${experience.name} (${experience.access_code}) with markscheme was cancelled`
        : `Assessment ${experience.name} (${experience.access_code}) without markscheme (for students) was cancelled`
    } by ${currentUser.name} (${upperFirst(currentUser.role)})`,
    printStart: `${
      exportModalConfig.exportType === "teacher_experience"
        ? `Assessment ${experience.name} (${experience.access_code}) with markscheme was printed`
        : `Assessment ${experience.name} (${experience.access_code}) without markscheme (for students) was printed`
    } by ${currentUser.name} (${upperFirst(currentUser.role)})`
  }
  const experienceRubric =  experience.rubric || {};
  const tscEnabled = experienceSettings.tsc_enabled
  const showAddTSCButton = experienceRubric.type_c === "myp_achievement_level" && !tscEnabled && experience.status === "draft";
  const showTaskSpecificClarification = experienceRubric.type_c === "myp_achievement_level";

  console.log('experience.rubric ===>>',experience.rubric, experienceSettings);
  // TODO: clean up with config objects, to think about Live quizzes create/take/grading view and OMR create/take/grading view - 677 and 913
  // let studentView = "apTakeTest"
  // console.log("studentView", studentView)
  // let viewModeToSet = userRole == "student" ? studentView : "apEditTest"
  // let experienceViewMode = experienceViewModeFromRedux || viewModeToSet
  // apPreviewTest, apTakeTest, apTakeTestOBO,  cpEditChapter, cpGamifiedLearning
  // const [experienceViewMode, setExperienceViewMode] = useState(viewModeToSet)
  // const [calcSetting, setCalcSetting] = useState({
  //   type: 'desmos_scientific_calc',
  //   showCalc: false,
  // })

  // TODO: Rewrite to handle APL-926 also
  // TODO: adding the same here again to trigger on url/location change as well, not just on mount. Ideally can remove from mount now? - causing topic switch bug
  // useEffect(() => {
  //   if((experience.current_user_can_edit  || experience.current_user_can_monitor) && location.pathname.indexOf('deliver') !== -1)
  //     setActiveKey("deliver")
  //   if((experience.current_user_can_edit || experience.current_user_can_monitor) && location.pathname.indexOf('monitor') !== -1)
  //     setActiveKey("monitor")
  //   if(experience.current_user_can_edit && location.pathname.indexOf('grade') !== -1)
  //     setActiveKey("grade")
  //   if((location.pathname.indexOf('all-tests') !== -1 || location.pathname.indexOf('tests') !== -1 || location.pathname.indexOf('embed') !== -1) && location.pathname.indexOf('deliver') == -1 && location.pathname.indexOf('monitor') == -1  && location.pathname.indexOf('grade') == -1  && location.pathname.indexOf('analytics') == -1){
  //     console.log("embed and here ");
  //     if(experienceViewMode == "apEditTest"){
  //       if(experience.current_user_can_monitor){
  //         history.push(`${url}/monitor`)
  //         setActiveKey("monitor")
  //       }
  //       else{
  //         history.push(`${url}/c`)
  //         setActiveKey("c")
  //       }
  //     }
  //   }
  // }, [location.pathname]);

  function setLogs(msg) {
    dispatch(
      firestoreInteractionActions.setLogs({
        logging: true,
        log_type: "experience",
        category: "actions",
        msg: msg,
        itemId: experience.id,
        experienceId: experience.id,
      })
    );
  }

  useEffect(() => {
    // TODO: Rewrite to handle APL-926 also
    // if (
    //   (experience.current_user_can_edit || experience.current_user_can_monitor) 
    //   && location.pathname.indexOf("c") !== -1
    // ) {
    //   setActiveKey("c");
    // }

    if (
      (experience.current_user_can_edit ||
        experience.current_user_can_monitor) &&
      location.pathname.indexOf("deliver") !== -1
    ) {
      setActiveKey("deliver");
    }

    if (
      (experience.current_user_can_edit ||
        experience.current_user_can_monitor) &&
      location.pathname.indexOf("monitor") !== -1
    ) {
      setActiveKey("monitor");
    }

    if (
      (experience.current_user_can_grade ||
        embedded ||
        currentUser.role === "support" ||
        (experienceSettings.peer_review_enabled && isStudentReviewerMode)) &&
      location.pathname.indexOf("grade") !== -1
    ) {
      // *Allowing the grade tab to be set for embedded regardless of permission to handle invig case. But then showing error message later based on experience.current_user_can_edit that you can't grade - only owners and collaborators can
      setActiveKey("grade");
    }

    if (
      (experience.current_user_can_edit || currentUser.role === "support") &&
      location.pathname.indexOf("analytics") !== -1
    ) {
      setActiveKey("analytics");
    }

    if (
      (location.pathname.indexOf("all-tests") !== -1 ||
        location.pathname.indexOf("tests") !== -1 ||
        location.pathname.indexOf("embed") !== -1) &&
      location.pathname.indexOf("deliver") === -1 &&
      location.pathname.indexOf("monitor") === -1 &&
      location.pathname.indexOf("grade") === -1 &&
      // location.pathname.indexOf("c") === -1 &&
      location.pathname.indexOf("analytics") === -1
    ) {
      console.log("embed and here ");
      if (experienceViewMode === "apEditTest") {
        // current_user_can_only_monitor is for invigilator
        if (experience.current_user_can_only_monitor) {
          history.push(`${url}/monitor`);
          setActiveKey("monitor");
        } else {
          history.push(`${url}/c`);
          setActiveKey("c");
        }
      }
    }
  }, []);


  console.log("activeKey is now ==>", activeKey);

  // let title = experience.name
  // TILL ABOVE - all in separate pageheader
  // TODO: use config
  // for MB split screen gradebook iframe
  const mbGradeBookEmbeddedView =
    embedded &&
    experience.is_mb_linked &&
    location.pathname.indexOf("grade") > -1
      ? true
      : false;
  // TEMP ON DEV
  // const mbGradeBookEmbeddedView = true
  const experienceHeaderMoreActionsMenu = (
    <Menu className="header-action-dropdown">
      {/* {editAllowed && uploadResourceButton} */}
      {editAllowed && activeKey === "c" && (
        <Menu.Item
          key="menu-experience-rearrange"
          title={mrIntl("ExperienceManage.rearrange")}
          // title="Rearrange"
          icon={<SwapOutlined />}
          onClick={() => {
            setRearrangeMode(true);
          }}
        >
          <MrTranslate id={"ExperienceManage.rearrange"} />
        </Menu.Item>
      )}
      {(experience.current_user_can_edit || experience.current_user_can_grade) && (
        <Menu.Item
          key="menu-experience-collaborators"
          title={mrIntl("ExperienceManage.collaborators")}
          // title="Collaborators"
          icon={<UsergroupAddOutlined />}
          onClick={() => {
            showForm({ name: "experiencesCollaborators" });
          }}
        >
          {<MrTranslate id={"ExperienceManage.collaborators"} />}
        </Menu.Item>
      )}
      {experience.current_user_can_edit && (
        <Menu.Item
          key="menu-experience-invigilators"
          title={mrIntl("ExperienceManage.invigilators")}
          // title="Invigilators"
          icon={<UserAddOutlined />}
          onClick={() => {
            showForm({ name: "experiencesInvigilators" });
          }}
        >
          {<MrTranslate id={"ExperienceManage.invigilators"} />}
        </Menu.Item>
      )}
      {/* {experience.current_user_can_edit && (
        <Menu.Item
          key="menu-experience-print"
          title="Print"
          icon={<PrinterOutlined />}
          onClick={() => {
            window.open(`/print/experiences/${experience.uid}`, "_blank");
          }}
        >
          Print
        </Menu.Item>
      )} */}

      {/* {experience.current_user_can_edit && (
        <Menu.Item
          key="menu-experience-print-for-teacher"
          title={mrIntl("ExperienceManage.includes_correct_answers_and_explanations")}
          // title="Includes correct answers and explanations"
          icon={<PrinterOutlined />}
          onClick={() => {
            setExportModalConfig({
              ...exportModalConfig,
              showModal: true,
              modalTitle: mrIntl("ExperienceManage.print_with_markscheme"),
              // modalTitle: "Print (with markscheme)",
              exportButtonText: mrIntl("CommonText.print"),
              exportType: "teacher_experience",
              exportURLType: "pdf",
              noSubmissionsMessage: "",
              downloadMessage: mrIntl("CommonText.download_pdf"),
              // downloadMessage: "Download PDF",
              timeMessage: mrIntl("ExperienceManage.this_will_take_about_30s"),
              // timeMessage: "This will take about 30s",
              delay: 10,
              experience: experience,
              logMsg: `Assessment with markscheme`,
            });
          }}
        >
          {<MrTranslate id={"ExperienceManage.print_with_markscheme"}/>}
        </Menu.Item>
      )}

      {experience.current_user_can_edit && (
        <Menu.Item
          key="menu-experience-print-for-student"
          title={mrIntl("ExperienceManage.without_answers_and_with_blank spaces")}
          // title="Without answers and with blank spaces"
          icon={<PrinterOutlined />}
          onClick={() => {
            console.log('modal config==>', exportModalConfig);
            setExportModalConfig({
              ...exportModalConfig,
              showModal: true,
              modalTitle:mrIntl("ExperienceManage.print_for_students"),
              // modalTitle: "Print for students",
              exportButtonText: mrIntl("CommonText.print"),
              exportType: "student_experience",
              exportURLType: "pdf",
              noSubmissionsMessage: "",
              downloadMessage: mrIntl("CommonText.download_pdf"),
              timeMessage:mrIntl("ExperienceManage.this_will_take_about_30s"),
              delay: 10,
              experience: experience,
              logMsg: `Assessment without markscheme (for students)`
            });
          }}
        >
          {<MrTranslate id={"ExperienceManage.print_for_students"}/>}
        </Menu.Item>
      )} */}

      {(experience.current_user_can_edit || experience.current_user_can_deliver) && (
        <Menu.Item
          key="menu-experience-print"
          icon={<PrinterOutlined />}
          onClick={() => {
            console.log("modal config==>", exportModalConfig);
            setExportModalConfig({
              ...exportModalConfig,
              showModal: true,
              modalTitle: mrIntl("ExperienceManage.export"),
              exportButtonText: mrIntl("CommonText.export"),
              exportType: "experience",
              exportURLType: "pdf",
              noSubmissionsMessage: "",
              downloadMessage: mrIntl("CommonText.download_pdf"),
              // downloadMessage: "Download PDF",
              timeMessage: mrIntl("ExperienceManage.this_will_take_about_30s"),
              // timeMessage: "This will take about 30s",
              autoInitiateExportDisabled: true,
              delay: 10,
              experience: experience,
            });
          }}
        >
          {mrIntl("CommonText.export")}
        </Menu.Item>
      )}
       
      {enabledFeatures.ai_assistant_settings &&
        <Menu.Item
          key="menu-ai-assistant-settings"
        >
          <AIAssistantSettings withText={true} />
        </Menu.Item>
      }

      {(showAddTSCButton) && (
        <Menu.Item
          key="menu_experience_task_specific_clarification"
          className="experience-task-specific-clarification"
        >
          <TaskSpecificClarification editAllowed={true} updateResource={updateResource} showTSCInActions={true} />
        </Menu.Item>
      )}
      {enabledFeatures.translate_assessment && (
        <Menu.Item
          key="menu-translate-assessment"
          className="menu-translate-assessment"
        >
          <TranslateAssessment item={experience} renderInExperienceAction={true} />  
        </Menu.Item>
      )}
      <Divider style={{ margin: "5px 0px 5px 0px" }} />

      <Menu.Item key="menu-experience-feedback" icon={<CommentOutlined />} style={{padding: '0px 0px 0px 12px'}}>
        <FeedbackModal
          title={mrIntl("ExperienceManage.give_feedback")}
          // title={"Give feedback"}
          buttonStyle={{ padding: "0px", background: "none" }}
          shape={false}
          type={"text"}
          icon={false}
          ghost={false}
        />
      </Menu.Item>

      <Menu.Item key="menu-experience-logs" style={{padding: '0px 0px 0px 12px'}}>
        <AuditLogs
          // title={"Assessment Logs"}
          title={mrIntl("ExperienceManage.assessment_logs")}
          experience={experience}
          logView={"experience_audit_logs"}
          log_type={"experience"}
          itemId={experience.id}
          // buttonStyle={{ padding: "0px" }}
          // shape={false}
          // type={"text"}
          // icon={false}
          // ghost={false}
        />
      </Menu.Item>
      {/* <Menu.Item icon={<LinkOutlined/>} >
        <a href={`/embed/${experience.uuid}`} target="_blank"> Embed </a>
      </Menu.Item> */}
    </Menu>
  );

  let rearrangeButton = "";

  if (editAllowed) {
    if (rearrangeMode) {
      rearrangeButton = (
        <Tooltip
          placement="left"
          // title="Finish rearranging"
          title={mrIntl("ExperienceManage.finish_rearranging")}
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
        >
          <Button
            shape="circle"
            type="primary"
            icon={<CheckOutlined />}
            onClick={() => setRearrangeMode(false)}
          />
        </Tooltip>
      );
    } else {
      rearrangeButton = (
        <Tooltip placement="bottom" title={mrIntl("ExperienceManage.rearrange")}>
          <Button
            shape="circle"
            icon={<SwapOutlined />}
            onClick={() => setRearrangeMode(true)}
          />
        </Tooltip>
      );
    }
  }

  const disableRearrangeBtn = (
    <Tooltip placement="left" title={mrIntl("ExperienceManage.finish_rearranging")} getPopupContainer={triggerNode => triggerNode.parentNode}>
      <Button
        shape="circle"
        type="primary"
        icon={<CheckOutlined />}
        onClick={() => setRearrangeMode(false)}
      />
    </Tooltip>
  );

  const joinCodeButton = (
    <Tooltip
    title={mrIntl("ExperienceManage.click_to_copy_join_code")}
      placement="bottom"
      getPopupContainer={(triggerNode) => triggerNode.parentNode}
    >
      <Button
        className={"join-code-custom-btn"}
        type={"primary"}
        ghost
        shape={"round"}
        onClick={() => {
          writeToClipboardPolyfill(experience.access_code);
          message.success(mrIntl("InfoModal.join_code_copied"));
        }}
      >
        {experience.access_code}
      </Button>
    </Tooltip>
  );

  let statusStyle;
  if (experience.status === "published") {
    statusStyle = {
      // className: "bg-green border-green",
      // background: "limegreen",
      // borderColor: "limegreen",
      icon: <CheckOutlined />,
      buttonType: "primary",
      // tooltipTitle: `Publish time ${dayjs(experience.updated_at).format(
      //   "MMM DD, HH:mm"
      // )}`,
      tooltipTitle: mrIntl("ExperienceStatusBtn.publish_date_and_time", {
        publishTimeAndDate: dayjs(experience.updated_at).format(
          "MMM DD, HH:mm"
        )
      }),
      // fix this -> save publish time and show that
    };
  } else if (experience.status === "draft") {
    statusStyle = {
      // className: "bg-orange border-orange",
      // background: "orangered",
      // borderColor: "orangered",
      icon: <MinusOutlined />,
      buttonType: "primary",
      danger: true,
      tooltipTitle: "",
    };
  } else if (experience.status === "closed") {
    statusStyle = {
      className: "bg-black border-black",
      // background: "lightslategrey",
      // borderColor: "lightslategrey",
      icon: <CloseOutlined />,
      buttonType: "primary",
      // tooltipTitle: `Close time ${dayjs(
      //   experienceSettings.close_at_datetime
      // ).format("MMM DD, HH:mm:ss")}`,
      tooltipTitle: mrIntl("ExperienceManage.close_time", {experienceClosedAtDateTime: dayjs(
        experienceSettings.close_at_datetime
      ).format("MMM DD, HH:mm:ss")}),
    };
  }

  const updateExperienceStatus = (statusToSet) => {
    const currentUserNameAndRole = `${currentUser.name} (${upperFirst(currentUser.role)})`
    dispatch(
      experienceActions.updateStatus(
        { id: experience.id, status: statusToSet },
        {
          successCallback: (response) => {
            const responseExperience = response.data.experience;
            console.log("experience from back ==>", responseExperience);
            if(responseExperience) {
              dispatch(
                experienceActions.showSuccess({
                  data: {
                    experience: {
                      ...experience,
                      status: responseExperience.status,
                      settings: responseExperience.settings,
                      // custom_fields: responseExperience.custom_fields
                    }
                  },
                })
              );
            }
            console.log('current user==>', currentUser);
            // let msg = `Assessment ${
            //   statusToSet === "draft" ? "changed to draft" : statusToSet
            // } by ${currentUser.name} (${upperFirst(currentUser.role)})`
            
            let msg =  
              statusToSet === "draft" 
                ? mrIntl("ExperienceManage.change_to_draft_msg", { currentUserNameAndRole: currentUserNameAndRole }) 
                : statusToSet === "published" 
                  ? mrIntl("ExperienceManage.change_to_publish_msg", { currentUserNameAndRole: currentUserNameAndRole }) 
                  : mrIntl("ExperienceManage.change_to_closed_msg", {currentUserNameAndRole: currentUserNameAndRole})

            // dispatch(
            //   firestoreInteractionActions.setLogs({
            //     logging: true,
            //     log_type: "experience",
            //     msg: msg,
            //     itemId: response.data.experience.id,
            //     experienceId: response.data.experience.id
            //   })
            // );
            // setLogs(msg)
            // dispatch(firestoreInteractionActions.setUserInfo(
            //   {
            //     id: currentUser.id
            //   },
            //   {
            //     log: {
            //       logging: true,
            //       log_type: "experience",
            //       msg: msg,
            //     },
            //   }
            // ))
            message.success(msg);
            setLoading(false);
          },
          errorCallback: (errors) => {
            console.log("errors ==>", errors);
            if (errors.length > 0) {
              errors.map((error) => {
                message.error(error.message);
              });
            } else {
              message.error(mrIntl("ExperienceManage.error_in_updating"));
            }
            setLoading(false);
          },
        }
      )
    );
  }

  const onExperienceStatusChange = (statusToSet) => {
    if (statusToSet === "published" && experience.attemptable_questions_count === 0) {
      message.error(
        mrIntl("ExperienceManage.publish_assessment_error_msg")
      );
      return;
    }
    const possibleStudents = experience.students || []
    const hasUnmappedStudents = experienceSettings.allow_unmapped_students === undefined || experienceSettings.allow_unmapped_students === true || possibleStudents.length !== 0;
    const hasUnsavedChanges = editModeForSegmentId !== 0 || showSegmentForm !== 0;

    console.log("map_classes_or_students =====>", possibleStudents, experienceSettings.allow_unmapped_students);
    setLoading(true);
    dispatch(
      firestoreInteractionActions.getExperienceInfo(
        {
          experienceId: experience.id,
        },
        {
          successCallback: (data) => {
            console.log("inside callback==>", data);
            let segment_types_count = experience.custom_fields.segment_types_count || {};
            const isAudioOrVideoInputAvailable = (enabledFeatures.question_type_audio_input || enabledFeatures.question_type_video_input) &&
              (segment_types_count.audio_input >= 1 || segment_types_count.video_input >= 1);
            // let title = "Publish assessment?"
            let title = mrIntl("ExperienceManage.publish_assessment");
            let content = "";
            let okText = "";
            let cancelText = "";
            let okButtonProps = {}

            if (statusToSet === "draft" && data.haveStudentsStarted) {
              title = mrIntl(
                "CommonText.some_students_have_already_started_this_assessment_msg"
              );
              okText = mrIntl("CommonText.yes");
              cancelText = mrIntl("CommonText.no");
            }

            if (statusToSet === "published") {
              if (experienceSettings.mode === "offline" && (experienceSettings.allow_unmapped_students || experienceSettings.allow_unmapped_students === undefined || possibleStudents.length != 0)) {
                content = (
                  <div>
                    <p>
                      {mrIntl(
                        "ExperienceManage.assesment_require_latest_lockdown_app"
                      )}
                    </p>
                    <ul>
                      <li>
                        {mrIntl("ExperienceManage.mac")} -{" "}
                        {/* {getLatestAppVersion("mac", enabledFeatures, true)} */}
                        {/* NOTE:- Doing this because new app is not launched yet. will uncomment the above code when it's launched */}
                        {getLatestAppVersion("mac", enabledFeatures)} 
                      </li>
                      <li>
                        {mrIntl("ExperienceManage.windows_seb")} -{" "}
                        {getLatestAppVersion("seb")}
                      </li>
                      <li>
                        {mrIntl("ExperienceManage.ipad")} -{" "}
                        {getLatestAppVersion("ios")}
                      </li>
                    </ul>
                  </div>
                );
              }
              if(experienceSettings.allow_unmapped_students === false && possibleStudents.length == 0) {
                content = mrIntl("ExperienceManage.the_test_cannot_be_published_as_there_are_no_students")
                okButtonProps = { style: { display: "none" } }
                title = mrIntl("ExperienceManage.unable_to_publish")
              }
              if (isAudioOrVideoInputAvailable) {
                const audioVideoInputMessage = (
                  <p>
                    {mrIntl("ExperienceManage.assessment_have_audio_video_question_msg")}
                  </p>
                );
                content = (
                  <div>
                    {content}
                    {audioVideoInputMessage}
                  </div>
                );
              }
              okText = mrIntl("ExperienceManage.publish_now");
              cancelText = mrIntl("CommonText.cancel");
            }
            const showConfirmModalParams = {
              title: title,
              content: content,
              okText: okText,
              cancelText: cancelText,
              onOk: () => {
                updateExperienceStatus(statusToSet);
              },
              onCancel: () => {
                setLoading(false);
                return;
              },
              okButtonProps: {
                ...okButtonProps,
                type: "primary",
              },
            };

            if (statusToSet === "published" && hasUnmappedStudents && hasUnsavedChanges) {
              showConfirmModal({
                title: mrIntl("ExperienceManage.unable_to_publish"),
                content: mrIntl("ExperienceManage.you_have_unsaved_changes_in_your_assessment"),
                okText: mrIntl("ExperienceManage.discard_changes"),
                cancelText: mrIntl("CommonText.cancel"),
                onOk: () => {
                  if (editModeForSegmentId) {
                    dispatch(segmentActions.setEditModeForSegmentIdSuccess({ editModeForSegmentId: 0 }));
                  }
                  if (showSegmentForm) {
                    dispatch(segmentActions.setShowSegmentFormSuccess({ showSegmentForm: 0 }));
                  }
                  if (experienceSettings.mode === "offline") {
                    showConfirmModal(showConfirmModalParams);
                  } else {
                    updateExperienceStatus(statusToSet);
                  }
                  setLoading(false);
                },
                onCancel: () => {
                  setLoading(false);
                },
              });
              return;
            }

            if (statusToSet === "draft" && data.haveStudentsStarted) {
              showConfirmModal(showConfirmModalParams);
            } else if (
              statusToSet === "published" &&
              (experienceSettings.mode === "offline" || isAudioOrVideoInputAvailable || (experienceSettings.allow_unmapped_students === false && possibleStudents.length == 0))
            ) {
              showConfirmModal(showConfirmModalParams);
            } else {
              updateExperienceStatus(statusToSet);
            }
          },
          errorCallback: (data) => {
            setLoading(false);
            updateExperienceStatus(statusToSet);
          },
        }
      )
    );


    
    // updateResource(
    //   { uid: experience.uid, status: statusToSet },
    //   {
    //     success: {
    //       showMessage: true,
    //       message: `Assessment ${statusToSet === "draft" ? "changed to draft" : statusToSet }`,
    //     },
    //     error: {
    //       showMessage: true,
    //       message: "Error in updating assessment status",
    //     },
    //     successCallback: (data) => {
    //       console.log("status updated", data);
    //     },
    //   }
    // );
  };

  let menuItems = [];
  if (experience.status === "draft") {
    menuItems.push(
      <Menu.Item
        className="assessment-status"
        key="menu-experience-status-1"
        // title="Publish test"
        title={mrIntl("ExperienceManage.publish_test")}
        icon={<CheckCircleTwoTone twoToneColor="#52c41a" />}
        onClick={() => onExperienceStatusChange("published")}
        disabled={loading}
      >
        <MrTranslate id={"CommonText.publish"}/>
      </Menu.Item>
    );
  } else if (experience.status === "published") {
    // if(experience.submissions_count == 0){
    menuItems.push(
      <Menu.Item
       className="assessment-status"
        key="menu-experience-status-3"
        title={
          experience.submissions_count !== 0
            ? mrIntl("CommonText.cannot_convert_to_draft_msg")
            : mrIntl("CommonText.change_assessment_to_draft")
        }
        disabled={(experience.submissions_count !== 0 || loading) ? true : false}
        icon={<MinusCircleTwoTone twoToneColor="#eb2f96" />}
        onClick={() => onExperienceStatusChange("draft")}
      >
      <MrTranslate id={"CommonText.draft"}/>
      </Menu.Item>
    );

    menuItems.push(
      <Menu.Item
        key="menu-experience-status-2"
        title= {mrIntl("CommonText.close_test")}
        icon={<CloseCircleTwoTone twoToneColor="lightslategrey" />}
        onClick={() => onExperienceStatusChange("closed")}
        disabled={loading}
      >
        <MrTranslate id={"CommonText.close"}/>
      </Menu.Item>
    );

    // }
    // else{
    //   menuItems.push(<Menu.Item key="menu-experience-status-2" icon={<CloseCircleTwoTone twoToneColor="lightslategrey"/>} onClick={() => updateExperienceStatus("closed")}>
    //     Close
    //   </Menu.Item>)
    // }
  } else if (experience.status === "closed") {
    menuItems.push(
      <Menu.Item
        key="menu-experience-status-3"
        disabled="true"
        // title="Cannot convert to draft since test has been closed"
        title={mrIntl("CommonText.cannot_convert_to_draft_after_closed")}
        icon={<MinusCircleTwoTone twoToneColor="#eb2f96" />}
      >
       <MrTranslate id={"CommonText.draft"} />
      </Menu.Item>
    );

    menuItems.push(
      <Menu.Item
        key="menu-experience-status-4"
        // title="Re-open test for students"
        title={mrIntl("CommonText.open_again_test")}
        icon={<CheckCircleTwoTone twoToneColor="#52c41a" />}
        onClick={() => onExperienceStatusChange("published")}
      >
        <MrTranslate id={"CommonText.publish"}/>
      </Menu.Item>
    );
  }

  let getExperienceStatusButtonText = () => {
    let getExperienceStatusButtonText = null
    if (experience.status === "draft") {
      getExperienceStatusButtonText = mrIntl("CommonText.draft")
    } else if (experience.status === "published")  {
      getExperienceStatusButtonText = mrIntl("CommonText.published")
    } else if (experience.status === "closed") {
      getExperienceStatusButtonText = mrIntl("CommonText.closed")
    }
    return getExperienceStatusButtonText
  }

  const deliverStatusActions = <Menu className="deliver-status-btn-menu">{menuItems}</Menu>;

  const changeStatusBtn = (
    <Tooltip placement="left" title={statusStyle.tooltipTitle}>
      <Dropdown
        overlay={deliverStatusActions}
        // placement="bottomRight"
        trigger={[dropDownTrigger]}
      >
        <Button
          loading={loading}
          className={statusStyle.className}
          style={{
            // background: statusStyle.background,
            // borderColor: statusStyle.borderColor,
            textTransform: "capitalize",
          }}
          shape={isMobile ? "circle" : "round"}
          icon={statusStyle.icon}
          ghost={false}
          type={statusStyle.buttonType}
          danger={statusStyle.danger}
          trigger={"click"}
        >
          {/* {experience.status} <DownOutlined /> */}
          {/* {!isMobile && experience.status} */}
          {!isMobile && getExperienceStatusButtonText()}
          {/* <DownOutlined /> */}
        </Button>
        {/* <Button style={experience.status == "published" ? { background: "limegreen", borderColor: "limegreen" } : { background: "orangered", borderColor: "orangered" }} shape="round" icon={experience.status == "published" ? <CheckOutlined /> : <MinusOutlined />} ghost={false} type={experience.status == "published" ? "primary" : "danger"}>
        {experience.status}
        </Button> */}
      </Dropdown>
    </Tooltip>
  );

  // TODO: @uttam figure out how to convert below into Menu.Item - later
  const uploadResourceButton = (
    <ResourceModal
      createMode={true}
      attachments_json={experience.attachments_json}
      submitResources={(attachments_json) =>
        updateResource(
          {
            uid: experience.uid,
            attachments_json: attachments_json,
          },
          {
            success: {
              showMessage: true,
              message: mrIntl("ExperienceManage.resource_saved_successfully"),
            },
            error: {
              showMessage: true,
              message: mrIntl("ExperienceManage.error_in_saving_resource"),
            },
          }
        )
      }
      programmeSubjectId = {experience && experience.relation_items && experience.relation_items[0].programme_subject_id}
    ></ResourceModal>
  );

  const taskSpecificClarification = (
    <TaskSpecificClarification
    editAllowed={true}
    updateResource={updateResource}
  />
  );

  const handlePreviewButton = () => {
    console.log("handlePreviewButton ===>");
    const msg = `Assessment was previewed by ${currentUser.name} (${currentUser.role})` 
    setLogs(msg)
    setExperienceViewMode("apPreviewTest")
  }

  const previewModeButton = (
    <Tooltip title={mrIntl("ExperienceManage.preview")} placement="bottom" getPopupContainer={triggerNode => triggerNode.parentNode}>
      <Button
        shape="circle"
        icon={<EyeOutlined />}
        onClick={() => handlePreviewButton()}
      />
    </Tooltip>
  );

  // adding duplicateBtn to operations in PageHeader for teacher when a QB test is opened
  // temp isLibrary true - cuz only in case of qb test right now
  // showOpenBtn hidden for now in this case - first clean up showExperience and Link to
  const duplicateBtn = (
    <DuplicateExperienceButton
      item={experience}
      sourceId={experience.uid}
      duplicateResource={duplicateResource}
      isLibrary={true}
      withText={true}
      showOpenBtn={false}
    />
  );

  const translateAssessmentBtn = (
    <TranslateAssessment
    isLibrary={true}
    item={experience}
    />
  )

  const experienceHeaderMoreActionsButton = (
    <Dropdown
      overlay={experienceHeaderMoreActionsMenu}
      placement="bottomRight"
      trigger={[dropDownTrigger]}
      getPopupContainer={(triggerNode) => triggerNode.parentNode}
    >
      <Button shape="round">
        {<MrTranslate id={"ExperienceManage.actions"}/>}<DownOutlined />
      </Button>
    </Dropdown>
  );

  const externalVideoCallButton = (
    <ExternalVideoCallButton
      videoCallLink={experienceSettings.video_call_link}
      experienceSettings = {experienceSettings}
    ></ExternalVideoCallButton>
  );

  const shouldHideForPamoja = enabledFeatures.special_ui_for_pamoja_school && experience?.custom_fields?.block

  // TODO: move embed to actions also - moved but css not working there
  // const embedButton = <Tooltip title="Embed" placement="bottom">
  //   <Button shape="circle" icon={<LinkOutlined />} href={`/embed/${experience.uuid}`} target="_blank" />
  // </Tooltip>

  const operations = (
    <Space>
      {experience.access_code && !experience.questionbank && joinCodeButton}
      {(experience.current_user_can_edit || experience.current_user_can_deliver) && !embedded && changeStatusBtn}
      {/* {experience.uuid && embedButton} */}
      {(experience.current_user_can_edit ||
        experience.current_user_can_monitor) &&
        experienceSettings.include_video_call_link &&
        experienceSettings.video_call_link &&
        !isMobile &&
        (activeKey && activeKey !== 'c' && activeKey === 'monitor') &&
        externalVideoCallButton}
      {/* {editAllowed && rearrangeButton} */}
      {rearrangeMode && disableRearrangeBtn}
      {!isMobile && editAllowed && uploadResourceButton}
      {/* <ExperienceSettings {...props} /> */}
      {(experience.current_user_can_edit ||
        experience.current_user_can_monitor) &&
        previewModeButton}
      {showTaskSpecificClarification && tscEnabled  && taskSpecificClarification}
      {/* {experience.current_user_can_edit && editCollaboratorsButton} */}
      {/* {experience.current_useroperation_can_edit && editInvigilatorsButton} */}
      {/* {experience.current_user_can_edit && printButton} */}
      {/* TODO CHECK CONDITIONS - show for qb tests */}
      {enabledFeatures.translate_assessment &&
        experience.questionbank_id &&
        (currentUser.role === "admin" || currentUser.role === "teacher") &&
        !isMobile &&
        translateAssessmentBtn}
      {experience.questionbank_id &&
        (currentUser.role === "admin" || currentUser.role === "teacher") &&
        !isMobile &&
        duplicateBtn}
      {!isMobile && !isAdminOrTeacherWithQB && !isStudentReviewerMode && !shouldHideForPamoja && experienceHeaderMoreActionsButton}
    </Space>
  );

  experienceProps = { ...experienceProps, rearrangeMode: rearrangeMode };
  
  console.log("exportModalConfig before final render ==>", exportModalConfig);
  let finalRender = (
    <React.Fragment>
      {/* Initializes firestore, gets token, sets initial values */}
      {rearrangeMode ? (
        <ExperiencePageHeader
          activeKey={activeKey}
          setActiveKey={setActiveKey}
          experience={experience}
          experienceViewMode={experienceViewMode}
          fromResourceName={fromResourceName}
          fromUrl={fromUrl}
          pageHeaderProps={{
            // className: "site-page-header-responsive experience-header",
            title: false,
            subTitle: false,
            backIcon: false,
            footer: false,
            extra: rearrangeButton,
          }}
        ></ExperiencePageHeader>
      ) : (
        <ExperiencePageHeader
          activeKey={activeKey}
          setActiveKey={setActiveKey}
          embedded={embedded}
          experience={experience}
          experienceViewMode={experienceViewMode}
          fromResourceName={fromResourceName}
          fromUrl={fromUrl}
          pageHeaderProps={{
            className: `site-page-header-responsive experience-header  ${
              isMobile ? "mobile" : ""
            } ${mbGradeBookEmbeddedView ? "visibility-hidden" : ""}`,
            // className="site-page-header-responsive experience-header"
            // onBack={() => history.push("/")}
            extra: !embedded && operations,
            // TODO: below permissions/show/hide via configs
          }}
          updateResource={updateResource}
        ></ExperiencePageHeader>
      )}

      <div className={"experience-manage-container ap-app"}>
        {/* TODO: Create tab new component */}
        {/* TODO: handle multiple authoring layouts - current, Live quiz slides, OMR quick create sections with questions */}
        {activeKey === "c" && (
          <ExperienceCreate {...props} experienceProps={experienceProps} />
        )}

        {/* TODO: Deliver tab new component */}
        {activeKey === "deliver" && (
          <ExperienceDeliver {...props} experienceProps={experienceProps} />
        )}

        {activeKey === "monitor" && (
          <ExperienceMonitor experience={experience} />
        )}

        {activeKey == "grade" &&
          ((experienceSettings.peer_review_enabled && isStudentReviewerMode) ||
          experience.current_user_can_grade ||
          currentUser.role === "support" ? (
            <Row>
              <Route
                path={`${path}/grade`}
                render={(routeProps) => (
                  <ExperienceGrade
                    experienceProps={{
                      ...experienceProps,
                    }}
                    exportModalConfig={exportModalConfig}
                    setExportModalConfig={setExportModalConfig}
                    isRoute={false}
                    isGradingEnabled={true}
                    mbGradeBookEmbeddedView={mbGradeBookEmbeddedView}
                    {...routeProps}
                  />
                )}
              />
            </Row>
          ) : (
            <Alert
              type={"error"}
              message={mrIntl("ExperienceManage.grading_is_only_enabled_for_the_author_msg")}
            />
          ))}

        {activeKey === "analytics" && <ExperienceAnalytics />}

        <ExportModal
          experience={experience}
          exportModalConfig={exportModalConfig}
          setExportModalConfig={setExportModalConfig}
          showTaskSpecificClarification={showTaskSpecificClarification}
          tscEnabled={tscEnabled} 
        />
      </div>

      <FirestoreInteractions experience={experience} />
      {/* To show/hide collab and invig forms in modal */}
      <div>
        {renderForm({
          name: "experiencesCollaborators",
          config: {
            isModal: true,
            visible: showFormVal === "experiencesCollaborators",
            modal: { title: mrIntl("ExperienceManage.collaborate"), closeIcon: false},
            actions: { showCancelBtn: true },
            handleCancelCallback: () => { // It is to Override the handleCancelCallback as previously when clicked cancel, it takes to the previous state that we were on and we get a blank page.
              console.log("cancelled");
            },
            submitConfig: {
              hideForm: true,
              successCallback: (data) => {
                {/* console.log("collaborators added", data);
                let updatedData = data.data.experience.data.attributes; 
                let msg = `Collaborators set to ${updatedData.collaborators.toString()} by ${currentUser.name} (${upperFirst(currentUser.role)})`
                setLogs(msg) */}
              },
            },
          },
          resource: experience,
        })}
      </div>
      <div>
        {renderForm({
          name: "experiencesInvigilators",
          config: {
            isModal: true,
            visible: showFormVal === "experiencesInvigilators",
            submitConfig: {
              hideForm: true,
              successCallback: (data) => {
                console.log("invigilators added", data);
              },
            },
            // modal: { title: "Choose invigilators" },
            modal: { title: mrIntl("ExperienceManage.choose_invigilators"), closeIcon: false},
            actions: { showCancelBtn: true },
            handleCancelCallback: () => { // It is to Override the handleCancelCallback as previously when clicked cancel, it takes to the previous state that we were on and we get a blank page.
              console.log("cancelled")
            },
          },
          resource: experience,
        })}
      </div>
      {enabledFeatures.allow_chatgpt_for_teachers && !isAdminOrTeacherWithQB && <TalkToGPT experienceViewMode={experienceViewMode} />}
      {enabledFeatures.translate_assessment && <TranslationProgressModal />}
    </React.Fragment>
  );

  return finalRender;
};

export default ExperienceManage;
