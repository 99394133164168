import React, { useEffect, useMemo, useState } from 'react';
import { Button, Modal, Select, Table } from "antd";
import "./BulkImportUsers.scss";
import { buildOptionsArr } from "/src/lib/utils/helperMethods";
import FileUploadSteps from "./FileUploadSteps";
import { useDispatch, useSelector } from "react-redux";
import { currentUserSelector } from "/src/views/Auth/Login/selector";
import { cloneDeep, isArray, isEmpty, isObject, uniq } from "lodash";
import { actions } from "/src/views/Orgs/redux";
import ImportStatus from './ImportStatus';
import { useTranslate } from '/src/lib/MrTranslate/MrTranslate';

const updateMappedValue = (sourceKeys, targetMapping, columnIndex, newArrayWithMappedKeys, row, rowIndex) => {
  if (!sourceKeys?.length) return;

  const sourceValue = row[columnIndex];

  sourceKeys.forEach(sourceKey => {
    const targetValue = targetMapping[sourceKey];
    const isMatch = isArray(targetValue)
      ? targetValue.includes(sourceValue) 
      : targetValue?.toLowerCase() === sourceValue?.toLowerCase();

    if (isMatch) {
      newArrayWithMappedKeys[rowIndex][columnIndex] = sourceKey;
    }
  });
};

export function updateArrayWithObjectKeys(obj, arr = [], role, terms) {
  let newArray = cloneDeep(arr);
  if (!obj || !Array.isArray(newArray) || Object.keys(obj).length === 0 || newArray.length === 0) {
    return newArray;
  }
  // Function to check if an object is empty
  const isEmptyObject = (obj) => Object.keys(obj).length === 0;
  let newArrayWithMappedKeys = []
  // Filter out empty objects
  let filteredArray = newArray.filter(obj => !isEmptyObject(obj));
  
  if (obj.mappedHeader && filteredArray[0]) {
    const keys = Object.keys(obj.mappedHeader || {});
    if (keys.length === 0) {
      return filteredArray;
    }
    const indices = [];
    keys.forEach((key, i) => {
      const index = filteredArray[0].findIndex((item) => item === obj.mappedHeader[key]);
      indices.push(index);
      if (index !== -1) {
        filteredArray[0][index] = key;
      }
    });
    filteredArray.forEach(row => {
      const newRow = indices.map(index => row[index]);
      newArrayWithMappedKeys.push(newRow);
    });
  }
  console.log("newArrayWithMappedKeys =====>", newArrayWithMappedKeys);
  const headers = newArrayWithMappedKeys[0];
  if (!isEmpty(obj.mappedGrade) && headers) {
    const gradeKeys = Object.keys(obj.mappedGrade || {});
    const subjectKeys = Object.keys(obj.mappedSubject || {});
    const subjectGroupKeys = Object.keys(obj.mappedSubjectGroups || {});
    console.log("gradeKeys =====>", gradeKeys, subjectKeys, subjectGroupKeys);
    const gradeIndex = headers.findIndex((item) => (item === "Grade" || item === "Grade Level"));
    const subjectIndex = headers.findIndex((item) => item === "Subject");
    const subjectGroupIndex = headers.findIndex((item) => item === "Subject Group");
    const fieldMappings = [
      { sourceKeys: gradeKeys, targetMapping: obj.mappedGrade, columnIndex: gradeIndex },
      { sourceKeys: subjectKeys, targetMapping: obj.mappedSubject, columnIndex: subjectIndex },
      { sourceKeys: subjectGroupKeys, targetMapping: obj.mappedSubjectGroups, columnIndex: subjectGroupIndex }
    ];

    newArrayWithMappedKeys.forEach((row, rowIndex) => {
      if (rowIndex === 0) return row;
      fieldMappings.forEach(({ sourceKeys, targetMapping, columnIndex }) => {
        updateMappedValue(sourceKeys, targetMapping, columnIndex, newArrayWithMappedKeys, row, rowIndex);
      });
    });
    console.log("newArrayWithMappedKeys =====>", newArrayWithMappedKeys);
  }
  newArrayWithMappedKeys = newArrayWithMappedKeys.slice(1).map(item => {
    const obj = {};
    newArrayWithMappedKeys[0].forEach((key, index) => {
      console.log("key =====>", key, item[index]);
      obj[key] = item[index];
      if (key === "Email") {
        obj["Role"] = role;
      }
      if (key === "Role") {
        if (terms.length > 1) {
          const startTerm = terms[0];
          const endTerm = terms[1];
          obj['Start term'] = startTerm.start_term;
          obj['End term'] = endTerm.end_term;
        }
      }
    });
    return obj;
  });
  console.log("newArrayWithMappedKeys2 =====>", newArrayWithMappedKeys);

  return newArrayWithMappedKeys;
}

export const RenderTable = ({ leftSide, excelData, setDataMapping, dataMapping, columnNames, mappingKey, mode, hideSelect = false, currentPage = null }) => {
  const defaultValue = mode === "single" ? null : [];
  const options = buildOptionsArr(uniq(excelData));
  // This is to remove the start term and end term from the column names in case of classes
  const filteredColumnNames = columnNames.filter((columnName) => columnName !== "Start term" && columnName !== "End term");
  
  useEffect(() => {
    console.log("mappingKey =====>", mappingKey, !isEmpty(dataMapping[mappingKey]));
    if(hideSelect) {
      return;
    }
    if(isEmpty(dataMapping[mappingKey])) {
      const obj = {};
      leftSide.forEach((l) => {
        const matchingRow = excelData.find((row) => {
          if (isObject(l)) {
            return `${l.label}`.toLowerCase() === `${row}`.toLowerCase();
          } else {
            return `${l}`.toLowerCase() === `${row}`.toLowerCase();
          }
        });
        
        if (isObject(l) && matchingRow) {
          obj[`${l.label}`] = matchingRow;
        } else if (matchingRow) {
          obj[`${l}`] = matchingRow;
        } else {
          if(currentPage === 1) {
            obj[`${l}`] = "";
          }
        }
      });
      
      console.log("obj dataMapping =====>", obj, excelData, leftSide);
      setDataMapping({ ...dataMapping, [mappingKey]: obj });
    }
  }, [mappingKey]);

  console.log("options =====>", options, excelData);
  const handleSelectChange = (item, cellValue) => {
    const updatedMapping = {
      ...dataMapping[mappingKey] || {},
      [`${cellValue}`]: item
    };

    setDataMapping({ ...dataMapping, [mappingKey]: updatedMapping });
  };

  const renderColumns = () => {
    return filteredColumnNames.map((columnName, index) => {
      const renderContent = (cellData, cellIndex) => {
        const cellValue = isObject(cellData) ? (cellData.label ? cellData.label : cellData[columnName]) : cellData;
        const value = isObject(dataMapping[mappingKey]) ? dataMapping[mappingKey][cellValue] : defaultValue;
        if (index === 0 || hideSelect) {
          return <span>{cellValue}</span>;
        } else {
          return (
            <Select
              onChange={(item) => handleSelectChange(item, cellValue)}
              value={value}
              mode={mode}
              options={options}
              style={{ width: "100%" }}
              key={`${columnName}_${index}`}
            />
          );
        }
      };

      return {
        title: columnName,
        dataIndex: "",
        key: `${columnName}_${index}`,
        render: renderContent
      };
    });
  };

  return <Table className='bulk-imports-table' dataSource={leftSide || excelData} columns={renderColumns()} pagination={false} />;
};

const BulkImportUsers = ({ singleResourceName, isMenuItem, org }) => {
  console.log("BulkImportUsers ======>", singleResourceName);
  const [showModal, setShowModal] = useState(false);
  const [bulkImportConfig, setBulkImportConfig] = useState({
    programId: null,
    currentPage: 1,
    file: null,
    role: "student",
  });
  const [excelData, setExcelData] = useState([]);
  const [dataMapping, setDataMapping] = useState({});
  const [loading, setLoading] = useState(false);
  const [updatedExcelData, setUpdatedExcelData] = useState([]);
  const [disableNextButton, setDisableNextButton] = useState(false)
  const [bulkImportInfo, setBulkImportInfo] = useState({});
  const currentUser = useSelector(currentUserSelector());
  const currentOrg = (currentUser.role === "superadmin" ? org : currentUser.org) || {};
  const currentOrgId = currentOrg.id;
  const tableHeaders = {
    student: ["First Name", "Last Name", "Email", "Grade"],
    teacher: ["First Name", "Last Name", "Email"],
    admin: ["First Name", "Last Name", "Email"],
    parent: ["First Name", "Last Name", "Email"]
  };
  const modalTitles = ["Bulk add or update users", "Field Mapping", "Grade Mapping", "Preview import data", "Import status"];
  const currentOrgProgrammes = currentOrg.org_programmes || [];
  const dispatch = useDispatch();
  const totalPage = 5;
  const mrIntl = useTranslate();
  const gradeOptions = useMemo(() => {
    const currentOrgProgrammeGrades = currentOrgProgrammes.find(program => program.id === bulkImportConfig.programId)?.grades ?? [];
    
    return currentOrgProgrammeGrades.map(grade => ({ label: grade.label, value: grade.label }));
  }, [bulkImportConfig.programId]);

  console.log("currentOrgProgrammeGradesIndex =====>", gradeOptions, currentOrgProgrammes);

  const getGradeData = useMemo(() => {
    if (excelData.length > 0 && dataMapping.mappedHeader && dataMapping.mappedHeader.Grade) {
      const gradeIndexInTable = excelData[0].findIndex((header) => header.includes(dataMapping.mappedHeader.Grade));
      return excelData.slice(1).map((row) => row[gradeIndexInTable]).filter(value => value !== undefined);
    }
    return [];
  }, [excelData, dataMapping]);

  useEffect(() => {
    validateError();
  }, [bulkImportConfig, dataMapping]);

  useEffect(() => {
    if(bulkImportConfig.currentPage === 1) {
      setDataMapping({});
    }
  }, [bulkImportConfig.currentPage]);

  useEffect(() => {
    let interval = null;
    let retries = 0;
    // let delay = 300;
    let delay = 5;
    if (loading) {
      interval = setInterval(() => {
        dispatch(
          actions.importStatus({
            id: currentOrgId,
          }, {
            successCallback: (response) => {
              console.log("response =====>", response);
              if (response.bulk_import_info.users_import_status === "completed") {
                setBulkImportInfo(response.bulk_import_info);
                setLoading(false);
                if (interval) {
                  clearInterval(interval);
                }
              }
            }
          })
        )
        retries += 1;
        if (retries % 10 === 0) {
          // console.log( "updating delay" );
          delay += 45;
        }
        if (retries > 20) {
          if (interval) {
            clearInterval(interval);
          }
        }
      }, delay * 1000);
    } else {
      if (interval) {
        clearInterval(interval);
      }
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [loading]);

  const handleMenuItemClick = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);
  const handleModalBack = () => {
    if (bulkImportConfig.currentPage > 1) {
      let pageNumberToAdd = 1
      if(bulkImportConfig.role !== "student" && bulkImportConfig.currentPage === 4) {
        pageNumberToAdd = 2
      }
      setBulkImportConfig((prevConfig) => ({ ...prevConfig, currentPage: prevConfig.currentPage - pageNumberToAdd }));
    }
  };

  const validateError = () => {
    switch (bulkImportConfig.currentPage) {
      case 1:
        if (bulkImportConfig.file && (bulkImportConfig.programId || bulkImportConfig.role !== "student")) {
          setDisableNextButton(false);
        } else {
          setDisableNextButton(true);
        }
        break;
      case 2:
        const headerValues = Object.values(dataMapping.mappedHeader || {});
        const withoutEmptyValues = headerValues.filter((value) => value !== "");
        const isHeadersMapped = withoutEmptyValues.length === tableHeaders[bulkImportConfig.role].length;
        if (isHeadersMapped) {
          setDisableNextButton(false);
        } else {
          setDisableNextButton(true);
        }
        break;
      case 3:

        const isGradeMapped = Object.keys(dataMapping.mappedGrade || {}).length >= uniq(getGradeData).length;
        if (isGradeMapped) {
          setDisableNextButton(false);
        } else {
          setDisableNextButton(true);
        }
        break;
      default:
        setDisableNextButton(false);
    }
  };

  const clearLocalState = () => {
    setExcelData([]);
    setDataMapping({});
    setUpdatedExcelData([]);
    setBulkImportInfo({});
  }

  const renderModalContent = () => {
    const modalContent = [];
    modalContent.push(
      <FileUploadSteps bulkImportConfig={bulkImportConfig} setBulkImportConfig={setBulkImportConfig} setExcelData={setExcelData} key="file-upload" singleResourceName={singleResourceName} clearLocalState={clearLocalState} currentOrgId={currentOrgId} />
    )
    console.log("tableHeaders[bulkImportConfig.role] ====>", tableHeaders[bulkImportConfig.role]);
    if (excelData.length > 0) {
      modalContent.push(
        <div key="mapping">
          <p>{mrIntl("BulkImportUsers.please_map_the_required_fields_on_assessprep_with_the_column")}</p>
          <RenderTable leftSide={tableHeaders[bulkImportConfig.role]} excelData={excelData[0]} setDataMapping={setDataMapping} dataMapping={dataMapping} columnNames={["AssessPrep Fields", "Uploaded File Column Headers"]} key="mapped-header" mappingKey="mappedHeader" mode="single" currentPage={currentPageIndex} />
        </div>
      );
      modalContent.push(
        <div key="mapping-grades">
          <p>{mrIntl("BulkImportUsers.map_the_grades_from_assessprep_to_the_corresponding_grade_values")}.</p>
          <RenderTable leftSide={gradeOptions} excelData={getGradeData} setDataMapping={setDataMapping} dataMapping={dataMapping} columnNames={["AssessPrep Grades", "Uploaded File Grades"]} key="mapped-grade" mappingKey="mappedGrade" mode="multiple" />
        </div>
      );
      modalContent.push(
        <div key="preview-import-data">
          <p>{mrIntl("BulkImportUsers.please_validate_the_data_based_on_the_first_3_rows")}</p>
          <RenderTable excelData={updatedExcelData.slice(0, 3)} setDataMapping={setDataMapping} dataMapping={dataMapping} columnNames={Object.keys(updatedExcelData[0] || {})} hideSelect={true} key="preview-data-table" mode="multiple" />
        </div>
      );
      modalContent.push(
        <ImportStatus loading={loading} key="import-status" bulkImportInfo={bulkImportInfo} singleResourceName={singleResourceName} totalDataCount={updatedExcelData.length} />
      )
    }

    return modalContent;
  };
  const currentPageIndex = bulkImportConfig.currentPage - 1;
  const currentPageContent = renderModalContent()[currentPageIndex];

  return (
    <>
      <Button type={isMenuItem ? "text" : "primary"} onClick={handleMenuItemClick} disabled={org?.is_mb} className="users-bulk-import-button">
        {isMenuItem ? mrIntl("BulkImportUsers.import_users") : mrIntl("BulkImportUsers.new_bulk_import_users")}
      </Button>
      <Modal
        title={modalTitles[bulkImportConfig.currentPage - 1]}
        visible={showModal}
        width="60%"
        className='bulk-import-users-modal'
        onCancel={handleModalClose}
        footer={[
          <Button key="cancel" onClick={handleModalBack} disabled={bulkImportConfig.currentPage === 1 || loading}>
            {mrIntl("BulkImportUsers.back")}
          </Button>,
          <Button
            key="import"
            type="primary"
            disabled={disableNextButton || loading}
            onClick={() => {
              console.log("importing data =====>", updatedExcelData, bulkImportConfig);
              if ((bulkImportConfig.role === "student" && bulkImportConfig.currentPage >= 3) || (bulkImportConfig.role !== "student" && bulkImportConfig.currentPage >= 2)) {
                console.log("importing data =====>", updatedExcelData, bulkImportConfig, excelData);
                const previewDataHeaders = updateArrayWithObjectKeys(dataMapping, excelData, bulkImportConfig.role);
                console.log("previewDataHeaders =====>", previewDataHeaders);
                setUpdatedExcelData(previewDataHeaders);
                if (bulkImportConfig.currentPage === 4 && bulkImportConfig.file) {
                  setLoading(true);
                  const header = Object.keys(previewDataHeaders[0]);
                  const values = [header]
                  previewDataHeaders.forEach((row) => {
                    values.push(Object.values(row));
                  });
                  console.log("newValues newValues =====>", values);
                  dispatch(
                    actions.update({
                      id: currentOrgId,
                      org_programme_id: bulkImportConfig.programId,
                      bulk_import_data: {...values},
                      users_role: bulkImportConfig.role,
                      type_c: 'users'
                    }, {
                      successCallback: () => {
                        // setLoading(false);
                      },
                      errorCallback: () => setLoading(false)
                    })
                  );
                }
              }
              let pageNumberToAdd = 1
              if (bulkImportConfig.currentPage < 5) {
                if(bulkImportConfig.role !== "student" && bulkImportConfig.currentPage === 2) {
                  pageNumberToAdd = 2
                }
                setBulkImportConfig((prevConfig) => ({ ...prevConfig, currentPage: prevConfig.currentPage + pageNumberToAdd }));
              } else {
                handleModalClose();
                setBulkImportConfig({
                  programId: null,
                  currentPage: 1,
                  file: null,
                  role: "student",
                });
                clearLocalState()
              }
            }}
          >
            {bulkImportConfig.currentPage === totalPage ? mrIntl("BulkImportUsers.finish") : mrIntl("BulkImportUsers.next")}
          </Button>,
        ]}
      >
        {currentPageContent}
      </Modal>
    </>
  );
};


export default BulkImportUsers;