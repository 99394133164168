import React, { useEffect, useState } from "react";

import MediaUI from "/src/components/UI/Media/MediaUI"
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const BulkImportFileURL = props => {
  console.log("BulkImportFileURL props ==>", props)

  const fileURL = props.value || ''
  const mrIntl = useTranslate();
  const mediaFormInitialState = {
    modal: false,
    loading: false,
    visible: false,
    title: mrIntl("CommonText.insert_files"),
    okText: mrIntl("CommonText.add_file"),
    cancelText: mrIntl("CommonText.cancel"),
    triggerSaveAction: 0,
    requireFooter: false,
    content_type: 'excel',
    limit: 1,
    supportedExtensions: 'xlsx, xls',
    supportedFormats: 'xlsx, xls',
    selectConfig: {
      maxSize: "20 MB",
      disabledButton: props.disabled,
    },
  }
  const [mediaFormSetting, setMediaFormSetting] = useState(mediaFormInitialState)

  const onSaveMedia = (files) => {
    if (files && files[0]) {
      let url = files[0].url
      if (props.onChange) {
        props.onChange(url)
      }
      setMediaFormSetting({
        ...mediaFormSetting,
        from: '',
        triggerSaveAction: 0,
      })
    }
  }

  return (
    <>
      <MediaUI
        computer={true}
        url={true}
        mediaFormSetting={mediaFormSetting}
        setMediaFormSetting={setMediaFormSetting}
        onSaveMedia={onSaveMedia}
      ></MediaUI>

      {fileURL &&
        <h4 style={{
          'overflow-wrap': 'break-word',
          'word-wrap': 'break-word',
          'word-break': 'break-word'
        }}>
          <MrTranslate id={"BulkImportFileURL.file_url"}/>:
          <br/>
          <a href={fileURL}>
            {fileURL}
          </a>
        </h4>
      }
    </>
  )
}

export default BulkImportFileURL;