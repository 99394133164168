import React from 'react';
import { Row, Col, Button, Divider } from 'antd';
import './DataEmptyState.scss';
import { PlusOutlined, DatabaseOutlined } from '@ant-design/icons';
import { Link, useHistory, useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslate } from '/src/lib/MrTranslate/MrTranslate';

const DataEmptyState = (props) => {
  const { dataEmptyStateConfig } = props;
  console.log("DataEmptyState dataEmptyStateConfig", dataEmptyStateConfig);
  const mrIntl = useTranslate()
  const history = useHistory();
  let { url } = useRouteMatch();

  return <div className='empty-assessment-wrapper'>
    <Row justify="center" className="message-container">
      <Col>
        <h2 className="no-assessment-title">{mrIntl("DataEmptyState.no", { dataEmptyStateConfig: dataEmptyStateConfig?.content })}</h2>
        <p className="subtitle">{mrIntl("DataEmptyState.you_have_not_added_any", { dataEmptyStateConfig: dataEmptyStateConfig?.content })}</p>
      </Col>
    </Row>
    <Row justify="center">
      <Col>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          className="create-button"
          onClick={() => history.push(`${url}/create`)}
        >
          {mrIntl("DataEmptyState.create_new", { dataEmptyStateConfig: dataEmptyStateConfig?.content })}
        </Button>
      </Col>
    </Row>
    <Row justify="center" align="middle" className='divider-container'>
      <Divider className="divider"> {mrIntl("CommonText.or")} </Divider>
    </Row>
    <Row justify="center">
      <Col>
        <Link to={`/u/library`}>
          <Button
            icon={<DatabaseOutlined />}
            onClick={(e) => e.stopPropagation()}
          >
            {mrIntl("DataEmptyState.go_to_library")}
          </Button>
        </Link>
      </Col>
    </Row>
  </div>;
};

export default DataEmptyState;
