import { useEffect, useRef } from "react";
import { getUniqueId } from "/src/lib/utils/helperMethods";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { wrapSelectedWordInSpan } from "./HighlightWords";

const addCorrectAndIncorrectClassName = (ans, presentationMode) => {
  if (!presentationMode) return "";

  if (ans.correct) return "correct";
  if (ans.checked && (!ans.hasOwnProperty("correct") || !ans.correct)) {
    return "incorrect";
  }
  return "";
};

const HighlightEditor = (props) => {
  const { createMode, options, textContent, presentationMode, gradingMode } = props;
  const ref = useRef(null);
  const mrIntl = useTranslate();

  useEffect(() => {
    const callback = (sortedSelectedTextRanges, result) => {
      for (const selectTextRange of sortedSelectedTextRanges) {
        const before = result.substring(0, selectTextRange.startIndex);
        const toWrap = result.substring(
          selectTextRange.startIndex,
          selectTextRange.endIndex
        );
        const after = result.substring(selectTextRange.endIndex);
        const correctAndIncorrectClassName = addCorrectAndIncorrectClassName(
          selectTextRange,
          presentationMode
        );

        result = `${before}<span class="editor-highlighted-word  select-none relative ${correctAndIncorrectClassName}" id="correct-ans-${selectTextRange.id}">${toWrap}</span>${after}`;
      }
      return result;
    };

    const selectedWordInSpan = wrapSelectedWordInSpan(
      textContent,
      options,
      callback
    );

    ref.current.innerHTML = selectedWordInSpan;
  }, [options, textContent, gradingMode, presentationMode]);

  const createSpanElement = (uniqueId, selectedText) => {
    const span = document.createElement("span");
    span.id = `correct-ans-${uniqueId}`;
    span.className = "highlighted-word relative";
    span.textContent = selectedText;
    return span;
  };

  const createLabelElement = () => {
    const label = document.createElement("label");
    label.className = "select-none";
    label.contentEditable = false;
    label.textContent = mrIntl("HighlightEditor.hot_text");
    label.onClick = (e) => {
      e.stopPropagation();
      e.preventDefault();
    };
    return label;
  };

  const handleHotTextCheck = (uniqueId, selectedText, startIndex, endIndex) => {
    // Find the element by ID
    const element = document.getElementById(`confirm-modal-${uniqueId}`);

    // Check if the element exists before removing
    if (element) {
      element.remove(); // Removes the element from the DOM
    }

    props.onCheck(uniqueId, selectedText, startIndex, endIndex);
  };

  const createCheckboxElement = (
    uniqueId,
    selectedText,
    range,
    confirmModal,
    startIndex,
    endIndex
  ) => {
    const checkbox = document.createElement("input");
    checkbox.type = "checkbox";
    checkbox.className = "pointer-events-auto cursor-pointer";
    checkbox.onclick = () =>
      handleHotTextCheck(uniqueId, selectedText, startIndex, endIndex);

    // Replace the selected text with a span
    const span = createSpanElement(uniqueId, selectedText);
    span.appendChild(confirmModal);

    // Replace the selected text with the new span
    range.deleteContents();
    range.insertNode(span);

    return checkbox;
  };

  const createConfirmPopup = (
    uniqueId,
    selectedText,
    selectedTextRange,
    startIndex,
    endIndex
  ) => {
    const label = createLabelElement();
    const confirmModal = document.createElement("div");
    const checkbox = createCheckboxElement(
      uniqueId,
      selectedText,
      selectedTextRange,
      confirmModal,
      startIndex,
      endIndex
    );
    confirmModal.id = `confirm-modal-${uniqueId}`;
    confirmModal.className = "hot-text-pop-confirm pointer-events-none";
    confirmModal.appendChild(checkbox);
    confirmModal.appendChild(label);
    return confirmModal;
  };

  const handlePaste = (event) => {
    if (presentationMode) return;
    event.preventDefault();

    // Get the plain text from the clipboard
    const text = (event.clipboardData || window.clipboardData).getData(
      "text/plain"
    );

    // Insert the text at the current cursor position
    const selection = window.getSelection();
    if (selection.rangeCount) {
      const range = selection.getRangeAt(0);
      range.deleteContents(); // Remove any selected content
      range.insertNode(document.createTextNode(text)); // Insert plain text
      selection.collapseToEnd();
    } else {
      // If no selection, append the text directly
      ref.current.appendChild(document.createTextNode(text));
    }

    props.onInput(text);
  };

  // Helper function to calculate offset from the start of the contentEditable div
  function calculateOffsetFromStart(container, node, offset) {
    let current = node;
    let totalOffset = offset;

    while (current && current !== container) {
      if (current.previousSibling) {
        current = current.previousSibling;
        totalOffset += current.textContent.length;
      } else {
        current = current.parentNode;
      }
    }

    return totalOffset;
  }

  const handleClick = (e) => {
    if (presentationMode) return;

    const selection = window.getSelection();

    // Do nothing if any part of the selection is already highlighted
    const isAlreadyWrapped = Array.from(
      selection.getRangeAt(0).cloneContents().childNodes
    ).some((node) => {
      return (
        node.nodeType === Node.ELEMENT_NODE &&
        node.tagName === "SPAN" &&
        node.classList.contains("editor-highlighted-word")
      );
    });

    // Check if the clicked element is a label
    if (
      ["LABEL", "SPAN"].includes(e.target.tagName) ||
      selection.toString().length === 0 ||
      isAlreadyWrapped
    ) {
      const popup = e.currentTarget.querySelector(".hot-text-pop-confirm");
      if (popup) {
        const popupId = popup.id;
        const id = popupId.split("-")[2];
        const span = document.getElementById(`correct-ans-${id}`);

        popup.remove(); // remove popup if already exists
        // Get the content inside the span
        const content = span.textContent;
        // Replace the span with its content
        span.replaceWith(content);
      }
      return; // Exit the function if it's a label or span
    }

    if (selection.rangeCount > 0 && selection.toString().length > 0) {
      const uniqueId = getUniqueId();
      // Get the range of the current selection
      const selectedTextRange = selection.getRangeAt(0);

      // Get start and end positions
      // const startIndex = range.startOffset;
      const startIndex = calculateOffsetFromStart(
        ref.current,
        selectedTextRange.startContainer,
        selectedTextRange.startOffset
      );
      // const endIndex = range.endOffset;
      const endIndex = startIndex + selection.toString().length;
      // Get the selected text
      const selectedText = selection.toString();

      // If you need the full text of the div
      const fullText = e.target.textContent;

      createConfirmPopup(
        uniqueId,
        selectedText,
        selectedTextRange,
        startIndex,
        endIndex
      ); // create an confirm popup
    }
  };

  return (
    <div
      ref={ref}
      contentEditable
      className={`highlight-word-input-box ${createMode ? "create-mode" : ""}`}
      onClick={handleClick}
      onPaste={handlePaste}
      onInput={(e) => props.onInput(e.target.textContent)}
    />
  );
};

export default HighlightEditor;
