import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { currentUserSelector } from '/src/views/Auth/Login/selector';

import Orgs from '/src/views/Orgs/Orgs';
import OrgFeatureItemDetail from '/src/views/Orgs/OrgItemDetail/OrgFeatureItemDetail';
import { useRouteMatch, Route } from "react-router-dom";
import { Modal } from 'antd';

const FeaturesModal = (props) => {
  const currentUser = useSelector(currentUserSelector())
  const { path } = useRouteMatch();  

  // if (!(import.meta.env.VITE_MODE === "development" && currentUser?.role === 'admin')) {
  //   return null;
  // }

  const [isVisible, setIsVisible] = useState(false);

  const handleKeyboardShortcuts = (e) => {
    if ((e.altKey || e.metaKey) && e.key === "k") {
      e.preventDefault();
      setIsVisible(true);
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", handleKeyboardShortcuts);
    return () => {
      document.removeEventListener("keydown", handleKeyboardShortcuts);
    }
  }, [])


  //TODO: Remove this once we have a proper way to handle feature flags
  if (currentUser?.role !== 'admin') {
    return null;
  }


  
  

  return (
    <Modal
      open={isVisible}
      onCancel={() => setIsVisible(false)}
      footer={null}
      width={"50%"}
    >
      <Route
        path={`${path}`}
        render={(routeProps) => (
          <Orgs
            resourceId={currentUser.org_id}
            embedded={false}          
            isRoute={false}
            {...routeProps}
            config={{

              routes: { fetch: false},
              itemDetail: { widget: (props) => <OrgFeatureItemDetail {...props} setIsVisible={setIsVisible} />},
            }}
          />
        )}
      />
    </Modal>
  );
};

export default FeaturesModal;