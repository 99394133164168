import {Map} from "immutable";
import { createSelector } from "reselect";
import { activeAdjustedExperienceState } from "/src/views/Experiences/selector";
import { enabledFeaturesState } from "/src/views/Auth/Login/selector";
import { experienceUsersSorter } from "/src/lib/utils/helperMethods";

const experienceUsersLoadingState = (state, props) => {
  return state.experienceUsers.get("loading");
};
export const experienceUsersLoadingSelector = () =>
  createSelector([experienceUsersLoadingState], (loading) => loading);

export const publishResultsExperienceUsersLoadingState = (state) =>
  state.experienceUsers.get("publishloading", false);

export const publishResultsExperienceUsersLoadingSelector = () =>
  createSelector([publishResultsExperienceUsersLoadingState], (publishloading) => publishloading);

  export const exportAllToExcelExperienceUsersLoadingState = (state) =>
  state.experienceUsers.get("exportloading", false);

export const exportAllToExcelExperienceUsersLoadingSelector = () =>
  createSelector([exportAllToExcelExperienceUsersLoadingState], (exportloading) => exportloading);

const submitAttachmentsLoadingState = (state) =>
  state.experienceUsers.get("submitloading", false);

export const submitAttachmentsLoadingSelector = () =>
  createSelector([submitAttachmentsLoadingState], (submitloading) => submitloading);

export const experienceUsersCountState = (state) =>
  state.experienceUsers.get("experience_users", []).length;

export const experienceUsersCountSelector = () =>
  createSelector([experienceUsersCountState], (experienceUsersCount) => experienceUsersCount);

export const experienceUsersState = (state) =>
  state.experienceUsers.get("experience_users", []);

export const experienceUsersSelector = (only_submissions) =>
  createSelector(
    [experienceUsersState, studentNameFormatState],
    (experienceUsers, studentNameFormat) => {
      let sortedUsers = experienceUsers.slice().sort((a, b) => experienceUsersSorter(a, b, studentNameFormat))

      if (only_submissions) {
        sortedUsers = sortedUsers.filter((eu) => eu.dup_from_id === null)
      }
      return sortedUsers;
    }
  );

export const activeExperienceUserIdState = (state) =>
  state.experienceUsers.get("activeExperienceUserId", null);

export const activeExperienceUserIdSelector = () =>
  createSelector([activeExperienceUserIdState], (activeExperienceUserId) => activeExperienceUserId);

export const activeExperienceUserSelector = () =>
  createSelector([experienceUsersState, activeExperienceUserIdState], (experienceUsers, activeExperienceUserId) => {
    console.log("activeExperienceUserSelector ==>", experienceUsers, activeExperienceUserId)
    return experienceUsers.find((item) => item.id === parseInt(activeExperienceUserId)) || {}
  });

export const studentNameFormatState = (state) =>
  state.experienceUsers.get("studentNameFormat", "first_name");

export const studentNameFormatSelector = () =>
  createSelector([studentNameFormatState], (studentNameFormat) => studentNameFormat);

export const userResponseState = (state) =>
  state.userResponses.get("user_responses", []);

export const allUserResponsesSelector = () =>
  createSelector([userResponseState], (userResponses) => userResponses);

export const currentCriteriaIdState = (state) =>
  state.experienceUsers.get("currentCriteriaId", "");

export const currentCriteriaIdSelector = () =>
  createSelector([currentCriteriaIdState], (currentCriteriaId) => currentCriteriaId);

export const gradingModalVisibleState = (state) =>
  state.experienceUsers.get("gradingModalVisble", false);

export const gradingModalVisibleSelector = () =>
  createSelector([gradingModalVisibleState], (gradingModalVisible) => gradingModalVisible);

export const overallGradingFormVisibleState = (state) =>
  state.experienceUsers.get("overallGradingFormVisible", false);

export const overallGradingFormVisibleSelector = () =>
  createSelector([overallGradingFormVisibleState], (overallGradingFormVisible) => overallGradingFormVisible);

export const showConvertedAchievementLevelState = (state, props) => {
  let showConvertedAchievementLevel = false
  const activeExperience = activeAdjustedExperienceState(state)
  console.log("activeExperienceGradingDisabledState", activeExperience)
  if (activeExperience && activeExperience.rubric && activeExperience.rubric.type_c === "criteria_with_points"){
    showConvertedAchievementLevel = true
  }
  return showConvertedAchievementLevel
}

export const showConvertedAchievementLevelSelector = () =>
  createSelector([showConvertedAchievementLevelState], (showConvertedAchievementLevel) => showConvertedAchievementLevel);

export const activeAnnotateFileState = (state) =>
  state.experienceUsers.get("activeAnnotateFile", null);

export const activeAnnotateFileSelector = () =>
  createSelector([activeAnnotateFileState], (activeAnnotateFile) => activeAnnotateFile);

export const isExperienceUserGradingInProgressSelector = () =>
  createSelector([experienceUsersState], (experienceUsers) =>
    experienceUsers.some((user) => {
      const gradingStatus = user.custom_fields?.ai_grading_status?.status;
      return gradingStatus === "started" || gradingStatus === "in_progress";
    })
  );
