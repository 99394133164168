import React, { useEffect, useState, useSelector } from "react";
import { Select, Row, Col, Form, Input, Radio, InputNumber, Tooltip, Space, Tag } from "antd";
import { InfoCircleTwoTone, QuestionCircleTwoTone } from "@ant-design/icons";
import { FormRenderer } from "mr_react_framework";
import { getQuestionContent, validateText } from "../../../lib/utils/helperMethods";
import CKETextContent from "../CKETextContent/CKETextContent";

import CKESubjective from "../CKESubjective/CKESubjective";
import TextAnswerExplanation from "../Explanation/TextAnswerExplanation";

import MCQSingle from "../MCQ/MCQSingle";
import MCQMultiple from "../MCQ/MCQMultiple";
import TrueFalse from "../TrueFalse/TrueFalse"
import FabricDrawing from "../FabricDrawing/FabricDrawing";
import FabricLabel from "../FabricLabel/FabricLabel";
import FabricHotspot from "../FabricHotspot/FabricHotspot";
import GGBGraph from "../GGBGraph/GGBGraph";
import RBDInlineMatching from "../RBDMatching/RBDInlineMatching";
import RBDInlineSorting from "../RBDSorting/RBDInlineSorting";
import RBDInlineClassify from "../RBDClassify/RBDInlineClassify";
import RBDMatching from "../RBDMatching/RBDMatching";
import RBDSorting from "../RBDSorting/RBDSorting";
import RBDClassify from "../RBDClassify/RBDClassify";
import RDSTable from "../RDSTable/RDSTable";
import RTCAudioRecording from "../RTCRecording/RTCAudioRecording";
import AudioInput from "../AudioInput/AudioInput";
import CKEFillText from "../CKEFill/CKEFillText";
import CKEFillDropDown from "../CKEFill/CKEFillDropDown";
import FlashCard from "../FlashCard/FlashCard";
import VideoInput from "../VideoInput/VideoInput";
import MiniQuiz from "../MiniQuiz/MiniQuiz"

import { validateJSON } from "/src/views/Segments/InteractiveHelpers"
import VideoAnswerExplanation from "../Explanation/VideoAnswerExplanation";
import PDFAnswerExplanation from "../Explanation/PDFAnswerExplanation";
import DesmosGraph from "../DesmosGraph/DesmosGraph";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import Connect from "../Connect/Connect";
import Markscheme from "../Markscheme/Markscheme";
import FileUpload from "../FileUpload/FileUpload";
import NumberLine from "../NumberLine/NumberLine";
import HighlightWords from "../HighlightWords/HighlightWords";

const segmentConfig = {
  createMode: true,
  editMode: false,
  autoCheckMode: false,
}

const ckeSubjectiveForm = (props) => {
  console.log("line number 47", props);
  const { formObjProps, setFormObjProps, getFieldValue, mrIntl } = props
  const { validateTrigger } = formObjProps
  const questionText = getFieldValue(["question_segment_attributes", "content"])  

  return {
    type: "object",
    properties: {
      content: {
        type: "string",
        title: mrIntl("QuestionForm.question_content"),
        // title: "Question content",
        widget: CKESubjective,
        widgetConfig: {
          ...segmentConfig,
          writingAssistantConfig: { enabled: questionText },
        },
        trigger: 'onSubmit',
        formItemConfig: { validateTrigger: validateTrigger },
        rules: [{
          validator(rule, value) {
            const validationRules = {
              // required: true,
              required: formObjProps.required,
              maxCharsMessage: mrIntl("QuestionForm.limit_reached_msg"),
              requiredMessage: mrIntl("CommonText.required_msg"),
            }
            const validation = validateText(value, validationRules)
            const questionType = getFieldValue(["question_segment_attributes", "question_type"]);

            if (!validation.success && questionType != 'group') {
              setFormObjProps({
                ...formObjProps,
                validateTrigger: 'onChange'
              })
              return Promise.reject(validation.messages)
            } else {
              return Promise.resolve()
            }
          },
        }]
      },
    }
  }
}

const ckeAnswerExplanationForm = (props) => {
  const { formObjProps, setFormObjProps, getFieldValue, mrIntl } = props
  console.log("ckeAnswerExplanationForm ====>", props);
  
  const { validateTrigger } = formObjProps
  const questionSegmentAttributes = getFieldValue(["question_segment_attributes"]) || {}
  const questionType = questionSegmentAttributes.question_type || ""
  const questionContent = getQuestionContent(questionType, questionSegmentAttributes)

  return {
    type: "object",
    properties: {
      answer_explanation: {
        type: "string",
        title: (
          <Space>
            <span>{mrIntl("CommonText.teacher_explanation")}</span>
            <Tag>{mrIntl("QuestionForm.visible_to_students_after_grading_tags")}</Tag>
          </Space>
        ),
        widget: TextAnswerExplanation,
        widgetConfig: {
          ...segmentConfig,
          questionContent: questionContent,
          questionType: questionType,
        },
        trigger: 'onSubmit',
        formItemConfig: { validateTrigger: validateTrigger },
        rules: [{
          validator(rule, value) {
            const validationRules = {
              required: true,
              maxCharsMessage: mrIntl("QuestionForm.limit_reached_msg")
            }
            const validation = validateText(value, validationRules)
            const attachments_json = getFieldValue(["question_segment_attributes", "attachments_json"])
            let isAttachments = false
            if (attachments_json && (attachments_json["video_answer_explanation"] || attachments_json["pdf_answer_explanation"])) {
              isAttachments = attachments_json["video_answer_explanation"]["videos"].length || attachments_json["pdf_answer_explanation"]["pdfs"].length
            }
            console.log("attachments_json in answer explanation", attachments_json)
            if (!validation.success && !isAttachments) {
              setFormObjProps({
                ...formObjProps,
                validateTrigger: 'onChange'
              })
              return Promise.reject(validation.messages)
            } else {
              return Promise.resolve()
            }
          },
        }]
      },
    }
  }
}

const markschemeForm = (props) => {
  console.log("markscheme from logs props===>>>", props)
  const { formObjProps, setFormObjProps, mrIntl, getFieldValue } = props
  const { validateTrigger } = formObjProps
  const questionContent = getFieldValue(["question_segment_attributes", "content"])
  const questionType = getFieldValue(["question_segment_attributes", "question_type"])

  return {
    type: "object",
    properties: {
      markscheme: {
        type: "string",
        title: (
          <Space>
            <span>{mrIntl("QuestionForm.markscheme_title")}</span>
            <Tag>{mrIntl("QuestionForm.only_visible_to_teachers_title")}</Tag>
          </Space>
        ),
        widget: Markscheme,
        widgetConfig: {
          ...segmentConfig,
          questionContent: questionContent,
          questionType: questionType
        },
        trigger: 'onSubmit',
        formItemConfig: { validateTrigger: validateTrigger },
        rules: [{
          validator(rule, value) {
            const validationRules = {
              required: true,
              maxCharsMessage: mrIntl("QuestionForm.limit_reached_msg")
            }
            const validation = validateText(value, validationRules)
            if (!validation.success) {
              setFormObjProps({
                ...formObjProps,
                validateTrigger: 'onChange'
              })
              return Promise.reject(validation.messages)
            } else {
              return Promise.resolve()
            }
          },
        }]
      },
    }
  }
}
const videoAnswerExplanationForm = (props) => {
  const { formObjProps, setFormObjProps, mrIntl } = props
  const { validateTrigger } = formObjProps
  return {
    type: 'object',
    properties: {
      video_answer_explanation: {
        type: "string",
        title: mrIntl("QuestionForm.video_answer_explanation"),
        widget: VideoAnswerExplanation,
        widgetConfig: {
          ...segmentConfig,
        },
        trigger: 'onSubmit',
        formItemConfig: { validateTrigger: validateTrigger },
      },
    }
  }
}

const pdfAnswerExplanationForm = (props) => {
  const { formObjProps, setFormObjProps, mrIntl } = props
  const { validateTrigger } = formObjProps
  return {
    type: 'object',
    properties: {
      pdf_answer_explanation: {
        type: "string",
        title: mrIntl("QuestionForm.pdf_answer_explanation"),
        widget: PDFAnswerExplanation,
        widgetConfig: {
          ...segmentConfig,
        },
        trigger: 'onSubmit',
        formItemConfig: { validateTrigger: validateTrigger },
      },
    }
  }
}

const mcqSingleForm = (props) => {
  const { formObjProps, setFormObjProps, mrIntl } = props
  const { validateTrigger } = formObjProps

  return {
    type: "object",
    properties: {
      teacher_json: {
        type: "string",
        title: mrIntl("CommonText.answer_choices"),
        // title: "Answer choices",
        widget: MCQSingle,
        widgetConfig: {
          ...segmentConfig,
          question_type: 'mcq_single',
        },
        trigger: 'onSubmit',
        formItemConfig: { validateTrigger: validateTrigger },
        rules: [{
          validator(rule, value) {
            console.log("validator is called with rule", rule, value)
            const validation = validateJSON('mcq_single', value)
            if (!validation.success) {
              setFormObjProps({
                ...formObjProps,
                validateTrigger: 'onChange'
              })
              return Promise.reject(validation.messages)
            } else {
              return Promise.resolve()
            }
          },
        }]
      },
    }
  }
}

const numberLineForm = (props) => {
  const { formObjProps } = props

  return {
    type: "object",
    properties: {
      teacher_json: {
        type: "string",
        title: props.mrIntl("CommonText.number_line"),
        // title: "Number line",
        widget: NumberLine,
        widgetConfig: {
          ...segmentConfig,
          question_type: 'number_line',
        },
        trigger: 'onSubmit',
        formItemConfig: { validateTrigger: formObjProps.validateTrigger },
        rules: [{
          validator(rule, value) {
            console.log("validator is called with rule", rule, value)
            const validation = validateJSON('number_line', value)
            if (!validation.success) {
              props.setFormObjProps({
                ...formObjProps,
                validateTrigger: 'onChange'
              })
              return Promise.reject(validation.messages)
            } else {
              return Promise.resolve()
            }
          },
        }]
      },
    }
  }
}

const mcqMultipleForm = (props) => {
  console.log("line number 215", props);
  const { formObjProps, setFormObjProps, mrIntl } = props
  const { validateTrigger } = formObjProps

  return {
    type: "object",
    properties: {
      teacher_json: {
        type: "string",
        title: mrIntl("CommonText.answer_choices"),
        // title: "Answer choices",
        widget: MCQMultiple,
        widgetConfig: {
          ...segmentConfig,
          question_type: 'mcq_multiple',
          // ...mcqMultipleData
        },
        trigger: 'onSubmit',
        formItemConfig: { validateTrigger: validateTrigger },
        rules: [{
          validator(rule, value) {
            const validation = validateJSON('mcq_multiple', value)
            if (!validation.success) {
              setFormObjProps({
                ...formObjProps,
                validateTrigger: 'onChange'
              })
              return Promise.reject(validation.messages)
            } else {
              return Promise.resolve()
            }
          },
        }]
      },
    }
  }
}

const trueFalseForm = (props) => {
  console.log("true false form===>", props)
  const { formObjProps, setFormObjProps, mrIntl } = props
  const { validateTrigger } = formObjProps
  return {
    type: "object",
    properties: {
      teacher_json: {
        type: "string",
        title: mrIntl("QuestionForm.statements"),
        // title: "Statements",
        widget: TrueFalse,
        widgetConfig: {
          ...segmentConfig,
          question_type: 'true_false',
          // ...mcqMultipleData
        },
        trigger: 'onSubmit',
        formItemConfig: { validateTrigger: validateTrigger },
        rules: [{
          validator(rule, value) {
            const validation = validateJSON('true_false', value)
            if (!validation.success) {
              setFormObjProps({
                ...formObjProps,
                validateTrigger: 'onChange'
              })
              return Promise.reject(validation.messages)
            } else {
              return Promise.resolve()
            }
          },
        }]
      },
    }
  }
}

const fabricDrawingForm = (props) => {
  const { formObjProps, setFormObjProps, mrIntl } = props
  const { validateTrigger } = formObjProps

  return {
    type: "object",
    properties: {
      teacher_json: {
        type: "string",
        title: mrIntl("QuestionForm.drawing"),
        // title: "Drawing",
        widget: FabricDrawing,
        widgetConfig: {
          ...segmentConfig,
          question_type: 'fabric_drawing',
          // ...interactiveData
        },
        trigger: 'onSubmit',
        formItemConfig: { validateTrigger: validateTrigger },
        rules: [{
          validator(rule, value) {
            const validation = validateJSON('fabric_drawing', value)
            if (!validation.success) {
              setFormObjProps({
                ...formObjProps,
                validateTrigger: 'onChange'
              })
              return Promise.reject(validation.messages)
            } else {
              return Promise.resolve()
            }
          },
        }]
      },
    }
  }
}

const fabricDNDLabelForm = (props) => {
  const { formObjProps, setFormObjProps, mrIntl } = props
  const { validateTrigger } = formObjProps

  return {
    type: "object",
    properties: {
      teacher_json: {
        type: "string",
        title: mrIntl("QuestionForm.label_drag_and_drop"),
        // title: "Label Drag and Drop",
        widget: FabricLabel,
        widgetConfig: {
          ...segmentConfig,
          question_type: 'fabric_dnd_label'
          // ...dndLabelData
        },
        trigger: 'onSubmit',
        formItemConfig: { validateTrigger: validateTrigger },
        rules: [{
          validator(rule, value) {
            const validation = validateJSON('fabric_dnd_label', value)
            if (!validation.success) {
              setFormObjProps({
                ...formObjProps,
                validateTrigger: 'onChange'
              })
              return Promise.reject(validation.messages)
            } else {
              return Promise.resolve()
            }
          },
        }]
      },
    }
  }
}

const fabricFillLabelForm = (props) => {
  const { formObjProps, setFormObjProps, mrIntl } = props
  const { validateTrigger } = formObjProps

  return {
    type: "object",
    properties: {
      teacher_json: {
        type: "string",
        title: mrIntl("QuestionForm.label_fill"),
        // title: "Label - Fill",
        widget: FabricLabel,
        widgetConfig: {
          ...segmentConfig,
          question_type: 'fabric_fill_label'
          // ...dndLabelData
        },
        trigger: 'onSubmit',
        formItemConfig: { validateTrigger: validateTrigger },
        rules: [{
          validator(rule, value) {
            const validation = validateJSON('fabric_fill_label', value)
            if (!validation.success) {
              setFormObjProps({
                ...formObjProps,
                validateTrigger: 'onChange'
              })
              return Promise.reject(validation.messages)
            } else {
              return Promise.resolve()
            }
          },
        }]
      },
    }
  }
}

const fabricHotspotForm = (props) => {
  const { formObjProps, setFormObjProps, mrIntl } = props
  const { validateTrigger } = formObjProps

  return {
    type: "object",
    properties: {
      teacher_json: {
        type: "string",
        title: mrIntl("CommonText.hotspot"),
        // title: "Hotspot",
        widget: FabricHotspot,
        widgetConfig: {
          ...segmentConfig,
          question_type: 'fabric_hotspot'
          // ...hotspotData
        },
        trigger: 'onSubmit',
        formItemConfig: { validateTrigger: validateTrigger },
        rules: [{
          validator(rule, value) {
            const validation = validateJSON('fabric_hotspot', value)
            console.log("hotspot value in validator ==>", value)
            if (!validation.success) {
              setFormObjProps({
                ...formObjProps,
                validateTrigger: 'onChange'
              })
              return Promise.reject(validation.messages)
            } else {
              return Promise.resolve()
            }
          },
        }]
      },
    }
  }
}

const ggbGraphForm = (props) => {
  const { formObjProps, setFormObjProps, mrIntl } = props
  const { validateTrigger } = formObjProps

  return {
    type: "object",
    properties: {
      teacher_json: {
        type: "string",
        title: mrIntl("QuestionForm.geogebra_graph"),
        // title: "Geogebra Graph",
        widget: GGBGraph,
        widgetConfig: {
          ...segmentConfig,
          question_type: 'ggb_graph'
          // ...graphData
        },
        trigger: 'onSubmit',
        formItemConfig: { validateTrigger: validateTrigger },
        rules: [{
          validator(rule, value) {
            const validation = validateJSON('ggb_graph', value)
            if (!validation.success) {
              setFormObjProps({
                ...formObjProps,
                validateTrigger: 'onChange'
              })
              return Promise.reject(validation.messages)
            } else {
              return Promise.resolve()
            }
          },
        }]
      },
    }
  }
}

const desmosGraphForm = (props) => {
  const { formObjProps, setFormObjProps, mrIntl } = props
  const { validateTrigger } = formObjProps

  return {
    type: "object",
    properties: {
      teacher_json: {
        type: "string",
        title: mrIntl("QuestionForm.desmos_graph"),
        // title: "Desmos Graph",
        widget: DesmosGraph,
        widgetConfig: {
          ...segmentConfig,
          question_type: 'desmos_graph'
          // ...graphData
        },
        trigger: 'onSubmit',
        formItemConfig: { validateTrigger: validateTrigger },
        rules: [{
          validator(rule, value) {
            const validation = validateJSON('desmos_graph', value)
            if (!validation.success) {
              setFormObjProps({
                ...formObjProps,
                validateTrigger: 'onChange'
              })
              return Promise.reject(validation.messages)
            } else {
              return Promise.resolve()
            }
          },
        }]
      },
    }
  }
}

const rbdInlineMatchingForm = (props) => {
  const { formObjProps, setFormObjProps, mrIntl } = props
  const { validateTrigger } = formObjProps
  // const mrIntl = useTranslate();
  return {
    type: "object",
    properties: {
      teacher_json: {
        type: "string",
        title: mrIntl("QuestionForm.match_pairs"),
        // title: "Match pairs",
        widget: RBDInlineMatching,
        widgetConfig: {
          ...segmentConfig,
          question_type: 'rbd_inline_matching'
        },
        trigger: 'onSubmit',
        formItemConfig: { validateTrigger: validateTrigger },
        rules: [{
          validator(rule, value) {
            const validation = validateJSON('rbd_inline_matching', value)
            if (!validation.success) {
              setFormObjProps({
                ...formObjProps,
                validateTrigger: 'onChange'
              })
              return Promise.reject(validation.messages)
            } else {
              return Promise.resolve()
            }
          },
        }]
      },
    }
  }
}

const rbdMatchingForm = (props) => {
  const { formObjProps, setFormObjProps, mrIntl } = props
  const { validateTrigger } = formObjProps

  return {
    type: "object",
    properties: {
      teacher_json: {
        type: "string",
        title: mrIntl("QuestionForm.matching_title"),
        widget: RBDMatching,
        widgetConfig: {
          ...segmentConfig,
          question_type: 'rbd_matching'
          // ...matchingData
        },
        trigger: 'onSubmit',
        formItemConfig: { validateTrigger: validateTrigger },
        rules: [{
          validator(rule, value) {
            const validation = validateJSON('rbd_matching', value)
            if (!validation.success) {
              setFormObjProps({
                ...formObjProps,
                validateTrigger: 'onChange'
              })
              return Promise.reject(validation.messages)
            } else {
              return Promise.resolve()
            }
          },
        }]
      },
    }
  }
}

const rbdSortingForm = (props) => {
  const { formObjProps, setFormObjProps, mrIntl } = props
  const { validateTrigger } = formObjProps

  return {
    type: "object",
    properties: {
      teacher_json: {
        type: "string",
        title: mrIntl("QuestionForm.sorting_title"),
        widget: RBDSorting,
        widgetConfig: {
          ...segmentConfig,
          question_type: 'rbd_sorting'
          // ...sortingData
        },
        trigger: 'onSubmit',
        formItemConfig: { validateTrigger: validateTrigger },
        rules: [{
          validator(rule, value) {
            const validation = validateJSON('rbd_sorting', value)
            if (!validation.success) {
              setFormObjProps({
                ...formObjProps,
                validateTrigger: 'onChange'
              })
              return Promise.reject(validation.messages)
            } else {
              return Promise.resolve()
            }
          },
        }]
      },
    }
  }
}

const rbdInlineSortingForm = (props) => {
  const { formObjProps, setFormObjProps, mrIntl } = props
  const { validateTrigger } = formObjProps

  return {
    type: "object",
    properties: {
      teacher_json: {
        type: "string",
        title: mrIntl("RBDInlineClassify.items"),
        // title: "Items",
        widget: RBDInlineSorting,
        widgetConfig: {
          ...segmentConfig,
          question_type: 'rbd_inline_sorting'
          // ...sortingData
        },
        trigger: 'onSubmit',
        formItemConfig: { validateTrigger: validateTrigger },
        rules: [{
          validator(rule, value) {
            const validation = validateJSON('rbd_inline_sorting', value)
            if (!validation.success) {
              setFormObjProps({
                ...formObjProps,
                validateTrigger: 'onChange'
              })
              return Promise.reject(validation.messages)
            } else {
              return Promise.resolve()
            }
          },
        }]
      },
    }
  }
}

const rbdClassifyForm = (props) => {
  const { formObjProps, setFormObjProps, mrIntl } = props
  const { validateTrigger } = formObjProps

  return {
    type: "object",
    properties: {
      teacher_json: {
        type: "string",
        title: mrIntl("CommonText.classify"),
        widget: RBDClassify,
        widgetConfig: {
          ...segmentConfig,
          question_type: 'rbd_classify'
          // ...classifyData
        },
        trigger: 'onSubmit',
        formItemConfig: { validateTrigger: validateTrigger },
        rules: [{
          validator(rule, value) {
            const validation = validateJSON('rbd_classify', value)
            if (!validation.success) {
              setFormObjProps({
                ...formObjProps,
                validateTrigger: 'onChange'
              })
              return Promise.reject(validation.messages)
            } else {
              return Promise.resolve()
            }
          },
        }]
      },
    }
  }
}

const rbdInlineClassifyForm = (props) => {
  const { formObjProps, setFormObjProps, mrIntl } = props
  const { validateTrigger } = formObjProps
  console.log("rbdInlineClassifyForm ==>", props)

  return {
    type: "object",
    properties: {
      teacher_json: {
        type: "string",
        title: mrIntl("QuestionForm.categories_and_items"),
        // title: "Categories and items",
        widget: RBDInlineClassify,
        widgetConfig: {
          ...segmentConfig,
          question_type: 'rbd_inline_classify'
          // ...classifyData
        },
        trigger: 'onSubmit',
        formItemConfig: { validateTrigger: validateTrigger },
        rules: [{
          validator(rule, value) {
            const validation = validateJSON('rbd_inline_classify', value)
            if (!validation.success) {
              setFormObjProps({
                ...formObjProps,
                validateTrigger: 'onChange'
              })
              return Promise.reject(validation.messages)
            } else {
              return Promise.resolve()
            }
          },
        }]
      },
    }
  }
}

const ckeFillTextForm = (props) => {
  const { formObjProps, setFormObjProps, mrIntl } = props
  const { validateTrigger } = formObjProps
  
  return {
    type: "object",
    properties: {
      teacher_json: {
        type: "string",
        title: mrIntl("QuestionForm.text_with_gaps"),
        // title: "Text with gaps",
        widget: CKEFillText,
        widgetConfig: {
          ...segmentConfig,
          question_type: 'cke_fill_text'
          // ...fillBlanksData
        },
        trigger: 'onSubmit',
        formItemConfig: { validateTrigger: validateTrigger },
        rules: [{
          validator(rule, value) {
            const validation = validateJSON('cke_fill_text', value)
            if (!validation.success) {
              setFormObjProps({
                ...formObjProps,
                validateTrigger: 'onChange'
              })
              return Promise.reject(validation.messages)
            } else {
              return Promise.resolve()
            }
          },
        }]
      },
    }
  }
}

const ckeFillDropDownForm = (props) => {
  console.log("line number 778",);
  const { formObjProps, setFormObjProps, mrIntl } = props
  const { validateTrigger } = formObjProps

  return {
    type: "object",
    properties: {
      teacher_json: {
        type: "string",
        title: mrIntl("QuestionForm.text_with_dropdown_gaps"),
        // title: "Text with dropdown gaps",
        widget: CKEFillDropDown,
        widgetConfig: {
          ...segmentConfig,
          question_type: 'cke_fill_dropdown'
          // ...fillBlanksData
        },
        trigger: 'onSubmit',
        formItemConfig: { validateTrigger: validateTrigger },
        rules: [{
          validator(rule, value) {
            const validation = validateJSON('cke_fill_dropdown', value)
            if (!validation.success) {
              setFormObjProps({
                ...formObjProps,
                validateTrigger: 'onChange'
              })
              return Promise.reject(validation.messages)
            } else {
              return Promise.resolve()
            }
          },
        }]
      },
    }
  }
}

const rdsTableForm = (props) => {
  const { formObjProps, setFormObjProps, mrIntl } = props
  const { validateTrigger } = formObjProps
  

  return {
    type: "object",
    properties: {
      teacher_json: {
        type: "string",
        title: mrIntl("QuestionForm.table_and_graph"),
        // title: "Table and graph",
        widget: RDSTable,
        widgetConfig: {
          ...segmentConfig,
          question_type: 'rds_table'
          // ...tableChartData
        },
        trigger: 'onSubmit',
        formItemConfig: { validateTrigger: validateTrigger },
        rules: [{
          validator(rule, value) {
            const validation = validateJSON('rds_table', value)
            if (!validation.success) {
              setFormObjProps({
                ...formObjProps,
                validateTrigger: 'onChange'
              })
              return Promise.reject(validation.messages)
            } else {
              return Promise.resolve()
            }
          },
        }],
        // rules: [({ getFieldValue }) => ({
        //     validator(rule, value) {
        //       return Promise.reject('The two passwords that you entered do not match!');
        //     },
        //   }),
        // ]
      },
    }
  }
}

const audioInputForm = (props) => {
  const { formObjProps, setFormObjProps, mrIntl } = props
  const { validateTrigger } = formObjProps

  return {
    type: "object",
    properties: {
      teacher_json: {
        type: "string",
        title: <span>
          { mrIntl("QuestionForm.audio_input")}
          {/* Audio Input */}
           &nbsp;
          <Tooltip
            title={mrIntl("QuestionForm.not_supported_for_mac_msg")}
            placement="top"
          >
            {/* <sup> */}
            <QuestionCircleTwoTone />
            {/* </sup> */}
          </Tooltip>
        </span>,
        widget: AudioInput,
        widgetConfig: {
          ...segmentConfig,
          question_type: 'audio_input'
          // ...recordingData
        },
        trigger: 'onSubmit',
        formItemConfig: { validateTrigger: validateTrigger },
        rules: [{
          validator(rule, value) {
            const validation = validateJSON('audio_input', value)
            if (!validation.success) {
              setFormObjProps({
                ...formObjProps,
                validateTrigger: 'onChange'
              })
              return Promise.reject(validation.messages)
            } else {
              return Promise.resolve()
            }
          },
        }]
      },
    }
  }
}

const videoInputForm = (props) => {
  const { formObjProps, setFormObjProps, mrIntl } = props
  const { validateTrigger } = formObjProps

  return {
    type: "object",
    properties: {
      teacher_json: {
        type: "string",
        title: <span>
          Video Input &nbsp;
          <Tooltip
            title={mrIntl("QuestionForm.not_supported_for_mac_os_110_and_below_and_assessprep")}
            placement="top"
          >
            <QuestionCircleTwoTone />
          </Tooltip>
        </span>,
        widget: VideoInput,
        widgetConfig: {
          ...segmentConfig,
          question_type: 'video_input'
        },
        trigger: 'onSubmit',
        formItemConfig: { validateTrigger: validateTrigger },
        rules: [{
          validator(rule, value) {
            const validation = validateJSON('video_input', value)
            if (!validation.success) {
              setFormObjProps({
                ...formObjProps,
                validateTrigger: 'onChange'
              })
              return Promise.reject(validation.messages)
            } else {
              return Promise.resolve()
            }
          },
        }]
      },
    }
  }
}

const fileUploadForm = (props) => {
  const { formObjProps, setFormObjProps, mrIntl } = props
  const { validateTrigger } = formObjProps

  return {
    type: "object",
    properties: {
      teacher_json: {
        type: "string",
        title: mrIntl("CommonText.file_upload"),
        widget: FileUpload,
        widgetConfig: {
          ...segmentConfig,
          question_type: 'file_upload'
        },
        trigger: 'onSubmit',
        formItemConfig: { validateTrigger: validateTrigger },
        rules: [{
          validator(rule, value) {
            const validation = validateJSON('file_upload', value)
            if (!validation.success) {
              setFormObjProps({
                ...formObjProps,
                validateTrigger: 'onChange'
              })
              return Promise.reject(validation.messages)
            } else {
              return Promise.resolve()
            }
          },
        }]
      },
    }
  }
}

const miniQuizForm = (props) => {
  const { formObjProps, setFormObjProps, mrIntl } = props
  const { validateTrigger } = formObjProps

  return {
    type: "object",
    properties: {
      teacher_json: {
        type: "string",
        title: mrIntl("CommonText.mini_quiz"),
        widget: MiniQuiz,
        widgetConfig: {
          ...segmentConfig,
          question_type: 'mini_quiz'
        },
        trigger: 'onSubmit',
        formItemConfig: { validateTrigger: validateTrigger },
        rules: [{
          validator(rule, value) {
            const validation = validateJSON('mini_quiz', value)
            if (!validation.success) {
              setFormObjProps({
                ...formObjProps,
                validateTrigger: 'onChange'
              })
              return Promise.reject(validation.messages)
            } else {
              return Promise.resolve()
            }
          },
        }]
      },
    }
  }
}

const connectForm = (props) => {
  const { formObjProps, setFormObjProps, mrIntl } = props
  const { validateTrigger } = formObjProps

  return {
    type: "object",
    properties: {
      teacher_json: {
        type: "string",
        title: mrIntl("CommonText.connect"),
        widget: Connect,
        widgetConfig: {
          ...segmentConfig,
          question_type: 'connect'
        },
        trigger: 'onSubmit',
        formItemConfig: { validateTrigger: validateTrigger },
        rules: [{
          validator(rule, value) {
            const validation = validateJSON('connect', value)
            if (!validation.success) {
              setFormObjProps({
                ...formObjProps,
                validateTrigger: 'onChange'
              })
              return Promise.reject(validation.messages)
            } else {
              return Promise.resolve()
            }
          },
        }]
      },
    }
  }
}

const flashCardForm = (props) => {
  const { formObjProps, setFormObjProps, mrIntl } = props
  const { validateTrigger } = formObjProps

  return {
    type: "object",
    properties: {
      teacher_json: {
        type: "string",
        title: mrIntl("CommonText.flash_card_small_cards"),
        widget: FlashCard,
        widgetConfig: {
          ...segmentConfig,
          question_type: 'flash_card'
        },
        trigger: 'onSubmit',
        formItemConfig: { validateTrigger: validateTrigger },
        rules: [{
          validator(rule, value) {
            const validation = validateJSON('flash_card', value)
            if (!validation.success) {
              setFormObjProps({
                ...formObjProps,
                validateTrigger: 'onChange'
              })
              return Promise.reject(validation.messages)
            } else {
              return Promise.resolve()
            }
          },
        }]
      }
    }
  }
}

const highlightWordsForm = (props) => {
  const { formObjProps, setFormObjProps, mrIntl } = props;
  const { validateTrigger } = formObjProps;

  return {
    type: "object",
    properties: {
      teacher_json: {
        type: "string",
        title: mrIntl("CommonText.answer_choices"),
        widget: HighlightWords,
        widgetConfig: {
          ...segmentConfig,
          question_type: "highlight_words",
        },
        trigger: "onSubmit",
        formItemConfig: { validateTrigger: validateTrigger },
        rules: [
          {
            validator(rule, value) {
              console.log("validator is called with rule", rule, value);
              const validation = validateJSON("highlight_words", value);
              if (!validation.success) {
                setFormObjProps({
                  ...formObjProps,
                  validateTrigger: "onChange",
                });
                return Promise.reject(validation.messages);
              } else {
                return Promise.resolve();
              }
            },
          },
        ],
      },
    },
  };
};

const QuestionContentChildComponent = (props) => {
  console.log("QuestionContentChildComponent props", props)
  let { getFieldValue, sourceValues, formInstance } = props
  const questionType = getFieldValue(["question_segment_attributes", "question_type"]);
  let isPamojaBlock = sourceValues.custom_fields && sourceValues.custom_fields.block ? true : false
  const mrIntl = useTranslate()
  const [formObjProps, setFormObjProps] = useState({
    validateTrigger: 'onSubmit',
    required: !isPamojaBlock
  })

  let finalRender = null
  // we saved some content in content field for pamoja blocks - so showing that
  if (isPamojaBlock || (questionType != 'cke_fill_text' && questionType != 'cke_fill_dropdown' && questionType != 'connect')) {
    finalRender = <FormRenderer
      formObj={ckeSubjectiveForm({ formObjProps: formObjProps, setFormObjProps: setFormObjProps, getFieldValue, mrIntl: mrIntl })}
      propertyKey={"question_segment_attributes"}
      sourceValues={sourceValues}
      formInstance={formInstance}
    />
  }
  return finalRender
}

const TeacherJSONChildComponent = (props) => {
  let { getFieldValue, sourceValues, formInstance, setFieldsValue, resetFields } = props
  const questionType = getFieldValue(["question_segment_attributes", "question_type"]);
  console.log("questionType", questionType);
  console.log("props in depends upon ==>", props);
  const mrIntl = useTranslate();

  // const [questionTypeChangedStatus, setQuestionTypeChangedStatus] = useState(false)
  const [currentQuestionType, setCurrentQuestionType] = useState(questionType)
  useEffect(() => {
    // resetFields(["question_segment_attributes", "teacher_json"])
    if (currentQuestionType != questionType) {
      setFieldsValue({ question_segment_attributes: { teacher_json: null } })
      setCurrentQuestionType(questionType)
    }
    // setQuestionTypeChangedStatus(true)
    console.log("question type changed ===>", currentQuestionType, questionType, sourceValues)
  }, [questionType])

  // useEffect(() => {
  //   setTimeout(() => { setQuestionTypeChangedStatus(false)}, 5)
  // }, [questionTypeChangedStatus])
  const [formObjProps, setFormObjProps] = useState({
    validateTrigger: 'onSubmit',
  })

  if (currentQuestionType != questionType) {
    return null
  }

  let finalRender = null;
  if (questionType === "mcq_single" || (!questionType && sourceValues.id && sourceValues.question_type === "mcq_single")) {
    finalRender = <FormRenderer
      formObj={mcqSingleForm({ formObjProps: formObjProps, setFormObjProps: setFormObjProps, mrIntl: mrIntl })}
      propertyKey={"question_segment_attributes"}
      sourceValues={sourceValues}
      formInstance={formInstance}
    />
  }

  if (questionType === "mcq_multiple" || (!questionType && sourceValues.id && sourceValues.question_type === "mcq_multiple")) {
    finalRender = <FormRenderer
      formObj={mcqMultipleForm({ formObjProps: formObjProps, setFormObjProps: setFormObjProps, mrIntl: mrIntl })}
      propertyKey={"question_segment_attributes"}
      sourceValues={sourceValues}
      formInstance={formInstance}
    />
  }

  if (questionType === "true_false" || (!questionType && sourceValues.id && sourceValues.question_type === "true_false")) {
    finalRender = <FormRenderer
      formObj={trueFalseForm({ formObjProps: formObjProps, setFormObjProps: setFormObjProps, mrIntl: mrIntl  })}
      propertyKey={"question_segment_attributes"}
      sourceValues={sourceValues}
      formInstance={formInstance}
    />
  }

  if (questionType === "fabric_drawing" || (!questionType && sourceValues.id && sourceValues.question_type === "fabric_drawing")) {
    finalRender = <FormRenderer
      formObj={fabricDrawingForm({ formObjProps: formObjProps, setFormObjProps: setFormObjProps, mrIntl: mrIntl})}
      propertyKey={"question_segment_attributes"}
      sourceValues={sourceValues}
      formInstance={formInstance}
    />
  }

  if (questionType === "fabric_dnd_label" || (!questionType && sourceValues.id && sourceValues.question_type === "fabric_dnd_label")) {
    finalRender = <FormRenderer
      formObj={fabricDNDLabelForm({ formObjProps: formObjProps, setFormObjProps: setFormObjProps, mrIntl: mrIntl })}
      propertyKey={"question_segment_attributes"}
      sourceValues={sourceValues}
      formInstance={formInstance}
    />
  }

  if (questionType === "fabric_fill_label" || (!questionType && sourceValues.id && sourceValues.question_type === "fabric_fill_label")) {
    finalRender = <FormRenderer
      formObj={fabricFillLabelForm({ formObjProps: formObjProps, setFormObjProps: setFormObjProps, mrIntl: mrIntl})}
      propertyKey={"question_segment_attributes"}
      sourceValues={sourceValues}
      formInstance={formInstance}
    />
  }

  if (questionType === "fabric_hotspot" || (!questionType && sourceValues.id && sourceValues.question_type === "fabric_hotspot")) {
    finalRender = <FormRenderer
      formObj={fabricHotspotForm({ formObjProps: formObjProps, setFormObjProps: setFormObjProps, mrIntl: mrIntl })}
      propertyKey={"question_segment_attributes"}
      sourceValues={sourceValues}
      formInstance={formInstance}
    />
  }

  if (questionType === "ggb_graph" || (!questionType && sourceValues.id && sourceValues.question_type === "ggb_graph")) {
    finalRender = <FormRenderer
      formObj={ggbGraphForm({ formObjProps: formObjProps, setFormObjProps: setFormObjProps, mrIntl: mrIntl})}
      propertyKey={"question_segment_attributes"}
      sourceValues={sourceValues}
      formInstance={formInstance}
    />
  }

  if (questionType === "desmos_graph" || (!questionType && sourceValues.id && sourceValues.question_type === "desmos_graph")) {
    finalRender = <FormRenderer
      formObj={desmosGraphForm({ formObjProps: formObjProps, setFormObjProps: setFormObjProps, mrIntl: mrIntl })}
      propertyKey={"question_segment_attributes"}
      sourceValues={sourceValues}
      formInstance={formInstance}
    />
  }

  if (questionType === "rbd_inline_matching" || (!questionType && sourceValues.id && sourceValues.question_type === "rbd_inline_matching")) {
    finalRender = <FormRenderer
      formObj={rbdInlineMatchingForm({ formObjProps: formObjProps, setFormObjProps: setFormObjProps, mrIntl: mrIntl })}
      propertyKey={"question_segment_attributes"}
      sourceValues={sourceValues}
      formInstance={formInstance}
    />
  }

  if (questionType === "rbd_matching" || (!questionType && sourceValues.id && sourceValues.question_type === "rbd_matching")) {
    finalRender = <FormRenderer
      formObj={rbdMatchingForm({ formObjProps: formObjProps, setFormObjProps: setFormObjProps, mrIntl: mrIntl })}
      propertyKey={"question_segment_attributes"}
      sourceValues={sourceValues}
      formInstance={formInstance}
    />
  }

  if (questionType === "rbd_inline_sorting" || (!questionType && sourceValues.id && sourceValues.question_type === "rbd_inline_sorting")) {
    finalRender = <FormRenderer
      formObj={rbdInlineSortingForm({ formObjProps: formObjProps, setFormObjProps: setFormObjProps, mrIntl: mrIntl  })}
      propertyKey={"question_segment_attributes"}
      sourceValues={sourceValues}
      formInstance={formInstance}
    />
  }

  if (questionType === "rbd_sorting" || (!questionType && sourceValues.id && sourceValues.question_type === "rbd_sorting")) {
    finalRender = <FormRenderer
      formObj={rbdSortingForm({ formObjProps: formObjProps, setFormObjProps: setFormObjProps, mrIntl: mrIntl })}
      propertyKey={"question_segment_attributes"}
      sourceValues={sourceValues}
      formInstance={formInstance}
    />
  }

  if (questionType === "rbd_inline_classify" || (!questionType && sourceValues.id && sourceValues.question_type === "rbd_inline_classify")) {
    finalRender = <FormRenderer
      formObj={rbdInlineClassifyForm({ formObjProps: formObjProps, setFormObjProps: setFormObjProps, mrIntl: mrIntl})}
      propertyKey={"question_segment_attributes"}
      sourceValues={sourceValues}
      formInstance={formInstance}
    />
  }

  if (questionType === "rbd_classify" || (!questionType && sourceValues.id && sourceValues.question_type === "rbd_classify")) {
    finalRender = <FormRenderer
      formObj={rbdClassifyForm({ formObjProps: formObjProps, setFormObjProps: setFormObjProps, mrIntl: mrIntl})}
      propertyKey={"question_segment_attributes"}
      sourceValues={sourceValues}
      formInstance={formInstance}
    />
  }

  if (questionType === "rds_table" || (!questionType && sourceValues.id && sourceValues.question_type === "rds_table")) {
    finalRender = <FormRenderer
      formObj={rdsTableForm({ formObjProps: formObjProps, setFormObjProps: setFormObjProps, mrIntl: mrIntl })}
      propertyKey={"question_segment_attributes"}
      sourceValues={sourceValues}
      formInstance={formInstance}
    />
  }

  if (questionType === "cke_fill_text" || (!questionType && sourceValues.id && sourceValues.question_type === "cke_fill_text")) {
    finalRender = <FormRenderer
      formObj={ckeFillTextForm({ formObjProps: formObjProps, setFormObjProps: setFormObjProps, mrIntl: mrIntl })}
      propertyKey={"question_segment_attributes"}
      sourceValues={sourceValues}
      formInstance={formInstance}
    />
  }

  if (questionType === "cke_fill_dropdown" || (!questionType && sourceValues.id && sourceValues.question_type === "cke_fill_dropdown")) {
    finalRender = <FormRenderer
      formObj={ckeFillDropDownForm({ formObjProps: formObjProps, setFormObjProps: setFormObjProps, mrIntl: mrIntl })}
      propertyKey={"question_segment_attributes"}
      sourceValues={sourceValues}
      formInstance={formInstance}
    />
  }

  if (questionType === "audio_input" || (!questionType && sourceValues.id && sourceValues.question_type === "audio_input")) {
    finalRender = <FormRenderer
      formObj={audioInputForm({ formObjProps: formObjProps, setFormObjProps: setFormObjProps, mrIntl: mrIntl})}
      propertyKey={"question_segment_attributes"}
      sourceValues={sourceValues}
      formInstance={formInstance}
    />
  }
  if (questionType === "video_input" || (!questionType && sourceValues.id && sourceValues.question_type === "video_input")) {
    finalRender = <FormRenderer
      formObj={videoInputForm({ formObjProps: formObjProps, setFormObjProps: setFormObjProps, mrIntl: mrIntl })}
      propertyKey={"question_segment_attributes"}
      sourceValues={sourceValues}
      formInstance={formInstance}
    />
  }
  
  if (questionType === "file_upload" || (!questionType && sourceValues.id && sourceValues.question_type === "file_upload")) {
    finalRender = <FormRenderer
      formObj={fileUploadForm({ formObjProps: formObjProps, setFormObjProps: setFormObjProps, mrIntl: mrIntl })}
      propertyKey={"question_segment_attributes"}
      sourceValues={sourceValues}
      formInstance={formInstance}
    />
  }

  if (questionType === "mini_quiz" || (!questionType && sourceValues.id && sourceValues.question_type === "mini_quiz")) {
    finalRender = <FormRenderer
      formObj={miniQuizForm({ formObjProps: formObjProps, setFormObjProps: setFormObjProps, mrIntl: mrIntl  })}
      propertyKey={"question_segment_attributes"}
      sourceValues={sourceValues}
      formInstance={formInstance}
    />
  }

  if (questionType === "connect" || (!questionType && sourceValues.id && sourceValues.question_type === "connect")) {
    finalRender = <FormRenderer
      formObj={connectForm({ formObjProps: formObjProps, setFormObjProps: setFormObjProps, mrIntl: mrIntl })}
      propertyKey={"question_segment_attributes"}
      sourceValues={sourceValues}
      formInstance={formInstance}
    />
  }

  if (questionType === "flash_card" || (!questionType && sourceValues.id && sourceValues.question_type === "flash_card")) {
    finalRender = <FormRenderer
      formObj={flashCardForm({ formObjProps: formObjProps, setFormObjProps: setFormObjProps, mrIntl: mrIntl })}
      propertyKey={"question_segment_attributes"}
      sourceValues={sourceValues}
      formInstance={formInstance}
    />
  }

  if (questionType === "number_line" || (!questionType && sourceValues.id && sourceValues.question_type === "number_line")) {
    finalRender = <FormRenderer
      formObj={numberLineForm({ formObjProps: formObjProps, setFormObjProps: setFormObjProps, mrIntl: mrIntl })}
      propertyKey={"question_segment_attributes"}
      sourceValues={sourceValues}
      formInstance={formInstance}
    />
  }
  if (
    questionType === "highlight_words" ||
    (!questionType &&
      sourceValues.id &&
      sourceValues.question_type === "highlight_words")
  ) {
    finalRender = (
      <FormRenderer
        formObj={highlightWordsForm({
          formObjProps: formObjProps,
          setFormObjProps: setFormObjProps,
          mrIntl: mrIntl,
        })}
        propertyKey={"question_segment_attributes"}
        sourceValues={sourceValues}
        formInstance={formInstance}
      />
    );
  }

  return finalRender;
}

const AnswerExplanationChildComponent = (props) => {
  console.log("AnswerExplanationChildComponent props ==>", props)
  let { getFieldValue, sourceValues, formInstance } = props
  const includeExplanation = getFieldValue(["settings", "include_explanation"])
  const mrIntl = useTranslate()
  const [formObjProps, setFormObjProps] = useState({
    validateTrigger: 'onSubmit',
  })

  let finalRender = []
  if (includeExplanation) {
    finalRender.push(<FormRenderer
      formObj={ckeAnswerExplanationForm({ formObjProps: formObjProps, setFormObjProps: setFormObjProps, getFieldValue, mrIntl: mrIntl })}
      propertyKey={"question_segment_attributes"}
      sourceValues={sourceValues}
      formInstance={formInstance}
    />)
  }
  return finalRender
}

const MarkschemeChildComponent = (props) => {
  console.log("MarkschemeChildComponent props ==>", props)
  let { getFieldValue, sourceValues, formInstance } = props
  const includeExplanation = getFieldValue(["settings", "include_markscheme"])
  const mrIntl = useTranslate()
  const [formObjProps, setFormObjProps] = useState({
    validateTrigger: 'onSubmit',
  })

  let finalRender = []
  if (includeExplanation) {
    finalRender.push(<FormRenderer
      formObj={markschemeForm({ formObjProps: formObjProps, setFormObjProps: setFormObjProps, getFieldValue, mrIntl: mrIntl })}
      propertyKey={"question_segment_attributes"}
      sourceValues={sourceValues}
      formInstance={formInstance}
    />)
  }
  return finalRender
}

const getGameType = (props) => {
  const { sourceValues, mrIntl } = props
  return {
    type: "object",
    properties: {
      game_type: {
        // title: "Select Game",
        type: "string",
        placeholder: mrIntl("QuestionForm.game_type_placeholder"),
        widget: "SelectWidget",
        widgetConfig: {
          options: [
            { label: mrIntl("QuestionForm.cryptic_hunt_label"), value: "cryptic_hunt" },
            { label: mrIntl("QuestionForm.fast_n_curious"), value: "fast_and_curious" },
          ],
        },
      },
    },
  };
};

const GameTypeChildComponent = ({
  getFieldValue,
  sourceValues,
  formInstance,
}) => {
  const mrIntl = useTranslate()
  const gameType = getFieldValue(["question_segment_attributes", "question_type"]);
  console.log('inside game type==>', gameType);
  let finalRender = null;
  if (gameType === "group" && ["staging", "development"].includes(import.meta.env.VITE_MODE)) {
    finalRender = (
      <FormRenderer
        formObj={getGameType({sourceValues: sourceValues, mrIntl: mrIntl})}
        propertyKey={"question_segment_attributes"}
        sourceValues={sourceValues}
        formInstance={formInstance}
      />
    );
  }
  return finalRender;
};

const VideoAnswerExplantionChildComponent = (props) => {
  let { getFieldValue, sourceValues, formInstance } = props
  const includeExplanation = getFieldValue(["settings", "include_explanation"])
  console.log("Include explantion ==>", includeExplanation)
  const mrIntl = useTranslate()
  const [formObjProps, setFormObjProps] = useState({
    validateTrigger: 'onSubmit',
  })

  let finalRender = null
  if (includeExplanation) {
    finalRender = <FormRenderer
      formObj={videoAnswerExplanationForm({ formObjProps: formObjProps, setFormObjProps: setFormObjProps, mrIntl: mrIntl })}
      propertyKey={"question_segment_attributes", "attachments_json"}
      sourceValues={sourceValues}
      formInstance={formInstance}
    />
  }
  return finalRender
}

const PDFAnswerExplantionChildComponent = (props) => {
  let { getFieldValue, sourceValues, formInstance } = props
  const includeExplanation = getFieldValue(["settings", "include_explanation"])
  console.log("Include explantion ==>", includeExplanation)
  const mrIntl = useTranslate()
  const [formObjProps, setFormObjProps] = useState({
    validateTrigger: 'onSubmit',
  })

  let finalRender = null
  if (includeExplanation) {
    finalRender = <FormRenderer
      formObj={pdfAnswerExplanationForm({ formObjProps: formObjProps, setFormObjProps: setFormObjProps, mrIntl: mrIntl })}
      propertyKey={"question_segment_attributes"}
      sourceValues={sourceValues}
      formInstance={formInstance}
    />
  }
  return finalRender
}

// const attachmentsJSONForm = (props) => {
//   const { formObjProps, setFormObjProps } = props
//   const { validateTrigger } = formObjProps
//   return {
//     type: 'object', 
//     properties: {
//       attachments_json: {
//         type: 'object',
//         properties: {
//           video_answer_explanation_parent: {
//             type: "string",
//             dependsOn: ["settings.include_explanation"],
//             children: VideoAnswerExplantionChildComponent,
//           },
//           pdf_answer_explanation_parent: {
//             type: "string",
//             dependsOn: ["settings.include_explanation"],
//             children: PDFAnswerExplantionChildComponent,
//           }
//         }
//       },
//     }
//   }
// }

const attachmentsJSONForm = (props) => {
  const { formObjProps, setFormObjProps, mrIntl } = props
  const { validateTrigger } = formObjProps
  return {
    type: 'object', 
    properties: {
      attachments_json: {
        type: 'object',
        properties: {
          video_answer_explanation: {
            type: "string",
            title:  mrIntl("QuestionForm.video_answer_explanation"),
            // title: "Video answer explanation",
            widget: VideoAnswerExplanation,
            widgetConfig: {
              ...segmentConfig,
            },
            trigger: 'onSubmit',
            formItemConfig: { validateTrigger: validateTrigger },
          },
          pdf_answer_explanation: {
            type: "string",
            title:  mrIntl("QuestionForm.pdf_answer_explanation"),
            // title: "PDF answer explanation",
            widget: PDFAnswerExplanation,
            widgetConfig: {
              ...segmentConfig,
            },
            trigger: 'onSubmit',
            formItemConfig: { validateTrigger: validateTrigger },
          },
        }
      }
    }
  }
}

const AttachmentsJSONChildComponent = (props) => {
  let { getFieldValue, sourceValues, formInstance } = props
  const includeExplanation = getFieldValue(["settings", "include_explanation"])
  console.log("AttachmentsJSONChildComponent Include explantion ==>", includeExplanation)
  const mrIntl = useTranslate()
  const [formObjProps, setFormObjProps] = useState({
    validateTrigger: 'onSubmit',
  })

  let finalRender = null
  if (includeExplanation) {
    finalRender = <FormRenderer
      formObj={attachmentsJSONForm({ formObjProps: formObjProps, setFormObjProps: setFormObjProps, mrIntl: mrIntl })}
      propertyKey={("question_segment_attributes")}
      sourceValues={sourceValues}
      formInstance={formInstance}
    />
  }
  return finalRender
}

const questionForm = (enabledFeatures = {}, currentUser = {}, mrIntl, nestingLevel = 1, activeExperience) => {

  const isDevOrStagingMode = ["development", "staging"].includes(import.meta.env.VITE_MODE);
  const questionTypes = [
    { label: mrIntl("CommonText.long_answer"), value: "cke_subjective" },
    { label: mrIntl("CommonText.mcq_single_label"), value: "mcq_single" },
    { label: mrIntl("CommonText.mcq_multiple"), value: "mcq_multiple" },
    { label: mrIntl("CommonText.true_false"), value: "true_false" },
    { label: mrIntl("CommonText.fill_text"), value: "cke_fill_text" },
    { label: mrIntl("CommonText.fill_dropdown"), value: "cke_fill_dropdown" },
    { label: mrIntl("CommonText.match"), value: "rbd_inline_matching" },
    { label: mrIntl("CommonText.sort"), value: "rbd_inline_sorting" },
    { label: mrIntl("CommonText.classify"), value: "rbd_inline_classify" },
    { label: mrIntl("CommonText.table"), value: "rds_table" },
    { label: mrIntl("CommonText.drawing"), value: "fabric_drawing" },
    { label: mrIntl("CommonText.label_drag"), value: "fabric_dnd_label" },
    { label: mrIntl("CommonText.label_fill"), value: "fabric_fill_label" },
    { label: mrIntl("CommonText.hotspot"), value: "fabric_hotspot" },
    { label: mrIntl("CommonText.geogebra_graph"), value: "ggb_graph" },
    { label: mrIntl("CommonText.desmos_graph"), value: "desmos_graph" },
    { label: mrIntl("CommonText.number_line"), value: "number_line" },
    // { label: "Mini quiz", value: "mini_quiz"},
    // { label: "Flash Card", value: "flash_card"},
  ]

  const mcqQuestionType = [
    { label: mrIntl("CommonText.mcq_single_label"), value: "mcq_single" }
  ]

  if (enabledFeatures.question_type_connect) {
    questionTypes.push({ label: mrIntl("CommonText.connect"), value: "connect" });
  }

  const relationItems = activeExperience?.relation_items || [];
  const isGroupQuestionTypeEnabled = [5, 27, 30, 31].includes(relationItems[0]?.programme_id);

  if (isGroupQuestionTypeEnabled && nestingLevel <= 2) {
    questionTypes.push({ label: mrIntl("CommonText.group"), value: "group" });
  }
  
  if (enabledFeatures.question_type_audio_input) {
    questionTypes.push({ label: mrIntl("QuestionForm.audio_input"), value: "audio_input"})
  }
  if (enabledFeatures.question_type_video_input) {
    questionTypes.push( { label: mrIntl("CommonText.video"), value: "video_input"})
  }
  if (enabledFeatures.question_type_file_upload) {
    questionTypes.push( { label: mrIntl("CommonText.file_upload"), value: "file_upload"})
  }
  if (enabledFeatures.question_type_highlight_words) {
    questionTypes.push({ label: mrIntl("CommonText.highlight_words"), value: "highlight_words" })
  }

  const showOnlyMcqQuestion = enabledFeatures.show_only_mcq_question;
  
  let properties = {
    question_type: {
      type: "string",
      placeholder: mrIntl("QuestionForm.question_type_placeholder"),
      span: 8,
      widget: "SelectWidget",
      wrapClassName: "wrap-question-form-question-type-dropdown",
      className: "form-item-question-form-question-type-dropdown",
      widgetConfig: {
        className: "widget-question-form-question-type-dropdown",
        options: showOnlyMcqQuestion ? mcqQuestionType : questionTypes,      
      },
    },
    // using game_type instead of is_game
    game_type: {
      type: "string",
      // placeholder: "Game type!!",
      span: 8,
      // widget: "SelectWidget",
      wrapClassName: "wrap-question-form-game-type-dropdown",
      className: "form-item-question-form-game-type-dropdown",
      dependsOn: ["question_segment_attributes.question_type"],
      children: GameTypeChildComponent,
      // widgetConfig: {
      //   className: "widget-question-form-game-type-dropdown",
      //   options: gameTypes
      // },
    },
    // points: {
    //   type: "string",
    //   placeholder: "Points",
    //   span: 6,
    //   rules: [
    //     {
    //       required: true,
    //       message: 'Required',
    //     },
    //   ]
    //   // // setInitialValue: true,
    // },    
    content: {
      type: "string",
      title: mrIntl("QuestionForm.question_content"),
      span: 24,
      // // setInitialValue: true,
      dependsOn: ["question_segment_attributes.question_type"],
      children: QuestionContentChildComponent
    },
    teacher_json_parent: {
      type: "string",
      title: mrIntl("QuestionForm.teacher_json_title"),
      dependsOn: ["question_segment_attributes.question_type"],
      children: TeacherJSONChildComponent
    },
    answer_explanation: {
      type: "string",
      title: mrIntl("CommonText.explanation_title"),
      // // setInitialValue: true,
      dependsOn: ["settings.include_explanation"],
      children: AnswerExplanationChildComponent
    },
  }

  if(currentUser.role === "qb_author" || import.meta.env.VITE_MODE == "development"){
    //NOTE: Use custom properties name for easy position changing in forms - In preProcess and postProcessFormData remap values to question attachments_json
    // properties.attachments_json_parent = {
    //   type: 'object', 
    //   properties: {
    //     attachments_json: {
    //       type: 'object',
    //       properties: {
    //         video_answer_explanation_parent: {
    //           type: "string",
    //           dependsOn: ["settings.include_explanation"],
    //           children: VideoAnswerExplantionChildComponent,
    //         },
    //         pdf_answer_explanation_parent: {
    //           type: "string",
    //           dependsOn: ["settings.include_explanation"],
    //           children: PDFAnswerExplantionChildComponent,
    //         }
    //       }
    //     }
    //   }
    // }

    properties.attachments_json = {
      type: "string",
      dependsOn: ["settings", "include_explanation"],
      children: AttachmentsJSONChildComponent,
    }
  }

  properties.markscheme = {
    type: "string",
    title: mrIntl("QuestionForm.markscheme_title"),
    // // setInitialValue: true,
    dependsOn: ["settings.include_markscheme"],
    children: MarkschemeChildComponent
  }

  console.log("properties in question form ==>", properties)
  return {
    type: "object",
    properties: properties
  };
} 


export default questionForm;