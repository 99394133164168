import { Skeleton, Space } from "antd";
import { displayPoints, isStringEmpty } from "/src/lib/utils/helperMethods";
import React, {memo} from "react";
import { useSelector } from "react-redux";
import { generateClassName } from "/src/views/Experiences/ExperienceHelperMethods";
import { activeAdjustedExperienceIdSelector } from "/src/views/Experiences/selector";
import { dbUserResponsesSelector } from "/src/views/UserResponses/selector";
import { MessageOutlined, FileOutlined, MinusCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { BiCommentDetail, BiComment } from "react-icons/bi";
import { enabledFeaturesSelector } from "/src/views/Auth/Login/selector";

const NonMemoizedUserResponseCellDetail = (props) => {
  const { experience_user_id, user_id, segment, rubric, sortedColumn } = props
  console.log("NonMemoizedUserResponseCellDetail", props);
  const activeAdjustedExperienceId = useSelector(
    activeAdjustedExperienceIdSelector()
  );

  let dbUserResponse = useSelector(dbUserResponsesSelector(activeAdjustedExperienceId, user_id, segment.id, false, true, experience_user_id))
  const rubricType = rubric && rubric.type_c
  const enabledFeatures = useSelector(enabledFeaturesSelector())

  if (!dbUserResponse || (sortedColumn.segmentId === segment.id && sortedColumn.loading)) {
    return <Skeleton.Input className="skeleton-loading-scroll" active />
  }
  if (dbUserResponse) {
    let finalRender = []
    let innerRender = []
    let urPts = displayPoints(dbUserResponse.points, false, true);
    
    if(rubricType === "comments_only"){
      (!isStringEmpty(dbUserResponse.comments) || (dbUserResponse.custom_fields.media_comments && dbUserResponse.custom_fields.media_comments.attachments && dbUserResponse.custom_fields.media_comments.attachments.length > 0)) ? innerRender.push(<BiCommentDetail />) : innerRender.push("-")
    } else if(rubricType === "points"){
      dbUserResponse.marked ? innerRender.push(urPts) : innerRender.push("-")
    } else {
      if((rubricType !== "myp_achievement_level") || (rubricType === "myp_achievement_level" && !enabledFeatures.disable_myp_al_per_question_grading)) {
        dbUserResponse.submission_criterium_associations_attributes.map((urca) => {
          let urDisplayPts = displayPoints(urca.points, false, true);
          let cTitle = <sup>{urca.custom_fields.title}</sup>;
          let isPointsNull = urca.points == null || urca.points == undefined
          innerRender.push(<span>{cTitle}{isPointsNull ? "-" : urDisplayPts}&nbsp;</span>)
        })
      }
    }

    finalRender.push(
      <Space
        key={`user-response-${dbUserResponse.id}`}
        // style={{ height: "100%", width: "100%" }}
        className={`exp-users-list-points-wrapper question-cell-color-${generateClassName(dbUserResponse, rubric.type_c)}`}
        // id={`selected-cell-${experience_user_id}-${segment.id}`}
        size={0}
      >
        {dbUserResponse && !dbUserResponse.attempted && <div className={"ur-cell-unattempted-flag"}>
          {/* <MinusCircleFilled /> */}
          <CloseCircleFilled />
        </div>}

        <span className={`ur-cell-points-span ${dbUserResponse && !dbUserResponse.attempted && "unattempted1"}`}>{innerRender}</span>

        {(!isStringEmpty(dbUserResponse.comments) || (dbUserResponse.custom_fields.media_comments && dbUserResponse.custom_fields.media_comments.attachments && dbUserResponse.custom_fields.media_comments.attachments.length > 0)) && rubricType !== "comments_only" && <div className={"ur-cell-icons ur-cell-icon-comment"}>
          <BiCommentDetail />
        </div>}
        {dbUserResponse.attachments_json.attachments && dbUserResponse.attachments_json.attachments.length > 0 && <div className={"ur-cell-icons ur-cell-icon-file"}>
          <FileOutlined />
        </div>}
      </Space>
    );
    return finalRender
  }
}

const UserResponseCellDetail = memo(NonMemoizedUserResponseCellDetail)
export default UserResponseCellDetail;
