import { fromJS, Map } from "immutable";
import { MrReduxCrud } from "mr_react_framework";
// import firebase from "firebase";
import { message } from "/src/components/UI/AntdAppHelper";
import { put, call, select } from "redux-saga/effects";
import * as Sentry from "@sentry/react";
import { axiosInstance } from "/src/api/apiModule";
import { realtimeBatchActions } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/firebaseHelper";
import { actions as experienceReduxActions } from "/src/views/Experiences/redux";
import { firebaseAuth, firestoreDB, realtimeDB } from "/src/config/initializers";
import supabaseDB from "/src/config/supabaseInitializer";
import {
  currentUserSelector,
  appRegionSelector,
  enabledFeaturesSelector,
  getEmbeddedSelector,
  partnerNameSelector,
  getUserIpSelector,
} from "/src/views/Auth/Login/selector";
import {
  activeExperienceIdSelector,
  userResponsesSelector,
  questionsStatusSelector,
  activeSegmentInfoSelector,
  totalAttachmentsSelector,
  userResponseConfigSelector,
  userResponseSelector,
  currentViewSelector,
  firestoreConnectionClosedSelector,
  currentSessionIdSelector,
} from "./selector";
import {
  activeAdjustedExperienceIdSelector,
  activeAdjustedExperienceSelector,
  experienceConfigSelector,
  experienceViewModeSelector,
  getInternetStatusSelector,
  getOfflineDBStatusSelector,
  switchFromStartToResumeViewSelector,
} from "/src/views/Experiences/selector";
import {
  getSessionId,
  checkIPAD,
  checkMob,
  getUniqueId,
  checkPageReload,
} from "/src/lib/utils/helperMethods";
import { isEmpty } from "lodash";
import { appTypeSelector } from "/src/App/OfflineApp/offlineAppSelectors";
import {
  saveItemToOfflineDB,
  getItemsFromOfflineDB,
  onlinePriorityKeys,
} from "/src/lib/OfflineDatabase/OfflineDatabase";
import { parse } from "query-string";
import { useLocation } from "react-router-dom";
import loadable from "@loadable/component";
import { buildLogObject } from "/src/lib/utils/auditLogsHelper";
import dayjs from "dayjs";
import { getAppNativeVars, getDeviceInfo } from "/src/App/OfflineApp/offlineAppHelper";
import { getExperienceDurationForStudent } from "../ExperienceManage/ExperienceMonitor/StudentsList";
import { getCurrentExtraTime } from "../Components/AddTimeBtn";
import { signInWithCustomToken } from "firebase/auth";
import { collection, doc, getDoc, getDocs, query, setDoc, where, writeBatch } from "firebase/firestore";

const socketIO = loadable.lib(() =>
  import(/* webpackChunkName: "fabric" */ "socket.io-client")
);

function rtdb_and_fs_presence(docPath) {
  var uid = firebaseAuth.currentUser.uid;
  var userStatusDatabaseRef = realtimeDB.ref("/status/" + uid);

  var isOfflineForDatabase = {
    state: "offline",
    last_changed: Date.now(),
    docPath: docPath,
  };

  var isOnlineForDatabase = {
    state: "online",
    last_changed: Date.now(),
    docPath: docPath,
  };

  userStatusDatabaseRef.on("value", (snap) => {
    let currentVal = snap.val() !== null ? snap.val() : { state: "offline" };
    if (currentVal.state === "offline") {
      userStatusDatabaseRef.set(isOnlineForDatabase);
    }
  });

  realtimeDB.ref(".info/connected").on("value", function (snapshot) {
    console.log("snapshot", snapshot);
    userStatusDatabaseRef
      .onDisconnect()
      .set(isOfflineForDatabase)
      .then(function () {
        userStatusDatabaseRef.set(isOnlineForDatabase);
      });
  });
}

const reduxCrud = new MrReduxCrud({
  axiosInstance,
  resourceName: "firebase",
  // actionNames: ["GET_USER_INFO", "GET_USER_RESPONSES", "GET_FIREBASE_TOKEN", "UPDATE_DOC", "SET_DOC"]
  actionNames: [
    "GET_FIREBASE_TOKEN",
    "SUBMIT_EXPERIENCE",
    "SET_CURRENT_VIEW",
    "SET_USER_RESPONSE",
    "SET_USER_INFO",
    "SET_LOGS",
    "SET_EXPERIENCE_INFO",
    "SET_CURRENT_SESSION_ID",
    "SET_ACTIVE_EXPERIENCE_ID",
    "GET_USER_RESPONSES",
    "UPDATE_ONLINE_STATUS",
    "TEACHER_SUBMIT_EXPERIENCE",
    "BATCH_SET_USER_RESPONSES",
    "SET_ACTIVE_CHAT",
    "REMOVE_ACTIVE_CHAT",
    "SAVING_STATUS",
    "SUBMIT_USER_RESPONSE",
    "SET_USER_RESPONSE_CONFIG", //- should do generic instead of below
    // "SET_CONFIG_SAVE_TO_AP_DB"
    "GET_EXPERIENCE_INFO",
    "GET_ORG_AI_USAGE_INFO",
    "SUPPORT_OLD_USER_RESPONSE_ID",
    "SET_PRESENCE_DATA",
    "SIGN_IN_TO_FIRESTORE",
    "SET_FIRESTORE_CONNECTION_CLOSED",
    "SET_SESSION_SWITCHED",
    "SYNC_OFFLINE_RESPONSES"
  ],
});

const initialState = fromJS({
  // responses: Map({}),
  responses: {},
  activeChats: [],
  user_info: {},
  presence_data: {},
  experience_info: Map({}),
  ai_usage_info: {},
  activeExperienceId: null,
  firebaseToken: null,
  isFirebaseAuthenticated: false,
  getFirebaseTokenLoading: true,
  // getFirebaseTokenLoading: false,
  page: 1,
  serverTime: null,
  onlineStatus: null,
  total_pages: 1,
  total_count: 0,
  page_size: 0,
  // action: {},
  error: null,
  loading: false,
});

function* getExperienceInfo(action) {
  console.log("actions in experienceinfo==>", action, action.experienceId);
  try {
    const appRegion = yield select(appRegionSelector());
    let experienceInfo = {};
    let firestoreExperienceInfo = {};

    // When calling from getFirebaseToken, experienceId is in action.experienceId, wasn't working
    let experienceId = (action.payload && action.payload.experienceId) || (action.experienceId)
    yield put(actions.setExperienceInfoStart());
    if (appRegion === "china") {
      const { data, error } = yield supabaseDB().from('experiences').select().eq('id', experienceId)
      if(error){
        console.log("Error in getting user info from parse: ", error);
      }else{
        firestoreExperienceInfo = data[0] || {}
        console.log("supabaseDB firestoreExperienceInfo", firestoreExperienceInfo);
      }
    } else {
      let firestoreDBData = yield getDoc(doc(firestoreDB, "experiences", experienceId.toString()));
      firestoreExperienceInfo = firestoreDBData.exists() ? firestoreDBData.data() : {};
    }
    console.log(
      "value coming from firestore/china==>",
      firestoreExperienceInfo
    );
    experienceInfo = {
      ...firestoreExperienceInfo,
    };
    yield put(actions.setExperienceInfoSuccess(experienceInfo));

    if (action.options && action.options.successCallback) {
      action.options.successCallback(experienceInfo);
    }
    return experienceInfo;
  } catch (error) {
    yield put(actions.setExperienceInfoFail({ error }));
    if (action.options && action.options.errorCallback) {
      // message.error("Something went wrong. Please try again.");
      action.options.errorCallback();
    }
  }
}

export function* getOrgAiUsageInfo(action) {
  console.log("getOrgAiUsageInfo ====>", action);
  try {
    const appRegion = yield select(appRegionSelector());
    let aiUsageInfo = {};
    // When calling from getFirebaseToken, experienceId is in action.experienceId, wasn't working
    // let experienceId = (action.payload && action.payload.experienceId) || (action.experienceId)
    let orgId = (action.payload && action.payload.orgId) || (action.orgId)
    yield put(actions.getOrgAiUsageInfoStart());
    if (appRegion === "china") {
      // const { data, error } = yield supabaseDB().from('experiences').select().eq('id', experienceId)
      // if(error){
      //   console.log("Error in getting user info from parse: ", error);
      // }else{
      //   firestoreExperienceInfo = data[0] || {}
      //   console.log("supabaseDB firestoreExperienceInfo", firestoreExperienceInfo);
      // }
    } else {
      const docRef = doc(firestoreDB, `orgs/${orgId}/products`, "ai");
      const firestoreDBData = yield getDoc(docRef);
      aiUsageInfo = firestoreDBData.exists() ? firestoreDBData.data() : {};
    }
    yield put(actions.getOrgAiUsageInfoSuccess(aiUsageInfo));
    if (action.options && action.options.successCallback) {
      action.options.successCallback(aiUsageInfo);
    }
    console.log("aiUsageInfo ======>", aiUsageInfo);
    return aiUsageInfo;
  } catch (error) {
    yield put(actions.getOrgAiUsageInfoFail({ error }));
    if (action.options && action.options.errorCallback) {
      // message.error("Something went wrong. Please try again.");
      action.options.errorCallback();
    }
  }
}


function* setExperienceInfoSaga(action) {
  console.log("setexperinceinfo props==>", action);
  const { experienceId, experienceInfo } = action.payload;
  const appRegion = yield select(appRegionSelector());

  if (appRegion === "china") {
    let experienceInfoToUpdate = {
      ...experienceInfo,
      id: experienceId,
      uuid: `eId${experienceId}`
    }
    console.log("supabaseDB writing to experiences ==>", experienceInfoToUpdate)
    const { data, error } = yield supabaseDB().from('experiences').upsert(experienceInfoToUpdate, { onConflict: 'uuid' })

  } else {
    // const batch = firestoreDB.batch();
    const batch = writeBatch(firestoreDB);
    const experienceInfoRef = doc(firestoreDB, `experiences`, experienceId.toString());
    setDoc(experienceInfoRef, experienceInfo, { merge: true });
    yield batch.commit();
  }
}

function* getUserInfoFromBackup(options) {
  // Put this in try catch
  const { experienceId, userId } = options;
  const appRegion = yield select(appRegionSelector());
  const enabledFeatures = yield select(enabledFeaturesSelector());
  const embedded = yield select(getEmbeddedSelector());
  const activeExperience = yield select(activeAdjustedExperienceSelector());

  const experienceSettings = activeExperience.settings || {};
  

  let userInfoFB = {},
    userInfoFromOnlineDB = {},
    userInfoFromOfflineDB = {};

  console.log("supabaseDB experienceId, userId", experienceId, userId)
  if (appRegion === "china") {
    const { data, error } = yield supabaseDB().from('user_info').select().eq('experience_id', experienceId).eq('user_id', userId);
    console.log("supabaseDB getting user info: ", data);
    if(error){
      console.log("Error in getting user info from parse: ", error);
    }else{
      userInfoFromOnlineDB = data[0] || {}
      console.log("supabaseDB userInfoFromOnlineDB", userInfoFromOnlineDB);
    }
  } else {
    try {
      yield new Promise((res) => setTimeout(res, 1000));
      const userDocRef = doc(firestoreDB, `experiences/${experienceId}/user_info`, userId.toString());
      let firestoreDBData = yield getDoc(userDocRef);
      userInfoFromOnlineDB = firestoreDBData.exists() ? firestoreDBData.data() : {};
    } catch (error) {
      console.log("Error in getting user info from firestore: ", error);
    }
  }
  // Merge offline db user_info data to this
  userInfoFB = {
    ...userInfoFB,
    ...userInfoFromOnlineDB,
  };

  if (!embedded) {
    let offlineDBData = yield getItemsFromOfflineDB("userInfo", {
      experience_id: experienceId,
      user_id: `${userId}`,
    });
    userInfoFromOfflineDB = offlineDBData[0] || {};

    delete userInfoFromOfflineDB.id; // extra data no use

    // console.log("offlineDBData ==>", offlineDBData, JSON.stringify(userInfoFromOfflineDB), JSON.stringify(userInfoFromOnlineDB));
    if (
      (userInfoFromOfflineDB.student_updated_at >
      userInfoFromOnlineDB.student_updated_at) || 
      (!userInfoFromOnlineDB.student_updated_at) // APL-3262 fix - userInfoFromOnlineDB.student_updated_at is undefined if couldn't fetch user_info from firestore and > undefined is false so not using userInfoFromOfflineDB hence userInfoFb going as {}
    ) {
      userInfoFB = {
        ...userInfoFB,
        ...userInfoFromOfflineDB,
      };
    } else {
      // online is latest
    }

    // Giving teacher action keys priority from online
    onlinePriorityKeys.forEach((key) => {
      if (userInfoFromOnlineDB[key]) {
        userInfoFB[key] = userInfoFromOnlineDB[key];
      }
    });
  }

  return userInfoFB;
}

function* getUserResponsesFromBackup(options) {
  // Put this in try catch
  const { experienceId, userId, onlyOffline } = options;
  const appRegion = yield select(appRegionSelector());
  const enabledFeatures = yield select(enabledFeaturesSelector());
  const embedded = yield select(getEmbeddedSelector());
  const userResponsesFromRedux = yield select(
    userResponsesSelector(experienceId, userId)
  );
  const activeExperience = yield select(activeAdjustedExperienceSelector());

  const experienceSettings = activeExperience.settings || {};
  var userResponsesFB = onlyOffline ? {} : userResponsesFromRedux,
    userResponsesFromOnlineDB = {},
    userResponsesFromOfflineDB = {};
  let supportOldUserResponseId = false;
  // console.log("experienceId, userId 112", experienceId, userId );
  try {
    if (appRegion === "china") {
      const { data, error } = yield supabaseDB().from('user_responses').select().eq('experience_id', experienceId).eq('user_id', userId).eq('backup', false)
      if(error){
        console.log("Error in getting user info from parse: ", error);
      }else{
        userResponsesFromOnlineDB = formatSupabaseUserResponses(data)
        console.log("supabaseDB userResponsesFromOnlineDB", userResponsesFromOnlineDB);
      }
    } else {
      try {
        if (!onlyOffline) {
          const userResponsesCollection = collection(firestoreDB, `experiences/${experienceId}/user_responses`);
          const userResponsesQuery = query(
            userResponsesCollection,
            where("user_id", "==", parseInt(userId)),
            where("backup", "==", false)
          );
          const firestoreDBData = yield getDocs(userResponsesQuery);
          userResponsesFromOnlineDB = formatFirestoreUserResponses(firestoreDBData)
        }// use for comparision
      } catch (error) {
        console.log("Error in getting data from firestore ==>", error);
      }
    }

    userResponsesFB = {
      ...userResponsesFB,
      ...userResponsesFromOnlineDB,
    };

    if (!embedded) {
      let batchActionParams = [];
      let offlineDBData = yield getItemsFromOfflineDB("userResponses", {
        experience_id: experienceId,
        user_id: parseInt(userId),
      });
      userResponsesFromOfflineDB = offlineDBData;

      if (!onlyOffline) {
        userResponsesFromOfflineDB = offlineDBData.filter(
          (item) => item.backup === false
        );
      }
      // try {
      // } catch (error) {
      //   console.log("Error in getting data from indexed db ==>", error)
      // }
      // In dexie boolean's are not indexed so when searching having boolean value ({backup: false}) not possible

      console.log(
        "userResponsesFromOnlineDB, userResponsesFromOfflineDB",
        userResponsesFromOnlineDB,
        userResponsesFromOfflineDB
      );

      userResponsesFromOfflineDB.forEach((urODB) => {
        delete urODB.id;
        const ur_id = (onlyOffline ? "offline_" : "") + urODB.user_response_id;
        const urFromFirestoreDB = userResponsesFromOnlineDB[ur_id];
        const urFromOfflineDB = { 
          ...urODB, 
          user_response_id: ur_id,
          uuid: ur_id,
          backup: onlyOffline ? true : urODB.backup
        };

        let saveToOnlineBackup = false;
        if (urFromFirestoreDB) {
          if (
            Date.parse(urFromOfflineDB.updated_at) >
            Date.parse(urFromFirestoreDB.updated_at)
          ) {
            userResponsesFB[ur_id] = { ...urFromOfflineDB };
            saveToOnlineBackup = true;
          }
          // Can use this if want to sync online data to offline data
          // else {
          //   urFromOfflineDB[ur_id] = { ...urFromFirestoreDB }
          // }
        } else {
          userResponsesFB[ur_id] = { ...urFromOfflineDB };
          saveToOnlineBackup = true;
        }

        if (saveToOnlineBackup) {
          // When recoverd backup is merged then online backup also merged for consistency purpose ?
          delete urFromOfflineDB.id; // extra data no use
          batchActionParams.push({
            // experienceId: experienceId,
            basePath: getExperienceCollectionPath(
              experienceId,
              "user_responses"
            ),
            docIds: [ur_id],
            tableName: "user_responses",
            uuids: [ur_id],
            object: { ...urFromOfflineDB },
            options: {},
          });

          if(urFromFirestoreDB){
            // urFromFirestoreDB can be undefined in case no online backups were being made - only offline - so no need to send in that case
            let backupDocId = ur_id + "_" + Date.now()
            batchActionParams.push({
              // experienceId: experienceId,
              basePath: getExperienceCollectionPath(
                experienceId,
                "user_responses"
              ),
              docIds: [backupDocId],
              tableName: "user_responses",
              uuids: [backupDocId],
              object: {
                ...urFromFirestoreDB,
                uuid: backupDocId,
                user_response_id: backupDocId,
                backup: true,
              },
              options: {},
            });
          }
          }
      });

      // Never helped - so not using anymore
      // Saving all offline data to seperate user_responses_offline for investigation purpose not getting used for now
      // offlineDBData.forEach((data) => {
      //   delete data.id; // extra data no use
      //   batchActionParams.push({
      //     experienceId: experienceId,
      //     basePath: getExperienceCollectionPath(
      //       experienceId,
      //       "user_responses_offline"
      //     ),
      //     docIds: [data.user_response_id],
      //     object: data,
      //     options: {},
      //   });
      // });

      // ADD support for china batch actions here
      if (batchActionParams.length) {
        realtimeBatchActions(batchActionParams, {
          successCallback: () => {
            console.log("realtimeBatchActions successcallback 1");
          },
          errorCallback: (error) => {
            console.log("realtimeBatchActions errorcallback 1", error);
          },
          appRegion: appRegion,
        });
      }
    }

    let ur_key = Object.keys(userResponsesFB)[0];
    if (ur_key && ur_key.indexOf("qId") >= 0) {
      supportOldUserResponseId = true;
    }

    console.log("hey ==>", supportOldUserResponseId, actions);
    if (supportOldUserResponseId) {
      yield put(
        actions.supportOldUserResponseIdSuccess({ supportOldUserResponseId })
      );
    }
  } catch (error) {
    console.error("Error in getUserResponsesFromBackup ==>", error);
    Sentry.captureException(error);
  }

  console.log("Final userResponsesFB ==>", userResponsesFB);
  return userResponsesFB;
}

function* syncLogs(options) {
  const { experienceId, userId } = options;
  const appRegion = yield select(appRegionSelector());
  const embedded = yield select(getEmbeddedSelector());

  if (!embedded) {
    try {
      let batchActionParams = [];
      let logsFromOfflineDB = yield getItemsFromOfflineDB("logs", {
        experience_id: experienceId,
        user_id: parseInt(userId)
      }, {
        filterBy: {internet_status: ["offline", "network_issue"]}, // using filterBy as search of array values in searchObject for key is not supported in indexedDB
      });

      console.log("logsFromOfflineDB ==>", logsFromOfflineDB);

      logsFromOfflineDB.forEach((offlineLog) => {
        delete offlineLog.id; // extra data no use
        // NOTE: we are overridding online logs with offline logs - those with same log_id since they would actually be the same, it doesnt matter
        batchActionParams.push({
          // experienceId: experienceId,
          basePath: getExperienceCollectionPath(experienceId, "logs"),
          docIds: [offlineLog.log_id],
          tableName: "logs",
          uuids: [offlineLog.log_id],
          object: offlineLog,
          options: {},
        });
      });

      realtimeBatchActions(batchActionParams, {
        successCallback: (response) => {
          console.log("Logs synced successfully");
        },
        errorCallback: (error) => {
          console.log("Logs not synced", error);
        },
        appRegion: appRegion,
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  }
}

function getExperienceCollectionPath(testId, childPath = "user_info") {
  return `experiences/${testId}/${childPath}`;
}
function getOrgCollectionPath(orgId, childPath = "logs") {
  return `orgs/${orgId}/${childPath}`;
}


// export function* batchSetUserResponseSaga(action) {
//   try {
//     yield put(actions.batchStart());
//     const { userId } = action.payload;
//     const activeExperienceId = yield select(activeExperienceIdSelector);
//     const appRegion = yield select(appRegionSelector());
//     const currentUser = yield select(currentUserSelector());
//     const userResponsesFromRedux = yield select(
//       userResponsesSelector(activeExperienceId, userId)
//     );
//     let userResponses = { ...userResponsesFromRedux };
//     // check internet connectivity aswell
//     const batch = firestoreDB.batch();

//     const experienceUserResponsesRef = firestoreDB.collection(
//       getExperienceCollectionPath({
//         testId: activeExperienceId,
//         childPath: "user_responses",
//       })
//     );
//     for (const key in userResponses) {
//       if (userResponses.hasOwnProperty(key)) {
//         const element = userResponses[key];
//         const tempRef = experienceUserResponsesRef.doc(key);
//         batch.set(tempRef, { ...element }, { merge: true });
//         // batch
//       }
//     }
//     yield batch.commit();
//     yield put(actions.batchSuccess());
//   } catch (error) {
//     yield put(actions.batchFail());
//   }
// }

export function* setUserResponseSaga(action) {
  try {
    const {
      userResponseId,
      segmentId,
      questionId,
      userId,
      topicId,
      ePoints,
      responseJSON,
      textAnswer,
      gameStatus,
      attemptStatus,
      attachmentsJSON,
      includeTextAnswer,
      extraInteractive,
      filesUploaded,
      extra = {},
    } = action.payload;
    console.log("setUserResponseSaga payload ==>", action.payload)
    const { updateOnlineSaveStatus = () => {} } = action.options;

    const docId = userResponseId;
    const appRegion = yield select(appRegionSelector());
    const partnerName = yield select(partnerNameSelector());
    const experienceId = yield select(activeExperienceIdSelector);
    const activeExperience = yield select(activeAdjustedExperienceSelector());

    const embedded = yield select(getEmbeddedSelector());
    const currentUser = yield select(currentUserSelector());
    const activeSegmentInfo = yield select(activeSegmentInfoSelector);
    const userResponseConfigFromRedux = yield select(userResponseConfigSelector);
    const beforeUpdateUserInfoQuestionsStatus = yield select(questionsStatusSelector);
    const beforeUpdateUserResponse = yield select(userResponseSelector(userResponseId))
    const experienceConfig = yield select(experienceConfigSelector());

    let saveToFirebase = true;
    // let saveToFirebase = false;
    let saveToAPDB = false;
    if (partnerName === "osc") {
      saveToFirebase = false;
      // IMP: setting saveToAPDB true in userResponseConfig on checkAnswer in MCQSingle for osc case
      saveToAPDB = userResponseConfigFromRedux.saveToAPDB;
    }

    console.log(
      "beforeUpdateUserInfoQuestionsStatus",
      beforeUpdateUserInfoQuestionsStatus,
      beforeUpdateUserResponse
    );

    var d = new Date();

    // let beforeUpdateUserResponse = yield select((state) =>
    //   state.firebase.getIn(["responses", userResponseId], {
    //     attempt_status: {},
    //   })
    // );

    // if (typeof beforeUpdateUserResponse === "undefined") {
    //   beforeUpdateUserResponse = {
    //     attempt_status: {},
    //   };
    // }

    let userInfoData = {};
    let userInfoChanged;
    let attempted = false;
    let filesUploadedCount = 0;
    let reduxUserResponses;
    let userResponseData = {
      ...beforeUpdateUserResponse,
      experience_id: experienceId,
      user_id: userId,
      question_id: questionId,
      segment_id: segmentId,
      topic_name: extra.topic_name,
      question_number: extra.question_number,
      topic_idx: extra.topic_idx,
      topic_id: topicId,
      updated_at: d.toUTCString(),
      backup: false,
      // e_points: ePoints, // commenting out for now - need to make fields in supabase
      user_response_id: userResponseId,
    };

    if (responseJSON) {
      userResponseData = {
        ...userResponseData,
        extra_interactive: extraInteractive,
        response_json: responseJSON,
      };
    }

    // to save text answer when completely removed
    if (textAnswer || textAnswer == "") {
      userResponseData = {
        ...userResponseData,
        include_text_answer: textAnswer ? true : false,
        text_answer: textAnswer,
      };
    }

    if (gameStatus) {
      userResponseData = {
        ...userResponseData,
        game_status: gameStatus,
      }
    }

    const beforeAttemptStatus = beforeUpdateUserResponse.attempt_status || {};
    if (attemptStatus) {
      let newAttemptStatus = {
        ...beforeAttemptStatus,
        ...attemptStatus,
      };
      filesUploadedCount = newAttemptStatus.files_uploaded; // This is number to check files uploaded count?
      
      // text_answer_attempted overriding in questions except subjective - remove text answer attempt from them
      if (responseJSON || gameStatus) {
        newAttemptStatus = {
          ...newAttemptStatus,
          text_answer_attempted:
            beforeAttemptStatus.text_answer_attempted || false,
        };
      }

      attempted =
        newAttemptStatus.text_answer_attempted ||
        newAttemptStatus.json_attempted ||
        newAttemptStatus.files_upload_attempted ||
        false;

      console.log(
        "newAttemptStatus ==>",
        newAttemptStatus,
        beforeAttemptStatus,
        attempted
      );
      userResponseData = {
        ...userResponseData,
        attempt_status: {
          ...newAttemptStatus,
          attempted: attempted,
        },
      };
    }

    let oldFilesUploadedCount =
        beforeAttemptStatus.files_uploaded;
    if (attachmentsJSON) {
      userResponseData = {
        ...userResponseData,
        files_uploaded: filesUploaded, // This is boolean to check files uploaded or not?
        attachments_json: attachmentsJSON,
      };

      filesUploadedCount = attemptStatus.files_uploaded; // This is number to check files uploaded count?
      
      let attachmentsCount = 0;

      if (oldFilesUploadedCount !== filesUploadedCount) {
        const beforeUpdateTotalAttachmentsJSON = yield select(
          totalAttachmentsSelector
        );

        const beforeUpdateTotalAttachments = beforeUpdateTotalAttachmentsJSON.attachments
        const attachments = attachmentsJSON.attachments

        let totalAttachments = beforeUpdateTotalAttachments.filter((atc) => atc.segment_id !== segmentId)
        totalAttachments = totalAttachments.concat(attachments)
        // const totalAttachments = {
        //   ...beforeUpdateTotalAttachments,
        //   [topicId]: {
        //     topic_id: topicId,
        //     topic_name: extra.topic_name,
        //     [segmentId]: {
        //       question_number: extra.question_number,
        //       segment_id: segmentId,
        //       question_id: questionId,
        //       files_uploaded: files_uploaded,
        //       backup_attachments: attachmentsJSON,
        //     },
        //   },
        // };

        // reduxUserResponses = yield select(userResponsesSelector(experienceId, userId));
        // for (const key in reduxUserResponses) {
        //   let v = reduxUserResponses[key];
        //   if (key !== userResponseId) {
        //     if (v && v.attempt_status && v.attempt_status.files_uploaded) {
        //       attachmentsCount += v.attempt_status.files_uploaded;
        //     }
        //   }
        // }
        // attachmentsCount += files_uploaded;

        userInfoData = {
          ...userInfoData,
          total_attachments_json: {
            attachments: totalAttachments,
          },
          attachments_count: totalAttachments.length,
        };
        userInfoChanged = true;
      }
    }

    console.log(
      "user responsedata==>",
      userResponseData,
      docId,
      action.payload
    );

    let userInfoQuestionsStatus = [...beforeUpdateUserInfoQuestionsStatus]; // is array of objects
    let correspondingQuestionStatusForURIdx = userInfoQuestionsStatus.findIndex(
      (item) =>
        item.segment_id === userResponseData.segment_id &&
        item.question_id === userResponseData.question_id &&
        item.topic_id === userResponseData.topic_id
    );

    let beforeQuestionsStatusAttemptStatus = userInfoQuestionsStatus[correspondingQuestionStatusForURIdx] && userInfoQuestionsStatus[correspondingQuestionStatusForURIdx].attempted

    let attemptCount = 0;
    let totalEngagementPoints = 0;
    // let oldAttempted = false;
    // if (
    //   beforeAttemptStatus.attempted == true
    // ) {
    //   oldAttempted = true;
    // }
    let oldAttempted = beforeAttemptStatus.attempted
    console.log(
      "before attempt count",
      userInfoQuestionsStatus[correspondingQuestionStatusForURIdx],
      experienceId,
      userId,
      userResponseId,
      oldAttempted,
      attempted,
      beforeQuestionsStatusAttemptStatus,
    );
    console.log("oldFilesUploadedCount, filesUploadedCount ====>", oldFilesUploadedCount, filesUploadedCount);
    if (oldAttempted !== attempted || oldFilesUploadedCount !== filesUploadedCount || beforeQuestionsStatusAttemptStatus !== attempted) {
      if (!reduxUserResponses) {
        reduxUserResponses = yield select(userResponsesSelector(experienceId, userId));
      }
      console.log("reduxUserResponses", reduxUserResponses);

      
      for (const key in reduxUserResponses) {
        let v = reduxUserResponses[key];
        if (key !== userResponseId) {
          if (v && v.attempt_status && v.attempt_status.attempted == true) {
            attemptCount += 1;
            totalEngagementPoints += v.e_points; // for all responses except current one attempted
          }
        } else {
        }
      }

      if (attempted) {
        attemptCount += 1;
      }else{
        // reset
        userResponseData = {
          ...userResponseData,
          // e_points: 0 // commenting out for now - need to make fields in supabase
        }
      }
      // current attempted one
      totalEngagementPoints += userResponseData.e_points;

      // TODO: save userResponseData.attempt_status, userResponseData.segment_id, question_number, question_id in userInfo overall_attempt_status = []
      // PROB - dont have existing userInfoData here so can't use attempt_status existing. have to build each time - doing in loop above
      let tempAttemptStatusObj = {
        // ...userResponseData.attempt_status, // We were not saving latest value for this - so better to not use it
        attempted: attempted ?? false,
        files_uploaded: filesUploadedCount ?? 0,
        segment_id: userResponseData.segment_id,
        question_id: userResponseData.question_id,
        topic_id: userResponseData.topic_id,
        topic_idx: userResponseData.topic_idx,
        question_number: userResponseData.question_number,
      };
      console.log(
        "tempAttemptStatusObj",
        // beforeUpdateUserInfoQuestionsStatus,
        tempAttemptStatusObj,
        correspondingQuestionStatusForURIdx
      );
      if (correspondingQuestionStatusForURIdx >= 0) {
        // correspondingAttemptStatusForUR = tempAttemptStatusObj
        userInfoQuestionsStatus[correspondingQuestionStatusForURIdx] = {
          ...userInfoQuestionsStatus[correspondingQuestionStatusForURIdx],
          ...tempAttemptStatusObj,
        };
      } else {
        userInfoQuestionsStatus.push(tempAttemptStatusObj);
      }

      userInfoData = {
        ...userInfoData,
        attempt_count: attemptCount,
        questions_status: userInfoQuestionsStatus,
        // total_e_points: totalEngagementPoints, // commenting out for now - need to make fields in supabase
      };
      userInfoChanged = true;
    }

    console.log(
      "setUserResponseSaga just before success",
      userResponseId,
      userResponseData
    );
    yield put(
      actions.setUserResponseSuccess({
        userResponseId,
        userResponse: userResponseData,
      })
    );

    if (userInfoChanged && saveToFirebase) {
      console.log(
        "sending user info data inside setUserResponse ==>",
        userInfoData
      );
      yield put(actions.setUserInfo({ userInfo: userInfoData }));
    }

    // save to FB after redux
    let savedToFirebase = false;
    if (currentUser.role === "student") {
      try {
        // userResponseData = userResponseData[5][5] // generate custom error
        // new
        let batchActionParams = [];
        let latestUserResponseBatchParams = {};

        // // TEMP
        // if(appRegion === "china"){
        //   console.log("supabaseDB writing to user_info ==>", userResponseData)
        //   let userInfoToUpdate = {
        //     ...userResponseData,
        //     experience_id: experienceId,
        //     uuid: `eId${experienceId}_uId${currentUser.id}_sId${userResponseData.segment_id}`
        //   }
        //   const { data: data1, error } = yield supabaseDB
        //   .from('user_responses')
        //   .upsert(userInfoToUpdate, { onConflict: 'uuid' })
        //   .select()
        // }

        latestUserResponseBatchParams = {
          // experienceId: experienceId,
          basePath: getExperienceCollectionPath(experienceId, "user_responses"),
          docIds: [docId],
          tableName: "user_responses",
          uuids: [docId],
          object: userResponseData,
          options: {
            actionType: "set",
            ...action.options,
          },
        };

        if (
          !userResponseData.last_backup_at ||
          // beforeUpdateUserResponse.updated_at && // Ask Shikhar - this should not create multiple backup at same time
          (userResponseData.last_backup_at &&
            Date.now() - parseInt(userResponseData.last_backup_at) >= 120000)
        ) {
          // check if no last_backup or exists but gap more than 2 mins
          // check if no last_backup or exists but gap more than 2 mins
          let updatedUserResponseData = {
            ...userResponseData,
            backup_type: "online",
            last_backup_at: Date.now(),
          };
          latestUserResponseBatchParams = {
            ...latestUserResponseBatchParams,
            object: updatedUserResponseData,
          };

          let backupDocId = docId + "_" + Date.now();
          let backupUserResponseDataBatchParams = {};

          backupUserResponseDataBatchParams = {
            // latest user_response
            // experienceId: experienceId,
            basePath: getExperienceCollectionPath(
              experienceId,
              "user_responses"
            ),
            docIds: [backupDocId], // append timestamp
            tableName: "user_responses",
            uuids: [backupDocId],
            object: {
              ...beforeUpdateUserResponse, // Done: used beforeUpdateUserResponse for the case user reset the question
              backup: true,
              user_response_id: backupDocId,
              uuid: backupDocId,
              backup_type: "online", // must keep keys the same for china supabase bulk upsert
              // any other mods?
            },
            options: {
              actionType: "set",
            },
          };

          batchActionParams.push(latestUserResponseBatchParams);
          if(beforeUpdateUserResponse.user_response_id){
            // to avoid saving the backupUserResponseDataBatchParams on first time setUserResponse of any question
            batchActionParams.push(backupUserResponseDataBatchParams);
          }

          // setting locally again with last_backup at
          yield put(
            actions.setUserResponseSuccess({
              userResponseId,
              userResponse: updatedUserResponseData,
            })
          );
        } else {
          batchActionParams.push(latestUserResponseBatchParams);
        }

        // Check this in emebedded iframe - indexedDB works?
        // On submission save local data on firestore and then clear local data
        // Check how to hide data in indexedDB - currently showing in plain text

        // Saving to offline before online

        if (currentUser.role == "student" && !embedded) {
          batchActionParams.forEach((params) => {
            // const userResponseId = [...params["docIds"]][0] + "_offline"; // Already having backup_type so no "_offline" append required
            const object = { ...params["object"] };
            const userResponseId = object.user_response_id
            const userResponseData = {
              ...object,
              // user_response_id: userResponseId,
              experience_id: experienceId,
              backup_type: "offline",
            };
            delete userResponseData.id;
            saveItemToOfflineDB(
              "userResponses",
              { user_response_id: userResponseId },
              userResponseData,
              params["options"]
            );
          });
        }

        console.log("realtime batch action params ==>", batchActionParams);

        if (saveToFirebase) {
          // TODO: send ur to BE here instead of firestore in case of osc embed mcq save user_response

          realtimeBatchActions(batchActionParams, {
            successCallback: () => {
              savedToFirebase = true;
              console.log("realtimeBatchActions successcallback 1");
              updateOnlineSaveStatus("saved");
            },
            errorCallback: (error) => {
              console.log("realtimeBatchActions errorcallback 1", error);
              if (
                error.message &&
                error.message.indexOf("Unsupported field value: undefined") >= 0
              ) {
                const withoutUndfinedBatchActionParams = JSON.parse(
                  JSON.stringify(batchActionParams)
                );
                console.log(
                  "withoutUndfinedBatchActionParams",
                  withoutUndfinedBatchActionParams
                );
                realtimeBatchActions(withoutUndfinedBatchActionParams, {
                  successCallback: () => {
                    savedToFirebase = true;
                    console.log("realtimeBatchActions successcallback 2");
                    updateOnlineSaveStatus("saved");
                  },
                  errorCallback: (error) => {
                    console.log(
                      "realtimeBatchActions errorcallback 2 ==>",
                      error
                    );
                    savedToFirebase = false;
                    Sentry.captureException(error);
                    updateOnlineSaveStatus("failed");
                  },
                  appRegion: appRegion,
                });
              } else {
                console.log("response is not saved on firebase ==>", error);
                savedToFirebase = false;
                Sentry.captureException(error);
                updateOnlineSaveStatus("failed");
              }
            },
            appRegion: appRegion,
          });
        }
      } catch (error) {
        console.log("userResponseData saving to firebase failed", error);
        savedToFirebase = false;
        Sentry.captureException(error);
      }

      // TODO - from experienceConfig and also add eu_id to urData
      if (saveToAPDB || experienceConfig.takeExperience.start.userResponseSave.apDb.enabled) {
        console.log(
          "to do: saveToAPDB in case od OSC embed segment",
          userResponseData
        );
        if (activeExperience.experience_user) {
          userResponseData = {
            ...userResponseData,
            experience_user_id: activeExperience.experience_user.id
          }
        }

        yield call(submitUserResponseSaga, userResponseData);
      }
    }
  } catch (error) {
    console.log("setUserResponse error", error, error.message);
    Sentry.captureException(error);
    yield put(actions.setUserResponseFail({ error: error }));
  }
}

export function* teacherSubmitExperienceSaga(action) {
  const { userId } = action.payload;
  try {
    yield put(actions.teacherSubmitExperienceStart({ id: userId }));
    const activeExperienceId = action.payload.experienceId;

    const userResponsesFB = yield call(getUserResponsesFromBackup, {
      experienceId: activeExperienceId,
      userId: userId,
    });

    const submitExperienceData = {
      responses: userResponsesFB,
      user_id: userId,
    };

    let url = `submit_experience/${activeExperienceId}.json?user_id=${userId}`;
    const response = yield axiosInstance.instance.post(
      url,
      submitExperienceData
    );

    if (action.options && action.options.successCallback) {
      yield call(action.options.successCallback, response.data);
    }

    if (response.data) {
      let studentName = action.payload.name;
      if (response.data.code === "submitted") {
        message.success(
          `Responses successfully submitted for user ${studentName}`
        );
      } else if (response.data.code === "already_submitted") {
        message.error(
          `Test submission attempted but was already submitted for user ${studentName}`
        );
      } else if (response.data.code === "submitting") {
        // in case simultaneous submission happens again
        message.error(
          `Test submission attempted but submission already in progress for user ${studentName}`
        );
      } else {
        message.error(
          `Some error occurred in submission for user ${studentName}`
        );
      }
    }

    yield put(actions.teacherSubmitExperienceSuccess({ id: userId }));
  } catch (error) {
    console.error("error", error);
    message.error(
      `Something went wrong in saving student responses for ${action.payload.name}`
    );
    Sentry.captureException(error);
    if (error.response && error.response.data) {
      if (action.options && action.options.errorCallback) {
        yield call(action.options.errorCallback, error.response.data);
      }
    }
    yield put(actions.teacherSubmitExperienceFail({ id: userId }));
  }
}

export function* signInToFirestoreSaga(action) {
  try {
    // TODO: can avoid this token call if fbCurrentUser exists so check that first
    const { payload = {} } = action;
    const { experienceId } = payload;
    console.log("signInToFirestoreSaga ======>", action);
    const appRegion = yield select(appRegionSelector());
    const currentUser = yield select(currentUserSelector());
    const enabledFeatures = yield select(enabledFeaturesSelector());
    
    const url = "get_realtimedb_token.json";
    yield put(actions.signInToFirestoreStart());
    let token = null;
    let refreshToken = null;
    let fbCurrentUser = null;
    let params = { region: appRegion }
    if (currentUser.role === "student" && experienceId){
      params.test_id = experienceId;
    }

    if (appRegion === "china") {
      // not setting session since customJWT anyway so no point checking here, calling to getCustomJWT each time for china, handle expiry in case we change this and want to keep it in LS
      // const { data: { session } } = yield supabaseDBWithoutToken.auth.getSession();
      // if(session){
      //   fbCurrentUser = session.user;
      // }
      // console.log("supabaseDB session", session);

      if (!fbCurrentUser) {
        const response = yield call(axiosInstance.instance.get, url, {
          params: params,
        });
        console.log("supabaseDB getCustomJWT", response);
        token = response.data.token;

        if (token) {
          supabaseDB(token) // init and creates supabaseDB that is exported for use as before
        } else {
          console.log("Supabase token not received from backend", response);
          throw Error(`Supabase token not received from backend, Token - ${response.data.token}, Status - ${response.status}, Message - ${response.data.message}`);
        }
        // refreshToken = response.data.refresh_token;
        // console.log("supabaseDB token response", response);
        // // setSession not working
        // // const { data, error } = yield supabaseDB.auth.setSession({token: token, refresh_token: refreshToken});
        // const { data, error } = yield supabaseDB.auth.refreshSession({refresh_token: refreshToken});


        // This token works
        // let AUTHORIZATION_TOKEN = "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJhdXRoZW50aWNhdGVkIiwiZXhwIjoxNjgwODUzMzY4LCJzdWIiOiIyOWJjOTJlZi03ODFkLTQyYjMtOTlmYS04NDc0YmIzMzlmYjMiLCJlbWFpbCI6InNoYXJvbkBlZHV2by5jb20iLCJwaG9uZSI6IiIsImFwcF9tZXRhZGF0YSI6eyJwcm92aWRlciI6ImVtYWlsIiwicHJvdmlkZXJzIjpbImVtYWlsIl19LCJ1c2VyX21ldGFkYXRhIjp7fSwicm9sZSI6ImF1dGhlbnRpY2F0ZWQiLCJhYWwiOiJhYWwxIiwiYW1yIjpbeyJtZXRob2QiOiJwYXNzd29yZCIsInRpbWVzdGFtcCI6MTY4MDgxMDE2OH1dLCJzZXNzaW9uX2lkIjoiMzkzOWI4ZTUtMDVkZS00OGFiLWJkMGItNTczMWM2NWM4ZDQyIn0.WYBnqrcc45KiGIYh4lBZAXhCh8h8mpvXVMgfX_VEAys"
        // This token doesnt work
        // let AUTHORIZATION_TOKEN = "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInVzZXJfaWQiOjI0NSwicmVmIjoieGltYmR3eWVrZm1pb3h4ZWtsaHgiLCJyb2xlIjoiYXV0aGVudGljYXRlZCIsImFwX3JvbGUiOiJ0ZWFjaGVyIiwiaWF0IjoxNjczNDk1OTY1LCJleHAiOjE5ODkwNzE5NjV9.P6NSBWqpqAu9ySVDp8xWnJ1JjyUnP8UVclbSG3O0HiE"

        // const supabaseDBNew = window.supabase && window.supabase.createClient(supabaseConf.supabaseUrl, supabaseConf.supabaseKey,
        //   { global: { headers: { Authorization: AUTHORIZATION_TOKEN } } }
        // )
        // const { data, error } = yield supabaseDBNew.auth.getUser()

        // const { data, error } = yield supabaseDB.auth.getUser()
        // console.log("supabaseDB auth", data, error);

        
        // if(error){
        //   console.log("supabaseDB setSession error", error);
        // }
        // if(data){
        //   fbCurrentUser = data.user
        // }
      }
    } else {
      fbCurrentUser = yield firebaseAuth.currentUser;
      console.log("fbCurrentUser", fbCurrentUser);
      if (!fbCurrentUser) {
        const response = yield call(axiosInstance.instance.get, url, {
          params: params,
        });
        token = response.data.token;
        if (token) {
          // when we did token new for each test - calling each time - issue is that 1 test then open another test, then come back to 1st test - issues
          
          // IMP: BLOCKING error - goes into catch in case signInWithCustomToken fails - not letting student start test if signIn fails (eg google/firestore urls blocked in school network) - this was already the behaviour - just adding comment. Not happening if user already signed-in at some point (since we check 1287) - need to solve for that case 
          // console.log("signInToFirestore token, response.data, params ===>", token, JSON.stringify(response.data), JSON.stringify(params));
          const fbCredential = yield signInWithCustomToken(firebaseAuth, token);
          fbCurrentUser = fbCredential.user;
          console.log("signInToFirestore fbCurrentUser", fbCredential);
          if (!fbCurrentUser) {
            // IMP: BLOCKING error in case for some reason, sign in fails and doesn't go into catch but no fbCurrentUser
            console.log("signInToFirestore no fbCurrentUser found", fbCurrentUser);
            throw Error(`Not able to find firebase user, User - ${fbCurrentUser}`);
          }
        } else {
          console.log("Firebase token not received from backend", response);
          throw Error(`Firebase token not received from backend, Token - ${response.data.token}, Status - ${response.status}, Message - ${response.data.message}`);
        }
      }

      if (enabledFeatures.firestore_accessible_check) {
        ///////////////////////////////////////////////
        // NOTE: Signing in each time is not required, just checking if Firestore is accessible or not will redirect to the FS-O1 error page
        // Perform a simple operation to check if Firestore is accessible
        ///////////////////////////////////////////////
        try {
          const testDocRef = doc(firestoreDB, "test", "testDoc");
          console.log("Check if firestore is accessible.");
          yield getDoc(testDocRef);
          // Document was successfully written, Firestore is accessible
          console.log("Firestore is accessible.");
        } catch(error) {
          // Firestore is inaccessible due to a potential block
          console.log("Firestore is blocked or inaccessible:", error);
          throw Error(`Firestore is blocked or inaccessible - ${error.message}`);
        };
      }
    }
    console.log("signInToFirestore ==>", fbCurrentUser)
    let finalSuccessPayload = { token };
    yield put(actions.signInToFirestoreSuccess(finalSuccessPayload));
  } catch (error) {
    console.log("error in signInToFirestore", error.message, error, error.response);
    yield put(actions.signInToFirestoreFail({ error: error }));
    Sentry.captureException(error);
  }
}

export function* submitExperienceSaga(action) {
  try {
    yield put(actions.submitExperienceStart());
    const activeExperienceId = yield select(activeExperienceIdSelector);
    const activeExperience = yield select(activeAdjustedExperienceSelector());
    const appRegion = yield select(appRegionSelector());
    const currentUser = yield select(currentUserSelector());
    const appType = yield select(appTypeSelector());
    const userResponsesFB = yield call(getUserResponsesFromBackup, {
      experienceId: activeExperienceId,
      userId: currentUser.id,
    });
    const currentDeviceInfo = getDeviceInfo(appType);

    // Syncing offline logs with online logs on submit
    yield call(syncLogs, {
      experienceId: activeExperienceId,
      userId: currentUser.id,
    });

    const submitExperienceData = {
      student_region: appRegion,
      responses: userResponsesFB,
      device_info: currentDeviceInfo,
    };
    let url = `submit_experience/${activeExperienceId}.json?user_id=${currentUser.id}`;
    const response = yield axiosInstance.instance.post(
      url,
      submitExperienceData
    );

    yield put(actions.submitExperienceSuccess(response.data));

    // Logging from backend only, setting user info from here to update offline db
    if (response.data) {
      if (response.data.code === "submitted") {
        // success
        yield put(
          actions.setUserInfo({ userInfo: { currentView: "submittedTest" } })
        );
        if (activeExperience && response.data.eu){
          let updatedExp = {
            ...activeExperience,
            experience_user: response.data.eu
          }
          console.log("updatedExp", updatedExp);
          yield put(
            experienceReduxActions.showSuccess(
              { data: { experience: updatedExp } },
              // { success: { showMessage: false } }
            )
          );
        }
      } else if (response.data.code === "already_submitted") {
        yield put(
          actions.setUserInfo({ userInfo: { currentView: "submittedTest" } })
        );
        // Logs moved to BE
        message.error("Test submission attempted but was already submitted");
      } else if (response.data.code === "submitting") {
        // in case simultaneous submission happens again
        message.error(
          "Test submission attempted but submission already in progress"
        );
      } else {
        message.error(`Some error occurred in submission - ${response.data.message}`);
      }
    }

    // Deleting data from local so that no memory issues
    // yield deleteItemsFromOfflineDB('userInfo', {experience_id: activeExperienceId, user_id: currentUser.id})
    // yield deleteItemsFromOfflineDB('userResponses', {experience_id: activeExperienceId, user_id: currentUser.id})
  } catch (error) {
    console.log("Not able to submit user responses", error);
    if(import.meta.env.VITE_MODE === "staging") {
      message.error(`Some error occurred in submission - ${error.message}`);
    } else {
      message.error("Some error occurred in submission");
    }
    // TodO change to message from BE
    // message.error(error)
    yield put(
      actions.submitExperienceFail({
        error: {
          code: "RAILS_ERROR_OR_FIREBASE_ERROR",
          message: "Could not save your responses",
        },
      })
    );
    Sentry.captureException(error);
  }
}

export function* syncOfflineResponsesSaga(action) {
  try {
    yield put(actions.syncOfflineResponsesStart());
    const activeExperienceId = yield select(activeExperienceIdSelector);
    const currentUser = yield select(currentUserSelector());

    // Syncing local storage responses with online responses
    yield call(getUserResponsesFromBackup, {
      experienceId: activeExperienceId,
      userId: currentUser.id,
      onlyOffline: true,
    });

    // Offline logs with online logs
    yield call(syncLogs, {
      experienceId: activeExperienceId,
      userId: currentUser.id,
    });

    yield put(actions.syncOfflineResponsesSuccess());
    message.success("Local storage responses synced successfully");
  } catch (error) {
    console.log("Not able to sync local storage responses", error);
    message.success("Local storage responses sync failed");
    Sentry.captureException(error);
    yield put(actions.syncOfflineResponsesFail({ error: error }));
  }
}

export function* submitUserResponseSaga(userResponseData) {
  try {
    console.log("submitUserResponseSaga userResponseData", userResponseData);
    yield put(actions.submitUserResponseStart());
    const appRegion = yield select(appRegionSelector());
    const currentUser = yield select(currentUserSelector());
    const partnerName = yield select(partnerNameSelector());

    const submitUserResponse = {
      student_region: appRegion,
      partner: partnerName,
      user_response: userResponseData,
      user_id: currentUser.id,
    };

    let url = `submit_user_response.json`;

    const response = yield axiosInstance.instance.post(url, submitUserResponse);
    yield put(actions.submitUserResponseSuccess(response.data));

    console.log("submitUserResponse response", response);

    if (response.data) {
      if (response.data.code === "submitted") {
        // success
        if(partnerName !== "osc"){
          // OSC didn't want success message
          message.success(response.data.message);
        }
      } else {
        message.error("Some error occurred in submission");
      }
    }
  } catch (error) {
    console.log("Not able to submit user responses", error);
    message.error("Some error occurred in submission");
    Sentry.captureException(error);
  }
}

export function* setLogsSaga(action) {
  //////////////////////////////////////////////////////////////////////////
  // NOTE:- Send collection = experience for monitor and experienceTake logs and other logs send to org
  /////////////////////////////////////////////////////////////////////////
  const { experienceId, msg, url, log_type, category = "", logging, itemId, collection = "org" } = action.payload;
  console.log("action.payload ===>", action.payload);

  const enabledFeatures = yield select(enabledFeaturesSelector());
  const originalUserRole = localStorage.getItem('originalUserRole');
  console.log("setting logs data==>", action.payload);
  if (originalUserRole === 'superadmin' || originalUserRole === 'support') {
    return;
  }

  // if (options.experienceViewMode === "apPreviewTest") {
  //   return;
  // }
  
  try {
    const appRegion = yield select(appRegionSelector());
    const currentUser = yield select(currentUserSelector());
    console.log("currentUser ====>", currentUser);
    const currentTime = Date.now();
    const internetStatus = yield select(getInternetStatusSelector());
    const userIp = yield select(getUserIpSelector());
    const experience = yield select(activeAdjustedExperienceSelector());
    console.log("userIp =====>", userIp);
    
    
    const orgId = collection == "org" ? currentUser.org_id : experience.org_id
    let batchActionParams = [];
    let logObj = null, logParams = null;

    if (logging === true) {
      // TODO: Remove this after handling org level logs
      if (msg || url) {

        console.log('sending logs here==>1318', userIp, internetStatus);

        logObj = {
          log_id: `${currentUser.id}_${currentTime}`,
          msg: msg,
          log_level: action.payload.log_level || "info",
          log_type: log_type || "user", // NOTE: @shikhar what should be the default log_type value (experience or user or org) 
          category: category,
          user_id: action.payload.user_id || parseInt(currentUser.id), // Action created on user
          user_ids: [action.payload.user_id || parseInt(currentUser.id)],
          user_names: [action.payload.user_name || currentUser.name],
          user_email: currentUser.email,
          by_user_id: currentUser.id, // Action created by user
          internet_status: internetStatus,
          user_ip: userIp || "",
          updated_at: currentTime,
          created_at: currentTime,
          item_id: itemId || "",
          experience_id: experienceId || "",
          org_id: currentUser.org_id, // handle guest user accounts
        };

        console.log("logObj ====>", logObj);
        // if (log_type === "segment") {
        //   logObj = {
        //     ...logObj,
        //     segment_action: action.payload.segmentAction,
        //     segment_type: action.payload.segmentType,
        //     question_type: action.payload.questionType,
        //   };
        //   if (action.payload.segmentAction === "archived") {
        //     logObj = {
        //       ...logObj,
        //       archived_content: action.payload.archivedContent,
        //     };
        //   }
        // }
        if(appRegion !== "china" && url) {
          logObj = {
            ...logObj,
            url: url,
          }
        }
      } else {
        logObj = buildLogObject(currentUser, action.payload, {orgId, experienceId, internetStatus, userIp});
      }

      if (!logObj.msg && !logObj.url) {
        console.error("log message cannot be empty");
        throw new Error("Log message cannot be empty") 
      }
    }

    if (logObj) {
      logParams = {
        // experienceId: experienceId,
        basePath: collection === "org" ? getOrgCollectionPath(orgId, "logs") : getExperienceCollectionPath(experienceId, "logs"),
        docIds: [logObj.log_id],
        tableName: "logs",
        uuids: [logObj.log_id],
        object: {
          ...logObj,
        },
        options: {},
      };

      batchActionParams.push(logParams);
      console.log('batchActionParams==>', batchActionParams);

      realtimeBatchActions(batchActionParams, {
        successCallback: (response) => {
          console.log("Global Batch actions executed successfully", response);
        },
        errorCallback: (error) => {
          console.log("Global Batch actions executed failed", error);
        },
        appRegion: appRegion,
      });
    }

    console.log("batchActionParams ==>", batchActionParams);
    
  } catch (error) {
    console.error("Something went wrong to save user info online ==>", error);
    Sentry.captureException(error);
  }
}

export function* setUserInfoWithLogsSaga(data, options, shouldSaveToRedux = true) {
  console.log("setUserInfoWithLogsSaga ==>", data, options)
  const { experienceId, userInfo, currentUser } = data;

  const { user_id } = userInfo
  const experienceViewMode = yield select(experienceViewModeSelector());
  const currentView = yield select(currentViewSelector);
  // if (!currentUserId || experienceViewMode === "apPreviewTest" || currentView == "submittedTest") {
  //   return;
  // }
  if (currentUser && experienceViewMode !== "apPreviewTest" && currentView !== "submittedTest") {
    try {
      const orgId = currentUser.org_id // experience ? experience.org_id : currentUser.org_id // @shikhar should we save experience org_id?
      const embedded = yield select(getEmbeddedSelector());
      const appRegion = yield select(appRegionSelector());
      const appType = yield select(appTypeSelector());

      let batchActionParams = [];

      let userInfoFB = {}
      if (
        options.internetStatusChanged && 
        userInfo.onlineStatus === "online"
      ) {
        userInfoFB = yield call(getUserInfoFromBackup, {
          experienceId,
          userId: user_id,
        });
      }

      if (currentUser.role === "student" && !embedded) {
        // Saving to offline before online
        saveItemToOfflineDB(
          "userInfo",
          { experience_id: experienceId, user_id: user_id },
          userInfo
        );
      }

      const userInfoParams = {
        basePath: getExperienceCollectionPath(experienceId, "user_info"),
        docIds: [user_id],
        // experienceId: experienceId,
        tableName: "user_info",
        uuids: [`eId${experienceId}_uId${user_id}`],
        object: {
          ...userInfoFB,
          ...userInfo, // current userInfo must always be latest
          user_id: parseInt(user_id), // adding to all user_infos so that RLS in supabase can work with user_id but still needed uuid rule check
        },
        options: { merge: true },
      };
      batchActionParams.push(userInfoParams);
      console.log("userInfoParams in batchActionParams ==>", userInfoParams, userInfo, userInfoFB, options)

      let logObj = null, logParams = null;
      if (options.log && options.log.logging === true) {
        if (options.log.action) {
          const internetStatus = yield select(getInternetStatusSelector());
          const userIp = yield select(getUserIpSelector());
          console.log("userIp =====>", userIp);
          // Use common function to build log object for this and for setLogsSaga
          // Here logs sent in batch with user info so not using setLogsSaga

          logObj = buildLogObject(currentUser, options.log, {orgId, experienceId, internetStatus, userIp});
          logParams = {
            // experienceId: experienceId,
            tableName: "logs",
            uuids: [logObj.log_id],
            basePath: getExperienceCollectionPath(experienceId, "logs"),
            docIds: [logObj.log_id],
            object: {
              ...logObj,
            },
            options: {},
          };
  
          console.log("supabaseDB adding logs to batch ==>", logParams)
          batchActionParams.push(logParams);

          if (currentUser.role == "student" && !embedded) {
            // Saving to offline before online
            // every time new log will be created
            console.log("logObj ====>", logObj);
            saveItemToOfflineDB(
              "logs",
              { log_id: logObj.log_id },
              { experience_id: experienceId, ...logObj }
            );
          }
        } else {
          console.error("log message cannot be empty");
        }
      }      

      yield realtimeBatchActions(batchActionParams, {
        successCallback: (response) => {
          console.log(
            "User info with logs Global Batch actions executed successfully", batchActionParams
          );
        },
        errorCallback: (error) => {
          if (import.meta.env.VITE_MODE === "staging") {
            message.error(`User info with logs Global Batch actions executed failed - ${error.message}`, 10);
            console.error("User info with logs Global Batch actions executed failed", error, batchActionParams);
          }
          if (
            error.message &&
            error.message.indexOf("Unsupported field value: undefined") >= 0
          ) {
            const withoutUndfinedBatchActionParams = JSON.parse(
              JSON.stringify(batchActionParams)
            );
            console.log(
              "withoutUndfinedBatchActionParams",
              withoutUndfinedBatchActionParams
            );
            realtimeBatchActions(withoutUndfinedBatchActionParams, {
              successCallback: () => {
                console.log(
                  "User info with logs Global Batch actions executed successfully 2",
                );
              },
              errorCallback: (error) => {
                console.error(
                  "User info with logs Global Batch actions executed failed 2",
                  error, batchActionParams
                );
              },
              appRegion: appRegion,
            });
          } else {
            console.error(
              "User info with logs Global Batch actions executed failed",
              error, batchActionParams
            );
          }
        },
        appRegion: appRegion,
      });

      if (userInfo.currentView === "endTest") {
        console.log("syncing logs from offline to online ==>", userInfo.currentView, userInfo.onlineStatus)
        // || userInfo.onlineStatus === "online") { 
        // This will sync on refresh too do we want that?
        // This will sync logs from offline to online
        yield call(syncLogs, {
          experienceId: experienceId,
          userId: user_id,
        });
      }

      if (userInfo.currentView === "endTest") { // This will sync on refresh too do we want that?
         // This will sync useResponses from offline to online 
        yield call(getUserResponsesFromBackup, {
          experienceId: experienceId,
          userId: user_id,
        });
      }

    } catch (error) {
      console.error("Something went wrong to save user info online ==>", error);
      Sentry.captureException(error);
    }

  }

  if (shouldSaveToRedux) {
    yield put(actions.setUserInfoSuccess(data));
  }
}

export function* setUserInfoSaga(action) {
  console.log("set user info action ==>", action)
  try {
    let currentTime = Date.now();
    let userInfoData = {
      ...(action.payload.userInfo || {}),
    };
    const currentUser = yield select(currentUserSelector());
    const experienceViewMode = yield select(experienceViewModeSelector());
    const activeExperienceId = yield select(activeExperienceIdSelector);
    const firestoreConnectionClosed = yield select(firestoreConnectionClosedSelector());
    const experienceId = activeExperienceId || action.payload.experienceId;
    const currentUserId = `${currentUser.id}`;
    let options = { experienceViewMode: experienceViewMode, ...action.options };
    let shouldSaveToRedux = true
    console.log("userinfo logs common ==> setUserInfoSaga", userInfoData, activeExperienceId, action.payload.experienceId)
    
    // // Removed yield because for it was blocking every request 
    // yield put(actions.setUserInfoStart());
    let data = {};
    if(firestoreConnectionClosed) {
      return;
    }
    if (
      currentUser.role === "student" ||
      experienceViewMode == "apPreviewTest"
    ) {
      data = {
        userInfo: {
          ...userInfoData,
          user_id: currentUserId,
          student_updated_at: currentTime,
        },
        currentUser,
        experienceId,
      };
    } else if (currentUser.role !== "student") {
      // NOTE: here curentUserId and currentUser are different, because this is triggered by a teacher
      data = {
        userInfo: {
          ...userInfoData,
        },
      };
      if (action.payload.id) {
        data = {
          ...data,
          userInfo: {
            ...data.userInfo,
            user_id: action.payload.id,
            teacher_updated_at: currentTime,
          },
          currentUser,
          experienceId,
        }
        shouldSaveToRedux = false
      }
    }
    
    // // Removed yield beacause it has resolved occurance of multiple calls
    // if (shouldSaveToRedux) {
    //   yield put(actions.setUserInfoSuccess(data));
    // }

    console.log("userinfo logs common ==> setUserInfoSuccess", data, shouldSaveToRedux)
    yield call(setUserInfoWithLogsSaga, data, options, shouldSaveToRedux);
    if (action.options && action.options.successCallback) {
      action.options.successCallback(data);
    }
  } catch (error) {
    console.error("setUserInfoSaga error", error);
    yield put(actions.setUserInfoFail({ error }));
    Sentry.captureException(error);
    if (action.options && action.options.errorCallback) {
      message.error("Something went wrong. Please try again.");
      action.options.errorCallback();
    }
  }
}

export function* getUserResponsesSaga(action) {
  try {
    yield put(actions.getUserResponsesStart());

    const currentUser = yield select(currentUserSelector());
    const activeExperienceId = yield select(activeExperienceIdSelector);

    let userResponsesObj = yield call(getUserResponsesFromBackup, {
      experienceId: activeExperienceId,
      userId: currentUser.id,
    });

    yield put(
      actions.getUserResponsesSuccess({ userResponses: userResponsesObj })
    );
  } catch (error) {
    Sentry.captureException(error);
  }
}

// export function* setCurrentSessionIdSaga(action) {
//   try {
//     const { sessionId } = action.payload;
//     console.log("current session id ")
//     yield put(
//       actions.setCurrentSessionIdSuccess({ sessionId })
//     );
//   } catch (error) {
//     Sentry.captureException(error);
//   }
// }

function formatFirestoreUserResponses(userResponsesFB) {
  console.log(
    "formatFirestoreUserResponses userResponsesFB ==>",
    userResponsesFB,
    typeof userResponsesFB
  );
  const userResponsesObj = {};

  userResponsesFB.forEach((doc) => {
    if (typeof doc.data === "function") {
      console.log("userResponsesFB doc", doc);
      userResponsesObj[doc.id] = doc.data();
    }
  });

  return userResponsesObj;
}

function formatSupabaseUserResponses(userResponsesFB) {
  console.log("userResponsesFB Supabase", userResponsesFB);
  const userResponsesObj = {};
  userResponsesFB.forEach((data) => {
    delete data.id;
    userResponsesObj[data.user_response_id] = data
  });
  return userResponsesObj;
}

///////////////////////////////////////////////////////
// NOTE: getFirebaseToken function is now behaving like a initExperienceTakeDate where we are calling signInFirestore which already setting firebaseToken and isFirebaseAuthenticated
///////////////////////////////////////////////////////
export function* getFirebaseToken(action) {
  try {
    const { options = {} } = action;
    console.log("getFirebaseToken ==>", action);
    yield put(actions.getFirebaseTokenStart());
    const url = "get_realtimedb_token.json";
    const { experienceId, currentStudentGroupIds } = action.payload;
    const currentUser = yield select(currentUserSelector());
    const appRegion = yield select(appRegionSelector());
    const enabledFeatures = yield select(enabledFeaturesSelector());
    const appType = yield select(appTypeSelector());
    const activeExperience = yield select(activeAdjustedExperienceSelector());
    const switchFromStartToResumeView = yield select(switchFromStartToResumeViewSelector())
    const experienceConfigFromRedux = yield select(experienceConfigSelector())

    yield put(actions.setActiveExperienceIdSuccess({ experienceId: experienceId }))

    // let token = null;
    // let refreshToken = null;
    // let fbCurrentUser = null;

    // if (appRegion === "china") {
    //   // not setting session since customJWT anyway so no point checking here, calling to getCustomJWT each time for china, handle expiry in case we change this and want to keep it in LS
    //   // const { data: { session } } = yield supabaseDBWithoutToken.auth.getSession();
    //   // if(session){
    //   //   fbCurrentUser = session.user;
    //   // }
    //   // console.log("supabaseDB session", session);

    //   if (!fbCurrentUser) {
    //     const response = yield call(axiosInstance.instance.get, url, {
    //       params: { test_id: experienceId, region: appRegion },
    //     });
    //     console.log("supabaseDB getCustomJWT", response);
    //     token = response.data.token;

    //     supabaseDB(token) // init and creates supabaseDB that is exported for use as before

    //     // refreshToken = response.data.refresh_token;
    //     // console.log("supabaseDB token response", response);
    //     // // setSession not working
    //     // // const { data, error } = yield supabaseDB.auth.setSession({token: token, refresh_token: refreshToken});
    //     // const { data, error } = yield supabaseDB.auth.refreshSession({refresh_token: refreshToken});


    //     // This token works
    //     // let AUTHORIZATION_TOKEN = "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJhdXRoZW50aWNhdGVkIiwiZXhwIjoxNjgwODUzMzY4LCJzdWIiOiIyOWJjOTJlZi03ODFkLTQyYjMtOTlmYS04NDc0YmIzMzlmYjMiLCJlbWFpbCI6InNoYXJvbkBlZHV2by5jb20iLCJwaG9uZSI6IiIsImFwcF9tZXRhZGF0YSI6eyJwcm92aWRlciI6ImVtYWlsIiwicHJvdmlkZXJzIjpbImVtYWlsIl19LCJ1c2VyX21ldGFkYXRhIjp7fSwicm9sZSI6ImF1dGhlbnRpY2F0ZWQiLCJhYWwiOiJhYWwxIiwiYW1yIjpbeyJtZXRob2QiOiJwYXNzd29yZCIsInRpbWVzdGFtcCI6MTY4MDgxMDE2OH1dLCJzZXNzaW9uX2lkIjoiMzkzOWI4ZTUtMDVkZS00OGFiLWJkMGItNTczMWM2NWM4ZDQyIn0.WYBnqrcc45KiGIYh4lBZAXhCh8h8mpvXVMgfX_VEAys"
    //     // This token doesnt work
    //     // let AUTHORIZATION_TOKEN = "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInVzZXJfaWQiOjI0NSwicmVmIjoieGltYmR3eWVrZm1pb3h4ZWtsaHgiLCJyb2xlIjoiYXV0aGVudGljYXRlZCIsImFwX3JvbGUiOiJ0ZWFjaGVyIiwiaWF0IjoxNjczNDk1OTY1LCJleHAiOjE5ODkwNzE5NjV9.P6NSBWqpqAu9ySVDp8xWnJ1JjyUnP8UVclbSG3O0HiE"

    //     // const supabaseDBNew = window.supabase && window.supabase.createClient(supabaseConf.supabaseUrl, supabaseConf.supabaseKey,
    //     //   { global: { headers: { Authorization: AUTHORIZATION_TOKEN } } }
    //     // )
    //     // const { data, error } = yield supabaseDBNew.auth.getUser()

    //     // const { data, error } = yield supabaseDB.auth.getUser()
    //     // console.log("supabaseDB auth", data, error);

        
    //     // if(error){
    //     //   console.log("supabaseDB setSession error", error);
    //     // }
    //     // if(data){
    //     //   fbCurrentUser = data.user
    //     // }
    //   }
    // } else {
    //   // NOTE: @SHIKHAR we should change this as @ABHI doing for china
    //   // TODO: can avoid this token call if fbCurrentUser exists so check that first
    //   const response = yield call(axiosInstance.instance.get, url, {
    //     params: { test_id: experienceId, region: appRegion },
    //   });
    //   token = response.data.token;
    //   // now that token is new for each test - calling each time - issue is that 1 test then open another test, then come back to 1st test - issues
    //   // fbCurrentUser = yield firebaseAuth.signInWithCustomToken(token);
    //   // fbCurrentUser = fbCurrentUser.user;

    //   // OLD - reverted for now
    //   fbCurrentUser = yield firebaseAuth.currentUser;
    //   if (!fbCurrentUser) {
    //     fbCurrentUser = yield firebaseAuth.signInWithCustomToken(token);
    //     fbCurrentUser = fbCurrentUser.user;
    //   }
    // }
    
    // console.log("supabaseDB fbcurrent user ==>", fbCurrentUser);

    // let finalSuccessPayload = { token };

    // NOTE: use call instead of put if you want to wait for the saga to finish
    yield call(signInToFirestoreSaga, { payload: { experienceId } })
    // const pageReloaded = yield call(checkPageReload);
    // console.log("pageReloaded ==>", pageReloaded)
    let finalSuccessPayload = {}
    
    console.log(
      "experienceId, currentUser, currentUser.id, experienceConfigFromRedux",
      experienceId,
      currentUser,
      currentUser.id,
      experienceConfigFromRedux
    );

    const currentUserId = `${currentUser.id}`;
    // sessioninfo common for china and global
    let newSessionId = getSessionId();
    let appNativeVars = getAppNativeVars(appType);
    const offlineDBStatus = yield select(getOfflineDBStatusSelector())

    const experienceSettings = activeExperience.settings || {}
    // TODO: Only set deviceInfo - no need for appNativeVars - only use to build device info
    let sessionInfo = {
      session_id: newSessionId,
      name: currentUser.name,
      name_lcase: currentUser.name ? currentUser.name.toLowerCase() : "",
      name_by_last_name: currentUser.name_by_last_name ? currentUser.name_by_last_name : "",
      name_by_last_name_lcase: currentUser.name_by_last_name ? currentUser.name_by_last_name.toLowerCase() : "",
      s_code: currentUser.s_code,
      app_type: appType,
      app_native_vars: appNativeVars,
      group_id: currentStudentGroupIds || null,
      offline_db_status: offlineDBStatus,
      guest:
        currentUser.custom_fields["account_type"] == "guest" ? true : false,
      user_id: currentUserId,
      experience_id: experienceId,
      online_priority_keys: onlinePriorityKeys,
    };

    if (options.mobileUploadMode) {
      sessionInfo.mobile_upload_mode = true;
      // once set, not setting to false and opening mobile view always in this mode for this test
    }
    let currentDeviceInfo = getDeviceInfo(appType);

    let userInfoOptions = {};
    if (currentUser.role === "student") {
      // TO reproduce issue of APL-6552 uncomment this
      // yield put(
      //   actions.setUserInfo({}, {
      //     log: {
      //       logging: true,
      //       action: "focus_lost_in_mac",
      //     },
      //   })
      // );

      let userInfoObj = yield call(getUserInfoFromBackup, {
        experienceId,
        userId: currentUserId,
      });

      if (enabledFeatures.student_accommodations) {
        if (!userInfoObj?.accommodations) {
          const accommodationsFromUserCustomFields = currentUser.custom_fields?.accommodations || {}

          const initialAccommodations = {
            ...accommodationsFromUserCustomFields,
            spellcheck: experienceSettings.spellcheck_enabled || accommodationsFromUserCustomFields.spellcheck || false,
            calc_enabled: experienceSettings.calc_enabled || accommodationsFromUserCustomFields.calc_enabled || false,
            graphing_calc_enabled: experienceSettings.graphing_calc_enabled || accommodationsFromUserCustomFields.graphing_calc_enabled || false,
          }

          const experienceDuration = getExperienceDurationForStudent(sessionInfo, experienceSettings)

          sessionInfo.accommodations = initialAccommodations;
          sessionInfo.extra_time = getCurrentExtraTime(sessionInfo, experienceDuration, true)
          console.log("sessionInfo.extra_time===>>>", sessionInfo.extra_time)
        }
      } else {
        sessionInfo.spellcheck_enabled = userInfoObj.spellcheck_enabled ?? (experienceSettings.spellcheck_enabled || false)
        sessionInfo.calc_enabled = userInfoObj.calc_enabled ?? (experienceSettings.calc_enabled || false)
        sessionInfo.graphing_calc_enabled = userInfoObj.graphing_calc_enabled ?? (experienceSettings.graphing_calc_enabled || false)
      }

      yield call(getExperienceInfo, { experienceId });
      let userResponsesObj = yield call(getUserResponsesFromBackup, {
        experienceId,
        userId: currentUserId,
      });

      if (typeof(userInfoObj.allowed_in_browser) === "undefined") {
        if (enabledFeatures.new_join_view_with_dynamic_config) {
          sessionInfo.allowed_in_browser =
          experienceSettings.mode !== "offline"
        } else {
          sessionInfo.allowed_in_browser = 
          experienceSettings.mode !== "offline" ||
          (experienceSettings.mode === "offline" &&
          (activeExperience.student_experience_membership && activeExperience.student_experience_membership.settings.allow_online ? true : false))
        }
      } else {
        sessionInfo.allowed_in_browser = experienceSettings.mode !== "offline" || userInfoObj.allowed_in_browser;
      }
      if (userInfoObj.usedDevices) {
        sessionInfo.usedDevices = [...userInfoObj.usedDevices];
      } else {
        sessionInfo.usedDevices = [];
      }

      let sendLog = false
      let logAction = 
        appType !== "web" 
          ? "opened_in_app" 
          : experienceSettings.mode === "offline" && sessionInfo.allowed_in_browser
            ? "opened_on_browswer_with_online_permission" 
            : "opened_on_browser"

      let logValues = {
        status: "joined",
        app_version: 
          appType === "mac"
            ? `AssessPrep Mac App ${appNativeVars.appVersion}`
            : appType == "ios"
              ? `AssessPrep iOS App ${appNativeVars.appVersion}`
              : appType == "cros"
                ? `Chromebook`
                : `SEB ${appNativeVars.appVersion}`,
        browser_details: currentDeviceInfo.browser,
        os_details: currentDeviceInfo.os + " " + currentDeviceInfo.osVersion,
      }
      // userInfoObj = undefined when first time
      if (userInfoObj) {
        // TODO: Currently not setting userInfoObj.currentView = "viewFeedback" from backend when change/publishing grading, but we should  
        if (userInfoObj.currentView !== "submittedTest" && userInfoObj.currentView !== "viewFeedback") { // Checking if test is submitted and opened as viewFeedback to avoid logging
          console.log("userInfoObj.currentView =====>", userInfoObj.currentView, experienceConfigFromRedux.takeExperience.firstView);
          if (
            userInfoObj.currentView === undefined ||
            userInfoObj.currentView === ""
          ) {
            // sessionInfo.currentView = "joined";
            if (!isEmpty(userInfoObj)) {
              console.log("userInfoObj ==>", userInfoObj);
              throw new Error("User info should be empty for first time while setting currentView to joined");
            } else {
              sessionInfo.currentView = experienceConfigFromRedux.takeExperience.firstView;
            }
          }
          // Detect if device has changed from existing and then set logging to true with message with device info. Update main device info to show current (sessionInfo.deviceInfo = getDeviceInfo()). Adjust message for upload mode if mobile_upload_mode and device is checkMob and checkpad
          const isSwitchToResumeViewForViews = ["startTest", "endTest"].includes(userInfoObj.currentView)
          if (
            (userInfoObj.mobile_upload_mode ||
              (options.mobileUploadMode)) &&
            ((checkIPAD() && appType !== "ios") || checkMob())
          ) {
            sessionInfo.app_type = userInfoObj.app_type; // don't change it
            // if(userInfoObj.uploadDeviceInfo !== currentDeviceInfo){
            // NOTE: we need outside for mobile refresh and multiple tab in mobile cases
            sessionInfo.uploadDeviceInfo = currentDeviceInfo;
            if (
              !userInfoObj.uploadDeviceInfo ||
              userInfoObj.uploadDeviceInfo.os !== currentDeviceInfo.os ||
              userInfoObj.uploadDeviceInfo.browser !== currentDeviceInfo.browser
            ) {
              
              sessionInfo.usedDevices.push(currentDeviceInfo);
              sendLog = true
              logAction = "opened_in_upload_mode"
            }
          } else {
            if (
              isSwitchToResumeViewForViews &&
              switchFromStartToResumeView === true &&
              // experienceViewMode != "apPreviewTest" &&
              (activeExperience && 
              activeExperience.settings &&
              activeExperience.settings.mode === "offline" &&
              (appType === "seb" || appType === "mac" || appType === "ios" || appType === "cros") && window.location.search.indexOf("firstLoad") > -1)
            ) {
              userInfoObj.currentView = "resumeTest"
              sendLog = true
              logValues = {
                ...logValues,
                status: "re-entered",
              }
            }

            if (
              !userInfoObj.deviceInfo ||
              userInfoObj.deviceInfo.os !== currentDeviceInfo.os ||
              userInfoObj.deviceInfo.browser !== currentDeviceInfo.browser ||
              userInfoObj.deviceInfo.appVersion !== currentDeviceInfo.appVersion
            ) {
              console.log(
                "userInfoObj.deviceInfo - sending log",
                userInfoObj.deviceInfo,
                currentDeviceInfo
              );
              sessionInfo.deviceInfo = currentDeviceInfo;
              sessionInfo.usedDevices.push(currentDeviceInfo);
              sendLog = true
            }
          }

          if (enabledFeatures.new_join_view_with_dynamic_config) {
            const sessionIdFromStorage = yield call(getOrCreateSessionId, newSessionId)

            // console.log("sessionIdFromStorage =====>", 
            // {
            //   sessionIdFromStorage,
            //   uploadDeviceInfo: sessionInfo.uploadDeviceInfo,
            //   userInfoObjSessionId: userInfoObj.session_id,
            // });

            // message.error(`sessionIdFromStorage. ${JSON.stringify(sessionIdFromStorage)}`);
            // console.log("sessionIdFromStorage ==>", sessionInfo, userInfoObj);
            const isMobileUploadMode = userInfoObj.mobile_upload_mode || options.mobileUploadMode;
            const isNonIosDevice = (checkIPAD() && appType !== "ios") || checkMob();

            if (!(isMobileUploadMode && isNonIosDevice)) {
              if (!sessionInfo.uploadDeviceInfo) {
                sessionInfo.deviceInfo = {
                  ...currentDeviceInfo,
                  session_id: sessionIdFromStorage,
                };
              }
            }
            if (sessionInfo.uploadDeviceInfo) {
              sessionInfo.uploadDeviceInfo.upload_session_id = sessionIdFromStorage;
              delete sessionInfo.session_id

            } else if (userInfoObj.session_id) {
              delete sessionInfo.session_id
              if (isSwitchToResumeViewForViews) {
                const isSameSession = userInfoObj.session_id === sessionIdFromStorage;
                const shouldResumeTest = isSameSession ? 
                  (switchFromStartToResumeView && window.location.search.includes("firstLoad")) :
                  true;

                if (shouldResumeTest) {
                  userInfoObj.currentView = "resumeTest";
                  sendLog = true;
                  logValues = {
                    ...logValues,
                    status: "re-entered",
                  };
                }
              }
            }
          }
        }
        if(userInfoObj.currentView == "submittedTest" || userInfoObj.currentView == "viewFeedback"){
          // When student views result, it overrides these 2 and then shows incorrect deviceInfo in monitor list
          delete(sessionInfo.app_type)
          delete(sessionInfo.app_native_vars)
        }
      } else {
        // sessionInfo.currentView = "joined";
        sessionInfo.currentView = experienceConfigFromRedux.takeExperience.firstView;
        // for first time start
        sendLog = true
        sessionInfo.deviceInfo = currentDeviceInfo;
      }

      sessionInfo = {
        ...userInfoObj,
        ...sessionInfo,
      };

      userInfoOptions = {
        log: {
          logging: sendLog,
          action: logAction,
          current_value: logValues
        },
      };

      // let userInfoToSetData = {
      //   // userInfoData: sessionInfo,
      //   userInfo: sessionInfo,
      //   experienceId,
      //   currentUser,
      //   currentUserId,
      // };
      // console.log("userinfo logs common ==> before getFirebaseTokenSuccess", userInfoToSetData)
      // if (
      //   !userInfoObj ||
      //   (userInfoObj && userInfoObj.currentView !== "submittedTest")
      // ) {
      //   // deviceinfo and apptype was getting updated even when student opened the test after submission - to view feedback
      //   // yield setUserInfoWithLogsSaga(userInfoToSetData, userInfoOptions);
      //  yield put(
      //    actions.setUserInfo(userInfoToSetData, userInfoOptions)
      //  );
      // }

      finalSuccessPayload = {
        ...finalSuccessPayload,
        userInfo: { ...sessionInfo },
        userResponses: { ...userResponsesObj },
      };
      
      // TODO: set logs when @NITIN moves setLog outside of setUserInfoWithLogsSaga
      yield put(actions.setLogs({
        logging: offlineDBStatus != "open",
        msg: `Local backups not available. ${(offlineDBStatus === "closed" && "(Failed to open connection)") || (offlineDBStatus === "not_supported" && "(Not supported by browser)")}`,
        category: "monitor_student_actions",
        experienceId: experienceId,
        itemId: experienceId,
        collection: "experience",
        log_type: "experience", // NOTE:- We should be sending log_type, default is setting to user define category
      }))
    }

    let userInfoToSetData = {
      // userInfoData: sessionInfo,
      userInfo: sessionInfo,
      experienceId,
      currentUser,
      currentUserId,
    };

    //NOTE: Setting user info in all cases - Teacher Preview, View result and Exam mode - handling firstore update in setUserInfoWithLogsSaga
    yield put(
      actions.setUserInfo(userInfoToSetData, userInfoOptions)
    );

    finalSuccessPayload = {
      ...finalSuccessPayload,
      experienceId,
    };
    console.log("finalSuccess ===>", finalSuccessPayload);

    // Creating realtime connection from firestore db
    if (action.options && action.options.callback) {
      yield action.options.callback({
        experienceId,
        currentUser: currentUser,
      });
    }
    
    // yield put(actions.setUserInfo({
    //   log: {
    //     logging: offlineDBStatus != "open",
    //     msg: `Local backups not available (${offlineDBStatus == "failed" && "Failed" || offlineDBStatus == "not_supported" && "Not supported"} supported by browser)`,
    //   },
    // });

    yield put(actions.setCurrentSessionIdSuccess({ sessionId: newSessionId }));
    yield put(actions.getFirebaseTokenSuccess(finalSuccessPayload));

  } catch (e) {
    console.error("error getFirebaseToken", e);
    yield put(actions.getFirebaseTokenFail({ error: e }));
    Sentry.captureException(e);
  }
}

export function getOrCreateSessionId(cId) {
  try {
    let storedId = sessionStorage.getItem("currentSessionId");

    if (!storedId) {
      storedId = localStorage.getItem("currentSessionId");
    }

    if (!storedId && cId) {
      storedId = cId;
      try {
        sessionStorage.setItem("currentSessionId", storedId);
      } catch (sessionError) {
        console.error("sessionStorage unavailable, using localStorage", sessionError);
        localStorage.setItem("currentSessionId", storedId);
        Sentry.captureException(sessionError);
      }
    }

    return storedId;
  } catch (error) {
    console.error("Error accessing storage", error);
    Sentry.captureException(error);
    return "";
  }
}


export const actions = reduxCrud.getActions();
export const actionTypes = reduxCrud.getActionTypes();

// TODO:
function* getActionWatchTypeOptions() {
  let options = {};
  const appRegion = yield select(appRegionSelector());
  options = {
    // IMP: to override if we want takeEvery for a particular action. Default is takeLatest in framework
    [actionTypes.SET_USER_RESPONSE_FIREBASE]: {
      watchType: appRegion === "china" ? "takeEvery" : "takeLatest",
      // watchType: "takeEvery",
    },
    [actionTypes.SET_USER_INFO_FIREBASE]: {
      // watchType: appRegion === "china" ? "takeEvery" : "takeLatest",
      watchType: "takeEvery",
    },
  };
  // if (appRegion === "china") {
  // }
  return options;
}

export const watchFirebase = reduxCrud.generateWatchSaga(
  {
    baseUrl: "",
    GET_FIREBASE_TOKEN_FIREBASE: getFirebaseToken,
    [actionTypes.SET_USER_RESPONSE_FIREBASE]: setUserResponseSaga,
    // [actionTypes.SET_USER_RESPONSE_FIREBASE]: debounceSetUserResponseSaga,
    [actionTypes.SET_USER_INFO_FIREBASE]: setUserInfoSaga,
    [actionTypes.SET_LOGS_FIREBASE]: setLogsSaga,
    [actionTypes.SET_EXPERIENCE_INFO_FIREBASE]: setExperienceInfoSaga,
    [actionTypes.GET_EXPERIENCE_INFO_FIREBASE]: getExperienceInfo,
    [actionTypes.GET_ORG_AI_USAGE_INFO_FIREBASE]: getOrgAiUsageInfo,
    [actionTypes.SUBMIT_EXPERIENCE_FIREBASE]: submitExperienceSaga,
    [actionTypes.SYNC_OFFLINE_RESPONSES_FIREBASE]: syncOfflineResponsesSaga,
    [actionTypes.SUBMIT_USER_RESPONSE_FIREBASE]: submitUserResponseSaga,
    [actionTypes.GET_USER_RESPONSES_FIREBASE]: getUserResponsesSaga,
    [actionTypes.TEACHER_SUBMIT_EXPERIENCE_FIREBASE]: teacherSubmitExperienceSaga,
    [actionTypes.SIGN_IN_TO_FIRESTORE_FIREBASE]: signInToFirestoreSaga,
    // [actionTypes.SET_CURRENT_SESSION_ID_FIREBASE]: setCurrentSessionIdSaga,

    // SET_USER_INFO_FIREBASE: setUserInfoSaga
  },
  {
    // callback
  },
  getActionWatchTypeOptions()
  // {
  // options
  // IMP: to override if we want takeEvery for a particular action. Default is takeLatest in framework
  // [actionTypes.SET_USER_RESPONSE_FIREBASE]: {
  //   watchType: "takeEvery"
  // },
  // [actionTypes.SET_USER_INFO_FIREBASE]: {
  //   watchType: "takeEvery"
  // },
  // }
);

export const reducer = (state = initialState, action) => {
  const { payload } = action;
  console.log("inside reducer==>", action);
  switch (action.type) {
    case actionTypes.SIGN_IN_TO_FIRESTORE_FIREBASE_START:
      return state
        .set("signInToFirestoreLoading", true)
        .set("firebaseToken", null)
        .set("isFirebaseAuthenticated", false);
    case actionTypes.SIGN_IN_TO_FIRESTORE_FIREBASE_SUCCESS:
      return state
        .set("signInToFirestoreLoading", false)
        .set("firebaseToken", payload.token)
        .set("isFirebaseAuthenticated", true)
    case actionTypes.SIGN_IN_TO_FIRESTORE_FIREBASE_FAIL:
      return state
        .set("signInToFirestoreError", action.payload.error)
        .set("isFirebaseAuthenticated", false);
    ///////////////////////////////////////////////////////
    // NOTE: getFirebaseToken function is now behaving like a initExperienceTakeDate where we are calling signInFirestore which already setting firebaseToken and isFirebaseAuthenticated
    ///////////////////////////////////////////////////////
    case actionTypes.GET_FIREBASE_TOKEN_FIREBASE_START:
      return state
        .set(`getFirebaseTokenLoading`, true)
        // .set("firebaseToken", null)
        // .set("isFirebaseAuthenticated", false);
    case actionTypes.GET_FIREBASE_TOKEN_FIREBASE_SUCCESS:
      let newState = state
        .set(`getFirebaseTokenLoading`, false)
        // .set("firebaseToken", payload.token)
        // .set("isFirebaseAuthenticated", true)
        // .set("activeExperienceId", payload.experienceId);

      // if (payload.userInfo && payload.userInfo.session_id) {
      //   newState = newState.set(
      //     "currentSessionId",
      //     payload.userInfo.session_id
      //   );
      // }
      if (payload.userResponses) {
        newState = newState.set("responses", Map(payload.userResponses));
      }
      return newState;
    case actionTypes.GET_FIREBASE_TOKEN_FIREBASE_FAIL:
      return state
        .set(`getFirebaseTokenLoading`, false)
        .set("getFirebaseTokenError", action.payload.error)
        // .set("isFirebaseAuthenticated", false);
    case actionTypes.SET_CURRENT_SESSION_ID_FIREBASE_SUCCESS:
      let newCurrentSessionState = state.set(
        `currentSessionId`,
        action.payload.sessionId
      );
      console.log("current session id in SET_CURRENT_SESSION_ID_FIREBASE_SUCCESS ==>", action.payload)

      return newCurrentSessionState;
    case actionTypes.SET_ACTIVE_EXPERIENCE_ID_FIREBASE_SUCCESS:
        return state.set(`activeExperienceId`, action.payload.experienceId);
    case actionTypes.TEACHER_SUBMIT_EXPERIENCE_FIREBASE_START:
      return state.set(`teacherSubmitLoading`, action.payload.id);
    case actionTypes.TEACHER_SUBMIT_EXPERIENCE_FIREBASE_SUCCESS:
      return state.set("teacherSubmitLoading", false);
    case actionTypes.TEACHER_SUBMIT_EXPERIENCE_FIREBASE_FAIL:
      return state.set(`teacherSubmitLoading`, false);
    case actionTypes.SET_ACTIVE_CHAT_FIREBASE_START:
      return state.set(`setActiveChatLoading`, true);
    case actionTypes.SET_ACTIVE_CHAT_FIREBASE_SUCCESS:
      return state
        .set("setActiveChatLoading", false)
        .set("activeChats", [{ ...action.payload }]);
    case actionTypes.SET_ACTIVE_CHAT_FIREBASE_FAIL:
      return state.set(`setActiveChatLoading`, false);
    case actionTypes.REMOVE_ACTIVE_CHAT_FIREBASE_START:
      return state.set(`removeActiveChatLoading`, true);
    case actionTypes.REMOVE_ACTIVE_CHAT_FIREBASE_SUCCESS:
      const activeChats = state.get("activeChats", []);
      const idxToRemove = activeChats.findIndex(
        (item) => item.id === action.payload.id
      );
      let newChatState = state.set("removeActiveChatLoading", false);
      if (idxToRemove !== -1) {
        activeChats.splice(idxToRemove, 1);
        newChatState = newChatState.set("activeChats", [...activeChats]);
      }
      return newChatState
    case actionTypes.REMOVE_ACTIVE_CHAT_FIREBASE_FAIL:
      return state.set(`removeActiveChatLoading`, false);
    case actionTypes.GET_USER_RESPONSES_FIREBASE_START:
      return state.set(`getUserResponsesLoading`, true);
    case actionTypes.GET_USER_RESPONSES_FIREBASE_SUCCESS:
      return state
        .set(`responses`, Map(action.payload.userResponses))
        .set("getUserResponsesLoading", false);
    case actionTypes.GET_USER_RESPONSES_FIREBASE_FAIL:
      return state.set(`getUserResponsesLoading`, false);
    case actionTypes.UPDATE_ONLINE_STATUS_FIREBASE_SUCCESS:
      return state.set(`onlineStatus`, action.payload.status);
    case actionTypes.SET_USER_RESPONSE_CONFIG_FIREBASE_SUCCESS:
      return state.set("userResponseConfig", action.payload.userResponseConfig);
    case actionTypes.SET_USER_RESPONSE_FIREBASE_START:
      return state.set(`setUserResponseLoading`, true);
    case actionTypes.SET_USER_RESPONSE_FIREBASE_SUCCESS:
      const { userResponseId, userResponse } = action.payload;
      return state.setIn(["responses", userResponseId], userResponse, Map({}));
    case actionTypes.SET_USER_RESPONSE_FIREBASE_FAIL:
      return state
        .set(`setUserResponseLoading`, false)
        .set("error", action.payload.error);
    // case actionTypes.SET_EXPERIENCE_INFO_FIREBASE_SUCCESS:
    //   return state.set('experience_info', action.payload);
    case actionTypes.SET_USER_INFO_FIREBASE_START:
      return state.set(`setUserInfoLoading`, true);
    case actionTypes.SET_USER_INFO_FIREBASE_SUCCESS:
      const { userInfo, merge = true } = action.payload;
      console.log("userinfo logs common ==> action.payload ==>", userInfo);
      if (action.payload && userInfo) {
        let existingUserInfo = state.get("user_info", {});
        let newUserInfo = {}
        
        if (merge) {
          newUserInfo = { 
            ...existingUserInfo,
            ...userInfo,
          }
        } else {
          newUserInfo = {
            ...userInfo
          }
        }
        let newUserInfoState = state
          .set(`setUserInfoLoading`, false)
          .set("user_info", newUserInfo);
        return newUserInfoState;
      } else {
        return state.set(`setUserInfoLoading`, false);
      }
    case actionTypes.SET_USER_INFO_FIREBASE_FAIL:
      return state
        .set(`setUserInfoLoading`, false)
        .set("error", action.payload.error);
    case actionTypes.SUBMIT_EXPERIENCE_FIREBASE_START:
      return state.set(`submitExperienceLoading`, true);
    case actionTypes.SUBMIT_EXPERIENCE_FIREBASE_SUCCESS:
      return state.set(`submitExperienceLoading`, false);
    case actionTypes.SUBMIT_EXPERIENCE_FIREBASE_FAIL:
      return state
        .set(`submitExperienceLoading`, false)
        .set("error", action.payload.error);
    case actionTypes.SAVING_STATUS_FIREBASE_SUCCESS:
      const { segment_id, status } = action.payload;
      const savingStatus = state.get("savingStatus", {});
      const newStatus = {
        ...savingStatus[segment_id],
        ...status,
      };

      return state.set("savingStatus", {
        ...savingStatus,
        latest: newStatus,
        [segment_id]: newStatus,
      });
    case actionTypes.SET_EXPERIENCE_INFO_FIREBASE_START:
      return state.set(`experienceInfoLoading`, true);
    case actionTypes.SET_EXPERIENCE_INFO_FIREBASE_FAIL:
      return state.set(`experienceInfoLoading`, false);
    case actionTypes.SET_EXPERIENCE_INFO_FIREBASE_SUCCESS:
      console.log("running in the state==>", state);
      let currentState = state.get("experience_info", {});
      let newExperienceInfo = {
        ...currentState,
        ...action.payload,
      };
      if(action.options.reset) {
        newExperienceInfo = {}
      }
      console.log(
        "state in exp info==>",
        currentState,
        newExperienceInfo,
        action.payload
      );
      return state
        .set(`experience_info`, newExperienceInfo)
        .set(`experienceInfoLoading`, false);

    case actionTypes.GET_EXPERIENCE_INFO_FIREBASE_START:
      return state.set(`experienceInfoLoading`, true);
    case actionTypes.GET_EXPERIENCE_INFO_FIREBASE_FAIL:
      return state.set(`experienceInfoLoading`, false);
    case actionTypes.GET_ORG_AI_USAGE_INFO_FIREBASE_START:
      return state.set(`orgStatsInfoLoading`, true);
    case actionTypes.GET_ORG_AI_USAGE_INFO_FIREBASE_FAIL:
      return state.set(`orgStatsInfoLoading`, false);
    case actionTypes.GET_ORG_AI_USAGE_INFO_FIREBASE_SUCCESS:
      return state.set(`ai_usage_info`, action.payload.stats)
    // case actionTypes.GET_EXPERIENCE_INFO_FIREBASE_SUCCESS:
    //   // let currentState = state.get("experience_info");
    //   // let newExperienceInfo = {
    //   //   ...currentState,
    //   //   ...action.payload,
    //   // };
    //   // console.log(
    //   //   "state in exp info==>",
    //   //   JSON.stringify(currentState),
    //   //   JSON.stringify(newExperienceInfo),
    //   // );
    //   // return state.set(`experience_info`, newExperienceInfo);
    // .set("error", action.payload.error);
    case actionTypes.SUPPORT_OLD_USER_RESPONSE_ID_FIREBASE_SUCCESS:
      console.log("supportOldUserResponseIdSuccess ==>", action.payload);
      return state.set(
        "supportOldUserResponseId",
        action.payload.supportOldUserResponseId
      );
    case actionTypes.SET_PRESENCE_DATA_FIREBASE_SUCCESS:
      const { presenceData } = action.payload;
      const { shouldMerge = true } = action.options;
      console.log("socketIO - presenceData logs common ==> action.payload ==>", action);
      if (action.payload && presenceData) {
        let existingPresenceData = state.get("presence_data", {});
        let newPresenceData = {}
        
        if (shouldMerge) {
          newPresenceData = { 
            ...existingPresenceData,
            ...presenceData,
          }
        } else {
          newPresenceData = {
            ...presenceData
          }
        }
        let newPresenceDataState = state
          // .set(`setUserInfoLoading`, false)
          .set("presence_data", newPresenceData);
        return newPresenceDataState;
      } 
      else {
        // return state.set(`setUserInfoLoading`, false);
        return state.set("error", action.payload.error);
      }
    case actionTypes.SET_PRESENCE_DATA_FIREBASE_FAIL:
      return state
        // .set(`setUserInfoLoading`, false)
        .set("error", action.payload.error);
    case actionTypes.SET_FIRESTORE_CONNECTION_CLOSED_FIREBASE_SUCCESS: 
      return state.set("firestoreConnectionClosed", action.payload.firestoreConnectionClosed);
    case actionTypes.SET_SESSION_SWITCHED_FIREBASE_SUCCESS:
      return state.set("sessionSwitched", action.payload.sessionSwitched);
    case actionTypes.SYNC_OFFLINE_RESPONSES_FIREBASE_START:
      return state.set("syncOfflineResponsesLoading", true);
    case actionTypes.SYNC_OFFLINE_RESPONSES_FIREBASE_SUCCESS:
      return state.set("syncOfflineResponsesLoading", false);
    case actionTypes.SYNC_OFFLINE_RESPONSES_FIREBASE_FAIL:
      return state.set("syncOfflineResponsesLoading", false);
    default:
      console.log("default action.type1", action.type);
      return state;
  }
};

export default reduxCrud;
