import { Button, Checkbox, Modal } from "antd";
import React, { useEffect, useMemo, useState } from 'react';
import "./BulkImportGroups.scss";
import FileUploadSteps from "../../Users/BulkImportUsers/FileUploadSteps";
import { useDispatch, useSelector } from "react-redux";
import { currentUserSelector } from "/src/views/Auth/Login/selector";
import { RenderTable, updateArrayWithObjectKeys } from "/src/views/Users/BulkImportUsers/BulkImportUsers";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { actions as orgActions } from "/src/views/Orgs/redux";
import ImportStatus from "../../Users/BulkImportUsers/ImportStatus";
import { uniq } from "lodash";

const BulkImportGroups = ({singleResourceName, org, isMenuItem}) => {
  const [showModal, setShowModal] = useState(false);
  const [bulkImportConfig, setBulkImportConfig] = useState({
    programId: null,
    currentPage: 1,
    file: null,
    role: "student",
    start_term: null,
    end_term: null,
  });

  const [excelData, setExcelData] = useState([]);
  const [dataMapping, setDataMapping] = useState({});
  const [disableNextButton, setDisableNextButton] = useState(true)
  const [skipSubjects, setSkipSubjects] = useState(false)
  const [loading, setLoading] = useState(false);
  const [updatedExcelData, setUpdatedExcelData] = useState([]);
  const [bulkImportInfo, setBulkImportInfo] = useState({});

  const mrIntl = useTranslate();
  const modalTitles = ["Bulk add or update classes", "Field Mapping", "Grade Mapping", "Subject Group Mapping", "Subject Mapping", "Preview import data",  "Import status"];
  const tableHeaders = ["Class name", "Grade Level", "Subject Group", "Subject", "Email", "Role"];
  const currentUser = useSelector(currentUserSelector());
  const currentOrg = (currentUser.role === "superadmin" ? org : currentUser.org) || {};
  const currentOrgId = currentOrg.id;
  const currentOrgProgrammes = currentOrg.org_programmes || [];
  
  
  // ?? This operator returns the right-hand side value if the left-hand side is null or undefined. If subjects is undefined, an empty array will be returned.
  const currentOrgProgrammeSubjects = currentOrgProgrammes.find(program => program.id === bulkImportConfig.programId)?.subjects ?? [];

  const dispatch = useDispatch();
  const withoutGroupSubjects = currentOrgProgrammeSubjects.filter(subject => subject.type_c !== "group");
  const allSubjectGroups = currentOrgProgrammeSubjects.filter(subject => subject.type_c === "group");
  const totalPage = 7;

  const gradeOptions = useMemo(() => {
    const currentOrgProgrammeGrades = currentOrgProgrammes.find(program => program.id === bulkImportConfig.programId)?.grades ?? [];
    
    return currentOrgProgrammeGrades.map(grade => ({ label: grade.label, value: grade.label }));
  }, [bulkImportConfig.programId]);  

  let getGradeData = useMemo(() => {
    const gradeName = dataMapping.mappedHeader?.["Grade Level"];
    
    if (excelData.length === 0 || !gradeName) {
      return [];
    }
  
    const gradeIndexInTable = excelData[0].indexOf(gradeName);
  
    if (gradeIndexInTable === -1) {
      return [];
    }
    
    // we are doing slice(1) to remove the header row and then we map over the rows to get the grade value from the gradeIndexInTable
    return excelData.slice(1)
      .map(row => row[gradeIndexInTable])
      .filter(value => value !== undefined);
  
  }, [excelData, dataMapping]);

  const subjectOptions = withoutGroupSubjects.map(subject => ({ label: subject.name, value: subject.id }));
  const subjectGroupsOptions = allSubjectGroups.map(subject => ({ label: subject.label, value: subject.label }));

  const getExportedData = (headerName, data) => {
    const headerIndex = data[0]?.findIndex(header => header === headerName);
    if (headerIndex === -1) return [];
    const newData = [];
    data.slice(1).forEach(row => {
      if(row[headerIndex]) {
        newData.push(row[headerIndex])
      }
    })

    return newData;
  };

  let subjectExportData = [];
  let subjectGroupExportData = [];

  if (excelData && excelData.length > 0 && dataMapping.mappedHeader) {
    if (dataMapping.mappedHeader['Subject']) {
      subjectExportData = getExportedData(dataMapping.mappedHeader['Subject'], excelData);
      console.log("subjectExportData =====>", subjectExportData, subjectOptions, dataMapping);
    }

    if (dataMapping.mappedHeader['Subject Group']) {
      subjectGroupExportData = getExportedData(dataMapping.mappedHeader['Subject Group'], excelData);
      console.log("subjectGroupExportData =====>", subjectGroupExportData, subjectGroupsOptions, dataMapping);
    }
  }

  const handleMenuItemClick = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    // clearLocalState()
  };

  const handleModalBack = () => {
    const { currentPage } = bulkImportConfig;
  
    if (currentPage <= 1) return;
  
    setBulkImportConfig(prevConfig => ({
      ...prevConfig,
      currentPage: currentPage - 1,
    }));
  };  

  const handleSkipSubjects = (checked) => {
    setDisableNextButton(!checked);
    setSkipSubjects(checked)
  }

  useEffect(() => {
    validateError();
  }, [bulkImportConfig, dataMapping]);

  useEffect(() => {
    let interval = null;
    let retries = 0;
    // let delay = 300;
    let delay = 10;
    if (loading) {
      interval = setInterval(() => {
        dispatch(
          orgActions.importStatus({
            id: currentOrgId,
            type_c: "classes"
          }, {
            successCallback: (response) => {
              console.log("response =====>", response);
              if (response.bulk_import_info.classes_import_status === "completed") {
                setBulkImportInfo(response.bulk_import_info);
                setLoading(false);
                if (interval) {
                  clearInterval(interval);
                }
              }
            }
          })
        )
        retries += 1;
        if (retries % 10 === 0) {
          // console.log( "updating delay" );
          delay += 30;
        }
        if (retries > 20) {
          if (interval) {
            clearInterval(interval);
          }
        }
      }, delay * 1000);
    } else {
      if (interval) {
        clearInterval(interval);
      }
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [loading]);
  
  const validateError = () => {
    switch (bulkImportConfig.currentPage) {
      case 1:
        if (bulkImportConfig.file && bulkImportConfig.start_term && bulkImportConfig.end_term && (bulkImportConfig.programId)) {
          console.log("isHeadersMapped ==>>", bulkImportConfig, dataMapping);
          setDisableNextButton(false);
        } else {
          setDisableNextButton(true);
        }
        break;
      case 2:
        const isHeadersMapped = Object.keys(dataMapping.mappedHeader || {}).length === tableHeaders.length;
        console.log("isHeadersMapped ==>>", (dataMapping.mappedHeader || {}).length, tableHeaders.length, isHeadersMapped);
        if (isHeadersMapped) {
          setDisableNextButton(false);
        } else {
          setDisableNextButton(true);
        }
        break;
      case 3:

        const isGradeMapped = Object.keys(dataMapping.mappedGrade || {}).length >= uniq(getGradeData).length;
        if (isGradeMapped) {
          setDisableNextButton(false);
        } else {
          setDisableNextButton(true);
        }
        break;
      case 4:
        const isSubjectGroupMapped = (Object.keys(dataMapping.mappedSubjectGroups || {}).length >= (uniq(subjectExportData).length > 4 ? 4 : uniq(subjectExportData).length)) || skipSubjects;
        if (isSubjectGroupMapped) {
          setDisableNextButton(false);
        } else {
          setDisableNextButton(true);
        }
        break;
      case 5:
        const isSubjectMapped = (Object.keys(dataMapping.mappedSubject || {}).length >= (uniq(subjectExportData).length > 4 ? 4 : uniq(subjectExportData).length)) || skipSubjects;
        if (isSubjectMapped) {
          setDisableNextButton(false);
        } else {
          setDisableNextButton(true);
        }
        break;
      default:
        setDisableNextButton(false);
    }
  };

  const clearLocalState = () => {
    setExcelData([]);
    setDataMapping({});
    setUpdatedExcelData([]);
    setBulkImportInfo({});
    getGradeData = []
    setSkipSubjects(false)
    setDisableNextButton(true)
  }

  const renderModalContent = () => {
    const modalContent = [
      <FileUploadSteps
        bulkImportConfig={bulkImportConfig}
        setBulkImportConfig={setBulkImportConfig}
        setExcelData={setExcelData}
        key="file-upload"
        singleResourceName={singleResourceName}
        clearLocalState={clearLocalState}
        setDisableNextButton={setDisableNextButton}
        currentOrgId={currentOrgId}
      />
    ];

    if (excelData.length > 0) {
      modalContent.push(
        <div key="mapping-column-header">
          <p>{mrIntl("BulkImportGroups.please_map_the_required_fields_on_assessprep_with_the_column")}</p>
          <RenderTable
            leftSide={tableHeaders}
            excelData={excelData[0]}
            setDataMapping={setDataMapping}
            dataMapping={dataMapping}
            columnNames={["AssessPrep Fields", "Uploaded File Column Headers"]}
            key="mapped-header"
            mappingKey="mappedHeader"
            mode="single"
          />
        </div>
      );
      modalContent.push(
        <div key="mapping-grade">
          <p>{mrIntl("BulkImportGroups.map_the_grades_from_assessprep_to_the_corresponding_grade_values")}</p>
          <RenderTable
            leftSide={gradeOptions}
            excelData={getGradeData}
            setDataMapping={setDataMapping}
            dataMapping={dataMapping}
            columnNames={["AssessPrep Grades", "Uploaded File Grades"]}
            key="mapped-grade"
            mappingKey="mappedGrade"
            mode="multiple"
          />
        </div>
      );
      modalContent.push(
        <div key="mapped-subject-groups">
          <p>{mrIntl("BulkImportGroups.map_the_subject_groups_from_assessprep_to_the_corresponding_subject")}</p>
          <RenderTable
            leftSide={subjectGroupsOptions}
            excelData={subjectGroupExportData}
            setDataMapping={setDataMapping}
            dataMapping={dataMapping}
            columnNames={["AssessPrep Subjects", "Uploaded File Subject groups"]}
            key="mapped-subject-groups"
            mappingKey="mappedSubjectGroups"
            mode="multiple"
          />
          <Checkbox
            onChange={(e) => handleSkipSubjects(e.target.checked)}
            checked={skipSubjects}
          >
            {mrIntl("BulkImportGroups.skip_subject_group_mapping")}
          </Checkbox>
          <p>{mrIntl("BulkImportGroups.note_if_you_skip_this_step_classes_will_be_created")}</p>
        </div>
      );
      modalContent.push(
        <div key="mapped-subject-table">
          <p>{mrIntl("BulkImportGroups.map_the_subjects_from_assessprep_to_the_corresponding_subject_values")}</p>
          <RenderTable
            leftSide={subjectOptions}
            excelData={subjectExportData}
            setDataMapping={setDataMapping}
            dataMapping={dataMapping}
            columnNames={["AssessPrep Subjects", "Uploaded File Subjects"]}
            key="mapped-subject"
            mappingKey="mappedSubject"
            mode="multiple"
          />
          <Checkbox
            onChange={(e) => handleSkipSubjects(e.target.checked)}
            checked={skipSubjects}
          >
            {mrIntl("BulkImportGroups.skip_subject_mapping")}
          </Checkbox>
          <p>{mrIntl("BulkImportGroups.note_if_you_skip_this_step_classes_will_be_created")}</p>
        </div>
      );

      modalContent.push(
        <div key="preview-import-data">
          <p>{mrIntl("BulkImportGroups.please_validate_the_data_based_on_the_first_3_rows")}</p>
          <RenderTable
            excelData={updatedExcelData.slice(0, 3)}
            setDataMapping={setDataMapping}
            dataMapping={dataMapping}
            columnNames={Object.keys(updatedExcelData[0] || {})}
            key="preview-data-table"
            hideSelect={true}
            mode="multiple"
          />
        </div>
      );

      modalContent.push(
        <ImportStatus loading={loading} key="import-status" bulkImportInfo={bulkImportInfo} singleResourceName={singleResourceName} totalDataCount={updatedExcelData.length} />
      )
    }

    return modalContent;
  };
  const currentPageIndex = bulkImportConfig.currentPage - 1;
  const currentPageContent = renderModalContent()[currentPageIndex];

  const handleBulkImport = (previewDataHeaders, programId, start_term, end_term) => {
    setLoading(true);
    const header = Object.keys(previewDataHeaders[0]);
    const values = [header, ...previewDataHeaders.map(row => Object.values(row))];

    dispatch(
      orgActions.update(
        {
          id: currentOrgId,
          org_programme_id: programId,
          bulk_import_data: { ...values },
          type_c: "classes",
          start_term,
          end_term,
        },
        {
          successCallback: () => {
            // setLoading(false);
          },
          errorCallback: () => setLoading(false),
        }
      )
    );
  };

  const updatePreviewData = (start_term, end_term, currentPage, file, programId, role) => {
    const terms = [{ start_term }, { end_term }];
    const previewDataHeaders = updateArrayWithObjectKeys(dataMapping, excelData, role, terms);
    setUpdatedExcelData(previewDataHeaders);

    if (currentPage === 6 && file) {
      handleBulkImport(previewDataHeaders, programId, start_term);
    }
  };

  const moveToNextPage = (role, currentPage) => {
    let pageNumberToAdd = 1;

    if (role !== "student" && currentPage === 2) {
      pageNumberToAdd = 2;
    }

    setBulkImportConfig(prevConfig => ({
      ...prevConfig,
      currentPage: prevConfig.currentPage + pageNumberToAdd,
    }));
  };

  const handleNextButton = () => {
    if (skipSubjects) {
      setSkipSubjects(false);
    }
  
    const { currentPage, role, file, start_term, end_term, programId } = bulkImportConfig;
  
    if (currentPage < 7) {
      console.log("importing data =====>", updatedExcelData, bulkImportConfig, excelData);
      updatePreviewData(start_term, end_term, currentPage, file, programId, role);
      moveToNextPage(role, currentPage);
    } else {
      setSkipSubjects(false);
      setDisableNextButton(true);
      resetBulkImportConfig();
      handleModalClose();
    }
  };
  
  const resetBulkImportConfig = () => {
    setBulkImportConfig({
      programId: null,
      currentPage: 1,
      file: null,
      role: "student",
      start_term: null,
      end_term: null,
    });
  };
  

  return (
    <>
      <Button type={isMenuItem ? "text" : "primary"} onClick={handleMenuItemClick} disabled={org?.is_mb} className='classes-bulk-import-button'>
        {isMenuItem ? mrIntl("BulkImportGroups.import_classes") : mrIntl("BulkImportGroups.new_bulk_import_classes")}
      </Button>
      <Modal
        title={modalTitles[bulkImportConfig.currentPage - 1]}
        open={showModal}
        width="60%"
        onCancel={handleModalClose}
        footer={[
          <Button key="cancel" onClick={handleModalBack} disabled={bulkImportConfig.currentPage === 1 || loading}>
            {mrIntl("BulkImportGroups.back")}
          </Button>,
          <Button key="import" type="primary"
            disabled={disableNextButton || loading}
            onClick={() => handleNextButton()}>
            {bulkImportConfig.currentPage === totalPage ? mrIntl("BulkImportGroups.finish") : mrIntl("BulkImportGroups.next")}
          </Button>,
        ]}
      >

        {currentPageContent}
      </Modal>
    </>
  );
};

export default BulkImportGroups;