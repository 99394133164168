import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  Layout, Typography, Button, Space,
  Card,
  Row,
  Col,
  Input,
  Tag,
  Spin,
  Form,
  Image,
  Divider
} from "antd";
import { CalendarOutlined, EditOutlined, CloseOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import {
  checkIPAD,
  checkMob,
  currentTimeValidWrtServerTime,
} from "/src/lib/utils/helperMethods";
import { actions as experienceActions } from "/src/views/Experiences/redux";
import {
  experienceLoadingSelector,
  getServerTimeOffsetSelector,
} from "/src/views/Experiences/selector";
import StudentCodeAckModal from "/src/views/Experiences/ExperienceShow/Components/StudentCodeAckModal";
import {
  currentUserSelector,
  enabledFeaturesSelector,
  getEmbeddedSelector,
} from "/src/views/Auth/Login/selector";
import { appTypeSelector } from "/src/App/OfflineApp/offlineAppSelectors";
import { actions as loginActions } from "/src/views/Auth/Login/redux";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

import BackToHomeBtn from "/src/components/UI/BackToHomeBtn";
import QuitBtn from "/src/components/UI/QuitBtn";
import './JoinView.scss';
import StartTestButton from "/src/components/UI/StartTestButton/StartTestButton";
import ButtonCountDownWrapper from "../../Components/ButtonCountDownWrapper";
import ViewStatus from "../ViewComponents/ViewStatus";
import { allowedInBrowserSelector } from "../../FirestoreInteractions/selector";
import { SolarDocumentAddOutline, SolarHourglassBoldDuotone, SolarLikeBold, SolarStarLineDuotone } from "/src/components/UI/Icons/SolarIcons";
import { getDeviceInfo } from "/src/App/OfflineApp/offlineAppHelper";
import ViewCard1 from "../ViewComponents/ViewCard1";

const { Text } = Typography;

const isIPAD = checkIPAD();
const isMobile = checkMob();
const isMobileOrIpad = isIPAD || isMobile

const InputJoinCode = (props) => {
  const {
    isScheduleTimePassed,
    joinCodeDefaultValue,
    isJoinCodeCorrect,
    setIsJoinCodeCorrect,
    expUUID,
    className
  } = props;
  console.log("InputJoinCode props", props);
  const dispatch = useDispatch()
  const loading = useSelector(experienceLoadingSelector())
  const joinCodeLength = 6;
  const [validationFailed, setValidationFailed] = useState(false)
  const mrIntl = useTranslate()

  const verifyOnChange = (joinCode) => {
    dispatch(experienceActions.verifyJoinCode({
      uuid: expUUID, 
      join_code: joinCode
    }, 
    {
      successCallback: () => {
        setValidationFailed(false)
        setIsJoinCodeCorrect(true)
      },
      errorCallback: () => {
        setValidationFailed(true)
        setIsJoinCodeCorrect(false)
      }
    }))
  }

  useEffect(() => {
    if (joinCodeDefaultValue) {
      verifyOnChange(joinCodeDefaultValue) 
    }
  }, [])

  return (
    <>
      <Spin spinning={loading} />
      <Form.Item
        validateStatus={validationFailed ? "error" : ""}
        help={validationFailed && "Incorrect code"}
        className={"input-join-code"}
      >
        <Input
          key="join-code-input"
          placeholder={mrIntl("JoinView.join_code")}
          defaultValue={joinCodeDefaultValue}
          disabled={isJoinCodeCorrect || !isScheduleTimePassed}
          variant="borderless"
          className={className}
          // status={showError && "error"}
          maxLength={joinCodeLength}
          onChange={(e) => {
            const joinCode = e.target.value;
            if (joinCode.length === joinCodeLength) {
              verifyOnChange(joinCode)
            }
          }}
        />
      </Form.Item>
    </>
  );
};

const JoinView = (props) => {
  const {
    experienceProps,
    videoCallButton,
    showStudentCodeAckModal,
    setShowStudentCodeAckModal,
    setSwitchFromStartToResumeView,
  } = props;

  const { experience, setUserInfo } = experienceProps;

  const experienceSettings = experience.settings || {};
  const isScheduledTime = experienceSettings.start_condition === 'scheduled_time';

  const location = useLocation();
  const history = useHistory();
  const mrIntl = useTranslate();

  const locationQueryParams = queryString.parse(location.search);

  const [isJoinCodeCorrect, setIsJoinCodeCorrect] = useState(false);
  const [isScheduleTimePassed, setIsScheduleTimePassed] = useState(!isScheduledTime);

  // const experienceViewMode = useSelector(experienceViewModeSelector());
  const currentUser = useSelector(currentUserSelector());
  const serverTimeOffset = useSelector(getServerTimeOffsetSelector());
  const appType = useSelector(appTypeSelector());
  const allowedInBrowser = useSelector(allowedInBrowserSelector)
  const enabledFeatures = useSelector(enabledFeaturesSelector());
  const embedded = useSelector(getEmbeddedSelector());

  const isStudent = currentUser.role === 'student' || currentUser.role === 'parent';
  const targetTimeUTC = Date.parse(experienceSettings.start_at_datetime);
  const currentTimeUTC = new Date().getTime();
  const isTimeNotCompleted = experienceSettings.start_at_datetime && targetTimeUTC > currentTimeUTC;
  const shouldStartInApp = experience.settings.mode === "offline" && appType === "web" && !allowedInBrowser
  const deviceInfo = getDeviceInfo(appType)
  const os = deviceInfo.os

  let startCountDown = null;
  if (isStudent && !experience.experience_user) {
    if (experienceSettings.start_condition === 'scheduled_time') {
      if (currentTimeValidWrtServerTime(serverTimeOffset)) {
        const showBeforeCountDownStarts = (
          <Tag icon={<CalendarOutlined />}>
            {mrIntl("JoinView.scheduled_at")} {dayjs(experienceSettings.start_at_datetime).format('MMM DD, hh:mm a')}
          </Tag>
        );
        startCountDown = (
          <ButtonCountDownWrapper
            key={`start-count-down-wrapper-${experience.id}`}
            targetTime={experienceSettings.start_at_datetime}
            showBeforeCountDownStarts={showBeforeCountDownStarts}
            onCountDownComplete={() => { setIsScheduleTimePassed(true)}}
          />
        );
      }
    }
  }

  const shouldHideForPamoja = enabledFeatures.special_ui_for_pamoja_school && experience?.custom_fields?.block;
  let startButtonEnabled = false
  if ((isScheduledTime && isScheduleTimePassed) || experienceSettings.start_condition === 'anytime') {
    if (experienceSettings.with_join_code) {
      startButtonEnabled = isJoinCodeCorrect
    } else {
      startButtonEnabled = true
    }
  }
  let showJoinCodeInput = experienceSettings.with_join_code;
  // This case will never happen but left for safety.
  if (experienceSettings.mode === 'offline' && appType === 'web' && !allowedInBrowser) {
    showJoinCodeInput = false // show inside app after launching
  }

  const startTestButton = <StartTestButton
    className='start-test-button'
    experience={experience}
    serverTimeOffset={serverTimeOffset}
    history={history}
    buttonText={mrIntl("JoinView.start_test")}
    startButtonWithoutCountDown={true}
    startButtonEnabled={startButtonEnabled}
    setSwitchFromStartToResumeView={setSwitchFromStartToResumeView}
    setUserInfo={setUserInfo}
    buttonIcon={<SolarDocumentAddOutline className="start-test-icon" />}
  />

  const inputJoinCode = showJoinCodeInput && (
    <InputJoinCode
      key="join-code-input-outer"
      isScheduleTimePassed={isScheduleTimePassed}
      isJoinCodeCorrect={isJoinCodeCorrect}
      setIsJoinCodeCorrect={setIsJoinCodeCorrect}
      joinCodeDefaultValue={locationQueryParams.joinCode || ""}
      expUUID={experience.uid}
      className="m-t-5"
    />
  )

  const backToHomeButton =  (!embedded && appType === "web" && !shouldHideForPamoja) && (
    <BackToHomeBtn 
      key="join-view-back-to-home-button" 
      experience={experience} 
      action="back_to_home_before_starting_test"
      className='back-to-home-button'
    />
  );

  const startCountDown1 = isScheduledTime && !isScheduleTimePassed ? (
    <Text 
      type="secondary" 
      className="start-at-datetime"
    >
      {startCountDown}
    </Text>
  ) : null;

  const showConfig = {
    showRightAssessmentDetailsCard: experienceSettings.include_extra_instructions ? true : false,
    showInstructions: experienceSettings.include_extra_instructions ? true : false,
    startCountDown: startCountDown1,
    inputJoinCode: inputJoinCode,
    titleColorClassName: 'bg-blue',
    statusTextColorClassName: 'color-blue',
  }

  return (
    <div className={`join-view-container ${isMobile && showConfig.showRightAssessmentDetailsCard && 'join-view-ipad'}`}>
      <ViewStatus
        statusIcon={
          isTimeNotCompleted 
            ? <SolarHourglassBoldDuotone />
            : <SolarLikeBold />
        }
        statusText={
          isTimeNotCompleted 
            ? mrIntl("JoinView.test_joined")
            : mrIntl("JoinView.start_test")
        }
        statusTextType={
          isTimeNotCompleted
            ? "primary"
            : "success"
        }
        statusMessage={
          shouldStartInApp
            ? os === "Windows" ? mrIntl("JoinView.this_is_a_lockdown_test_to_open_test_please_click_start_in_seb")
              : mrIntl("JoinView.this_is_a_lockdown_test_to_open_test_please_click")
            : isTimeNotCompleted
              ? mrIntl("JoinView.the_test_will_start_at_the_scheduled_time_please_wait")
              : mrIntl("JoinView.you_may_now_begin_the_test_please_click_start_test")
        }
        showConfig={showConfig}
      />
      <ViewCard1
        experience={experience}
        showConfig={showConfig}
        // quitButtonAction="quit_app_after_end"
        // footerCenterComponent={timer} 
        // footerLeftComponent={
        //   !isTimeUp 
        //     ? backToTestButton 
        //     : <span></span>={<Space>}
        // }
        footerCenterComponent={inputJoinCode}
        footerLeftComponent={backToHomeButton}
        footerRightComponent={startTestButton} 
        videoCallButton={videoCallButton}
      />
      <StudentCodeAckModal
        sCode={currentUser.s_code}
        showStudentCodeAckModal={showStudentCodeAckModal}
        setShowStudentCodeAckModal={setShowStudentCodeAckModal}
      />
    </div>
  );
};

export default JoinView;
