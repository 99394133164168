import React, { useState, useEffect } from "react";
import { Row, Col, Button, Space, Select, Badge, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { findIndex } from "lodash";
import dayjs from "dayjs";
import MrFirebase from "/src/lib/MrFirebase/MrFirebase";
import { firestoreDB } from "/src/config/initializers";
import { showConfirmModal } from "/src/components/UI/Segment/UIHelper";
import { actions as userResponseActions } from "/src/views/UserResponses/redux";
import { formatJsonApiData } from "mr_react_framework";
import "../SegmentList/SegmentList.scss";
import { getFromLS, getUniqueId } from "/src/lib/utils/helperMethods";
import { appRegionSelector } from "/src/views/Auth/Login/selector";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { MdRestore } from "react-icons/md";

const { Option } = Select;

const BackupDropdown = ({
  experienceId,
  userId,
  currentUser,
  experience,
  segmentId,
  currentBackup,
  dbBackupStatus,
  userResponse,
  setCurrentBackup,
  setTriggerStateUpdate,
  setRenderMath,
  experienceUser,
}) => {
  console.log(
    "currentBackup in BackupDropdown props ==>",
    currentBackup,
    dbBackupStatus
  );
  const dispatch = useDispatch();

  const originalUserRole = getFromLS("originalUserRole");

  const [showBackupSelect, setShowBackupSelect] = useState(false);
  const [showRecoverButton, setShowRecoverButton] = useState(false);
  // const [latestBackup, setLatestBackup] = useState();
  const appRegion = useSelector(appRegionSelector())
  const mrIntl = useTranslate()

  useEffect(() => {
    setShowBackupSelect(false);
    setShowRecoverButton(false);
    // if (!showStudentsList) {
    //   setShowRecoverButton(false);
    //   setShowBackupSelect(false);
    // }
  }, [userResponse && userResponse.id]);

  // const differenceBetweenTime = (time) => {
  //   let finalTime;
  //   const diffTime =
  //     latestBackup &&
  //     dayjs(latestBackup.updated_at).diff(dayjs(time), "minute");
  //   if (latestBackup) {
  //     console.log("diffTime==>", diffTime, typeof diffTime);
  //     if (diffTime == 0) {
  //       console.log("inside if block", diffTime);
  //       finalTime = `${dayjs(latestBackup.updated_at).diff(
  //         dayjs(time),
  //         "second"
  //       )} seconds before last edit`;
  //     } else {
  //       finalTime = `${diffTime} minutes before last edit`;
  //     }
  //   }

  //   return finalTime;
  // };

  const ResponseFilter = (props) => {
    return null;
  };

  const ResponseList = (props) => {
    const { data } = props;
    console.log("checking backup==>", data);
    // const orderedData = data && sortBy(data, ["last_backup_at"], ["asc"]);

    let orderedData = []
    if (data) {
      orderedData = data.sort((a, b) => Date.parse(b.updated_at) - Date.parse(a.updated_at));
    }
    let firstData = orderedData[0];

    useEffect(() => {
      // check if there is no backup are question is not attempted one
      if (firstData) {
        // setLatestBackup(firstData);
        // if (!currentBackup.fb_response_id) {
        //   onSelectChange(firstData)
        // }

         if ((!currentBackup.fb_response_id) ) {
            onSelectChange(firstData)
          }
      }
      return () => {
        // setCurrentBackup({})
      }
    }, [firstData]);

    const onSelectChange = (data) => {
      const newCurrentBackup = {
        ...data,
        show_backup: true,
        fb_response_id: data.user_response_id || data.uid,
      }
      setCurrentBackup(newCurrentBackup);
      setTriggerStateUpdate(getUniqueId());
      
      if(newCurrentBackup.fb_response_id !== dbBackupStatus.fb_response_id) {
        setShowRecoverButton(true);
      } else {
        setShowRecoverButton(false);
      }
    }

    console.log("ordered data, current backup in response list ==>", currentBackup)
    return (
      <>
        <Select
          value={currentBackup.fb_response_id}
          style={{ width: 300 }}
          placeholder={mrIntl("BackupDropdown.select")}
          optionFilterProp="children"
          onChange={(id) => {
            const idx = findIndex(data, (item) => item.user_response_id === id || item.uid === id);
            if (idx >= 0) {
              onSelectChange(data[idx])
            }
          }}
        >
          {orderedData.length &&
            orderedData.map((item, index) => {
              console.log("checking date==>", item, index);
              let time = dayjs(item.updated_at).format("MMM DD, HH:mm:ss a");
              // data is ordered on the basis of updated_at, so by default taking first item as latest edit, backup is false for both online and offline latest edit can't use that
              if (index === 0) {
                item.version_value = `${mrIntl("BackupDropdown.latest_edit")} - ${time}`;
              } else {
                // {
                //   /* item.version_value = `Version ${index} - ${differenceBetweenTime(item.updated_at)}` */
                // }
                item.version_value = `${mrIntl("BackupDropdown.version")} ${index} - ${time}`;
              }
              // item.version_value = item.backup
              //   ? `Version ${index} - ${differenceBetweenTime(item.updated_at)}`
              //   : `Latest Edit`;

              // uid is used earlier which was unique but in case of china it was only user id, so now using user_response_id - earlier we were not saving user_response_id in all cases but now we are saving it.
              return (
                <Option key={index} value={item.user_response_id || item.uid}>
                  <Tooltip
                    title={
                      item.backup_type && item.backup_type === "offline"
                        ? mrIntl("CommonText.offline")
                        : mrIntl("CommonText.online")
                    }
                  >
                    <Badge
                      style={{ marginRight: "10px" }}
                      dot={true}
                      color={
                        item.backup_type && item.backup_type === "offline"
                          ? "red"
                          : "green"
                      }
                    />
                  </Tooltip>
                  {item.version_value}
                </Option>
              );
            })}
        </Select>
      </>
    );
  };


  const recoverBackUP = () => {
    showConfirmModal({
      className: "recover-backup-confirm-modal",
      centered: true,
      title: mrIntl("BackupDropdown.recovering_backup_title"),
      // content: `This will replace current response with ${currentBackup.version_value}. Are you sure?`,
      content: mrIntl("BackupDropdown.this_will_replace_current_response", {currentBackup: currentBackup.version_value}),
      mrIntl: mrIntl,
      onOk: () => {
        dispatch(
          userResponseActions.recoverResponse(
            {
              ...currentBackup,
              id: userResponse.id,
            },
            {
              success: { showMessage: true, message: mrIntl("BackupDropdown.recovered") },
              successCallback: (response) => {
                const newUserResponse = formatJsonApiData(
                  response.data.user_response
                );
                console.log("newUserResponse ==>", newUserResponse);
                const updatedUserResponse = {
                  ...userResponse,
                  ...newUserResponse,
                };

                dispatch(
                  userResponseActions.updateSuccess(
                    { data: { user_response: updatedUserResponse } },
                    { success: { showMessage: false } }
                  )
                );
              },
            }
          )
        );
        setShowRecoverButton(false)
      },
    });
  };


  const filterParams = {
    segment_id: appRegion === "china" ? ["eq", parseInt(segmentId)] : ["==", parseInt(segmentId)],
    user_id: appRegion === "china" ? ["eq", parseInt(userId)] : ["==", parseInt(userId)],
  }

  const offlineFilterParams = {...filterParams}

  const showOfflineBackupSelect = originalUserRole === "superadmin"
  if (showOfflineBackupSelect) {
    filterParams.backup_type = appRegion === "china" ? ["eq", "online"] : ["==", "online"];
    offlineFilterParams.backup_type = appRegion === "china" ? ["eq", "offline"] : ["==", "offline"]
  }

  return (
    <>
      <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
        <Col span={showOfflineBackupSelect ? 6 : 12} className={`backup-dropdown`}>
          {showBackupSelect && (
            <MrFirebase
              firestoreDB={firestoreDB}
              mode={"not-realtime"}
              filter={ResponseFilter}
              list={ResponseList}
              // extraProps={experience.students}
              currentUser={currentUser}
              experience={experience}
              params={filterParams}
              // params={logParams}
              collectionPath={`experiences/${experienceId}/user_responses`}
              tableName={"user_responses"}
            />
          )}
        </Col>

        {showOfflineBackupSelect && (
          <Col span={5} offset={1} className={`backup-dropdown`}>
            {showBackupSelect && (
              <MrFirebase
                firestoreDB={firestoreDB}
                mode={"not-realtime"}
                filter={ResponseFilter}
                list={ResponseList}
                // extraProps={experience.students}
                currentUser={currentUser}
                experience={experience}
                params={offlineFilterParams}
                // params={logParams}
                collectionPath={`experiences/${experienceId}/user_responses`}
                tableName={"user_responses"}
              />
            )}
          </Col>
        )}
        <Col span={12} style={{ textAlign: "right" }}>
          <Space>
            {currentBackup && showRecoverButton && (
              <Button
                className="recover-backup-btn"
                type="primary"
                onClick={() => recoverBackUP()}
                disabled={!currentBackup.fb_response_id}
              >
                <MrTranslate id={"BackupDropdown.recover_button"}/>
              </Button>
            )}
           {experienceUser.marked 
              ? ( <Tooltip title={mrIntl("BackupDropdown.unpublish_result_to_view_backups_title")}>
                  <Button type="text" disabled={true} icon={<MdRestore />}>
                  </Button>
                </Tooltip>) 
              : ( <Tooltip title={showBackupSelect ? "" : mrIntl("BackupDropdown.view_backups")}>
                  <Button
                    type={showBackupSelect ? "danger" : "text"}
                    icon={showBackupSelect ? "" : <MdRestore />}
                    ghost={showBackupSelect ? true : false}
                    onClick={() => {
                      setShowBackupSelect(!showBackupSelect);
                      if (showBackupSelect) {
                        setShowRecoverButton(false);
                        setCurrentBackup({});
                      } else {
                        // NOTE: showing the recover button even if just the latest edit and no backups - temp in china only
                        // if(appRegion === "china"){
                        //   setShowRecoverButton(true);
                        // }
                        // setShowRecoverButton(true);
                      }
                      setRenderMath(getUniqueId());
                    }}
                  >
                    {showBackupSelect ? mrIntl("CommonText.cancel") : " "}
                  </Button>
                </Tooltip>
              )}
          </Space>
        </Col>
      </Row>
    </>
  );
};

export default BackupDropdown;
