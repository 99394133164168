import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Checkbox } from 'antd';
import { FormRenderer } from "mr_react_framework";
import SelectOrgDropdown from '/src/components/AppSpecific/SelectOrg/SelectOrgDropdown';
import { actions as genericActions } from "/src/App/genericRedux";
import Spinner from "/src/components/UI/Spinner/Spinner";
import { SelectFeaturesCode } from '/src/components/AppSpecific/SelectSubject/SelectSubject';
import { currentUserSelector } from '../Auth/Login/selector';

export const SelectOrgCustomComponent = (props) => {
  console.log("SelectOrgCustomComponent props", props);
  const { value, onChange, formInstance: { getFieldValue } } = props
  const orgFeatures = getFieldValue(["org_features"]) || [];

  const hiddenOrgIds = []
  orgFeatures.forEach((of) => {
    console.log("of ==>", of, value);
    if (of && of.org_id !== value) {
      hiddenOrgIds.push(of.org_id)
    }
  });
  const params = { should_serialize: false, page_size: 10 };

  return (
    <SelectOrgDropdown
      value={value}
      onChange={(newValue) => {
        onChange(newValue);
      }}
      key={value}
      config={{
        // initialSearchParams: {...params, by_feature_id: feature_id, page_size: 100},
        searchApi: true,
        params: params,
        debounceTime: 1000,
        widgetConfig: {
          hiddenOrgIds: hiddenOrgIds,
          allowClear: false,
        }
      }}
    />
  );
};

export const SelectFeaturesCustomComponent = (props) => {
  const { value, onChange, formInstance: { getFieldValue } } = props;

  const orgFeatures = getFieldValue(["org_features"]) || [];
  const hiddenFeatureIds = []
  orgFeatures.forEach((of) => {
    if (of && of.feature_id !== value) {
      hiddenFeatureIds.push(of.feature_id)
    }
  });
  const params = { should_serialize: false, page_size: 100 };

  return (
    <SelectFeaturesCode
      value={value}
      onChange={(newValue) => {
        onChange(newValue);
      }}
      key={value}
      config={{
        // initialSearchParams: {...params, by_org_id: org_id, page_size: 200},
        searchApi: true,
        params: params,
        debounceTime: 1000,
        widgetConfig: {
          hiddenFeatureIds: hiddenFeatureIds,
          allowClear: false,
        }
      }}
    />
  );
};

const OrgFeaturesChildComponent = (props) => {
  console.log("OrgFeaturesChildComponent props", props, props.__INTERNAL__.name);
  const { getFieldValue, sourceValues, formInstance } = props;
  let finalRender = null;

  const formName = props.__INTERNAL__.name;
  const resourceName = formName.indexOf("orgs") !== -1 ? "features" : "orgs"; // For features, we are adding orgs and for orgs, we are adding features - Passing resourceName from above is causing remounting of the component

  const orgFeatures = sourceValues.org_features || [];
  const dispatch = useDispatch();
  const [isDataLoaded, setIsDataLoaded] = useState(orgFeatures.length > 0 ? false : true);
  const [disableAll, setDisableAll] = useState(false);
  const [bulkArchive, setBulkArchive] = useState(false);
  const isEditing = !!sourceValues.id;
  const hasAtLeastOneOrg = (sourceValues.org_features?.length || 0) >= 1

  const currentUser = useSelector(currentUserSelector())
  
  const isSuperAdmin = currentUser.role === "superadmin"

  
  let shouldShowForm = true
  let resource_key = "feature_id";
  let by_filter_key = "by_org_id";
  if (resourceName === "orgs") {
    const status = getFieldValue(["status"]);
    shouldShowForm = status !== "released";
    resource_key = "org_id";
    by_filter_key = "by_feature_id";
  }

  useEffect(() => {
    if (shouldShowForm && !isDataLoaded) {
      dispatch(genericActions[resourceName]({ params: { [by_filter_key]: sourceValues.id, unarchived: true, should_serialize: false, page_size: 200 } }, {
        resourceName: resourceName,
        apiResourceName: resourceName,
        url: "/" + resourceName,
        successCallback: (data) => {
          console.log("successCallback data ===>", data);
          setIsDataLoaded(true);
        }
      }));
    }
  }, [shouldShowForm, resourceName, sourceValues.id]);

  useEffect(() => {
    if (isEditing && hasAtLeastOneOrg) {
      const hasEnabled = orgFeatures.some(feature => feature.enabled);
      setDisableAll(!hasEnabled);
    }
  }, [orgFeatures, isEditing, hasAtLeastOneOrg]);

  const handleToggleAll = (checked) => {
    setDisableAll(checked);
    const updatedFeatures = formInstance.getFieldValue("org_features")?.map(feature => ({
      ...feature,
      enabled: !checked,
    })) || [];
  
    formInstance.setFieldsValue({ org_features: updatedFeatures });
  };
  
  const handleBulkArchive = (e) => {
    const checked = e.target.checked;
    
    const updatedFeatures = formInstance.getFieldValue("org_features")?.map(feature => ({
      ...feature,
      archived: checked, 
    })) || [];
  
    formInstance.setFieldsValue({ org_features: updatedFeatures });
    setBulkArchive(checked);
  };

  if (shouldShowForm) {
    if (!isDataLoaded) {
      return <Spinner />; // or a loading spinner
    }

    finalRender = <>
    {resourceName === "orgs" && isEditing && hasAtLeastOneOrg && (
        <div>
          <div style={{ width: "100%", display: "flex", gap:"16vw", alignItems: "center", marginBottom: "16px", marginLeft: "12px" }}>
            Enable/Disable all organizations
            <Switch
              checked={disableAll}
              onChange={handleToggleAll}
              checkedChildren="Enable All"
              unCheckedChildren="Disable All"
            /></div>
        </div>)
      }
    <FormRenderer
      formObj={{
        type: "object",
        properties: {
          org_features: {
            type: "array",
            span: 24,
            min: 0,
            max: 100,
            default: [{}],
            title: resourceName === "orgs" ? <span className='org-title'>Add organization</span> : "Feature overrides",
            items: {
              type: "object",
              properties: {
                [resource_key]: {
                  type: "string",
                  title: resourceName === "orgs" ? "Select org" : "Select feature",
                  widget: resourceName === "orgs" ? SelectOrgCustomComponent : SelectFeaturesCustomComponent,
                  span: isSuperAdmin ? 8 : 15,
                  rules: [
                    {
                      required: true,
                      message:  resourceName === "orgs" ? "Please select org" : "Please select feature",
                    },
                  ],
                },                
                  enabled: {
                    type: "boolean",
                    title: "Enable/Disable",
                    span: 6,
                    widget: Switch,
                    widgetConfig: {
                      defaultValue: true,
                    },
                    valuePropName: "checked",
                    setInitialValue: true,
                  },
                  archived: {
                    type: "string",
                    title: "Remove",
                    widget: "CheckboxWidget",
                    valuePropName: "checked",
                    span: isSuperAdmin ? 8 : 3,
                  },
                },
                archived: {
                  type: "string",
                  title: "Remove",
                  widget: "CheckboxWidget",
                  valuePropName: "checked",
                  span: 8,
                },
              
            },
          },
        }
      }}
        sourceValues={sourceValues}
        formInstance={formInstance}
      />
      {resourceName === "orgs" && isEditing && hasAtLeastOneOrg && (
        <div>
          <div style={{ width: "100%", display: "flex", alignItems: "center", marginBottom: "16px", marginLeft: "12px" }} >
            <Checkbox checked={bulkArchive} onChange={handleBulkArchive}>
              Archive All
            </Checkbox></div>
        </div>)
      }
    </>
    
  }
  return finalRender;
};

export default OrgFeaturesChildComponent;