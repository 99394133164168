import React, { useState, useEffect } from "react";
import "../Experiences.scss";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { actions as firestoreInteractionActions } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/redux";
import { actions as experienceActions } from "/src/views/Experiences/redux";
import { experienceViewModeSelector } from "/src/views/Experiences/selector";
import { appTypeSelector } from "/src/App/OfflineApp/offlineAppSelectors";
import "./ExperienceItemDetail.scss";
import { enabledFeaturesSelector, getEmbeddedSelector } from "/src/views/Auth/Login/selector";
import { unMountExperience } from "/src/views/Experiences/ExperienceHelperMethods"; //Rename this file to camelcase
import ExperienceManage from "/src/views/Experiences/ExperienceShow/ExperienceManage/ExperienceManage";
import ExperienceTakeOld from "/src/views/Experiences/ExperienceShow/ExperienceTake/ExperienceTakeOld";
import ExperienceTake from "/src/views/Experiences/ExperienceShow/ExperienceTake/ExperienceTake";
import { openOfflineDB } from "/src/lib/OfflineDatabase/OfflineDatabase";
import loadable from "@loadable/component";
import GGBGraph from "/src/views/Segments/GGBGraph/GGBGraph";

const ckLib = loadable.lib(() =>
  import(/* webpackChunkName: "ckeditor" */ "@ckeditor/ckeditor5-super-build")
);
const fabricLib = loadable.lib(() =>
  import(/* webpackChunkName: "fabric" */ "fabric")
);
const reactDataSheetLib = loadable(() =>
  import(/* webpackChunkName: "react-datasheet" */ "react-datasheet")
);

const ExperienceItemDetail = (props) => {
  console.log("ExperienceItemDetail props", props);

  const {
    item,
    history,
    currentUser,
    permission,
    duplicateResource,
    setParams,
    renderForm,
    showForm,
    showFormVal,
    updateResource,
    ...nestedProps
  } = props;

  const experience = item;
  const experienceSettings = experience.settings || {};

  const location = useLocation();
  const dispatch = useDispatch();

  const appType = useSelector(appTypeSelector());
  const embedded = useSelector(getEmbeddedSelector());
  const experienceViewModeFromRedux = useSelector(experienceViewModeSelector());
  const enabledFeatures = useSelector(enabledFeaturesSelector());

  const [fromResourceName, setFromResourceName] = useState(null);
  const [fromUrl, setFromUrl] = useState(null)
  const isStudentReviewerMode = experience.student_reviewer_mode

  let experienceProps = {
    experience: item,
    experienceSettings: experienceSettings,
    setParams: setParams,
    duplicateResource: duplicateResource,
    updateResource: updateResource,
    renderForm: renderForm,
    showForm: showForm,
    showFormVal: showFormVal,
    fromResourceName: fromResourceName,
    fromUrl: fromUrl,
    embedded: embedded,
    experienceViewMode:
      (currentUser.role === "student" || 
      currentUser.role === "parent")
          ? "apTakeTest"
          : "apEditTest",
  };

  if (experienceViewModeFromRedux) {
    experienceProps = {
      ...experienceProps,
      experienceViewMode: experienceViewModeFromRedux,
    };
  }

  if (currentUser.role === "student") {
    if (experienceSettings.peer_review_enabled) {
      if (isStudentReviewerMode) {
        experienceProps = {
          ...experienceProps,
          experienceViewMode: "apEditTest", // For reviewing other students
        };
      } else {
        experienceProps = {
          ...experienceProps,
          experienceViewMode: "apTakeTest", // For view feedback
        };
      }
    }
  }

  let finalRender = [];
  let experienceConfig = {
    isMBTask: true,
    isPamojaTask: true,
    mbGradeBookEmbeddedView: true,
    pageHeader: {
      back: {
        show: true,
      },
      title: {
        show: true,
        inlineEdit: true,
      },
      subtitle: {
        show: true,
      },

      resource: {
        show: true, // based on manage.canEdit below
      },
      preview: {
        show: true,
      },
      rearrange: {
        show: true,
      },
      changeStatus: {
        show: true,
      },
      joinCode: {
        show: true,
      },
      duplicate: {
        show: true,
      },
      points: {
        show: true,
      },
      criteria: {
        show: true,
      },
      deliveryMode: {
        show: true,
      },
      selectInvigilators: {
        show: true,
      },
      selectCollaborators: {
        show: true,
      },
      progressBar: {
        show: true,
      },
      instructions: {
        show: true,
      },
      questionsCount: {
        show: true,
      },
      userName: {
        show: true,
      },
      endTest: {
        show: true,
        disabled: true,
      },
      timer: {
        show: false,
      },
      calculator: {
        enabled: true,
      },
      qrModal: {
        show: true,
      },
      bookmark: {
        show: true,
        disabled: true,
      },
      video: {
        apVideoMonitoring: {
          enabled: true,
          // jitsiConfig: getJitsiConfig,
        },
        externalLink: {
          show: true,
          openInNewTab: true,
        },
      },
      mobileUploadDone: {
        show: true,
      },
    },
    manage: {
      canEdit: true,
      create: {
        show: true,
        alerts: {
          published: {
            show: true,
          },
          closed: {
            show: true,
          },
        },
        topics: {
          layout: {
            span: 4,
            offset: 1,
          },
          list: {
            show: true,
          },
          create: {
            showFirst: true,
            show: true,
            disabled: false,
          },
          edit: {
            enabled: true,
            disabled: false,
          },
          delete: {
            enabled: true,
            disabled: false,
          },
        },
        segments: {
          layout: {
            span: 18,
            offset: 1,
          },
          list: {
            show: true,
          },
          create: {
            showFirst: true,
            show: true,
            disabled: false,
          },
          edit: {
            enabled: true,
            disabled: false,
          },
          delete: {
            enabled: true,
            disabled: false,
          },
        },
        addFromLibraryModal: {
          show: true,
        },
      },
      deliver: {
        show: true,
        alerts: {
          draft: {
            show: true,
          },
          published: {
            show: true,
          },
          closed: {
            show: true,
          },
        },
        settings: {
          show: true,
          mode: {
            show: true,
            disabled: true,
          },
        },
        studentMemberships: {
          show: true,
          disabled: true,
          allowGuests: {
            show: true,
          },
          mappedStudents: {
            show: true,
            disabled: true,
            allowOnline: true,
            selectable: true,
          },
        },
      },
      monitor: {
        show: true,
        realtime: {
          enabled: true,
        },
        liveResponses: {
          // teacher can choose to see one particular live response
          enabled: true,
        },
        liveQuiz: {
          enabled: true, // also in take - SID in sync between teacher and student - teacher controls - one at a time
        },
        filters: {
          show: true,
          search: {
            show: true,
          },
          group: {
            show: true,
          },
        },
        addTime: {
          show: true,
        },
        timer: {
          show: true,
        },
        apVideoMonitoring: {
          show: true,
        },
        studentList: {
          show: true,
        },
      },
      grade: {
        show: true,
      },
    },
    take: {
      // separate pageHeader here OR use above and have role/experienceViewMode conditions there
      focusLost: {
        enabled: true,
      },
      nativeSecurity: {
        // @uttam @abhimanyu for example this can be changed from monitor page - so a watcher for userInfoFromReduxNativeSecurity will set this as false? obv set this later inside the take component, only default here false
        enabled:
          currentUser.role === "student" &&
          (appType === "mac" || appType === "ios") &&
          experienceSettings.mode === "offline"
            ? true
            : false,
      },
      video: {
        apVideoMonitoring: {
          enabled: true,
        },
        externalLink: {
          show: true,
          openInNewTab: true,
        },
      },
      spellcheck: {
        enabled: true,
      },
      calculator: {
        enabled: true,
      },
      gamification: {
        enabled: true, // first topic open and earn points to unlock next topics
        leaderboard: {
          show: true,
        },
      },
      segmentFlow: {
        oneByOne: true, // for livequizzes/ slides creation/take one by one
      },
      liveQuiz: {
        enabled: true, // means that SID with live firestore connection to teacher monitor - segment that student sees controlled by teacher. teacher sees all responses/graph in realtime
      },
      join: {
        startTime: {
          show: true,
          timer: true,
        },
        startTest: {
          // button
          show: true,
          disabled: true,
        },
        quitTest: {
          // button
          show: true,
        },
        backToHome: {
          show: true,
        },
      },
      start: {
        video: {
          show: true,
        },
        responseSync: {
          cloud: {
            enabled: true,
          },
          local: {
            enabled: true,
          },
        },
        chat: {
          show: true,
        },
        upload: {
          enabled: true, // from setting and mode and appType - override in segmentConfig later as needed
        },
      },
      resume: {
        requestResume: {
          show: true,
        },
      },
      end: {
        timer: {
          show: true,
        },
        progressBar: {
          show: true,
          attemptedQuestions: {
            show: true,
            wide: true,
          },
        },
        uploadSummary: {
          show: true,
        },
        quitTest: {
          // button
          show: true,
        },
        backToStart: {
          show: true,
        },
        video: {
          apVideoMonitoring: {
            enabled: true,
          },
        },
        requestResume: {
          show: true,
        },
      },
      submit: {
        submitTest: {
          show: true,
          disabled: true,
        },
        quitTest: {
          // button
          show: true,
        },
        backToHome: {
          show: true,
        },
        viewResult: {
          show: false,
        },
      },
      result: {
        quitTest: {
          // button
          show: true,
        },
      },
    },
  };

  if (currentUser.role === "student") {
    // experienceProps.pageHeader.actions.rearrange = false;
  }

  function setExperienceViewMode(viewMode) {
    dispatch(
      experienceActions.setViewModeSuccess({
        experienceViewMode: viewMode,
      })
    );
  }

  function setUserInfo(userInfo, options = {}) {
    console.log("calling setuserinfo", userInfo, options);
    dispatch(
      firestoreInteractionActions.setUserInfo(
        { experienceId: experience.id, userInfo },
        options
      )
    );
  }

  function setExperienceConfig(experience) {
    dispatch(
      experienceActions.setConfig({
        experience: experience,
      })
    );
  }

  useEffect(() => {
    setExperienceViewMode(experienceProps.experienceViewMode);
    
    setExperienceConfig(experience)

    // TODO: relook - using to come back to my assessments / library - without using history - since that takes back to different tabs
    if (location.state) {
      // ideally get from url? cuz on refresh, from doesn't come
      setFromResourceName(location.state.fromResourceName);
      setFromUrl(location.state.fromUrl);
    }

    if (!embedded) {
      openOfflineDB({
        updateOfflineDBStatus: (offlineDBStatus) => {
          dispatch(experienceActions.setOfflineDbStatusSuccess({ status: offlineDBStatus }));
        },
      });
    }

    return () => {
      // made a helper function for unmount and call that here and after duplicate success modal button Open Assessment on click
      unMountExperience(item, appType, currentUser, dispatch);
    };
  }, []);

  useEffect(() => {
    const segment_types_count = experience.custom_fields["segment_types_count"] || {}
    // Loading all libs so that chunk error should not come between exam

    if (!embedded) {
      if (!window.CKEDITOR) {
        ckLib.load().then((module) => {
          window.CKEDITOR = module.default;
        });
      }
  
      if (!window.fabric) {
        fabricLib.load();
      }
    }
    // if (!window.reactDataSheet) {
    if (segment_types_count["rds_table"] > 0 && !window.reactDataSheet) {
      reactDataSheetLib.load();
    }

    // if (experienceSettings.has_geogebra) {
    // This will download large file and will reconstruct all other GGBs from it
    if (segment_types_count["ggb_graph"] > 0) {
      finalRender.push(
        <div style={{ display: "none" }}>
          <GGBGraph answerMode={true} />
        </div>
      );
    }
  }, []);

  // Not allowing edits in embedded for now - handles MB task preview mode for drafts as well
  if (
    !embedded &&
    permission.experience &&
    permission.experience.update &&
    experience.status === "draft" &&
    experience.master_editable === true &&
    experience.current_user_can_edit &&
    experienceProps.experienceViewMode === "apEditTest"
  ) {
    experienceProps = {
      ...experienceProps,
      editAllowed: true,
    };
  }

  experienceProps = {
    ...experienceProps,
    setUserInfo: setUserInfo,
    setExperienceViewMode: setExperienceViewMode,
  };

  console.log("experienceViewMode ==>", experienceProps.experienceViewMode);
  if (experienceProps.experienceViewMode === "apEditTest") {
    finalRender.push(
      <ExperienceManage experienceProps={experienceProps}></ExperienceManage>
    );
  } else if (
    experienceProps.experienceViewMode === "apTakeTest" ||
    experienceProps.experienceViewMode === "apPreviewTest"
  ) {
    if (enabledFeatures.new_join_view_with_dynamic_config) {
      finalRender.push(
        <ExperienceTake experienceProps={experienceProps} />
      );
    } else {
      finalRender.push(
        <ExperienceTakeOld experienceProps={experienceProps} />
      );
    }
  }

  return (
    <React.Fragment>
      {/* <PageVisibility onChange={handleVisibilityChange}> */}
      {/* Temp disable full screen - get modal to work, no need for pagevisibility - using blur */}
      {/* <FullScreen handle={mainTestScreen} onChange={reportChange}> */}


      {finalRender}


      {/* </FullScreen> */}
      {/* </PageVisibility> */}


    </React.Fragment>
  );
};

ExperienceItemDetail.defaultProps = {};

ExperienceItemDetail.propTypes = {};

export default ExperienceItemDetail;
