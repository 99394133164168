import React, { useEffect, useState } from "react";
import { Button, Card, Tooltip } from "antd";
import DesmosGraphing from "/src/components/UI/Desmos/DesmosGraphing";
import {
  CloseOutlined,
  CalculatorOutlined,
  FullscreenOutlined,
  FullscreenExitOutlined,
  LineChartOutlined,
} from "@ant-design/icons";
// import { copyLink } from "/src/lib/utils/helperMethods";
import { BiReset } from "react-icons/bi";
import DesmosScientific from "/src/components/UI/Desmos/DesmosScientific";
import { actions as experienceReduxActions } from "/src/views/Experiences/redux";
import { useDispatch, useSelector } from "react-redux";
import { calcTypeSelector } from "/src/views/Experiences/selector";
import "../Calculator/Calculator.scss";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import Draggable from "react-draggable";
import { CgArrowsExpandDownRight, CgArrowsExpandUpLeft } from "react-icons/cg";
import { checkIPAD, checkMob } from "/src/lib/utils/helperMethods";
import { currentUserSelector, enabledFeaturesSelector } from "/src/views/Auth/Login/selector";
import { usePinUnpin } from "/src/lib/utils/usePinUnpin";
import { calcEnabledSelector, graphingCalcEnabledSelector } from "../../FirestoreInteractions/selector";

const Calculator = (props) => {
  const { experienceSettings, calcSettingSidebar, handleCurrentTool } = props
  console.log("Calculator Props ==>", props);
  let calculator = null;
  const mrIntl = useTranslate()
  const enabledFeatures = useSelector(enabledFeaturesSelector())
  const newJoinViewWithDynamicConfigEnabled = enabledFeatures.new_join_view_with_dynamic_config
  const draggableTool = enabledFeatures.enable_draggable_tool;

  const [graphingCalculator, setGraphingCalculator] = useState();
  const [calcSetting, setCalcSetting] = useState({ type: "", showCalc: false });

  const calcEnabledFromUserInfo = useSelector(calcEnabledSelector());
  const graphingCalcEnabledFromUserInfo = useSelector(graphingCalcEnabledSelector())

  const [calcFullScreen, setCalcFullScreen] = useState(false);
  const styleCalc = calcFullScreen ? "calc-full-screen-height" : "calc-default-height";
  const dispatch = useDispatch();
  const isDesmosScientificCalc = calcSetting.type === "desmos_scientific_calc";
  const calcCardTitle = isDesmosScientificCalc ? mrIntl("Calculator.scientific_calculator") : mrIntl("Calculator.graphing_calculator");
  const calcSwitchTitle = isDesmosScientificCalc ? mrIntl("Calculator.graphing_calculator") : mrIntl("Calculator.scientific_calculator");
  const calcTypeFromRedux = useSelector(calcTypeSelector());
  const calcSwitch = calcSetting.type != "desmos_scientific_calc" ? "desmos_scientific_calc" : "desmos_graphing_calc";

  const { isPinned, position, isDragging, togglePin, handleDrag, handleStop } = usePinUnpin();

  const className = calcFullScreen
    ? isDesmosScientificCalc
      ? "graphing-calculator-view-full scientific-calc-wrapper"
      : "graphing-calculator-view-full"
    : isDesmosScientificCalc
      ? "scientific-calculator-view scientific-calculator-view-header"
      : newJoinViewWithDynamicConfigEnabled
        ? "graphing-calculator-view scientific-calculator-view-header"
        : "graphing-calculator-view";

  useEffect(() => {
    if (calcSettingSidebar) {
      setCalcSetting(calcSettingSidebar)
    }
  }, [calcSettingSidebar])

  useEffect(() => {
    let defaultCalcType = "desmos_scientific_calc";
    if (graphingCalcEnabledFromUserInfo &&
      !calcEnabledFromUserInfo) {
      defaultCalcType = "desmos_graphing_calc";
    }
    dispatch(
      experienceReduxActions.setCalcTypeSuccess({ calcType: defaultCalcType })
    );
  }, [])

  const enableToggleCalcButton = graphingCalcEnabledFromUserInfo && calcEnabledFromUserInfo

  const handleCalcSwitch = (value) => {
    setCalcSetting({
      ...calcSetting,
      type: value,
      showCalc: true,
    });
    dispatch(
      experienceReduxActions.setCalcTypeSuccess({ calcType: value })
    );
  };

  function hideCalculator() {
    setCalcSetting({
      ...calcSetting,
      showCalc: !calcSetting.showCalc,
    });
    setCalcFullScreen(false);
    if (calcSettingSidebar) {
      handleCurrentTool(null);
    }
  }
  const toggleFullScreen = () => {
    togglePin();
    setCalcFullScreen((prev) => !prev);
  };

  function resetDesmos() {
    if (graphingCalculator) {
      graphingCalculator.setBlank();
    }
  }

  useEffect(() => {
    if (!isPinned) {
      if (calcFullScreen) {
        togglePin();
      }
    }
  }, [calcFullScreen])


  const isMobileOrIpad = checkIPAD() || checkMob();

  if (isDesmosScientificCalc) {
    calculator = (
      <DesmosScientific
        isFullScreen={calcFullScreen}
        styleCalc={styleCalc}
      ></DesmosScientific>
    );
  } else {
    calculator = (
      <DesmosGraphing
        setCalculator={setGraphingCalculator}
        styleCalc={styleCalc}
      ></DesmosGraphing>
    );
  }

  return (
    <div className={`calculator-view ${!isPinned ? 'drag-tool-shadow' : ''}`}>
      {!newJoinViewWithDynamicConfigEnabled ?
        <Tooltip
          title={mrIntl("Calculator.calculator")}
          placement="bottom"
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
        >
          <Button
            shape="circle"
            icon={<CalculatorOutlined />}
            {...(isMobileOrIpad ? !isPinned && !calcFullScreen
              ? { onTouchStart: () => handleCalcSwitch(calcTypeFromRedux) }
              : { onClick: () => handleCalcSwitch(calcTypeFromRedux) }
              : { onClick: () => handleCalcSwitch(calcTypeFromRedux) }
            )}
          />
        </Tooltip> : null}
      {calcSetting.showCalc ? (<Draggable
        handle=".ant-card-head"
        disabled={isPinned}
        bounds="body"
        onDrag={handleDrag}
        onStop={handleStop}
        position={calcFullScreen ? { x: 0, y: 0 } : position}
      >
        <Card
          title={calcCardTitle}
          className={`${className} ${!isPinned ? 'drag-tool-shadow' : ''}`}
          extra={[
            !isDesmosScientificCalc && (
              <Tooltip title="Reset">
                <Button
                  className="reset-graphing-response"
                  key="reset-calculator-button"
                  icon={<BiReset />}
                  onClick={() => resetDesmos()}
                  shape="circle"
                ></Button>
              </Tooltip>
            ),
            (enableToggleCalcButton && <Tooltip
              title={calcSwitchTitle}
              placement={calcFullScreen ? "bottom" : "top"}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            >
              <Button
                shape="circle"
                className="calc-switch"
                icon={
                  isDesmosScientificCalc ? (
                    <LineChartOutlined />
                  ) : (
                    <CalculatorOutlined />
                  )
                }
                {...(isMobileOrIpad ? !isPinned && !calcFullScreen
                  ? { onTouchStart: () => handleCalcSwitch(calcSwitch) }
                  : { onClick: () => handleCalcSwitch(calcSwitch) }
                  : { onClick: () => handleCalcSwitch(calcSwitch) }
                )}
              />
            </Tooltip>),
            <Tooltip
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              title={!calcFullScreen ? mrIntl("Calculator.full_screen") : mrIntl("Calculator.exit_full_screen")}
              placement={calcFullScreen ? "bottom" : "top"}
            >
              <Button
                type={"default"}
                className="calc-full-screen-button"

                {...(isMobileOrIpad ? !isPinned && !calcFullScreen
                  ? { onTouchStart: () => setCalcFullScreen(!calcFullScreen) }
                  : { onClick: () => setCalcFullScreen(!calcFullScreen) }
                  : { onClick: () => setCalcFullScreen(!calcFullScreen) }
                )}
                shape="circle"
                icon={
                  !calcFullScreen ? (
                    <FullscreenOutlined />
                  ) : (
                    <FullscreenExitOutlined />
                  )
                }
              ></Button>
            </Tooltip>,
            (newJoinViewWithDynamicConfigEnabled && draggableTool && !calcFullScreen ?
              <Tooltip title={isPinned ? "Drag" : "Fixed"}>
                <Button
                  type={"default"}
                  shape="circle"
                  icon={isPinned ? <CgArrowsExpandUpLeft /> : <CgArrowsExpandDownRight />}
                  disabled={calcFullScreen}
                  className={`calc-draggable-button ${!isPinned ? "calc-draggable-button-color" : ""}`}
                  {...(isMobileOrIpad ? !isPinned && !calcFullScreen
                    ?{ onTouchStart: () => togglePin() }
                    :{ onClick: () => togglePin() }
                    :{ onClick: () => togglePin() }
                  )}
                ></Button>
              </Tooltip> : null),
            <Tooltip
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              title={mrIntl("CommonText.close")}
              placement={calcFullScreen ? "bottom" : "top"}
            >
              <Button
                key="close-calculator"
                className="close-calculator"
                icon={<CloseOutlined />}

                {...(isMobileOrIpad ? !isPinned && !calcFullScreen
                  ? { onTouchStart: () => hideCalculator() }
                  : { onClick: () => hideCalculator() }
                  : { onClick: () => hideCalculator() }
                )}
                shape="circle"
              ></Button>
            </Tooltip>,
          ]}
        >
          {calculator}
        </Card>
      </Draggable>
      ) : null}
    </div>
  );
};

export default Calculator;
