import { Button, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CalendarOutlined
} from "@ant-design/icons";
import { appRegionSelector, currentUserSelector, enabledFeaturesSelector, getEmbeddedSelector } from '/src/views/Auth/Login/selector';
import MrTranslate, { useTranslate } from '/src/lib/MrTranslate/MrTranslate';
import dayjs from 'dayjs';
import { appTypeSelector, sebConfigFileLoadingSelector } from '/src/App/OfflineApp/offlineAppSelectors';
import {actions as loginActions} from "/src/views/Auth/Login/redux"
import { actions as offlineAppActions } from "/src/App/OfflineApp/offlineAppRedux";
import ButtonCountDownWrapper from '/src/views/Experiences/ExperienceShow/Components/ButtonCountDownWrapper';
import { changeSecurityFailedMessage, checkAndChangeSecurity } from '/src/App/OfflineApp/CheckAndChangeSecurity';
import { endSessionBtnLoadingSelector, joinTestBtnLoadingSelector } from '/src/views/Experiences/selector';
import {actions as experienceReduxActions} from "/src/views/Experiences/redux"
import { message } from '../AntdAppHelper';
import { getWhiteListUrls, checkIfUnsupportedApp } from '/src/App/OfflineApp/offlineAppHelper';
import { checkInternetConnectivity } from '/src/lib/CheckInternetConnectivity/CheckInternetConnectivity';
import { currentTimeValidWrtServerTime, getServerTimeOffset, writeToClipboardPolyfill } from '/src/lib/utils/helperMethods';

const JoinTestButton = ({ history, item, serverTimeOffset, resourceName, showExperience }) => {
  
  const currentUser = useSelector(currentUserSelector());
  const appType = useSelector(appTypeSelector());
  const joinTestBtnLoading = useSelector(joinTestBtnLoadingSelector());
  const endSessionBtnLoading = useSelector(endSessionBtnLoadingSelector());
  const enabledFeatures = useSelector(enabledFeaturesSelector());
  const isUnsupportedApp = checkIfUnsupportedApp(appType, enabledFeatures, currentUser);
  
  const embedded = useSelector(getEmbeddedSelector())
  const appRegion = useSelector(appRegionSelector());

  const mrIntl = useTranslate();
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false);

  const isUnsupportedAppFlagEnabled = (enabledFeatures.disable_unsupported_apps || enabledFeatures.disable_start_btn_for_unsupported)  

  const experienceSettings = item.settings || {}
  const whiteListUrls = getWhiteListUrls(experienceSettings)
  const isStudent = 
    currentUser.role === "student" || currentUser.role === "parent" 
      ? true 
      : false

  const shouldStartBtnDisabled =
    isUnsupportedApp ||
    item.status === "closed" ||
    (joinTestBtnLoading && !joinTestBtnLoading[item.uid]) ||
    endSessionBtnLoading
  let finalRender = null;

  const joinTest = (e) => {
    getServerTimeOffset((offset) => {
      dispatch(experienceReduxActions.setServerTimeOffsetSuccess({ serverTimeOffset: offset }))
      if (currentTimeValidWrtServerTime(offset)) {  
        let urlToOpen = `/e/tests/${item.uid}${'?firstLoad=true'}`;
        showExperience(item, resourceName, 'default', e, null)
        history.push(urlToOpen);
      } else {
        message.error(mrIntl("CommonText.your_system_time_is_out_of_sync_not_be_able_to_start_tests"))
      }
      dispatch(experienceReduxActions.setJoinTestBtnLoadingSuccess({ joinTestBtnLoading: null }))
    })
  }

  const resetLoadingState = () => {
    setLoading(false)
    dispatch(experienceReduxActions.setJoinTestBtnLoadingSuccess({ joinTestBtnLoading: null }))
  }

  const handleJoinTest = (e) => {
    // TODO: Check whats happening for SEB 
    if(!joinTestBtnLoading) {
      setLoading(true)
      dispatch(experienceReduxActions.setJoinTestBtnLoadingSuccess({joinTestBtnLoading: {[item.uid]: true}}))
      
      !embedded && currentUser.role === 'student' && writeToClipboardPolyfill("");

      checkInternetConnectivity(
        appRegion,
        (internetStatus) => {
          if (internetStatus === "online") {
            getServerTimeOffset((offset) => {
              dispatch(experienceReduxActions.setServerTimeOffsetSuccess({ serverTimeOffset: offset }))
              if (currentTimeValidWrtServerTime(offset)) {
                if (item.settings.mode === "offline" && (appType === "mac" || appType === "ios")) {
                  checkAndChangeSecurity(
                    dispatch,
                    {
                      appType: appType,
                      changeSecurityTo: true,
                      whiteListUrls: whiteListUrls,
                      mrIntl: mrIntl,
                      onSuccess: () => {
                        resetLoadingState()
                        joinTest(e)
                      },
                      onError: () => {
                        resetLoadingState()
                        changeSecurityFailedMessage(mrIntl)
                      }
                    }
                  )
                } else {
                  resetLoadingState()
                  joinTest(e)
                }
              } else {
                message.error(mrIntl("CommonText.your_system_time_is_out_of_sync_not_be_able_to_start_tests"))
                resetLoadingState()
              }
            })
          } else {
            resetLoadingState()
          }
        },
        mrIntl)
    }
  }
  let joinBtn = 
    <Button 
      loading={loading}
      key={`join-button-${item.uid}`}
      shape="round"
      type="primary"
      disabled={shouldStartBtnDisabled}
      onClick={(e) => handleJoinTest(e)} 
    >
      {mrIntl("JoinTestButton.join")}
    </Button>

  if (isStudent && !item.experience_user) {
    if (experienceSettings.start_condition === "scheduled_time") {
      if (currentTimeValidWrtServerTime(serverTimeOffset)) {
        let showBeforeCountDownStarts = 
          <Tag 
            color="success" 
            icon={<CalendarOutlined />}
          >
            <MrTranslate id={"ExperienceList.starts_on"} /> {dayjs(experienceSettings.start_at_datetime).format("MMM DD, hh:mm a")}
          </Tag>
        let startCountDown = 
          <ButtonCountDownWrapper 
            key={`start-count-down-wrapper-${item.id}`}
            targetTime={experienceSettings.start_at_datetime}
            startCountDownTriggerMins={30} 
            showBeforeCountDownStarts={showBeforeCountDownStarts}
            showWhenCountDownStart={joinBtn} // We want to start show join button 30 mnts before start time
            showAfterCountDownComplete={joinBtn} // We want to show join button after start time
          />

        finalRender = startCountDown // add canStart based on start time and close time
      }
    } else {
      finalRender = joinBtn
    }
  }
  return finalRender;
};

export default JoinTestButton;