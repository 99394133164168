import { PlusOutlined } from '@ant-design/icons';
import { Row, Col, Tag, Modal, Button, Card, Tree, Typography, Input } from 'antd';
import { useEffect, useState } from 'react';
import { getTreeData } from '/src/components/AppSpecific/SelectSubject/SelectSubject';
import "./SyllabusTopicsCustom.scss"
import { useTranslate } from '/src/lib/MrTranslate/MrTranslate';

const { Text } = Typography;
const { Search } = Input;

const filterTreeData = (data, query) => {
  return data
    .map(node => {
      const key = node.key || node.value || node.title;

      if (node.children) {
        const filteredChildren = filterTreeData(node.children, query);
        if (filteredChildren.length > 0) {
          return { ...node, key, children: filteredChildren };
        }
      }
      if (node?.searchTitle?.toLowerCase()?.includes(query?.toLowerCase())) {
        return { ...node, key };
      }
      return null;
    })
    .filter(node => node !== null);
};

const SyllabusTopicsCustom = (props) => {
  console.log("SyllabusTopicsCustom props", props);
  const { tagged_standards_content_areas_data = [], value = [], onChange, selectable = false, className = '', multiple = true, checkable = true, defaultExpandAll = false } = props;

  const mrIntl = useTranslate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  let allTreeData = [];
  const defaultCheckedKeys = []
  const uniqueSyllabusTopicIds = new Set();

  const findKeyAndId = (data, ids = []) => {
    const keyAndId = [];
    const idsArray = Array.isArray(ids) ? ids : [ids];
    
    idsArray.forEach(id => {
      const found = data?.tag?.final_json?.data?.find(item => item.id === id);
      if (found) {
        return keyAndId.push({ key: `${data.tag_item_id}-${id}`, id: id, label: found.label });
      }
    });

    return keyAndId;
  };

  tagged_standards_content_areas_data.forEach((dataItem, index) => {
    const dataItemTag = dataItem?.tag || {};
    const tagLabel = dataItemTag.label || "";
    const tagGradeGroupLabel = dataItem.grade_group_label || "";
    const title = `${tagLabel} ${tagGradeGroupLabel && `- ${tagGradeGroupLabel}`}`
    let treeData = getTreeData(dataItem, "", {rootParentKey: `${dataItem.tag_item_id}`});
    if (treeData) {
      let firstLevelTreeData = {
        title: title,
        value: `${dataItemTag.id}`,
        key: `${dataItem.tag_item_id}`,
        children: treeData,
        checkable: false,
        selectable: false
      };
      allTreeData.push(firstLevelTreeData);
    }
    const keyAndId = findKeyAndId(dataItem, value)
    if (keyAndId.length > 0) {
      defaultCheckedKeys.push(...keyAndId);
    }
  })

  
  const [checkedKeys, setCheckedKeys] = useState(defaultCheckedKeys);
  const [selectedValues, setSelectedValues] = useState(defaultCheckedKeys);
  const filteredData = filterTreeData(allTreeData, searchQuery);
  
  useEffect(() => {
    if (Array.isArray(selectedValues) && !selectedValues.length) {
      setSelectedValues(defaultCheckedKeys);
      setCheckedKeys(defaultCheckedKeys);
    }
  }, [defaultCheckedKeys.length]);

  const showModal = () => setIsModalVisible(true);
  const handleOnSearch = e => setSearchQuery(e.target.value)

  const handleCancel = () => {
    setIsModalVisible(false);
    setSearchQuery("");
  }
  const handleOnSelect = (selectedKeys, info) => {
    const selectedNodes = info.selectedNodes.map(node => ({
      key: node.key,
      id: node.value,
      label: node.title
    }));
    setCheckedKeys(selectedNodes);
  };

  const handleSave = () => {
    setSelectedValues(checkedKeys);
    const finalValues = checkedKeys.map(item => item.id)
    onChange(finalValues);
    setIsModalVisible(false);
  };

  const handleOnCheck = (checkedKeysValue, info) => {
    const checkedNodes = info.checkedNodes.map(node => ({
      key: node.key,
      id: node.value,
      label: node.title
    }));
    setCheckedKeys(checkedNodes);
  };

  const handleOnClose = (itemId) => {
    const updatedSelectedValues = selectedValues.filter(t => t.id !== itemId);
    setSelectedValues(updatedSelectedValues);
    setCheckedKeys(updatedSelectedValues);
    onChange(updatedSelectedValues.map(item => item.id));
  }

  const uniqueSelectedValues = selectedValues.filter(item => {
    if (uniqueSyllabusTopicIds.has(item.id)) {
      return false;
    }
    uniqueSyllabusTopicIds.add(item.id);
    return true;
  }).slice(0, 2).map((item, index) => {
    return (
      <Tag 
        key={`${item?.id}-${index}`} 
        className="syllabus-topics-widget-tag" 
        closable 
        onClose={() => handleOnClose(item?.id)}
      >
        {item?.label}
      </Tag>
    );
  })

  return (
    <Row className={`syllabus-topics-widget ${className}`} justify="space-between">
      <Col span={24} className="syllabus-topics-widget-header" >
        <Text>{mrIntl("SyllabusTopicsCustom.select_syllabus_topics")}</Text>
        <Button type="default" icon={<PlusOutlined />} onClick={showModal} >
          {mrIntl("SyllabusTopicsCustom.add_topics")}
        </Button>
      </Col>
      <Col span={24} className="syllabus-topics-widget-tag-container" onClick={() => setIsModalVisible(true)}>
        {selectedValues?.length > 0 ? (
          <>
            {uniqueSelectedValues}
            {uniqueSyllabusTopicIds.size > 2 && (
              <Tag className="syllabus-topics-tag-more">
                + {uniqueSyllabusTopicIds.size - 2} ...
              </Tag>
            )}
          </>
        ) : (
          <Text className="syllabus-topics-widget-no-topics-text" >
            {mrIntl("SyllabusTopicsCustom.no_topics_selected_click_add_topics_to_tag_this_question")}
          </Text>
        )}
      </Col>
      <Modal
        title={mrIntl("SyllabusTopicsCustom.select_syllabus_topics")}
        visible={isModalVisible}
        onCancel={handleCancel}
        className="syllabus-topics-widget-modal"
        footer={[
          <Text key="selected-topics" className="syllabus-topics-widget-selected-topics-text" >
            {(checkedKeys?.length || 0)} topic{checkedKeys?.length !== 1 ? 's' : ''} selected
          </Text>,
          <Button key="done" type="primary" onClick={handleSave}>
            {mrIntl("SyllabusTopicsCustom.done")}
          </Button>,
        ]}
      >
        <Search
          placeholder="Search all topics..."
          allowClear
          value={searchQuery}
          onChange={handleOnSearch}
        />
        <Card className="syllabus-topics-widget-card">
          {filteredData.length > 0 ? (
            <Tree
              checkable={checkable}
              multiple={multiple}
              selectable={selectable}
              defaultExpandAll={defaultExpandAll}
              onSelect={(selectedKeys, info) => handleOnSelect(selectedKeys, info)}
              selectedKeys={checkedKeys?.map(item => item.key)}
              onCheck={(checkedKeysValue, info) => handleOnCheck(checkedKeysValue, info)}
              defaultCheckedKeys={checkedKeys?.map(item => item.key)}
              treeData={filteredData}
            />
          ) : (
            <Text className="syllabus-topics-not-found" >
              {mrIntl("SyllabusTopicsCustom.no_topics_found_matching", {searchQuery: `"${searchQuery}"`})}
            </Text>
          )}
        </Card>
      </Modal>
    </Row>
  );
}

export default SyllabusTopicsCustom;