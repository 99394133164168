import React from 'react';
import { Alert, Modal, Tag } from 'antd';
import { useTranslate } from '/src/lib/MrTranslate/MrTranslate';

const AccessCode = (props) => {
  console.log("AccessCode props ==>", props);
  const { setAccessCodeModal } = props

  return (
    <>
      <Modal
        title="Access Code"
        open={true}
        onCancel={() => setAccessCodeModal(null)}
        footer={null}
      >
      </Modal>
    </>
  );
};

export default AccessCode;


