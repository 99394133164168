import { current } from "immer";
import { createSelector } from "reselect";
import { currentUserState } from "/src/views/Auth/Login/selector";
import { activeAdjustedExperienceState } from "/src/views/Experiences/selector";
import { isEmpty } from "lodash";
import { supportedMypSubjectsForAI, supportedCrimsonSubjectsForAI, supportedDpSubjectsForAI } from "../Segments/GenerateAI/generateAIHelperMethods";

const empty = {}
const loadingState = (state, props) => {
  // debugger;
  state.appConstants.get("loading");
};
export const loadingSelector = () =>
  createSelector(
    [loadingState],
    loading => loading
  );

const errorState = (state, props) => state.appConstants.get("error");

export const errorSelector = () =>
  createSelector(
    [errorState],
    error => error
  );

const orgsState = (state, props) => state.orgs.get("orgs");

// export const leadsSelector = () =>
//   createSelector([leadsState,], leads => (leads ? leads : []));

export const orgsSelector = () =>
  createSelector(
    [orgsState],
    orgs => orgs
  );

const gradingScalesAttributesState = (state, props) => {
  console.log("gradingScalesAttributesState props", props, state);
  return state.orgs.get("grading_scales_attributes");
}
  
export const gradingScalesAttributesSelector = () =>
  createSelector(
    [gradingScalesAttributesState],
    gradingScalesAttributes => gradingScalesAttributes
  );

const paginationState = (state, props) => {
  const pageObj = {};
  pageObj.page = state.orgs.get("page");
  pageObj.totalPages = state.orgs.get("total_pages");

  return pageObj;
};

export const paginationSelector = () =>
  createSelector(
    [paginationState],
    pageObj => pageObj
  );





const orgSubjectsState = (state, props) =>
  state.request.get("subjects");

export const orgSubjectsSelector = () =>
  createSelector([orgSubjectsState], orgSubjects => orgSubjects);


const exportToExcelOrgsLoadingState = (state) =>
  state.orgs.get("exportloading", false);

export const exportToExcelOrgsLoadingSelector = () =>
  createSelector([exportToExcelOrgsLoadingState], (exportloading) => exportloading);

const exportedExcelUrlState = (state) =>
  state.orgs.get("exported_excel_url", false);

export const exportedExcelUrlSelector = () =>
  createSelector([exportedExcelUrlState], (exported_excel_url) => exported_excel_url);

const superadminDashboardStatsState = (state) =>
  state.orgs.get("superadmin_dashboard_stats");

export const superadminDashboardStatsSelector = () =>
  createSelector([superadminDashboardStatsState], (superadmin_dashboard_stats) => superadmin_dashboard_stats);

const boardsProgrammesSubjectsGradesState = (state) =>
  state.orgs.get("boards_programmes_subjects_grades");

export const boardsProgrammesSubjectsGradesSelector = () =>
  createSelector([boardsProgrammesSubjectsGradesState], (boards_programmes_subjects_grades) => boards_programmes_subjects_grades);

const boardsProgrammesSubjectsGradesLoadingState = (state) =>
  state.orgs.get("boardsloading", false);

export const boardsProgrammesSubjectsGradesLoadingSelector = () =>
  createSelector([boardsProgrammesSubjectsGradesLoadingState], (boardsProgrammesSubjectsGradesloading) => boardsProgrammesSubjectsGradesloading);

const isAIAssistantDisabledState = (state) => {
  const hasExceededAiQuota = state.orgs.get("has_exceeded_ai_quota");
  const currentUser = currentUserState(state)
  const activeExperience = activeAdjustedExperienceState(state)
  console.log("isAIAssistantDisabledState activeExperience", activeExperience);
  // const rubricType = activeExperience && activeExperience.rubric && activeExperience.rubric.type_c;
  const subjectLabel = (activeExperience && activeExperience.custom_fields && activeExperience.custom_fields.subject_item_label) || "";
  const standardsContentAreaTag = (activeExperience?.tagged_standards_content_areas_data?.tag) || {};
  const allowedSubjects = [...supportedDpSubjectsForAI, ...supportedMypSubjectsForAI, ...supportedCrimsonSubjectsForAI]
  const isMBPamojaBank = activeExperience && activeExperience.questionbank && ["mb_pamoja", "mb_pamoja_poc"].includes(activeExperience.questionbank.code);
  let finalObj = {disabled: false, reason: "", message: ""};
  console.log("allowedSubjects ====>", allowedSubjects, subjectLabel);
  // if (rubricType === "comments_only") {
  //   finalObj = {disabled: true, reason: "rubric_not_supported", message: "Coming soon for this rubric"};
  // } else if(hasExceededAiQuota) {
  if(hasExceededAiQuota) {
    finalObj = {disabled: true, reason: "quota_over", message: "Usage quota exceeded"};
  } else if(currentUser.permission && currentUser.permission.ai_assistant && currentUser.permission.ai_assistant.show === false) {
    finalObj =  {disabled: true, reason: "permission_not_allowed", message: "Feature disabled by admin"};
  } else if(currentUser.role === "qb_author" && (isMBPamojaBank || isEmpty(activeExperience))) {
    finalObj = {disabled: true, reason: "permission_not_allowed", message: "Feature disabled by admin"};
  } else if((allowedSubjects.findIndex(subject => subject.toLowerCase() === subjectLabel.toLowerCase()) === -1) && isEmpty(standardsContentAreaTag)) {
    // if subject not supported AND standards content area tag is empty - basically if exp is tagged with content_area, can allow AI generate
    // finalObj =  {disabled: true, reason: "subject_not_allowed", message: "Coming soon for this subject"};
    // ENABLING for all subjects regardless of standards available or not
  }

  return finalObj;
}
  

export const isAIAssistantDisabledSelector = () =>
  createSelector([isAIAssistantDisabledState], (has_exceeded_ai_quota) => has_exceeded_ai_quota);

const aiModelState = (state) =>
  state.orgs.get("ai_model");

export const aiModelSelector = () =>
  createSelector([aiModelState], (ai_model) => ai_model);

const aiModelTempState = (state) =>
  state.orgs.get("ai_model_temp");

export const aiModelTempSelector = () =>
  createSelector([aiModelTempState], (ai_model_temp) => ai_model_temp);

const upgradePlanModalConfigState = (state) =>
  state.orgs.get("upgrade_plan_modal_config", empty);

export const upgradePlanModalConfigSelector = () => 
  createSelector([upgradePlanModalConfigState], (upgrade_plan_modal_config) => upgrade_plan_modal_config );