export function resetOptionsFromNumberLine(parsedJSONObject) {
  let filteredOptions = parsedJSONObject.segment_data.options.map(
    ({ left, point, top, ...rest }) => rest
  );
  const data = {
    ...parsedJSONObject,
    segment_data: {
      ...parsedJSONObject.segment_data,
      options: filteredOptions,
    },
  };
  return data;
}

export function resetOptions(options) {
  return options.map(({ left, point, top, ...rest }) => rest);
}

export function mappedAnswer(correctAns, studentAns) {
  const correctAnsMap = new Map(
    correctAns.map((item) => [item.id, item.point])
  );

  const result = studentAns.map((item) => ({
    ...item,
    isCorrect: item.point === correctAnsMap.get(item.id),
  }));

  return result;
}
