import { formatJsonApiData } from "mr_react_framework";
import { axiosInstance } from "/src/api/apiModule";
import { put, select } from "redux-saga/effects";
import reduxCrud, { actions, actionTypes } from "./redux";
import { actions as topicActions } from "/src/views/Segments/Topics/redux";
import { actions as segmentActions } from "/src/views/Segments/redux";
// import {actions as libraryActions} from "/src/views/Experiences/LibraryExperiences/redux";
import { actions as userResponsesActions } from "/src/views/UserResponses/redux";
import { actions as loginActions } from "/src/views/Auth/Login/redux";
import { message } from '/src/components/UI/AntdAppHelper';
import { openUrl } from "/src/lib/utils/helperMethods";
import { appTypeSelector } from "/src/App/OfflineApp/offlineAppSelectors";
import _, { isArray } from "lodash";
import { appRegionSelector, currentUserSelector, enabledFeaturesSelector } from "/src/views/Auth/Login/selector";
import { actions as offlineAppActions } from "/src/App/OfflineApp/offlineAppRedux";
import { actions as experienceUserActions } from "/src/views/ExperienceUsers/redux";
import { actions as annotationsReduxActions } from "/src/views/Annotations/redux";
import { activeAdjustedExperienceIdSelector, activeAdjustedExperienceSelector } from "./selector";
import { actions as experienceReduxActions } from "./redux";
import { getAppVersion, getDeviceInfo, isNewAppVersion } from "/src/App/OfflineApp/offlineAppHelper";

export const makeShowSaga = (
  forController = "experiences",
  forActions,
  experienceActions
) => {
  return function* (action) {
    const { successCallback, forceApiRequest } = action.options;
    console.log("show action", action);
    console.log("double-render-check = show action", action);
    const exp_uid = action.payload.id;
    const with_topics = action.payload.with_topics;
    let url = `experiences/${exp_uid}.json`

    if(with_topics) {
     url += `?with_topics=` + with_topics;
    }
    
    try {
      // let experience = yield(select((state) => {return state[forController].get("experiences").find((item) => item.id === parseInt(action.payload.id))}));
      let experience = yield select((state) => {
        return state[forController]
          .get("experiences")
          .find((item) => item.uid === exp_uid);
      });
      let makeApiRequest = true;
      let updateInRedux = false;

      console.log("makeShowSaga experience", experience);

      if (experience) {
        // makeApiRequest = false;
        if (experience.status !== "draft") {
          if (!experience.topics) {
            makeApiRequest = true;
          } else {
            makeApiRequest = false;
          }
        } else {
          if (experience.students) {
            makeApiRequest = false;
          }
        }
        if (forceApiRequest) {
          makeApiRequest = true;
        }
      }
      if (makeApiRequest) {
        yield put(forActions.showStart());
        const response = yield axiosInstance.instance.get(url);
        // yield localStorage.setItem("token", response.data.token);
        console.log("show response 33", response); 
        experience = formatJsonApiData(response.data.experience);
        updateInRedux = true
        console.log("show response 33", response);

        if (experience.reviewed_by_experience_users) {
          yield put(experienceUserActions.fetchSuccess({ data: { experience_users: experience.reviewed_by_experience_users } }))
        }
      }

      // yield localStorage.setItem("token", response.data.token);
      // const experience = response.data.experience.data;

      // IMP - see APL-2880
      // const activeAdjustedExperienceId = !experience.master_editable ? experience.original_id : experience.id;
      const expId = experience.id
      const currentActiveAdjustedExperienceId = yield select(activeAdjustedExperienceIdSelector())
      if (currentActiveAdjustedExperienceId !== expId) {
        updateInRedux = true
      }
      yield put(
        experienceActions.setActiveSuccess({ id: expId })
      );
      // yield put(experienceActions.setActiveSuccess({id: experience.id}));
      if (experience.status !== "draft") {
        let topics,
          segments = [];
        topics = experience.topics;

        if (topics && Array.isArray(topics)) {
          topics = topics.map((topic) => {
            // segments = segments.concat(topic.segment);  // NOTE: @SHIKHAR ADDED THIS TO PUSH SECTION INTO SEGMENTS TOO
            segments = segments.concat(topic.children);
            return topic.segment;
          });
          yield put(topicActions.fetchSuccess({ data: { segments: topics } }));
          yield put(segmentActions.fetchSuccess({ data: { segments: segments } }));
        }
      }

      if (successCallback) {
        successCallback();
      }
      
      if (updateInRedux) {
        yield put(forActions.showSuccess({ data: { experience: experience } }));
        // Below is for guest user only - check inside
        yield put(loginActions.overrideOrgFeaturesSuccess({ exp_org_enabled_features: experience.exp_org_enabled_features }));

      }
    } catch (error) {
      // let errorMsg = response.data.error;
      console.log("show error", error);
      yield put(forActions.showFail({ error: error }));
    }
  };
};

export function* fetchWithJoinCodeSaga(action) {
  let url = `experiences_get_with_join_code.json`;
  let requestData = {};

  if (action.payload.name) {
    requestData = {...requestData, user_name: action.payload.name}
    // url += `?user_name=${action.payload.name}`;
  }
  if (action.payload.join_code) {
    requestData = {...requestData, join_code: action.payload.join_code}
    // url += `&join_code=${action.payload.join_code}`;
  }
  if (action.payload.s_code) {
    requestData = {...requestData, s_code: action.payload.s_code}
    // url += `&s_code=${action.payload.s_code}`;
  }
  console.log("getting fetchWithJoinCode", url);
  try {
    const appType = yield select(appTypeSelector());
    yield put(actions.fetchWithJoinCodeStart());
    const response = yield axiosInstance.instance.post(url, requestData);
    // yield localStorage.setItem("token", response.data.token);

    if (
      response.data &&
      response.data.extra_attrs &&
      response.data.extra_attrs.uuid
    ) {
      let expAttrs = response.data.extra_attrs;
      const isOfflineMode = expAttrs.settings.mode === "offline";
      yield put(actions.fetchWithJoinCodeSuccess(response.data));
      console.log("getting fetchWithJoinCode segments", response.data);
      const enabledFeatures = expAttrs.exp_org_enabled_features || {};
      const deviceInfo = getDeviceInfo(appType)
      const os = deviceInfo.os
      if (isOfflineMode && ((appType === "web" && !enabledFeatures.new_join_view_with_dynamic_config) || os === "IPad OS")) {
        message.error(
          "The test with this join code is a high-stakes assessment. Please use the AssessPrep desktop app for Windows, Mac and IPad"
        );
        if (action.options.successCallback) {
          action.options.successCallback(isOfflineMode)
        }
      } else {
        let experienceUrl =
          import.meta.env.VITE_WEB_URL +
          "/e/tests/" +
          expAttrs.uuid +
          "?joinCode=" +
          expAttrs.access_code +
          "&firstLoad=true";

        const appVersion = getAppVersion(appType);
        let shouldDispatchChangeSecurity =
          isOfflineMode &&
          appType === "mac" &&
          isNewAppVersion(appVersion, "10.1.0");

        // IMP : Take test as guest btn is not allowed in iOS
        // Earlier, the Mac app security was on experience mount.
        // Starting from version 10.1.0, a new security AAC mode approach is implemented.
        // The following changes are specific to version 10.1.0 and newer versions.

        let executeMethods = [];
        if (shouldDispatchChangeSecurity) {
          executeMethods.push({
            key: "changeSecurity",
            value: true,
          });
        }

        // TODO: Add firstLoad and figure out security on in case of ipad. see anything else that was done on start test button in exp list. Also hide upcoming and all list for guest account? how to show currently ongoing test in case tab closed?

        // DONE: Login user on FE - and then open url
        openUrl(experienceUrl);

        if(executeMethods.length > 0){
          yield put(offlineAppActions.executeMethod(executeMethods))
        }
  
        yield put(
          loginActions.createSuccess(
            { data: { user: response.data.user, token: response.data.token } }
            //{ successCallback: openUrl(experienceUrl)}
          )
        );
      }
    }
  } catch (error) {
    console.error("fetchWithJoinCode saga fail", error);
    // console.log("fetchWithJoinCode saga fail", error)
    // message.error('No test found with this join code');
    yield put(
      actions.fetchWithJoinCodeFail({ error: error.response.data.message })
    );
  }
}

export function* verifyJoinCodeSaga(action) {
  const {successCallback, errorCallback} = action.options
  let url = `experiences_verify_join_code.json?uuid=${action.payload.uuid}&join_code=${action.payload.join_code}`;
  try {
    yield put(actions.verifyJoinCodeStart());
    const response = yield axiosInstance.instance.get(url);
    if (response.status === 200) {
      if (successCallback) {
        successCallback(response)
      }
      yield put(actions.verifyJoinCodeSuccess(response.data));
    } else {
      if (errorCallback){
        errorCallback(response)
      }
    }
  } catch (error) {
    console.error("verifyJoinCode saga fail", error);
    // console.log("fetchWithJoinCode saga fail", error)
    // message.error('No test found with this join code');
    if (errorCallback) {
      errorCallback(error)
    }
    yield put(
      actions.verifyJoinCodeFail({ error: error.response.data.message })
    );
  }
}


export function* updateStatusSaga(action) {
  let url = "experience_status.json";
  console.log("experience_status action ==>", action)

  const { successCallback, errorCallback } = action.options;
  yield put(actions.updateStatusStart());
  try {
    const response = yield axiosInstance.instance.post(url, action.payload);
    console.log("update_status response ==>", response);

    let errors = response.data.errors
    if (errors.length == 0) {
      if (successCallback) {
        successCallback(response);
      }
    } else {
      if (errorCallback) {
        errorCallback(errors);
      }
    }
    // actions.showSuccess({
    //   data: {
    //     experience: response.data.experience,
    //   },
    // })
  } catch (error) {
    console.error("update_status error", error, error.message);
    if (errorCallback) {
      errorCallback(error);
    }
    yield put(actions.updateStatusFail({ error }));
  }
}

// add active experience id and
export function* printSaga(action) {
  let url = `experiences_for_print/${action.payload.id}.json`;
  const printParams = [];
  if (action.payload.user_id) {
    // url += `?user_id=${action.payload.user_id}`;
    printParams.push(`user_id=${action.payload.user_id}`)
  }
  if (action.payload.print_type) {
    // url += `?print_type=${action.payload.print_type}`;
    printParams.push(`print_type=${action.payload.print_type}`)
  }
  if (action.payload.with_exp_org_time) {
    // url += `?with_exp_org_time=true`;
    printParams.push(`with_exp_org_time=true`)
  }
  
  url += `?${printParams.join("&")}`
  console.log("getting print segments", url);
  try {
    yield put(actions.printStart());
    const response = yield axiosInstance.instance.get(url);
    // yield localStorage.setItem("token", response.data.token);
    const experienceUser = response.data.experience.experience_user;
    yield put(actions.printSuccess(response.data));
    console.log("getting print segments", response.data);
    if (response.data.experience.segments) {
      let allDescendants = []
      let topics = response.data.experience.segments
      topics.forEach((topic, i) => {
        allDescendants.push(topic.children)
      })
      yield put(
        segmentActions.fetchSuccess({
          data: {
            segments:
            _.flatten(allDescendants),
          },
        })
      );
    }

    if (experienceUser) {
      yield put(
        userResponsesActions.fetchSuccess({
          data: {
            user_responses:
              experienceUser.user_responses,
          },
        })
      );
      yield put(experienceUserActions.showSuccess({
        data: {
          experience_user: experienceUser.eu
        }
      }))
      yield put(
        experienceUserActions.setActiveExperienceUserIdSuccess({
          id: experienceUser.eu.id,
        })
      );
      console.log("experienceUser.annotations", experienceUser.annotations);
      if (experienceUser.annotations) {
        yield put(annotationsReduxActions.fetchStart())
        yield put(
          annotationsReduxActions.fetchSuccess({ annotations: experienceUser.annotations })
        );
      }
    }
  } catch (error) {
    console.error("print saga fail", error);
    yield put(actions.printFail({ error: error }));
  }
}

export function* exportSaga(action) {
  yield put(actions.exportStart());

  let url = "export_to_pdf.json";
  if (action.payload.exportURLType === "excel") {
    url = "export_to_excel.json";
  }

  const { successCallback, errorCallback } = action.options;
  try {
    const response = yield axiosInstance.instance.post(url, action.payload);
    console.log("export response", response);

    if (response && response.data.message) {
      // if (action.payload.export_cancelled == true) {
      //   // using this same fn to cancel also
      //   // yield put(actions.exportSuccess());
      // } else {
      //   // yield put(actions.exportSuccess());
      // }
    }
    if (successCallback) {
      successCallback(response);
    }
  } catch (error) {
    console.error("exportSaga error", error);
    if (errorCallback) {
      errorCallback(error);
    }
    yield put(actions.exportFail({ error }));
  }
}

export function* exportStatusSaga(action) {
  yield put(actions.exportStatusStart());
  let url = "export_status_check.json";

  console.log("export status check action ==>", action)
  const { successCallback, errorCallback } = action.options;
  try {
    const response = yield axiosInstance.instance.get(url, { 
      params: action.payload 
    });
    console.log("export status check response", response);
    
    if (response && response.data) {
      if (successCallback) {
        successCallback(response.data.export_json);
      }
    }
  } catch (error) {
    console.error("export status check error", error);
    if (errorCallback) {
      errorCallback(error);
    }
    yield put(actions.exportStatusFail({ error }));
  }
}

export function* setConfigSaga(action) {
  console.log("setConfigSaga action payload", action.payload);
  try {
    // yield put(actions.setConfigStart());
    const appType = yield select(appTypeSelector());
    const currentUser = yield select(currentUserSelector());
    const enabledFeatures = yield select(enabledFeaturesSelector());

    let experienceConfig = {
      isMBTask: true,
      isPamojaTask: true,
      mbGradeBookEmbeddedView: true,
      pageHeader: {
        back: {
          show: true,
        },
        title: {
          show: true,
          inlineEdit: true,
        },
        subtitle: {
          show: true,
        },
  
        resource: {
          show: true, // based on manage.canEdit below
        },
        preview: {
          show: true,
        },
        rearrange: {
          show: true,
        },
        changeStatus: {
          show: true,
        },
        joinCode: {
          show: true,
        },
        duplicate: {
          show: true,
        },
        points: {
          show: true,
        },
        criteria: {
          show: true,
        },
        deliveryMode: {
          show: true,
        },
        selectInvigilators: {
          show: true,
        },
        selectCollaborators: {
          show: true,
        },
        progressBar: {
          show: true,
        },
        instructions: {
          show: true,
        },
        questionsCount: {
          show: true,
        },
        userName: {
          show: true,
        },
        endTest: {
          show: true,
          disabled: true,
        },
        timer: {
          show: false,
        },
        calculator: {
          enabled: true,
        },
        qrModal: {
          show: true,
        },
        bookmark: {
          show: true,
          disabled: true,
        },
        video: {
          apVideoMonitoring: {
            enabled: true,
            // jitsiConfig: getJitsiConfig,
          },
          externalLink: {
            show: true,
            openInNewTab: true,
          },
        },
        mobileUploadDone: {
          show: true,
        },
      },
      manage: {
        canEdit: true,
        create: {
          show: true,
          alerts: {
            published: {
              show: true,
            },
            closed: {
              show: true,
            },
          },
          topics: {
            layout: {
              span: 4,
              offset: 1,
            },
            list: {
              show: true,
            },
            create: {
              showFirst: true,
              show: true,
              disabled: false,
            },
            edit: {
              enabled: true,
              disabled: false,
            },
            delete: {
              enabled: true,
              disabled: false,
            },
          },
          segments: {
            layout: {
              span: 18,
              offset: 1,
            },
            list: {
              show: true,
            },
            create: {
              showFirst: true,
              show: true,
              disabled: false,
              content: {
                enabled: true
              },
              questions: {
                enabled: true
              },
              games: {
                enabled: false
              },
              addFromLibrary: {
                enabled: true
              }
            },
            edit: {
              enabled: true,
              disabled: false,
            },
            delete: {
              enabled: true,
              disabled: false,
            },
            points: {
              show: true
            },
            ePoints: {
              show: false
            }
          },
        },
        deliver: {
          show: true,
          alerts: {
            draft: {
              show: true,
            },
            published: {
              show: true,
            },
            closed: {
              show: true,
            },
          },
          settings: {
            show: true,
            mode: {
              show: true,
              disabled: true,
            },
          },
          studentMemberships: {
            show: true,
            disabled: true,
            allowGuests: {
              show: true,
            },
            mappedStudents: {
              show: true,
              disabled: true,
              allowOnline: true,
              selectable: true,
            },
          },
        },
        monitor: {
          show: true,
          realtime: {
            enabled: true,
          },
          liveResponses: {
            // teacher can choose to see one particular live response
            enabled: true,
          },
          liveQuiz: {
            enabled: true, // also in take - SID in sync between teacher and student - teacher controls - one at a time
          },
          filters: {
            show: true,
            search: {
              show: true,
            },
            group: {
              show: true,
            },
          },
          addTime: {
            show: true,
          },
          timer: {
            show: true,
          },
          apVideoMonitoring: {
            show: true,
          },
          studentList: {
            show: true,
          },
        },
        grade: {
          show: true,
        },
      },
      takeExperience: {
        firstView: "joined",
        // separate pageHeader here OR use above and have role/experienceViewMode conditions there
        
        focusLost: {
          enabled: true,
        },
        nativeSecurity: {
          // @uttam @abhimanyu for example this can be changed from monitor page - so a watcher for userInfoFromReduxNativeSecurity will set this as false? obv set this later inside the take component, only default here false
          // enabled:
            // currentUser.role === "student" &&
            // (appType === "mac" || appType === "ios") &&
            // experienceSettings.mode === "offline"
            //   ? true
            //   : false,
        },
        video: {
          apVideoMonitoring: {
            enabled: true,
          },
          externalLink: {
            show: true,
            openInNewTab: true,
          },
        },
        spellcheck: {
          enabled: false,
        },
        calculator: {
          enabled: false,
        },
        gamification: {
          enabled: false, // first topic open and earn points to unlock next topics
          leaderboard: {
            show: false,
          },
        },
        engagement: {
          enabled: false,
          video: {
            enabled: false,
            points: 100
          },
          question: {
            enabled: true,
            points: 20
          },
          game: {
            enabled: true,
            points: 50
          }
        },
        segmentFlow: {
          oneByOne: false, // for livequizzes/ slides creation/take one by one
        },
        liveQuiz: {
          enabled: false, // means that SID with live firestore connection to teacher monitor - segment that student sees controlled by teacher. teacher sees all responses/graph in realtime
        },
        join: {
          createExperienceUserOnStart: false,
          startTime: {
            show: true,
            timer: true,
          },
          startTestBtn: {
            // button
            show: true,
            disabled: true,
          },
          quitTestBtn: {
            // button
            show: true,
          },
          backToHomeBtn: {
            show: true,
          },
        },
        start: {
          video: {
            show: true,
          },
          // TODO: NEED to separate segmentConfigFromRedux - embed case
          segment: {
            points: {
              show: true
            },
            ePoints: {
              show: false
            }
          },
          userResponseSave: {
            showSavingStatusIndicator: true,
            cloud: {
              enabled: true,
            },
            local: {
              enabled: true,
            },
            redux: {
              enabled: true
            },
            apDb: {
              enabled: false
            }
          },
          chat: {
            show: true,
          },
          upload: {
            enabled: true, // from setting and mode and appType - override in segmentConfig later as needed
          },
          pageHeader: {
            endButton: {
              show: true
            }
          }
        },
        resume: {
          requestResume: {
            show: true,
          },
        },
        end: {
          timer: {
            show: true,
          },
          progressBar: {
            show: true,
            attemptedQuestions: {
              show: true,
              wide: true,
            },
          },
          uploadSummary: {
            show: true,
          },
          quitTest: {
            // button
            show: true,
          },
          backToStart: {
            show: true,
          },
          video: {
            apVideoMonitoring: {
              enabled: true,
            },
          },
          requestResume: {
            show: true,
          },
        },
        submit: {
          submitTest: {
            show: true,
            disabled: true,
          },
          quitTest: {
            // button
            show: true,
          },
          backToHome: {
            show: true,
          },
          viewResult: {
            show: false,
          },
        },
        result: {
          quitTest: {
            // button
            show: true,
          },
        },
      },
    };

    let experience = action.payload.experience
    if(experience){
      let experienceSettings = experience.settings
      
      experienceConfig.experienceType = experience.experience_type

      if(experienceConfig.experienceType === "learning"){
        experienceConfig.takeExperience.start.pageHeader.endButton.show = false
        experienceConfig.takeExperience.engagement.enabled = true
        if(currentUser.role === "student"){
          experienceConfig.takeExperience.join.createExperienceUserOnStart = true
          experienceConfig.takeExperience.start.userResponseSave.apDb.enabled = true
        }
        if(enabledFeatures.games){
          experienceConfig.manage.create.segments.create.games.enabled = true
        }
        if(experienceConfig.takeExperience.engagement.enabled){
          experienceConfig.takeExperience.start.userResponseSave.showSavingStatusIndicator = false
          experienceConfig.takeExperience.start.segment.ePoints.show = true
        }
        // No need - keep joined and create eu on start test button click
        // experienceConfig.takeExperience.firstView = "startTest"
      }
    }
    console.log("setConfigSaga experienceConfig", experienceConfig);
    yield put(actions.setConfigSuccess({experienceConfig: experienceConfig}));
  } catch (error) {
    console.error("setConfigSaga fail", error);
    yield put(actions.setConfigFail({ error: error }));
  }
}

export function* makeExperienceUserSaga(action) {
  const currentUser = yield select(currentUserSelector()) 
  const appRegion = yield select(appRegionSelector()) 
  let url = `/experience_users`;
  let experienceUserData = {
    experience_id: action.payload.experienceId,
    user_id: currentUser.userId,
    status: "taken",
    attempt_no: 1,
    name: currentUser.name,
    custom_fields: {
      student_region: appRegion
    }
  }
  try {
    yield put(actions.makeExperienceUserStart());
    const response = yield axiosInstance.instance.post(
      url,
      experienceUserData
    );

    // yield put(actions.makeExperienceUserSuccess(response.data));
    console.log("makeExperienceUserSaga response", response.data);
    
  } catch (error) {
    console.error("makeExperienceUserSaga fail", error);
    yield put(actions.makeExperienceUserFail({ error: error }));
  }
}


export function* getAnalyticsSaga(action) {
  let url = `experience_analytics.json`;
  const params = action.payload
  Object.keys(params).forEach(key => {
    if ((!isArray(params[key]) && params[key]) || (isArray(params[key]) && params[key].length > 0)) {
      console.log("key params[key] ======>", key, params[key]);
      url += `${url.includes('?') ? '&' : '?'}${key}=${params[key]}`;
    }
  });
  // if (action.payload.s_code) {
  //   url += `&s_code=${action.payload.s_code}`;
  // }
  console.log("getting getAnalyticsSaga", url);
  try {
    yield put(actions.getAnalyticsStart());
    const response = yield axiosInstance.instance.get(url);
    console.log("getting getAnalyticsSaga", response);

    if (
      response.data.data
    ) {
      let successObj = {data: response.data.data, type: action.payload.type}
      yield put(actions.getAnalyticsSuccess(successObj));
      console.log("getting getAnalyticsSaga", response.data.data);
    }
  } catch (error) {
    console.error("getAnalyticsSaga saga fail", error);
    // message.error('No test found with this join code');
    yield put(
      actions.getAnalyticsFail({ error: error.response.data.message })
    );
  }
}

export function* updateMembershipSaga(action) {
  let url = "update_membership.json";

  const { experience, successCallback, errorCallback } = action.options;
  // yield put(actions.updateMembershipStart());
  try {
    const response = yield axiosInstance.instance.post(url, action.payload);
    console.log("update_membership response ==>", response);
    
    if (successCallback) {
      successCallback(response);
    }
    // yield put(actions.showSuccess({
    //   data: {
    //     experience: {
    //       ...experience,
    //       experience_membership_group_ids: response.data.experience_membership_group_ids,
    //       experience_membership_student_ids: response.data.experience_membership_student_ids,
    //     },
    //   },
    // }, { success: { showMessage: false }}))
    // yield put(actions.updateMembershipSuccess());
  } catch (error) {
    console.error("update_membership error", error, error.message);
    if (errorCallback) {
      errorCallback(error);
    }
    yield put(actions.updateMembershipFail({ error }));
  }
}

export function* topicsAndSegmentsFetchSaga(action) {
  let url = "experience_topics_and_segments.json";

  const { successCallback, errorCallback } = action.options;
  const activeExperience = yield select(activeAdjustedExperienceSelector());

  try {
    let makeApiRequest = false;
    if (activeExperience) {
      if(activeExperience.status === "draft") {
        makeApiRequest = true;
      } else {
        if (!activeExperience.topics) {
          makeApiRequest = true;
        }
      }
    }
    if(makeApiRequest) {
      yield put(actions.topicsAndSegmentsFetchStart());
      const response = yield axiosInstance.instance.get(url, { 
        params: action.payload 
      });
      console.log("fetch topics saga response", response);
      
      let topics,
      segments = [];
      topics = response.data.topics;
  
      if (topics && Array.isArray(topics)) {
        topics = topics.map((topic) => {
          // segments = segments.concat(topic.segment);  // NOTE: @SHIKHAR ADDED THIS TO PUSH SECTION INTO SEGMENTS TOO
          segments = segments.concat(topic.children);
          return topic.segment;
        });
        console.log("fetch topics saga topics ====>", topics, segments);
        yield put(topicActions.fetchSuccess({ data: { segments: topics } }));
        yield put(segmentActions.fetchSuccess({ data: { segments: segments } }));
        yield put(
          experienceReduxActions.showSuccess({
            data: {
              experience: {
                ...activeExperience,
                topics: response.data.topics,
              }
            },
          })
        );
        yield put(actions.topicsAndSegmentsFetchSuccess());
        if(successCallback) {
          successCallback(response.data);
        }
      }
    }
  } catch (error) {
    console.error("fetch topics saga error", error);
    if (errorCallback) {
      errorCallback(error);
    }
    yield put(actions.topicsAndSegmentsFetchFail({ error }));
  }
}

const showSaga = makeShowSaga("experiences", actions, actions);

export function* bulkAutoMappingProgressSaga(action) {
  console.log("bulkAutoMappingProgressSaga action ==>", action);
  let url = "get_bulk_auto_mapping_progress.json";

  const { successCallback, errorCallback } = action.options;
  try {
    yield put(actions.bulkAutoMappingProgressStart());
    const response = yield axiosInstance.instance.get(url, {
      params: action.payload,
    });

    if (response && response.data) {
      if (successCallback) {
        successCallback(response.data);
        yield put(actions.bulkAutoMappingProgressSuccess(response.data));
      }
    }
  } catch (error) {
    console.error("bulkAutoMappingProgressSaga error", error);
    if (errorCallback) {
      const errorMessage = error.response?.data?.message || error.message;
      errorCallback(error);
      yield put(actions.bulkAutoMappingProgressFail({ error: errorMessage }));
    }
  }
}


export function* getTranslateAssessmentStatusSaga(action) {
  console.log("getTranslateAssessmentStatusSaga action ==>", action);
  const url = "get_translation_status.json";
  const { successCallback, errorCallback } = action.options;

  try {
    yield put(actions.translateAssessmentStatusStart());
    const response = yield axiosInstance.instance.get(url, {
      params: action.payload,
    });

    if (response?.data) {
      yield put(actions.translateAssessmentStatusSuccess(response.data));
      successCallback?.(response.data);
    }
  } catch (error) {
    console.error("getTranslateAssessmentStatusSaga error", error);
    const errorMessage = error.response?.data?.message || error.message;
    yield put(actions.translateAssessmentStatusFail({ error: errorMessage }));
    errorCallback?.(error);
  }
}
export function* getGradeAllSubmissionsWithAIStatusSaga(action) {
  console.log("getGradeAllSubmissionswithAIStatusSaga action ==>", action);
  const url = "get_grade_submissions_with_ai_status.json";
  const { successCallback, errorCallback } = action.options;

  try {
    yield put(actions.gradeAllSubmissionsWithAiStatusStart());

    const response = yield axiosInstance.instance.get(url, {
      params: action.payload,
    });

    if (response?.data) {
      yield put(actions.gradeAllSubmissionsWithAiStatusSuccess(response.data));
      successCallback?.(response.data);
    }
  } catch (error) {
    console.error("getGradeAllSubmissionswithAIStatusSaga error", error);
    const errorMessage = error.response?.data?.message || error.message;
    yield put(
      actions.gradeAllSubmissionsWithAiStatusFail({ error: errorMessage })
    );
    errorCallback?.(error);
  }
}


export const watchExperiences = reduxCrud.generateWatchSaga({
  [actionTypes.FETCH_WITH_JOIN_CODE_EXPERIENCE]: fetchWithJoinCodeSaga,
  [actionTypes.VERIFY_JOIN_CODE_EXPERIENCE]: verifyJoinCodeSaga,
  [actionTypes.MAKE_EXPERIENCE_USER_EXPERIENCE]: makeExperienceUserSaga,
  [actionTypes.SET_CONFIG_EXPERIENCE]: setConfigSaga,
  [actionTypes.PRINT_EXPERIENCE]: printSaga,
  [actionTypes.SHOW_EXPERIENCE]: showSaga,
  [actionTypes.UPDATE_STATUS_EXPERIENCE]: updateStatusSaga,
  [actionTypes.EXPORT_EXPERIENCE]: exportSaga,
  [actionTypes.EXPORT_STATUS_EXPERIENCE]: exportStatusSaga,
  [actionTypes.BULK_AUTO_MAPPING_PROGRESS_EXPERIENCE]: bulkAutoMappingProgressSaga,
  [actionTypes.GET_ANALYTICS_EXPERIENCE]: getAnalyticsSaga,
  [actionTypes.UPDATE_MEMBERSHIP_EXPERIENCE]: updateMembershipSaga,
  [actionTypes.TOPICS_AND_SEGMENTS_FETCH_EXPERIENCE]: topicsAndSegmentsFetchSaga,
  [actionTypes.TRANSLATE_ASSESSMENT_STATUS_EXPERIENCE]: getTranslateAssessmentStatusSaga,
  [actionTypes.GRADE_ALL_SUBMISSIONS_WITH_AI_STATUS_EXPERIENCE]: getGradeAllSubmissionsWithAIStatusSaga
});
