import React, { useState } from "react";
import { Modal, Select, Row, Col, Input } from "antd";
import { actions as experienceReduxActions } from "/src/views/Experiences/redux";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { availableLanguageOptionsForAI } from "/src/lib/utils/helperMethods";
import { activeAdjustedExperienceIdSelector } from "../../Experiences/selector";

const { TextArea } = Input;

// NOT USED ANYMORE
const AIGradingSettingsModal = (props) => {
  const { setIsAIGradingSettingsModal, aiGradingSettings } = props;

  console.log("this is aiGradingSettings==>", aiGradingSettings);

  const dispatch = useDispatch();
  const mrIntl = useTranslate();
  const activeExperienceId = useSelector(activeAdjustedExperienceIdSelector());
  const [gradingSettings, setGradingSettings] =
    useState(aiGradingSettings) || {};

  const gradingModeOptions = [
    { label: "Lenient", value: "lenient" },
    { label: "Standard", value: "standard" },
    { label: "Strict", value: "strict" },
  ];

  // console.log("this is gradingState==>", {
  //   aiGradingSettings,
  //   gradingSettings,
  // });

  const feedbackLengthOptions = [
    { label: "Brief", value: "brief" },
    { label: "Standard", value: "standard" },
    { label: "Detailed", value: "detailed" },
  ];

  const feedbackToneAndStyleOptions = [
    { label: "Encouraging", value: "encouraging" },
    { label: "Neutral", value: "neutral" },
    { label: "Constructive", value: "constructive" },
    { label: "Formal", value: "formal" },
    { label: "Friendly", value: "friendly" },
  ];

  const languageOptions = availableLanguageOptionsForAI.map((language) => ({
    value: language.toLowerCase(),
    label: language,
  }));

  const handleOk = () => {
    dispatch(
      experienceReduxActions.setAiGradingSettingsSuccess(
        {
          aiGradingSettings: gradingSettings,
        }
      )
    );
    setIsAIGradingSettingsModal(false);
  };

  const handleCancelSetting = () => {
    setIsAIGradingSettingsModal(false);
  };

  const debouncedSetInstructions = debounce((value) => {
    // using to avoid unnecessary rendering when we set instructions in the tempGradingSetting
    setGradingSettings((gradingSettings) => ({
      ...gradingSettings,
      additionalInstructions: value,
    }));
  }, 500);

  return (
    <>
      <Modal
        open={true}
        title={mrIntl("AIGradingSettingsModal.settings")}
        onOk={handleOk}
        onCancel={handleCancelSetting}
        wrapClassName="settings-modal"
        okText={mrIntl("CommonText.save")}
      >
        <Row className="m-b-20">
          <Col span={24}>
            <label className="m-b-5">
              {mrIntl("AIGradingSettingsModal.grading_mode")}
            </label>
            <Select
              options={gradingModeOptions}
              defaultValue={aiGradingSettings.strictness}
              style={{ width: "100%" }}
              onChange={(value) =>
                setGradingSettings({
                  ...gradingSettings,
                  strictness: value,
                })
              }
            ></Select>
          </Col>
        </Row>
        <Row className="m-b-20">
          <Col span={11}>
            <label className="m-b-5">
              {mrIntl("AIGradingSettingsModal.feedback_language")}
            </label>
            <Select
              showSearch
              defaultValue={aiGradingSettings.feedback_language}
              options={languageOptions}
              style={{ width: "100%" }}
              onChange={(value) =>
                setGradingSettings({
                  ...gradingSettings,
                  feedbackLanguage: value,
                })
              }
            ></Select>
          </Col>
          <Col offset={2} span={11}>
            <label className="m-b-5">
              {mrIntl("AIGradingSettingsModal.feedback_length")}
            </label>
            <Select
              style={{ width: "100%" }}
              defaultValue={aiGradingSettings.feedback_length}
              options={feedbackLengthOptions}
              onChange={(value) =>
                setGradingSettings({
                  ...gradingSettings,
                  feedbackLength: value,
                })
              }
            ></Select>
          </Col>
        </Row>

        <Row className="m-b-20">
          <Col span={24}>
            <label className="m-b-5">
              {mrIntl("AIGradingSettingsModal.feedback_tone_and_style")}
            </label>
            <Select
              style={{ width: "100%" }}
              options={feedbackToneAndStyleOptions}
              defaultValue={aiGradingSettings.feedback_tone}
              onChange={(value) =>
                setGradingSettings({
                  ...gradingSettings,
                  feedbackTone: value,
                })
              }
            ></Select>
          </Col>
        </Row>

        <Row className="m-b-20">
          <Col span={24}>
            <label className="m-b-5">
              {mrIntl("AIGradingSettingsModal.additional_instructions")}
            </label>
            <TextArea
              maxLength={500}
              onChange={(e) => {
                const value = e.target.value;
                debouncedSetInstructions(value);
              }}
              defaultValue={gradingSettings.additional_instructions}
              autoSize={{ minRows: 5, maxRows: 5 }}
              placeholder={mrIntl(
                "AIGradingSettingsModal.provide_additional_instructions_for_the_ai_grader_eg_prioritize_creativity"
              )}
            ></TextArea>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
export default AIGradingSettingsModal;
