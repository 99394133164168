import React, { useEffect, useRef, useState} from "react";
import { Button, Modal, Space, Tag, Tooltip, Upload, Row, Col } from "antd";
import { message } from "/src/components/UI/AntdAppHelper";
import axios from "axios";
import { HiOutlineStop } from "react-icons/hi";
import { CameraOutlined } from "@ant-design/icons";
import { checkMob, getPermissions } from "/src/lib/utils/helperMethods";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { showConfirmModal, showInfoModal } from "../Segment/UIHelper";
import ThumbnailAttachments from "/src/components/UI/Attachments/ThumbnailAttachments/ThumbnailAttachments"
import PreviewAttachment from "../Attachments/PreviewAttachment";
import Spinner from "../Spinner/Spinner";
import { useDispatch } from "react-redux";
import { actions as experienceReduxActions } from "/src/views/Experiences/redux";
import "./ImageCaptureUploadModal.scss"
// import Cropper from 'cropperjs';
// import 'cropperjs/dist/cropper.css';

export const CustomCropper = ({ src, setCroppedImage, canvasWidth, canvasHeight }) => {
  const containerRef = useRef(null);
  const canvasRef = useRef(null);
  const [image, setImage] = useState(null);
  const [cropBox, setCropBox] = useState(null);
  const [isResizing, setIsResizing] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [resizeHandle, setResizeHandle] = useState("");
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [isSelecting, setIsSelecting] = useState(false);

  useEffect(() => {
    if (src) {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        setImage(img);
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0, img.width, img.height);
        const gap = 7;
        const initialCropBox = {
          x: gap,
          y: gap,
          width: img.width - 2 * gap,
          height: img.height - 2 * gap,
        };
        setCropBox(initialCropBox);
        updateCroppedImage(image, initialCropBox);
      };
    }
  }, [src]);

  const updateCroppedImage = (img, box) => {
    if (img && box.width > 0 && box.height > 0) {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = box.width;
      canvas.height = box.height;

      const scaleX = img.naturalWidth / canvasRef.current.width;
      const scaleY = img.naturalHeight / canvasRef.current.height;

      ctx.drawImage(
        img,
        box.x * scaleX,
        box.y * scaleY,
        box.width * scaleX,
        box.height * scaleY,
        0,
        0,
        box.width,
        box.height
      );

      setCroppedImage(canvas.toDataURL());
    }
  };

  const handleMouseDown = (e) => {
    const rect = containerRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    if (cropBox) {
      const withinX = x >= cropBox.x && x <= cropBox.x + cropBox.width;
      const withinY = y >= cropBox.y && y <= cropBox.y + cropBox.height;
      const edgeThreshold = 10;

      // Check if click is on resize handles first
      const isOnHandle = checkIfOnResizeHandle(x, y, cropBox, edgeThreshold);

      if (isOnHandle) {
        setIsResizing(true);
        setResizeHandle(isOnHandle);
      } else if (withinX && withinY) {
        // If not on handle but within crop box, enable dragging
        setIsDragging(true);
      } else {
        // If outside crop box, start new selection
        setIsSelecting(true);
        setCropBox({ x, y, width: 0, height: 0 });
      }
    } else {
      setIsSelecting(true);
      setCropBox({ x, y, width: 0, height: 0 });
    }

    setStartX(x);
    setStartY(y);
  };

  const checkIfOnResizeHandle = (x, y, box, threshold) => {
    // Corner handles
    if (Math.abs(x - box.x) <= threshold && Math.abs(y - box.y) <= threshold) {
      return "top-left";
    }
    if (Math.abs(x - (box.x + box.width)) <= threshold && Math.abs(y - box.y) <= threshold) {
      return "top-right";
    }
    if (Math.abs(x - box.x) <= threshold && Math.abs(y - (box.y + box.height)) <= threshold) {
      return "bottom-left";
    }
    if (Math.abs(x - (box.x + box.width)) <= threshold && Math.abs(y - (box.y + box.height)) <= threshold) {
      return "bottom-right";
    }

    // Middle handles
    if (Math.abs(x - (box.x + box.width / 2)) <= threshold && Math.abs(y - box.y) <= threshold) {
      return "top";
    }
    if (Math.abs(x - (box.x + box.width)) <= threshold && Math.abs(y - (box.y + box.height / 2)) <= threshold) {
      return "right";
    }
    if (Math.abs(x - (box.x + box.width / 2)) <= threshold && Math.abs(y - (box.y + box.height)) <= threshold) {
      return "bottom";
    }
    if (Math.abs(x - box.x) <= threshold && Math.abs(y - (box.y + box.height / 2)) <= threshold) {
      return "left";
    }

    return false;
  };

  const handleMouseMove = (e) => {
    const rect = containerRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    if (isSelecting) {
      const newWidth = x - startX;
      const newHeight = y - startY;
      setCropBox((prev) => ({
        ...prev,
        x: newWidth < 0 ? x : startX,
        y: newHeight < 0 ? y : startY,
        width: Math.abs(newWidth),
        height: Math.abs(newHeight),
      }));
    } else if (isDragging && !isResizing && cropBox) {
      const newX = Math.max(2, Math.min(x - startX + cropBox.x, canvasRef.current.width - cropBox.width - 2));
      const newY = Math.max(2, Math.min(y - startY + cropBox.y, canvasRef.current.height - cropBox.height - 2));
      setCropBox((prev) => ({
        ...prev,
        x: newX,
        y: newY,
      }));
      setStartX(x);
      setStartY(y);
      updateCroppedImage(image, { ...cropBox, x: newX, y: newY });
    }

    if (isResizing) {
      let newBox = { ...cropBox };
      if (resizeHandle === "bottom-right") {
        newBox.width = Math.max(50, Math.min(x - newBox.x, canvasRef.current.width - newBox.x - 2));
        newBox.height = Math.max(50, Math.min(y - newBox.y, canvasRef.current.height - newBox.y - 2));
      } else if (resizeHandle === "top-left") {
        const newX = Math.max(2, Math.min(x, newBox.x + newBox.width - 50));
        const newY = Math.max(2, Math.min(y, newBox.y + newBox.height - 50));
        newBox.width = newBox.x + newBox.width - newX;
        newBox.height = newBox.y + newBox.height - newY;
        newBox.x = newX;
        newBox.y = newY;
      } else if (resizeHandle === "top-right") {
        const newY = Math.max(2, Math.min(y, newBox.y + newBox.height - 50));
        newBox.width = Math.max(50, Math.min(x - newBox.x, canvasRef.current.width - newBox.x - 2));
        newBox.height = newBox.y + newBox.height - newY;
        newBox.y = newY;
      } else if (resizeHandle === "bottom-left") {
        const newX = Math.max(2, Math.min(x, newBox.x + newBox.width - 50));
        newBox.width = newBox.x + newBox.width - newX;
        newBox.height = Math.max(50, Math.min(y - newBox.y, canvasRef.current.height - newBox.y - 2));
        newBox.x = newX;
      } else if (resizeHandle === "left") {
        const newX = Math.max(2, Math.min(x, newBox.x + newBox.width - 50));
        newBox.width = newBox.x + newBox.width - newX;
        newBox.x = newX;
      } else if (resizeHandle === "right") {
        newBox.width = Math.max(50, Math.min(x - newBox.x, canvasRef.current.width - newBox.x - 2));
      } else if (resizeHandle === "top") {
        const newY = Math.max(2, Math.min(y, newBox.y + newBox.height - 50));
        newBox.height = newBox.y + newBox.height - newY;
        newBox.y = newY;
      } else if (resizeHandle === "bottom") {
        newBox.height = Math.max(50, Math.min(y - newBox.y, canvasRef.current.height - newBox.y - 2));
      }
      setCropBox(newBox);
      updateCroppedImage(image, newBox);
    }
  };

  const handleMouseUp = (e) => {
    // Reset all interaction states
    setIsSelecting(false);
    setIsDragging(false);
    setIsResizing(false);
    setResizeHandle("");

    // Only update cropped image if we were selecting
    if (isSelecting) {
      updateCroppedImage(image, cropBox);
    }
  };

  const handleMouseLeave = () => {
    setIsSelecting(false);
    setIsDragging(false);
    setIsResizing(false);
    setResizeHandle("");
  };

  const handleOutsideClick = (e) => {
    const rect = containerRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    // Only update the crop box if it doesn't exist or is invalid
    if (!cropBox || cropBox.width <= 0 || cropBox.height <= 0) {
      const newBox = {
        x: Math.max(2, Math.min(x - 50, canvasRef.current.width - 100 - 2)),
        y: Math.max(2, Math.min(y - 50, canvasRef.current.height - 100 - 2)),
        width: 100,
        height: 100,
      };
      setCropBox(newBox);
      updateCroppedImage(image, newBox);
    }
  };

  return (
    <div
      ref={containerRef}
      className={`cropper-container ${isDragging ? "dragging" : ""} ${isResizing ? "resizing" : ""}`}
      style={{ aspectRatio: `${canvasWidth / canvasHeight}` }}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseLeave}
      onClick={handleOutsideClick}
    >
      <div className="canvas-wrapper">
        <canvas ref={canvasRef} className="cropper-canvas" ></canvas>
      </div>

      {cropBox && (
        <>
          <div
            className="crop-box"
            style={{
              left: cropBox.x,
              top: cropBox.y,
              width: cropBox.width,
              height: cropBox.height,
            }}
          />
          {["top-left", "top-right", "bottom-left", "bottom-right"].map((position) => (
            <div
              key={position}
              className={`resize-handle ${position}`}
              style={{
                ...(position === "top-left" && { left: cropBox.x + 1, top: cropBox.y + 1 }),
                ...(position === "top-right" && { left: cropBox.x + cropBox.width - 8, top: cropBox.y }),
                ...(position === "bottom-left" && { left: cropBox.x, top: cropBox.y + cropBox.height - 8 }),
                ...(position === "bottom-right" && { left: cropBox.x + cropBox.width - 8, top: cropBox.y + cropBox.height - 8 }),
              }}
            />
          ))}
          {/* Add mid-point resize handles */}
          {["top", "right", "bottom", "left"].map((position) => (
            <div
              key={position}
              className={`resize-handle ${position}-mid`}
              style={{
                ...(position === "top" && { left: cropBox.x + cropBox.width / 2, top: cropBox.y - 1 }),
                ...(position === "right" && { left: cropBox.x + cropBox.width - 6, top: cropBox.y + cropBox.height / 2 - 7 }),
                ...(position === "bottom" && { left: cropBox.x + cropBox.width / 2, top: cropBox.y + cropBox.height - 6 }),
                ...(position === "left" && { left: cropBox.x - 3, top: cropBox.y + cropBox.height / 2 - 7 }),
              }}
            />
          ))}
        </>
      )}
    </div>
  );
};
const ImageCaptureUploadModal = (props) => {
  const {
    attachments,
    mediaFormSetting,
    setMediaFormSetting,
    video = true,
  } = props;
  const mrIntl = useTranslate();
  const dispatch = useDispatch();
  const [image, setImage] = useState();
  
  const notDeletableFiles = mediaFormSetting.selectConfig.notDeletableFiles || [];

  const [stream, setStream] = useState(null);
  const [mediaRecorder, setMediaRecorder] = useState({});
  const mediaRecordConstraints = {
    audio: false,
    video: true,
  };
  const [croppedImage, setCroppedImage] = useState(null);
  const [recorder, setRecorder] = useState({});
  const [fileSetting, setFileSetting] = useState({});
  
  useEffect(() => {
    if (recorder.state) {
      setMediaFormSetting({
        ...mediaFormSetting,
        okDisabled: recorder.state !== "stopped",
      });
    }
    console.log("recorder state changed ==>", recorder, recorder.state);
    return () => {
      stopStream()
    }
  }, [recorder.state]);
  const [loading, setLoading] = useState(false);
  const [askPermission, setAskPermission] = useState(false);
  const [saving, setSaving] = useState(false);

  const initMediaRecorder = (stream, callback) => {
    console.log("come in stream=====>");
    setAskPermission(false)
    setLoading(true);
    setMediaFormSetting((mediaFormSetting) => {
      return {
        ...mediaFormSetting,
        video: true,
        visible: true,
      };
    });
    setStream(stream);
    const streamContainer = document.querySelector("#stream_video");
    if (streamContainer) {
      streamContainer.style.display = "block";
      streamContainer.srcObject = stream;
      streamContainer.captureStream =
        streamContainer.captureStream || streamContainer.mozCaptureStream;
      // Add onloadedmetadata event listener to handle when video is ready
      streamContainer.onloadedmetadata = () => {
        setLoading(false);
      };
      setRecorder({
        ...recorder,
        state: "recording",
      });
      if (typeof callback === 'function') {
        callback();
      }
    }
  };

  const restartCapture = () => {
    setCroppedImage(null);
    startRecording(); 
  };
  console.log("record==>",recorder.state);

  const startRecording = () => {
    getPermissions(mediaRecordConstraints, {
      successCallback: (stream) => {
        initMediaRecorder(stream, () => setImage(null));
      },
      errorCallback: (error) => {
        errorNotSupported(error);
      },
    });
  };

  const setSkipFocusLostModal = (value) => {
    console.log("setSkipFocusLostModal ==>", value)
    dispatch(
      experienceReduxActions.setSkipFocusLostModalSuccess({
        skipFocusLostModal: value,
      })
    );
  };
  const captureImage = () => {
    let streamContainer = document.getElementById("stream_video");
    if (streamContainer) {
      const canvas = document.getElementById("canvas");
      var context = canvas.getContext("2d");
      canvas.width = streamContainer.clientWidth;
      canvas.height = streamContainer.clientHeight;
      context.drawImage(streamContainer, 0, 0, canvas.width,canvas.height);
      setImage(canvas.toDataURL())
      streamContainer.style.display = "none";
      streamContainer.srcObject = null;
      streamContainer.muted = true;
      setRecorder({
        ...recorder,
        saved: false,
        state: "stopped", //mediaRecorder.state,
      });
    }
  };

  const errorNotSupported = () => {
    setAskPermission(false)
    console.log("Recording not supported");
    if (!mediaRecorder.startRecording) {
      showInfoModal({
        type: "warning",
        title: mrIntl("ImageCaptureUploadModal.please_allow_camera"),
        key:'allow-camera-warning'
      });
      return;
    }
  };
  const stopStream = () =>{
    if(stream){
      stream.getTracks().forEach(track => track.stop())
      setStream(null)
    }
  }

  const handleOk = () => {
    console.log("On save media ==>", recorder);
    if (!mediaRecorder) {
      return;
    }

    stopStream()

    setRecorder({
      ...recorder,
      saved: true,
    });

    setSaving(true);
    setMediaFormSetting({
      ...mediaFormSetting,
      okDisabled:true,
      loading: true
    });
    const formData = new FormData();
    if (croppedImage) {
      formData.append("url", croppedImage);
    } else {
      const canvas = document.getElementById("canvas");
      const imgData = canvas.toDataURL(); 
      formData.append("url", imgData);
    }
    
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        token: token,
      },
    };
    const url =
      (import.meta.env.VITE_API_URL || "/backend/api/v1/") + "attachments?" + mediaFormSetting.extraParams;
      axios.post(url, formData, config).then(
      (response) => {
        let responseData = response.data;
        const newAttachments = [...attachments];
        newAttachments.push(responseData);
        if (mediaFormSetting.onChangeSaveFiles) {
          mediaFormSetting.onChangeSaveFiles(newAttachments);
        }
        resetState();

        setMediaFormSetting({
          ...mediaFormSetting,
          from: "",
          modal: false,
          visible: false,
          loading: false,
          triggerSaveAction: 0,
        });
          setSaving(false);
      },
      (error) => {
        message.error(mrIntl("MediaRecorder.something_went_wrong"));
        setMediaFormSetting({
          ...mediaFormSetting,
          okDisabled: false,
          visible: false,
        });
        setSaving(false);
      }
    );
  };
  

  const handleCancel = () => {
    setRecorder({});
    stopStream()
    setMediaFormSetting({
      ...mediaFormSetting,
      from: "",
      visible: false,
    });
    resetState();
  };

  const showModal = () => {
    //askPermission to fix multiple time show the info modal when user multi click when browser ask permission modal open
    setImage(null);
    setCroppedImage(null);
    if (!askPermission) {
      setAskPermission(true);
      setSkipFocusLostModal(true);
      setTimeout(() => {
        setSkipFocusLostModal(false);
      }, 2000);
      startRecording();
    }
  };

  const resetState = () => {
    setRecorder({});
  };

  const onRemove = (file) => {
    if (notDeletableFiles.find((f) => f.uid === file.uid)) {
      showInfoModal({
        type: "warning",
        title:
          "This file cannot be deleted now because it is already submitted",
        content: mediaFormSetting.deleteNotAllowedMessage,
      });
      return false;
    } else {
      return new Promise((removeResolve) => {
        showConfirmModal({
          // okButtonProps: { loading: true },
          centered: true,
          title: mrIntl("CommonText.confirm_delete"),
          onOk: (close) => {
            removeResolve(true);
            return new Promise((okResolve) => {
              console.log("on remove ==>", file);

              if (mediaFormSetting.onRemoveFile) {
                mediaFormSetting.onRemoveFile(file);
              }

              okResolve(close);
            });
          },
          onCancel: () => {
            removeResolve(false);
          },
        });
      });
    }
  };

  const onPreview = async (file) => {
    const fileResponse = file.response;
    if (fileResponse && fileResponse.status === "done") {
      file = fileResponse;
    }
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    setFileSetting({
      ...fileSetting,
      previewFile: {
        ...file,
        url: src,
      },
    });
  };

  
  const getForm = () => {
    const showCanvas = !["recording", "paused"].includes(recorder.state);
    const canvas = document.getElementById("canvas");
    const canvasWidth = canvas?.width;
    const canvasHeight = canvas?.height;
    return (
      <div>
        <div className="recorder-preview">
          {loading && <Spinner />}
          <video
            className="video-input"
            id="stream_video"
            autoplay="autoplay"
            disablePictureInPicture
            style={{
              visibility:
                recorder.state == "recording" || recorder.state == "paused"
                  ? "visible"
                  : "hidden",
              height: "360",
              display: loading ? "none" : "block"
            }}
          />
          <canvas
            id="canvas"
            width="640"
            height="480"
            hidden
          ></canvas>
          {/* Only show CustomCropper when there is an image */}
          {image && (
            <CustomCropper 
              src={image} 
              setCroppedImage={setCroppedImage}
              canvasWidth={canvasWidth}
              canvasHeight= {canvasHeight}
              viewMode={3}
              zoomTo={0.5}
            
            />
          )}
        </div>
      </div>
    );
  };

  const getFooter = () => {
    return [
      <Row align="middle">
        <Col span={14} >
          {recorder.state === "stopped" && (
            <Button
              onClick={(e) => restartCapture()}
              size="large"
              shape="round"
              type="primary"
              ghost={true}
              icon={<CameraOutlined />}
              disabled={saving}
            >
              {mrIntl("ImageCaptureUploadModal.retake_picture")}
            </Button>
          )}
          {(recorder.state == "recording" ||
            recorder.state == "paused") && (
              <Button
                onClick={(e) => captureImage()}
                size="large"
                shape="round"
                className="capture-icon"
                icon={<HiOutlineStop />}
              >
                {mrIntl("ImageCaptureUploadModal.take_a_picture")}
              </Button>
            )}
        </Col>
        <Col span={10}>
          <Space>
            <Button
              key="back"
              onClick={(e) => handleCancel()}
              disabled={mediaFormSetting.okDisabled && mediaFormSetting.loading}
            >
              {mediaFormSetting.cancelText || "Return"}
            </Button>
            <Button
              key="submit"
              type="primary"
              onClick={(e) => handleOk()}
              loading={mediaFormSetting.okDisabled && mediaFormSetting.loading}
              disabled={mediaFormSetting.okDisabled}
            >
              {mediaFormSetting.okText || mrIntl("CommonText.submit")}
            </Button>
          </Space>
        </Col>
      </Row>
    ];
  };

  const showMaxmLimitReachedMessage = () => {
    message.error(mediaFormSetting.limitMessage || "Max upload limit reached");
  };

  let finalRender = [],
  uploadComponent = null;

  const limit = mediaFormSetting.limit;
  let maxLimitReached = attachments.length >= limit;

  uploadComponent = 
    mediaFormSetting.imageCaptureConfig.showImageCaptureUploader && (
    <Tooltip
      title={mediaFormSetting.imageCaptureConfig.disabledUploaderTooltip}
    >
      <span
        className="image-upload-centered-element-container"
        style={{ width: "100%", height: "auto"}}
        onClick={(e) => !maxLimitReached ? showModal() : showMaxmLimitReachedMessage()}
      >
        <CameraOutlined />
        <div style={{ marginTop: "8px" }}> Capture </div>
      </span>
    </Tooltip>
  );
  finalRender = [
    <ThumbnailAttachments
      attachments={attachments}
      listProps={{
        showDownloadIcon: false,
        listType: "picture-card",
        onPreview: onPreview,
        onRemove: onRemove,
        openFileDialogOnClick: false,
      }}
      componentAfterThumbnails={uploadComponent}
    />,
  ];

  finalRender.push(
    <PreviewAttachment
      type={"modal"}
      visible={fileSetting.previewFile}
      file={fileSetting.previewFile}
      onCancel={() =>
        setFileSetting({
          ...fileSetting,
          previewFile: null,
        })
      }
    />
  );

  if (props.modal && mediaFormSetting.visible) {
    finalRender.push(
      <Modal
        {...mediaFormSetting}
        width={checkMob() ? "" : "50%"}
        centered={checkMob() ? true : false}
        className={checkMob() ? "height-centered-modal" : ""}
        open={mediaFormSetting.visible}
        closable={false}
        maskClosable={false}
        keyboard={false}
        title={mediaFormSetting.title || "Capture Image"}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={getFooter()}
        style={{ background: "white", paddingBottom: "0px" }}
        bodyStyle={{ overflow: "hidden" }}
        wrapClassName="capture-upload-modal"
        loading={false}
      >
        <div className="media-recorder-form">{getForm()}</div>
      </Modal>
    );
  }

  return finalRender;
};
export default ImageCaptureUploadModal;
