import React, {useEffect } from "react";
import { Redirect, useRouteMatch } from "react-router-dom";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  UploadOutlined,
  SettingOutlined,
  UnorderedListOutlined,
  HomeOutlined,
  BookOutlined,
  InfoCircleOutlined,
  DatabaseOutlined,
  CloudDownloadOutlined,
  ProfileOutlined,
  BankOutlined,
  UserOutlined,
  TeamOutlined,
  DollarOutlined,
  CommentOutlined,
  BarChartOutlined,
  FileAddOutlined,
  SecurityScanOutlined,
  AppstoreOutlined
} from "@ant-design/icons";
// import logo from "../logo.svg";
import "./App.scss";
import { StyleProvider, legacyLogicalPropertiesTransformer } from '@ant-design/cssinjs';
// import "@ckeditor/ckeditor5-super-build/build/styles.css"
import { Button, Dropdown, Menu, Space, Tag, Tooltip, App as AntdApp, ConfigProvider, theme, message} from "antd";
// import WrappedNormalLoginForm from "../views/Auth/Login/Login";
import {
  appThemeSelector,
  enabledFeaturesSelector,
  isAuthenticatedSelector,
  partnerNameSelector,
  tokenPresentSelector,
} from "../views/Auth/Login/selector";
import { actions as loginActions } from "../views/Auth/Login/redux";
import { actions as firestoreReduxActions } from "../views/Experiences/ExperienceShow/FirestoreInteractions/redux";
// import { getRoutes } from "../routes/routes";
import commonMessages from "/src/translations/commonMessages";
// import AppLayout from "../components/Layouts/AppLayout/AppLayout";
import AuthLayout from "/src/components/Layouts/AuthLayout/AuthLayout";
import EmbedLayout from "/src/components/Layouts/EmbedLayout/EmbedLayout";
import ExperienceLayout from "/src/components/Layouts/ExperienceLayout/ExperienceLayout";
import { MrApp } from "mr_react_framework";
import AppConstants from "/src/views/AppConstants/AppConstants";
import Login from "/src/views/Auth/Login/Login";
import Orgs from "/src/views/Orgs/Orgs";
import SubjectResources from "/src/views/ProgrammeSubjects/SubjectResources/SubjectResources";
import UiComponents from "../components/UiComponents/UiComponents";

// import { IntlProvider, defineMessages } from "react-intl";
// import * as locale_de from "react-intl/locale-data/de";
// import * as locale_fr from "react-intl/locale-data/fr";
// import * as locale_ar from "react-intl/locale-data/ar";
// import * as locale_zh from "react-intl/locale-data/zh";
// import messages_en from "/src/translations/en.json";
import messages_en from "/src/lang/en_US.json";
import messages_fr from "/src/lang/fr_FR.json";
import messages_es from "/src/lang/es_ES.json";
import messages_zh from "/src/lang/zh_ZH.json";
import messages_de from "/src/lang/de_DE.json";
import messages_pt from "/src/lang/pt_PT.json";
import messages_tr from "/src/lang/tr_TR.json";
import messages_ko from "/src/lang/ko_KO.json";
import messages_ja from "/src/lang/ja_JA.json";
import messages_hi from "/src/lang/hi_IN.json";
// import messages_fr from "/src/translations/fr.json";
// import messages_zh from "/src/translations/zh.json";
import commonSelectors from "./commonSelectors";
import Programmes from "/src/views/Programmes/Programmes";
import OrgProgrammes from "/src/views/OrgProgrammes/OrgProgrammes";
import Boards from "/src/views/Boards/Boards";
import Rubrics from "/src/views/Rubrics/Rubrics";
import Questionbanks from "/src/views/Questionbanks/Questionbanks";
import Plans from "/src/views/Plans/Plans";
import Users from "/src/views/Users/Users";
import Groups from "/src/views/Groups/Groups";
import Integrations from "/src/views/Integrations/Integrations";
import Experiences from "/src/views/Experiences/Experiences";
import Segments from "/src/views/Segments/Segments";
import FbDashboard from "/src/views/SuperAdminDashboard/fbDashboard";
import LogoImageHor from "../assets/images/logo-hor.png";
import LogoImageCollapsed from "../assets/images/logo.png";
import {
  checkIPAD
} from "../lib/utils/helperMethods";
import OfflineApps from "/src/views/OfflineApps/OfflineApps";
import Help from "/src/views/Help/Help";
import Profile from "/src/views/Profile/Profile";
import OrgItemDetailWrapper from "/src/views/Orgs/OrgItemDetail/OrgItemDetailWrapper";
import LibraryPage from "/src/views/Library/LibraryPage";
import { checkMob } from "/src/lib/utils/helperMethods";
import EmbeddedResourceItemDetailUuid from "/src/views/EmbeddedResources/EmbeddedResourceItemDetailUuid/EmbeddedResourceItemDetailUuid";
import PrintLayout from "/src/components/Layouts/PrintLayout/PrintLayout";
import MaintenanceLayout from "/src/components/Layouts/MaintenanceLayout/MaintenanceLayout";
import DemoResettingComponent from "/src/components/Maintenance/DemoResettingComponent";
import ExperiencePrint from "/src/views/Experiences/ExperienceShow/ExperienceManage/ExperiencePrint/ExperiencePrint";
import {
  appTypeSelector,
  appValidSelector,
} from "./OfflineApp/offlineAppSelectors";
import LibraryExperiences from "/src/views/Experiences/LibraryExperiences/LibraryExperiences";
import _ from "lodash";
import Overview from "/src/views/Overview/Overview";
import OauthCallback from "/src/views/Auth/Oauth/OauthCallback";
import { CheckAndSetAppType } from "./OfflineApp/CheckAndSetAppType";
import ErrorLayout from "/src/components/Layouts/ErrorLayout/ErrorLayout";
import ErrorPage from "/src/components/Error/ErrorPage";
// import StartInSebBtn from "/src/components/UI/SEB/StartInSebBtn";
import QuitBtn from "/src/components/UI/QuitBtn";
import ForgotPassword from "/src/views/Auth/ForgotPassword/ForgotPassword";
import SetPassword from "/src/views/Auth/SetPassword/SetPassword";
import GuestJoinCode from "/src/views/Auth/GuestJoinCode/GuestJoinCode";
import EnvSelection from "/src/components/UI/EnvSelection/EnvSelection"
import Features from "../views/Features/Features";
import Feedbacks from "/src/views/Feedbacks/feedbacks";
import FeedbackModal from "/src/views/Feedbacks/FeedbackModal";
import SuperadminDashboard from "/src/views/SuperAdminDashboard/SuperadminDashboard";
import AppNotice from "/src/components/UI/AppNotice/AppNotice";
import Tags from "/src/views/Tags/Tags";
import OrgUserSwitcher from "/src/components/UI/OrgUserSwitcher/OrgUserSwitcher";
import { actions as loginReduxActions } from "/src/views/Auth/Login/redux";
import { BiMessageRoundedError } from "react-icons/bi"
import LogoutDropdown from "/src/components/UI/LogoutDropdown/LogoutDropdown";
import TopLeftNavExtra from "/src/components/UI/TopLeftNavExtra/TopLeftNavExtra";
import MrTranslate, { mrTranslate, useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import SignUp from "/src/views/Auth/SignUp/SignUp";
import IcpText from "/src/components/UI/IcpText/IcpText";
import Gradebook from "/src/views/Gradebook/Gradebook";
import Prompts from "/src/views/Prompts/prompts";
import loadable from "@loadable/component";
import AntdAppHelper from "/src/components/UI/AntdAppHelper";
import MyAssessments from "/src/views/MyAssessment/MyAssessments";
import OrgPermissions from "/src/views/OrgProgrammes/OrgPermissions/OrgPermissions";
import MaintenancePage from "/src/components/Maintenance/MaintenancePage"
import HelpCenterButton from "/src/components/UI/HelpCenterButton/HelpCenterButton";
import { actions as offlineAppActions } from "/src/App/OfflineApp/offlineAppRedux";
import { CheckNativeAppUpdate } from "./OfflineApp/CheckNativeAppUpdate";
import UnsupportedVersionLayout from "../components/Layouts/UnsupportedVersionLayout/UnsupportedVersionLayout";
import UnsupportedVersionNotice from "../components/UI/UnsupportedVersionNotice/UnsupportedVersionNotice";
import { SurveyLinkAnnouncement } from "../components/UI/SurveyLinkAnnouncement/SurveyLinkAnnouncement";
import UpgradePlanModal from "../lib/UpgradePlanFeature/UpgradePlanModal";
// import AntdAppHelper1 from "/src/components/UI/AntdAppHelper";
import "./v3AntdOverrides.scss";
import { v3ThemeOptions } from "./Themes/v3ThemeOptions";
import { getShowExperiencePreviewSelector } from "../views/Experiences/selector";
import { checkIfUnsupportedApp, getAppVersion, getDeviceInfo } from "./OfflineApp/offlineAppHelper";
import FeaturesModal from "../components/UI/FeaturesModal/FeaturesModal";

// const legacyBrowsersSupportCSS = loadable.lib(() => import('./LegacyBrowsersSupportCSS.scss'))

// const messages_zh = React.lazy(() => import("translations/zh.json"));
// require("react-dom");
// window.React2 = require("react");
// console.log("window.React1 === window.React2", window.React1 === window.React2);
// if (!Intl.PluralRules) {
//   require("@formatjs/intl-pluralrules/polyfill");
//   require("@formatjs/intl-pluralrules/dist/locale-data/de"); // Add locale data for de
//   require("@formatjs/intl-pluralrules/dist/locale-data/fr"); // Add locale data for de
// }

// if (!Intl.RelativeTimeFormat) {
//   require("@formatjs/intl-relativetimeformat/polyfill");
//   require("@formatjs/intl-relativetimeformat/dist/locale-data/de"); // Add locale data for de
//   require("@formatjs/intl-relativetimeformat/dist/locale-data/fr"); // Add locale data for de
// }

// addLocaleData([...locale_de, ...locale_ar, ...locale_fr, ...locale_zh]);
// class App extends Component {
//   componentDidMount() {
//     this.props.onLoadCheckAuthState();
//   }

//   render() {
//     let routes = getRoutes(this.props.isAuthenticated, this.props.tokenPresent);
//     return routes;
//   }
// }

// const makeMapStateToProps = () => {
//   const isAuthenticatedState = isAuthenticatedSelector();
//   const mapStateToProps = (state, props) => {
//     return {
//       //  bar: getBarState(state, props)
//       isAuthenticated: isAuthenticatedState(state, props),
//       tokenPresent: tokenPresentSelector()(state, props),
//       currentUser: currentUserSelector()(state, props)
//     };
//   };
//   return mapStateToProps;
// };

// const mapDispatchToProps = (dispatch, ownProps) => {
//   return {
//     onLoadCheckAuthState: () => {
//       dispatch(loginActions.checkAuthState());
//     }
//     // onTryAutoSignup: () => {
//     //   dispatch(actions.checkAuthState());
//     // },
//   };
// };

// export default withRouter(
//   connect(
//     makeMapStateToProps,
//     mapDispatchToProps
//   )(App)
// );

const localeConfig = {
  en: {
    label: "English",
    messages: messages_en,
    // messages: () => import("lang/en_US.json"),
  },
  fr: {
    label: "French",
    messages: messages_fr,
  },
  es: {
    label: "Spanish",
    messages: messages_es,
  },
  zh: {
    label: "Chinese",
    messages: messages_zh,
  },
  de: {
    label: "German",
    messages: messages_de,
  },
  ko: {
    label: "Korean",
    messages: messages_ko,
  },
  ja: {
    label: "Japanese",
    messages: messages_ja,
  },
  pt: {
    label: "Portuguese",
    messages: messages_pt,
  },
  tr: {
    label: "Turkish",
    messages: messages_tr,
  },
  // hi: {
  //   label: "Hindi",
  //   messages: messages_hi,
  // },
};

// if (import.meta.env.VITE_MODE !== "production") {
//   localeConfig.fr = {
//     label: "French",
//     messages: messages_fr,
//   }
//   localeConfig.es = {
//     label: "Spanish",
//     messages: messages_es,
//   }
//   localeConfig.zh = {
//     label: "Chinese",
//     messages: messages_zh,
//   }
//   // localeConfig.hi = {
//   //   label: "Hindi",
//   //   messages: messages_hi,
//   // }
// }

const TopNavExtra = (props) => {
  // return <span>Extra Items</span>
  const dispatch = useDispatch();
  const mrIntl = useTranslate()

  console.log("TopNavExtra props", props);

  const { currentUser } = props;

  // Todo: create component and add to other layouts also - Exp layout
  const appType = useSelector(appTypeSelector());
  const partnerName = useSelector(partnerNameSelector());
  const enabledFeatures = useSelector(enabledFeaturesSelector()) 
  // const funcName = "exportToDesktop"
  // const tempOfflineAppData = useSelector((state) => {
  //   return state.offlineApp.get(funcName)
  // })
  // const dispatch = useDispatch();
  // const isMobile = checkMob()
  // const isPAD = checkIPAD()
  const deviceInfo = getDeviceInfo(appType);
  // const { path, url } = useRouteMatch();
  const isUnsupportedApp = checkIfUnsupportedApp(appType, enabledFeatures, currentUser);

  useEffect(() => {
    const partnerName = window.name == "21k-assessprep-embed" ? "21k" : null;
    if(partnerName){
      dispatch(loginReduxActions.setPartnerNameSuccess({partnerName: partnerName}))
    }
    let rolesAllowed = ["superadmin", "support", "admin", "teacher"]
    if (rolesAllowed.indexOf(currentUser.role) > -1){
      dispatch(firestoreReduxActions.signInToFirestore({}))
    }
  }, []);
  
  // const obj = {
  //   url: "url_link here",
  //   title: "app.zip"
  // }
  if (import.meta.env.VITE_MAINTENANCE_MODE == "true") {
    return <Redirect to="/maintenance-mode/maintenance-page" />;
  }
  if (isUnsupportedApp) {
    return <Redirect to="/unsupported" />;
  }
  let finalRender = [];
  finalRender.push(<QuitBtn />);
  // finalRender.push(feedbackComponent)

  // if(currentUser.role == "student" && currentUser.org.can_create_lockdown_tests && appType == "web" && !isMobile && !isPAD){
  //   finalRender.push(<StartInSebBtn />)
  // }
  // finalRender.push(appType + appValid)


  finalRender.push(<CheckNativeAppUpdate />);
  
  
  appType != "web" && currentUser.role == "superadmin" && finalRender.push(<EnvSelection/>)

  finalRender.push(
    import.meta.env.VITE_MODE === "development" && <Tag>{currentUser.id}</Tag>
  );
  finalRender.push(
    (import.meta.env.VITE_MODE === "development" ||
      import.meta.env.VITE_MODE === "staging") && (
      <>
        <Tag>
          {appType}-{deviceInfo.os}
        </Tag>
        <Tag>
          {partnerName}
        </Tag>
      </>
    )
  );
  finalRender.push(
    (import.meta.env.VITE_MODE === "development" ||
      import.meta.env.VITE_MODE === "staging") && (
      <Tag>
        {currentUser.org &&
          currentUser.org.org_time &&
          currentUser.org.org_time.utc_offset}
      </Tag>
    )
  );
  (import.meta.env.VITE_MODE === "development") && finalRender.push(<Button size="small" onClick={() => { 
    dispatch(
      loginActions.setAppThemeSuccess({
        appTheme: "v3-light",
      })
    );
  }}> V3 </Button>);
  (import.meta.env.VITE_MODE === "development") && finalRender.push(<Button size="small" onClick={() => { 
    dispatch(
      loginActions.setAppThemeSuccess({
        appTheme: "v2-light",
      })
    );
  }}> V2 </Button>);

  // finalRender.push(
  //   import.meta.env.VITE_MODE === "development" && <Tag>{currentUser.id}</Tag>
  // );
  // finalRender.push(
  //   (import.meta.env.VITE_MODE === "development" ||
  //     import.meta.env.VITE_MODE === "staging") && (
  //     <Tag>
  //       {appType}-{deviceInfo.os}
  //     </Tag>
  //   )
  // );
  // finalRender.push(
  //   (import.meta.env.VITE_MODE === "development" ||
  //     import.meta.env.VITE_MODE === "staging") && (
  //     <Tag>
  //       {currentUser.org &&
  //         currentUser.org.org_time &&
  //         currentUser.org.org_time.utc_offset}
  //     </Tag>
  //   )
  // );
  
  appType === "web" && currentUser.role !== "student" && finalRender.push(
    <FeedbackModal title={false} placement="bottom" icon={<BiMessageRoundedError />} tooltipTitle={mrIntl("App.feedback")}/>
  );

  appType !== "web" && finalRender.push(<Tag>v{getAppVersion(appType)}</Tag>);
  // if(currentUser.org_users.length > 1 && currentUser.role !== "student"){
    // TODO: also give for student after handling pamoja students
    finalRender.push(<OrgUserSwitcher />);
  // } else {
    // finalRender.push(<Tag>{currentUser.org.name}</Tag>);
    // finalRender.push(<Tag>{_.upperFirst(currentUser.role)}</Tag>);
  // }

  const showSurveyLinkAnnouncement = false && (enabledFeatures.show_survey_link_announcement) && (currentUser.role === "teacher" || currentUser.role === "admin");
  console.log("showSurveyLinkAnnouncement", showSurveyLinkAnnouncement);
  showSurveyLinkAnnouncement && finalRender.push(<SurveyLinkAnnouncement />)

  finalRender.push(
    <UpgradePlanModal />
  );


  return (
    <React.Fragment>
      {/* <span>
      {appType != "web" ? <span>
        <Button type="danger" onClick={
        ()=>{dispatch(offlineAppActions.executeMethod({key: funcName, value: JSON.stringify(obj)}))}}>{funcName}</Button>
        
        {tempOfflineAppData}

        <Button type="danger" onClick={
          ()=>{dispatch(offlineAppActions.executeMethod({key: "changeSecurity", value: true}))}}>Turn on S</Button> 
        <Button type="danger" onClick={
          ()=>{dispatch(offlineAppActions.executeMethod({key: "setAppSwitched", value: false}))}}>Turn off S</Button> 
        </span> : ""
      }
    </span> */}
      {/* <AntdAppHelper /> */}
      <CheckAndSetAppType />
      
      <FeaturesModal />
  
     {!checkMob() &&(<Space className="user-role" key="top-nav-info">
        {finalRender}
      </Space>)}
    </React.Fragment>
  );
};
console.log("window.location.href====>",window.location.href);

const getConfig = (props) => {
  console.log("getConfig =====>", props);
  const { appTheme, showExperiencePreview } = props;
  const finalConfig = {
    preHeader: {
      show: true,
      component: AppNotice,
    },
    header: {
      siderToggleIcon: {
        show: true,
      },
      language: {
        show: true,
      },
      userDropdown: {
        display: "name",
        show: true,
      },
      extraItems: TopNavExtra,
      extraItemsLeft: (props) => {
        const { currentUser } = props;
        return <TopLeftNavExtra currentUser={currentUser} />;
      },
      topNavbarDropdown: (props) => {
        const { currentUser, logout } = props;
        return <LogoutDropdown currentUser={currentUser} logout={logout} />;
      },
    },
    sider: {
      footer: {
        component: (
          <Space direction="vertical" className="footer-item-container">
            <HelpCenterButton />
            <IcpText buttonStyles={{ fontSize: "11px" }} />
          </Space>
        ),
      },
      logo: {
        expanded: (
          <Link to={"/"}>
            <img
              style={{ width: "100%" }}
              src={LogoImageHor}
              alt={"AssessPrep logo"}
              title={"AssessPrep"}
            />
          </Link>
        ),
        collapsed: (
          <img
            className="assessprep-collapsed-logo"
            src={LogoImageCollapsed}
            alt={"AssessPrep logo"}
          />
        ),
      },
      collapsed: checkMob() || checkIPAD() || showExperiencePreview,
      // collapsed: true,
      // collapsed: window.location.href.includes("/library/explore") ? true : false,
      className: "appLeftSider",
      menuOptions: {
        theme: "light", // used for Sider.theme also - default is dark in antd
        // theme: appTheme, // used for Sider.theme also - default is dark in antd
        // theme: "light",
        // theme: "dark"
      },
    },
  };

  return finalConfig
}
const getThemeOptions = (props) => {
  console.log("getThemeOptions props", props);
  const { appTheme } = props;
  let appThemeOptions = {
    cssVar: { key: 'ap-app', prefix: "ap" },
    token: {
      // Seed Token
      colorPrimary: "#00A6BD",
      colorInfo: "#00A6BD",
      colorSuccess: "#61cd79",
      colorWarning: "#fcab4c",
      colorError: "#ff4d50",
      colorBgBase: "#ffffff",
      colorBgLayout: "#f8f9fa",
  
      borderRadius: 5,
  
      // Alias Token
      // colorBgContainer: '#f6ffed',
    },
    // "algorithm": theme.darkAlgorithm
  }
  if (appTheme === "v2-dark" || appTheme === "v3-dark") {
    appThemeOptions.algorithm = theme.darkAlgorithm
  } else {
    appThemeOptions.algorithm = theme.lightAlgorithm
  }

  if (appTheme === "v3-light") {
    appThemeOptions = v3ThemeOptions;
  }

  return appThemeOptions;
}



const App = MrApp({
  name: "AssessPrep",
  theme: getThemeOptions,
  config: getConfig,
  selectorsObj: {
    ...commonSelectors,
    isAuthenticated: isAuthenticatedSelector,
    tokenPresent: tokenPresentSelector,
    appType: appTypeSelector,
    partnerName: partnerNameSelector,
    enabledFeatures: enabledFeaturesSelector,
    appTheme: appThemeSelector,
    showExperiencePreview: getShowExperiencePreviewSelector,
  },
  antdHelperComponent: <AntdAppHelper />,
  loginActions,
  localeConfig,
  HomeComponent: AppConstants,
  // LoginComponent: Login,
  routes: (props) => {
    console.log("routes props line 416", props);
    const { intl, currentUser, permission, appType, partnerName, enabledFeatures } = props;
    const mrIntl = mrTranslate(intl);

    console.log("routes appType", appType);
  
    const showUiComponents = import.meta.env.VITE_MODE === 'development';
    console.log("showUiComponents==>", showUiComponents);
  
    var menu = [];

    // Overview/Dashboard visible to all roles for now
    // menu.push(
    //   {
    //     component: (props) => <h1>Overview</h1>,
    //     url: "/overview",
    //     title: "Overview",
    //     resourceName: "overview",
    //     singleResourceName: "overview",
    //     icon: <HomeOutlined />,
    //   },
    // )

    if (
      permission.org &&
      permission.org.list &&
      currentUser.role === "support"
    ) {
      // support org outside submenu
      menu.push({
        component: Orgs,
        url: "/orgs",
        title: mrIntl("App.schools"),
        resourceName: "orgs",
        singleResourceName: "org",
        icon: <BankOutlined />,
      });
    }

    if (
      permission.experience &&
      permission.experience.list &&
      currentUser.role === "admin"
    ) {
      menu.push({
        component: Overview,
        // component: ExperiencesWrapper,
        url: "/overview",
        title: mrIntl("App.my_overview"),
        // resourceName: "experiences",
        // singleResourceName: "experience",
        icon: <HomeOutlined />,
      });
    }

    if (
      permission.experience &&
      permission.experience.list &&
      currentUser.role !== "qb_author" &&
      currentUser.role !== "superadmin"
    ) {
      menu.push({
        component: MyAssessments,
        // component: ExperiencesWrapper,
        url: "/tests",
        title: currentUser.role === "parent" ? mrIntl("App.assessments") : mrIntl("App.my_assessments"),
        resourceName: "experiences",
        singleResourceName: "experience",
        icon: <ProfileOutlined />,
      });
    }
    if (permission.library && permission.library.list && currentUser.role !== "superadmin") {
      menu.push({
        // component: (props) => <h1>Library</h1>,
        component: LibraryPage,
        url: "/library",
        title: mrIntl("CommonText.library"),
        resourceName: "library",
        singleResourceName: "library",
        icon: <DatabaseOutlined />,
      });
    }
    if ((enabledFeatures.gradebook || import.meta.env.VITE_MODE === "development" || import.meta.env.VITE_MODE === "staging") && permission.gradebook && permission.gradebook.list) {
      menu.push({
        // component: (props) => <h1>Library</h1>,
        component: Gradebook,
        url: "/gradebook",
        title: mrIntl("App.gradebook"),
        // resourceName: "library",
        // singleResourceName: "library",
        icon: <BarChartOutlined />,
      });
    }
    // if (permission.analytics && permission.analytics.list) {
    //   menu.push(
    //     {
    //       component: (props) => <h1>Analytics</h1>,
    //       url: "/analytics",
    //       title: "Analytics",
    //       resourceName: "analytics",
    //       singleResourceName: "analytics",
    //       icon: <BarChartOutlined />,
    //     },
    //   )
    // }
    if (permission.user && permission.user.list) {
      menu.push({
        component: Users,
        url: "/users",
        title: mrIntl("App.users"),
        resourceName: "users",
        singleResourceName: "user",
        // icon: <UserAddOutlined />,
        icon: <TeamOutlined />,
        // Crud config being handled automatically based on permission obj above - just modify that on BE
        // TODO: doesn't work - need to set show true only for superadmin
        // crudConfig: {
        //   filter: {config: {actions: {show: currentUser.role === "superadmin"}}}
        // },
      });
    }
    if (
      currentUser.role !== "student" &&
      permission.group &&
      permission.group.list
    ) {
      menu.push({
        component: Groups,
        url: "/classes",
        title: mrIntl("App.classes"),
        resourceName: "groups",
        singleResourceName: "group",
        icon: <BookOutlined />,
      });
    }
    if (enabledFeatures.custom_rubrics && currentUser.role === "admin" && !currentUser.org.is_mb)
      menu.push({
        component: Rubrics,
        url: "/rubrics",
        title: mrIntl("App.rubrics"),
        resourceName: "rubrics",
        singleResourceName: "rubric",
        icon: <UnorderedListOutlined />,
      })
    if (permission.programme && permission.programme.update && currentUser.role !== "superadmin") {
      // equating settings to programme create
      menu.push({
        component: (props) => <h1>Settings</h1>,
        title: mrIntl("App.settings"),
        icon: <SettingOutlined />,
        isSubMenu: true,
        children: [
          {
            component: OrgItemDetailWrapper,
            url: "/settings/org",
            title: mrIntl("App.organisation"),
            resourceName: "org",
            singleResourceName: "org",
            icon: <BankOutlined />,
          },
          {
            component: OrgProgrammes,
            url: "/settings/programs",
            title: mrIntl("App.programs"),
            resourceName: "settings",
            singleResourceName: "settings",
            icon: <SettingOutlined />,
          }
        ],
      });
    }
    if (currentUser.role !== "qb_author" && currentUser.role !== "parent" && currentUser.role !== "admin" && currentUser.role !== "teacher") {
      menu.push({
        component: Help,
        url: "/help",
        title: mrIntl("App.help"),
        // resourceName: "users",
        // singleResourceName: "user",
        icon: <InfoCircleOutlined />,
      });
    }
    if (
      currentUser.role !== "qb_author" &&
      currentUser.role !== "support" &&
      appType === "web" &&
      partnerName != "21k"
    ) {
      if (!enabledFeatures.disable_lockdown_mode) {
        menu.push({
          component: OfflineApps,
          url: "/download",
          title: mrIntl("App.download_apps"),
          // resourceName: "users",
          // singleResourceName: "user",
          icon: <CloudDownloadOutlined />,
        });
      }
      menu.push({
        component: Profile,
        url: "/profile",
        title: mrIntl("App.my_profile"),
        // resourceName: "users",
        // singleResourceName: "user",
        icon: <UserOutlined />,
      });
    }
    if(showUiComponents){
      menu.push({
        component: UiComponents,
        url: "/uicomponents",
        title: "UI Components",
        icon: <AppstoreOutlined />
      });
    }

    if (currentUser.role === "support") {
      menu.push({
        component: FbDashboard,
        url: "/fb_dashboard",
        title: mrIntl("App.fb_backups"),
        resourceName: "dashboards",
        singleResourceName: "dashboard",
        icon: <SettingOutlined />,
      });
      menu.push({
        component: Feedbacks,
        url: "/feedback",
        title: mrIntl("App.feedback"),
        resourceName: "feedbacks",
        singleResourceName: "feedback",
        icon: <SettingOutlined />,
      });
    }
    if (
      permission.org &&
      permission.org.create &&
      currentUser.role === "superadmin"
    ) {
      // only superadmin can do org.create
      menu.push({
        component: (props) => <h1>{mrIntl("App.superadmin_settings")}</h1>,
        url: "/settings",
        title: mrIntl("App.superadmin_settings"),
        resourceName: "settings",
        singleResourceName: "settings",
        icon: <SettingOutlined />,
        isSubMenu: true,
        children: [
          {
            component: SuperadminDashboard,
            url: "/superadmin_dashboard",
            title: mrIntl("App.orgs_dashboard"),
            icon: <BankOutlined />,
          },
          {
            component: Orgs,
            url: "/orgs",
            title: mrIntl("App.orgs_list"),
            resourceName: "orgs",
            singleResourceName: "org",
            icon: <BankOutlined />,
          },
          {
            component: FbDashboard,
            url: "/fb_dashboard",
            title: mrIntl("App.fb_dashboard"),
            resourceName: "dashboards",
            singleResourceName: "dashboard",
            icon: <SettingOutlined />,
            whoCanAccess: ["superadmin"],
          },
          {
            component: Feedbacks,
            url: "/feedback",
            title: mrIntl("App.feedback"),
            resourceName: "feedbacks",
            singleResourceName: "feedback",
            icon: <CommentOutlined />,
          },
          {
            component: AppConstants,
            url: "/app_constants",
            title: mrIntl("App.app_constants"),
            resourceName: "app_constants",
            singleResourceName: "app_constant",
            icon: <UploadOutlined />,
          },
          {
            component: Boards,
            url: "/boards",
            title: mrIntl("App.boards"),
            resourceName: "boards",
            singleResourceName: "board",
            icon: <UnorderedListOutlined />,
            whoCanAccess: ["superadmin"],
          },
          {
            component: Programmes,
            url: "/programmes",
            title: mrIntl("App.programmes"),
            resourceName: "programmes",
            singleResourceName: "programme",
            icon: <UnorderedListOutlined />,
          },
          {
            component: Tags,
            url: "/tags",
            title: mrIntl("App.tags"),
            resourceName: "tags",
            singleResourceName: "tag",
            icon: <UnorderedListOutlined />,
          },
          {
            component: Rubrics,
            url: "/rubrics",
            title: mrIntl("App.rubrics"),
            resourceName: "rubrics",
            singleResourceName: "rubric",
            icon: <UnorderedListOutlined />,
          },
          {
            component: Questionbanks,
            url: "/questionbanks",
            title: mrIntl("CommonText.questionbanks"),
            resourceName: "questionbanks",
            singleResourceName: "questionbank",
            icon: <UnorderedListOutlined />,
          },
          {
            component: Plans,
            url: "/plans",
            title: mrIntl("CommonText.plans"),
            resourceName: "plans",
            singleResourceName: "plan",
            icon: <DollarOutlined />,
          },
          {
            component: Features,
            url: "/features",
            title: mrIntl("App.features"),
            resourceName: "features",
            singleResourceName: "feature",
            icon: <DollarOutlined />,
          },
          {
            component: SubjectResources,
            url: "/SubjectResources",
            title: mrIntl("App.resource_sheet"),
            resourceName: "features",
            singleResourceName: "feature",
            icon: <FileAddOutlined />,
          },
          {
            component: Integrations,
            url: "/integrations",
            title: mrIntl("App.integrations"),
            resourceName: "integrations",
            singleResourceName: "integration",
            icon: <SettingOutlined />,
            whoCanAccess: ["superadmin"],
          },
          {
            component: Prompts,
            url: "/prompts",
            title: "Prompts dashboard",
            resourceName: "prompts",
            singleResourceName: "prompt",
            icon: <SettingOutlined />,
          },
        ],
      });
    }
    if (currentUser.role === "qb_author" && currentUser.custom_fields.can_edit_tags) {
      menu.push({
        component: Tags,
        url: "/tags",
        title: mrIntl("App.tags"),
        resourceName: "tags",
        singleResourceName: "tag",
        icon: <UnorderedListOutlined />,
      });
    }

    const layoutWiseRoutes = [
      {
        isPrivate: true,
        // path: "/u",
        path: "/u",
        // layout: "", //defaults to AppLayout
        routes: menu,
      },
      {
        isPrivate: false,
        // path: "/u",
        path: "/oauth",
        // layout: "", //defaults to AppLayout
        routes: [
          {
            component: OauthCallback,
            url: "/:client/callback",
          },
        ],
      },
      {
        isPrivate: false,
        path: "/auth",
        layout: AuthLayout,
        // layout: (props) => {
        //   return <div>My Basic Auth Layout<div>{props.children}</div> </div>
        // },
        routes: [
          {
            component: Login,
            url: "/login",
          },
          {
            component: ForgotPassword,
            url: "/forgot-password",
          },
          {
            component: SetPassword,
            url: "/set-password",
          },
          // {
          //   component: SignUp,
          //   url: "/signup",
          // },
          // {
          //   component: GuestJoinCode,
          //   url: "/guest-join-code",
          // }
        ],
      },
      {
        isPrivate: false,
        path: "/guest",
        layout: AuthLayout,
        routes: [
          {
            component: GuestJoinCode,
            url: "/join",
          },
        ],
      },
      // {
      //   isPrivate: true,
      //   path: "/iframe",
      //   // layout: "",
      //   routes: [{
      //     path: "/"
      //   }]
      // },
      {
        // isPrivate: true,
        isPrivate: false,
        path: "/embed/partners/:partner",
        layout: EmbedLayout,
        // layout: (props) => {
        //     return <div>My Basic iframe Layout<div>{props.children}</div> </div>
        //   },
        routes: [
          {
            // component: Segments,
            component: EmbeddedResourceItemDetailUuid,
            url: "/:uuid",
            // title: "Embedded Resources",
            title: mrIntl("App.embedded_resources"),
            resourceName: "embeddedResources",
            // isCrud: true,
            crudConfig: {},
            // layout: (props) => {
            //   return <div>Basic Blank Layout</div>
            // },
            singleResourceName: "embed_resource",
            icon: <SettingOutlined />,
          },
          {
            component: Segments,
            url: "/segments",
            // title: "Segments",
            title: mrIntl("App.segments"),
            resourceName: "segments",
            isCrud: true,
            crudConfig: {},
            // layout: (props) => {
            //   return <div>Basic Blank Layout</div>
            // },
            singleResourceName: "segment",
            icon: <SettingOutlined />,
          },
        ],
      },
      {
        // isPrivate: true,
        isPrivate: false,
        path: "/embed",
        layout: EmbedLayout,
        // layout: (props) => {
        //     return <div>My Basic iframe Layout<div>{props.children}</div> </div>
        //   },
        routes: [
          {
            // component: Segments,
            component: EmbeddedResourceItemDetailUuid,
            url: "/:uuid",
            // title: "Embedded Resources",
            title: mrIntl("App.embedded_resources"),
            resourceName: "embeddedResources",
            // isCrud: true,
            crudConfig: {},
            // layout: (props) => {
            //   return <div>Basic Blank Layout</div>
            // },
            singleResourceName: "embed_resource",
            icon: <SettingOutlined />,
          },
          {
            component: Segments,
            url: "/segments",
            // title: "Segments",
            title: mrIntl("App.segments"),
            resourceName: "segments",
            isCrud: true,
            crudConfig: {},
            // layout: (props) => {
            //   return <div>Basic Blank Layout</div>
            // },
            singleResourceName: "segment",
            icon: <SettingOutlined />,
          },
        ],
      },
      {
        isPrivate: false,
        // making public to allow for Open in new tab from MB - handle JWT login
        // isPrivate: import.meta.env.VITE_MODE !== "production" ? false : true, // Testing: TEMP for demo  - to handle JWT login - fix this later
        path: "/e",
        layout: ExperienceLayout,
        // layout: (props) => {
        //     return <div>My Basic iframe Layout<div>{props.children}</div> </div>
        //   },
        routes: [
          {
            // Todo: change to diff component that renders only ExperienceItemDetail
            component: Experiences,
            url: "/tests",
            // title: "Tests",
            title: mrIntl("App.tests"),
            resourceName: "experiences",
            isCrud: true,
            crudConfig: {},
            // layout: (props) => {
            //   return <div>Basic Blank Layout</div>
            // },
            singleResourceName: "experience",
            icon: <SettingOutlined />,
          },
          {
            // Todo: change to diff component that renders only ExperienceItemDetail
            component: LibraryExperiences,
            url: "/all-tests",
            // title: "Tests",
            title: mrIntl("App.tests"),
            resourceName: "libraryexperiences",
            isCrud: true,
            crudConfig: {},
            // layout: (props) => {
            //   return <div>Basic Blank Layout</div>
            // },
            singleResourceName: "experience",
            icon: <SettingOutlined />,
          },
          // {
          //   component: ExperiencePrint,
          //   url: "/print",
          //   title: "Print - hide",
          //   icon: <SettingOutlined />,
          // }
        ],
      },
      {
        isPrivate: true,
        path: "/print",
        layout: PrintLayout,
        // layout: (props) => {
        //     return <div>My Basic print Layout<div>{props.children}</div> </div>
        //   },
        routes: [
          {
            component: ExperiencePrint,
            url: "/experiences/:uuid",
            // title: "Print experience",
            title: mrIntl("App.print_experience")
          },
          // {
          //   component: ExperiencePrint,
          //   url: "/experiences/:id/print_type/:print_type",
          //   title: "Print experience",
          // },
          // {
          //   component: ExperiencePrint,
          //   url: "/experiences_submission/:id/student/:user_id/print_type/:print_type",
          //   title: "Print student submission",
          // },
        ],
      },
      {
        isPrivate: true,
        path: "/maintenance",
        layout: MaintenanceLayout,
        // layout: (props) => {
        //     return <div>Maintenance Mode<div>{props.children}</div> </div>
        //   },
        routes: [
          {
            component: DemoResettingComponent,
            // component: (props) => {
            //   return <div>Demo is resetting<div>{props.children}</div> </div>
            // },
            url: "/resetting-demo",
          },
        ],
      },
      {
        isPrivate: false,
        path: "/maintenance-mode",
        layout: MaintenanceLayout,
        routes: [
          {
            component: MaintenancePage,
            url: "/maintenance-page",
          },
        ],
      },
      {
        isPrivate: true,
        path: "/error",
        layout: ErrorLayout,
        // layout: (props) => {
        //     return <div>Maintenance Mode<div>{props.children}</div> </div>
        //   },
        routes: [
          {
            component: ErrorPage,
            url: "/",
          },
        ],
      },
      {
        isPrivate: true,
        path: "/unsupported",
        layout: UnsupportedVersionLayout,
        routes: [
          {
            component: UnsupportedVersionNotice,
            url: "/",
          },
        ],
      }
    ];
    
    // return menu;
    return layoutWiseRoutes;
  },
});



// const AppWithLegacyBrowserSupport = () => {
//   let finalRender = []
//   let legacySupportConfig = {}
//   if (!(CSS.supports("( padding-inline: 7px )") && CSS.supports("( margin-inline-start: 7px )"))){
//     // Safari 14 doesn't support padding-inline, does support :where
//     // Safari 13 and mac 10.15.7 and ipad 14 webview doesn't support padding-inline
//     legacySupportConfig.transformers = [legacyLogicalPropertiesTransformer]
//   }
//   if (!isSelectorSupported(":where(body)")){
//     // Safari 14 does support where
//     // Safari 13 and mac 10.15.7 and and ipad 14 webview doesn't support :where
//     legacySupportConfig.hashPriority = "high"
//   }

//   console.log("legacySupportConfig", legacySupportConfig);
//   if(Object.keys(legacySupportConfig).length){
//     legacyBrowsersSupportCSS.load()
//     finalRender.push(
//       <StyleProvider {...legacySupportConfig}>
//         <AntdAppHelper />
//         <App />
//       </StyleProvider>
//     )
//   } else {
//     finalRender.push(<AntdAppHelper />)
//     finalRender.push(<App />)
//   }
  
  
  
//   return finalRender
  
//   // IMP: USE this for new way to use message, notification and modal as per antd5
//   // finalRender.push(<AntdAppHelper />)
//   // finalRender.push(<AntdAppHelper1 />)
//   // return <AntdApp> {finalRender} </AntdApp> // IMP: AntdAppHelper1 universal logic doesn't work with this AntdApp encapsulation and AntdAppHelper1 needs to be inside StyleProvider for legacy css support in modal, notif and message. Encapsulation needed to use useApp universally but doing that doesn't make the legacy css work. only contextHolder method makes that work
//   // Simply use import { message } from '/src/components/UI/AntdAppHelper1'; in components as needed
//   // USE this for new way to use message, notification and modal as per antd5

//   // return (
//   //   <StyleProvider hashPriority="high" transformers={[legacyLogicalPropertiesTransformer]}>
//   //     <App />
//   //   </StyleProvider>
//   // )
// };

// const AppWithTheme = () => {

//   let finalRender = <ConfigProvider
//     theme={{
//       token: {
//         // Seed Token
//         colorPrimary: '#00A6BD',
//         borderRadius: 5,

//         // Alias Token
//         // colorBgContainer: '#f6ffed',
//         // algorithm: theme.darkAlgorithm,
//       },
//     }}
//   >
//     <AntdAppHelper />
//     <App />
//   </ConfigProvider>

//   return finalRender;

// }

const AppWrapper = () => {
  const appTheme = useSelector(appThemeSelector());
  return (
    <div class={`${(appTheme === "v3-light" || appTheme === "v3-dark") ? "v3" : ""}`}>
      {/* <AntdAppHelper /> */} 
      {/* IMP: Removed from here and moved inside App (needed context for theme etc) - inside react framework */}
      <App/>
    </div>
  )
}


export default AppWrapper;
// export default AppWithLegacyBrowserSupport
// export default AppWithTheme
