import React, { useState } from "react";
import axios from "axios";
import { Button, Space, Tag, Tooltip } from "antd";
import { message } from "/src/components/UI/AntdAppHelper";
import { InfoCircleOutlined } from "@ant-design/icons";
import { getFromLS, removeHTMLTagsFromText, getUniqueId, getAIServiceBaseURL } from "/src/lib/utils/helperMethods";
import * as Sentry from "@sentry/react";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { updateConfigHeaderForDemoUseStagingAI } from "../GenerateAI/generateAIHelperMethods";
const CheckTextOriginality = (props) => {
  let { text, updateResource, id, custom_fields, disabled } = props
  console.log("CheckTextOriginality ===>", props);
  const removeTags = text && removeHTMLTagsFromText(text, true);
  const [percentage, setPercentage] = useState({
    aiPercentage: custom_fields.ai_originality_score,
    plagiarismPercentage: custom_fields.pl_originality_score,
  }
  );
  const mrIntl = useTranslate()
  const [isLoading, setIsLoading] = useState(false);
  // const [plagiarismDetailModalVisiblle, setPlagiarismDetailModalVisiblle] = useState(false);
  // const [plagiarismResultsData, setPlagiarismResultsData] = useState(false)
  // console.log("plagiarismResultsData====>", plagiarismResultsData)

  const checkOriginality = async () => {
    setIsLoading(true);
    setPercentage((prevState) => ({
      ...prevState,
      aiPercentage: null,
      // plagiarismPercentage: null
    }));
    const customId = `copyleaks-${getUniqueId(4)}-${Date.now()}`
    const requestBody = { text: removeTags, customID: customId };
    const token = getFromLS("token");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    config = updateConfigHeaderForDemoUseStagingAI(config)
    try {
      const response = await axios.post(
        `${getAIServiceBaseURL()}/scan-ai`,
        // `${import.meta.env.VITE_AI_SERVICE_URL}/scan/ai`,
        requestBody,
        config
      );
      // const data = response.data;
      const aiPercentage = (response.data.summary.ai * 100).toFixed(0);
      // const plagiarismPercentage = data.plagiarism.total_text_score
      // const plagiarismResult = plagiarismPercentage > "0%" && data.plagiarism.results
      // console.log("plagiarismPercentage data===>", data, plagiarismResult)

      // setPlagiarismResultsData(plagiarismResult)
      setPercentage((prevState) => ({
        ...prevState,
        aiPercentage: aiPercentage,
      }));
      let finalCustomFields = {
        ...custom_fields,
        ai_originality_score: aiPercentage,
        // written_by_ai: aiPercentage > "80%",
        // pl_originality_score: plagiarismPercentage,
        // plagiarism_result: plagiarismResult
      };
      updateResource(
        {
          id: id,
          custom_fields: finalCustomFields,
        },
        {
          success: { showMessage: false },
        }
      );
    } catch (error) {
      console.log("error in text checking==>", error)
      if (error) {
        message.error(mrIntl("CheckTextOriginality.error_msg_something_wrong"))
        Sentry.captureException(error);
      }
    } finally {
      setIsLoading(false);
    }
  };
 
  // const dataSource = plagiarismResultsData && plagiarismResultsData.map(result => ({
  //   key: result.phrase,
  //   phrase: result.phrase,
  //   website: result.matches[0].website
  // }));
  // const hideModal = () => {
  //   setPlagiarismDetailModalVisiblle(false);
  // }
  // const columns = [
  //   {
  //     title: 'Phrase',
  //     dataIndex: 'phrase',
  //     key: 'phrase',
  //   },
  //   {
  //     title: 'Website',
  //     dataIndex: 'website',
  //     key: 'website',
  //   }
  // ];

  return (
    <div className="check-text-originality">
      <Space>
        {/* {!percentage.aiPercentage && ( */}
          <Tooltip
            title={
              disabled
                ? mrIntl("CheckTextOriginality.need_more_then_100_words_title")
                : mrIntl("CheckTextOriginality.check_if_done_by_ai_tools_title")
            }
          >
            <Button
              onClick={checkOriginality}
              loading={isLoading}
              disabled={ disabled }
            >
              {isLoading ? mrIntl("CheckTextOriginality.checking_button") : mrIntl("CheckTextOriginality.ai_detection_button")}
            </Button>
          </Tooltip>
        {/* )} */}
        {percentage.aiPercentage && (
          <>
            <Tooltip
              title={
                percentage.aiPercentage <= 50
                  ? mrIntl("CheckTextOriginality.answer_done_by_student_titile")
                  : percentage.aiPercentage >= 50 &&
                    percentage.aiPercentage <= 80
                  ? mrIntl("CheckTextOriginality.difficult_to_determine_done_by_ai_tools_or_student_title")
                  : mrIntl("CheckTextOriginality.done_by_ai_tools_title")
              }
            >
              <Tag
                color={
                  percentage.aiPercentage <= 50
                    ? "success"
                    : percentage.aiPercentage >= 50 &&
                      percentage.aiPercentage <= 80
                    ? "warning"
                    : "error"
                }
              >
                {mrIntl("CheckTextOriginality.ai_button")}: {percentage.aiPercentage}%
              </Tag>
            </Tooltip>
            {/* <Tooltip>
              <Tag
                onClick={() => {
                  setPlagiarismDetailModalVisiblle(!plagiarismDetailModalVisiblle);
                }}
                color={
                  percentage.plagiarismPercentage <= "50%"
                    ? "success"
                    : percentage.plagiarismPercentage >= "50%" &&
                      percentage.plagiarismPercentage <= "80%"
                    ? "warning"
                    : "error"
                }
              >
                {mrIntl("CheckOriginality.pl_button")}: {percentage.plagiarismPercentage}
              </Tag>
            </Tooltip> */}
          </>
        )}
        {/* {plagiarismDetailModalVisiblle && (
          <Modal
            title={mrIntl("CheckOriginality.plagiarism_result_modal_title")}
            visible={plagiarismDetailModalVisiblle}
            onCancel={hideModal}
            footer={null}
          >
            <Table
              dataSource={dataSource}
              columns={columns}
              pagination={false}
            />
          </Modal>
        )} */}
        {percentage.aiPercentage && (
          <Tooltip title={mrIntl("CheckTextOriginality.the_responses_provided_by_ai_model_title")}>
            <Button icon={<InfoCircleOutlined />} type="text" />
          </Tooltip>
        )}
      </Space>
    </div>
  );
};

export default CheckTextOriginality;
