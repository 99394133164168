import React from "react";
import { Tooltip, Space, Tag, Tabs, Button } from "antd";
// import { PageHeader } from '@ant-design/pro-layout';
import {PageHeader} from "@ant-design/pro-layout"
import {
  checkIfStartTime,
  checkMob,
  displayPoints,
} from "/src/lib/utils/helperMethods";
import {
  CloudTwoTone,
  SafetyCertificateTwoTone,
  LockTwoTone,
  ArrowLeftOutlined,
  FileTextTwoTone,
} from "@ant-design/icons";
import { appRegionSelector, currentUserSelector, enabledFeaturesSelector } from "/src/views/Auth/Login/selector";
import InstructionsModal from "/src/views/Experiences/ExperienceShow/Components/InstructionsModal";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import InfoModal from "/src/views/Experiences/ExperienceShow/Components/InfoModal";
import { actions as experienceReduxActions } from "/src/views/Experiences/redux";
// import MBIcon from "/src/assets/images/mb-icon.png";
import MBIcon from "/src/assets/images/MB-icon-new.png";
import { getMBTaskUrl } from "/src/views/Experiences/ExperienceHelperMethods";
import { actions as firestoreInteractionActions } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/redux";
import { firestoreFieldValue } from "/src/config/initializers";
import { focusLostCountSelector } from "../FirestoreInteractions/selector";
import MrTranslate, {useTranslate} from "/src/lib/MrTranslate/MrTranslate";
import ExperienceTitle from "./ExperienceTitle";
import { editModeForSegmentIdSelector, showSegmentFormSelector } from "/src/views/Segments/selector";
import { actions as segmentActions } from "/src/views/Segments/redux";

const ExperiencePageHeader = (props) => {
  console.log("ExperiencePageHeader props ==>", props);
  const {
    experience = {},
    pageHeaderProps,
    experienceViewMode,
    embedded,
    activeKey,
    setActiveKey,
    fromResourceName,
    fromUrl,
    updateResource,
    setSwitchFromStartToResumeView
  } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const { url } = useRouteMatch();
  const currentUser = useSelector(currentUserSelector());
  const appRegion = useSelector(appRegionSelector());
  const userInfoFromReduxFocusLostCount = useSelector(focusLostCountSelector);
  const enabledFeatures = useSelector(enabledFeaturesSelector());
  const editModeForSegmentId = useSelector(editModeForSegmentIdSelector())
  const showSegmentForm = useSelector(showSegmentFormSelector())
  const mrIntl = useTranslate();

  const experienceSettings = experience.settings || {};
  const experienceRubric = experience.rubric || {};
  const isMobile = checkMob();
  const isQuestionBankExperience = experience && experience.questionbank ? true : false
  const isQBAuthor = currentUser.role === "qb_author" ? true : false
  const isStudentReviewerMode = experience.student_reviewer_mode
  const isPaperMode = experienceSettings.mode === "paper"
  const hasNoSubmissions = experience.submissions_count === 0;
  const isPaperModeAssessmentPreview = experienceSettings.mode === "paper" && experienceViewMode === "apPreviewTest"

  // Move to Pageheader
  const handleTabClick = (key, event) => {
    if (embedded) {
      return;
    }
    let prevUrl = window.location.href;
    // console.log("historyResp prev url ", prevUrl, window.location.href);
    history.push(`${url}/${key}`);
    let changedUrl = window.location.href;
    // console.log("historyResp changed url ", changedUrl, window.location.href);
    // Doing this prev and changed logic to prevent tab change when form unsaved and alert shows = not link so still changing tabs. now fixed
    if (changedUrl !== prevUrl) {
      setActiveKey(key);
    }
    if (editModeForSegmentId !== 0 || showSegmentForm !== 0) {
      if (editModeForSegmentId) {
        dispatch(segmentActions.setEditModeForSegmentIdSuccess({ editModeForSegmentId: 0 }));
      }
      if (showSegmentForm) {
        dispatch(segmentActions.setShowSegmentFormSuccess({ showSegmentForm: 0 }));
      }
    }
  };

  function setUserInfo(userInfo, options = {}) {
    console.log("calling setuserinfo", userInfo, options);
    dispatch(
      firestoreInteractionActions.setUserInfo(
        { experienceId: experience.id, userInfo },
        options
      )
    );
  }


  const goBack = () => {
    if (experienceViewMode === "apTakeTest" && experienceSettings.mode !== 'online' ) {
      console.log('inside student back==>');
      let focusLostCount = userInfoFromReduxFocusLostCount;
      setUserInfo(
        {
          is_focus_lost: true,
          focus_lost_count:
            appRegion == "china" ? ++focusLostCount : firestoreFieldValue.increment(1),
        },
        {
          log: {
            logging: true,
            action: "focus_lost_on_go_back",
          },
        }
      );
    }
    if (experienceViewMode === "apPreviewTest") {
      dispatch(
        experienceReduxActions.setViewModeSuccess({
          experienceViewMode: "apEditTest",
        })
      );
    } else if (fromResourceName) {
      // let fromResourceName = location.state.fromResourceName
      if (fromResourceName === "experiences") {
        if(fromUrl){
          history.push(fromUrl);
        }else{
          history.push("/u/tests");
        }
      } else if (fromResourceName === "libraryexperiences") {
        if(fromUrl){
          history.push(fromUrl);
        }else{
          history.push("/u/library/tests");
        }
      } else if (fromResourceName === "todayexperiences") {
        if(fromUrl){
          // let newUrl = fromUrl.replace("page-2", "page-1");
          history.push(fromUrl);
        }else{
          history.push("/u/overview");
        }
      }
    } else {
      if(setSwitchFromStartToResumeView) {
        setSwitchFromStartToResumeView(true);
      }
      history.push("/");
    }
  };

  const isGuest = currentUser.custom_fields.account_type === "guest"
  let sCode = currentUser.s_code;
  let userName = currentUser.name;
  const experienceRubricTypeC = experienceRubric.type_c
  
  let showPts = false;
  if (
    experienceRubricTypeC !== "comments_only" &&
    experienceRubricTypeC !== "myp_achievement_level" &&
    experienceRubricTypeC !== "custom" &&
    experience.points
  ) {
    showPts = true;
  }

  let title, subTitle, displayUserName, footer;

  const deliveryModesStaticValues = {
    online: {
      title: mrIntl("ExperiencePageHeader.any_browser_tooltip"),
      // title: "Any browser",
      icon: <CloudTwoTone />,
    },
    online_secure: {
      title: mrIntl("ExperiencePageHeader.any_browser_with_security_tooltip"),
      // title: "Any browser with security",
      icon: <SafetyCertificateTwoTone />,
    },
    "online-secure": {
      title: mrIntl("ExperiencePageHeader.any_browser_with_security_tooltip"),
      icon: <SafetyCertificateTwoTone />,
    },
    offline: {
      title: mrIntl("ExperiencePageHeader.lockdown_app_tooltip"),
      // title: "Lockdown app",
      icon: <LockTwoTone twoToneColor="#eb2f96" />,
    },
    paper: { // Need this without flag - else breaks for already created tests
      title: mrIntl("CommonText.paper_mode"),
      icon: <FileTextTwoTone /> 
    }
  };

  let mode = experienceSettings.mode && (
    <Tooltip
      placement="bottom"
      title={deliveryModesStaticValues[experienceSettings.mode].title}
      getPopupContainer={() => document.querySelector(".experience-header")}
    >
      {deliveryModesStaticValues[experienceSettings.mode].icon}
    </Tooltip>
  );

  let criteriaPointsView = [];
  if (experience.criterium_associations) {
    // console.log("experience.criterium_associations", experience.criterium_associations)
    experience.criterium_associations.map((ca, i) => {
      criteriaPointsView.push(
        <Tag key={`criteria-points-${ca.id}`}>
          {ca.custom_fields.title} {displayPoints(ca.points)}
        </Tag>
      );
    });
  }

  if (experienceViewMode === "apEditTest") {
    title = (
      <ExperienceTitle
        experienceViewMode={experienceViewMode}
        experience={experience}
        updateResource={updateResource}
      />
    );

    const mbLinked = (
      <Tooltip title={mrIntl("ExperiencePageHeader.open_in_mb_msg")}>
        {
          <img
            alt={mrIntl("ExperiencePageHeader.mb_logo")}
            style={{ width: "16px", marginTop: "-2px", cursor: "pointer", verticalAlign: "middle" }}
            src={MBIcon}
            onClick={() => {
              window.open(getMBTaskUrl(currentUser.org, experience), "_blank");
            }}
          ></img>
        }
      </Tooltip>
    );
    subTitle = (
      <Space className="title">
        {experience.is_mb_linked && mbLinked}
        {mode}
        <InfoModal experience={experience} />
        {!isMobile && <Tag>{experience.questions_count} {mrIntl("ExperiencePageHeader.qs_tag")} {/* ({experience.attemptable_questions_count}) */} </Tag>}
        {!isMobile &&
          (showPts ? (
            <Tag>{displayPoints(experience.points)} {mrIntl("ExperiencePageHeader.pts_tag")}</Tag>
          ) : (
            <span>{criteriaPointsView}</span>
          ))}
      </Space>
    );

    const { TabPane } = Tabs;

    let gradeTabText = (
      <Space>
        <MrTranslate id={"ExperiencePageHeader.grade_tab"}/>
        {!isStudentReviewerMode && <Tooltip 
        title= {mrIntl("CommonText.grade_tooltip")}
        //  title="Submissions graded"
         placement="bottom">
          <Tag color="processing">
            {experience.marked_submissions_count}/{experience.submissions_count}
          </Tag>
        </Tooltip>}
      </Space>
    );

    footer = (
      <Tabs
        className={isMobile ? "mobile-bottom-nav" : "experience-tabs-nav"}
        centered
        activeKey={activeKey}
        onTabClick={handleTabClick}
      >
        {/* showing view button to invig only after test started */}
        {(embedded ||
          ((!isQuestionBankExperience || isQBAuthor) && (experience.current_user_can_edit || experience.current_user_can_view))) && (
          <TabPane
            tab={
              embedded || !experience.current_user_can_edit
                ? mrIntl("CommonText.view")
                : mrIntl("ExperiencePageHeader.create_tab")
            }
            key="c"
          ></TabPane>
        )}

        {!embedded && 
          experience.current_user_can_deliver && (
            // <TabPane tab="Deliver" key="deliver"></TabPane>
            <TabPane tab={mrIntl("ExperiencePageHeader.deliver_tab")} key="deliver"></TabPane>
          )}

        {!embedded && experience.current_user_can_monitor && (
          <TabPane
            tab={
              <Tooltip
                title={
                  isPaperMode
                    ? mrIntl(
                        "CommonText.disabled_since_delivering_on_paper"
                      )
                    : ""
                }
              >
                {mrIntl("ExperiencePageHeader.monitor_tab")}
              </Tooltip>
            }
            disabled={isPaperMode}
            key="monitor"
          ></TabPane>
        )}

        {!embedded &&
          (experience.current_user_can_grade ||
            currentUser.role === "support") && (
            <TabPane
              tab={
                experience && experience.submissions_count > 0
                  ? gradeTabText
                  : mrIntl("ExperiencePageHeader.grade_tab",)
              }
              key="grade"
              disabled={experience.status === "draft"}
            ></TabPane>
          )}
        {!embedded && 
          (enabledFeatures.experience_analytics || import.meta.env.VITE_MODE === "development" || import.meta.env.VITE_MODE === "staging") && experienceRubric.type_c !== "comments_only" &&
          experience &&
          (experience.current_user_can_edit ||
            currentUser.role === "support") && (
            <TabPane
              tab={
                <Tooltip
                  title={hasNoSubmissions ? mrIntl("ExperiencePageHeader.no_submissions_available") : ""}
                >
                  {mrIntl("ExperiencePageHeader.analyse")}
                </Tooltip>
              }
              key="analytics"
              disabled={hasNoSubmissions}
            ></TabPane>
          )}
      </Tabs>
    );

    if (embedded) {
      subTitle = !isMobile && (
        <span>
          <Tag>{experience.questions_count} {mrIntl("ExperiencePageHeader.qs_tag")}</Tag>
          {showPts ? (
            <Tag>{displayPoints(experience.points)} {mrIntl("ExperiencePageHeader.pts_tag")}</Tag>
          ) : (
            criteriaPointsView
          )}
        </span>
      );
    }
  } else if (
    experienceViewMode === "apTakeTest" ||
    experienceViewMode === "apPreviewTest"
  ) {
    displayUserName =
      userName.length > 15 ? (
        <Tooltip title={userName} placement="bottom">
          {userName.substring(0, 15)}...
        </Tooltip>
      ) : (
        userName
      );

    title = (
      <ExperienceTitle
        experienceViewMode={experienceViewMode}
        experience={experience}
      />
    );

    subTitle = (
      <span>
        {experienceSettings.include_extra_instructions && (
          <InstructionsModal
            instructions={experienceSettings.extra_instructions_text}
          />
        )}
        {!isMobile && <Tag>{experience.questions_count} {mrIntl("ExperiencePageHeader.qs_tag")}</Tag>}
        {!isMobile && showPts && experience.points && (
          <Tag>{displayPoints(experience.points)} {mrIntl("ExperiencePageHeader.pts_tag")}</Tag>
        )}
        {!isMobile && !embedded && !experience.questionbank && (
          <>
            {currentUser.role !== "student" && currentUser.role !== "parent" && 
            <Tooltip title={mrIntl("CommonText.join_code")} getPopupContainer={() => document.querySelector(".experience-header")}>
              <Tag color="success">{experience.access_code}</Tag>
            </Tooltip>}
            {currentUser.role === "student" && isGuest && (
              <Tooltip title={mrIntl("CommonText.student_code")} getPopupContainer={() => document.querySelector(".experience-header")}>
                <Tag>{sCode}</Tag>
              </Tooltip>
            )}
          </>
        )}
        {!isMobile && displayUserName}
      </span>
    );
  }
  let finalRender = [];
  const shouldHideForPamoja = enabledFeatures.special_ui_for_pamoja_school && experience?.custom_fields?.block
  
  finalRender.push(
    <PageHeader
      className={`site-page-header-responsive experience-header ${
        isMobile ? "mobile" : ""
      }`}
      title={isPaperModeAssessmentPreview ? "" : title}
      subTitle={ isPaperModeAssessmentPreview ? "" : subTitle}
      backIcon={
        (embedded || shouldHideForPamoja) ? (
          false
        ) : (
          <Tooltip
            getPopupContainer={() => document.querySelector(".experience-header")}
            placement="top"
            title={mrIntl("ExperiencePageHeader.back_btn")}
          >
            <Button shape="circle" icon={<ArrowLeftOutlined />} />
          </Tooltip>
        )
      }
      onBack={() => goBack()}
      footer={!isMobile && footer}
      {...pageHeaderProps}
    ></PageHeader>
  );

  if (isMobile && experienceViewMode === "apEditTest") {
    let experienceMobileNav = footer;
    finalRender.push(experienceMobileNav);
  }

  return finalRender;
};

export default ExperiencePageHeader;
