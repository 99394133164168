import { Tooltip, Progress, Popover } from "antd";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { attemptedCountSelector, currentViewSelector, questionsStatusSelector, userResponsesSelector, totalEngagementPointsUserInfoSelector } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/selector";
import AttemptStatus from "/src/views/Experiences/ExperienceShow/Components/AttemptStatus";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { experienceConfigTakeExperienceSelector, experienceMaxEngagementPointsSelector } from "/src/views/Experiences/selector";

const ProgressBar = (props) => {
  console.log("ProgressBar props==>", props);

  const {
    experienceTopics,
    questionsCount,
    experienceId,
    userId
  } = props;

  // Note - if use in student list send these values from parent component - this one is not used in student list
  const attemptedCount = useSelector(attemptedCountSelector);
  const questionsStatus = useSelector(questionsStatusSelector)
  const mrIntl = useTranslate();
  const totalEngagementPoints = useSelector(totalEngagementPointsUserInfoSelector)
  const experienceMaxEngagementPoints = useSelector(experienceMaxEngagementPointsSelector())
  const experienceConfigTakeFromRedux = useSelector(experienceConfigTakeExperienceSelector())
  const currentView = useSelector(currentViewSelector)
  const checkattemptedCount = attemptedCount == undefined ? 0 : attemptedCount
  console.log("ProgressBar totalEngagementPoints", totalEngagementPoints, experienceMaxEngagementPoints);
  let attemptPercentage =
    attemptedCount == undefined || !questionsCount
      ? 0
      : ((attemptedCount / questionsCount) * 100).toFixed(0);

  let engagementPercentage =
  totalEngagementPoints == undefined
    ? 0
    : ((totalEngagementPoints / experienceMaxEngagementPoints) * 100).toFixed(0);

        
  let content = (
    <AttemptStatus
      experienceTopics={experienceTopics}
      // questionsStatus={questionsStatus}
      experienceId={experienceId}
      userId={userId}
    />
  );
  let finalRender = null;
  if (currentView === "endTest") {
    finalRender = (
      <Tooltip
        // title={`Questions attempted (inc sub-parts) (${
        //   attemptedCount == undefined ? 0 : attemptedCount
        // }/${questionsCount})`}
        title={mrIntl("ProgressBar.questions_attempted_inc_sub_parts", {checkattemptedCount: checkattemptedCount, questionsCount: questionsCount})}
        placement="bottom"
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
      >
        <Progress type="circle" percent={attemptPercentage} width={45} />
      </Tooltip>
    );
  } else {
    if(experienceConfigTakeFromRedux.engagement.enabled){
      finalRender = (
        <Popover
          // content={content}
          title={mrIntl("ProgressBar.engagement_progress", {totalEngagementPoints: totalEngagementPoints, experienceMaxEngagementPoints: experienceMaxEngagementPoints})}
          // title={`Engagement Progress(${EngagementProgressTitle}/${experienceMaxEngagementPoints})`}
          placement="bottom"
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
        >
          <Progress type="circle" percent={engagementPercentage} width={45} />
        </Popover>
      );
    }else{
      finalRender = (
        <Popover
          content={content}
          title={mrIntl("ProgressBar.questions_attempted_inc_sub_parts", {checkattemptedCount: checkattemptedCount, questionsCount: questionsCount})}
          placement="bottom"
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
        >
          <Progress type="circle" percent={attemptPercentage} width={35} />
        </Popover>
      );
    }
  }
  return finalRender;
};

export default ProgressBar;
