import { message } from "antd";
import { getFromLS, getUniqueId, setToLS } from "./helperMethods";
import { getAppVersion, isNewAppVersion } from "/src/App/OfflineApp/offlineAppHelper";

window.appType = "web";
// for testing
// enable mac app with promise resolution
// if(import.meta.env.NODE_ENV !== "production"){
if(import.meta.env.NODE_ENV === "development" && false){
  // let setCurrentApp = import.meta.env.VITE_TYPE || "mac";
  // let setCurrentApp = import.meta.env.VITE_TYPE || "win";
  let setCurrentApp = import.meta.env.VITE_TYPE || "web";
  console.log( "setCurrentApp", setCurrentApp );
  if(setCurrentApp === "win"){
    // window.downloadHelper = (args) => {
    //   console.log( "win method called with args",args);
    // }
    window.downloadHelper = {
      quitAssessPrepWebView: (args) => {
        console.log( "win method called with args - quit",args);
      },
      changeSecurity: (args) => {
        console.log( "win method called with args - change security",args);
      },
      nativeLog: (args) => {
        console.log( "win method called with args - default - native log",args);
      }
    }
    
   
  } else if(setCurrentApp === "mac"){
    window.webkit = {
      messageHandlers: {
        macDownloadHelper: {
          postMessage: (args) => {
            const {guid, callbackType, key, value, ...extraProps} = args;
            console.log( "mac method called with args", args);
            const data = {
              sample: "data"
            }
            setTimeout(() => {
              window.executePromise && window.executePromise(guid, data);
              
            }, 2000);
          }
        }
      }
    }
  }
}

if (window.downloadHelper !== undefined){
  // To do with custom Suffix in userAgent like SEB
  window.appType = "win" 
  console.log("appType Win", window.appType)
}else if (window.navigator.userAgent && window.navigator.userAgent.indexOf("AssessPrepMac") > -1){
  // To do with custom Suffix in userAgent like SEB. current condition is setting appType to mac for ipad also
  window.appType = "mac"
  console.log("appType Mac", window.appType)
}else if (window.navigator.userAgent && window.navigator.userAgent.indexOf("AssessPrepIOS") > -1){
  // To do with custom Suffix in userAgent like SEB. current condition is setting appType to mac for ipad also
  window.appType = "ios"
  console.log("appType IOS", window.appType)
}


export const OfflineAppServiceMethod = () => {
  const finalServiceObject = {
    focusLost: false,
  }
  // const promises = {}
  const appType = window.appType || "web";
  const isDesktopApp = appType === "mac" || appType === "win";
  const isMac = appType === "mac";
  const isWin = appType === "win";
  const isIos = appType === "ios";
  // console.log( "isDesktopApp, isMac, isWin", isDesktopApp, isMac, isWin );
  
  let win, mac;
  // common

  // only for win
  if(isWin){
    win = window.downloadHelper;
  }
  // only for mac
  if(isMac){
    mac = window.webkit.messageHandlers && window.webkit.messageHandlers.macDownloadHelper;
    
  }

  if(isIos){
    mac = window.webkit.messageHandlers && window.webkit.messageHandlers.iosDownloadHelper;
  }
  console.log("appType offlineAppService",isIos);
  const promisesData = {}, newInterval = {}, appExecutedMethodData = {};
  window.executeSetFocusLost = (value) => {
    // if([true, "true", "1", 1].includes(value)){
    //   finalServiceObject.isFocusLost = true;
    // } else {
    //   finalServiceObject.isFocusLost = false;
    // }
    // const event = new Event("focusLost", {value: finalServiceObject.isFocusLost});
    const event = new CustomEvent("AssessPrepFocusLost", {value});
    window.dispatchEvent(event);
    // console.log( "focusLost value", value );
  }

  //Execute when AAC `func assessmentSession(_ session: AEAssessmentSession, wasInterruptedWithError error: Error) { ` delegate calling from AssessPrep Mac App

  window.executeSetSecurityWasInterrupted = (value) => {
    console.log("executeSetSecurityWasInterrupted",value);

    const event = new CustomEvent("SetAACSecurityInterruptedLog", { detail: value });
    window.dispatchEvent(event);
  }

  window.executeSetLogOnSecurityResume = (value) => {
    console.log("executeSetLogOnResumeSecurity",value);
    const event = new CustomEvent("SetSecurityResumeLog", { detail: value });
    window.dispatchEvent(event);
  }

  window.executeOnBeforeEndSession = (value) => {
    console.log("executeOnBeforeEndSession",value);
    const event = new CustomEvent("onBeforeEndSession", { detail: value });
    window.dispatchEvent(event);
  }
  
  
  window.executeSetLogOnQuit= (value) => {
    console.log("executeSetLogOnQuit",value);
    const currentSystemAppVersion = getAppVersion(appType)
    // For mac app v12.0.0 and above, we are not setting the LS securityHasBeenInterrupted to true because in 12 version, the security status is coming correctly
    if(appType === "mac" && !isNewAppVersion(currentSystemAppVersion, "12.0.0")) {
      setToLS("securityHasBeenInterrupted", null);
    }
    const event = new CustomEvent("SetNativeQuitLog", { detail: value });
    window.dispatchEvent(event);
  }

  window.executeSetAutoUpdateStatus= (value) => {
    console.log("executeSetAutoUpdateStatus",value);
    const event = new CustomEvent("SetAutoUpdateStatus", { detail: value });
    window.dispatchEvent(event);
  }

  window.executePromise = (guid, data) => {
    console.log("execute promoise 65",guid, data, promisesData);

    let changeSecurityGuidFromLS = getFromLS("changeSecurityGuid")
    let changeSecurityValueFromLS = getFromLS("changeSecurityValue")

    if (
      changeSecurityGuidFromLS === guid && //match security on request guid (or any error in delegate that triggers this method that sends securityGuid) (security off guid is different) == this guid is saved on native side as securityGuid (only on security begin) that is why it will match even when triggered out of flow (from native)
      changeSecurityValueFromLS === "true" && // can be true when inside test or undefined on refresh - using from LS to handle refresh case
      data === "false" // native app interrupt is triggering executePromise(securityguid, "false")
    ) {
      console.log("call execute promise when security interrupted==>", changeSecurityGuidFromLS, changeSecurityValueFromLS)
      const event = new CustomEvent("AACSecurityInterrupted", {data});
      window.dispatchEvent(event);

      setToLS("changeSecurityGuid", null)
      setToLS("changeSecurityValue", null)
      //Fixed By delete : because executePromise function calling twice , When the InterruptedWithError occures both  `AEAssessmentSession wasInterruptedWithError` and session ends delegates triggers from the native side.
    }

    // console.log("value of native data==>",appExecutedMethodData[guid])
    // const existingMethodData = appExecutedMethodData[guid]
    // //this guid is saved on native side as securityGuid (only on security begin) that is why it will match even when triggered out of flow (from native)
    // if (
    //   existingMethodData &&
    //   existingMethodData["key"] === "changeSecurity" &&
    //   existingMethodData["value"] === "true" && // can be true when inside test or undefined on refresh
    //   data == "false"
    // ) {
    //   console.log("call execute promise when security interrupted==>",appExecutedMethodData[guid])
    //   const event = new CustomEvent("AACSecurityInterrupted", {data});
    //   window.dispatchEvent(event);
    //   delete appExecutedMethodData[guid] //Fixed By delete : because executePromise function calling twice , When the InterruptedWithError occures both  `AEAssessmentSession wasInterruptedWithError` and session ends delegates triggers from the native side.
    // }
    promisesData[guid] = data;
    // delete promisesData[guid]; // TODO: uncomment this
  }

  // TODO Calling from mac to logout feature
  // window.executeSessionLogout = (value) => {
  //   console.log("execute method=============>","executeSessionLogout")
  //   const event = new CustomEvent("executeSessionLogout", { detail: value });
  //   window.dispatchEvent(event);
  // }
  // const executePromiseMethod = (guid, data) => {
  //   const theFunction = promises[guid];
  //   if( theFunction){
  //     theFunction.call( null, data);
  //   }
  //   delete promises[guid];
  // }
  const generateUUID = () => {
    // d = (new Date).getTime()
    // uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) ->
    //   r = (d + Math.random() * 16) % 16 | 0
    //   d = Math.floor(d / 16)
    //   (if c == 'x' then r else r & 0x3 | 0x8).toString 16
    // )
    // uuid
    return getUniqueId();
  }
  
  let executeAppMethod = ({key, value}) => {
    console.log( "no execute method found for ", appType, key, value);
  };
  if(isWin){
    // executeAppMethod = win;
    console.log( "win executeAppMethod ", win);
    executeAppMethod = ({key, value}) => {

      console.log( "args win executeAppMethod ", key, value);
      // mappings for win native fns
      let mappedFunction
      switch (key) {
        case "quit": mappedFunction = "quitAssessPrepWebView"; break;
        case "changeSecurity": mappedFunction = "changeSecurity"; break;
        case "exportToDesktop": mappedFunction = "exportJSONToFile"; break;
        case "getBatteryStatus": mappedFunction = "checkBatteryStatus"; break;
        case "checkIfAppSwitched": mappedFunction = "checkAppSwitched"; break; // native will trigger
        case "setAppSwitched": mappedFunction = "setAppSwitched"; break;  // set to false after allowing student to resume
        case "startUpdate": mappedFunction = "startUpdate"; break;
        case "closeAllApps": mappedFunction = "closeAllApps"; break;
        case "getAllLangs": mappedFunction = "getAllLangs"; break; // get languages also in win case
        // Usage:
        // getAllLangs, // returns string - use to show dropdown -> "U.S._@0_________Devanagari_@4_________Devanagari - QWERTY_@5_________Hindi – Transliteration_@6_________German_@8_________Canadian French - CSA_@9_________Cangjie_@10"
        // setCurrentKeyboardLayout("12"), // send ID to set
        case "setCurrentKeyboardLayout": mappedFunction = "setCurrentKeyboardLayout"; break;
        case "setKeyboardLangToEng": mappedFunction = "setKeyboardLangToEng"; break;
        case "getSystemCustomInfo": mappedFunction = "getSystemCustomInfo"; break;
        case "saveTestData": mappedFunction = "saveTestData"; break;
        default: mappedFunction = "nativeLog"
      }

      win[mappedFunction](value);
      key === "quit" && window.close()
    }
  } else if(isMac || isIos){

    executeAppMethod = ({key, value}) => {
      let noCallback = false
      console.log( "args mac executeAppMethod ", key, value);
      // mappings for mac native fns
      let mappedFunction

      switch (key) {
        case "quit": mappedFunction = "quitAssessPrep"; break; // IMP: Make sure to check if app version >= 9.0.0, when fn will use
        case "changeSecurity": mappedFunction = "changeSecurity"; break; // IMP: Make sure to check if app version >= 9.0.0, when fn will use
        case "getBatteryStatus": mappedFunction = "getBatteryStatus"; break; // IMP: Make sure to check if app version >= 9.0.0, when fn will use
        case "checkIfAppSwitched": mappedFunction = "checkIfAppSwitched"; break; // native will trigger // IMP: Make sure to check if app version >= 9.0.0, when fn will use
        case "setAppSwitched": mappedFunction = "setAppSwitched"; break; // set false // IMP: Make sure to check if app version >= 9.0.0, when fn will use
        case "startUpdate": mappedFunction = "startUpdate"; break; // IMP: Make sure to check if app version >= 11.0.0, when fn will use
        case "closeAllApps": mappedFunction = "closeAllApps"; // IMP: Make sure to check if app version >= 9.0.0, when fn will use
        // No callback was used on the native side to prevent all running applications from being triggered to close.
        noCallback = true;
        break; 
        case "downloadFile": mappedFunction = "downloadFile"; break; // IMP: Make sure to check if app version >= 9.0.0, when fn will use
        case "saveTestData": mappedFunction = "saveTestData"; break; // IMP: Make sure to check if app version >= 9.0.0, when fn will use
        case "exportToDesktop": mappedFunction = "exportJSONToFile"; break; // IMP: Make sure to check if app version >= 9.0.0, when fn will use
        case "clearClipboard": mappedFunction = "clearClipboard"; // IMP: Make sure to check if app version >= 9.0.0, when fn will use
        break;
        case "openLinkInBrowser": mappedFunction = "openLinkInBrowser"; // IMP: Make sure to check if app version >= 9.0.0, when fn will use
        break;
        case "setCountryName": mappedFunction = "setCountryName"; // IMP: Make sure to check if app version >= 9.0.0, when fn will use
        break;
        case "getCountryName": mappedFunction = "getCountryName"; // IMP: Make sure to check if app version >= 9.0.0, when fn will use
        break;
        case "setAppUrl": mappedFunction = "setAppUrl"; // IMP: Make sure to check if app version >= 9.0.0, when fn will use
        break;
        case "getAppUrl": mappedFunction = "getAppUrl"; // IMP: Make sure to check if app version >= 9.0.1, when fn will use
        break;
        case "setWhitelistedUrls": mappedFunction = "setWhitelistedUrls"; // IMP: Make sure to check if app version >= 9.0.0, when fn will use
        break;
        case "clearCache": mappedFunction = "clearCache"; // IMP: Make sure to check if app version >= 9.0.1, when fn will use
        // clear cache removing [WKWebsiteDataTypeDiskCache, WKWebsiteDataTypeMemoryCache] (cache and memory cache folder) (IMP: not remove IndexDB)
        break;
        case "clearCacheWithSession": mappedFunction = "clearCacheWithSession" // IMP: Make sure to check if app version >= 9.0.1, when fn will use
        //clear cache with session removing [WKWebsiteDataTypeDiskCache, WKWebsiteDataTypeMemoryCache, WKWebsiteDataTypeCookies,WKWebsiteDataTypeSessionStorage] (cache and memory cache folder, cookies and session as well) (IMP: not remove IndexDB)
        break;
        case "openNewWindow": mappedFunction = "openNewWindow"; // IMP: Make sure to check if app version >= 10.1.0, when fn will use - getting used to open zoom call
        noCallback = true;
        break;
        case "openChildWindow": mappedFunction = "openChildWindow"; // IMP: Make sure to check if app version >= 10.1.0, when fn will use - getting used to open iframe content in new window without minimize support
        noCallback = true;
        break;
        case "openContentInModal": mappedFunction = "openContentInModal"; // IMP: Make sure to check if app version >= 11.0.0, when fn will use - getting used to open iframe content in modal with minimize support
        noCallback = true;
        break;
        case "closeAllNewWindow": mappedFunction = "closeAllNewWindow" // IMP: Make sure to check if app version >= 10.1.0, when fn will use
        break;
        case "closeAllChildWindow": mappedFunction = "closeAllChildWindow" // IMP: Make sure to check if app version >= 10.1.0, when fn will use
        break;
        case "showQuitAlert": mappedFunction = "showQuitAlert" // IMP: Make sure to check if app version >= 10.2.0, when fn will use
        break;
        case "getSecurityStatus": mappedFunction = "getSecurityStatus" // IMP: Make sure to check if app version >= 10.2.0, when fn will use
        break;
        case "allowScreenSharing": mappedFunction = "allowScreenSharing" // Allow to share and capture screen dynamically, (IMP: Make sure to check if app version >= 10.1.0 , when fn will use)
        break;
        case "setSentryUser": mappedFunction = "setSentryUser"; // IMP: Make sure to check if app version >= 11.0.0, when fn will use
        break;
        case "exitApp": mappedFunction = "exitApp"; // IMP: Only for ipad Make sure to check if app version >= 3.2.0, when fn will use
        break;
        // get system info from LS
        // JSON.parse(localStorage.getItem "apNativeVars")
        default: mappedFunction = "nativeLog"
      }

      // key = mappedFunction
      
      let newPromiseId, nativeData;
      
      newPromiseId = generateUUID();
      if(value == undefined){
        value = "";
      }
      nativeData = {
        guid: newPromiseId,
        key: mappedFunction,
        value: value.toString(),
        callbackType: "method/data",
      }
      console.log( "args mac executeAppMethod nativeData", nativeData, noCallback, noCallback === false);
      // # conditionally execute executePromise or executeSetPromiseData method or data depending on callbackType

      appExecutedMethodData[newPromiseId] = nativeData
      mac.postMessage && mac.postMessage(nativeData);
      if(noCallback === false){
        console.log("noCallback === false ==>", noCallback);
        const promise = new Promise((resolve, reject) => {
          let timeout = 5000;
          let elapsedTime = 200;

          if(key === "changeSecurity"){
            timeout = appType === "ios" ? 30000 : 15000;
          }
          
          newInterval[newPromiseId] = setInterval(() => {
            console.log( "elapsedTime, promiseStatus ==>", elapsedTime, promisesData[newPromiseId]);
            let returnedData = promisesData[newPromiseId];

            if (elapsedTime <= timeout) {
              if(returnedData){
                clearInterval(newInterval[newPromiseId])
                if (key === "changeSecurity"){
                  setToLS("changeSecurityGuid", newPromiseId)
                  setToLS("changeSecurityValue", returnedData)
                }
                resolve(returnedData)
              } else if (returnedData === ""){ // @Rajesh ji when it comes as empty string?
                clearInterval(newInterval[newPromiseId]);
                delete newInterval[newPromiseId];
                resolve(returnedData)      
              }
            } else {
              console.log("elapsedTime");
              clearInterval(newInterval[newPromiseId]);
              delete newInterval[newPromiseId];

              let rejectMessage = new Error("Timeout - " + key + " - " + value);
              reject(rejectMessage);
            }
            
            elapsedTime += 200;
          }, 200);
        });
        return promise;
      }
      // catch e
      // console.log e
    }

  }
  finalServiceObject.executeAppMethod = executeAppMethod;
  return finalServiceObject;
}

const OfflineAppService = OfflineAppServiceMethod();

export default OfflineAppService;
