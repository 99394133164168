import React, { useEffect, useState } from "react";
// import "./GroupItemDetail.scss";
import { Route, useRouteMatch, useLocation } from "react-router-dom";
import { Card, Tabs, Button, Row, Col } from "antd";
import Terms from "/src/views/Terms/Terms";
import { getActiveKeyFromPathname } from "/src/lib/utils/helperMethods";
import Spinner from "/src/components/UI/Spinner/Spinner";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const OrgProgrammeItemDetail = (props) => {
  const { item, history, currentUser, ...nestedProps } = props;
  const { path, url, params } = useRouteMatch();
  const location = useLocation();
  const { TabPane } = Tabs;
  const mrIntl = useTranslate();
  const org = currentUser.org
  // let activeKey
  console.log("OrgProgrammeItemDetail props", props, url, location, params, item);

  const [activeKey, setActiveKey] = useState(null);
  const [showSubjectFormLoader, setShowSubjectFormLoader] = useState(false);
  useEffect(() => {
    console.log("location.pathname.split", location.pathname.split("/"));
    // let activeKey1 = location.pathname.split("/")[6]
    let pathNameArray = window.location.pathname.split("/");
    let programsIndex = pathNameArray.indexOf("programs");

    if (programsIndex > -1) {
      setActiveKey(pathNameArray[programsIndex + 2]);
    } else {
      history.push(`${url}/subjects`);
      setActiveKey("subjects");
    }
  }, []);

  const handleTabClick = (key, event) => {
    history.push(`${url}/${key}`);
    setActiveKey(key);
    console.log("inside org item detail==>", url, key, activeKey);
  };

  return (
    <React.Fragment>
      {/* <span>{item.section_segment_attributes.title} - {item.segment_type}</span> */}
      {/* <span>Org programme show - {JSON.stringify(item)}</span> */}
      <Tabs
        activeKey={activeKey}
        onTabClick={handleTabClick}
        type="card"
        className="org-programme-item-detail-tabs"
      >
        <TabPane tab= {mrIntl("CommonText.subjects")} key="subjects" className="subjects-tab">
          {/* <h2>Subjects</h2> */}
          {/* Subjects = Show view with url set to edit */}
          <Card>
            {!(
              item.code === "hs" ||
              item.code === "ms" ||
              item.code === "ps" ||
              item.code === "fs"
            ) &&
              (item.programme_subjects &&
              item.programme_subjects.length != 0 ? (
                showSubjectFormLoader ? (
                  <div>
                    <Spinner />
                  </div>
                ) : (
                  <div>
                    {props.renderForm({
                      name: "orgProgrammesSubjects",
                      config: {
                        isModal: false,
                        visible: true,
                        actions: { submitText: mrIntl("CommonText.save") },
                        submitConfig: {
                          success: {
                            showMessage: true,
                            message: mrIntl("OrgProgrammeItemDetail.update_subject_list_success_msg"),
                          },
                          error: {
                            showMessage: true,
                            message: mrIntl("OrgProgrammeItemDetail.update_subject_list_error_msg"),
                          },
                          successCallback: (data) => {
                            // window.location.href = url
                            setShowSubjectFormLoader(true);
                            setTimeout(() => {
                              setShowSubjectFormLoader(false);
                            }, 20);
                            {
                              /* TEMP hack above to refresh page/reloading component to get newly created subject again with id */
                            }
                          },
                        },
                      },
                      resource: item, //use this for edit
                    })}
                  </div>
                )
              ) : (
                <h4><MrTranslate id={"OrgProgrammeItemDetail.no_subject_in this_programme_msg"}/></h4>
              ))}
            {((currentUser.role === "superadmin" &&
              (((item.org_id == 189 || item.org_id == 948 || item.org_id == 27) &&
                item.code != "diploma" &&
                item.code != "myp") ||
                import.meta.env.VITE_MODE == "staging" ||
                import.meta.env.VITE_MODE == "development")) ||
              item.code === "hs" ||
              item.code === "ms" ||
              item.code === "ps" || 
              item.code === "fs") &&
              props.renderForm({
                name: "customSubjectForm",
                config: {
                  isModal: false,
                  visible: true,
                  actions: { submitText: mrIntl("CommonText.save") },
                  submitConfig: {
                    success: {
                      showMessage: true,
                      message: mrIntl("OrgProgrammeItemDetail.update_sunject_list_msg"),
                    },
                    error: {
                      showMessage: true,
                      message: mrIntl("OrgProgrammeItemDetail.error_msg_adding_subjects"),
                    },
                    successCallback: (data) => {
                      // window.location.href = url
                      setShowSubjectFormLoader(true);
                      setTimeout(() => {
                        setShowSubjectFormLoader(false);
                      }, 20);
                      {
                        /* TEMP hack above to refresh page/reloading component to get newly created subject again with id */
                      }
                    },
                  },
                },
                resource: item, //use this for edit
              })}
          </Card>
        </TabPane>
        <TabPane tab= {mrIntl("CommonText.grades")} key="grades" className="years-tab">
          {/* <h2>Years</h2> */}
          {/* Years = Show view with url set to edit */}

          <Card>
            {item.grades && item.grades.length != 0 ? (
              <div>
                {props.renderForm({
                  name: "orgProgrammesGrades",
                  config: {
                    isModal: false,
                    visible: true,
                    actions: { submitText: mrIntl("CommonText.save") },
                  },
                  resource: {
                    ...item,
                    grades: item?.grades?.sort((a, b) => {
                      const getNumericValue = (grade) => {
                        const match = grade?.label?.match(/\d+/);
                        return match ? parseInt(match[0], 10) : 0;
                      };
                      const numA = getNumericValue(a);
                      const numB = getNumericValue(b);
                      if (numA !== numB) {
                        return numA - numB;
                      }
                      return a?.label?.localeCompare(b?.label);
                    }),
                  },                                       
                })}
              </div>
            ) : (
              <h4><MrTranslate id={"OrgProgrammeItemDetail.no_grades_enabled_yet"}/></h4>
            )}
          </Card>
        </TabPane>
        <TabPane tab={ mrIntl("OrgProgrammeItemDetail.academic_years")} key="terms" className="terms-tab">
          {/* <h2>Academic years</h2> */}
          {/* Academic Years = full crud */}
          <Route
            path={`${path}/terms`}
            render={(routeProps) => (
              <Terms
                params={{ by_termable_id: item.id }}
                createUpdateParams={{
                  termable_id: item.id,
                  termable_type: "org_programme",
                }}
                isRoute={false}
                {...routeProps}
                config={{
                  filter: {
                    show: true,
                    config: {
                      create: {
                        show:
                          (item.settings["mb_integration_enabled"] == true ) || org.is_one_roster || org.is_edlink
                            ? false
                            : true,
                      },
                      search: { show: false },
                    },
                  },
                  pagination: { show: true },
                }}
              />
            )}
          />
        </TabPane>
      </Tabs>
    </React.Fragment>
  );
};

OrgProgrammeItemDetail.defaultProps = {};

OrgProgrammeItemDetail.propTypes = {};

export default OrgProgrammeItemDetail;
