import React, { useEffect, useState } from "react";
import { Button, Modal, Tooltip, Row, Tag, Space, message } from "antd";
import {
  UserAddOutlined,
  InfoOutlined,
  InfoCircleTwoTone,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { actions as loginActions } from "/src/views/Auth/Login/redux";
import { appTypeSelector, quitLoadingSelector } from "/src/App/OfflineApp/offlineAppSelectors";
import { actions as offlineAppActions } from "/src/App/OfflineApp/offlineAppRedux";
import { actions as fireStoreReduxActions } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/redux";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { useHistory } from "react-router-dom";
// import { actions as experienceReduxActions } from "/src/views/Experiences/redux";
import { checkAndChangeSecurity } from "/src/App/OfflineApp/CheckAndChangeSecurity";
import { actions as experienceReduxActions } from "/src/views/Experiences/redux";
import { enabledFeaturesSelector } from "/src/views/Auth/Login/selector";
import { activeAdjustedExperienceIdSelector, activeAdjustedExperienceSelector, startTestBtnLoadingSelector } from "/src/views/Experiences/selector";
import { checkNativeMethodSupported, getAppVersion, isNewAppVersion } from "/src/App/OfflineApp/offlineAppHelper";
import { setToLS } from "/src/lib/utils/helperMethods";

const QuitBtnChromeOS = (props) => {
  const [visible, setVisible] = useState(false);
  const mrIntl = useTranslate()

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = (e) => {
    setVisible(false);
  };

  const handleCancel = (e) => {
    setVisible(false);
  };

  return <>
    <Button danger type="primary" onClick={showModal}>
    {mrIntl("CommonText.quit")}
    </Button>
    <Modal
      // title="Assessment details"
      open={visible}
      footer={null}
      onOk={handleOk}
      onCancel={handleCancel}
      width={800}
      wrapClassName="experience-info-modal"
    >
      <Row className={"m-t-15"}>
        <Space>
          <span>To quit AssessPrep, press the power button and click on sign out.</span>
        </Space>
      </Row>

    </Modal>
  </>
}


const QuitBtn = (props) => {
  const { withLog, userInfo = {}, className = "", quitButtonText = "", quitButtonIcon = null, onQuitTurnOffSecurityOnly } = props;

  const mrIntl = useTranslate()
  const dispatch = useDispatch();
  const history = useHistory();

  const experienceId = useSelector(activeAdjustedExperienceIdSelector())
  const enabledFeatures = useSelector(enabledFeaturesSelector());
  const appType = useSelector(appTypeSelector());
  // const changeSecurityLoading = useSelector(changeSecurityLoadingSelector())
  const quitLoading = useSelector(quitLoadingSelector());
  const startTestBtnLoading = useSelector(startTestBtnLoadingSelector());
  const currentAppVersion = getAppVersion(appType);
  const activeExperience = useSelector(activeAdjustedExperienceSelector());

  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(true); // Want to show quit button by default for old versions iOS and other appTypes

  const isOfflineMode = activeExperience?.settings?.mode === "offline";
  useEffect(() => {
    if (appType === "ios") {
      // if (checkNativeMethodSupported(appType, "getSecurityStatus")) {
        // Showing quit button only when security status is true in iOS 3.2.0 and above
        // Some times security is enabled but quit button not visible
        // setVisible(false)
        // dispatch(
        //   offlineAppActions.executeMethod([{
        //     key: "getSecurityStatus",
        //     value: "status",
        //     successCallback: (response) => {
        //       if (response === "true") {
        //         setVisible(true)
        //       } else {
        //         setVisible(false)
        //       }
        //     }
        //   }])
        // );
      //}
      if (isNewAppVersion(currentAppVersion, "4.0.0")) {
        setVisible(false)
      }
    }
    if(appType === "mac") {
      if(isNewAppVersion(currentAppVersion, "12.0.0")) {
        setVisible(false)
      }
    }
  }, [appType]);


  useEffect(() => {
    setLoading(quitLoading)
  }, [quitLoading])

  // const redirectToHome = () => {
  //   dispatch(experienceReduxActions.setSwitchFromStartToResumeViewSuccess({switchFromStartToResumeView: true}))
  //   history.replace("/")
  //   // openUrl("/") // IMP: openUrl not working offline - gives native error
  // }

  let quitBtn = "";

  if (appType == "seb") {
    quitBtn = (
      <Button
        danger
        type="primary"
        className={className}
        onClick={() => {
          withLog && props.action &&
            dispatch(
              fireStoreReduxActions.setUserInfo(
                userInfo,
                {
                  log: {
                    logging: true,
                    action: props.action,
                  },
                }
              )
            );
        }}
        href={`${import.meta.env.VITE_WEB_URL}/seb_quit.html`}
      >
        {quitButtonIcon} {quitButtonText ? quitButtonText : mrIntl("CommonText.quit")}
      </Button>
    );
  }

  if (appType === "mac") {
    quitBtn = (
      <Button
        loading={loading}
        danger
        type="primary"
        className={className}
        onClick={() => {
          setLoading(true);
          withLog && props.action &&
            dispatch(
              fireStoreReduxActions.setUserInfo(
                userInfo,
                {
                  log: {
                    logging: true,
                    action: props.action,
                  },
                }
              )
            );
          setTimeout(
            () => {
              dispatch(
                offlineAppActions.executeMethod([
                  // {
                  //   key: "clearCache",
                  //   value: true,
                  // },
                  {
                    key: "quit",
                    value: true,
                  },
                ])
              );
              setLoading(false);
            },
            withLog ? 1500 : 0
          );
          // For mac app v12.0.0 and above, we are not setting the LS securityHasBeenInterrupted to true because in 12 version, the security status is coming correctly
          if(appType === "mac" && !isNewAppVersion(currentAppVersion, "12.0.0")) {
            setToLS("securityHasBeenInterrupted", null)
          }
        }}
      >
        {quitButtonIcon} {quitButtonText ? quitButtonText : mrIntl("CommonText.quit")}
      </Button>
    );
  }

  if (appType === "ios") {
    quitBtn = (
      <Button
        loading={loading}
        danger
        type="primary"
        className={className}
        onClick={() => {
          if (!startTestBtnLoading) {
            setLoading(true);
            dispatch(experienceReduxActions.setEndSessionBtnLoadingSuccess({endSessionBtnLoading: true}))
            if (withLog && props.action) {
              dispatch(
                fireStoreReduxActions.setUserInfo(
                  userInfo,
                  {
                    log: {
                      logging: true,
                      action: props.action,
                    },
                  }
                )
              );
            }
  
            setTimeout(() => {
              // exitApp and getSecurityStatus are supported in iOS app versions 3.2.0 and above so checking for getSecurityStatus only
              // At login page enabled features are not able
                if (isNewAppVersion(currentAppVersion, "3.2.0") && checkNativeMethodSupported(appType, "getSecurityStatus")) {
                // For iOS app versions 3.2.0 and above
                dispatch(
                  offlineAppActions.executeMethod([{
                    key: "getSecurityStatus",
                    value: "status",
                    successCallback: (response) => {
                      if (response === "false") {
                        // Exiting app if security status is false
                        // Although we are not showing quit button in this case
                        // But if somehow this button is button is visible and is clicked, we are exiting app
                        // dispatch(
                        //   offlineAppActions.executeMethod([{
                        //     key: "exitApp",
                        //     value: true,
                        //   }])
                        // );
                        message.success(mrIntl("QuitRequested.already_success_message"));
                        if (experienceId) {
                          dispatch(offlineAppActions.quitrequestedSuccess({ quitRequested: true }));
                        }
                        setLoading(false)
                        dispatch(experienceReduxActions.setEndSessionBtnLoadingSuccess({endSessionBtnLoading: false}))
                      } else {
                        // quit requested will take to quit requested component and will try to turn off security
                        checkAndChangeSecurity(
                          dispatch, 
                          {
                            appType: appType, 
                            changeSecurityTo: false, 
                            mrIntl,
                            onSuccess: () => {
                              message.success(mrIntl("QuitRequested.success_message"));
                              setLoading(false)
                              dispatch(experienceReduxActions.setEndSessionBtnLoadingSuccess({endSessionBtnLoading: false}))
                            }, 
                            onError: () => {
                              message.error(mrIntl("QuitRequested.error_message"));
                              setLoading(false)
                              dispatch(experienceReduxActions.setEndSessionBtnLoadingSuccess({endSessionBtnLoading: false}))
                            }
                          });
                        if (experienceId) {
                          dispatch(offlineAppActions.quitrequestedSuccess({ quitRequested: true }));
                        }
                      }
                    }, 
                    errorCallback: () => {
                      setLoading(false)
                      dispatch(experienceReduxActions.setEndSessionBtnLoadingSuccess({endSessionBtnLoading: false}))
                    }
                  }])
                );
              } else {
                // For iOS app versions below 2.0.5 and below
                dispatch(
                  offlineAppActions.executeMethod([{
                    key: "quit",
                    value: true,
                  }])
                );
              }
            }, withLog ? 1500 : 0); // 1500 ms delay for logging action in user info and logs
          }
        }}
      >
        {quitButtonIcon} 
        { isOfflineMode && isNewAppVersion(currentAppVersion, "3.2.0") && checkNativeMethodSupported(appType, "getSecurityStatus")
            ? mrIntl("CommonText.end_session")
            : mrIntl("CommonText.quit")
        }
      </Button>
    );
  }

  if (appType == "cros") {
    quitBtn = <QuitBtnChromeOS />
      // quitBtn = <h5>To quit AssessPrep, press the power button on your keyboard and click on Sign out.</h5>
  }

  return visible ? quitBtn : null;
};

export default QuitBtn;
