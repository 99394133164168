import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appTypeSelector } from "./offlineAppSelectors";
import { enabledFeaturesSelector } from "/src/views/Auth/Login/selector";
import { actions as offlineAppActions } from "/src/App/OfflineApp/offlineAppRedux";
import { checkAutoUpdateSupported, getAutoUpdateValues } from "./offlineAppHelper";


export const CheckNativeAppUpdate = (props) => {
  const dispatch = useDispatch();
  const { handleSetAutoUpdateChecking } = props;
  const appType = useSelector(appTypeSelector());
  const enabledFeatures = useSelector(enabledFeaturesSelector())

  useEffect(() => {
    // Calling getAppVersion function here becuase of first time we are getting the appType to web
    // We have initial appType value is "" instead of "web" check login -> redux for more description
    if (appType !== "" && appType !== "mac") {
      handleSetAutoUpdateChecking && handleSetAutoUpdateChecking(false);
      return;
    }
   
    if (appType === "mac") {
      const isEnableAutoUpdateInMacApp =
        enabledFeatures.allow_auto_update_mac_app;
      if (isEnableAutoUpdateInMacApp && checkAutoUpdateSupported(appType)) {
        const appUpdateValues = getAutoUpdateValues(appType, enabledFeatures);
         
        window.addEventListener("SetAutoUpdateStatus", handleSetAutoUpdateStatus);
        // dispatch the startUpdate action if auto update flag enable
        dispatch(
          offlineAppActions.executeMethod(
            [
              {
                key: "startUpdate",//startUpdate
                value: JSON.stringify(appUpdateValues),
                errorCallback: () => {
                  // set update status to false in case error
                  handleSetAutoUpdateChecking && handleSetAutoUpdateChecking(false);
                },
              },
            ],
            {
              errorCallback: () => {
                // set update status to false in case error
                handleSetAutoUpdateChecking && handleSetAutoUpdateChecking(false);
              },
            }
          )
        );
      } else {
        handleSetAutoUpdateChecking && handleSetAutoUpdateChecking(false)
      }

      return () => {
        window.removeEventListener(
          "SetAutoUpdateStatus",
          handleSetAutoUpdateStatus
        );
      };
    }

  }, [appType]);

  
  const handleSetAutoUpdateStatus = (e) => {
    // get multiple status becuase of mantain the status of update
    // e.detail is the autoUpdateStatus, e.autoUpdateStatus key not worked that's why using default e.detail
    if (["complete","skip","failedToDownload","error","alreadyCheckedTheUpdates"].includes(e.detail)) {
      handleSetAutoUpdateChecking && handleSetAutoUpdateChecking(false);
    }
  };

  return <></>;
};
