import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Row, Select, Space, Tooltip } from "antd";
import { CommentOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import RenderHtml from "/src/components/UI/RenderHtml/RenderHtml";
import CKETextContent from "/src/views/Segments/CKETextContent/CKETextContent";
import "./Comment.scss"
import { isStringEmpty, renderMathInElement } from "/src/lib/utils/helperMethods";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { updateConfigForTool, SpeechToTextTool } from "/src/components/UI/CKEditor/CKEConfig";
import { useSelector } from "react-redux";
import { enabledFeaturesSelector } from "../Auth/Login/selector";

// const Comment = (props) => {
//   console.log("Comment props ===>", props)
//   let { value, placeholder, onChange, ckeConfig = {} } = props;
//   const [preview, setPreview] = useState(true);

//   const onTextChanged = (data) => {
//     onChange(data);
//   };

//   const textToRender = !isStringEmpty(value) ? <RenderHtml text={value} /> : "Add comments";
//   const iconToRender = !isStringEmpty(value) ? <EditOutlined /> : <CommentOutlined />;
//   const isAddingComments = textToRender === "Add comments";
//   const tooltipTitle = isAddingComments ? "Add comments" : "Edit comment"
//   const commentButtonClass = `media-comments add-comment ${ckeConfig.overAllComments ? "overall-grade-header": ""}`

//   return (
//     <>
//       {preview ? (
//         <span className="comment-content-wrapper">
//           <Tooltip title={tooltipTitle} placement="right">
//             <Button
//               onClick={() => {
//                 setPreview(false);
//               }}
//               type="text"
//               size="large"
//               icon={iconToRender}
//               className={commentButtonClass}
//               shape="round"
//             >
//               {tooltipTitle}
//             </Button>
//           </Tooltip>
//           <br />
//           {!isAddingComments && textToRender}
//         </span>
//       ) : (
//         <CKETextContent
//           ckeConfig={ckeConfig}
//           value={value}
//           placeholder={placeholder}
//           onChange={onTextChanged}
//         />
//       )}
//     </>
//   );
// };

const Comment = (props) => {
  console.log("Comment props ===>", props)
  let { value, placeholder, onChange, ckeConfig = {}, writingAssistantConfig = {}, previousComments } = props;
  const [editMode, setEditMode] = useState(false);
  const previousCommentOptions = [];
  previousComments?.forEach(response => {
    if (response.comments?.trim()) {
      const isAlreadyAdded = previousCommentOptions.some(option => option.value === response.comments);
      if (!isAlreadyAdded) {
        previousCommentOptions.push({ value: response.comments, label: <RenderHtml text={response.comments} /> });
      }
    }
  });
  
  const onTextChanged = (data) => {
    onChange(data);
  };
  const commentRef = useRef()
  const textToRender = !isStringEmpty(value) && <RenderHtml text={value} />;
  const iconToRender = !isStringEmpty(value) ? <EditOutlined /> : <CommentOutlined />;
  const isAddingComments = textToRender === "Add comments";
  const tooltipTitle = isAddingComments ? "Add comments" : "Edit comment"
  const commentButtonClass = `media-comments add-comment ${ckeConfig.overAllComments ? "overall-grade-header": ""}`
  const isCommentExist = textToRender ? true : false
  const enabledFeatures = useSelector(enabledFeaturesSelector());
  const sttPluginEnabled = enabledFeatures.speech_to_text_plugin;
  const [selectedComment, setSelectedComment] = useState(value || "");
  const [editorKey, setEditorKey] = useState(0); 
  const mrIntl = useTranslate();

  if (sttPluginEnabled) {
    ckeConfig = updateConfigForTool(ckeConfig, SpeechToTextTool.toolbarName);
  }


  useEffect(() => {
    console.log("checking math in comment");
    if (commentRef.current) {
      renderMathInElement(commentRef.current);
    }
  }, [isCommentExist]);

  const handlePreviousCommentSelect = (comment) => {
    setSelectedComment(comment);
    onChange(comment); 
    setEditorKey((prevKey) => prevKey + 1);
  };

  return (
    <>
      {editMode ? 
        <>
          <CKETextContent
            key={editorKey}
            ckeConfig={ckeConfig}
            value={selectedComment}
            placeholder={placeholder}
            onChange={onTextChanged}
            writingAssistantConfig={writingAssistantConfig}
            editorFooterConfig={{
              visible: true
            }}
          />
          {previousCommentOptions?.length > 0 && (
            <Select
              options={previousCommentOptions}
              placeholder={mrIntl("Comment.select_previous_comment")}
              onChange={(val) => handlePreviousCommentSelect(val)}
              className="previous-comment-selector"
            />
          )}
        </> : (
        <div className={`${isCommentExist && "transparent-background cursor-pointer"} comment-content-wrapper`}
          onClick={() => {
            if (!ckeConfig.isReadOnly) {
              setEditMode(true)
            }
          }}
        >
          <Space direction="vertical" className="comment-content" ref={commentRef}>
            <span className="action-text">{isCommentExist ? <span><EditOutlined /> &nbsp;<MrTranslate id={"Comment.edit_comment"} /></span> : <span><PlusOutlined /> <MrTranslate id={"Comment.add_comment"} /></span>}</span>
            {textToRender}
          </Space>
        </div>
      )}
    </>
  );
};

export default Comment;