import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { firestoreDB, realtimeDB } from "/src/config/initializers";
import { Spinner, MrSelect } from "mr_react_framework";
import { Alert, Col, Input, Space } from "antd";
// import "antd/dist/antd.css";
import "/src/views/Experiences/Experiences.scss";
import MrFirebase from "/src/lib/MrFirebase/MrFirebase";
import VideoCall from "/src/lib/VideoCall/VideoCall";
import {
  studentVideoCallConfig,
  teacherVideoCallConfig,
} from "/src/lib/VideoCall/config";
import {
  getFirebaseTokenLoadingSelector,
  isFirebaseAuthenticatedSelector,
  signInToFirestoreErrorSelector,
} from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/selector";
import { useSelector, useDispatch } from "react-redux";
import {
  appRegionSelector,
  currentUserSelector,
  enabledFeaturesSelector,
} from "/src/views/Auth/Login/selector";
import StudentsList from "./StudentsList";
import { checkMob } from "/src/lib/utils/helperMethods";
import VideoCallWrapper from "../../Components/VideoCallWrapper";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { monitorConfigSelector } from "/src/views/Experiences/selector";
import { actions as experienceActions } from "/src/views/Experiences/redux";
import { SortAscendingOutlined } from '@ant-design/icons';

const SelectStatusFilter = MrSelect({
  // actions: genericActions,
  // resourceName: "grades",
  config: {
    // url: "/grades",
    // params: {by_org_programme_id: 3}, //get from previous filter
    processData: (data, props) => {
      console.log("data select groups", data);
      console.log("props select groups", props);
      return data.map((item) => {
        console.log("class selector item", item);
        return { label: item.label, value: item.value };
      });
    },
    searchApi: false,
    widgetConfig: {
      showSearch: false,
      filterOption: true,
      optionFilterProp: "label",
      placeholder: <MrTranslate id={"ExperienceMonitor.select_status"}/>,
      // placeholder: "Select status",
      // style: { width: "120px" },
      allowClear: true,
    },
  },
});

const SelectGroupFilter = MrSelect({
  // actions: genericActions,
  // resourceName: "grades",
  config: {
    // url: "/grades",
    // params: {by_org_programme_id: 3}, //get from previous filter
    processData: (data, props) => {
      console.log("data select groups", data);
      console.log("props select groups", props);
      return data.map((item) => {
        console.log("class selector item", item);
        return { label: item.label, value: item.value };
      });
    },
    searchApi: false,
    widgetConfig: {
      showSearch: false,
      filterOption: true,
      optionFilterProp: "label",
      // placeholder: "Select class",
      placeholder: <MrTranslate id={"ExperienceMonitor.select_class"}/>,
      // style: { width: "200px" },
      allowClear: true,
      mode: "multiple",
    },
  },
});


const SelectSortFilter = MrSelect({
  config: {
    processData: (data, props) => {
      console.log("data select groups", data);
      console.log("props select groups", props);
      return data.map((item) => {
        console.log("class selector item", item);
        return { label: item.label, value: item.value };
      });
    },
    searchApi: false,
  },
});

const Filter = (props) => {
  const monitorConfig = useSelector(monitorConfigSelector());
  const dispatch = useDispatch();
  const sortType = monitorConfig.sortType || "smart_sort";
  const enabledFeatures = useSelector(enabledFeaturesSelector());
  const showOnlyClassFilterFromMonitor = enabledFeatures.show_only_class_filter_from_monitor;
  const { experience } = props;
  // const [state, setState] = useState({ mode: "realtime" });
  const appRegion = useSelector(appRegionSelector());
  const mrIntl = useTranslate();
  const { Search } = Input
  let isChina = appRegion === "china" ? true : false;

  let groups = experience.groups;
  let groupOptions = [];
  let groupFilterSelect = "";
  if (groups && groups.length > 0) {
    groupOptions = groups.map((g, i) => {
      console.log("groupOptions", g);
      return { label: g.name, value: g.id };
    });
    groupFilterSelect = (
      <SelectGroupFilter
        className="monitor-group-filter"
        key="monitor-group-filter-btn"
        dropdownMatchSelectWidth={false}
        onChange={(value) => {
          console.log("SelectGroupFilter value", value);
          // single select
          // if (value) {
          // props.setParams("params", { group_id: ["==", value] });
          if (value && value.length > 0) {
            // multi select
            // if(new Date() < new Date("2021-02-28").getTime()){
            // props.setParams("params", { group_id: ["in", value] });
            // }else{
            props.setParams("params", {
              group_id: ["array-contains-any", value],
            });
            // }
          } else {
            props.setParams("params", { group_id: null });
          }
        }}
        config={{
          widgetConfig: { style: { width: "200px" }, options: groupOptions },
        }}
      />
    );
  }
  
  
  let optionsForSort = [
    { label: mrIntl("ExperienceMonitor.smart_sort_default"), value: "smart_sort" },
    { label: mrIntl("ExperienceMonitor.sort_by_first_name"), value: "alphabetical_sort" },
    { label: mrIntl("ExperienceMonitor.sort_by_last_name"), value: "last_name_sort" },
  ];
  let sortFilterSelect = (
    <SelectSortFilter
      className="monitor-sort-filter"
      key="monitor-sort-filter-btn"
      value={sortType}
      dropdownMatchSelectWidth={false}
      onChange={(value) => {
        dispatch(
          experienceActions.setMonitorConfigSuccess({
            sortType: value,
          })
        );
      }}
      config={{
        widgetConfig: {
          style: { width: "150px" },
          options: optionsForSort,
        },
      }}
    />
  );

  let statusOptions = [
    // { label: "Joined", value: null },
    { label: mrIntl("ExperienceMonitor.started"), value: "startTest" },
    // { label: "Started", value: "startTest" },
    { label: mrIntl("ExperienceMonitor.ended"), value: "endTest" },
    // { label: "Ended", value: "endTest" },
    { label: mrIntl("ExperienceMonitor.submitted"), value: "submittedTest" },
    // { label: "Submitted", value: "submittedTest" },
  ];
  let statusFilterSelect = (
    <SelectStatusFilter
      className="monitor-status-filter"
      key="monitor-status-filter-btn"
      dropdownMatchSelectWidth={false}
      onChange={(value) => {
        // changed currentView filter to local filter as well after change in stats component - not getting all data again there
        props.setLocalFilters({ currentView: value });
        // props.setLocalFilters({filterParam: {key: "currentView", value: value, includeNull: false}})
        // if (value) {
        //   setParams("params", { currentView: ["==", value] });
        // } else {
        //   setParams("params", { currentView: null });
        // }
      }}
      config={{
        widgetConfig: { style: { width: "150px" }, options: statusOptions },
      }}
    />
  );
  // let orderByNotificationsFilter = <Select
  //   placeholder="Order by"
  //   onChange={(value) => {
  //     setParams("sort", { [value]: "asc" });
  //   }}
  //   defaultValue="has_teacher_read"
  //   options={[
  //     { label: "Notifications", value: "has_teacher_read" },
  //     // { label: "Name", value: "name_lcase" },
  //     // { label: "Updated at", value: "updated_at" },
  //   ]}
  // />

  return (
    <div className="monitor-filters">
      <Space size={"middle"}>
        {/* Todo: figure out text match */}
        {!showOnlyClassFilterFromMonitor && <Search
          id="search-by-name"
          // placeholder="Search by name"
          placeholder={mrIntl("ExperienceMonitor.search_by_name")}
          onSearch={(value) => {
            console.log(value);
            props.setLocalFilters({ name: value });
            // if(!value || value === ""){
            //   props.setLocalFilters({name: null})
            //   // setParams("params", { name_lcase: null });
            // } else {
            //   props.setLocalFilters({name: value})
            //   // setParams("params", { name_lcase: [[">=", value.toLowerCase()], ["<=", value.toLowerCase() + '\uf8ff']] });

            // }
          }}
          allowClear
          enterButton
        />}

        {/* make thsee multi select */}
        {/* {!isChina && statusFilterSelect} */}
        {!showOnlyClassFilterFromMonitor && statusFilterSelect}

        {!isChina && groupFilterSelect}

        {!showOnlyClassFilterFromMonitor && sortFilterSelect}

        {/* {!isChina && orderByNotificationsFilter} */}

        {/* <Button type="primary">Search</Button> */}
        {/* {props.batchSelectionMode && (
          <Checkbox
            // checked={}
            // disabled={disabled}
            onChange={(e) =>
              props.batchAction("update", {
                calc_enabled: e.target.checked,
              })
            }
          >
            Calculator
          </Checkbox>
        )}
        <Checkbox
          checked={props.mode === "realtime"}
          // disabled={this.state.disabled}
          onChange={props.toggleRealtimeMode}
        >
          Realtime Mode
        </Checkbox>
        <Select
          placeholder="Filter By User"
          onChange={(value) => {
            if (value) {
              setParams("params", { user_id: ["==", value] });
            } else {
              setParams("params", { user_id: null });
            }
          }}
          options={[
            { label: "All", value: null },
            { label: "User 1", value: 1 },
            { label: "User 2", value: 2 },
          ]}
        />
        <Select
          placeholder="Filter "
          onChange={(value) => {
            setParams("sort", { [value]: "asc" });
          }}
          options={[
            { label: "Name", value: "name" },
            { label: "Updated at", value: "updated_at" },
          ]}
        /> */}
        {/* <Select
          style={{width: '200px'}}
          placeholder="Status"
          onChange={(value) => {
            console.log("value==>", typeof value);
            const filterArr = [
              "online",
              "calc_enabled",
              "spellcheck_enabled",
              "submitted",
              "endTest",
            ];
            const finalParams = {};
            filterArr.forEach((itm, indx) => {
              if (itm === value) {
                finalParams[value] = ["==", true];
              } else {
                finalParams[itm] = [];
              }
            });
            setParams("params", finalParams);
          }}
          options={[
            { label: "Online", value: "online" },
            { label: "Calculator", value: "calc_enabled" },
            { label: "Spell Check", value: "spellcheck_enabled" },
            { label: "Submitted", value: "submittedTest" },
            { label: "End Test", value: "endTest" },
          ]}
        /> */}
      </Space>
      <Space>{/* <ResetFilter setParams={setParams} /> */}</Space>
    </div>
  );
};

const sortData = (finalData, presenceData, sortType,) => {
  console.log('in sort function==>', finalData, presenceData, sortType);
  let sortedData = finalData.sort((a, b) => {
    if (sortType === "smart_sort") {
      if (a.needs_attention_at > b.needs_attention_at) {
        return -1;
      } else {
        return 1;
      }
    } else if (sortType === "alphabetical_sort") {
      if (
        a.name_lcase.replace(/\s+/g, " ").trim() <
        b.name_lcase.replace(/\s+/g, " ").trim()
      ) {
        return -1;
      }
      if (
        a.name_lcase.replace(/\s+/g, " ").trim() >
        b.name_lcase.replace(/\s+/g, " ").trim()
      ) {
        return 1;
      }
    } else if (sortType === "last_name_sort") {
      let lastNameA = a.name_by_last_name_lcase;
      let lastNameB = b.name_by_last_name_lcase;
      if (lastNameA < lastNameB) {
        return -1;
      }
      if (lastNameA > lastNameB) {
        return 1;
      }
    }
    // if (a.request_resume === "request" && b.request_resume !== "request") {
    //   return -1;
    // } else if (
    //   a.request_resume !== "request" &&
    //   b.request_resume === "request"
    // ) {
    //   return 1;
    // }
    // // if(a.request_resume === "request" && b.has_teacher_read === "request"){

    // // }
    // if (
    //   a.has_teacher_read === false &&
    //   (b.has_teacher_read === true || typeof b.has_teacher_read === "undefined")
    // ) {
    //   console.log("chat a greater a, b", a, b);
    //   return -1;
    // } else if (
    //   (a.has_teacher_read === true ||
    //     typeof a.has_teacher_read === "undefined") &&
    //   b.has_teacher_read === false
    // ) {
    //   console.log("chat a smaller a, b", a, b);
    //   return 1;
    // }
    // if(a.has_teacher_read === true && b.has_teacher_read === true){
    //   console.log( "chat a equal a, b", a, b );
    //   return a.name > b.name ? 1 : -1
    // }
    const aPresenceData = presenceData[a.uid] || {};
    const bPresenceData = presenceData[b.uid] || {};
    if (aPresenceData.state === "online" && bPresenceData.state !== "online") {
      return -1;
    }
    if (aPresenceData.state !== "online" && bPresenceData.state === "online") {
      return 1;
    }
    // if (sortType === "ascending") {
    //   if (a.name_lcase > b.name_lcase) {
    //     return 1;
    //   } else {
    //     return -1;
    //   }
    // } else if (sortType === "descending") {
    //   if (a.name_lcase > b.name_lcase) {
    //     return -1;
    //   } else {
    //     return 1;
    //   }
    // }
    // if (sortType === "chat") {
    //   if (a.has_teacher_read > b.has_teacher_read) {
    //     return 1;
    //   } else {
    //     return -1;
    //   }
    // }
    
    return a.name > b.name ? 1 : -1;
    // return 0
    // return a.name > b.name ? 1 : -1;
  });
  // if (sortType === "ascending") {
  //   sortedData = sortedData.sort((a, b) => (a.name_lcase > b.name_lcase ? 1 : -1));
  // } else if (sortType === "descending") {
  //   sortedData = sortedData.sort((a, b) => (a.name_lcase > b.name_lcase ? -1 : 1));
  // } else if (sortType === 'chat') {
  //   sortedData = sortedData.sort((a, b) => (a.has_teacher_read > b.has_teacher_read ? 1 : -1));
  // }
  return sortedData;
};

const ExperienceMonitor = (props) => {
  console.log("dashboard props ==>", props);
  // const { experienceProps } = props;

  const { experience } = props;
  const mrIntl = useTranslate()

  const isFirebaseAuthenticated = useSelector(isFirebaseAuthenticatedSelector);
  // const isFocusLost = useSelector((state) => {
  //   // return OfflineAppService.focusLost;
  //   return window.focusLost;
  // });
  const currentUser = useSelector(currentUserSelector());
  const appRegion = useSelector(appRegionSelector());
  const isFirebaseTokenLoading = useSelector(getFirebaseTokenLoadingSelector);
  const isMobile = checkMob()
  const monitorConfig = useSelector(monitorConfigSelector());
  const sortType = monitorConfig.sortType || 'smart_sort';
  const enabledFeatures = useSelector(enabledFeaturesSelector())
  const signInToFirestoreError = useSelector(signInToFirestoreErrorSelector)

  // const [sortType, setSortType] = useState('smart_sort');

  useEffect(() => {
    const main = document.getElementsByTagName("html")[0];
    main.classList.add("no-scroll");
    return () => {
      main.classList.remove("no-scroll");
    };
  }, []);

  // const videoCallConfig =
  //   currentUser.role === "student"
  //     ? studentVideoCallConfig
  //     : teacherVideoCallConfig;
  // let uuid = experience.access_code;

  // let videoRender = uuid && (
  //   <>
  //     <VideoCall
  //       containerDimensions={{
  //         height: "700px",
  //         // width: "1000px"
  //         width: "100%",
  //       }}
  //       config={{
  //         roomName: `AssessPrep-${uuid}`,
  //         // password: "hello",
  //         height: "700px",
  //         width: "100%",
  //         // width: "1000px",
  //         // height: 30,
  //         // width: 30,
  //         // passwordRequired: true,
  //         userInfo: {
  //           email: currentUser.email,
  //           displayName: currentUser.name,
  //         },
  //         ...videoCallConfig,
  //       }}
  //     />
  //   </>
  // );
  console.log("chat experience", experience);

  let cloudBackupConnectionErrorAlert;
  if (signInToFirestoreError && (currentUser.role === "admin" || currentUser.role === "teacher")) {
    cloudBackupConnectionErrorAlert = (
      <Alert
        message={<Space direction="vertical">
          {mrIntl("FirestoreInteractions.critical_service_connection_error_teacher")}
          <a href='https://assessprep.zendesk.com/hc/en-us/articles/4841317260177#h_01HFY86R1RS6XX53AF26P45SYW'>See troubleshooting guide (Error code: FS-01)</a>
          </Space>
        }
        banner
        closable={false}
        className={"offline-alert"}
      />
    );
  }
  
  return (
    <React.Fragment>
      {/* <Row className="m-t-15"> */}
      {/* <Col span={20} offset={2} className="m-t-15"> */}

      {/* JITSI! */}
      {experience.status === "published" &&
        experience.settings.enable_ap_video_monitoring && false && (
          <Col span={22} offset={1} className="m-t-15">
            {/* <div className="custom-mini-overlay-monitor"></div> */}
            {/* {videoRender} */}
            <VideoCallWrapper
              videoCallUUID={experience.uid}
            />
          </Col>
        )}

      {/* JISTI! */}
      {cloudBackupConnectionErrorAlert}
      {isFirebaseTokenLoading && <Spinner />}
      {isFirebaseAuthenticated && !isFirebaseTokenLoading && (
        <Col span={isMobile ? 22 : 16} offset={isMobile ? 1 : 4} className="m-t-15">
          <MrFirebase
            realtimeDB={realtimeDB}
            firestoreDB={firestoreDB}
            filter={Filter}
            sortMethod={(finalData, statePresenceData) => sortData(finalData, statePresenceData, sortType)}
            list={StudentsList}
            extraProps={experience.students}
            currentUser={currentUser}
            experience={experience}
            collectionPath={`experiences/${experience.id}/user_info`}
            tableName={"user_info"}
            params={appRegion === "china" ? {experience_id: ["eq", experience.id]} : {}}
          />
        </Col>
      )}

      {/* </Row> */}
    </React.Fragment>
  );
};

ExperienceMonitor.defaultProps = {
  item: {
    id: 1,
  },
};

ExperienceMonitor.propTypes = {
  item: PropTypes.object.isRequired,
};

export default ExperienceMonitor;
