import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { activeAdjustedExperienceSelector, aiAssistantSettingsSelector } from '../../Experiences/selector';
import { getAIServiceBaseURL, getAlphabetAtIndex, getFromLS, getHtml2CanvasUrl, getQuestionContent, getStudentAnswer, getUniqueId, isStringEmpty, removeHTMLTagsFromText, renderMathInElement, uploadImageAndGetUrl } from '/src/lib/utils/helperMethods';
import { getSegmentByIdSelector } from '../selector';
import { useTranslate } from '/src/lib/MrTranslate/MrTranslate';
import { actions as userResponseActions } from '../../UserResponses/redux';
import { enabledFeaturesSelector } from '../../Auth/Login/selector';
import { aiModelSelector, aiModelTempSelector } from '../../Orgs/selector';
import axios from 'axios';
import { Alert, Col, Row, Skeleton, Space, Typography } from 'antd';
import { isEmpty } from 'lodash';
import RenderHtml from '/src/components/UI/RenderHtml/RenderHtml';
import AIContentImprovementFooter from '../Explanation/AIContentImprovementFooter/AIContentImprovementFooter';
import TypingEffect from '../../Experiences/ExperienceShow/Components/TypingEffect/TypingEffect';
import './CheckGradeWithAINew.scss';
import MagicButton from '../GenerateAI/MagicButton';
import { FaRegStopCircle } from 'react-icons/fa';

import { ImMagicWand } from 'react-icons/im';
import AIAssistantSettings from '../GenerateAI/AIAssistantSettings';
import { updateConfigHeaderForDemoUseStagingAI } from '../GenerateAI/generateAIHelperMethods';

const getStudentAnswerImagesForSegment = (euAttachmentsJson, segmentId) => {
  // need to get corresponding segment images from euAttachmentsJson images -> not euAttachmentsJson attachments. images have images from converted pdf also - APL-5787
  let images = euAttachmentsJson?.images?.filter((a) => a.segment_id === segmentId) || []

  return images
}

const CheckGradeWithAINew = ({
  segment,
  experienceUser,
  userResponse,
  setShowGradingForm
}) => {
  const dispatch = useDispatch()
  const mrIntl = useTranslate()
  const activeExperience = useSelector(activeAdjustedExperienceSelector())
  const enabledFeatures = useSelector(enabledFeaturesSelector())
  const aiAssistantSettings = useSelector(aiAssistantSettingsSelector())
  const aiModel = useSelector(aiModelSelector());
  const aiModelTemp = useSelector(aiModelTempSelector());
  const parentSegment = useSelector(getSegmentByIdSelector(segment.parent_id))
  // const gradeWithAI = useSelector(gradeWithAISelector());

  const sseRef = useRef(null);
  const generatedGradesRef = useRef(null);

  const [loading, setLoading] = useState(false)
  const [studentAnswerHTML, setStudentAnswerHTML] = useState("")
  const [previewImages, setPreviewImages] = useState([])
  const [suggestedGrades, setSuggestedGrades] = useState({});
  const [shouldShowAIContent, setShouldShowAIContent] = useState(false)

  // const currentGradeWithAIIndex = gradeWithAI.findIndex((item) => item.userResponseId === userResponse.id)
  const segmentId = segment.id
  const rubric = activeExperience.rubric || {}
  let rubricType = rubric.type_c
  let question = segment.question_segment_attributes || {}
  const segmentSettings = segment.settings || {}
  const questionType = question.question_type
  const maxPoints = userResponse.max_points
  let answerExplanation = segmentSettings.include_explanation ? question.answer_explanation : "";
  let markscheme = segmentSettings.include_markscheme ? question.markscheme : "";
  const studentAnswerImages = getStudentAnswerImagesForSegment(experienceUser.attachments_json, segmentId)
  const scas = userResponse.submission_criterium_associations_attributes
  const isPerQuestionGrading = (activeExperience && activeExperience.settings && activeExperience.settings.grading_setting == "per_question") ? true : false
  const firstAttachmentUrl = studentAnswerImages && studentAnswerImages[0] && studentAnswerImages[0].s3_url
  const isMYP = rubricType && (rubricType === "myp_achievement_level" || rubricType === "criteria_with_points");
  const chooseAIModalEnabled = enabledFeatures.choose_ai_model;
  let isGradeWithAIAllowed = markscheme || answerExplanation;
  
  // Process student answer
  let studentAnswer = getStudentAnswer(questionType, userResponse)
  let answer = removeHTMLTagsFromText(studentAnswer, true);

  if (questionType === "mcq_single"){
    rubricType = "comments_only" // overriding to generate comments only - we already have marks for MCQ - regardless for MYP/DP/Non-IB
  }
  
  // Process question text
  let questionText = getQuestionContent(questionType, question)
  let parentSegmentText = ""
  if (parentSegment && parentSegment.segment_type === "question") {
    const parentSegmentContent = parentSegment.question_segment_attributes.content;
    if (parentSegmentContent) {
      console.log("parentSegmentContent ==>", parentSegmentContent);
      parentSegmentText = removeHTMLTagsFromText(parentSegmentContent, true);
      questionText = parentSegmentText + "\n" + questionText;
    }
  }

  if (!enabledFeatures.with_question_images_in_grading) {
    markscheme = removeHTMLTagsFromText(markscheme, true);
    answerExplanation = removeHTMLTagsFromText(answerExplanation, true);
    questionText = removeHTMLTagsFromText(questionText, true);
  }
  
  // Process student answer attachments
  const studentAnswerAttachmentUrls = []
  if (studentAnswerImages?.length > 0) {
    studentAnswerImages.forEach(image => {
      if (image?.s3_url) {
        studentAnswerAttachmentUrls.push(image.s3_url)
      }
    })
  }

  // Initialize studentAnswerHTML with the raw student answer
  useEffect(() => {
    setStudentAnswerHTML(studentAnswer);
  }, [studentAnswer]);

  // // Fetch suggested grades on component mount
  // useEffect(() => {
  //   getSuggestedGrades()
  //   return () => {
  //     // dispatch(userResponseActions.gradeWithAiSuccess([]))
  //   }
  // }, []);

  const addClassToPhrasesInStudentAnswer = (studentAnswer, commentsByPhrases, className) => {
    for (let i = 0; i < commentsByPhrases.length; i++) {
      let phrase = commentsByPhrases[i].phrase;
      let comment = commentsByPhrases[i].comment;
      // Escape special characters in the phrase for regex
      let escapedPhrase = phrase.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
      // Create regex with global flag to replace all occurrences
      let regex = new RegExp(escapedPhrase, 'g');
      let uniqueId = getUniqueId(10)
      // Replace occurrences with wrapped version (adding class)
      studentAnswer = studentAnswer.replace(regex, `<span class="${className} custom-highlight" onmouseover="document.getElementById('${uniqueId}').style.visibility = 'visible';" onmouseout="document.getElementById('${uniqueId}').style.visibility = 'hidden';">${phrase}<span class="custom-tooltip" id="${uniqueId}">${comment}</span></span>`);
      console.log("studentAnswer phrase", escapedPhrase, studentAnswer);
    }
    return studentAnswer;
  }

  const getSuggestedGrades = async (extraDataToPost = {}) => {
    setLoading(true);
    let rubricTypeMap = {
      "criteria_with_points": "myp_cwp",
      "myp_achievement_level": "comments_only", // or set to myp_cwp if we want per question levels also
      "comments_only": "comments_only",
      "points": "points_with_comments", // or set to points_with_detailed_comments if we want detailed comments
    }
    let rubric_type = rubricTypeMap[rubricType] || "comments_only" // setting default
    let data = {
      rubric_type: rubric_type,
      question_text: questionText,
      student_answer: answer,
      student_answer_files: studentAnswerAttachmentUrls,
      subject_label: activeExperience.custom_fields.subject_item_label,
    }
    let newExtraDataToPost = {...extraDataToPost}
    // This case is only for improving the AI content in case of improve we are passing extraDataToPost
    if(extraDataToPost.last_generated_value && extraDataToPost.user_prompt) {
      const lastGeneratedValue = newExtraDataToPost.last_generated_value
      // Create a formatted string from the last generated value
      let formattedLastGeneratedValue = '';
      
      // Handle points if they exist
      if (lastGeneratedValue.points !== undefined) {
        formattedLastGeneratedValue += `Points: ${lastGeneratedValue.points}\n\n`;
      }
      
      // Handle criteria points for MYP
      if (lastGeneratedValue.criteria && lastGeneratedValue.criteria.length > 0) {
        formattedLastGeneratedValue += 'Criteria points:\n';
        lastGeneratedValue.criteria.forEach(c => {
          formattedLastGeneratedValue += `${c.title}: ${c.points}\n`;
        });
        formattedLastGeneratedValue += '\n';
      }
      
      // Handle comments
      if (lastGeneratedValue.comments) {
        formattedLastGeneratedValue += `Comments:\n${lastGeneratedValue.comments}`;
      }
      
      // Replace the object with the formatted string
      newExtraDataToPost.last_generated_value = formattedLastGeneratedValue.trim();
      data = {...data, ...newExtraDataToPost}
    }
    let updatedPreviewImages = [...studentAnswerAttachmentUrls];
    if (questionType === "rds_table") {
      // for image
      const tableElement = document.querySelector(`[data-id="segment-id-${segmentId}"] .rds-table`);
      const imageUrl = await getHtml2CanvasUrl({ element: tableElement });
      updatedPreviewImages.push(imageUrl);
    }
    if (questionType === "fabric_drawing") {
      const canvasElement = document.querySelector(`${`[data-id="segment-id-${segmentId}"]`} .canvas-container`);
      const imageUrl = await getHtml2CanvasUrl({ element: canvasElement });
      updatedPreviewImages.push(imageUrl);
    }
    if (questionType === "ggb_graph" || questionType === "desmos_graph") {
      const responseJSON = userResponse.response_json || {};
      const png64data = responseJSON?.segment_data?.pngBase64;
      const imageUrl = await uploadImageAndGetUrl(png64data);
      if (imageUrl) {
        updatedPreviewImages.push(imageUrl);
      }
    }
    if (questionType === "audio_input" || questionType === "video_input") {
      const responseJSON = userResponse.response_json || {};
      studentAnswer = responseJSON?.segment_data?.attachments[0]?.url;
    }

    data.student_answer_files = updatedPreviewImages;
    if (markscheme) {
      data.markscheme = markscheme;
    }

    if (answerExplanation) {
      data.answer_explanation = answerExplanation;
    }

    if (enabledFeatures.ai_assistant_settings && aiAssistantSettings?.grading) {
      const gradingSettings = aiAssistantSettings.grading;
      data.strictness = gradingSettings.strictness;
      data.feedback_language = gradingSettings.feedback_language;
      data.feedback_length = gradingSettings.feedback_length;
      data.feedback_tone = gradingSettings.feedback_tone;
      data.additional_instructions = gradingSettings.additional_instructions;
      data.use_seeds_for_grading = gradingSettings.use_seeds_for_grading;
      data.decimal_values_in_points = gradingSettings.decimal_values_in_points;
    }

    if (aiAssistantSettings?.grading?.use_seeds_for_grading && (questionType === "cke_subjective" || questionType === "mcq_single")) {
      data.use_seeds_for_grading = true;
      data.user_response_id = userResponse.id;
      data.question_type = questionType;
    }
    if (enabledFeatures.with_question_images_in_grading) {
      data = {
        ...data,
        with_images: true
      }
    }

    if (isMYP) {
      let criteria = []
      scas.map((ca, i) => {
        criteria.push({
          id: ca.criterium_id,
          title: `${ca.custom_fields.title}-${ca.custom_fields.label}`,
          marks: parseInt(ca.custom_fields.max_points),
        });
      })
      data.criteria = criteria
    }
    if (rubricType === "points") {
      data.max_points = Number(maxPoints)
    }
    if (aiModel) {
      data.model = aiModel
    }
    if (chooseAIModalEnabled) { // temporary open only for Korea Jeju University experiment
      data.temperature = 0.5
    }
    if (aiModelTemp) { // override temperature if set
      data.temperature = aiModelTemp
    }
    console.log("finalData==>", data);
    const token = getFromLS("token");
    const abortController = new AbortController()
    if (sseRef) {
      sseRef.current = {
        abortSse: () => {
          abortController.abort();
        }
      }
    }

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal: abortController.signal
    };
    config = updateConfigHeaderForDemoUseStagingAI(config)
    try {
      const response = await axios.post(
        `${getAIServiceBaseURL()}/grade`,
        data,
        config
      );
      console.log("setSuggestedGrades==>", response);


      if (rubric_type === "points_with_detailed_comments") {
        let grammarComments = response.data.comments.grammar
        let spellingComments = response.data.comments.spelling
        let logicComments = response.data.comments.logic
        let studentAnswerToUse = firstAttachmentUrl ? response.data.studentAnswerFromFile : studentAnswer

        let modifiedAnswer = addClassToPhrasesInStudentAnswer(studentAnswerToUse, grammarComments, "grammar-mistakes-highlight")
        modifiedAnswer = addClassToPhrasesInStudentAnswer(modifiedAnswer, spellingComments, "spelling-mistakes-highlight")
        modifiedAnswer = addClassToPhrasesInStudentAnswer(modifiedAnswer, logicComments, "logic-mistakes-highlight")

        console.log("studentAnswer phrase modifiedAnswer", modifiedAnswer);
        setStudentAnswerHTML(modifiedAnswer)
      }

      setSuggestedGrades(response.data);
      setShouldShowAIContent(true)
      setLoading(false)
      setTimeout(() => {
        console.log("Rendering math");
        renderMathInElement(generatedGradesRef.current, true);
      }, 100);

    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  const handleUseButtonClick = (comment, points = null, criteria = []) => {
    let newCustomFieldsObject = userResponse.custom_fields && typeof userResponse.custom_fields === "object" ? { ...userResponse.custom_fields } : {};
    newCustomFieldsObject = {
      ...newCustomFieldsObject,
      ai_used: {
        ...newCustomFieldsObject.ai_used,
        points: true,
        comments: true,
      },
    };

    console.log("onSuccess ss", comment, points);
    if (scas && scas.length > 0) {
      // !TEMP until MYP grading function calling works
      // let first_sca = item.submission_criterium_associations_attributes[0]
      // first_sca.points = criteria[0].points
      // !TEMP until MYP grading function calling works
      scas.forEach(element => {
        let matchingCriteria = criteria.find((c) => { return parseInt(element.criterium_id) === parseInt(c.id) }) // there are times it may not return id - also match title? - sometimes points key not returned
        console.log("onSuccess ss matchingCriteria", matchingCriteria);
        if (matchingCriteria && matchingCriteria.points !== undefined && matchingCriteria.points !== null) { // can be 0
          element.points = matchingCriteria.points
        }
      });

      let pointsNull = false
      if (rubricType !== "points" && ((!scas || !scas.length) || (scas && scas.length > 0 && scas.find((sca) => sca.points === null || sca.points === undefined)))) {
        // !scas.length to handle old AL submissions where we didn't create scas
        pointsNull = true
      }

      const isMarked = pointsNull ? false : true

      dispatch(userResponseActions.update({
        id: userResponse.id,
        comments: comment,
        submission_criterium_associations_attributes: scas,
        custom_fields: newCustomFieldsObject,
        marked: isMarked
      }, {
        success: { showMessage: true, message: mrIntl("CommonText.points_comments_saved") },
        successCallback: (response) => {
          console.log('after callback==>', response)
          // setShowGradingForm(false)
          // let timeout = setTimeout(() => {setShowGradingForm(true); clearTimeout(timeout)}, 1)
          let updatedUserResponse = response.data.user_response.data.attributes
          dispatch(userResponseActions.setParentsPoints({ updatedUserResponse, isPerQuestionGrading }));
          setShowGradingForm(true)
          setShouldShowAIContent(false)
          // handleDiscardClick()
        },
        errorCallback: (error) => {
          // reject("Something went wrong, Not able to update annotation")
        },
      }));
    } else {
      // DP
      const commentsInfluenceMarked = rubricType === "comments_only"
      const commentsNull = isStringEmpty(comment)

      let pointsNull = false
      if (points == undefined || points == null) {
        pointsNull = true
      }
      console.log("item.points ====>", points);
      const isMarked = (pointsNull && userResponse.points == null) || (commentsInfluenceMarked && commentsNull) ? false : true
      // checking to make sure no existing points assigned - in case of MCQ - already points assigned, only generating comments - so this was setting marked false

      let aiGradedUserResponse = {
        id: userResponse.id,
        comments: comment,
        // points: points,
        marked: isMarked,
        custom_fields: newCustomFieldsObject
      }
      if (rubricType !== "comments_only" && !pointsNull) {
        aiGradedUserResponse = {
          ...aiGradedUserResponse,
          points: points
        }
      }
      dispatch(
        userResponseActions.update(
          aiGradedUserResponse,
          {
            success: {
              showMessage: true,
              message: mrIntl("CommonText.points_comments_saved"),
            },
            successCallback: (response) => {
              console.log(
                "after callback==>",
                userResponse.custom_fields,
                newCustomFieldsObject
              );
              // setShowGradingForm(false);
              // let timeout = setTimeout(() => {
              //   setShowGradingForm(true);
              //   clearTimeout(timeout);
              // }, 1);
              let updatedUserResponse = response.data.user_response.data.attributes
              dispatch(userResponseActions.setParentsPoints({ updatedUserResponse, isPerQuestionGrading }));
              setShowGradingForm(true)
              setShouldShowAIContent(false)
              // handleDiscardClick()
            },
            errorCallback: (error) => {
              // reject("Something went wrong, Not able to update annotation")
            },
          }
        )
      );
    }
  };

  const isSuggestedGradeValid = (suggestedGrade) => {
    console.log("isSuggestedGradeValid==>", suggestedGrade);
    if(isEmpty(suggestedGrade)){
      return false
    }
    if(!suggestedGrade.comments){
      return false
    }
    if(rubricType === "criteria_with_points"){
      if(!suggestedGrade.criteria || suggestedGrade.criteria.length === 0){
        return false
      }
    }
    if(rubricType === "points"){
      if(suggestedGrade.points == null || suggestedGrade.points === "undefined"){
        return false
      }
    }
    return true
  }

  const handleDiscardClick = () => {
    // const newGradeWithAI = [...gradeWithAI]    
    // if(currentGradeWithAIIndex !== -1){
    //   newGradeWithAI.splice(currentGradeWithAIIndex, 1)
    // }
    // dispatch(userResponseActions.gradeWithAiSuccess(newGradeWithAI))
    setShowGradingForm(true)
    setShouldShowAIContent(false)
  }

  const magicButton = (
    <MagicButton
      onClick={() => {
        if (sseRef.current && loading) {
          sseRef.current.abortSse();
          setShowGradingForm(true)
        } else {
          setShowGradingForm(false)
          getSuggestedGrades()
        }
      }}
      tooltipTitle={
        // mrIntl("CheckGradeWithAI.grade_with_ai")
        enabledFeatures.new_grade_with_ai && !isGradeWithAIAllowed // enabledFeatures.grade_with_ai - added this condition to show the tooltip only when grade_with_ai is enabled and sampleAnswer is not present - otherwise allow clicking the button to open upgrade plan modal
        ? mrIntl("CheckGradeWithAI.no_explanation_cannot_suggest_grades")
        : mrIntl("CheckGradeWithAI.grade_with_ai")
      }
      disabled={enabledFeatures.new_grade_with_ai && !isGradeWithAIAllowed} // enabledFeatures.grade_with_ai - added this condition to show the tooltip only when grade_with_ai is enabled and sampleAnswer is not present - otherwise allow clicking the button to open upgrade plan modal
      text={loading ? mrIntl("CommonText.stop") : mrIntl("CheckGradeWithAINew.grade_with_ai")}
      shape="default"
      icon={loading ? <FaRegStopCircle /> : <ImMagicWand />}
    />
  )

  const skeleton = <Skeleton
    className="bg-lavender m-t-10"
    style={{
      borderRadius: '8px',
      padding: '10px',
    }}
    active
    paragraph={{ rows: 7 }}
  />
  
  const aiContent = (
    <Row className="check-grade-with-ai-new">
      <Col className="bg-lavender p-10 border-radius-5 border-red">
        {isSuggestedGradeValid(suggestedGrades) ? (
          <Space direction="vertical" ref={generatedGradesRef}>
            {(rubricType === "points" ||
              rubricType === "criteria_with_points") && (
                <span>
                  <b>
                    {rubricType === "criteria_with_points"
                      ? "Criteria points"
                      : mrIntl("CheckGradeWithAI.points_modal")}

                    {!isMYP && ": "}
                  </b>
                  {isMYP && <br></br>}
                  {!isEmpty(suggestedGrades) &&
                    (isMYP ? (
                      <Space direction="vertical">
                        {suggestedGrades.criteria.map((c) => {
                          return `${c.title}: ${c.points}`;
                        })}
                      </Space>
                    ) : (
                      suggestedGrades.points
                    ))}
                </span>
              )}
            <Typography.Text className="ai-generated-comments">
              <b>{mrIntl("CheckGradeWithAI.comments_modal_text")}</b>
              {!isEmpty(suggestedGrades) &&
                !isEmpty(suggestedGrades.comments) && <div className="ai-generated-comments-text">
                  <RenderHtml text={suggestedGrades.comments}/>
                </div>}
            </Typography.Text>
          </Space>
        ) : (
          <Alert
            type="warning"
            message={mrIntl(
              "CommonText.something_went_wrong_please_try_again"
            )}
          />
        )}
      </Col>
      <Col className="ai-content-improvement-footer-container">
        <AIContentImprovementFooter
          onTextChanged={() => {}}
          handleUseButtonClick={() => {
            if(isMYP){
              handleUseButtonClick(suggestedGrades.comments, null, suggestedGrades.criteria);
            } else if (rubricType === "comments_only") {
              handleUseButtonClick(suggestedGrades.comments, null, []);
            } else {
              // points_with_comments or points_with_detailed_comments
              handleUseButtonClick(suggestedGrades.comments, suggestedGrades.points, []);
              
              // TODO: handle points_with_detailed_comments comments later
              // const comments = (suggestedGrade.comments.overall && suggestedGrade.comments.overall[0] && suggestedGrade.comments.overall[0].comment) || suggestedGrade.comments.overall[0]
              // onSuccess(comments, suggestedGrade.points, []);
            }
          }}
          onMagicButtonClick={getSuggestedGrades}
          promptList={["Be stricter", "Be more lenient", "Be concise", "Be more encouraging", "Highlight improvement areas"]}
          handleDiscard={handleDiscardClick}
          aiGeneratedContent={suggestedGrades}
          showImproveAndDiscardButtons={!loading}
          showImproveButton={suggestedGrades.comments && suggestedGrades.comments.length > 0 && !loading}
          isStreaming={loading}
          defaultActiveTabInSettings={"grading"}
          improveInputPlaceholder={mrIntl("CheckGradeWithAINew.type_instructions_to_improve_the_feedback_or_select_from_the")}
        />
      </Col>
    </Row>
  )
  const aiAssistantSettingsButton = (
    <AIAssistantSettings
      disabled={!isGradeWithAIAllowed || loading}
      defaultActiveTab={"grading"} 
    />
  )

  return (
    <>
      {/* Display magic button and settings when AI content is not yet shown */}
      {!shouldShowAIContent ? (
        <Space className='m-t-10'>
          {magicButton}
          {enabledFeatures.ai_assistant_settings ? aiAssistantSettingsButton : null}
        </Space>
      ) : !loading && aiContent}
      {loading && skeleton}
    </>
  );
};

export default CheckGradeWithAINew;