import React, { Component, PureComponent, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import Spinner from '/src/components/UI/Spinner/Spinner';
import _ from 'lodash';
import update from "immutability-helper";
import { connect, useDispatch, useSelector } from "react-redux";
import supabaseDB from "/src/config/supabaseInitializer";
import { actions as firestoreInteractionActions } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/redux";
import { activeChatsSelector, firstActiveChatSelector, presenceDataState, teacherSubmitLoadingSelector } from '/src/views/Experiences/ExperienceShow/FirestoreInteractions/selector';
import { appRegionSelector, currentUserSelector } from "/src/views/Auth/Login/selector";
import MrChat from '/src/lib/MrChat/MrChat';
import ChatWrapper from '/src/lib/MrChat/UI/ChatWrapper/ChatWrapper';
import { firestoreFieldValue } from '/src/config/initializers';
import { isEqual } from 'lodash';
import { executeConnectionObject, realtimeBatchActions, unsubscribeRealtimeListenerWhenPromise } from '/src/views/Experiences/ExperienceShow/FirestoreInteractions/firebaseHelper';
import { orderBy, query } from 'firebase/firestore';
import { off, onValue, ref } from 'firebase/database';

const defaultConfig = {};
const initialState = {
  mode: "realtime",
  params: {},
  sort: {},
  data: [],
  localFilters: {

  },
  userResponseData: [],
  chatData: [],
  loading: false,
  error: null,
  selected: {},
  // records: {},
  // meta: {},
  presenceData: {},
  batchSelectionMode: false,
  showSelectBoxes: false,
  allSelected: false,
  totalSelections: 0,
}

const printChanges = (a, b, opts = {print: "updateFix"}) => {
  // let a = prevProps, b = this.props;
      // let a = prevState, b = this.state;
      // 
      // 
  let changes = _.reduce(a, function(result, value, key) {
    return _.isEqual(value, b[key]) ?
        result : result.concat(key);
  }, []);
  console.log( `${opts.print} changes`, changes );
  return changes
}
// const MrFirebase = (props) => {
class MrFirebase extends PureComponent {
  constructor(props) {
    super(props);
    const finalInitialState = _.merge({}, initialState, props.initialState || {})
    this.state = { ...initialState };
    this.fireStoreDbref = props.firestoreDB;
    this.realtimeDBRef = null;
    this.realtimeListener = null;
  }
  setLocalFilters = (params, options = {}) => {
    const updatedLocalFilters = options.reset ? {...params} : {...this.state.localFilters,
      ...params};
    this.setState({localFilters: updatedLocalFilters});
  }
  setParams = (key = "params", params, options = {execute: true, reset: false}) => {
    const updatedParams = options.reset ? { ...params } : { ...this.state[key], ...params};
    const updatedState = {
      [key]: {
        ...updatedParams
      },
      data: []
    }
    this.setState(updatedState, () => {
      console.log('params==>', this.state.params)

      options.execute && this.executeFetch();
      // executeFetch or reinitListener
      
    });
  }
  setSelection = (key, value, options = {}) => {
    console.log("selection key", value, options);
    const updatedSelectedState = {
      ...this.state.selected,
      [key]: value
    }

    
    
    let selectedCount = 0
    for (const key in updatedSelectedState) {
      if (updatedSelectedState.hasOwnProperty(key)) {
        const element = updatedSelectedState[key];
        if(element){
          selectedCount += 1;
        }
      }
    }
    let batchSelectionMode = selectedCount > 0 ? true : false;
    
    let allSelected = this.state.allSelected 
    if(value === false){
      allSelected = false
    }
    if(selectedCount == this.state.data.length){
      allSelected = true
    }

    this.setState({ selected: updatedSelectedState, batchSelectionMode, totalSelections: selectedCount, allSelected: allSelected }, () => {
      console.log( "this.state.selected", this.state.selected );
    });
  }

  toggleBatchSelectionMode = (options = {}) => {
    this.setState({
      batchSelectionMode: !this.state.batchSelectionMode
    }, () => {
      options.callback && options.callback()
    })
  }

  toggleShowSelectBoxes = (options = {}) => {
    this.setState({
      showSelectBoxes: !this.state.showSelectBoxes
    }, () => {
      options.callback && options.callback()
    })
  }
  
  clearSelection = () => {
    this.setState({ 
      selected: {},
      batchSelectionMode: false,
      allSelected: false,
      totalSelections: 0
    });
  }

  selectAll = (options = {}) => {
    if(options.unselect){
      this.clearSelection();
      return;
    }
    // To be discussed
    const finalSelectionObj = {}
    let updateSelectedObj = false;
    let filteredData = this.filteredData()
    console.log("selectAll filteredData", filteredData);
    if (options.onlineOnly) {
      filteredData = filteredData.filter((item) => {
        return (
          this.props &&
          this.props.presenceData &&
          this.props.presenceData[item.uid] && 
          this.props.presenceData[item.uid].state === "online" &&
          item.currentView !== "joined" &&
          item.currentView !== "submittedTest"
        );
      });
    }
    console.log( "selectAll options", filteredData, this.props.presenceData, options );


    if(options.keyName){
      // this.state.data.forEach(element => {
      filteredData.forEach(element => {
        console.log( "element", element );
        console.log( "element[options.keyName]", element[options.keyName] );
        if(element[options.keyName]){
          finalSelectionObj[element[options.keyName]] = element[options.valueKey] || true;
          updateSelectedObj = true;
          console.log( "selectAll finalSelectionObj", finalSelectionObj );
        }
      });
    }
    if(updateSelectedObj){
      console.log( "selectAll finalSelectionObj", finalSelectionObj );
      this.setState({
        selected: {
          ...finalSelectionObj
        },
        batchSelectionMode: true,
        allSelected: true,
        totalSelections: filteredData.length
      })
    }
  }

  batchAction = (actionType = "set", updatedValue, options = {}) => {
    realtimeBatchActions(this.props.collectionPath, this.state.selected, updatedValue, {fireStoreDbref: this.fireStoreDbref, ...options});
    return;
  }
  resetParams = () => {
    this.setState({ 
      params: {
        ...initialState.params
      },
      sort: {
        ...initialState.sort
      },

   });
  }
  
  // Not using this anymore - calling the one in firebaseHelper
  buildConnectionObject1 = (config, options = {
    onlyBase: false
  }) => {
    console.log('collectionpath==>', this.props.collectionPath, options, this.state.params)
    let finalConnectionObject = this.fireStoreDbref;
    const finalConfig = config || [
      {
        name: "collection",
        args: [this.props.collectionPath]
      }
    ];
    // finalConnectionObject = finalConnectionObject.collection(this.props.collectionPath)
    finalConfig.forEach(element => {
      finalConnectionObject = finalConnectionObject[element.name](...element.args);
    }); 
    if(!options.onlyBase){
      for (const key in this.state.params) {
        if (this.state.params.hasOwnProperty(key)) {
          const element = this.state.params[key];
          console.log('element, key ==>', element, key)

          
          if(Array.isArray(element) && element.length > 0){
            if(element[0] && Array.isArray(element[0])){
              // console.log( "search params element[0]", element[0] );
              for (let index = 0; index < element.length; index++) {
                const itm = element[index];
                finalConnectionObject = finalConnectionObject.where(`${key}`, ...itm);
              }
            } else {
              console.log( "search params in else", element );
              finalConnectionObject = finalConnectionObject.where(`${key}`, ...element);  
            }
            console.log( "element", element );
            console.log( "this.state.params", this.state.params );
            
            // finalConnectionObject = finalConnectionObject.where(`${key}`, ...element);
          }
        }
      }
      for (const key in this.state.sort) {
        if (this.state.sort.hasOwnProperty(key)) {
          const element = this.state.sort[key];
          if(element){
            finalConnectionObject = query(finalConnectionObject, orderBy(key, element));
          }
        }
      }
    }
    return finalConnectionObject;
  }


  callExecuteConnectionObject = async(config, options = {}) => {
    if(!config) return;
    this.setState({ loading: true, error: null });
    let appRegion = config.appRegion;
    let realtime = this.state.mode === "realtime" ? true : false;
    let connectionOptions = {
      realtime: realtime,
      params: this.state.params,
      sortParams: this.state.sort
    }
    let successFunction, errorFunc

    // unsubscribe first, in case of realtime
    unsubscribeRealtimeListenerWhenPromise(this.realtimeListener)
    // if(typeof(this.realtimeListener) === "function"){
    //   this.realtimeListener();
    // }

    if(appRegion === "china") {
      connectionOptions.channelName = `${config.tableName}:${Object.keys(connectionOptions.params).map((key) => key.concat("_").concat(connectionOptions.params[key])).join(":")}`
      // Object.keys(params).map((key) => key.concat("_").concat(params[key])).join(":")
      this.setState({ channelName: connectionOptions.channelName });
      successFunction = (payload, realtime = true) => {
        if(realtime){
          console.log('supabaseDB Change received!', payload)
          let eventType = payload.eventType
          let finalDataState = this.state.data
          let shouldUpdateState = false
          if(eventType === "INSERT"){
            // new.id is id of row inserted
            // old is {}
            let newRow = payload.new
            const idx = finalDataState.findIndex((itm) => itm.uid === newRow.user_id)
            const dataToPush = [{...newRow, uid: newRow.user_id}];
            console.log( "updatefix dataToPush", dataToPush );
            if(idx === -1){
              finalDataState = update(finalDataState, {$push: dataToPush});
              shouldUpdateState = true;
            }
          } else if(eventType === "UPDATE"){
            // old.id is id of row updated
            let updatedRow = payload.new
            const idx = finalDataState.findIndex((itm) => itm.uid === updatedRow.user_id)
            if(idx !== -1){
              finalDataState = update(finalDataState, {[idx]: {$set: {...updatedRow, uid: updatedRow.user_id}}})
              shouldUpdateState = true;
            }
          } else if(eventType === "DELETE"){
            // old.id is id of row deleted
            // new is {}
            let deletedId = payload.old.id
            const idx = finalDataState.findIndex((itm) => itm.id === deletedId)
            if(idx !== -1){
              finalDataState = update(finalDataState, { $splice: [[idx, 1]] });
              shouldUpdateState = true;
            }
          }
          console.log( "updatefix finalDataState", finalDataState );
          const finalStateChanges = {
            loading: false
          }
          if(shouldUpdateState){
            finalStateChanges.data = finalDataState || [];
          }
          console.log( "supabaseDB final finalDataState==>", finalDataState );
          this.setState(finalStateChanges);
        } else {
          let updatedData = []
          payload.forEach(element => {
            updatedData.push({
              ...element,
              uid: element.user_id
            })
          });
          this.setState({data: updatedData, loading: false})
        }
      }
      errorFunc = (error) => {
        this.setState({ loading: false, error });
        console.log( "error", error );
      }
      const onlyGetSuccessFunction = (data) => {
        console.log("supabaseDB executeConnectionObject first get", data);
        // need to add uid to each row so need to loop
        let updatedData = []
          data.forEach(element => {
            updatedData.push({
              ...element,
              uid: element.user_id
            })
          });
          this.setState({data: updatedData, loading: false})
      }
      // if(realtime){
      //   // must first get and then subscribe in china
      //   let onlyGetConnectionOptions = {
      //     ...connectionOptions,
      //     realtime: false,
      //   }
      //   executeConnectionObject(config, onlyGetConnectionOptions, onlyGetSuccessFunction, errorFunc)
      // } else {
      //   // because different success functions for first get vs handling updates/changes
      //   successFunction = onlyGetSuccessFunction
      // }
          
    } else if(appRegion !== "china") {
      successFunction = (querySnapshot) => 
      {
        // debounce here for 1 second
        let finalDataState = this.state.data;
        let tempDataState = this.state.data;

        let shouldUpdateState = false;
        const parentThis = this;
        console.log( "updatefix successfunction", querySnapshot );
        querySnapshot.docChanges().forEach(function(change) {
          let tempData = change.doc.data();
          tempData.id = change.doc.id;
          const idx = finalDataState.findIndex((itm) => itm.uid === tempData.id)
          console.log( "updatefix change.type", change.type );
          if (change.type === "added") {
            console.log( "updatefix added", tempData );
            const dataToPush = [{...tempData, uid: tempData.id}];
            console.log( "updatefix dataToPush", dataToPush );
            if(idx === -1){
              finalDataState = update(finalDataState, {$push: dataToPush});
              shouldUpdateState = true;
            }
          }
          if (change.type === "modified") {
            console.log( "updatefix modified", tempData );
            // const idx = finalDataState.findIndex((itm) => itm.uid === tempData.id)
            console.log( "updatefix modified idx", idx );
            finalDataState = update(finalDataState, {[idx]: {$set: {...tempData, uid: tempData.id}}})
            shouldUpdateState = true;
          }
          if (change.type === "removed") {
            console.log( "updatefix removed", tempData );
            // const idx = finalDataState.findIndex((itm) => itm.uid === tempData.id)
            finalDataState = update(finalDataState, { $splice: [[idx, 1]] });
            shouldUpdateState = true;

          }
        });
        console.log( "updatefix finalDataState", finalDataState );
        const finalStateChanges = {
          loading: false
        }
        if(shouldUpdateState){
          finalStateChanges.data = finalDataState || [];
        }
        console.log( "final finalDataState==>", finalDataState );
        this.setState(finalStateChanges);
        // const finalData = []
        // querySnapshot.forEach((doc) => {
        //   const docData = doc.data();
        //   console.log( "doc", doc );
        //   console.log( "docData", docData );
        //   finalData.push({...docData, uid: doc.id});
        // });
        // // debounce this
        // this.setState({ loading: false, data: finalData });
  
      }
      errorFunc = (error) => {
        this.setState({ loading: false, error });
        console.error( "mrfirebase onSnapshotError", error );
      }
    }

    let connection = executeConnectionObject(config, connectionOptions, successFunction, errorFunc)

    if(realtime){
      this.realtimeListener = connection
    }
  }

  //To show student presence(online/offline) data in realtime
  getRealtimeStatus = async () => {
    if(this.state.mode !== "realtime"){
      return
    }
    console.log("calling getRealtimeStatus");
    if(this.props.appRegion === "china") {
      let experienceId = this.props.experience.id
      let userId = this.props.currentUser.id
      const channel = supabaseDB().channel(`online_users_eId${experienceId}`, {
        config: {
          presence: {
            key: userId,
          },
        },
      })
      channel.on('presence', { event: 'sync' }, () => {
        // using this event to override all presenceData - all online users. other approach would be to use the join and leave events
        console.log('supabaseDB presence teacher side Online users: ', channel.presenceState())
        let presenceData = {}
        let presenceDataFromSupabaseDB = channel.presenceState()
        for (const key in presenceDataFromSupabaseDB) {
          const valueArr = presenceDataFromSupabaseDB[key]
          if(valueArr && valueArr.length){
            valueArr.forEach(element => {
              presenceData[element.user_id] = {
                state: "online", // only online users in presenceDataFromSupabaseDB
                last_changed: element.last_changed
              }  
            });
          }
        }
        console.log("supabaseDB setting presenceData", presenceData);
        this.props.setPresenceData({presenceData: presenceData}, {shouldMerge: false})
      })
      // channel.on('presence', { event: 'join' }, ({ newPresences }) => {
      //   console.log('supabaseDB New users have joined: ', newPresences)
      // })
      // channel.on('presence', { event: 'leave' }, ({ leftPresences }) => {
      //   console.log('supabaseDB Users have left: ', leftPresences)
      // })
      channel.subscribe(async (status) => {
        if (status === 'SUBSCRIBED') {
          const status = await channel.track({ user_id: userId, last_changed: Date.now() })
          console.log("supabaseDB subscribe status", status)
        }
      })
      
    }else{
      // in global, we get all users presenceData each time
      this.realtimeDBRef = ref(this.props.realtimeDB, `experiences/${this.props.experience.id}/users`);
      onValue(this.realtimeDBRef, (snap) => {
        console.log("Log se pehle", snap.val());
        this.props.setPresenceData({ presenceData: snap.val() || {} });
      });
    }
  }

  

  toggleRealtimeMode = (e) => {
    const value = e.target.checked;
    const finalCheckedVal = value ? "realtime" : "static";
    this.setState({ mode: finalCheckedVal }, () => {
      this.executeFetch();
    });
  }
  componentDidUpdate(prevProps, prevState) {
    console.log( "updatefix props", prevProps, this.props );
    console.log( "updatefix state", prevProps, this.state );
    printChanges(prevProps, this.props);
    printChanges(prevState, this.state, {print: "updateFix state"});
    let shouldExecuteFetch = false, shouldUpdateState = false;
    if(prevProps.collectionPath !== this.props.collectionPath ){
      shouldExecuteFetch = true;
      this.executeFetch();
    }
    console.log( "log this.props.params", this.props.params );
    if((this.props.params && !isEqual(prevProps.params, this.props.params) && !isEqual(this.state.params, this.props.params)) || (this.props.sort && !isEqual(prevProps.sort, this.props.sort) && !isEqual(this.state.sort, this.props.sort))){
      shouldExecuteFetch = true;
      shouldUpdateState = true;
    }
    if(shouldExecuteFetch){
      if(shouldUpdateState){
        this.handleExecuteFetch()

      } else {
        this.executeFetch();
      }
    }
  }
  filteredData = () => {
    const params = this.state.localFilters;
    console.log("lagcheck 341", params);
    const finalData = this.state.data.filter((itm, idx) => {
      let keyCount = 0;
      let finalVal;
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          const element = params[key];
          if(element && itm[key]){
            keyCount += 1;
            console.log("element itm[key]", element, itm[key])
            if(itm[key].toLowerCase().indexOf(element.toLowerCase()) !== -1){
              finalVal = true;
              console.log("element itm[key] finalVal", element, itm[key], finalVal)
              break;
            }
          }
        }
      }
      console.log("element itm[key] params", params)
      if(keyCount === 0 || finalVal){
      // if(Object.keys(params).length === 0 || finalVal){
        return true
      } else {
        return false;
      }
    });
    // const sortedData = _.sortBy(finalData, ['has_teacher_read', "request_resume"], ["asc"]);
    const parentThis = this;
    // const sortedData = _.sortBy(finalData, [function(o) { 
    //   const userOnlineState = parentThis.state.presenceData[o.uid] || {};
    //   console.log( "userOnlineState", userOnlineState );
    //   return [ o.request_resume === 'request'] 
    // }]);
    let sortedData;
    if(this.props.sortMethod){
      // sortedData = this.props.sortMethod(finalData, this.state.presenceData);
      sortedData = this.props.sortMethod(finalData, this.props.presenceData);
    } else {
      sortedData = finalData;
    }
    return sortedData;
    // const sortedData = finalData.sort((a, b) => {
    //   if(a.request_resume === "request" && b.request_resume !== "request"){
    //     return -1;
    //   } else if(a.request_resume !== "request" && b.request_resume === "request"){
    //     return 1;
    //   }
    //   // if(a.request_resume === "request" && b.has_teacher_read === "request"){
        
    //   // }
    //   if(a.has_teacher_read === false && b.has_teacher_read === true){
    //     console.log( "chat a greater a, b", a, b );
    //     return -1;
    //   } else if(a.has_teacher_read === true && b.has_teacher_read === false){
    //     console.log( "chat a smaller a, b", a, b );
    //     return 1;
    //   }
    //   // if(a.has_teacher_read === true && b.has_teacher_read === true){
    //   //   console.log( "chat a equal a, b", a, b );
    //   //   return a.name > b.name ? 1 : -1
    //   // }
    //   const aPresenceData = this.state.presenceData[a.uid] || {};
    //   const bPresenceData = this.state.presenceData[b.uid] || {};
    //   if(aPresenceData.state === "online" && bPresenceData.state !== "online"){
    //     return -1;
    //   }
    //   if(aPresenceData.state !== "online" && bPresenceData.state === "online"){
    //     return 1;
    //   }
    //   return a.name > b.name ? 1 : -1
    //   // return 0
    //   // return a.name > b.name ? 1 : -1;
    // })
    // console.log( "search params finalData", finalData );
    // return sortedData;
  }
  executeFetch = (params = {}) => {
    let config;
    const {collectionPath, tableName} = this.props;
    // if(this.props.appRegion !== "china") {
    //   config = {
    //     name: "collection",
    //     args: [collectionPath],
    //   }
    // } else if(this.props.appRegion === "china") {
    //   config = {
    //     tableName: tableName,
    //   };
    // }
    config = {
      // name: "collection",
      // args: [collectionPath],
      collectionPath: collectionPath,
      tableName: tableName,
      appRegion: this.props.appRegion
    }
    console.log("executeFetch config", config);
    this.callExecuteConnectionObject(config);
    if(this.props.realtimeDB){
      this.getRealtimeStatus();
    }
  }

  handleExecuteFetch(){
    let shouldSetState = false;
    let finalState = {};
    if(this.props.params){
      console.log("this.props.params", this.props.params);
      finalState.params = {
        ...this.props.params
      }
      // for logs append fix - setting data = []
      finalState.data = []
      shouldSetState = true;
    }
    if(this.props.sort){
      finalState.sort = {
        ...this.props.sort
      }
      shouldSetState = true;
    }
    if(this.props.mode){
      finalState.mode = this.props.mode;
      shouldSetState = true;
    }
    if(shouldSetState){
      this.setState({...finalState}, () => {
        this.executeFetch();
      })
    } else {
      this.executeFetch()
    }
  }
  

  componentDidMount() {
    this.handleExecuteFetch()
  }

  componentWillUnmount() {
    // Note: Experience Id was not passing in org logs so in case of unsubscribe it was not working
    // TODO: Generalise it for realtime without experience id too
    if(this.state.mode !== "realtime"){
      return
    }
    if(this.props.appRegion === "china"){
      // console.log("supabase.getChannels()", this.realtimeListener, this.state.channelName, supabaseDB().getChannels());
      // console.log("supabase.supabaseDB", typeof(this.realtimeListener), this.realtimeListener);
      let experienceId = this.props.experience.id

      // !IMP: THIS DOES NOT WORK!
      // supabaseDB.removeChannel(`online_users_eId${experienceId}`)
      // supabaseDB.removeChannel("realtime:online_users_eId1160")
     

      // !IMP: THIS WORKS:
      // supabaseDB.channel("online_users_eId1160").unsubscribe()
      // supabaseDB.channel("user_info:experience_id_eq,1160").unsubscribe()
      // is now an object because returning promise - May have to fix global also - yep - done
      unsubscribeRealtimeListenerWhenPromise(this.realtimeListener)
      
      supabaseDB().channel(`online_users_eId${experienceId}`).unsubscribe()

      console.log("supabase.getChannels()", this.realtimeListener, this.state.channelName, supabaseDB().getChannels());



      // console.log("this.presenceDataSubscription", this.presenceDataSubscription)
      // if(this.presenceDataSubscription !== null && this.presenceDataSubscription !== undefined){
      //   this.presenceDataSubscription.unsubscribe();
      // }
    } else {
      // if(typeof(this.realtimeListener) === "function"){
      //   this.realtimeListener();
      // }

      // is now a promise after making executeConnectionObject async after china supabase
      unsubscribeRealtimeListenerWhenPromise(this.realtimeListener)
  
      if(this.realtimeDBRef){
        off(this.realtimeDBRef);
      }
    }
  }
  submitStudentResponse = (args, options = {}) => {
    this.props.onSubmitStudentResponse(args, {
      // // this is done on backend now
      // successCallback: () => {
      //   // this.updateRecord(args.userId, {
      //   //   currentView: "submittedTest"
      //   // }, {})
      // },
      ...options
    });
  }
  updateRecord = (id, updatedValue, object = {}, options = {}) => {
    this.props.setUserInfo({
      id: id,
      userInfo: {
        ...updatedValue
      }
    }, options)
    return;
  }

  sendMessage = (sCode, senderId, senderName, id, messageText) => {
    console.log('when messaging ==>', sCode, senderId, senderName, id, messageText)
  }
  
  render() {
    const {filter: Filter, list: List, extraProps, currentUser, experience} = this.props;
    console.log("mr firebase props ==>", this.props)
    const childProps = {
      mode: this.state.mode,
      // presenceData: this.state.presenceData,
      // presenceData: this.props.presenceData, // no need
      setParams: this.setParams,
      updateRecord: this.updateRecord,
      submitStudentResponse: this.submitStudentResponse,
      teacherSubmitLoading: this.props.teacherSubmitLoading,
      toggleRealtimeMode: this.toggleRealtimeMode,
      batchSelectionMode: this.state.batchSelectionMode,
      showSelectBoxes: this.state.showSelectBoxes,
      allSelected: this.state.allSelected,
      totalSelections: this.state.totalSelections,
      toggleBatchSelectionMode: this.toggleBatchSelectionMode,
      toggleShowSelectBoxes: this.toggleShowSelectBoxes,
      selected: this.state.selected,
      filterParams: this.state.params,
      setLocalFilters: this.setLocalFilters,
      orderByParams: this.state.sort,
      sendMessage: this.sendMessage,
      extraProps,
      currentUser,
      experience: experience,
      selectAll: this.selectAll,
      clearSelection: this.clearSelection
    }
    // console.log("print kraa de", this.state.presenceData);
    const list = <List {...childProps} data={this.filteredData()} loading={this.state.loading} setSelection={this.setSelection} batchAction={this.batchAction} userResponseData={this.state.userResponseData} chatData={this.state.chatData} dataWithoutLocalFilters={this.state.data} />;
    console.log('in mrfireabse==>', this.state.loading);
    // console.log('socketIO - in mrfireabse presenceData==>', this.props.presenceData);
    
      
    return <React.Fragment>
      <Row gutter={[24,24]}>
        <Col span={24}>
          <Filter {...childProps} batchAction={this.batchAction} />
        </Col>
        <Col span={24}>
          {this.state.loading ? <div className="logs-spinner"><Spinner /></div> : list}
        </Col>
      </Row>
      
    </React.Fragment>;
  }

}

const makeMapStateToProps = () => {
  const teacherSubmitLoadingState = teacherSubmitLoadingSelector;
  const appRegionState = appRegionSelector();
  const mapStateToProps = (state) => {
    return {
      //  bar: getBarState(state, props)
      teacherSubmitLoading: teacherSubmitLoadingState(state),
      appRegion: appRegionState(state),
      presenceData: presenceDataState(state),
      // activeChats: activeChatsSelector(state)
    };
  };
  return mapStateToProps;
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmitStudentResponse: (args, options) => {
      dispatch(firestoreInteractionActions.teacherSubmitExperience(args, options));
    },
    setUserInfo: (params, options = {}) => {
      dispatch(firestoreInteractionActions.setUserInfo(params, options))
    },
    setPresenceData: (params, options = {}) => {
      console.log("socketIO - setPresenceData", params, options);
      dispatch(firestoreInteractionActions.setPresenceDataSuccess(params, options))
    }
    // setActiveChat: (args, options = {}) => {
    //   dispatch(firestoreInteractionActions.setActiveChatSuccess(args, options));
    // },
    
  };
};

MrFirebase.defaultProps = {
  initialState: {}
}
MrFirebase.propTypes = {
  config: PropTypes.object.isRequired,
  collectionPath: PropTypes.string.isRequired,
  list: PropTypes.any.isRequired,
  filter: PropTypes.any.isRequired,
}
export default connect(makeMapStateToProps, mapDispatchToProps)(MrFirebase);