import { useCallback, useEffect, useRef } from "react";
import { wrapSelectedWordInSpan } from "./HighlightWords";

const HighlightSelector = (props) => {
  const { options, textContent } = props;
  const ref = useRef(null);

  useEffect(() => {
    const callback = (sortedSelectedTextRanges, result) => {
      for (const selectedTextRange of sortedSelectedTextRanges) {
        const before = result.substring(0, selectedTextRange.startIndex);
        const toWrap = result.substring(selectedTextRange.startIndex, selectedTextRange.endIndex);
        const after = result.substring(selectedTextRange.endIndex);
        
        result = `${before}<span class="highlight-checked-word cursor-pointer ${
          selectedTextRange.checked ? "checked" : ""
        }" id="${selectedTextRange.id}">${toWrap}</span>${after}`;
      }
      return result;
    }
    const selectedWordInSpan = wrapSelectedWordInSpan(textContent, options, callback);
    ref.current.innerHTML = selectedWordInSpan;
  }, [options, textContent]);

  const handleClick = useCallback(
    (e) => {
      const { id } = e.target;
      props.onSelect(id);
    },
    [props]
  );

  useEffect(() => {
    const options = ref.current?.querySelectorAll(".highlight-checked-word");

    if (options) {
      for (const option of options) {
        option.addEventListener("click", handleClick);
      }
    }

    return () => {
      const options = ref.current?.querySelectorAll(".highlight-checked-word");
      if (options) {
        for (const option of options) {
          if (option) {
            option.removeEventListener("click", handleClick);
          }
        }
      }
    };
  }, [handleClick]);

  return <div ref={ref} className="highlight-word-input-box select-none" />;
};

export default HighlightSelector;
