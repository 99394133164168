import React, {useEffect, useRef, useState, useContext} from 'react';
import PropTypes from 'prop-types';
import { Button, Input, Skeleton, Space, Tooltip, Row, Col, Tag, Menu, Dropdown } from 'antd';
import { merge, union } from 'lodash';
import { SendOutlined, CloseOutlined } from "@ant-design/icons";
import { buildFirestoreConnectionObject, executeConnectionObject, realtimeBatchActions, unsubscribeRealtimeListenerWhenPromise } from '/src/views/Experiences/ExperienceShow/FirestoreInteractions/firebaseHelper';
// import * as MrFirebaseHelper from './../MrFirebase/MrFirebase.js'
import { List } from 'antd';
import { appRegionSelector, currentUserSelector, enabledFeaturesSelector } from '/src/views/Auth/Login/selector';
import { useDispatch, useSelector } from 'react-redux';
import "./MrChat.scss";
import {
  FileOutlined,
  NotificationTwoTone,
  ThunderboltOutlined,
} from "@ant-design/icons";
import { BiCheckDouble } from "react-icons/bi";
import dayjs from 'dayjs';
import {firestoreDB, firestoreFieldValue} from "/src/config/initializers";
import { ChatWrapperContext } from "./UI/ChatWrapper/ChatWrapper";
import { showNotification } from '/src/components/UI/Segment/UIHelper';
import { getInternetStatusSelector ,activeAdjustedExperienceSelector } from '/src/views/Experiences/selector';
import MrTranslate, { useTranslate } from '/src/lib/MrTranslate/MrTranslate';
import { dropDownTrigger, getUniqueId ,allowedPasteCharactersLimit, checkIPAD, checkMob} from '/src/lib/utils/helperMethods';
import { RiSaveLine } from 'react-icons/ri';
import * as Sentry from "@sentry/react";
// import {FaCircle} from "react-icons/fa";
// import Avatar from 'antd/lib/avatar/avatar';
// import TextArea from 'antd/lib/input/TextArea';
// import NotificationSound from "/src/assets/audio/notification-insight.mp3";
import Draggable from "react-draggable"; 
import { actions as firestoreInteractionActions } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/redux";
import { firstActiveChatSelector } from '/src/views/Experiences/ExperienceShow/FirestoreInteractions/selector';
import { CgArrowsExpandDownRight, CgArrowsExpandUpLeft } from 'react-icons/cg';
import { usePinUnpin } from "/src/lib/utils/usePinUnpin";
import { doc, collection as fbCollection, writeBatch } from 'firebase/firestore';

const defaultConfig = {
  mode: "readOnly",
  role: "teacher",
  sender_id: null,
  isActive: false,
  isOnline: false
}

const ChatFooter = (props) => {
  const {sendMessage, isOpen} = props;
  // const activeExperienceSelector = useSelector(activeAdjustedExperienceSelector());
  const inputRef = useRef(null);
  const [chatTxt, setChatTxt] = useState("");
  console.log( "chat isOpen", isOpen );
  const mrIntl = useTranslate();
  useEffect(() => {
    if(isOpen){
      console.log( "chat inputRef", inputRef );
      if(inputRef.current){
        inputRef.current.focus();
      }
    }
  }, [isOpen])
  let internetStatus = useSelector(getInternetStatusSelector());
  const currentUser = useSelector(currentUserSelector());
  const enabledFeatures = useSelector(enabledFeaturesSelector());
  const quickChatEnabled = (enabledFeatures.quick_chat_messages || import.meta.env.VITE_MODE !== "production") && currentUser.role !== "student";
  const [showTooltip, setShowTooltip] = useState(false);


  const localSendMessage = () => {
    console.log("localSendMessage chatTxt", chatTxt, chatTxt.length, chatTxt.trim().length)
    if(internetStatus !== 'online' || chatTxt.trim().length === 0){
      return;
    }
    sendMessage({
      msg_type: "text",
      msg: chatTxt
    }, () => {
      setChatTxt("");
    });
    setChatTxt("");
  }

  
 const quickChatMessages = [
   mrIntl("CommonText.yes"),
   mrIntl("CommonText.no"),
   mrIntl("MrChat.thank_you"),
   mrIntl("MrChat.do_you_need_extra_time"),
   mrIntl("MrChat.submit_assessment_instruction_msg"),
   mrIntl("MrChat.uploaded_handwritten_submissions_msg"),
   mrIntl("MrChat.please_refresh_the_page"),
   mrIntl("MrChat.please_give_more_details_about_your_issue"),
   mrIntl("MrChat.please_turn_your_camera_on"),
   mrIntl("MrChat.i_have_received_your_submission"),
];

const handleQuickChatOverlay = (quickMessage) => {
  if (inputRef.current) {
    inputRef.current.focus();
  }
  setChatTxt(quickMessage);
};
 const quickChatOverlay = (
   <Menu className="quick-chat-overlay">
     <Menu.ItemGroup
      //  title="Quick reply"
      title= {mrIntl("MrChat.quick_reply")}
       className="quick-reply-menu-item-group"
     >
       {quickChatMessages.map((quickMessage) => (
         <Menu.Item onClick={(e) => handleQuickChatOverlay(quickMessage)}>
           {quickMessage}
         </Menu.Item>
       ))}
     </Menu.ItemGroup>
   </Menu>
 );


  return (
    <>
      {/* <Input value={chatTxt} placeholder={"Enter message...."} onPressEnter={() => localSendMessage()} onChange={(e) => {
      // 
      const value = e.target.value;
      setChatTxt(value);
    }}/>
    <Button type="primary" onClick={() => localSendMessage()}><SendOutlined /></Button> */}

      <Row className="chat-footer-container">
        {quickChatEnabled && (
          <Col span={2}>
            <Dropdown
              overlayClassName="quick-chat-dropdown"
              overlay={quickChatOverlay}
              trigger={["click"]}
              placement="topLeft"
              arrow
            >
              <Tooltip
                title={mrIntl("MrChat.quick_replies")}
                open={showTooltip}
                onOpenChange={setShowTooltip}
              >
                <Button
                  icon={<ThunderboltOutlined />}
                  className="quick-chat-button"
                  onClick={(e) => setShowTooltip(false)}
                />
              </Tooltip>
            </Dropdown>
          </Col>
        )}
        <Col span={quickChatEnabled ? 16 : 18}>
          {/* change to Textare and update Antd to 4.9 */}
          <Input
            ref={inputRef}
            placeholder={`${mrIntl("MrChat.type_a_message")}..`}
            rows={1}
            showCount
            maxLength={1000}
            autoSize={false}
            value={chatTxt}
            onPressEnter={() => localSendMessage()}
            onChange={(e) => {
              //
              const value = e.target.value;
              setChatTxt(value);
            }}
            // onPaste={(e) => {
            //   try {
            //     if (activeExperienceSelector.settings.mode === 'online_secure' || activeExperienceSelector.settings.mode === 'offline') {
            //       const pastedText = e.clipboardData.getData('text');
            //       const truncatedText = pastedText.substring(0, allowedPasteCharactersLimit);
            //       const newText = (chatTxt + truncatedText).substring(0, 1000);
            //       setChatTxt(newText);
            //       e.preventDefault();
            //     }
            //   } catch (error) {
            //     console.error('An error occurred:', error);
            //     Sentry.captureException(error);
            //   }
            // }}
          />
        </Col>
        <Col span={6}>
          <Button type="primary" onClick={() => localSendMessage()}>
            {mrIntl("MrChat.send")} <SendOutlined />
          </Button>
        </Col>
      </Row>
    </>
  );
}
const ChatFeed = (props) => {

  console.log("ChatFeed props", props)
  const mrIntl = useTranslate()
  const {messages, config: finalConfig, isOpen} = props;
  const currentUser = useSelector(currentUserSelector());
  const chatBottomRef = useRef(null);
  function scrollToBottomOfChat(options = {}){
    if(chatBottomRef && chatBottomRef.current){
      chatBottomRef.current.scrollIntoView(options);
    }
  }
  useEffect(() => {
    console.log( "chat chatBottomRef", chatBottomRef );
    scrollToBottomOfChat();
  }, [chatBottomRef.current])

  return <>
    <List 
      locale={{emptyText: mrIntl("MrChat.no_messages_yet")}}
      split={false}
      key={`chat-window-${finalConfig.chat_type}-${finalConfig.chat_id}`}
      dataSource={messages}
      renderItem={(item)  => {
        // handle different type of messages
        
        let showDoubleTick = false
        let finalClass = "left";
        let senderName = item.sender_name;
        if(currentUser.id === item.sender_id){
          finalClass = "right"
          senderName = mrIntl("MrChat.you")
        }
        // let finalDesc = `${senderName} - ${dayjs(item.updated_at).format("MMM DD, hh:mm a")}`;
        let finalDesc = []
        // finalDesc.push(senderName)
        finalDesc.push(dayjs(item.updated_at).format("hh:mm a"))
        if(item.read_by_names && item.sender_id === currentUser.id){
          showDoubleTick = item.read_by_names.length > 0 ? true : false
          if(showDoubleTick){
            finalDesc.push(<Tooltip title={`Read by ${item.read_by_names.join(", ")}`}><BiCheckDouble className="double-tick"/></Tooltip>)
          }
          // finalDesc += ` ${item.read_by_names.join(", ")}`;
        }
        return <List.Item key={item.id} className={`ap-chat-bubble ${finalClass}`}>

          <Row className="message-container" style={{"width":"100%"}}>
            {/* {finalClass === "left" && <Col span={3}>
              <Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>{senderName.substr(0,1).toUpperCase()}</Avatar>
            </Col>} */}
            <Col className="message-inner" span={finalClass === "right" ? 20 : 20} offset={finalClass === "right" ? 4 : 0}>
              {/* <Space direction="vertical"> */}
                <span className={"sender-name"}>{senderName}</span>
                <br></br>
                {item.msg_type === "announcement" ? <Tooltip title={mrIntl("MrChat.announcement")}><NotificationTwoTone /></Tooltip> : ""} {item.msg}
                <br></br>
                <Space className="description">{finalDesc}</Space>

              {/* </Space> */}
            </Col>
            {/* {finalClass === "right" && <Col span={3} offset={1}>
              <Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>{senderName.substr(0,1).toUpperCase()}</Avatar>
            </Col>} */}
          </Row>

          {/* <List.Item.Meta
            avatar={<Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>{senderName.substr(0,1).toUpperCase()}</Avatar>}
            title={item.msg}
            description={<Space>{finalDesc}</Space>}
          /> */}
        </List.Item>
        }}
      />
      <div ref={chatBottomRef}/>
    </>
}
const MrChat = (props) => {
  const {config, collection, msgExtra, afterMessageCallback, openChat, handleCurrentTool} = props;
  let chatWindowStatus = useContext(ChatWrapperContext);
  console.log("chat chatWindowStatus", chatWindowStatus);
  const dispatch = useDispatch();
  const firstActiveChat = useSelector(firstActiveChatSelector) || {};
  let isWindowOpen = true;
  const mrIntl = useTranslate()
  const finalConfig = merge({}, defaultConfig, config);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const chatFeedRef = useRef(null);
  const currentUser = useSelector(currentUserSelector());
  const appRegion = useSelector(appRegionSelector());
  const isChina = appRegion === "china" ? true : false;
  const { isPinned, position, isDragging, togglePin, handleDrag, handleStop } = usePinUnpin();
  console.log("MrChat props", props, messages);
  // const notification = new Audio(NotificationSound);
  
  
  let internetStatus = useSelector(getInternetStatusSelector());

  const enabledFeatures = useSelector(enabledFeaturesSelector());
  const draggableTool = enabledFeatures.enable_draggable_tool;
  const newJoinViewWithDynamicConfigEnabled = enabledFeatures.new_join_view_with_dynamic_config && currentUser.role === "student";
  
  if(!finalConfig.sender_id){
    throw new Error("config.sender_id is required");
  }
  const [windowStatus, setWindowStatus] = useState(chatWindowStatus);

  useEffect(() => {
    console.log("chat chatWindowStatus 96", chatWindowStatus);
    if(chatWindowStatus !== windowStatus){
      setWindowStatus(chatWindowStatus);
    }
    // return () => {
    //   cleanup
    // }
  }, [chatWindowStatus])
  const chatParams = {}
  // 1. create a group of invigs and collaborators to message
  const baseCollectionPath = collection.path;
  let baseFirestoreConnectionObj;
  let messagesConnectionObj;
  if(!isChina){
      baseFirestoreConnectionObj = buildFirestoreConnectionObject({
        collectionPath: baseCollectionPath
      });

      messagesConnectionObj = fbCollection(firestoreDB, baseCollectionPath);
      }
  const handleChangedDataCreator = () => {
    const chatWindowStatus = windowStatus;
    return (change, finalData, updateBatch, batch, windowStatus) => {
      
    }

  }
  function scrollToBottomOfChat(options = {}){
    if(chatFeedRef && chatFeedRef.current){
      chatFeedRef.current.scrollIntoView(options);
    }
  }
  useEffect(() => {
    // if(messages.leng)
    const messagesLength = messages.length;
    if(messagesLength && messages[messagesLength - 1].sender_id === currentUser.id){
      scrollToBottomOfChat({ behavior: 'smooth' })
    }
    // if(chatFeedRef && chatFeedRef.current){
    //   chatFeedRef.current.scrollIntoView({ behavior: 'smooth' });
    // }

    // TODO:
    // notification.play()
    // document.title = "Shikhar sent you a message..."
    // // setInterval to keep changing between notif and test title
    // document.title = "AP - 1947AK - MYP Design..."

  }, [messages])
  // const handleChangedData = 
  useEffect(() => {
    const finalParams = {}
    // finalParams.chat_type = finalConfig.chat_type;
    // if(finalConfig.chat_type === "group"){
    //    = ["==", finalConfig.group_id]
    //   finalParams.group_id = ["==", finalConfig.group_id]
    // } else {
      
    //   if(finalConfig.role === "teacher"){
    //     // finalParams.receiver_role = ["==", finalConfig.role];
    //   } else {
    //     // finalParams.receiver_ids = ["array-contains", finalConfig.sender_id];

    //   }
    //   // finalParams.sender_id = finalConfig.receiver_id;
    //   // finalParams.sender_id = ["==", finalConfig.sender_id];
    // }
    if(isChina){
      finalParams.subscription_key = ["eq", `eId${msgExtra.experience_id}_uId${finalConfig.chat_id}`]
      // subscribe can't handle multiple filters so must use uuid - CANT use ilike - only eq so must make another column not use uuid
      // finalParams.experience_id = ["eq", msgExtra.experience_id];
      // finalParams.chat_id = ["eq", parseInt(finalConfig.chat_id)];
      // finalParams.chat_type = ["eq", finalConfig.chat_type]; // add properly when we do group chat - right now all individual so no need
    } else {
      // finalParams.chat_type = finalConfig.chat_type; // add properly when we do group chat - right now all individual so no need
      finalParams.chat_id = ["==", `${finalConfig.chat_id}`];
    }

    console.log("Custom Logger finalParams", finalParams);

    let finalData = [];
    let messagesToBeRead = [];
    
    let successFunc, firstGetSuccessFunc
    let errorFunc = (error) => {
      console.log("supabaseDB realtime subs error in chat subscribe", error);
      // TODO: report to sentry
    }
    let chatConnectionOptions = {
      onlyBase: false, 
      realtime: true,
      params: {
        ...finalParams
      },
      sortParams: {
        created_at: false
      }
    }
    let chatConnectionConfig = {
      collectionPath: baseCollectionPath,
      tableName: "messages",
      appRegion: appRegion
    }
    if(isChina){
      // get messaged first time
      firstGetSuccessFunc = (data) => {
        console.log("supabaseDB executeConnectionObject chat messages first get", data);
        // let finalData = []
        finalData = [...data];
        // data.forEach(element => {
        //   finalData.push({
        //     ...element,
        //   })
        // });
        if (internetStatus === "online") { // TODO: do we need this?
          setMessages(finalData);
        }
        setLoading(false);
      }
      // executeConnectionObject(
      //   chatConnectionConfig, 
      //   {...chatConnectionOptions, realtime: false}, 
      //   (data) => {
      //     console.log("supabaseDB executeConnectionObject chat messages first get", data);
      //     let finalData = []
      //     data.forEach(element => {
      //       finalData.push({
      //         ...element,
      //       })
      //     });
      //     if (internetStatus === "online") { // TODO: do we need this?
      //       setMessages([...finalData]);
      //     }
      //     setLoading(false);
      //   }, 
      //   errorFunc
      // )

      successFunc = (payload, realtime = true) => {
        // let finalData = []
        if(realtime){
          console.log('supabaseDB realtime subs Change received! messages', payload, messages)
          let batchActionParams = [];
          let eventType = payload.eventType
          // TODO: Getting messages each time there is a change - not the best approach but messages is not usable otherwise - any other ideas except using redux?
          // let realTimeChatListener1 = executeConnectionObject(
          //   {...chatConnectionConfig, realtime: false},
          //   chatConnectionOptions,
          //   successFunc,
          //   errorFunc,
          // )

          // finalData = [...messages] // TODO: messages is blank so fetching first but not the best - maybe use redux. adding messages.length to useEffect conditions makes it work but a new listener is made each time 
          if(eventType === "INSERT"){
            // new.id is id of row inserted
            // old is {}
            let newRow = payload.new
            finalData.push(newRow)
  
            let usePureArray = false;
            if (!newRow.read_by) {
              usePureArray = true;
            }
            if (!(newRow.read_by && newRow.read_by.includes(currentUser.id)) && currentUser.id !== newRow.sender_id) {
              console.log("Custom Logger chat chatWindowStatus before update", chatWindowStatus);
              if (chatWindowStatus === "open") {
                if (usePureArray) {
                  newRow.read_by = [currentUser.id];
                  newRow.read_by_names = [currentUser.name];
                } else {
                  newRow.read_by = union(newRow.read_by, [currentUser.id])
                  newRow.read_by_names = union(newRow.read_by_names, [currentUser.name]);
                }
                console.log("Custom Logger readBy", newRow.read_by, newRow.read_by_names);
                
                let msgParams = {
                  basePath: baseCollectionPath,
                  docIds: [newRow.uuid],
                  tableName: "messages",
                  uuids: [newRow.uuid],
                  object: {
                    ...newRow,
                    subscription_key: `eId${newRow.experience_id}_uId${newRow.chat_id}`
                  },
                  options: {},
                };
            
                batchActionParams.push(msgParams);
            
                realtimeBatchActions(batchActionParams, {
                  successCallback: (response) => {
                    console.log("supabase - realtimeBatchActions success read receipt", response);
                  },
                  errorCallback: (error) => {
                    console.log("Global Batch actions executed failed", error);
                  },
                  appRegion: appRegion,
                });
              }
            }
  
           
            
              
          } else if(eventType === "UPDATE"){
            // old.id is id of row updated
            let updatedRow = payload.new
            const idx = finalData.findIndex((itm) => itm.uuid === updatedRow.uuid)
            if (idx !== -1) {
              finalData[idx] = updatedRow;
            }
          } else if(eventType === "DELETE"){
            // old.id is id of row deleted
            // new is {}
            let deletedId = payload.old.id
            const idx = finalData.findIndex((itm) => itm.id === deletedId)
            if (idx !== -1) {
              finalData.splice(idx, 1);
            }
          }
        } else{
          console.log('supabaseDB realtime subs first fetch messages', payload, messages)
          payload.forEach(element => {
            finalData.push({
              ...element,
            })
          });
        }
        
        scrollToBottomOfChat({ behavior: "smooth" })
        // if (internetStatus === "online") { // TODO: need this?
        console.log("supabaseDB realtime subs messages before set", ...finalData);
        setMessages([...finalData]);
        // setMessagesInRedux // TODO maybe
        // }
        setLoading(false);
        console.log('supabaseDB realtime subs messages set', messages)
      }
    } else {
      successFunc = (querySnapshotData) => {

        let batch = writeBatch(firestoreDB);
        let updateBatch = false;
        // const handleChangedData = handleChangedDataCreator();
        querySnapshotData.docChanges().forEach(function (change) {
          let tempData = change.doc.data();
          tempData.id = change.doc.id;
          if (change.type === "added") {
            console.log("chat New data: ", change.doc.data());
            finalData.push({
              ...tempData
            });
            let usePureArray = false;
            if (!tempData.read_by) {
              usePureArray = true;
            }
            if (!(tempData.read_by && tempData.read_by.includes(currentUser.id)) && currentUser.id !== tempData.sender_id) {
              console.log("chat chatWindowStatus before update", chatWindowStatus);
              if (chatWindowStatus === "open") {
                let readBy, readByNames;
                if (usePureArray) {
                  readBy = [currentUser.id];
                  readByNames = [currentUser.name];
                } else {
                  readBy = firestoreFieldValue.arrayUnion(currentUser.id);
                  readByNames = firestoreFieldValue.arrayUnion(currentUser.name);
                }
                const tempRef = doc(messagesConnectionObj, tempData.id);
                let finalUpdatedValue = {
                  read_by: readBy,
                  read_by_names: readByNames
                }
                updateBatch = true;
                batch.update(tempRef, { ...finalUpdatedValue });
              }
              // firestoreFieldValue
            }

          }
          if (change.type === "modified") {
            console.log("chat Modified data: ", change.doc.data());
            const idx = finalData.findIndex((itm) => itm.id === tempData.id)
            if (idx !== -1) {
              finalData[idx] = tempData;
            }
            // finalData.push({
            //   ...tempData;
            // })
          }
          if (change.type === "removed") {
            console.log("chat Removed data: ", change.doc.data());
            const idx = finalData.findIndex((itm) => itm.id === tempData.id)
            if (idx !== -1) {
              finalData.splice(idx, 1);
            }
          }
        });
        console.log("chat finalData", finalData);
        if (updateBatch) {
          batch.commit()
        }
        scrollToBottomOfChat({ behavior: "smooth" })
        //  querySnapshotData.forEach((data) => {
        //   let tempData = data.data(); 
        //   finalData.push({
        //     id: data.id,
        //     ...tempData
        //   });

        // })
        if (internetStatus === "online") {
          setMessages([...finalData]);
        }
        setLoading(false);
        // setMessages(finalData);
        // setLoading(false);

        // 
        // if (data.messages === undefined) {
        //   setChatData([]);
        // } else {
        //   setChatData(data.messages);
        // }
      }
    }

    // TODO: shouldn't this be only when chatWindowStatus open? @abhimanyu
    // subscribe to messages
    let realTimeChatListener
    if(chatWindowStatus === "open") {
    // if(!isChina || (isChina && chatWindowStatus === "open")) {
      realTimeChatListener = executeConnectionObject(
        chatConnectionConfig,
        chatConnectionOptions,
        successFunc,
        errorFunc,
        // firstGetSuccessFunc,
      )
    }

    if(chatWindowStatus === "open"){
      scrollToBottomOfChat()
      // if(chatFeedRef && chatFeedRef.current){
      //   chatFeedRef.current.scrollIntoView();
      // }
    }
    // chatFeedRef.current.scrollIntoView({ behavior: 'smooth' });
    
    return () => {
      // cleanup
      
      unsubscribeRealtimeListenerWhenPromise(realTimeChatListener) // is now promise in both cases
      // if(isChina){
      //   if(typeof(realTimeChatListener) === "object"){
      //     realTimeChatListener && realTimeChatListener.then((unsubscribe) => {
      //       if(unsubscribe && typeof(unsubscribe) === "function"){
      //         unsubscribe();
      //       }
      //     })
      //   }
      // } else {
      //   if (typeof realTimeChatListener === "function") {
      //     realTimeChatListener();
      //   }
      // }

      
    }
  }, [chatWindowStatus])
  

  useEffect(() => {
    console.log("messages==>", messages, config);
    if(messages.length > 0 && currentUser.role === "student" && windowStatus !== "open" && config.hasStudentRead === false){
      let latestMessage = messages[messages.length - 1]
      if(latestMessage.msg_type === "announcement"){
        showNotification('warning', {
          message: 'New announcement!',
          description: latestMessage.msg,
          duration: 0, // user must close
          top: 65
        })
      }else{
        showNotification('info', {
          message: 'New message!',
          description: latestMessage.msg,
          duration: 3,
          top: 65
        })
      }
    }
  }, [messages.length, config.hasStudentRead])

  const sendToRealtimeServer = async (finalMsg) => {
    // let finalConnectionObject;
    console.log("testing chat", finalMsg);
    let msgUuid = finalMsg.uuid ? finalMsg.uuid : `eId${finalMsg.experience_id}_uId${finalMsg.chat_id}_sId${finalMsg.sender_id}_${getUniqueId(8)}_${finalMsg.created_at}`
    let msgParams = {
      basePath: baseCollectionPath,
      docIds: [msgUuid],
      tableName: "messages",
      uuids: [msgUuid],
      object: {
        ...finalMsg,
        subscription_key: `eId${finalMsg.experience_id}_uId${finalMsg.chat_id}`
      },
      options: {},
    };

    let batchActionParams = []
    batchActionParams.push(msgParams);

    realtimeBatchActions(batchActionParams, {
      successCallback: async (response) => {
        console.log("supabase - realtimeBatchActions message send success", response);
        if(afterMessageCallback){
          await afterMessageCallback()
        }
      },
      errorCallback: (error) => {
        console.log("Global Batch actions executed failed", error);
      },
      appRegion: appRegion,
    });
  }
  const sendMessage = (msg = {}, callback = null) => {
    
    if(msg.msg_type === "text"){
      if((msg.msg === "")){
        return;
      }
    }
    // const value = e.target.value;
    let finalMsg = {
      ...msgExtra,
      msg_type: "text",
      msg: msg.msg,
      read_by: [],
      read_by_names: [],
      sender_role: "teacher",
      // created_at: Date.now(),
      updated_at: Date.now(),
      created_at: Date.now(),
      chat_type: finalConfig.chat_type,
      chat_id: finalConfig.chat_id,
      sender_name: finalConfig.sender_name,
      // receiver_ids: finalConfig.receiver_ids,
      sender_id: finalConfig.sender_id,
    }
    if(msg.id){
      finalMsg.id = msg.id;
    } else {
      finalMsg.created_at = Date.now();
    }
    // 
    // if(msg.msg_type === "text"){
    //   // send text message to firebase
    //   finalMsg.msg = msg.msg;
      
    // } else {

    // }

    // send finalMsg to 
    sendToRealtimeServer(finalMsg);
    // send to firebase
    
    // setChatTxt("");
  };
  
  if(loading){
    return <Skeleton />
  }

  let currentView = ""
  let attachmentCountTag = ""
  let attemptPercentage = ""
  if(currentUser.role !== "student" && finalConfig && finalConfig.userInfo){
    if(finalConfig.userInfo.currentView === "startTest"){
      currentView = <Tag color="processing">{mrIntl("MrChat.started")}</Tag>
    }else if(finalConfig.userInfo.currentView === "endTest"){
      currentView = <Tag color="error">{mrIntl("MrChat.ended")}</Tag>
    }else if(finalConfig.userInfo.currentView === "submittedTest"){
      currentView = <Tag color="success">{mrIntl("MrChat.submitted")}</Tag>
    }else if(finalConfig.userInfo.currentView === "resumeTest"){	
      currentView = <Tag color="processing">{mrIntl("MrChat.resuming")}</Tag>	
    }else if(finalConfig.userInfo.currentView === "joined"){	
      currentView = <Tag color='default'>{mrIntl("MrChat.joined")}</Tag>
    }else{
      // currentView = <Tag>Not started</Tag>
      currentView = <Tag color='default'>{mrIntl("MrChat.joined")}</Tag>
    }
  
    attachmentCountTag = finalConfig.userInfo.attachments_count && finalConfig.userInfo.attachments_count !== 0 ? <Tooltip key={"total-uploads-count"} title={mrIntl("MrChat.total_uploads")} placement="top"><Tag color='default'><FileOutlined />  {finalConfig.userInfo.attachments_count}</Tag></Tooltip> : ""
  
    attemptPercentage = finalConfig.userInfo.attempt_count === undefined || finalConfig.userInfo.attempt_count === 0 ? 0 : ((finalConfig.userInfo.attempt_count/finalConfig.totalQuestionsCount)*100).toFixed(0);
  }

  const handleClose = () => {
    if (!newJoinViewWithDynamicConfigEnabled) {
      dispatch(firestoreInteractionActions.setActiveChatSuccess({ id: firstActiveChat.id, status: "minimized", name: firstActiveChat.name }));
    }
    if (handleCurrentTool) {
      handleCurrentTool(!openChat)
    };
  }

  const classNames = `AP-MrChat ${!isPinned ? 'drag-tool-shadow' : ''} ${newJoinViewWithDynamicConfigEnabled ? 'new-join-view-tool' : ''} ${isDragging ? 'tool-dragging' : ''}`;

  const isMobileOrIpad = checkIPAD() || checkMob();
  
  return (
    <Draggable
      handle=".chat-header"
      disabled={isPinned}
      bounds="body"
      onStop={handleStop}
      onDrag={handleDrag}
      position={position}
    >
      <div className={classNames}>
        <div className={"chat-header"}>
          <Space direction="vertical">
            {/* {finalConfig.isActive && <Tooltip title="Active"><FaCircle color="green"/></Tooltip>}  */}
            <span className="chat-header-inner">
              {finalConfig.header ? finalConfig.header : mrIntl("MrChat.chat")}
            </span>
            {currentUser.role !== "student" && finalConfig.isActive && finalConfig.isOnline && " (Active)"}
            {currentUser.role !== "student" && finalConfig.isOnline === false ? mrIntl("MrChat.student_is_offline") : ""}
            {/* show finalConfig.isOnline only in case of student (teacher viewing student chat), not teacher */}
            {currentUser.role !== "student" && <Space>
              {currentView}
              <Tooltip key={"attemptPercentage"} title={mrIntl("MrChat.attempt_percentage")} placement="top"><Tag color='default'>{attemptPercentage}%</Tag></Tooltip>
              {attachmentCountTag}
            </Space>}
          </Space>
          <Button
            type="text"
            icon={<CloseOutlined />}
            className="chat-close-button"
            {...(isMobileOrIpad ? {onTouchStart: () => handleClose()} : {onClick: () => handleClose()})}
          >
          </Button>
          {newJoinViewWithDynamicConfigEnabled && draggableTool && (
            <Tooltip title={isPinned ? "Drag" : "Fixed"}>
              <Button
                type="text"
                icon={isPinned ? <CgArrowsExpandUpLeft /> : <CgArrowsExpandDownRight />}
                className={`chat-close-button ${!isPinned ? "tool-draggable-button" : ""}`}
                {...(isMobileOrIpad ? {onTouchStart: () => togglePin()} : {onClick: () => togglePin()})}
              ></Button>
            </Tooltip>
          )}
        </div>
        {/* {messages.map((item) => {
        
        return <div>{item.msg}</div>
      })} */}
        <div className={"chatFeed"} >
          <ChatFeed config={finalConfig} messages={messages} />
          <div className={"bottomDiv"} ref={(ref) => {
            chatFeedRef.current = ref;
          }} />
        </div>
        <div className={"chatFooter"}>
          <ChatFooter sendMessage={sendMessage} isOpen={chatWindowStatus === "open"} />
        </div>
      </div>
    </Draggable>
  )
};

MrChat.defaultProps = {
  config: {},
  msgExtra: {}
}

MrChat.propTypes = {
  collection: PropTypes.shape({
    path: PropTypes.string,
    config: PropTypes.object,
  }),
}

export default MrChat;
