import { formatJsonApiData } from "mr_react_framework";
import { axiosInstance } from "/src/api/apiModule";
import { all, call, put, select } from "redux-saga/effects";
import reduxCrud, { actions, actionTypes } from "./redux";
import { actions as experienceReduxActions } from "/src/views/Experiences/redux";
import { actions as userResponseReduxActions } from "/src/views/UserResponses/redux";
import { actions as annotationsReduxActions } from "/src/views/Annotations/redux";
import { message } from "/src/components/UI/AntdAppHelper";
import { activeAdjustedExperienceSelector } from "/src/views/Experiences/selector";
import { experienceUsersSelector } from "./selector";
import { dbUserResponsesSelector } from "/src/views/UserResponses/selector";

export function* publishResultsSaga(action) {
  const { experience, publishStatus, groupIds, publishUnmapped, user_id } = action.payload
  const { successCallback, errorCallback } = action.options
  let publishResultsData = {
    experience_id: experience.id,
    publish_status: publishStatus,
  };
  if (user_id) {
    publishResultsData = {
      ...publishResultsData,
      user_id: user_id
    }
  }
  if (groupIds) {
    publishResultsData = {
      ...publishResultsData,
      by_group_ids: groupIds,
    }
  }
  if (publishUnmapped) {
    publishResultsData = {
      ...publishResultsData,
      publish_unmapped: publishUnmapped
    }
  }
  yield put(actions.publishResultsStart());
  let url = "publish_results_experience_users.json";
  try {
    const response = yield axiosInstance.instance.post(url, publishResultsData);
    // yield localStorage.setItem("token", response.data.token);

    console.log("publishResultsSaga response", response);
    // yield put(experienceReduxActions.showSuccess(response));
    // OR
    if (response.data.code === "validation_failed") {
      // message.error(response.data.message);
      if (errorCallback) {
        errorCallback(response)
      }
      let errorMessage = 
        groupIds && groupIds.length > 0 
          ? "No grades have been published. Please ensure that there are submissions from the selected class and at least one student has been completely graded." 
          : "No grades have been published because at least one student needs to be completely graded.";

      yield put(actions.publishResultsFail({ error: errorMessage }));
    } else {
      if (response && response.data.experience) {
        let exp_attrs = response.data.experience;
        let marked_submissions_count = exp_attrs.marked_submissions_count;
        let all_marked = exp_attrs.all_marked;
        yield put(
          experienceReduxActions.showSuccess({
            data: {
              experience: {
                ...experience,
                marked_submissions_count: marked_submissions_count,
                all_marked: all_marked,
              },
            },
          })
        );

        let experienceUsers = yield select(experienceUsersSelector())
        let eusToUpdateMarked, markedValue
        if (publishStatus === "publish") {
          let euIdsPublished = response.data.eu_ids_published
          let eusPublishedCount = euIdsPublished && euIdsPublished.length
          eusToUpdateMarked = experienceUsers.filter((eu) => { return euIdsPublished.indexOf(eu.id) > -1 })
          markedValue = true
          
          message.success(
            `Grades published for ${
              eusPublishedCount
            } student${eusPublishedCount !== 1 ? "s" : ""}`
          );
          if (response.data.eus_names_incomplete_grading.length > 0) {
            message.success(
              `Could not publish for ${
                response.data.eus_names_incomplete_grading.length
              } student${
                response.data.eus_names_incomplete_grading.length !== 1
                  ? "s"
                  : ""
              }. Please finish their grading first. You can filter for ungraded submissions`
            );
          }

          
        } else {
          let euIdsUnPublished = response.data.eu_ids_unpublished
          let eusUnPublishedCount = euIdsUnPublished && euIdsUnPublished.length
          eusToUpdateMarked = experienceUsers.filter((eu) => { return euIdsUnPublished.indexOf(eu.id) > -1 })
          markedValue = false
          message.success(
            `Grades unpublished for ${
              eusUnPublishedCount
            } student${eusUnPublishedCount !== 1 ? "s" : ""}`
          );
        }
        console.log("publishResults eusToUpdateMarked", eusToUpdateMarked, markedValue);
        if(eusToUpdateMarked){
          yield all(eusToUpdateMarked.map(eu => {
            let updatedEU = {
              ...eu,
              marked: markedValue
            }
            return put(
              actions.updateSuccess(
                { data: { experience_user: updatedEU } },
                { success: { showMessage: false } }
              )
            );
          }))
        }
      }
      // Don't fetch all again
      // yield put(actions.fetch({ params: { by_experience_id: experience.id } }));
      yield put(actions.publishResultsSuccess());
      if (successCallback) {
        successCallback(response);
      }
    }
  } catch (error) {
    console.error("publishResultsSaga error", error);
    message.error("Something went wrong in publishing/unpublishing results");
    if (errorCallback) {
      errorCallback(error)
    }
    yield put(actions.publishResultsFail({ error }));
  }
}

export function* retakeExperienceSaga(action) {
  const experienceUser = action.payload.experienceUser;
  const eu_id = experienceUser.id
  const retake_submission = action.payload.retake_submission;
  const durationAfterReset = action.payload.durationAfterReset
  const {successCallback, errorCallback} = action.options
  const activeExperience = yield select(activeAdjustedExperienceSelector())
  // const userId = action.payload.userId
  yield put(actions.retakeExperienceStart());
  yield put(actions.setLoadingSuccess({
    loading: true,
    experience_user_id: eu_id
  }));
  const retakeExperienceData = {
    id: eu_id,
    // user_id: userId
    retake_submission: retake_submission,
    duration_after_reset: durationAfterReset
  };
  let url = "reset_experience_user.json";
  try {
    const response = yield axiosInstance.instance.post(
      url,
      retakeExperienceData
    );
    // yield localStorage.setItem("token", response.data.token);
    
    console.log("retakeExperience response", response, experienceUser);
    // yield put(experienceReduxActions.showSuccess(response));
    // OR
    if (response.data.code === "validation_failed") {
      message.error(response.data.message);
      if (errorCallback) {
        errorCallback(response)
      }
    } else if (response.data.code === "submission_reset_success") {
      
      // todo: splice experienceUser from experience_users list in redux
      // yield put(actions.deleteSuccess({id: experienceUser.id}, {success: {showMessage: true, message: "Submission reset successfully"},}));
      if (retake_submission) {
        yield put(
          actions.deleteSuccess(
            { data: { experience_user: { id: experienceUser.id } } },
            {
              success: {
                showMessage: true,
                message: "Submission reset successfully",
              },
            }
          )
        );
        let submissionsCount = response.data.submissions_count
        if (submissionsCount && activeExperience.submissions_count != submissionsCount) {
          yield put(
            experienceReduxActions.showSuccess({
              data: {
                experience: {
                  ...activeExperience,
                  submissions_count: submissionsCount,
                },
              },
            })
          );
        }
      } else {
        message.success("Student allowed to upload more files.");
      }
      if (successCallback) {
        successCallback(response);
      }

      yield put(actions.retakeExperienceSuccess());
    }
    yield put(actions.setLoadingSuccess({
      loading: false,
      experience_user_id: eu_id
    }));
  } catch (error) {
    console.error("retakeExperience error", error);
    if (retake_submission) {
      message.error("Something went wrong in resetting submission");
    } else {
      message.error("Something went wrong in resetting allow uploads");
    }
    if (errorCallback) {
      errorCallback(error)
    }
    yield put(actions.setLoadingSuccess({
      loading: false,
      experience_user_id: eu_id
    }));
    yield put(actions.retakeExperienceFail({ error }));
  }
}

export function* recoverAttachmentsSaga(action) {
  yield put(actions.recoverAttachmentsStart());

  let url = "recover_attachments_experience_user.json";
  try {
    console.log("action options ==>", action, action.options);
    const response = yield axiosInstance.instance.post(url, action.payload);
    console.log("recoverAttachmentsData response", response);

    if (response && response.data.experience_user) {
      message.success(response.data.message || "Recovered successfully");
      const { success, successCallback } = action.options;
      if (successCallback) {
        successCallback(response);
      }
    }
  } catch (error) {
    console.error("recoverAttachmentsSaga error", error);
    yield put(actions.recoverAttachmentsFail({ error }));
  }
}

export function* autoMapHandWrittenAnswersSaga(action) {
  yield put(actions.autoMapHandwrittenAnswersStart());

  let url = "map_handwritten_answer_images_to_questions.json";
  const { experienceUserId } = action.payload;
  const autoMapHandWrittenAnswersData = {
    id: experienceUserId,
  }
  try {
    console.log("action options ==>", action, action.options);
    const response = yield axiosInstance.instance.post(url, autoMapHandWrittenAnswersData);
    console.log("automaphandwrittenanswers response", response);

    if (response && response.data && response.data.message) {
      if (response.status === 200) {
        message.success(response.data.message);
      } else {
        message.error(response.data.message);
      }
      const { success, successCallback } = action.options;
      if (successCallback) {
        successCallback(response);
      }
    }
  } catch (error) {
    console.error("automaphandwrittenanswers error", error);
    yield put(actions.autoMapHandwrittenAnswersFail({ error }));
  }
}

export function* submitAttachmentsSaga(action) {
  console.log("action options ==>", action, action.options);
  const { success, successCallback, errorCallback } = action.options;
  yield put(actions.submitAttachmentsStart());

  let url = "submit_attachments.json";
  try {
    const response = yield axiosInstance.instance.post(url, action.payload);
    console.log("submitAttachmentsData response", response);

    if (response && response.data.experience_user) {
      message.success(response.data.message || "Files submitted successfully.");
      if (successCallback) {
        successCallback(response);
      }
    }
  } catch (error) {
    console.error("submitAttachmentsSaga error", error);
    if (errorCallback) { 
      errorCallback(error);
    }
    yield put(actions.submitAttachmentsFail({ error }));
  }
}

export function* fetchCustomSaga(action) {
  console.log("Overring fetchCustomSaga", action);

  let url = "experience_users.json";
  try {
    yield put(actions.fetchStart());
    yield put(annotationsReduxActions.fetchStart());
    const response = yield axiosInstance.instance.get(url, action.payload);
    console.log("Overring fetchCustomSaga response", response);
    let experienceUsers = formatJsonApiData(response.data.experience_users.data) || [];
    let userResponses = (response.data.user_responses && formatJsonApiData(response.data.user_responses.data)) || [];
    // make annotations conditional on response data
    let annotations = (response.data.annotations && formatJsonApiData(response.data.annotations.data)) || [];
    let submissionsCount = response.data.submissions_count;

    const activeExperience = yield select(activeAdjustedExperienceSelector())
    console.log("Overring fetchCustomSaga response formatted", experienceUsers, userResponses, annotations, submissionsCount, activeExperience.submissions_count);

    const mergeFetchedData = action.options && action.options.mergeFetchedData

    yield put(
      actions.fetchSuccess({ data: { experience_users: experienceUsers } }, {mergeFetchedData: mergeFetchedData})
    );
    if (userResponses.length) {
      if(mergeFetchedData) {
        yield put(
          userResponseReduxActions.summarySuccess({
            user_responses: userResponses,
          }));
      } else {
        yield put(
          userResponseReduxActions.fetchSuccess({ data: { user_responses: userResponses } })
        );
      }
      yield put(
        actions.setHasUserResponsesSuccess({ids: userResponses.map(ur => parseInt(ur.experience_user_id)) })
      )
    }

    if (annotations.length) {
      yield put(
        annotationsReduxActions.fetchSuccess({ annotations: annotations })
      );
    }

    if (submissionsCount && activeExperience.submissions_count != submissionsCount) {
      yield put(
        experienceReduxActions.showSuccess({
          data: {
            experience: {
              ...activeExperience,
              submissions_count: submissionsCount,
            },
          },
        })
      );
    }
  } catch (error) {
    console.log("Overring fetchCustomSaga experience users fetch error", error);
    yield put(actions.fetchFail({ error: error }));
    yield put(annotationsReduxActions.fetchFail({ error: error }));
  }
}

export function* calculateAchievementLevelSaga(action) {
  console.log("calculateAchievementLevelSaga==>", action);


  let url = "calculate_all_eu_achievement_levels.json";
  try {
    yield put(actions.fetchStart());
    const response = yield axiosInstance.instance.post(url, action.payload);
    let experienceUsers = yield select(experienceUsersSelector())
    experienceUsers.map((eu) => {
      const user = response.data.updated_eus.find((user) => user.eu_id === eu.id);
      console.log("Overring calculateAchievementLevelSaga response", user, eu);
      eu.submission_criterium_associations_attributes = eu.submission_criterium_associations_attributes.map((sca) => {
        const scaUpdate = user.eu_scas.find((update) => update.criterium_id === sca.criterium_id);
  
        if (scaUpdate) {
          return {
            ...sca,
            points: scaUpdate.points,
          };
        }
  
        return sca;
      });
    });
    yield put(
      actions.fetchSuccess({ data: { experience_users: experienceUsers } })
    );
    console.log("Overring calculateAchievementLevelSaga experienceUsers", experienceUsers);

   
  } catch (error) {
    console.log("Overring fetchCustomSaga experience users fetch error", error);
    yield put(actions.fetchFail({ error: error }));
  }
}

export function* convertToAchievementLevelSaga(action) {
  console.log("calculateAchievementLevelSaga==>", action);
  const { successCallback } = action.options

  let url = "calculate_als_for_all_eus_cwp.json";
  try {
    yield put(actions.fetchStart());
    const response = yield axiosInstance.instance.post(url, action.payload);
    // TODO: check response status/data? @nitin

   if (response && response.data && response.data.updated_eus) {
    let experienceUsers = yield select(experienceUsersSelector());

    experienceUsers.map((eu) => {
      const user = response.data.updated_eus.find((user) => user.eu_id === eu.id);
      console.log("updatedSca==>", user)
      eu.submission_criterium_associations_attributes = eu.submission_criterium_associations_attributes.map((sca) => {
        const scaUpdate = user.eu_scas.find((update) => update.criterium_id === sca.criterium_id);
          if (scaUpdate) {
            const updatedSca = {
              ...sca,
              custom_fields: {
                ...sca.custom_fields,
                converted_achievement_level: scaUpdate.custom_fields.converted_achievement_level,
              }
            }
            return updatedSca
          }

          return sca;
        });
      });
      yield put(
        actions.fetchSuccess({ data: { experience_users: experienceUsers } })
      );
      if (successCallback) {
        successCallback(response);
      }
    }
    // console.log("Overring calculateAchievementLevelSaga experienceUsers", experienceUsers);


  } catch (error) {
    console.log("Overring fetchCustomSaga experience users fetch error", error);
    yield put(actions.fetchFail({ error: error }));
  }
}

export function* createNewSubmissionsSaga(action) {
  console.log("aq CNS: ", { ...action.payload });
  const { experienceId, newSubmissionIds } = action.payload
  const { successCallback, errorCallback } = action.options
  const activeExperience = yield select(activeAdjustedExperienceSelector())
  const userResponses = yield select(dbUserResponsesSelector(experienceId))
  console.log("userResponses===>", userResponses);
  yield put(actions.createNewSubmissionsStart());
  let createNewSubmissionsData = {
    experience_id: experienceId,
    user_ids: [...newSubmissionIds]
  }
  let url = `submit_experience_for_students/${experienceId}`

  try {
    const response = yield axiosInstance.instance.post(url, createNewSubmissionsData);
    if (response) {
      console.log("createNewSubmissionsSaga response", response);
      const experienceUsers = yield select(experienceUsersSelector())
      let newExperienceUsers = response.data.new_experience_users.data || []
      newExperienceUsers = newExperienceUsers.map(eu=>{
        let newEU = {
          ...eu, attributes : {...eu.attributes, has_user_responses_in_redux: true}
        } 
        return newEU
      })
      const updatedExperienceUsers = [...experienceUsers, ...newExperienceUsers]

      const newExperienceUsersLength = newExperienceUsers.length
      const existingExperienceUsersLength = response.data.existing_experience_users.length
      const failedExperienceUsersLength = newSubmissionIds.length - (newExperienceUsersLength + existingExperienceUsersLength)
      if (newExperienceUsersLength > 0) {
        yield put(actions.fetchSuccess({ 
          data: { 
            experience_users: updatedExperienceUsers 
          },
          success: { 
            showMessage: false 
          } 
        }))

        let newSubmissionsCount = response.data.submissions_count
        if (newSubmissionsCount > activeExperience.submissions_count) {
          yield put(
            experienceReduxActions.showSuccess({
              data: {
                experience: {
                  ...activeExperience,
                  submissions_count: newSubmissionsCount,
                },
              },
            })
          );
        }

        const newUserResponses = response.data.new_user_responses.data || []
        if (newUserResponses.length > 0) {
          let allUserResponses = [...userResponses, ...newUserResponses]
          console.log("allUserResponses===>", allUserResponses);
          yield put(
            userResponseReduxActions.fetchSuccess({ 
              data: { 
                user_responses: allUserResponses 
              }, 
              success: { 
                showMessage: false 
              }
            })
          );
        }
      }
      const messageForNew = `${newExperienceUsersLength} submission${newExperienceUsersLength > 1 ? "s" : ""} successfully created.`
      const messageForExisting = existingExperienceUsersLength > 0 ? `Note: ${existingExperienceUsersLength} submission${existingExperienceUsersLength > 1 ? "s" : ""} already exist (duplicates not added)` : ""

      const messageForFailed = failedExperienceUsersLength > 0 ? `, ${failedExperienceUsersLength} submission${failedExperienceUsersLength > 1 ? "s" : ""} failed..` : ""

      const successMessage = (messageForNew && `${messageForNew}`) + (messageForExisting && ` ${messageForExisting}`) + (messageForFailed && `${messageForFailed}`)

      message.success(successMessage)

      if (successCallback) {
        successCallback(updatedExperienceUsers, failedExperienceUsersLength)
      }
      yield put(actions.createNewSubmissionsSuccess())
    }
  } catch (error) {
    if (errorCallback) {
      errorCallback(error)
    }
    let errorMessage = "Something went wrong in creating new submissions"
    yield put(actions.createNewSubmissionsFail({error: errorMessage}));
  }
}

export function* assignReviewersSaga(action) {

  let url = "assign_reviewers.json";
  try {
    yield put(actions.assignReviewersStart());
    yield put( actions.fetchStart());

    const experience_users = yield select(experienceUsersSelector(true));
    const eu_reviewer_user_ids_map = experience_users.reduce((map, eu) => {
      map[eu.id] = eu.custom_fields["reviewer_user_ids"];
      return map;
    }, {});

    const response = yield axiosInstance.instance.post(url, {...action.payload, eu_reviewer_user_ids_map});
    let experienceUsers = formatJsonApiData(response.data.experience_users) || [];

    yield put(actions.fetchSuccess({ data: { experience_users: experienceUsers } }));
    yield put(actions.assignReviewersSuccess());
  } catch (error) {
    console.log("assignReviewersSaga error", error);
    yield put( actions.fetchFail());
    yield put(actions.assignReviewersFail({ error: error }));
  }
}

export function* bulkUploadAnswerSheetsSaga(action) {
  console.log("bulkUploadAnswerSheetsSaga action ===>", action);

  const { payload, options } = action;
  const { successCallback, errorCallback } = options;
  const bulkUploadData = {
    experience_id: payload.experienceId,
    bulk_answer_sheets: payload.filesToUpload,
  };

  try {
    const url = "/allow_bulk_upload_to_map_correct_answers";
    const response = yield axiosInstance.instance.post(url, bulkUploadData);

    if (!response?.data) {
      throw new Error("Invalid response from server");
    }

    successCallback?.(response.data);
  } catch (error) {
    console.error("Error in bulkUploadAnswerSheetsSaga:", error);
    errorCallback?.(error);
  }
}

export function* gradeSubmissionsWithAiSaga(action) {
  console.log("gradeSubmissionsWithAiSaga action ===>", action);
  const { successCallback, errorCallback } = action.options;
  const url = `/grade_submissions_with_ai`;
  try {
    const response = yield axiosInstance.instance.post(url, action.payload);
    console.log("gradeSubmissionsWithAiSaga response ===>", response);
    successCallback?.(response.data);
  } catch (error) {
    console.error("Error in gradeSubmissionsWithAiSaga:", error);
    errorCallback?.(error);
  }
}

export const watchExperienceUsers = reduxCrud.generateWatchSaga({
  [actionTypes.FETCH_EXPERIENCE_USER]: fetchCustomSaga,
  [actionTypes.PUBLISH_RESULTS_EXPERIENCE_USER]: publishResultsSaga,
  [actionTypes.RECOVER_ATTACHMENTS_EXPERIENCE_USER]: recoverAttachmentsSaga,
  [actionTypes.AUTO_MAP_HANDWRITTEN_ANSWERS_EXPERIENCE_USER]: autoMapHandWrittenAnswersSaga,
  [actionTypes.SUBMIT_ATTACHMENTS_EXPERIENCE_USER]: submitAttachmentsSaga,
  [actionTypes.RETAKE_EXPERIENCE_EXPERIENCE_USER]: retakeExperienceSaga,
  [actionTypes.CONVERT_TO_ACHIEVEMENT_LEVEL_EXPERIENCE_USER]: convertToAchievementLevelSaga,
  [actionTypes.CREATE_NEW_SUBMISSIONS_EXPERIENCE_USER]: createNewSubmissionsSaga,
  [actionTypes.CALCULATE_ACHIEVEMENT_LEVEL_EXPERIENCE_USER]: calculateAchievementLevelSaga,
  [actionTypes.ASSIGN_REVIEWERS_EXPERIENCE_USER]: assignReviewersSaga,
  [actionTypes.BULK_UPLOAD_ANSWER_SHEETS_EXPERIENCE_USER]: bulkUploadAnswerSheetsSaga,
  [actionTypes.GRADE_SUBMISSIONS_WITH_AI_EXPERIENCE_USER]: gradeSubmissionsWithAiSaga,
});