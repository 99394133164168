import React, { useEffect, useState } from "react";
import { MrSelect } from "mr_react_framework";
import { actions as genericActions } from "/src/App/genericRedux";
import {
  Button,
  Drawer,
  Space,
  Radio,
  Checkbox,
  DatePicker,
  Typography,
  Switch,
  Input
} from "antd";
import { checkMob, getUTCDate } from "../../../lib/utils/helperMethods";
import "../Experiences.scss";
import ResetFilter from "/src/components/ResetFilter/ResetFilter";
import { FilterOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { actions as userActions } from "/src/views/Users/redux";
import { enabledFeaturesSelector, schoolLibraryProgrammePermissionsSelector } from "/src/views/Auth/Login/selector";
import {
  SelectSubject as SelectOrgSubject,
  SelectGrade as SelectOrgGrade,
  SelectProgram,
  SelectProgrammeSubject,
  SelectProgrammeGrade,
  SelectChild,
  SelectStarting,
  SelectOrgQuestionbank,
  SelectOrgQuestionbankRadio,
  SelectCourseId,
  SelectStatus,
  SelectProgrammeGradeRadio,
  SelectProgrammeSubjectRadio,
  SelectEvaluationType,
  SelectDeliveryMode,
  SelectTeachingLevel,
  SelectPaperType,
  // SelectRubric,
} from "/src/components/AppSpecific/SelectSubject/SelectSubject";
import SelectOrgDropdown from "/src/components/AppSpecific/SelectOrg/SelectOrgDropdown";
import ModalFilters from "./ModalFilters";
import { orgquestionbanksSelector } from "/src/views/Questionbanks/selector";
import { isEmpty } from "lodash";
import { activeChildIdSelector } from "/src/views/Users/selector";
// import moment from "moment";
import dayjs from 'dayjs';
import MrTranslate, {useTranslate} from "/src/lib/MrTranslate/MrTranslate";

import { appTypeSelector } from "/src/App/OfflineApp/offlineAppSelectors";
import utcPlugin from 'dayjs/plugin/utc'
import { orgProgrammesSelector } from "/src/views/OrgProgrammes/selector";
import { checkIfSEBHasDynamicConfig } from "/src/App/OfflineApp/offlineAppHelper";
dayjs.extend(utcPlugin);

const { Search } = Input

export const SelectTeachers = MrSelect({
  actions: genericActions,
  resourceName: "users",
  config: {
    url: "/users",
    params: { by_role: "teacher_admin" },
    processData: (data, props) => {
      console.log("data select SelectTeachers=======>", data, props);
      return data.map((item) => {
        console.log("class selector item", item);
        return { label: item.name, value: item.id };
      });
    },
    // searchApi: false,
    searchApi: true,
    widgetConfig: {
      showSearch: true, // if false, this will either load entire data from backend on widget load or onSearch
      // TBD: If false, need to be able to search. Also if can do regular select from redux with this component, then can use same everywhere.
      filterOption: true,
      optionFilterProp: "label",
      placeholder: "Select Author",
      mode: "multiple",
      style: { width: "100%" },
    },
  },
});

export const SelectRubricType = MrSelect({
  actions: genericActions,
  resourceName: "rubrics",
  config: {
    // url: "/rubrics",
    // url: "/rubrics_combined",
    // params: { by_type_c: "group" },
    processData: (data, props) => {
      console.log("rubrics=====>", data)
      return data.map((item) => {
        // add to programme_label
        return { label: item.title , value: item.type_c };
      });
    },
    // searchApi: true,
    searchApi: false,
    // clearValueOnParamsChange: false,
    widgetConfig: {
      // options: [
      //   { label: "Achievement level", value: "myp_achievement_level" },
      //   { label: "Criteria with points", value: "criteria_with_points" },
      //   { label: "Points", value: "points" },
      //   { label: "Comments only", value: "comments_only" },
      // ],
      showSearch: true,
      filterOption: true,
      optionFilterProp: "label",
      placeholder: "Select rubric",
      allowClear: true,
    },
  },
});

// const SelectDuration = MrSelect({
//   config: {
//     processData: (data, props) => {
//       return data.map((item) => {
//         return { label: item.label, value: item.value };
//       });
//     },
//     searchApi: false,
//     widgetConfig: {
//       options: [
//         { label: 0 to 30, value: "0 30" },
//         { label: 30 to 60, value: "30 60" },
//         { label: 60 to 120, value: "60 120" },
//         { label: More than 120, value: "120 1000" },
//       ],
//       showSearch: false,
//       filterOption: true,
//       optionFilterProp: "label",
//       // placeholder: "Starting",
//       placeholder: Select duration,
//       style: { width: "100%" },
//       allowClear: true,
//     },
//   },
// })

const ExperiencesFilter = (props) => {
  console.log("ExperiencesFilter props", props);
  const { currentUser, resourceName } = props;
  const dispatch = useDispatch();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [localFilters, setLocalFilters] = useState({});
  const orgQBs = useSelector(orgquestionbanksSelector());
  const orgProgrammes = useSelector(orgProgrammesSelector())
  const enabledFeatures = useSelector(enabledFeaturesSelector());
  const appType = useSelector(appTypeSelector());
  const activeChildId = useSelector(activeChildIdSelector());
  const schoolLibraryProgrammePermissions = useSelector(schoolLibraryProgrammePermissionsSelector());
  const isPaperModeAllowed = enabledFeatures.paper_mode
  const { RangePicker } = DatePicker;
  const mrIntl = useTranslate()
  console.log("enabledFeatures, activeChildId, props", enabledFeatures, activeChildId, props);
  const isStudentOrParent = currentUser.role === "student" || currentUser.role === "parent"
  const isLibrary = props.is_library
  let extraFilters = [];

  let defaultValues = {
    by_questionbank_id: null,
    by_org_id: null,
    by_org_programme_id: null,
    by_subject_id: null,
    by_grade_id: null,
    by_subject_ids: [],
    by_grade_ids: [],
    by_programme_subject_ids: [],
    by_programme_grade_ids: [],
    // by_block_type: null,
    by_pamoja_course_id: null,
    by_status: [],
    by_evaluation_types: [],
    by_delivery_modes: [],
    // by_order: "upcoming",
    // by_starting: null,
    // by_original: true,
    by_original: false,
    by_orgs: false,
    by_school: false,
    by_date_range: [],
    by_start_at_date_range: [],
    by_student_status: currentUser.role === "student" ? "upcoming" : null,
    by_access_code: null,
    by_child_id: null,
    by_rubric_types: [],
    by_duration: [],
    by_difficulty_level: null,
    by_criteria_ids: [],
    by_tag_teaching_levels_ids: [],
    by_author_ids: [],
    by_tag_paper_types_ids: [],
    include_archived: null,
    only_archived: null,
    hide_pamoja_quizzes: true
  }

  let {
    by_questionbank_id,
    by_org_id,
    by_org_programme_id,
    by_subject_id,
    by_grade_id,
    by_subject_ids,
    by_grade_ids,
    by_programme_subject_ids,
    by_programme_grade_ids,
    by_pamoja_course_id,
    by_status,
    by_evaluation_types,
    by_delivery_modes,
    by_original,
    by_orgs,
    by_school,
    by_date_range,
    by_start_at_date_range,
    by_student_status,
    by_access_code,
    by_child_id,
    by_rubric_types,
    by_duration,
    by_difficulty_level,
    by_criteria_ids,
    by_tag_teaching_levels_ids,
    by_author_ids,
    by_tag_paper_types_ids,
    include_archived,
    only_archived,
    hide_pamoja_quizzes
  } = defaultValues

  const [currentAuthorIds, setCurrentAuthorIds] = useState(by_author_ids)

  let children = currentUser.children
  let filtersConfig = {
    showModal: checkMob() && currentUser.role !== "student",
    triggerOnChange: !checkMob(),
  };

  // Doing this to rerender author filter
  useEffect(() => {
    setCurrentAuthorIds(by_author_ids)
  }, [props.by_author_ids, localFilters?.by_author_ids]) // Checking inside `localFilters` for mobile case 

  const applyLocalFilters = () => {
    props.setParams(localFilters);
  };

  const setParamsLocal = (params) => {
    if (filtersConfig.triggerOnChange) {
      props.setParams(params);
    } else {
      const merged = { ...localFilters, ...params };
      setLocalFilters(merged);
    }
  };
  
  // const SelectCriteria = MrSelect({ // add after 
  //   actions: genericActions,
  //   // resourceName: "criteria",
  //   // apiResourceName: "criteria",
  //   // resourceName: "questionbanks",
  //   config: {
  //     // url: "/criteria",
  //     // params: { key: "value" },
  //     processData: (data, props) => {
  //       console.log("data rubric type====>",data);
  //       return data.map((item) => {
  //         return { label: item.title, value: item.id };
  //       });
  //     },
  //     widgetConfig: {
  //       filterOption: true,
  //       // options: [
  //       //   {label: "Comments only", value: "comments only"},
  //       //   {label: "Achievement Level", value: "achievement Level"},
  //       //   {label: "Criteria with points", value: "criteria with points"},
  //       // ],
  //       showSearch: false, // if false, this will either load entire data from backend on widget load or onSearch
  //       // TBD: If false, need to be able to search. Also if can do regular select from redux with this component, then can use same everywhere.
  //       placeholder: "Select criteria",
  //       allowClear: true,
  //       mode: "multiple",
  //       style: { width: "100%" },
  //     },
  //   },
  // });
  

  const getValueFromParams = (
    filterName,
    filterType = "string",
    defaultValue,
    valueToCompare = true
  ) => {
    // filterType = boolean, integer, string, array
    // valueToCompare = true/false in case boolean

    console.log("getValueFromParams", filterName, props[filterName]);
    let filterValue = defaultValues[filterName]
    // let filterValue = defaultValue;
    if (filterType === "boolean") {
      if (
        props[filterName] === valueToCompare ||
        props[filterName] === valueToCompare.toString()
      ) {
        filterValue = valueToCompare;
      }
      // if(props.by_original == false || props.by_original == "false"){
      //   by_original = false
      // }
    } else if (filterType === "integer") {
      if (props[filterName]) {
        filterValue = parseInt(props[filterName]);
      }
    } else if (filterType === "array_integer") {
      if (props[filterName]) {
        if (Array.isArray(props[filterName])){
          filterValue = props[filterName].map((value) => parseInt(value));
        }
      }
    } else if (filterType === "array") {
      // array of strings
      if(props[filterName]) {
        filterValue = props[filterName];
      }
    } else {
      // string
      if(props[filterName]) {
        filterValue = props[filterName];
      }
    }

    // return filtersConfig.triggerOnChange ? filterValue : localFilters[filterName] || props[filterName] || filterValue // as per nitin
    // filtersConfig.showModal ? !isEmpty(localFilters) ? localFilters.by_questionbank_id : by_questionbank_id : by_questionbank_id // also doing this?
    if (!filtersConfig.triggerOnChange && localFilters?.[filterName]) {
      filterValue = localFilters[filterName];
    }
    return filterValue;
  };

  by_subject_id = getValueFromParams("by_subject_id", "integer", by_subject_id);
  by_grade_id = getValueFromParams("by_grade_id", "integer", by_grade_id);
  by_subject_ids = getValueFromParams("by_subject_ids", "array_integer", by_subject_ids);
  by_grade_ids = getValueFromParams("by_grade_ids", "array_integer", by_grade_ids);
  by_org_programme_id = getValueFromParams(
    "by_org_programme_id",
    "integer",
    by_org_programme_id
  );
  by_student_status = getValueFromParams(
    "by_student_status",
    "string",
    by_student_status
  );
  by_access_code = getValueFromParams(
    "by_access_code",
    "string",
    by_access_code
  );
  if (props.by_date_range) {
    by_date_range = props.by_date_range;
  }
  if (props.by_start_at_date_range) {
    by_start_at_date_range = props.by_start_at_date_range;
  }
  if (props.by_duration) {
    by_duration = props.by_duration;
  }
  by_orgs = getValueFromParams("by_orgs", "boolean", by_orgs, props.by_orgs);
  by_school = getValueFromParams(
    "by_school",
    "boolean",
    by_school,
    props.by_school
  );
  // by_starting = getValueFromParams("by_starting", "string", by_starting);
  by_questionbank_id = getValueFromParams(
    "by_questionbank_id",
    "integer",
    by_questionbank_id
  );
  by_programme_grade_ids = getValueFromParams(
    "by_programme_grade_ids",
    "array_integer",
    by_programme_grade_ids
  );
  by_programme_subject_ids = getValueFromParams(
    "by_programme_subject_ids",
    "array_integer",
    by_programme_subject_ids
  );
  by_pamoja_course_id = getValueFromParams(
    "by_pamoja_course_id",
    "integer",
    by_pamoja_course_id
  );
  by_org_id = getValueFromParams("by_org_id", "integer", by_org_id);
  by_status = getValueFromParams("by_status", "array", by_status);
  by_criteria_ids = getValueFromParams("by_criteria_ids", "string", by_criteria_ids)
  by_tag_teaching_levels_ids = getValueFromParams("by_tag_teaching_levels_ids", "array_integer", by_tag_teaching_levels_ids)
  by_original = getValueFromParams(
    "by_original",
    "boolean",
    by_original,
    props.by_original
  );
  by_tag_paper_types_ids = getValueFromParams("by_tag_paper_types_ids", "array_integer", by_tag_paper_types_ids)
  hide_pamoja_quizzes = getValueFromParams(
    "hide_pamoja_quizzes",
    "boolean",
    hide_pamoja_quizzes,
    false
  );
  include_archived = getValueFromParams(
    "include_archived",
    "boolean",
    include_archived,
    props.include_archived
  );
  only_archived = getValueFromParams(
    "only_archived",
    "boolean",
    only_archived,
    props.only_archived
  );

  by_author_ids = getValueFromParams("by_author_ids", "array_integer", by_author_ids);

  if (currentUser.role === "support") {
    by_orgs = true; // defauslt
    by_original = false; // default
    include_archived = true; // default
  }

  let qbMode = false;
  // showQBSelect = resourceName == "libraryexperiences" && ((currentUser.role == "superadmin" || currentUser.role == "qb_author") || ((currentUser.role == "admin" || currentUser.role == "teacher") && currentUser.org.org_questionbanks && currentUser.org.org_questionbanks.length != 0))
  // qbMode = resourceName == "libraryexperiences" && currentUser.role == "qb_author"
  qbMode =
    resourceName === "libraryexperiences" &&
    currentUser.org.org_questionbanks &&
    currentUser.org.org_questionbanks.length !== 0 &&
    currentUser.role !== "support" &&
    !isStudentOrParent;

  let selectedQB =
    orgQBs && orgQBs.find((qb) => qb.questionbank_id === by_questionbank_id);
  let selectedOrgProgramme =
    orgProgrammes && orgProgrammes.find((p) => p.id === by_org_programme_id);

  const notAllowedOrgProgrammeIds = []
  if (!isEmpty(schoolLibraryProgrammePermissions)) {
    orgProgrammes.forEach(op => {
      if (schoolLibraryProgrammePermissions[op.code]?.list === false) {
        notAllowedOrgProgrammeIds.push(op.id)
      }
    })
  }
  
  const currentOrgProgramme = orgProgrammes.find(program => program.id === by_org_programme_id)
  const permittedGradeIds = schoolLibraryProgrammePermissions[currentOrgProgramme?.code]?.grade_ids || []
  const permittedSubjectIds = schoolLibraryProgrammePermissions[currentOrgProgramme?.code]?.subject_ids || []

  let isDPBankSelected =
    selectedQB && selectedQB.custom_fields.title.indexOf("DP") > -1
      ? true
      : false;
  console.log("isDPBankSelected", selectedQB, orgQBs);

  filtersConfig = {
    ...filtersConfig,
    showFiltersHeaderTitle: !isStudentOrParent,
    showSearchByOrgFilter:
      !isStudentOrParent &&
      resourceName === "libraryexperiences" &&
      currentUser &&
      (currentUser.role === "qb_author" ||
        currentUser.role === "superadmin" ||
        currentUser.role === "support") &&
      currentUser.custom_fields.can_view_all_experiences,
    showSelectOrgDropdownFilter: !isStudentOrParent && by_orgs,
    showSelectStartingFilter:
      !isStudentOrParent &&
      (resourceName === "todayexperiences" || currentUser.role === "support"),
    showSelectQuestionbankDropdownFilter:
    !isStudentOrParent && !by_orgs && qbMode,
    // !isStudentOrParent && !by_orgs && !by_school && qbMode,
    showSelectQuestionbankRadioFilter:
      !isStudentOrParent &&
      !by_orgs &&
      !by_school &&
      qbMode &&
      false,
    showSelectProgrammeGradeDropdownFilter:
      !isStudentOrParent &&
      by_questionbank_id &&
      (currentUser.role === "qb_author" || !isDPBankSelected)
        ? true
        : false,
    showSelectProgrammeGradeRadioFilter:
      !isStudentOrParent &&
      by_questionbank_id &&
      (currentUser.role === "qb_author" || !isDPBankSelected) &&
      false
        ? true
        : false,
    showSelectProgrammeSubjectDropdownFilter:
      !isStudentOrParent && by_questionbank_id ? true : false,
    showSelectProgrammeSubjectRadioFilter:
      !isStudentOrParent && by_questionbank_id && false
        ? true
        : false,
    showSelectPamojaCourseIdFilter:
      currentUser.role === "qb_author" && by_questionbank_id ? true : false,
    showSelectOrgProgrammeDropdownFilter:
      !isStudentOrParent &&
      (by_org_id ||
        (!by_questionbank_id &&
          currentUser.role !== "superadmin" &&
          currentUser.role !== "qb_author" &&
          currentUser.role !== "support")),
    showSelectOrgSubjectDropdownFilter: by_org_programme_id ? true : false, 
    showSelectOrgGradeDropdownFilter:
      !isStudentOrParent && by_org_programme_id ? true : false,
    showDateRangeFilter:
      !checkMob() &&
      !isStudentOrParent &&
      (resourceName === "libraryexperiences" || resourceName === "experiences") &&
      !by_questionbank_id
        ? true
        : false,
    showSelectStatusDropdownFilter:
      !isStudentOrParent && currentUser.role !== "parent" && (resourceName === "experiences" || (resourceName === "libraryexperiences" && ((currentUser.role === "admin" && !by_questionbank_id) || currentUser.role === "qb_author"))),
    showSelectStatusRadioFilter: 
      !isStudentOrParent && resourceName === "libraryexperiences" && (by_school || currentUser.role === "qb_author") && false,
    showSelectDeliveryModeFilter:
      currentUser.role !== "qb_author" && !isStudentOrParent && !selectedQB,
    showSelectEvaluationTypeFilter:
    resourceName !== "todayexperiences" && (enabledFeatures.allow_select_evaluation_type || import.meta.env.VITE_MODE !== "production") &&
      !isStudentOrParent,
    // showSelectEvaluationTypeLibraryFilter:
    //   resourceName === "libraryexperiences",
    showSelectPaperTypeFilter: enabledFeatures.allow_elastic_search && by_questionbank_id && by_programme_subject_ids,
    showSelectByOriginalFilter:
      !isStudentOrParent &&
      resourceName === "libraryexperiences" &&
      (currentUser.role === "qb_author" || !by_questionbank_id) &&
      false, // decided to not show anymore - should see all assessments
    showShowPamojaQuizzesFilter:
      currentUser.role === "support" &&
      resourceName === "libraryexperiences" &&
      by_orgs === true,
    showShowArchivedFilter:
      !isStudentOrParent &&
      resourceName !== "todayexperiences" &&
      (currentUser.role === "qb_author" || !by_questionbank_id),
    showResetFilter:
      !isStudentOrParent &&
      resourceName !== "todayexperiences" &&
      !filtersConfig.showModal,
    showStudentStatusFilter: currentUser.role === "student",
    showSearchByAccessCode:
      (!checkIfSEBHasDynamicConfig(appType)) && 
      currentUser.role === "student" && (!by_student_status || 
        by_student_status === "upcoming"),
    showSelectChildFilter: currentUser.role === "parent" && children.length > 0,
    showSelectRubricTypeFilter:
    resourceName !== "todayexperiences" && (currentUser.role === "admin" || currentUser.role === "teacher" || currentUser.role === "qb_author"),
    showSelectDurationTypeFiler:
      resourceName === "libraryexperiences",
    showSelectDifficultyFilter:
      resourceName === "libraryexperiences" && by_questionbank_id && selectedQB && (selectedQB.qb_code === "ib_dp" || selectedQB.qb_code === "osc_dp" || selectedQB.qb_code === "osc_dp_mocks") && false,
    showSelectAuthorFilter: resourceName === "libraryexperiences" && !selectedQB,
    showMeCheckBox: import.meta.env.VITE_MODE !== "production",
    showSelectTeachingLevelFilter:
     selectedQB,
    // showSelectCriteriaFilter:
    //   by_questionbank_id
    //   && (selectedQB.qb_code === "ap_myp"),
  };

  if(filtersConfig.showFiltersHeaderTitle){
    extraFilters.push(<Space style={{justifyContent: "space-between", width: "100%", marginBottom: "15px"}}>
      {/* <div>Filters</div>  */}
      {/* <Button icon={<FilterOutlined />}></Button> */}
      {filtersConfig.showResetFilter && <div className="text-right"><ResetFilter label={mrIntl("CommonText.reset")} setParams={props.setParams} defaultValues={defaultValues} useDefaultValues={true} /></div>}
    </Space>)
  }

  if (filtersConfig.showSelectChildFilter) {
    if (props.by_child_id) {
      by_child_id = parseInt(props.by_child_id);
      dispatch(userActions.setActiveChildIdSuccess({ childId: by_child_id }));
    } else if (activeChildId) {
      // TODO: get from selector - maybe already set in redux
      by_child_id = activeChildId;
      props.setParams({ by_child_id: by_child_id });
    } else {
      by_child_id = children[0].id; // default first - also on BE
      props.setParams({ by_child_id: by_child_id });
      dispatch(userActions.setActiveChildIdSuccess({ childId: by_child_id }));
    }

    extraFilters.push(
      <SelectChild
        key="selectChildExperiencesFilter"
        value={by_child_id}
        onChange={(value) => {
          props.setParams({ by_child_id: value });
          dispatch(userActions.setActiveChildIdSuccess({ childId: value }));
        }}
        config={{ forceReload: true, widgetConfig: { options: children } }}
      />
    );
  }

  if (filtersConfig.showStudentStatusFilter) {
    console.log("show student filter");
    extraFilters.push(
      <Radio.Group
        key="student-filter-upcoming"
        value={by_student_status}
        buttonStyle="solid"
        onChange={(e) => {
          console.log("value student status", e);
          props.setParams({ by_student_status: e.target.value });
        }}
      >
        {/* TEMP */}
        {/* <Radio.Button value="upcoming">Upcoming</Radio.Button> */}
        {/* <Radio.Button value="in_progress">In Progress</Radio.Button> */}
        {/* <Radio.Button value="submitted">Submitted</Radio.Button> */}
        <Radio.Button value="upcoming"><MrTranslate id={"ExperiencesFilter.upcoming"}/></Radio.Button>
        {appType === "web" && <Radio.Button value="submitted"><MrTranslate id={"ExperiencesFilter.submitted"}/></Radio.Button>}
        {appType === "web" && <Radio.Button value="all"><MrTranslate id={"ExperiencesFilter.all"}/></Radio.Button>}
      </Radio.Group>
      // <Button key="student-filter-upcoming" value={by_student_status} onChange={(value) => {props.setParams({by_student_status: "upcoming"})}}>Upcoming</Button>
    );
  }

  if (filtersConfig.showSearchByAccessCode) {
    extraFilters.push(
      <Search
        key="join-code-search"
        placeholder={mrIntl("ExperiencesFilter.or_enter_join_code")}
        // placeholder="or Enter Join Code"
        allowClear
        enterButton={mrIntl("ExperiencesFilter.join")}
        // enterButton={true}
        // size="large"
        // value={by_access_code}
        onSearch={(v) =>
          props.setParams({
            by_access_code: v,
            search: null,
            by_student_status: null,
          })
        }
      />
      // <Input type="text" value={by_access_code} onChange={(e) => props.setParams({by_access_code: e.target.value, search: null})} placeholder="Enter Join Code"/>
      // <Checkbox key="checkboxAccessExperiencesFilter" checked={by_access_code} onChange={(e) => {
      //   console.log("archived filter value", e);
      //   props.setParams({by_access_code: (e.target.checked ? true : null)})}}>Search by Join Code</Checkbox>
    );
  }

  if (filtersConfig.showSearchByOrgFilter) {
    extraFilters.push(
      <Checkbox
        key="checkboxAllOrgsExperiencesFilter"
        checked={by_orgs}
        onChange={(e) => {
          console.log("original filter value", e);
          // props.setParams({
          //   by_orgs: e.target.checked,
          //   by_programme_grade_ids: null,
          //   by_org_id: null,
          //   by_programme_subject_id: null,
          //   by_grade_id: null,
          //   by_subject_id: null,
          //   by_org_programme_id: null,
          //   by_questionbank_id: null,
          // });
          setParamsLocal({
            by_orgs: e.target.checked,
            by_programme_grade_ids: defaultValues.by_programme_grade_ids,
            by_org_id: defaultValues.by_org_id,
            by_programme_subject_ids: defaultValues.by_programme_subject_ids,
            by_grade_id: defaultValues.by_grade_id,
            by_subject_id: defaultValues.by_subject_id,
            by_org_programme_id: defaultValues.by_org_programme_id,
            by_questionbank_id: defaultValues.by_questionbank_id,
          });
        }}
      >
        {mrIntl("ExperiencesFilter.search_by_org")}
      </Checkbox>
    );
  }

  if (filtersConfig.showSelectOrgDropdownFilter) {
    extraFilters.push(
      <SelectOrgDropdown
        key="selectOrgsFilter"
        value={by_org_id}
        onChange={(value) => {
          // props.setParams({
          //   by_org_id: value,
          //   by_org_programme_id: null,
          //   by_grade_id: null,
          //   by_subject_id: null,
          // });
          setParamsLocal({
            by_org_id: value,
            by_org_programme_id: defaultValues.by_org_programme_id,
            by_grade_id: defaultValues.by_grade_id,
            by_subject_id: defaultValues.by_subject_id,
          });
        }}
        config={{
          widgetConfig: {
            style: { width: filtersConfig.showModal ? "100%" : "150px" },
            // allowClear: !filtersConfig.showModal,
          },
        }}
      />
    );
  }

  // if (filtersConfig.showSelectStartingFilter) {
  //   extraFilters.push(
  //     <SelectStarting
  //       key="selectStartingExperiencesFilter"
  //       value={by_starting}
  //       onChange={(value) => {
  //         console.log("starting value==>", value);
  //         if (value) {
  //           setParamsLocal({ by_starting: value });
  //         } else {
  //           setParamsLocal({ by_starting: "today" });
  //         }
  //         // props.setParams({ by_starting: value });
  //       }}
  //     />
  //   );
  // }
  if (filtersConfig.showSelectStartingFilter) {
    console.log("by_start_at_date_range", by_start_at_date_range);
    // if(by_start_at_date_range){
    let by_start_at_date_range_formatted = [];
    if (by_start_at_date_range && by_start_at_date_range.length > 0) {
      by_start_at_date_range_formatted = by_start_at_date_range.map((date) => dayjs(date));
    } else{
      by_start_at_date_range_formatted = [dayjs(), dayjs()]
    }
    console.log("by_start_at_date_range_formatted", by_start_at_date_range_formatted);
    extraFilters.push(<div className="filter-title">{mrIntl("ExperiencesFilter.scheduled_date")}</div>)
    extraFilters.push(
      <RangePicker
        placeholder={[mrIntl("SelectSubject.scheduled_time"), mrIntl("SelectSubject.scheduled_time")]}
        value={by_start_at_date_range_formatted}
        className="date-picker"
        allowClear={false}
        // defaultValue={[dayjs(), dayjs()]}
        // format={"YYYY-MM-DD h:mm a"}
        // showTime={{
        //   use12Hours: true,
        //   format: "h:mm a",
        //   minuteStep: 15,
        // }}
        presets={[
          { label: mrIntl("SelectSubject.today"), value: [getUTCDate({type: "startOf"}), getUTCDate({type: "endOf"})] },
          { label: mrIntl("SelectSubject.this_week"), value: [getUTCDate({type: "startOf"}), getUTCDate({type: "endOf", daysDiff: 7})] },
          { label: mrIntl("SelectSubject.next_30_days"), value: [getUTCDate({type: "startOf"}), getUTCDate({type: "endOf", daysDiff: 30})] },
          { label: mrIntl("SelectSubject.last_week"), value: [getUTCDate({type: "startOf", daysDiff: -7}), getUTCDate({type: "endOf"})] },
          { label: mrIntl("SelectSubject.last_30_days"), value: [getUTCDate({type: "startOf", daysDiff: -30}), getUTCDate({type: "endOf"})] },
          // { label: 'Last Month', value: dayjs().add(-1, 'month') },
        ]}
        onChange={(value, dateString) => {
          console.log("dateString ==>", value, dateString)
          const dateRange = value ? value : [getUTCDate({type: "startOf"}), getUTCDate({type: "endOf"})]
          setParamsLocal({
            // IMP: datestring doesn't send as UTC. value does - so using value to set. Also value can be nil in case of allowClear true
            // by_start_at_date_range: e ? dateString : [dayjs().format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')],
            by_start_at_date_range: dateRange.map((d) => d.toISOString()),
          });
        }}
      />
    );
    // }
  }

  if (filtersConfig.showSelectQuestionbankDropdownFilter) {
    extraFilters.push(<div className="filter-title">{mrIntl("CommonText.assessment_bank")}</div>)
    console.log("by_questionbank_id ====>", by_questionbank_id);
    extraFilters.push(
      <SelectOrgQuestionbank
        dropdownMatchSelectWidth={false}   // setParamsLocal({
          //   by_questionbank_id: value,
          //   by_programme_grade_ids: null,
          //   by_org_id: null,
          //   by_programme_subject_id: null,
          //   by_grade_id: null,
          //   by_subject_id: null,
          //   by_org_programme_id: null,
          // });
        key="selectQuestionbankSegmentsFilter"
        value={by_questionbank_id}
        onChange={(value) => {
          // props.setParams({
          //   by_questionbank_id: value,
          //   by_programme_grade_ids: null,
          //   by_org_id: null,
          //   by_programme_subject_id: null,
          //   by_grade_id: null,
          //   by_subject_id: null,
          //   by_org_programme_id: null,
          // });
          setParamsLocal({
            by_questionbank_id: value,
            by_programme_grade_ids: defaultValues.by_programme_grade_ids,
            by_org_id: defaultValues.by_org_id,
            by_programme_subject_ids: defaultValues.by_programme_subject_ids,
            by_grade_id: defaultValues.by_grade_id,
            by_subject_id: defaultValues.by_subject_id,
            by_org_programme_id: defaultValues.by_org_programme_id,
          });
        }}
        config={{
          params: {
            // with_resource_count: true,
            // with_resource_count_for_resource_type: "experience",
            without_qb_codes: currentUser.role !== 'qb_author' ? ["osc_dp_mocks", "osc_dp"] : []
          },
          widgetConfig: { 
            style: {width: "100%"},
            placeholder: mrIntl("CommonText.select_questionbank"),
            showSchoolLibraryInOptions: currentUser.role == "qb_author" ? false : true,
            // mode: "multiple",
          }
        }}
      />
    );
  }

  if (filtersConfig.showSelectQuestionbankRadioFilter) {
    extraFilters.push(
      <SelectOrgQuestionbankRadio
        key="selectOrgQuestionbankRadioFilter"
        value={by_questionbank_id}
        onChange={(e) => {
          console.log("SelectOrgQuestionbankRadio onchange e", e);
          let value = e.target.value;
          setParamsLocal({
            by_questionbank_id: value,
            by_programme_grade_ids: defaultValues.by_programme_grade_ids,
            by_org_id: defaultValues.by_org_id,
            by_programme_subject_ids: defaultValues.by_programme_subject_ids,
            by_grade_id: defaultValues.by_grade_id,
            by_subject_id: defaultValues.by_subject_id,
            by_org_programme_id: defaultValues.by_org_programme_id,
          });
        }}
        config={{
          params: { unarchived: true, with_resource_count: true },
        }}
      />
    );
  }

  if (filtersConfig.showSelectProgrammeGradeDropdownFilter) {
    extraFilters.push(<div className="filter-title">{mrIntl("CommonText.grade")}</div>)
    extraFilters.push(
      <SelectProgrammeGrade
        key="selectGradeSegmentsFilter"
        value={by_programme_grade_ids}
        onChange={(value) =>
          // props.setParams({ by_programme_grade_ids: value })
          setParamsLocal({ by_programme_grade_ids: value })
        }
        config={{
          forceReload: true,
          params: { by_questionbank_id: by_questionbank_id },
          widgetConfig: {
            // enabledFeatures: enabledFeatures,
            style: {width: "100%"},
            placeholder: mrIntl("CommonText.all_grades"),
            mode: "multiple",
          },
        }}
      />
    );
  }

  if (filtersConfig.showSelectProgrammeGradeRadioFilter) {
    extraFilters.push(
      <SelectProgrammeGradeRadio
        key="selectProgrammeGradeRadioFilter"
        value={by_programme_grade_ids}
        onChange={(e) => {
          console.log("SelectProgrammeGradeRadio onchange e", e);
          let value = e.target.value;
          setParamsLocal({ by_programme_grade_ids: value });
        }}
        config={{
          params: { by_questionbank_id: by_questionbank_id, unarchived: true },
        }}
      />
    );
  }

  if (filtersConfig.showSelectProgrammeSubjectDropdownFilter) {
    extraFilters.push(<div className="filter-title">{mrIntl("CommonText.subject")}</div>)
    extraFilters.push(
      <SelectProgrammeSubject
        dropdownMatchSelectWidth={false}
        key="selectProgrammeSubjectExperiencesFilter"
        value={by_programme_subject_ids}
        onChange={(value) =>{
          // props.setParams({ by_programme_subject_id: value })
          console.log("value ====>", value)
          setParamsLocal({ by_programme_subject_ids: value })
        }}
        config={{
          forceReload: true,
          params: {
            by_questionbank_id: by_questionbank_id,
            by_type_c: "group",
          },
          widgetConfig: {
            // enabledFeatures: enabledFeatures,
            style: {width: "100%"},
            placeholder: mrIntl("ExperiencesFilter.all_subjects"),
            showQPSResourceCounts: true,
            mode: "multiple",
            onlyShowSubjectsWithPublishedAssessments: currentUser.role !== "qb_author" 
          },
        }}
      />
    );
  }

  if (filtersConfig.showSelectProgrammeSubjectRadioFilter) {
    extraFilters.push(
      <SelectProgrammeSubjectRadio
        key="SelectProgrammeSubjectRadioFilter"
        value={by_programme_subject_ids}
        onChange={(e) => {
          console.log("SelectProgrammeSubjectRadio onchange e", e);
          let value = e.target.value;
          setParamsLocal({ by_programme_subject_ids: value });
        }}
        config={{
          nested: true,
          params: {
            by_questionbank_id: by_questionbank_id,
            by_type_c: "group",
            unarchived: true,
          },
          // TODO add param to return subjects with > 1 resources (experiences/segments) or with_resource_count
        }}
      />
    );
  }

  if (filtersConfig.showSelectPamojaCourseIdFilter) {
    let selectedQB =
      orgQBs && orgQBs.find((qb) => qb.questionbank_id === by_questionbank_id);
    console.log("selectedQB", orgQBs, selectedQB);
    if (selectedQB && selectedQB.custom_fields.title.indexOf("Pamoja") > -1) {
      extraFilters.push(
        <SelectCourseId
          key="selectCourseIdSegmentsFilter"
          value={by_pamoja_course_id}
          onChange={
            (value) => setParamsLocal({ by_pamoja_course_id: value })
            // props.setParams({ by_pamoja_course_id: value })
          }
          config={{
            widgetConfig: {
              style: { width: filtersConfig.showModal && "100%" },
            },
          }}
        />
      );
    }
  }
  if (filtersConfig.showSelectOrgProgrammeDropdownFilter) {
    extraFilters.push(<div className="filter-title">{mrIntl("ExperiencesFilter.program")}</div>)
    extraFilters.push(
      <SelectProgram
        key="selectOrgProgrammeFilter"
        dropdownMatchSelectWidth={false}
        value={by_org_programme_id}
        onChange={(value) => {
          console.log("by_org_id==>", by_org_id);
          setParamsLocal({
            by_org_programme_id: value,
            by_grade_id: defaultValues.by_grade_id,
            by_subject_id: defaultValues.by_subject_id,
            by_grade_ids: defaultValues.by_grade_ids,
            by_subject_ids: defaultValues.by_subject_ids,
          });
        }}
        config={{
          params: { by_org_id: by_org_id },
          widgetConfig: { 
            style: { width: "100%" },
            placeholder: mrIntl("ExperiencesFilter.all_programs"),
            notAllowedOrgProgrammeIds: isLibrary && notAllowedOrgProgrammeIds
          }
        }}
      />
    );
  }


  if (filtersConfig.showSelectOrgGradeDropdownFilter) {
    extraFilters.push(<div className="filter-title">{mrIntl("CommonText.grade")}</div>)
    extraFilters.push(
      <SelectOrgGrade
        key="selectGradeSegmentsFilter"
        // value={by_grade_id}
        value={by_grade_ids}
        onChange={(value) => {
          // props.setParams({ by_grade_id: value })
          // setParamsLocal({ by_grade_id: value });
          setParamsLocal({ by_grade_ids: value });
        }}
        config={{
          forceReload: true,
          params: { by_org_programme_id: by_org_programme_id },
          // widgetConfig: {allowClear: !filtersConfig.showModal}
          widgetConfig: { 
            style: {width: "100%"},
            placeholder: mrIntl("CommonText.all_grades"),
            mode: "multiple",
            allowedGradeIds: isLibrary && permittedGradeIds
          }
        }}
      />
    );
  }

  if (filtersConfig.showSelectOrgSubjectDropdownFilter) {
    extraFilters.push(<div className="filter-title">{mrIntl("CommonText.subject")}</div>)
    extraFilters.push(
      <SelectOrgSubject
        key="selectSubjectSegmentsFilter"
        dropdownMatchSelectWidth={false}
        // value={by_subject_id}
        value={by_subject_ids}
        onChange={(value) => {
          // props.setParams({ by_subject_id: value })
          // setParamsLocal({ by_subject_id: value });
          setParamsLocal({ by_subject_ids: value });
        }}
        config={{
          forceReload: true,
          params: {
            unarchived: true,
            by_org_programme_id: by_org_programme_id,
          },
          widgetConfig: {
            enabledFeatures: enabledFeatures,
            style: {width: "100%"},
            placeholder: mrIntl("ExperiencesFilter.all_subjects"),
            mode: "multiple",
            allowedSubjectIds: isLibrary && permittedSubjectIds
          },
        }}
      />
    );
  }

  if (filtersConfig.showSelectTeachingLevelFilter) {
    if (isDPBankSelected) {
      // if (props.by_tag_teaching_levels_ids) {
      //   by_tag_teaching_levels_ids = props.by_tag_teaching_levels_ids;
      // }
      extraFilters.push(<div className="filter-title">{mrIntl("CommonText.teaching_level")}</div>)
      extraFilters.push(
        <SelectTeachingLevel
          key="selectTeachingTypeFilter"
          value={by_tag_teaching_levels_ids}
          onChange={(value) =>{
            console.log("select teaching lebel value===>", value)
            props.setParams({ by_tag_teaching_levels_ids: value })
          }}
          config={{
            widgetConfig: { 
              hideSlHl: true,
              style: { width: "100%" },
              mode: "multiple",
              placeholder: mrIntl("CommonText.all_levels"),
            }
          }}
        />
      );
    }
  }
  

  // if (filtersConfig.showSelectCriteriaFilter) {
  //   console.log("by_criteria_ids===>", by_criteria_ids);
  //   extraFilters.push(
  //     <SelectCriteria
  //       key="selectCriteriaFilter"
  //       value={by_criteria_ids}
  //       onChange={(value) => props.setParams({ by_criteria_ids: [value] })}
  //       config={{
  //         widgetConfig: {
  //           // options: activeExperience.rubric.criteria
  //           // style: { width: "100%" },
  //         },
  //       }}
  //     />
  //   );
  // };
 

  if (filtersConfig.showDateRangeFilter) {
    console.log("by_date_range", by_date_range);
    // if(by_date_range){
    let by_date_range_selected = [];
    if (by_date_range && by_date_range.length > 0) {
      // by_date_range_selected = by_date_range.map((date) => moment.utc(date));
    by_date_range_selected = by_date_range.map((date) => dayjs(date));
    } else {
      // by_date_range_selected = [dayjs().add(-6, 'month'), dayjs()];
    }
    console.log("by_date_range_selected", by_date_range_selected);
    extraFilters.push(<div className="filter-title">{mrIntl("ExperiencesFilter.date")}</div>)
    extraFilters.push(
      <RangePicker
        placeholder={[mrIntl("ExperiencesFilter.start_date"), mrIntl("ExperiencesFilter.end_date")]}
        // defaultValue={by_date_range_selected}
        // defaultPickerValue={by_date_range_selected}
        value={by_date_range_selected}
        className="date-picker"
        presets={[
          { label: mrIntl("ExperiencesFilter.today"), value: [getUTCDate({type: "startOf"}), getUTCDate({type: "endOf"})] },
          { label: mrIntl("ExperiencesFilter.last_7_days"), value: [getUTCDate({type: "startOf", daysDiff: -7}), getUTCDate({type: "endOf"})] },
          { label: mrIntl("ExperiencesFilter.last_30_days"), value: [getUTCDate({type: "startOf", daysDiff: -30}), getUTCDate({type: "endOf"})] },
          { label: mrIntl("ExperiencesFilter.last_90_days"), value: [getUTCDate({type: "startOf", daysDiff: -90}), getUTCDate({type: "endOf"})] },
          { label: mrIntl("ExperiencesFilter.last_180_days"), value: [getUTCDate({type: "startOf", daysDiff: -180}), getUTCDate({type: "endOf"})] },
          { label: mrIntl("ExperiencesFilter.last_365_days_1_year"), value: [getUTCDate({type: "startOf", daysDiff: -365}), getUTCDate({type: "endOf"})] },
        ]}
        onChange={(value, dateString) => {          
          setParamsLocal({
            by_date_range: value ? value.map((d) => d.toISOString()) : null,
          });
        }}
      />
    );
    // }
  }

  if (filtersConfig.showSelectStatusDropdownFilter) {
    extraFilters.push(<div className="filter-title">{mrIntl("ExperiencesFilter.status")}</div>)
    extraFilters.push(
      <SelectStatus
        key="selectStatusExperiencesFilter"
        value={by_status}
        onChange={(value) => {
          setParamsLocal({ by_status: value });
        }}
        config={{
          widgetConfig: { 
            style: { width: "100%" },
            mode: "multiple",
            placeholder: mrIntl("ExperiencesFilter.all_statuses")
          }
        }}
      />
    );
  }

  const getRubricTypeOptions = () => {
    const rubricOptions = []
    if ((selectedOrgProgramme && selectedOrgProgramme.name === "IB Middle Years") || (selectedQB && selectedQB.custom_fields.title.includes("AP MYP"))) {
      rubricOptions.push({ title: "Achievement level", type_c: "myp_achievement_level" },
        { title: "Criteria with points", type_c: "criteria_with_points" })
    } else {
      rubricOptions.push({ title: "Points", type_c: "points" })
    }
    rubricOptions.push({ title: "Comments only", type_c: "comments_only" })
    return rubricOptions
  }
  if (filtersConfig.showSelectRubricTypeFilter) {
    by_rubric_types = getValueFromParams(
      "by_rubric_types",
      "array",
      by_rubric_types
    );
    extraFilters.push(<div className="filter-title">{mrIntl("ExperiencesFilter.rubric")}</div>)
    extraFilters.push(
      <SelectRubricType
        key="select-rubric-type-filter"
        value={by_rubric_types}
        onChange={(value) => {
          setParamsLocal({ by_rubric_types: value });
        }}
        config={{
          // params: { by_subject_id: by_subject_id },
          // params: { by_subject_id: by_subject_ids[0] },
          widgetConfig: {
            options: getRubricTypeOptions(),
            style: {width: "100%"},
            placeholder: mrIntl("ExperiencesFilter.all_rubrics"),
            mode: "multiple",
          },
        }}
      />
    );
  }
  
  // if (filtersConfig.showSelectDurationTypeFiler) { // need to add backend
  //   console.log('showSelectDurationTypeFiler==>', by_duration)
  //   by_duration = by_duration.length > 0 ? by_duration.join(" ") : by_duration
  //   extraFilters.push(
  //     <SelectDuration
  //       key="selectDurationFilter"
  //       value={by_duration}
  //       onChange={(value) => {
  //         // console.log('SelectDuration==>', vaule && value.split(" "))
  //         setParamsLocal({ by_duration: value ? value.split(" ") : null});
  //       }}
  //     />
  //   );
  // }

  // if (filtersConfig.showSelectStatusRadioFilter) {
  //   extraFilters.push(
  //     <GenericFilter
  //       label="Select Status"
  //       options={[
  //         { label: "Draft", value: "draft" },
  //         { label: "Published", value: "published" },
  //         { label: "Closed", value: "closed" },
  //       ]}
  //       value={by_status}
  //       keyToUpdate="by_status"
  //       onChange={setParamsLocal}
  //     />
  //   );
  // }

  // if (filtersConfig.showSelectDifficultyFilter) {
  //   extraFilters.push(
  //     <GenericFilter
  //       label="Select Difficulty level"
  //       options={[
  //         { label: "Accessible", value: "accessible" },
  //         { label: "Moderate", value: "moderate" },
  //         { label: "Hard", value: "hard" },
  //       ]}
  //       value={by_difficulty_level}
  //       keyToUpdate="by_difficulty_level"
  //       onChange={(value) => {
  //         setParamsLocal({ by_difficulty_level: value });
  //       }}
  //     />
  //   );
  // }

  if (filtersConfig.showSelectDeliveryModeFilter) {
    by_delivery_modes = getValueFromParams(
      "by_delivery_modes",
      "array",
      by_delivery_modes
    );
    const options = [
      { label: "Any browser", value: "online" },
      { label: "Any browser with security", value: "online_secure" },
      { label: "Lockdown app", value: "offline" },
    ]
    if (isPaperModeAllowed) {
      options.push({ label: "Paper", value: "paper" })
    }
    extraFilters.push(<div className="filter-title">{mrIntl("ExperiencesFilter.delivery_mode")}</div>)
    extraFilters.push(
      <SelectDeliveryMode
        key="select-delivery-mode-filter"
        value={by_delivery_modes}
        onChange={(value) => {
          setParamsLocal({ by_delivery_modes: value });
        }}
        config={{
          widgetConfig: { 
            style: { width: "100%" },
            mode: "multiple",
            placeholder: mrIntl("ExperiencesFilter.all_modes"),
            options,
          }
        }}
      />
    );
  }
  
  if (filtersConfig.showSelectEvaluationTypeFilter) {
    by_evaluation_types = getValueFromParams(
      "by_evaluation_types",
      "array",
      by_evaluation_types
    );
    extraFilters.push(<div className="filter-title">{mrIntl("ExperiencesFilter.assessment_type")}</div>)
    // Evaluation type
    extraFilters.push(
      <SelectEvaluationType
        key="select-evaluation-type-filter"
        value={by_evaluation_types}
        onChange={(value) => {
          setParamsLocal({ by_evaluation_types: value });
        }}
        config={{
          widgetConfig: { 
            style: { width: "100%" },
            mode: "multiple",
            placeholder: mrIntl("ExperiencesFilter.all_types"),
            options: [
              { label: mrIntl("CommonText.formative"), value: "formative" },
              { label: mrIntl("CommonText.summative"), value: "summative" },
            ]
          }
        }}
      />
    );
  }

  // if (filtersConfig.showSelectEvaluationTypeFilter) {
  //   by_evaluation_type = getValueFromParams(
  //     "by_evaluation_type",
  //     "string",
  //     by_evaluation_type
  //   );
  //   extraFilters.push(
  //     <GenericFilter
  //       label="Select Assessment Type"
  //       options={[
  //         { label: "Formative", value: "formative" },
  //         { label: "Summative", value: "summative" },
  //       ]}
  //       keyToUpdate="by_evaluation_type"
  //       value={by_evaluation_type}
  //       onChange={setParamsLocal}
  //     />
  //   );
  // }

  if (filtersConfig.showSelectPaperTypeFilter) {
    if (isDPBankSelected) {
      // Only for DP
      // if (props.by_tag_paper_types_ids) {
      //   by_tag_paper_types_ids = props.by_tag_paper_types_ids;
      // }
      extraFilters.push(<div className="filter-title">{mrIntl("CommonText.paper")}</div>)
      extraFilters.push(
        <SelectPaperType
          key="selectPaperTypeFilter"
          value={by_tag_paper_types_ids}
          onChange={(value) =>
            props.setParams({ by_tag_paper_types_ids: value })
          }
          config={{
            widgetConfig: { 
              style: { width: "100%" },
              mode: "multiple",
              placeholder: mrIntl("CommonText.all_papers"),
            }
          }}
        />
      );
    }
  }

  if (filtersConfig.showSelectAuthorFilter) {
    let index = by_author_ids.indexOf(currentUser.id)
    extraFilters.push(<div className="filter-title">{mrIntl("CommonText.authors")}</div>)
    if (filtersConfig.showMeCheckBox) {
    extraFilters.push(
      <Checkbox checked={index > -1 ? true : false} onChange={(e) => {
        console.log("author checkbox", e.target.checked, by_author_ids, currentUser.id, index);
        let updatedAuthorIds = [...by_author_ids]
        if(e.target.checked){
          updatedAuthorIds.push(currentUser.id)
          console.log("author checkbox after", updatedAuthorIds);
          return setParamsLocal({ by_author_ids: updatedAuthorIds })
        } else {
          if(index > -1){
            updatedAuthorIds.splice(index, 1)
            return setParamsLocal({ by_author_ids: updatedAuthorIds })  
          }
        }
        
        // return e.target.checked ? setParamsLocal({ by_author_ids: by_author_ids.push(currentUser.id) }) : setParamsLocal({ by_author_ids: by_author_ids.splice(0, 1) })
      }}> {mrIntl("CommonText.me")} </Checkbox>)
    }
    
    // First time author filter not fetch author thats why we rerender the author filter
    if(currentAuthorIds.length === by_author_ids.length) {
      extraFilters.push(
        <SelectTeachers
          key="select-author"
          value={by_author_ids}
          onChange={(value) => {
            setParamsLocal({ by_author_ids: value });
          }}
          config={{
            widgetConfig: { 
              style: { width: "100%" },
              placeholder: mrIntl("ExperiencesFilter.select"),
              allowClear: true,
              
            }
          }}
        />
      );
    }
  
  }

  if (filtersConfig.showSelectByOriginalFilter) {
    extraFilters.push(
      <Checkbox
        key="checkboxOriginalExperiencesFilter"
        checked={!by_original}
        onChange={(e) => {
          // props.setParams({ by_original: !e.target.checked });
          setParamsLocal({ by_original: !e.target.checked });
        }}
      >
        <MrTranslate id={"ExperiencesFilter.show_duplicates"}/>
      </Checkbox>
    );
  }

  if (filtersConfig.showShowPamojaQuizzesFilter) {
    extraFilters.push(
      <Checkbox
        key="checkboxHidePamojaQuizzesExperiencesFilter"
        checked={!hide_pamoja_quizzes}
        onChange={(e) => {
          // props.setParams({ by_original: !e.target.checked });
          setParamsLocal({ hide_pamoja_quizzes: !e.target.checked });
        }}
      >
        <MrTranslate id={"ExperiencesFilter.show_pamoja_quizzes"}/>
      </Checkbox>
    );
  }

  if (filtersConfig.showShowArchivedFilter) {
    // extraFilters.push(
    //   <Checkbox
    //     id="experiences-archived-filter"
    //     key="checkboxArchivedExperiencesFilter"
    //     checked={include_archived}
    //     onChange={(e) => {
    //       console.log("archived filter value", localFilters);
    //       // props.setParams({
    //       //   include_archived: e.target.checked ? true : null,
    //       // });
    //       setParamsLocal({
    //         include_archived: e.target.checked ? true : null,
    //       });
    //     }}
    //   >
    //     {/* Show archived */}
    //     <MrTranslate id={"ExperiencesFilter.show_archived"}/>
    //   </Checkbox>
    // );
    extraFilters.push(
      <Checkbox
        id="experiences-archived-filter"
        key="checkboxArchivedExperiencesFilter"
        checked={only_archived}
        onChange={(e) => {
          console.log("archived filter value", localFilters);
          // props.setParams({
          //   only_archived: e.target.checked ? true : null,
          // });
          setParamsLocal({
            only_archived: e.target.checked ? true : null,
          });
        }}
      >
        {/* Show archived */}
        <MrTranslate id={"ExperiencesFilter.show_archived"}/>
      </Checkbox>
    );
  }

  // if (filtersConfig.showResetFilter) {
  //   extraFilters.push(<ResetFilter setParams={props.setParams}  />);
  // }

  // const [visible, setVisible] = useState(false);
  // const toggleFilterDrawer = () => {
  //   setVisible(!visible);
  // };
  // const onClose = () => {
  //   setVisible(false);
  // };
  // let drawerFilters = <React.Fragment>
  //   {/* show filter here if value selected, otherwise in drawer only */}
  //   <Button icon={<FilterOutlined />} onClick={toggleFilterDrawer} >Filters</Button>
  //   {/* <Button icon={<FilterOutlined />} onClick={() => {console.log("show filters")}} >Filters</Button> */}
  //   <ResetFilter setParams={props.setParams} />
  //   <Drawer
  //     title="Filters"
  //     // placement="top"
  //     closable={true}
  //     onClose={onClose}
  //     open={visible}
  //     mask={false}
  //     width={"30%"}
  //     // height={"10%"}
  //     // getContainer={false}
  //     // getContainer={document.getElementsByTagName("main")}
  //     // getContainer={document.getElementsByClassName("experiences-list")}
  //     // style={{ position: 'absolute' }}
  //   >
  //     <Space direction="vertical" style={{"width":"100%"}}>
  //       {extraFilters}
  //     </Space>
  //   </Drawer>
  // </React.Fragment>


  let finalRender = [];
  if (filtersConfig.showModal) {
    finalRender = (
      <ModalFilters
        filters={extraFilters}
        applyLocalFilters={applyLocalFilters}
        setParams={props.setParams}
        setLocalFilters={setLocalFilters}
      />
    );
  } else {
    finalRender = extraFilters;
    // finalRender = <Space direction="vertical">{extraFilters}</Space>
    // finalRender = drawerFilters
  }

  return finalRender;
};

ExperiencesFilter.defaultProps = {};

export default ExperiencesFilter;
