
import { Button, Select, Skeleton, Space, Switch, Table, Tag, Tooltip } from "antd";
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as experienceUsersActions } from "/src/views/ExperienceUsers/redux";
import ExperienceUserOverallTitle from "./ExperienceUserOverallTitle";
import ExperienceUserOverallPoints from "./ExperienceUserOverallPoints";
import UserResponseCellDetail from "./UserResponseCellDetail";
import _, { debounce } from "lodash";
import { topicsSelector } from "/src/views/Segments/Topics/selector";
import { currentCriteriaIdSelector, showConvertedAchievementLevelSelector, studentNameFormatSelector } from "../selector";
import { currentUserSelector, enabledFeaturesSelector } from "/src/views/Auth/Login/selector";
import { segmentsSelector } from "/src/views/Segments/selector";
import { activeAdjustedExperienceSelector, gradeSubmissionsWithAISelector } from "/src/views/Experiences/selector";
import { actions as userResponseReduxActions } from "/src/views/UserResponses/redux";
import { actions as topicActions } from "/src/views/Segments/Topics/redux";
import { actions as segmentActions } from "/src/views/Segments/redux";
import ExperienceUserListItem from "./ExperienceUserListItem";
import ExperienceUserGradingProgressBar from "../ExperienceUserItemDetail/ExperienceUserGradingProgressBar";
import ViewUploadsModal from "/src/components/UI/ViewUploadsModal";
import { checkParentElementHasClassNames, displayPoints, experienceUsersSorter, getLabelFromIndexPath, getQuestionNumber } from "/src/lib/utils/helperMethods";
import { getRecursiveSegments, highLightActiveCell, removeHighLightActiveCellClass } from "/src/views/Experiences/ExperienceHelperMethods";
import { SwapOutlined } from "@ant-design/icons";
import ExperienceUserTotalPointsTitle from "./ExperienceUserTotalPointsTitle";
import ExperienceUserTotalPoints from "./ExperienceUsersTotalPoints";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";

const ExperienceUsersTable = (props) => {
  // ExperienceUsersTable
  console.log("ExperienceUsersTable  =====>", props);
  const { experienceUsers, gradingConfig, setGradingConfig, exportModalConfig, setExportModalConfig, updateResource } = props
  const mrIntl = useTranslate()
  // const sortDirections = ["ascend", "descend", "ascend"]
  const customLocaleTooltip = {
    triggerDesc: mrIntl("ExperienceUsersTable.click_to_sort_descending"),
    triggerAsc: mrIntl("ExperienceUsersTable.click_to_sort_ascending"),
    cancelSort: mrIntl("ExperienceUsersTable.click_to_cancel_sorting"),
  };
  const location = useLocation();
  const history = useHistory();
  const { url } = useRouteMatch();
  const dispatch = useDispatch()
  const studentNameFormat = useSelector(studentNameFormatSelector());
  const currentUser = useSelector(currentUserSelector());
  const topics = useSelector(topicsSelector());
  const segments = useSelector(segmentsSelector());
  const enabledFeatures = useSelector(enabledFeaturesSelector());
  const experience = useSelector(activeAdjustedExperienceSelector())
  const currentCriteriaId = useSelector(currentCriteriaIdSelector())
  const showConvertedAchievementLevel = useSelector(showConvertedAchievementLevelSelector())
  const gradeSubmissionsWithAI = useSelector(gradeSubmissionsWithAISelector());
  const [sortedColumn, setSortedColumn] = useState({
    segmentId: "",
    loading: false,
    sortOrder: ""
  })

  const isGradeAllWithAIInProgress = (gradeSubmissionsWithAI.status === "started" || gradeSubmissionsWithAI.status === "in_progress");
  const aiGradedExperienceUsersLength = Array.isArray(gradeSubmissionsWithAI.eu_ids) ? gradeSubmissionsWithAI.eu_ids.length : 0;

  const submissionsCount = experienceUsers.filter(eu => eu.dup_from_id == null).length

  const rubric = experience.rubric;
  const rubricType = rubric && rubric.type_c;
  const isSortingDisabled = rubricType === "comments_only"
  const experienceSettings = experience.settings || {};
  // let gradingEnabled = gradingConfig.gradingEnabled || false;
  // const isMYPAchievementLevel = rubricType == "myp_achievement_level" ? true : false;
  // const isStudent =
  //   currentUser.role == "student" || currentUser.role == "parent"
  //     ? true
  //     : false;

  const onRowEvents = (record, rowIndex) => {
    return {
      onClick: (event) => {
        console.log("event in onRowEvents", event, record);
        dispatch(
          experienceUsersActions.setActiveExperienceUserIdSuccess({
            id: record.id,
          })
        );
        // setParams({eu_id: record.id})
      }, // click row
      "row-id": record.id,
    };
  }
  const scroll = { x: 800, y: "70vh" }

  let firstQuestion = {}
  let firstTopic = topics[0] || {}
  // const firstQuestion = segments.find((segment) => (firstTopic.id == segment.parent_id) && segment.segment_type == "question") || {}

  // useEffect(() => {
  //   if (experienceUsers.length !== 0) {
  //     let experienceUserIdString = location.pathname.split("/grade/")[1];
  //     if (experienceUserIdString) {
  //       let experienceUserIdFromUrl = experienceUserIdString.split("/#")[0];
  //       if (experienceUserIdFromUrl) {
  //         // history.push(`${url}/${experienceUserIdFromUrl}`);
  //         // setSelectedExperienceUserId(experienceUserIdToSet); // url already set, just set in state
  //       }
  //     }
  //   }
  // }, [location.pathname, location.hash]);

  const isGradingWithAIInProgressForEU = (eu) => {
    
    const isEUUngraded = !gradeSubmissionsWithAI.eu_ids?.includes(eu.id);
    const isEUGradingStatusStartedOrInProgress = ["started", "in_progress"].includes(eu.custom_fields?.ai_grading_status?.status);

    return (isGradeAllWithAIInProgress && isEUUngraded) || isEUGradingStatusStartedOrInProgress
  }

  const setURLWithExperienceUserId = (eu_id) => {
    history.push(`${url}/${eu_id}${location.search}`);
  };

  const calculatePointsForSorting = (obj) => {
    const hasSCA =
      obj.submission_criterium_associations_attributes &&
      obj.submission_criterium_associations_attributes.length > 0;
    if (hasSCA) {
      const allNull = obj.submission_criterium_associations_attributes.every(
        (sca) => sca.points === null
      );
      if (allNull) {
        return -1;
      }
      return obj.submission_criterium_associations_attributes.reduce(
        (total, sca) => {
          const points =
            sca.points !== null && sca.points !== undefined
              ? parseInt(sca.points)
              : 0;
          return total + points;
        },
        0
      );
    }
    // returning -1 if obj.points are null to first sort ungraded, then 0 and then points greater than 0
    return obj.points === null ? -1 : obj.points;
  };

  const studentNameColumnHeader = (
    <span className="student-name-column-header-actions">
      <Space>
        {currentUser.role !== "student" ? (
            <>
              {mrIntl("ExperienceUsersTable.submissions")}
              <Tag>{submissionsCount}</Tag>
            </>
          ) : (
            mrIntl("ExperienceUsersTable.student_name")
        )}
      </Space>

      {/* {!gradingConfig.anonymousGradingEnabled && false &&
        <span>
          <Tooltip title={studentNameFormat === "last_name" ? "View by first name" : "View by last name"} placement="right">
            <Button
              size={"small"}
              type={"text"}
              className={`view-by-name-btn ${studentNameFormat === "last_name" ? "selected" : ""}`}
              icon={<SwapOutlined />}
              // disabled={gradingConfig.anonymousGradingEnabled}
              onClick={(event) => {
                dispatch(
                  experienceUsersActions.setStudentNameFormatSuccess({
                    student_name_format: studentNameFormat === "last_name" ? "first_name" : "last_name",
                  })
                );
                event.stopPropagation()
              }}
              // onMouseDown={(e) => {
              //   console.log("mouse event onMouseDown by", e);
              // }}
              // onMouseEnter={(e) => {
              //   console.log("mouse event onMouseEnter by", e);
              //   e.stopPropagation()
              // }}
              // onMouseOver={(e) => {
              //   console.log("mouse event onMouseOver by", e);
              //   e.stopPropagation()
              // }}
              
            />
          </Tooltip>
        </span>
      } */}
    </span>
  )

  // useEffect(() => {
  //   const nextCell = document.querySelector(`[cell-id="cell-0-0"]`);
  //   if(nextCell) {
  //     // nextCell.classList.add("selected-cell");
  //     const child = nextCell.childNodes[0]
  //     child.classList.add("selected-cell");
  //     nextCell.style.outline = "none"
  //     nextCell.focus();
  //   }
  // }, [])

  const handleKeyDown = (e, rowIndex) => {
    const { shiftKey, keyCode, code } = e;

    const isShiftArrowOrSpace = shiftKey && (keyCode >= 37 && keyCode <= 40 || code === 'Space');
    const currentCell = e.currentTarget;
    const nextCell = getNextCell(currentCell, rowIndex, keyCode);
    if (isShiftArrowOrSpace) {
      e.preventDefault();

      const cells = document.querySelectorAll(".selected-cell");
      cells.forEach(cell => cell.classList.remove("selected-cell"));

      if (code === 'Space') {
        e.target.click()
      } else if (nextCell) {
        const child = nextCell.childNodes[0]
        child.classList.add("selected-cell");
        // nextCell.classList.add("selected-cell");
        nextCell.style.outline = "none"
        nextCell.focus();
      }
    }
  };

  const getNextCell = (currentCell, rowIndex, keyCode) => {
    const columnIndex = currentCell.cellIndex;
    let nextRowIndex = rowIndex;
    let nextColumnIndex = columnIndex;

    switch (keyCode) {
      case 37: // left arrow
        if (nextColumnIndex !== 0) {
          nextColumnIndex -= 1;
        }
        break;
      case 38: // up arrow
        if (nextRowIndex !== 0) {
          nextRowIndex -= 1;
        }
        break;
      case 39: // right arrow
        if (nextColumnIndex !== finalGridColumns.length - 1) {
          nextColumnIndex += 1;
        }
        break;
      case 40: // down arrow
        if (nextRowIndex !== experienceUsers.length - 1) {
          nextRowIndex += 1;
        }
        break;
      default:
        return null
    }

    const nextCellId = `cell-${nextRowIndex}-${nextColumnIndex}`;
    return document.querySelector(`[cell-id="${nextCellId}"]`);
  };

  const colIndexMap = {}
  const getNextColIndex = (dataIndex) => {
    if (colIndexMap[dataIndex] === undefined) {
      colIndexMap[dataIndex] = finalGridColumns.findIndex(col => col.dataIndex === dataIndex)
    }
    return colIndexMap[dataIndex]
  }

  const getCellid = (rowIndex, dataIndex) => {
    return `cell-${rowIndex}-${getNextColIndex(dataIndex)}`
  }

  const experienceUserNameSorter = (a, b, sortOrder) => {
    return experienceUsersSorter(a, b, studentNameFormat, sortOrder)
  }

  const isCommentsOnly = rubricType == "comments_only" ? true : false;
  const showTotalPts = rubricType !== "myp_achievement_level" && rubricType !== "criteria_with_points";
  const showGradingProgressColumn = !isCommentsOnly
  const exp_cas = experience.criterium_associations || [];
  const withSCAPoints = rubricType === "criteria_with_points" || rubricType === "myp_achievement_level";
  let perQuestionWidth = null;
  if (segments && segments.length >= 13) {
    perQuestionWidth = withSCAPoints ? exp_cas.length * 50 : 150;
    if (perQuestionWidth < 80) {
      perQuestionWidth = 80
    }
  }

  const onExperienceUserNameCellClick = (e, record) => {
    // try to other way to find the first question of the first topic then uncomment the below code
    // const hasChildSegments = firstTopic && firstTopic.children_order && firstTopic.children_order.length > 0
    // const showSegmentId = currentUser.role === "student" ? hasChildSegments && firstTopic.children_order[0] : "all"

    const isClassExist = checkParentElementHasClassNames(e.target, ["student-name"])
    // need to check isClassExist. To stop onClick event. when the "reset submission" is disabled
    if (!isClassExist) {
      return false;
    }
    dispatch(experienceUsersActions.setGradingModalVisibleSuccess({ visible: true }));
    dispatch(topicActions.setActiveIdSuccess({ id: firstTopic.id }));
    dispatch(segmentActions.setActiveIdSuccess({ id: "all" }));
    dispatch(segmentActions.setActiveSegmentParentIdxsSuccess({ activeSegmentParentIdxs: [0] }))
    removeHighLightActiveCellClass() // Maybe move this to onRow
    setURLWithExperienceUserId(record.id);
  }

  const onOverallPointsCellClick = (record) => {
    dispatch(experienceUsersActions.setOverallGradingFormVisibleSuccess({ visible: true }));
    removeHighLightActiveCellClass()
  }

  const onGradingProgressCellClick = (record) => {
    console.log("firstQuestion in onCell click ==>")
    // setParams({eu_id: record.id, t_id: firstQuestion.parent_id, s_id: firstQuestion.id})
    
    dispatch(experienceUsersActions.setGradingModalVisibleSuccess({ visible: true }));
    dispatch(topicActions.setActiveIdSuccess({ id: firstTopic.id }));
    dispatch(segmentActions.setActiveIdSuccess({ id: firstTopic.id }));
    removeHighLightActiveCellClass()
    setURLWithExperienceUserId(record.id);
  }

  const onPointsCellClick = (record, segment) => {
    dispatch(
      experienceUsersActions.setGradingModalVisibleSuccess({
        visible: true,
      })
    );
    // dispatch(
    //   topicActions.setActiveIdSuccess({ id: segment.parent_id })
    // );
    dispatch(
      segmentActions.setActiveIdSuccess({ id: segment.id })
    );
    removeHighLightActiveCellClass()
    setURLWithExperienceUserId(record.id);
  }

  const onPointsHeaderCellClick = (column) => {
    console.log("onPointsHeaderCellClick =====>", column);
    const newSortOrder =
      sortedColumn.segmentId === column.segment_id &&
        sortedColumn.sortOrder === "asc"
        ? "desc"
        : "asc";
    setSortedColumn({
      segmentId: column.segment_id,
      sortOrder: newSortOrder,
      loading: true,
    });
    const requestedExperienceUserIds = experienceUsers.map((eu) => eu.id)
    dispatch(
      userResponseReduxActions.fetchMissing(
        {
          segmentId: column.segment_id,
          requestedExperienceUserIds: requestedExperienceUserIds,
        },
        {
          fromRedux: true,
          successCallback: (userResponses) => {
            console.log("User Responses ==>", userResponses)
            const finalExperienceUsers = [...experienceUsers];
            const sortedExperienceUsersByNameIds = finalExperienceUsers.sort(
              (a, b) =>
                a.name - b.name
            ).map((eu) => eu.id);

            const finalUserResponses = [...userResponses];
            const sortedExperienceUserIds = finalUserResponses
              .sort((a, b) => {
                const aPoints = calculatePointsForSorting(a);
                const bPoints = calculatePointsForSorting(b);

                const aNamePriority = sortedExperienceUsersByNameIds.indexOf(a.experience_user_id)
                const bNamePriority = sortedExperienceUsersByNameIds.indexOf(b.experience_user_id)
                return (sortedColumn.segmentId ===
                  column.segment_id &&
                  sortedColumn.sortOrder === "asc"
                  ? bPoints - aPoints
                  : aPoints - bPoints) || (aNamePriority - bNamePriority);
              }).map((ur) => ur.experience_user_id);
            // const finalExperienceUsers = [...experienceUsers];
            // const sortedExperienceUsers = finalExperienceUsers.sort(
            //   (a, b) =>
            //     sortedExperienceUserIds.indexOf(a.id) -
            //     sortedExperienceUserIds.indexOf(b.id)
            // );
            // dispatch(
            //   experienceUsersActions.fetchSuccess({
            //     data: {
            //       experience_users: sortedExperienceUsers,
            //     },
            //   })
            // );
            dispatch(experienceUsersActions.sortByIdsOrderSuccess({ sortedExperienceUserIds }))
            setSortedColumn((prevState) => ({
              ...prevState,
              loading: false,
            }));
            handleScroll();
          },
        }
      )
    )
  }

  const getGridColumns = () => {
    console.log("getGridColumns ==========>");
    const gridColumns = [];
    const studentNameCol = {
      title: studentNameColumnHeader,
      dataIndex: "name",
      key: "name",
      width: 250,
      fixed: "left",
      style: { cursor: "pointer" },
      className: "exp-users-full-height-column student-name",
      sorter: experienceUserNameSorter,
      sortDirections: ['ascend', 'descend', 'ascend'],
      defaultSortOrder: "ascend",
      // ellipsis: {
      //   showTitle: true,
      // },
      // sortDirections: ["ascend", "descend"],
      // sorter: (a, b) => a > b ? 1 : -1,
      // sorter: true,
      // sortOrder: ["ascend", "descend"],
      // defaultSortOrder: "ascend",
      render: (_, item, rowIndex) => (
        <ExperienceUserListItem
          studentNameFormat={studentNameFormat}
          rubricType={rubricType}
          item={item}
          gradingConfig={gradingConfig}
          setGradingConfig={setGradingConfig}
          exportModalConfig={exportModalConfig}
          setExportModalConfig={setExportModalConfig}
          updateResource={updateResource}
        />
      ),
      onCell: (record, rowIndex) => {
        console.log("virtual list onCell student name click", record, rowIndex);
        return {
          onKeyDown: (e) => handleKeyDown(e, rowIndex),
          'cell-id': getCellid(rowIndex, "name"),
          tabIndex: 0,
          onClick: (e) => onExperienceUserNameCellClick(e, record),
        }
      },
      // sorter: (a, b) => a.full_name.localeCompare(b.full_name),
      // sortDirections: ['ascend', 'descend'],
      // defaultSortOrder: 'ascend'
    }
    gridColumns.push(studentNameCol)

    if (experienceSettings.peer_review_enabled && currentUser.role !== "student") {
      if (gradingConfig.assignReviewersEnabled) {
        const generateReviewerOptions = (item) => {
        // This function, generateReviewerOptions, is used to generate the options for the Select component in the "Assign Reviewers" column.
          const options = [];
          const existingUserIds = {};
          experienceUsers.forEach(eu => {
            if (eu.user_id !== item.user_id && !existingUserIds[eu.user_id]) {
              const studentName = gradingConfig.anonymousGradingEnabled ? eu.custom_fields?.anonymous_name : eu.name;
              const label = studentName + (eu.is_guest_submission ? " " + mrIntl("ExperienceUserListItem.guest") : "");
              options.push({ 
                label: label,
                value: eu.user_id 
              });
              existingUserIds[eu.user_id] = true;
            }
          });
          return options;
        };
        gridColumns.push({
          title: mrIntl("AssignReviewerButton.assign_submission_to"),
          dataIndex: "assign_reviewers",
          key: "assign_reviewers",
          width: 200,
          fixed: "left",
          align: "center",
          className: "exp-users-full-height-column",
          render: (_, item, rowIndex) => (
            <Select 
              mode="multiple"
              allowClear
              style={{
                width: '100%',
              }}
              placeholder="Please select reviewers"
              value={item.custom_fields["reviewer_user_ids"]}
              onChange={(value) => {
                dispatch(experienceUsersActions.updateSuccess({
                  data: {
                    experience_user: {
                      ...item,
                      custom_fields: {
                        ...item.custom_fields,
                        reviewer_user_ids: value
                      }
                    }  
                  }
                }, {
                  success: {
                    showMessage: false,
                  }
                }))
              }}
              options={generateReviewerOptions(item)}
            />
          ),
        })

      } else {
        const studentNameCol = {
          title: mrIntl("ExperienceUsersTable.review_by"),
          dataIndex: "assigned_reviewer",
          key: "assigned_reviewer",
          width: 200,
          fixed: "left",
          style: { cursor: "pointer" },
          className: "exp-users-full-height-column student-name",
          sorter: experienceUserNameSorter,
          sortDirections: ['ascend', 'descend', 'ascend'],
          defaultSortOrder: "ascend",
          // ellipsis: {
          //   showTitle: true,
          // },
          // sortDirections: ["ascend", "descend"],
          // sorter: (a, b) => a > b ? 1 : -1,
          // sorter: true,
          // sortOrder: ["ascend", "descend"],
          // defaultSortOrder: "ascend",
          render: (_, item, rowIndex) => (
            <ExperienceUserListItem
              studentNameFormat={studentNameFormat}
              rubricType={rubricType}
              item={item}
              gradingConfig={gradingConfig}
              setGradingConfig={setGradingConfig}
              exportModalConfig={exportModalConfig}
              setExportModalConfig={setExportModalConfig}
              updateResource={updateResource}
              reviewerColumn={true}
            />
          ),
          onCell: (record, rowIndex) => {
            console.log("virtual list onCell student name click", record, rowIndex);
            return {
              onKeyDown: (e) => handleKeyDown(e, rowIndex),
              'cell-id': getCellid(rowIndex, "name"),
              tabIndex: 0,
              onClick: (e) => onExperienceUserNameCellClick(e, record),
            }
          },
          // sorter: (a, b) => a.full_name.localeCompare(b.full_name),
          // sortDirections: ['ascend', 'descend'],
          // defaultSortOrder: 'ascend'
        }
        gridColumns.push(studentNameCol)
      }
    }

    if (showGradingProgressColumn) {
      gridColumns.push(
        {
          title: mrIntl("ExperienceUsersTable.grading_progress"),
          dataIndex: "grading_progress",
          key: "grading_progress",
          width: 100,
          fixed: "left",
          // align: 'center',
          className: "exp-users-full-height-column grading-progress",
          sorter: isSortingDisabled ? null : (a, b) => {
            handleScroll();
            return a.marked_responses_count - b.marked_responses_count
          },
          render: (_, item, rowIndex) => (
            <ExperienceUserGradingProgressBar item={item} />
          ),
          onCell: (record, rowIndex) => {
            // console.log("record, rowIndex ==>", record, rowIndex)
            return {
              onKeyDown: (e) => handleKeyDown(e, rowIndex),
              onClick: (ev) => onGradingProgressCellClick(record),
              'cell-id': getCellid(rowIndex, "grading_progress"),
              tabIndex: 0,
            };
          },
        },
      );
    }
    
    gridColumns.push(
      {
        title: mrIntl("ExperienceUsersTable.uploads"),
        dataIndex: "uploads",
        key: "attachments_count",
        width: 80,
        align: 'center',
        className: "exp-users-full-height-column",
        render: (attachments_count, item) => (
          <>
            <ViewUploadsModal count={item.attachments_count} type={"text"} item={item} ></ViewUploadsModal>
          </>
        ),
        onCell: (record, rowIndex) => {
          return {
            onKeyDown: (event) => handleKeyDown(event, rowIndex),
            'cell-id': getCellid(rowIndex, "uploads"),
            tabIndex: 0,
          };
        },
      },
    )
    
    if (rubricType === "criteria_with_points" || rubricType === "myp_achievement_level") {
      gridColumns.push({
        title: (
          <ExperienceUserTotalPointsTitle rubricType={rubricType} exp_cas={exp_cas} experience={experience} />
        ),
        // title: `Total (${displayPoints(experience.points, false, true) })`,
        dataIndex: "total",
        key: "total",
        width: 100,
        align: 'center',
        className: "exp-users-full-height-column",
        sorter: isSortingDisabled ? null : (a, b) => {
          console.log("sorter==>", a, b);
          handleScroll();
          const aPoints = calculatePointsForSorting(a);
          const bPoints = calculatePointsForSorting(b);
          return aPoints - bPoints || a.name - b.name;
        },
        render: (total_points, eu) => (
          <ExperienceUserTotalPoints eu={eu} eu_scas={eu.submission_criterium_associations_attributes} exp_cas={exp_cas} rubricType={rubricType} />
        ),
        onCell: (record, rowIndex) => {
          return {
            onKeyDown: (e) => handleKeyDown(e, rowIndex),
            onClick: (ev) => onOverallPointsCellClick(record),
            'cell-id': getCellid(rowIndex, "total"),
            'data-id': `total-cell-${record.id}`,
            tabIndex: 0,
          };
        },
      })
    }

    gridColumns.push({
      title: (
        <ExperienceUserOverallTitle rubricType={rubricType} exp_cas={exp_cas} experience={experience} />
      ),
      // title: `Total (${displayPoints(experience.points, false, true) })`,
      dataIndex: "total_points",
      key: "total_points",
      width: perQuestionWidth || 150,
      align: 'center',
      className: "exp-users-full-height-column",
      sorter: isSortingDisabled ? null : (a, b) => {
        console.log("sorter==>", a, b);
        handleScroll();
        const aPoints = calculatePointsForSorting(a);
        const bPoints = calculatePointsForSorting(b);
        return aPoints - bPoints || a.name - b.name;
      },
      render: (total_points, eu) => (
        <ExperienceUserOverallPoints eu={eu} eu_scas={eu.submission_criterium_associations_attributes} exp_cas={exp_cas} rubricType={rubricType} />
      ),
      onCell: (record, rowIndex) => {
        return {
          onKeyDown: (e) => handleKeyDown(e, rowIndex),
          onClick: (ev) => onOverallPointsCellClick(record),
          'cell-id': getCellid(rowIndex, "total_points"),
          'data-id': `total-points-cell-${record.id}`,
          tabIndex: 0,
        };
      },
    })


   if (showConvertedAchievementLevel) {
    gridColumns.push({
      title: (
        <ExperienceUserOverallTitle rubricType={rubricType} exp_cas={exp_cas} experience={experience} show_converted_al={true} />
      ),
      // title: `Total (${displayPoints(experience.points, false, true) })`,
      dataIndex: "converted_achievement_levels",
      key: "converted_achievement_levels",
      width: perQuestionWidth || 150,
      align: 'center',
      className: "exp-users-full-height-column",
      sorter: isSortingDisabled ? null : (a, b) => {
        console.log("sorter==>", a, b);
        handleScroll();
        const aPoints = calculatePointsForSorting(a);
        const bPoints = calculatePointsForSorting(b);
        return aPoints - bPoints || a.name - b.name;
      },
      render: (total_points, eu) => (
        <ExperienceUserOverallPoints eu={eu} exp_cas={exp_cas} rubricType={rubricType} show_converted_al={true} eu_scas={eu.submission_criterium_associations_attributes} />
      ),
      onCell: (record, rowIndex) => {
        return {
          onKeyDown: (e) => handleKeyDown(e, rowIndex),
          onClick: (ev) => onOverallPointsCellClick(record),
          'cell-id': getCellid(rowIndex, "converted_achievement_levels"),
          'data-id': `converted-achievement-levels-cell-${record.id}`,
          tabIndex: 0,
        };
      },
    })
   }

    if (experienceSettings.grading_setting == "per_question" || experienceSettings.grading_setting == "overall") {
      let withSCAPoints = rubricType === "criteria_with_points" || rubricType === "myp_achievement_level";
      let perQuestionWidth = null;
      if (segments && segments.length >= 13) {
        perQuestionWidth = withSCAPoints ? exp_cas.length * 50 : 100;
        if (perQuestionWidth < 80) {
          perQuestionWidth = 80
        }
      }

      if (topics.length) {
        const indexPath = []
        let options = {
          segment_type: "question",
          segments: segments,
        }
        const callback = (data, segment, index, level) => {
          let questionType = segment.question_segment_attributes.question_type
          indexPath.splice(level + 1)
          indexPath[level + 1] = index
          let titleToRender = `${getLabelFromIndexPath(indexPath)}`
          if (level >= 1) {
            titleToRender = `${getQuestionNumber(index + 1, level + 1)}`
          }

          let selectedCriteria = currentCriteriaId ? segment.criterium_associations_attributes.find((sca) => sca.criterium_id == currentCriteriaId) : true
          if (selectedCriteria) {
            gridColumns.push({
              // title: 'Q'+ (i+1) + '.' + (k+1) + "(" + displayPoints(segment.points, false, true) + ")",
              title: (
                <Space direction="vertical" size={0}>
                  {titleToRender}
                  {segment.criterium_associations_attributes.length > 0 ? (
                    <span>
                      {segment.criterium_associations_attributes.map((ca) => {
                        let cTitle = <sup>{ca.custom_fields.title}</sup>;
                        let cPoints;
                        if (
                          rubric &&
                          rubric.type_c !== "myp_achievement_level"
                        ) {
                          cPoints = displayPoints(ca.points, false, true);
                        }
                        let finalRender = (
                          <>
                            {cTitle}
                            {cPoints}&nbsp;
                          </>
                        );
                        return finalRender;
                      })}
                    </span>
                  ) : (
                    rubric &&
                    rubric.type_c !== "comments_only" && (
                      <span>
                        ({displayPoints(segment.points, false, true)})
                      </span>
                    )
                  )}
                </Space>
              ),
              segment_id: segment.id,
              dataIndex: "question-" + segment.id,
              key: "question-" + segment.id,
              // width: segments && segments.length > 20 && 50,
              width: perQuestionWidth,
              className: `exp-users-full-height-column ${level == 0 && "table-header-cell-gray"}`,
              align: "center",
              sorter: isSortingDisabled ? null : true,
              // sortDirections: ["ascend", "descend"],
              // defaultSortOrder: "ascend",
              onHeaderCell: (column) => {
                console.log("column==>", column)
                if (isSortingDisabled) {
                  return {};
                }
                return {
                  onClick: () => onPointsHeaderCellClick(column),
                };
              },
              render: (_, eu) =>  {
                const isGradingWithAIInProgress = isGradingWithAIInProgressForEU(eu); 
                // Adding skeleton and checking progress outside the component to prevent every userResponse checking selector for grading progress and finding its experienceUser from all experienceUsers as not sending experienceUser in the UserResponseCellDetail component. 
                return isGradingWithAIInProgress 
                  ? <Skeleton.Input className={`skeleton-loading-scroll bg-lavender`} active />
                  : <UserResponseCellDetail
                      experience_user_id={eu.id}
                      user_id={eu.user_id}
                      segment={segment}
                      rubric={rubric}
                      sortedColumn={sortedColumn}
                    />
              },
              onCell: (record, rowIndex) => {
                return {
                  onKeyDown: (event) => handleKeyDown(event, rowIndex),
                  'cell-id': getCellid(rowIndex, "question-" + segment.id),
                  'data-id': `segment-cell-${record.id}-${segment.id}`,
                  tabIndex: 0,
                  onClick: (ev) => onPointsCellClick(record, segment),
                }
              },
            });
          }
          if (questionType == "group") {
            getRecursiveSegments({}, segment, index, level + 1, indexPath, options, callback)
          } else {
            if (_.isEmpty(firstQuestion)) {
              firstQuestion = segment
            }
          }
        }
        topics.forEach((segment, i) => {
          getRecursiveSegments({}, segment, i, 0, indexPath, options, callback)
        });
      }
    }
    return gridColumns;
  }
  // const finalGridColumns = getGridColumns1;
  // const datas = experienceUsers.map((eu) => {
  //   return {
  //     key: 'row-' + eu.id,
  //     ...eu,
  //   }
  // })
  // console.log("datas ==>", datas)

  const tableRef = useRef(null);  
  const handleScroll = useCallback(
    debounce(() => {
      console.log("calling handleScroll", tableRef);
      if (!tableRef.current) {
        return
      }
      const table = tableRef.current.querySelector('.ant-table-body');
      const tableRows = table.querySelectorAll('tr');
      const visibleRowsExperienceUserIds = [];
      const tableRect = table.getBoundingClientRect();
      const tableHeight = tableRect.height;
      const tableTop = tableRect.top;
      for (let i = 0; i < tableRows.length; i++) {
        const rowRect = tableRows[i].getBoundingClientRect();
        const rowHeight = rowRect.height;
        if (
          (rowRect.top >= tableTop && rowRect.top < tableTop + tableHeight) ||
          (rowRect.bottom > tableTop && rowRect.bottom <= tableTop + tableHeight) ||
          (rowRect.top < tableTop && rowRect.bottom > tableTop + tableHeight)
        ) {
          const id = tableRows[i].getAttribute('row-id');
          id && visibleRowsExperienceUserIds.push(parseInt(id));
        }
      }

      console.log("userResponseReduxActions ==>", userResponseReduxActions)
      dispatch(userResponseReduxActions.fetchMissing({ requestedExperienceUserIds: visibleRowsExperienceUserIds }))

    }, 200),
    []
  );

  useEffect(() => {
    console.log("checkingTableRefs on Line 718", tableRef)
    if (tableRef.current) {
      console.log("checkingTableRefs on Line 720", tableRef)
      const table = tableRef.current.querySelector('.ant-table-body');
      table.addEventListener('scroll', handleScroll);
      return () => table.removeEventListener('scroll', handleScroll)
    };
  }, [handleScroll]);

  useEffect(() => {
    handleScroll();
    return () => {
      console.log("ExperienceUsersList unmounted ==>")
    }
  }, []);
  
  const finalGridColumns = useCallback(getGridColumns(), [gradingConfig.anonymousGradingEnabled, sortedColumn, studentNameFormat, currentCriteriaId, submissionsCount, gradingConfig.assignReviewersEnabled, isGradeAllWithAIInProgress, aiGradedExperienceUsersLength])

  const getExperienceUsers = () => {
    let newExperienceUsers = [...experienceUsers];
    // If peer review is disabled after assigning, remove all duplicate experience users also
    if (!experienceSettings.peer_review_enabled || gradingConfig.assignReviewersEnabled) {
        newExperienceUsers = newExperienceUsers.filter(eu => eu.dup_from_id == null);
    }
    return newExperienceUsers;
  }

  const finalExperienceUsers = useCallback(getExperienceUsers(), [sortedColumn, experienceUsers, gradingConfig.assignReviewersEnabled])
 

  // const expandedRowRender = (record, index, indent, expanded) => {
  // }
  
  console.log("finalExperienceUsers ======>", finalExperienceUsers);
  return <Table
    rowkey="id"
    // sortDirections={sortDirections}
    locale={customLocaleTooltip}
    // className={"grading-summary"}
    // dataSource={datas}
    dataSource={finalExperienceUsers}
    columns={finalGridColumns}
    bordered
    className="experience-users-list-table p-0-20"
    // expandable={{
    //   expandedRowRender
    // }}
    pagination={false}
    size="small"
    showHeader={true}
    ref={node => {
      if(node && node.nativeElement){
        tableRef.current = node.nativeElement
      } else {
        tableRef.current = node
      }
    }}
    // ref={tableRef}
    // scroll={{ x: 800, y: 500 }}
    scroll={scroll}
    // onScroll={handleScroll}
    onRow={(record, rowIndex) => onRowEvents(record, rowIndex)}
    onChange={(pagination, filters, sorter, extra) => {
      handleScroll();
      console.log("pagination, filters, sorter, extra ==>", pagination, filters, sorter, extra)
    }}
  />
}

export default memo(ExperienceUsersTable);