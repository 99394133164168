import { useCallback, useEffect, useMemo, useState } from "react";
import { getParsedJSONObject } from "../InteractiveHelpers";
import SegmentItemFooter from "../SegmentItemDetail/SegmentItemFooter";
import { AddButton, DeleteButton } from "/src/components/UI/Segment/UIHelper";
import { useDispatch, useSelector } from "react-redux";
import {
  getEmbeddedSelector,
  partnerNameSelector,
} from "../../Auth/Login/selector";
import { getUniqueId } from "/src/lib/utils/helperMethods";
import { cloneDeep } from "lodash";
import { actions as firestoreInteractionActions } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/redux";
import { Flex, Input, InputNumber } from "antd";
import NumberLineCanvas from "./NumberLineCanvas";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import {
  mappedAnswer,
  resetOptions,
  resetOptionsFromNumberLine,
} from "./NumberLineHelper";

const getDefaultOption = () => ({
  id: getUniqueId(),
  value: "",
});

const NumberLine = (props) => {
  const {
    experienceViewMode,
    question_type,
    showCorrectAnswerSwitch,
    segmentSettings,
    setRenderMath,
    segmentFooterAttributes,
    screenSettings,
  } = props;

  const isGradingMode = useMemo(
    () => experienceViewMode === "apGradeTest",
    [experienceViewMode]
  );

  const attemptStatusObject = {
    reset_count: props.attempt_status?.reset_count ?? 0,
    json_attempted: props.attempt_status?.json_attempted ?? false,
  };

  const dispatch = useDispatch();
  const partnerName = useSelector(partnerNameSelector());
  const embedded = useSelector(getEmbeddedSelector());
  let {
    parsedJSONObject,
    createMode,
    answerMode,
    presentationMode,
    autoCheckMode,
  } = getParsedJSONObject(props);

  if (createMode) {
    parsedJSONObject = {
      segment_data: {
        options: [getDefaultOption()],
        minValue: 0,
        maxValue: 10,
      },
      segment_version: "1.0.1",
    };
    if (question_type === "number_line") {
      parsedJSONObject = props.value || parsedJSONObject;
    }
  }

  if (answerMode && !props.response_json) {
    parsedJSONObject = resetOptionsFromNumberLine(parsedJSONObject);
  }

  const mrIntl = useTranslate();
  const [textAnswer, setTextAnswer] = useState(props.text_answer ?? "");
  const [stateUpdatedCount, setStateUpdatedCount] = useState(0);
  const [attemptStatus, setAttemptStatus] = useState(attemptStatusObject);
  const [state, setState] = useState(parsedJSONObject);
  const [myAnswer, setMyAnswer] = useState(state);
  const [stateSetting, setStateSetting] = useState({
    autoCheckMode: autoCheckMode,
    quickCheckModeEnabled: false,
    showCorrectAnswerSwitch: showCorrectAnswerSwitch,
    showCorrectAnswerSwitchValue: false,
  });

  let textAnswerString = props.text_answer || "";
  let currentJSON, text_answer, attempt_status;
  if (presentationMode && !stateSetting.showCorrectAnswerSwitchValue) {
    currentJSON = parsedJSONObject;
    text_answer = textAnswerString;
    attempt_status = attemptStatusObject;
  } else {
    currentJSON = state;
    text_answer = textAnswer;
    attempt_status = attemptStatus;
  }

  const segment_data = currentJSON?.segment_data || {};
  const options = segment_data.options ?? [getDefaultOption()];
  const [choices, setChoices] = useState(options);
  const [holdCanvas, setHoldCanvas] = useState(false);

  function checkResponses(question_json, answer_json) {
    console.log("Question and Answer JSON ==>", question_json, answer_json);
    if (!question_json || !answer_json) {
      return;
    }
    let q_segment_data = question_json.segment_data || {};
    let a_segment_data = answer_json.segment_data || {};

    let q_options = q_segment_data.options || [];
    let a_options = a_segment_data.options || [];

    let correct_status = { all_correct: false };
    if (a_options) {
      let i = 0;
      while (i < q_options.length) {
        let q_option = q_options[i];
        let a_option =
          a_options.find((option) => option.id == q_option.id) || {};

        if (q_option.checked && a_option.checked) {
          a_option.correct = true;
          correct_status.all_correct = true;
        }

        if (partnerName === "osc" && q_option && q_option.checked) {
          a_option.correct = true;
        }

        i++;
      }
    }

    if (!autoCheckMode && props.onAutoCheck) {
      props.onAutoCheck(correct_status);
    }

    if (partnerName === "osc" && embedded) {
      // postMessage to parent iframe to let osc know if correct or not when student attempts
      window.parent.postMessage({ correct: correct_status.all_correct }, "*");
    }

    return answer_json;
  }

  const saveState = (state) => {
    setState(state);
    setStateUpdatedCount((stateUpdatedCount) => stateUpdatedCount + 1);
  };

  const toggleAnswer = () => {
    setStateSetting({
      ...stateSetting,
      quickCheckModeEnabled: !stateSetting.quickCheckModeEnabled,
      doNotShowExplanation: !stateSetting.doNotShowExplanation,
    });
  };

  const showCorrectAnswer = (value) => {
    let question_json, answer_json;
    if (value) {
      question_json = cloneDeep(props.teacher_json);
      answer_json = cloneDeep(props.teacher_json);
    } else {
      question_json = cloneDeep(props.teacher_json);
      answer_json = cloneDeep(myAnswer);
    }

    let newState = checkResponses(question_json, answer_json);
    newState.segment_data.options = mappedAnswer(
      question_json.segment_data.options,
      newState.segment_data.options
    );
    saveState(newState);
    setStateSetting({
      ...stateSetting,
      quickCheckModeEnabled: true,
      showCorrectAnswerSwitchValue: value,
    });
    if (setRenderMath) {
      setRenderMath(getUniqueId());
    }
  };

  // Add isCorrect property in options when in grading mode
  useEffect(() => {
    if (!isGradingMode) return;

    const mappedOptions = mappedAnswer(
      props.teacher_json.segment_data.options,
      state.segment_data.options
    );

    const newState = {
      ...state,
      segment_data: {
        ...state.segment_data,
        options: mappedOptions,
      },
    };
    setChoices(mappedOptions);

    saveState(newState);
  }, [isGradingMode, props.teacher_json?.segment_data?.options]);

  const checkAnswer = (check = false) => {
    if (check) {
      let question_json = cloneDeep(props.teacher_json);
      let answer_json = cloneDeep(state);
      const newState = checkResponses(question_json, answer_json);

      if (partnerName === "osc") {
        // To make sure only sending to BE on checkAnswer click, not on answer select and try again
        dispatch(
          firestoreInteractionActions.setUserResponseConfigSuccess({
            userResponseConfig: { saveToAPDB: true },
          })
        );
      }
      setMyAnswer(state);
      saveState(newState);
    }

    let doNotShowExplanation = false;
    if (partnerName === "osc") {
      doNotShowExplanation = true;
    }

    // if(partnerName !== "osc"){
    setStateSetting({
      ...stateSetting,
      quickCheckModeEnabled: check,
      doNotShowExplanation: doNotShowExplanation,
      autoCheckMode: true,
    });
    // }

    if (setRenderMath) {
      setRenderMath(getUniqueId());
    }
  };

  const resetQuestion = () => {
    setChoices((prev) => resetOptions(prev));
    let student_json = cloneDeep(props.student_json);
    const newState = resetOptionsFromNumberLine(student_json);

    if (partnerName === "osc") {
      // To make sure only sending to BE on checkAnswer click, not on answer select and try again
      dispatch(
        firestoreInteractionActions.setUserResponseConfigSuccess({
          userResponseConfig: { saveToAPDB: false },
        })
      );
    }

    setAttemptStatus({
      ...attemptStatus,
      reset_count: attemptStatus.reset_count + 1,
      json_attempted: false,
    });
    saveState(newState);
    if (partnerName === "osc") {
      setStateSetting({
        ...stateSetting,
        allowCheckAnswer: false,
        quickCheckModeEnabled: false,
        doNotShowExplanation: false,
        autoCheckMode: false,
      });
    } else {
      setStateSetting({
        ...stateSetting,
        quickCheckModeEnabled: false,
      });
    }
    if (setRenderMath) {
      setRenderMath(getUniqueId());
    }
    if (props.onTryAgain) {
      props.onTryAgain();
    }
  };

  const saveTextAnswer = (data) => {
    setTextAnswer(data);
    setStateUpdatedCount((stateUpdatedCount) => stateUpdatedCount + 1);
  };

  const handleChoicesChange = useCallback(() => {
    const data = {
      ...state,
      segment_data: {
        ...state.segment_data,
        options: choices,
      },
    };

    saveState(data);
  }, [choices]);

  const handleOptionCoords = useCallback((group, point = undefined) => {
    setChoices((prev) =>
      prev.map((item) =>
        group.id === item.id
          ? {
              ...item,
              left: group.left,
              top: group.top,
              point,
            }
          : item
      )
    );
  }, []);

  const handleAddClicked = () => {
    setChoices((prev) => [...prev, getDefaultOption()]);
  };

  const handleDeleteClicked = (index) => {
    setChoices((prev) => prev.filter((item, idx) => idx !== index));
  };

  useEffect(() => {
    if (answerMode) {
      const isAtleastOneSelected = choices.every((option) => option.point);

      setAttemptStatus((prev) => ({
        ...prev,
        json_attempted: isAtleastOneSelected,
      }));
    }
  }, [answerMode, choices]);

  useEffect(() => {
    if (createMode) {
      if (props.onChange) {
        let teacher_json = cloneDeep(state);
        props.onChange(teacher_json);
      }
      if (props.onSubmit) {
        // let teacher_json = cloneDeep(state)
        // props.onSubmit(teacher_json)
        console.log("On submit is available now", props.onSubmit);
      }
    } else if (answerMode && stateUpdatedCount > 0) {
      if (props.onChange) {
        let result = {
          response_json: cloneDeep(state),
          // text_answer: cloneDeep(textAnswer),
          attempt_status: cloneDeep(attemptStatus),
        };

        props.onChange(result);
      }
    }
  }, [stateUpdatedCount]);

  useEffect(() => {
    if (holdCanvas) return;
    handleChoicesChange();
  }, [handleChoicesChange, holdCanvas]);

  const toggleHoldCanvas = () => {
    let timeout;
    timeout && clearTimeout(timeout);
    timeout = setTimeout(() => {
      setHoldCanvas(false);
      clearTimeout(timeout);
    }, 1500);
  };

  const handleInputChange = (e, index) => {
    setChoices((prev) =>
      prev.map((item, idx) =>
        index === idx
          ? {
              ...item,
              top: undefined,
              left: undefined,
              point: undefined,
              value: e.target.value,
            }
          : item
      )
    );
    setHoldCanvas(true);
    toggleHoldCanvas();
  };

  const handleRangeChange = (rangeType, value) => {
    setChoices((prev) => resetOptions(prev));

    saveState((prev) => ({
      ...prev,
      segment_data: {
        ...prev.segment_data,
        options: resetOptions(choices),
        [rangeType]: value,
      },
    }));
  };

  return (
    <>
      <Flex justify="flex-start" vertical gap="small">
        {createMode && (
          <>
            <Flex style={{ marginTop: "20px" }} gap="small">
              <Flex vertical align="start">
                <div>Min Value</div>
                <InputNumber
                  value={state.segment_data?.minValue}
                  onChange={(value) => handleRangeChange("minValue", value)}
                  max={state.segment_data?.maxValue - 1}
                  status={state.segment_data?.minValue === null ? "error" : "-"}
                />
              </Flex>

              <Flex vertical align="start">
                <div>Max Value</div>
                <InputNumber
                  value={state.segment_data?.maxValue}
                  onChange={(value) => handleRangeChange("maxValue", value)}
                  min={state.segment_data?.minValue + 1}
                  status={state.segment_data?.maxValue === null ? "error" : "-"}
                />
              </Flex>
            </Flex>
            {choices?.map(({ id, value }, index) => (
              <Flex key={`option-${id}`} gap={"10px"}>
                <Input
                  id={`input-number-line-${id}`}
                  value={value}
                  onChange={(e) => handleInputChange(e, index)}
                  placeholder="Write choice"
                  className={`${value ? "gray-border" : ""}`}
                  name={`number-line-choice-${id}`}
                />
                <DeleteButton
                  clicked={() => handleDeleteClicked(index)}
                  disabled={segment_data?.options.length < 2}
                  tooltipTitle={
                    segment_data?.options.length < 2
                      ? mrIntl("CommonText.minimum_limit_reached")
                      : ""
                  }
                />
              </Flex>
            ))}
            <AddButton
              clicked={handleAddClicked}
              style={{ width: "fit-content", margin: "0 0 10px 0" }}
            />
          </>
        )}
      </Flex>

      <NumberLineCanvas
        options={state?.segment_data?.options}
        setOptionCoords={handleOptionCoords}
        presentationMode={presentationMode}
        minValue={state.segment_data?.minValue}
        maxValue={state.segment_data?.maxValue}
        autoCheckMode={autoCheckMode}
        isGradingMode={isGradingMode}
      />

      <SegmentItemFooter
        createMode={createMode}
        answerMode={answerMode}
        presentationMode={presentationMode}
        segmentFooterAttributes={segmentFooterAttributes}
        segmentSettings={segmentSettings}
        segmentStateSettings={stateSetting}
        screenSettings={screenSettings}
        setRenderMath={setRenderMath}
        saveTextAnswer={saveTextAnswer}
        // resetQuestion={partnerName === "osc" ? null : resetQuestion}
        resetQuestion={resetQuestion}
        checkAnswer={checkAnswer}
        showCorrectAnswer={partnerName === "osc" ? null : showCorrectAnswer}
        toggleAnswer={partnerName === "osc" ? toggleAnswer : null}
        questionType={partnerName === "osc" ? "number_line" : null}
      />
    </>
  );
};

export default NumberLine;
