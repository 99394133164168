import React, { useEffect, useState } from "react";
import { Modal, Button, Radio, Space, Tooltip, message } from "antd";
import { getUniqueId } from "/src/lib/utils/helperMethods";
import MediaRecorder from "/src/components/UI/Media/MediaRecorder";
import SelectFromComputer from "/src/components/UI/Media/SelectFromComputer";
import InsertFromURL from "/src/components/UI/Media/InsertFromURL";
import { checkMob } from "/src/lib/utils/helperMethods";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { enabledFeaturesSelector } from "/src/views/Auth/Login/selector";
import InsertFromDrive from "/src/components/UI/Media/InsertFromDrive";
import { useSelector } from "react-redux";
import Spinner from "../Spinner/Spinner";
const MediaUI = (props) => {
  console.log("Media UI props", props);
  const { mediaFormSetting, setMediaFormSetting, onSaveMedia, extractQuestionsFromPdf, attachmentsCount } = props;
  const fromButtonStyle = {
    margin: "10px",
  };

  const mrIntl = useTranslate();
  const enabledFeatures = useSelector(enabledFeaturesSelector())

  const handleFromChange = (type) => {
    if (type == "audiorecorder") {
      addFromMediaRecorder();
    } else if (type == "computer") {
      addFromComputer();
    } else if (type == "url") {
      addFromURL();
    } else if(type == "drive") {
      addFromDrive()
    }
  };

  const addFromComputer = () => {
    setMediaFormSetting({
      ...mediaFormSetting,
      visible: true,
      from: "computer",
    });
  };

  const addFromURL = () => {
    setMediaFormSetting({
      ...mediaFormSetting,
      visible: true,
      from: "url",
    });
  };

  const addFromDrive = () => {
    setMediaFormSetting({
      ...mediaFormSetting,
      from: "drive", 
    });
  }
  const addFromMediaRecorder = () => {
    setMediaFormSetting({
      ...mediaFormSetting,
      visible: true,
      from: "audiorecorder",
    });
  };

  const handleOk = () => {
    setMediaFormSetting({
      ...mediaFormSetting,
      triggerSaveAction: mediaFormSetting.triggerSaveAction + 1,
    });
  };

  const handleExtraOk = () => {
    setMediaFormSetting({
      ...mediaFormSetting,
      extraOkClicked: true,
      triggerSaveAction: mediaFormSetting.triggerSaveAction + 1,
    });
  };

  const handleCancel = () => {
    setMediaFormSetting({
      ...mediaFormSetting,
      visible: false,
      triggerSaveAction: 0,
    });
  };
  let formToRenderClassName = "media-ui-form";
  if (attachmentsCount > 0 && mediaFormSetting.notShowDriveBtn) {
    formToRenderClassName = formToRenderClassName + " hide-media-ui-form";
  }

  const formToRender = (
    <div className={formToRenderClassName}>
      <Radio.Group
        onChange={(e) => handleFromChange(e.target.value)}
        value={mediaFormSetting.from}
        className="media-from-buttons"
        buttonStyle="solid"
      >
        {/* <Space> */}
        {props.audiorecorder && (
          <Radio.Button value="audiorecorder">
            {mrIntl("MediaUI.record_audio")}
          </Radio.Button>
        )}
        {props.computer && (
          <>
            {mediaFormSetting.selectConfig.disabledButtonTooltip ? (
              <Tooltip
                title={mediaFormSetting.selectConfig.disabledButtonTooltip}
                placement="top"
              >
                <Radio.Button value="computer" disabled={true}>
                  {mrIntl("MediaUI.add_from_computer")}
                </Radio.Button>
              </Tooltip>
            ) : (
              <Radio.Button value="computer" disabled={mediaFormSetting.selectConfig?.disabledButton}>
                {mrIntl("MediaUI.add_from_computer")}
              </Radio.Button>
            )}
          </>
        )}
        {props.url && (
          <Radio.Button value="url" disabled={mediaFormSetting.selectConfig?.disabledButton}>
            {mrIntl("MediaUI.add_from_url")}
          </Radio.Button>
        )}
        {import.meta.env.VITE_REGION === "global" && props.drive && (
          <Radio.Button value="drive">
            {mrIntl("InsertFromDrive.upload_from_drive")}
          </Radio.Button>
        )}
        {/* </Space> */}
      </Radio.Group>
      {/* {props.audiorecorder && <Button onClick={(e) => addFromMediaRecorder()} style={fromButtonStyle} >Record Audio</Button>}
      {props.computer && <Button onClick={(e) => addFromComputer()} style={fromButtonStyle}> Add from computer</Button>}
      {props.url && <Button onClick={(e) => addFromURL()} style={fromButtonStyle}> Add from url</Button>} */}
      {mediaFormSetting.from == "audiorecorder" && (
        <MediaRecorder
          modal={!mediaFormSetting.modal}
          mediaFormSetting={mediaFormSetting}
          setMediaFormSetting={setMediaFormSetting}
          onSaveMedia={onSaveMedia}
        ></MediaRecorder>
      )}
      {mediaFormSetting.from == "computer" && (
        <SelectFromComputer
          modal={!mediaFormSetting.modal}
          mediaFormSetting={mediaFormSetting}
          setMediaFormSetting={setMediaFormSetting}
          onSaveMedia={onSaveMedia}
          extractQuestionsFromPdf={extractQuestionsFromPdf}
        ></SelectFromComputer>
      )}
      {mediaFormSetting.from == "url" && (
        <InsertFromURL
          modal={!mediaFormSetting.modal}
          mediaFormSetting={mediaFormSetting}
          setMediaFormSetting={setMediaFormSetting}
          onSaveMedia={onSaveMedia}
        ></InsertFromURL>
      )}
      {mediaFormSetting.from == "drive" && (
        <InsertFromDrive
          modal={false}
          mediaFormSetting={mediaFormSetting}
          setMediaFormSetting={setMediaFormSetting}
          onSaveMedia={onSaveMedia}
        />
      )}
    </div>
  );

  const getFooter = () => {
    return [
      <Button key="back" onClick={handleCancel}>
        {mediaFormSetting.cancelText || mrIntl("MediaUI.return")}
      </Button>,
      mediaFormSetting.extraOkText && (
        <Button
          key="extra-submit"
          type="primary"
          onClick={handleExtraOk}
          loading={mediaFormSetting.loading}
          disabled={mediaFormSetting.okDisabled}
        >
          {mediaFormSetting.extraOkText || mrIntl("CommonText.submit")}
        </Button>
      ),
      <Button
        key="submit"
        type="primary"
        onClick={handleOk}
        loading={mediaFormSetting.loading}
        disabled={mediaFormSetting.okDisabled}
      >
        {mediaFormSetting.okText || mrIntl("CommonText.submit")}
      </Button>,
    ];
  };

  let finalRender = null;
  if (mediaFormSetting.modal) {
    finalRender = mediaFormSetting.visible && (
      <Modal
        {...mediaFormSetting}
        open={mediaFormSetting.visible}
        width={checkMob() ? "" : "50%"}
        centered={checkMob() ? true : false}
        className={checkMob() ? "height-centered-modal" : ""}
        closable={false}
        maskClosable={false}
        keyboard={false}
        title={mediaFormSetting.title || mrIntl("CommonText.insert_media_title")}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={getFooter()}
        loading={false}
      >
        {formToRender}
      </Modal>
    );
  } else {
    finalRender = formToRender;
  }

  console.log("Media UI form before render ==>", mediaFormSetting);
  return (
    <>
      {finalRender}
      {mediaFormSetting.loading && mediaFormSetting.from === "drive" && (
        <Spinner />
      )}
    </>
  );
};
export default MediaUI;
