import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Space, Table, List, Select, Row, Col, Input, Typography, Switch, Modal, Tag, Popover, Divider, Collapse, Card } from 'antd';
import { actions as usersActions } from "/src/views/Users/redux";
import { useDispatch, useSelector } from 'react-redux';
import { allOrgUsersSelector } from '/src/views/Users/selector';
import Spinner from '/src/components/UI/Spinner/Spinner';
import { CheckBox } from '/src/components/UI/Segment/UIHelper';
import { SelectGrade, SelectProgram } from '/src/components/AppSpecific/SelectSubject/SelectSubject';
import { currentUserSelector, enabledFeaturesSelector } from '/src/views/Auth/Login/selector';
import { useTranslate } from '/src/lib/MrTranslate/MrTranslate';
import OrgPermissionsFilter from './OrgPermissionsFilter';
import { displayRole } from '/src/lib/utils/helperMethods';
import { UndoOutlined, EditOutlined, InfoCircleOutlined } from "@ant-design/icons";
import "./OrgPermissions.scss";
import BulkPermissionsModal from './BulkPermissionsModal';
import { cloneDeep, isArray } from 'lodash';
import { orgSubjectsSelector } from '../../Orgs/selector';
import { CaretRightOutlined } from '@ant-design/icons';

const { Text, Title } = Typography;
const { Search } = Input;

export const OldPermissionsUI = (props) => {
  const { org, updateResource } = props
  const mrIntl = useTranslate()
  const [permissions, setPermissions] = useState({});

  const enabledFeatures = useSelector(enabledFeaturesSelector())
  const hasActiveIBMiddleYearsProgramme = org.org_programmes.find(programme => {
    return programme.code === "myp" && !programme.archived;
  });

    // let initialPermissionListItemsData = [
  //   {
  //     role: "teacher",
  //     entity: "schoolbank_experience",
  //     type: "list",
  //     value: (item.roles.schoolbank_experience && item.roles.schoolbank_experience.list) || false,
  //     displayText: "Hide schoolbank experiences in library for teachers"
  //   },
  //   {
  //     role: "teacher",
  //     entity: "experience_user",
  //     type: "reset",
  //     value: (item.roles.experience_user && item.roles.experience_user.reset) || false,
  //     displayText: "Allow teachers to reset submissions"
  //   }
  // ]

  useEffect(() => {
    // From org in db otherwise setting defaults
    // IMP: hardcoded for teacher permissions right now
    let rolePerms = org.roles["teacher"]
    console.log("rolePerms", rolePerms)
    let initialPermissions = {
      teacher__schoolbank_experience__list: (rolePerms.schoolbank_experience && rolePerms.schoolbank_experience.list !== undefined) ? rolePerms.schoolbank_experience.list : true,
      teacher__experience_user__reset: (rolePerms.experience_user && rolePerms.experience_user.reset !== undefined) ? rolePerms.experience_user.reset : true,
      teacher__experience__allow_chatGPT: (rolePerms.experience && rolePerms.experience.allow_chatGPT !== undefined) ? rolePerms.experience.allow_chatGPT : false,
      teacher__experience__hide_per_question_grading_myp_al: (rolePerms.experience && rolePerms.experience.hide_per_question_grading_myp_al !== undefined) ? rolePerms.experience.hide_per_question_grading_myp_al : false,
      teacher__experience__can_switch_anonymous_grading: (rolePerms.experience && rolePerms.experience.can_switch_anonymous_grading !== undefined) ? rolePerms.experience.can_switch_anonymous_grading : false,
      // teacher__video_file__large: (rolePerms.video_file && rolePerms.video_file.large !== undefined) ? rolePerms.video_file.large : false,
      // teacher__audio_file__large: (rolePerms.audio_file && rolePerms.audio_file.large !== undefined) ? rolePerms.audio_file.large : false,
    }
    // IMP: hardcoded for teacher permissions right now
    setPermissions(initialPermissions)
  }, []);
  
  const updatePermission = (key, checked) => {
    // update local FE
    let updatedPermissions = { ...permissions }
    updatedPermissions[key] = checked
    setPermissions(updatedPermissions)

    // Update BE - send request
    let splitKey = key.split("__")
    let updatedPermission = {
      role: splitKey[0],
      entity: splitKey[1],
      type: splitKey[2],
      value: checked,
    }
    updateResource({ id: org.id, update_single_permission: updatedPermission })

    if (key === "teacher__experience__hide_per_question_grading_myp_al") {
      let splitKey = key.split("__")
      let updatedPermission = {
        role: "admin",
        entity: splitKey[1],
        type: splitKey[2],
        value: checked,
      }
      updateResource({ id: org.id, update_single_permission: updatedPermission })
    }
  }

  let finalRender = <List>
    {/* <List.Item>
      <Checkbox checked={!permissions.teacher__schoolbank_experience__list} onChange={(event) => updatePermission("teacher__schoolbank_experience__list", !event.target.checked)}>
         Hide schoolbank assessments in library for teachers 
         <MrTranslate id = {"OrgItemDetail.hide_schoolbank_assessments_msg"}/>
         </Checkbox>
      We want to save false when checked
    </List.Item> */}
    {hasActiveIBMiddleYearsProgramme && (
      <List.Item>
        <Checkbox
          checked={permissions.teacher__experience__hide_per_question_grading_myp_al}
          onChange={(event) => {
            updatePermission("teacher__experience__hide_per_question_grading_myp_al", event.target.checked)
          }}
        >
          {mrIntl("OrgPermissions.disable_per_question_grading_in_myp_al")}
        </Checkbox>
      </List.Item>
    )}

    <List.Item>
      <Checkbox checked={!permissions.teacher__experience_user__reset} onChange={(event) => updatePermission("teacher__experience_user__reset", !event.target.checked)}>
         {mrIntl("OrgItemDetail.allow_teachers_to_reset_student_submissions_and_reopen_uploads")}
         </Checkbox>
    </List.Item>
    <List.Item>
      <Checkbox checked={permissions.teacher__experience__can_switch_anonymous_grading} onChange={(event) => updatePermission("teacher__experience__can_switch_anonymous_grading", event.target.checked)}>
        {mrIntl("OrgPermissions.allow_teachers_to_enable_disable_anonymous_grading")}
      </Checkbox>
    </List.Item>
    {/* {enabledFeatures.allow_chatgpt_for_teachers && <List.Item>
      <Checkbox checked={permissions.teacher__experience__allow_chatGPT} onChange={(event) => updatePermission("teacher__experience__allow_chatGPT", event.target.checked)}>
      <MrTranslate id = {"OrgItemDetail.allow_teachers_to_use_chatgpt"}/>
         Allow teachers to use ChatGPT 
         </Checkbox>
    </List.Item>} */}
    {/* <List.Item>
      <Tooltip title={enabledFeatures.allow_large_video_file ? "" : "This feature is only available in Plus plan"} placement="left">
        <Checkbox checked={permissions.teacher__video_file__large} onChange={(event) => updatePermission("teacher__video_file_large", event.target.checked)} disabled={!enabledFeatures.allow_large_video_file}> Allow large video file size upto 500 MB </Checkbox>
      </Tooltip>
    </List.Item>
    <List.Item>
      <Tooltip title={enabledFeatures.allow_large_audio_file ? "" : "This feature is only available in Plus plan"} placement="left">
        <Checkbox checked={permissions.teacher__audio_file__large} onChange={(event) => updatePermission("teacher__audio_file__large", event.target.checked)}  disabled={!enabledFeatures.allow_large_audio_file}> Allow large audio file size upto 500 MB </Checkbox>
      </Tooltip>
    </List.Item> */}
  </List>


  return finalRender
}

const EditableSelect = ({ record, selectedUser, type = '', programmePermissions = {}, handleProgrammeDataChange, allOptions = [] }) => {
  const [isEditing, setIsEditing] = useState(false);
  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const valueKey = type === 'subjects' ? 'subject_ids' : 'grade_ids';
  const displayText = type === 'subjects' ? 'All Subjects' : 'All Grades';

  const SelectComponent = type === 'subjects' ? SelectSubject : SelectGrade;
  const schoolLibraryProgrammePermissions = programmePermissions?.["schoolbank_experience"]?.["programmes"] || {}
  const selectedGradeOrSubjectIds = schoolLibraryProgrammePermissions[record.code]?.[valueKey] || []

  const selectedGradeOrSubjectLabels = selectedGradeOrSubjectIds.map(id => {
    const option = allOptions.find(opt => opt.id === id);
    return option.label
  }) || []

  let selectConfig = {
    program: record,
    value: selectedGradeOrSubjectIds,
    onSelect: (value) => handleProgrammeDataChange(record.code, valueKey, value, "select", allOptions, record.id),
    onDeselect: (value) => handleProgrammeDataChange(record.code, valueKey, value, "deselect", allOptions, record.id),
  }

  if (type === 'grades') {
    selectConfig = {
      ...selectConfig,
      config: {
        widgetConfig: {
          options: allOptions,
          style: { width: "250px", textAlign: 'left' },
          mode: "multiple",
          maxTagCount: "responsive",
          allowClear: false,
        }
      }
    }
  }

  return (
    <Row>
      <Col span={24} className="editable-select-container">
        {isEditing ? (
          <SelectComponent
            {...selectConfig}
          />
        ) : (
          <div className='selected-names-container'>
            {selectedGradeOrSubjectLabels.length > 0 ? (
              <>
                {selectedGradeOrSubjectLabels.slice(0, 2).map(name => (
                  <Tag color="blue" key={name}>{name}</Tag>
                ))}
                {selectedGradeOrSubjectLabels.length > 2 && (
                  <Tag color="blue" style={{ fontSize: '14px' }}>+{selectedGradeOrSubjectLabels.length - 2}</Tag>
                )}
              </>
            ) : (
              <Tag color="blue" >{displayText}</Tag>
            )}
            {!isEditing && <Button onClick={handleEditClick} icon={<EditOutlined />} type='text'></Button>}
          </div>
        )}
      </Col>
    </Row>
  );
};


const SelectSubject = ({ program, onChange, value, onDeselect, onSelect }) => {
  const renderOptions = [];
  const allSubjects = program.subjects || []
  allSubjects.forEach(option => {
    if (option.type_c === 'group') {
      const groupSubjects = allSubjects.filter(subject => subject.type_c === 'subject' && subject.parent_id === option.id);
      if (groupSubjects.length > 0) {
        renderOptions.push(
          <Select.OptGroup key={option.id} label={option.name}>
            {groupSubjects.map(subject => (
              <Select.Option key={subject.id} value={subject.id}>
                {subject.name}
              </Select.Option>
            ))}
          </Select.OptGroup>
        );
      }
    }
  });

  return (
    <Select
      mode="multiple"
      placeholder={'Select subjects'}
      onChange={onChange}
      onSelect={onSelect}
      onDeselect={onDeselect}
      showSearch
      filterOption={(input, option) =>
        (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
      }
      value={value}
      maxTagCount="responsive"
      className='select-org-programme-subject'
    >
      {renderOptions}
    </Select>
  );
};

const EditModal = ({
  isOpen,
  selectedUser = {},
  setIsModalOpen,
  org,
  fetchUsers,
  programmePermissions,
  setProgrammePermissions,
  mrIntl
}) => {
  // console.log("programmePermissions =====>", programmePermissions);
  const currentUser = useSelector(currentUserSelector());
  const enabledFeatures = useSelector(enabledFeaturesSelector());
  const orgId = currentUser.org_id;
  const dispatch = useDispatch();
  const { permission = {} } = selectedUser;
  
  // Not taking from selector as each user can have different permissions
  const schoolLibraryProgrammePermissions = programmePermissions["schoolbank_experience"]?.["programmes"]
  const schoolLibraryListPermission =
    (permission["schoolbank_experience"] &&
      permission["schoolbank_experience"]["list"]) !== undefined
      ? permission["schoolbank_experience"]["list"]
      : true;
  const questionbankListPermission =
    (permission["questionbank_experience"] &&
      permission["questionbank_experience"]["list"]) !== undefined
      ? permission["questionbank_experience"]["list"]
      : true;
  const aiAssistantPermission = permission["ai_assistant"] && permission["ai_assistant"]["show"];
  const aiAssistantDefaultChecked =
    aiAssistantPermission !== undefined
    ? aiAssistantPermission
    : enabledFeatures.ai_generate_questions_with_prompt
  const managePermission = permission["manage_permissions"]?.list;

  const handleCancel = () => {
    setProgrammePermissions({})
    setIsModalOpen(false)
    // fetchUsers()
  };

  const handleOk = () => {
    const permissionsToUpdate = {};

    if (programmePermissions["schoolbank_experience"]) {
      permissionsToUpdate["schoolbank_experience"] = {
        ...programmePermissions["schoolbank_experience"],
        list: programmePermissions["schoolbank_experience"]["list"],
        programmes: programmePermissions["schoolbank_experience"]["programmes"] || {},
      };
    }

    if (programmePermissions["questionbank_experience"]) {
      permissionsToUpdate["questionbank_experience"] = {
        list: programmePermissions["questionbank_experience"]["list"],
      };
    }

    if (programmePermissions["ai_assistant"]) {
      permissionsToUpdate["ai_assistant"] = {
        show: programmePermissions["ai_assistant"]["show"],
      };
    }

    if (programmePermissions["manage_permissions"]) {
      permissionsToUpdate["manage_permissions"] = {
        list: programmePermissions["manage_permissions"]["list"],
      };
    }

    dispatch(usersActions.updatePermission({ permissions: permissionsToUpdate, user_ids: [selectedUser.id], org_id: orgId }, {
      successCallback: () => {
        fetchUsers();
        setIsModalOpen(false);
        setProgrammePermissions({})
      },
    }));
  };

  const handleProgrammeDataChange = (programmeCode, dataType, value, type, allOptions, programmeId) => {
    setProgrammePermissions(prevPermissions => {
      const updatedPermissions = { ...prevPermissions };
      if (!updatedPermissions["schoolbank_experience"]) {
        updatedPermissions["schoolbank_experience"] = { list: true };
      }
      if (!updatedPermissions["schoolbank_experience"]["programmes"]) {
        updatedPermissions["schoolbank_experience"]["programmes"] = {};
      }
      if (!updatedPermissions["schoolbank_experience"]["programmes"]?.[programmeCode]) {
        updatedPermissions["schoolbank_experience"]["programmes"][programmeCode] = { programme_id: programmeId, list: true, subject_ids: [], grade_ids: [] };
      }

      const dataTypeArray = updatedPermissions["schoolbank_experience"]["programmes"][programmeCode][dataType] || [];
      if (type === 'select') {
        updatedPermissions["schoolbank_experience"]["programmes"][programmeCode][dataType] = [...dataTypeArray, value];
      } else if (type === 'deselect') {
        updatedPermissions["schoolbank_experience"]["programmes"][programmeCode][dataType] = dataTypeArray.filter(item => item !== value);
      }

      return updatedPermissions;
    });
  };

  const handleSwitchChange = (checked, record, permissionToUpdate, updateParentPermission = false) => {
    
    setProgrammePermissions(prevPermissions => {
      const updatedPermissions = { ...prevPermissions };
      
      if (permissionToUpdate === 'ai_assistant') {
        updatedPermissions[permissionToUpdate] = {
          show: checked,
        };
      } else {
        let permissionData = { list: true }
        if (updateParentPermission) {
          permissionData = {
            ...updatedPermissions[permissionToUpdate],
            list: checked,
          };
        } else {
          permissionData.programmes = {
            ...updatedPermissions[permissionToUpdate]?.programmes,
            [record.code]: {
              programme_id: record.id,
              list: checked,
              subject_ids: [],
              grade_ids: [],
            },
          };
        }
        updatedPermissions[permissionToUpdate] = permissionData;
      }
  
      return updatedPermissions;
    });
  };

  const columns = [
    {
      title: 'Programmes',
      dataIndex: 'programme_id',
      key: 'programme_id',
      fixed: 'left',
      render: (text, record) => {
        const defaultCheckedProgramme = schoolLibraryProgrammePermissions?.[record.code]?.list
        return <Row>
          <Col style={{marginRight: '10px'}}>
            <Switch key={`programme-id-${record.id}`} size="small" defaultChecked checked={defaultCheckedProgramme} onChange={(checked) => handleSwitchChange(checked, record, 'schoolbank_experience')} />
          </Col>
          <Col span={21}>
            <p>{record.name}</p>
          </Col>
        </Row>
      }
    },
    {
      title: 'Subjects',
      dataIndex: 'subjects',
      key: 'subjects',
      width: 400,
      align: 'center',
      render: (text, record) => (
        (schoolLibraryProgrammePermissions?.[record.code]?.list || schoolLibraryProgrammePermissions?.[record.code]?.list === undefined) ? (
          <EditableSelect
            record={record}
            selectedUser={selectedUser}
            key={`select-subject-${record.id}`}
            type="subjects"
            programmePermissions={programmePermissions}
            handleProgrammeDataChange={handleProgrammeDataChange}
            allOptions={record.subjects}
          />
        ) : (
          <Tag>N/A</Tag>
        )
      ),
    },
    {
      title: 'Grades',
      dataIndex: 'grades',
      key: 'grades',
      width: 400,
      align: 'center',
      render: (text, record) => (
        (schoolLibraryProgrammePermissions?.[record.code]?.list || schoolLibraryProgrammePermissions?.[record.code]?.list === undefined) ? (
          <EditableSelect
            record={record}
            selectedUser={selectedUser}
            key={`select-grade-${record.id}`}
            type="grades"
            programmePermissions={programmePermissions}
            handleProgrammeDataChange={handleProgrammeDataChange}
            allOptions={record.grades}
          />
        ) : (
          <Tag>N/A</Tag>
        )
      ),
    },
  ];

  const items = [
    {
      key: '1',
      label: (
        <Row align="middle" justify="space-between" gutter={8} className='collapse-header-permissions-modal'>
          <Col>
            <Text strong level={2}>{mrIntl("CommonText.school_library")}</Text>
          </Col>
        </Row>
      ),
      children: (
        <Table
          dataSource={org?.org_programmes}
          columns={columns}
          bordered
          expandable={{ showExpandColumn: false }}
          scroll={{ x: 'max-content' }}
          pagination={false}
        />
      ),
      extra: <Switch defaultChecked={schoolLibraryListPermission} onChange={(checked) => handleSwitchChange(checked, selectedUser, 'schoolbank_experience', true)} />,
    },
  ];

  const withoutCollapse = [
    {
      key: '2',
      label: (
        <Row align="middle" justify="space-between" gutter={8}>
          <Col>
            <span>{mrIntl("OrgPermissions.ap_banks")}</span>
          </Col>
        </Row>
      ),
      extra: <Switch defaultChecked={questionbankListPermission} onChange={(checked) => handleSwitchChange(checked, selectedUser, 'questionbank_experience', true)} />,
    },
    {
      key: '3',
      label: (
        <Row align="middle" justify="space-between" gutter={8}>
          <Col>
            <span>{mrIntl("OrgPermissions.ai_assistant")}</span>
          </Col>
        </Row>
      ),
      extra: <Switch defaultChecked={aiAssistantDefaultChecked} onChange={(checked) => handleSwitchChange(checked, selectedUser, 'ai_assistant', true)} />,
    },
    {
      key: '4',
      label: (
        <Row align="middle" justify="space-between" gutter={8}>
          <Col>
            <span>{mrIntl("OrgPermissions.manage_permissions")}</span>
          </Col>
        </Row>
      ),
      extra: <Switch defaultChecked={managePermission} disabled={selectedUser?.role !== "admin"} onChange={(checked) => handleSwitchChange(checked, selectedUser, 'manage_permissions', true)} />,
    },
  ];

  return (
    <Modal
      title={<Title level={4}>{`${selectedUser.name}'s Permissions`}</Title>}
      open={isOpen}
      footer={
        <>
          <Button onClick={() => handleCancel()}>{mrIntl("CommonText.cancel")}</Button>
          <Button type="primary" onClick={() => handleOk()}>
            {mrIntl("CommonText.save")}
          </Button>
        </>
      }
      onCancel={() => handleCancel()}
      width={"80%"}
      className='org-permissions-modal'
    >
      <Card title={<Title strong level={3}>{mrIntl("OrgPermissions.permissions")}</Title>} className='org-permissions-modal-card'>
        <Collapse
          accordion
          items={items}
          bordered={false}
          defaultActiveKey={['1']}
          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
          className='org-permissions-modal-collapse'
        />
        {withoutCollapse.map(item => (
          <Card key={item.key} className='org-permissions-modal-card'>
            <Row justify="space-between" align="middle">
              <Col>{item.label}</Col>
              <Col>{item.extra}</Col>
            </Row>
          </Card>
        ))}
      </Card>
    </Modal>
  );
};

const PermissionCheckbox = (props) => {
  console.log("PermissionCheckbox =====>", props);
  const { id, org_id, permissionToUpdate = {}, dependentPermissionToUpdate, permission = {}, disabled, defaultChecked, setDisableUserIdsPermissions = null } = props;

  // const [checked, setChecked] = useState();
  const dispatch = useDispatch();

  const updatePermission = (userId, checked) => {
    let updatedPermissions = {}
    // let updatedPermissions = {
    //   [permissionToUpdate.name]: {
    //     // ...permission[permissionToUpdate.name], // NOTE: Do not need to store this value, because we are updating a single permission
    //   }
    // }

    // if(permissionToUpdate.permission && isArray(permissionToUpdate.permission)) {
    //   permissionToUpdate.permission.map((key) => {
    //     updatedPermissions[permissionToUpdate.name][key] = checked 
    //   })
    // } else {
    //   updatedPermissions[permissionToUpdate.name][permissionToUpdate.permission] = checked 
    // }
    
    if(permissionToUpdate && isArray(permissionToUpdate)) {
      permissionToUpdate.forEach(p => {
        console.log('permissionToUpdate ==>>', p, updatedPermissions);
        updatedPermissions[p.name] = {
          ...updatedPermissions[p.name],
          [p.permission]: checked
        }
      })
    }
    else {
      updatedPermissions = {
        [permissionToUpdate.name]: {
          // ...permission[permissionToUpdate.name], // NOTE: Do not need to store this value, because we are updating a single permission
          [permissionToUpdate.permission]: checked
        }
      }
      // updatedPermissions[permissionToUpdate.name][permissionToUpdate.permission] = checked
    }

    if (dependentPermissionToUpdate) {
      updatedPermissions = {
        ...updatedPermissions,
        [dependentPermissionToUpdate.name]: {
          // ...permission[dependentPermissionToUpdate.name], // NOTE: Do not need to store this value, because we are updating a single permission
          [dependentPermissionToUpdate.permission]: dependentPermissionToUpdate.value,
        }
      }
      
      if(setDisableUserIdsPermissions) {
        setDisableUserIdsPermissions((prevState) => ({ 
          ...prevState,
          [userId]: {
            [dependentPermissionToUpdate.name]: !checked,
          }
        }))
      }
    }
    console.log("updatedPermissions =====>", checked, updatedPermissions);
    dispatch(usersActions.updatePermission({ user_ids: [userId], org_id: org_id, permissions: updatedPermissions }))
  };

return <Switch
      disabled={disabled}
      size="small"
      // checked={disabled ? !disabled : checked} // NOTE: Do not store checked value, let Checkbox handle it
      defaultChecked={defaultChecked}
      onChange={(checked) => {
        // We are not store value in redux, so we are using local state to handle checkbox value 
        // const checked = e.target.checked
        // setChecked(checked)
        updatePermission(id, checked)
      }}
    />
};

const returnModifiedItem = (item, org) => {
  let newItem = {...item}
  if(item.org_users.length){
    let orgId = org.id
    let correctOrgUser = item.org_users.find(ou => ou.org_id == orgId)
    if(correctOrgUser){
      // TODO: we should actually use all attrs of correctOrgUser - since we join with org_users for the list but are still showing user attrs - might be wrong
      newItem.permission = correctOrgUser.permission
    }
    console.log("returnModifiedItem newItem", newItem, correctOrgUser);
  }
  return newItem
}

const OrgPermissions = (props) => {
  console.log("OrgPermissions =====>", props);
  const { org } = props
  const dispatch = useDispatch();
  const orgId = org.id;
  const allUsers = useSelector(allOrgUsersSelector());
  const [filterParams, setFilterParams] = useState({
    by_role: "teacher_admin",
    page_size: 300,
    by_org_user_org_id: orgId,
    // by_org_user_unarchived: true // no need already default on BE, instead adding this was duplicating user in the list
  });
  const [loading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState("");
  const [disableUserIdsPermissions, setDisableUserIdsPermissions ] = useState({})
  const [programmePermissions, setProgrammePermissions] = useState({});
  const [programmeIds, setProgrammeIds] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const mrIntl = useTranslate()
  const extraActions = []
  const filters = []
  const enabledFeatures = org.enabled_features || {};
  const showBulkPermissions = enabledFeatures.enable_update_bulk_permissions
  const showAdvancedAssessmentPermissions = (enabledFeatures.show_advanced_assessment_permissions || import.meta.env.VITE_MODE === "development");
  const isSchoolLibraryProgrammePermissions = enabledFeatures.enable_school_library_programme_permissions

  const fetchUsers = () => {
    setLoading(true);
    dispatch(usersActions.fetch({params: filterParams}, {
      successCallback: () => setLoading(false),
    }));
  }

  useEffect(() => {
    fetchUsers()
  }, [filterParams]);

  const columns = []

  const newUpdatedUsers = allUsers.map((u, i) => {
    return returnModifiedItem(u, org);
  });  

  const showModal = (record) => {
    const newProgrammePermissions = cloneDeep(record.permission)
    setProgrammePermissions(newProgrammePermissions)
    console.log("record.permission =====>", newProgrammePermissions);
    
    setCurrentRecord(record);
    setIsModalOpen(true);
  };


  columns.push(
    {
      title: 'Name',
      key: 'name',
      width: 200,
      render: (record) => {
        console.log('record ===>', record);
        
        return <div className='org-permission-users-name'>
          <span>
            {record.name}
            <br />
            <Text type="secondary" className="f-s-12">
              {record.email}
            </Text>
          </span>
          {isSchoolLibraryProgrammePermissions
            ? <Button
              className='edit-org-permission-user-button'
              icon={<EditOutlined />}
              type="text"
              onClick={() => showModal(record)}
            />
            : null}
        </div>
      }
    },
    {
      title: 'Role',
      key: 'role',
      width: 80,
      render: (record) => {
        return displayRole(record.role)
      }
    },
    // {
    //   title: 'Entire library',
    //   key: 'library',
    //   align: 'center',
    //   width: 120,
    //   render: (record) => {
    //     const { permission } = record
    //     const libraryListPermission = permission["library"] && permission["library"]["list"];

    //     return <PermissionCheckbox
    //       key={`permission-checkbox-entire-library-${record.id}`}
    //       defaultChecked={libraryListPermission}
    //       permissionToUpdate={{ name: "library", permission: "list"}}
    //       setDisableUserIdsPermissions={setDisableUserIdsPermissions}
    //       dependentPermissionToUpdate={{name: "schoolbank_experience", permission: "list", value: false}}
    //       {...record}
    //       org_id={orgId} // Override org_id from record to handle superadmin case
    //     />
    //   },
    // },
    {
      title: 'School library',
      key: 'schoolbank_experience',
      align: 'center',
      width: 100,
      render: (record) => {
        const { permission = {} } = record
        const schoolLibraryPermissions = record.permission?.schoolbank_experience || {}
        const schoolLibraryProgrammePermissions = schoolLibraryPermissions["programmes"] || {} 
        let selectedProgrammeDetails = [];
        org.org_programmes?.forEach(program => {
          const isProgramListed = schoolLibraryProgrammePermissions?.[program.code]?.list;
          const defaultProgrammeDetails = {
            programName: program.name,
            subjects: 'N/A',
            grades: 'N/A'
          };

          if (!isProgramListed && isProgramListed !== undefined) {
            selectedProgrammeDetails.push(defaultProgrammeDetails);
            return;
          }

          const programmeSubjects = program.subjects.filter(subject =>
            schoolLibraryProgrammePermissions?.[program.code]?.subject_ids?.includes(subject.id)
          ) || [];

          const programmeGrades = program.grades.filter(grade =>
            schoolLibraryProgrammePermissions?.[program.code]?.grade_ids?.includes(grade.id)
          ) || [];

          selectedProgrammeDetails.push({
            programName: program.name,
            subjects: programmeSubjects.map(subject => subject.name).join(', ') || "All subjects",
            grades: programmeGrades.map(grade => grade.label).join(', ') || "All grades"
          });
        });

        const schoolLibraryListPermission =
          (permission["schoolbank_experience"] &&
            permission["schoolbank_experience"]["list"]) !== undefined
            ? permission["schoolbank_experience"]["list"]
            : true;
        // const disabled = 
        //   disableUserIdsPermissions[record.id] && 
        //   disableUserIdsPermissions[record.id]["schoolbank_experience"] !== undefined
        //     ? disableUserIdsPermissions[record.id]["schoolbank_experience"]
        //     : !(permission["library"] && permission["library"]["list"])
        return <Row className='permission-switch-info-icon'>
          <PermissionCheckbox
            key={`permission-checkbox-school-experience-${record.id}`} // NOTE: using disabled in key to force rerender and get updated value for defaultChecked
            // disabled={disabled}
            defaultChecked={schoolLibraryListPermission}
            permissionToUpdate={{ name: "schoolbank_experience", permission: "list" }}
            {...record}
            org_id={orgId} // Override org_id from record to handle superadmin case
          />
          {isSchoolLibraryProgrammePermissions
            ? <Popover
              overlayInnerStyle={{ height: selectedProgrammeDetails.length === 1 ? '100px' : '200px', overflowY: selectedProgrammeDetails.length === 1 ? 'hidden' : 'auto' }}
              overlayClassName='popover-content-wrapper'
              title={
                <div className='popover-content'>
                  {selectedProgrammeDetails.length > 0 ? (
                    selectedProgrammeDetails.map(({ programName, subjects, grades }) => (
                      <Row key={programName} className="category">
                        <Col span={24}>
                          <strong>{mrIntl("OrgPermissions.programme")}</strong> {programName}
                        </Col>
                        <Col span={24}>
                          <strong>{mrIntl("OrgPermissions.subjects")}</strong> {subjects}
                        </Col>
                        <Col span={24}>
                          <strong>{mrIntl("OrgPermissions.grades")}</strong> {grades}
                        </Col>
                        {selectedProgrammeDetails.length > 1 && <Divider className='divider'></Divider>}
                      </Row>
                    ))
                  ) : null}
                </div>
              }
            >
              <InfoCircleOutlined className='programme-permissions-info-icon' />
            </Popover>
            : null}
        </Row>
      },
    }
  )

  if (org.org_questionbanks && org.org_questionbanks.length > 0) {
    columns.push({
      title: 'AP banks',
      key: 'questionbank_experience',
      align: 'center',
      width: 100,
      render: (record) => {
        const { permission = {} } = record
        const schoolLibraryListPermission = 
          (permission["questionbank_experience"] && 
            permission["questionbank_experience"]["list"]) !== undefined 
              ? permission["questionbank_experience"]["list"] 
              : true;
        // const disabled = userIdToDisable[record.id] !== undefined ? userIdToDisable[record.id] === false : !(permission["library"] && permission["library"]["list"]);
        // const disabled = 
        //   disableUserIdsPermissions[record.id] && 
        //   disableUserIdsPermissions[record.id]["questionbank_experience"] !== undefined
        //     ? disableUserIdsPermissions[record.id]["questionbank_experience"]
        //     : !(permission["library"] && permission["library"]["list"])
        
        console.log("disableUserIdsPermissions ==> 225", record.id, disableUserIdsPermissions, permission["questionbank_experience"])
        return <PermissionCheckbox
          key={`permission-checkbox-questionbank-experience-${record.id}`}
          // key={`permission-checkbox-school-experience-${record.id}-${disabled}`} // NOTE: using disabled in key to force rerender and get updated value for defaultChecked
          // disabled={disabled}
          defaultChecked={schoolLibraryListPermission}
          permissionToUpdate={{ name: "questionbank_experience", permission: "list" }}
          {...record}
          org_id={orgId} // Override org_id from record to handle superadmin case
        />
      },
    })
  }
    
  // TODO: remove allow_chatgpt_for_teachers condition
  if (enabledFeatures.ai_generate_questions_with_prompt) {
    columns.push({
      title: 'AI assistant',
      key: 'ai_assistant',
      align: 'center',
      width: 100,
      render: (record) => {
      const { permission = {} } = record
      const aiAssistantPermission = permission["ai_assistant"] && permission["ai_assistant"]["show"];
      const defaultChecked = aiAssistantPermission !== undefined ? aiAssistantPermission : enabledFeatures.ai_generate_questions_with_prompt

      return <PermissionCheckbox 
        key={`permission-checkbox-ai-assistant-${record.id}`}
        defaultChecked={defaultChecked}
        permissionToUpdate={{name: "ai_assistant", permission: "show"}} 
        {...record} 
        org_id={orgId} // Override org_id from record to handle superadmin case
      />},
    })
  }

  // comment for now because Remove the option to delete directly. Delete should only be available for archived assessments.
  // columns.push(
  //   {
  //     title: 'Assessment delete',
  //     key: 'assessment_delete',
  //     align: 'center',
  //     width: 100,
  //     render: (record) => {
  //       const { permission = {} } = record
  //       const assessmentDelete = (permission.experience && permission.experience.delete) ? true : false;
  //       return <PermissionCheckbox
  //         key={`permission-checkbox-assessment-delete-${record.id}`}
  //         defaultChecked={assessmentDelete}
  //         permissionToUpdate={{ name: "experience", permission: "delete" }}
  //         {...record}
  //         org_id={orgId}
  //       />
  //     },
  //   },
  // )

  columns.push(
    {
      title: 'Manage permissions',
      key: 'manage_permissions',
      align: 'center',
      width: 100,
      render: (record) => {
        const { permission = {} } = record
        const managePermission = permission["manage_permissions"] && permission["manage_permissions"]["list"];
        return <PermissionCheckbox
          key={`permission-checkbox-manage-permissions-${record.id}`}
          defaultChecked={managePermission}
          disabled={record.role !== "admin"}
          permissionToUpdate={{ name: "manage_permissions", permission: "list" }}
          {...record}
          org_id={orgId} // Override org_id from record to handle superadmin case
        />
      },
    },
  )

  if (showAdvancedAssessmentPermissions) {
    columns.push(
      {
        title: 'Create/Edit assessments',
        key: 'create_edit_assessments',
        align: 'center',
        width: 100,
        render: (record) => {
          const { permission = {} } = record
          const experiencePermissions = permission.experience || {}
          const createEditPermission = (experiencePermissions.create && experiencePermissions.update) ? true : false;
          return <PermissionCheckbox
            key={`permission-checkbox-create-edit-permissions-${record.id}`}
            defaultChecked={createEditPermission}
            disabled={record.role !== "teacher"}
            permissionToUpdate={[{ name: "experience", permission: "create" }, { name: "experience", permission: "update" }, 
              // { name: "library", permission: "list" }
            ]}
            {...record}
            org_id={orgId}
          />
        },
      },
    )
    columns.push(
      {
        title: 'Deliver assessments',
        key: 'deliver_assessments',
        align: 'center',
        width: 100,
        render: (record) => {
          const { permission = {} } = record
          const experiencePermissions = permission.experience || {}
          const deliverPermission = (experiencePermissions.deliver === undefined || experiencePermissions.deliver) ? true : false;
          return <PermissionCheckbox
            key={`permission-checkbox-deliver-permissions-${record.id}`}
            defaultChecked={deliverPermission}
            disabled={record.role !== "teacher"}
            permissionToUpdate={{ name: "experience", permission: "deliver" }}
            {...record}
            org_id={orgId}
          />
        },
      },
    )
    columns.push(
      {
        title: 'Monitor assessments',
        key: 'monitor_assessments',
        align: 'center',
        width: 100,
        render: (record) => {
          const { permission = {} } = record
          const experiencePermissions = permission.experience || {}
          const monitorPermission = (experiencePermissions.monitor === undefined || experiencePermissions.monitor) ? true : false;
          return <PermissionCheckbox
            key={`permission-checkbox-monitor-permissions-${record.id}`}
            defaultChecked={monitorPermission}
            disabled={record.role !== "teacher"}
            permissionToUpdate={{ name: "experience", permission: "monitor" }}
            {...record}
            org_id={orgId}
          />
        },
      },
    )
    columns.push(
      {
        title: 'Grade assessments',
        key: 'grade_assessments',
        align: 'center',
        width: 100,
        render: (record) => {
          const { permission = {} } = record
          const experiencePermissions = permission.experience || {}
          const gradePermission = (experiencePermissions.grade === undefined || experiencePermissions.grade) ? true : false;
          return <PermissionCheckbox
            key={`permission-checkbox-grade-permissions-${record.id}`}
            defaultChecked={gradePermission}
            disabled={record.role !== "teacher"}
            permissionToUpdate={{ name: "experience", permission: "grade" }}
            {...record}
            org_id={orgId}
          />
        },
      },
    )
  }

  // filters.push(
  //   <Button
  //     onClick={() => {
  //       setFilterParams({by_role: "teacher_admin", search: ""})
  //       setSearchData("")
  //     }}
  //     icon={<UndoOutlined />}
  //     style={{ float: "right" }}
  //   >  
  //     Reset 
  //   </Button>
  // )
  
  // filters.push(
  //   <div className="filter-title">Search</div>
  // )
  filters.push(
    <Search
      placeholder="Search"
      value={searchData}
      onChange={(e) => setSearchData(e.target.value)}
      onSearch={(value) => setFilterParams({...filterParams, search: value })}
      allowClear
      enterButton
      style={{
        width: "100%"
      }}
    />
  );
  // filters.push(
  //   <div className="filter-title">Role</div>
  // )
  filters.push(
    <Select
      allowClear
      className="user-permission-role"
      placeholder={"Select role"}
      value={filterParams.by_role}
      onChange={(value) => {
        if (value === undefined) {
          setFilterParams({
            ...filterParams,
            by_role: "teacher_admin"
          })
        } else {
          setFilterParams({ ...filterParams, by_role: value })
        }
      }
      }
      options={[{
        label: "All",
        value: "teacher_admin"
        },
        { 
          label: "Admin", 
          value: "admin"
        }, 
        { 
          label: "Teacher", 
          value: "teacher" 
      }]} 
    />
  );

  if (showBulkPermissions) {
    extraActions.push(<BulkPermissionsModal fetchUsers={fetchUsers} orgId={orgId} />)
  }

  const spinner = <Spinner />;
  console.log("programmePermissions =====>", programmePermissions);
  
  const finalRender = (
    <>
      {/* <OrgPermissionsFilter 
        filterParams 
        setFilterParams
      /> */}
      <Row className='m-b-20 m-t-10'>
        <Col span={24}>
          <b>User permissions</b>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Space direction="horizantal" className="user-permissions-filters">
            {filters}
            {extraActions}
          </Space>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {loading
            ? spinner
            : <Table
              dataSource={newUpdatedUsers}
              columns={columns}
              bordered
              expandable={{
                showExpandColumn: false,
              }}
              className='user-permissions-table'
              scroll={{
                x: 'max-content',
              }}
            />}
        </Col>
        {/* <Col span={6}>
          <Space direction="vertical" style={{
            width: "100%",
            padding: "0 20px 10px",
          }}>
            {filters}
          </Space>
        </Col> */}
        {isModalOpen && (
          <EditModal
            isOpen={isModalOpen}
            selectedUser={currentRecord}
            setIsModalOpen={setIsModalOpen}
            org={org}
            fetchUsers={fetchUsers}
            programmeIds={programmeIds}
            setProgrammeIds={setProgrammeIds}
            programmePermissions={programmePermissions}
            setProgrammePermissions={setProgrammePermissions}
            mrIntl={mrIntl}
          />
        )}
      </Row>
    </>
  );

  

  return finalRender;
};

export default OrgPermissions;
