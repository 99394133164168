import React, { useEffect, useState } from "react";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";


const OrgFeatureItemDetail = (props) => {
  const {item, setIsVisible} = props;
  const mrIntl = useTranslate();

  console.log("propsprops ", props)
  

  return (
    <React.Fragment>
      {props.renderForm({
                  name: "features",
                  config: { 
                    isModal: false, 
                    visible: true,
                    // modal: {
                    //   width: "50%",
                    //   title: mrIntl("CommonText.org_features"),
                    //   closeIcon: false,
                    // },
                      
                    submitConfig: {
                      hideForm: true,
                      successCallback: (data) => {
                        if(data.status === 200) {
                          console.log("success 12 => ", data);
                          setIsVisible(false);
                        } 
                      },
                    },
                    
                  },
                  resource: item, //use this for edit
                })}
      
    </React.Fragment>
  );
}


OrgFeatureItemDetail.defaultProps = {};

OrgFeatureItemDetail.propTypes = {};

export default OrgFeatureItemDetail;
