import React, { useState } from "react";
import { Modal, Radio } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import MagicButton from "../Segments/GenerateAI/MagicButton";
import { useDispatch, useSelector } from "react-redux";
import { message } from "/src/components/UI/AntdAppHelper";
import { actions as experienceUsersActions } from "./redux";
import {
  activeAdjustedExperienceSelector,
  gradeSubmissionsWithAISelector,
} from "../Experiences/selector";
import { actions as experienceActions } from "../Experiences/redux";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { ImMagicWand } from "react-icons/im";
import { axiosInstance } from "/src/api/apiModule";
import { isExperienceUserGradingInProgressSelector } from "./selector";


const GradeAllSubmissions = () => {
  const dispatch = useDispatch();
  const mrIntl = useTranslate();

  const activeExperience = useSelector(activeAdjustedExperienceSelector());
  const gradeSubmissionsWithAI = useSelector(gradeSubmissionsWithAISelector());
  const isExperienceUserGradingInProgress = useSelector(isExperienceUserGradingInProgressSelector())

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [ungradedOnly, setUngradedOnly] = useState(false);

  const gradeAllSubmissionsWithAIStatus = gradeSubmissionsWithAI?.status;
  const isGradeAllWithAIInProgress =
    gradeAllSubmissionsWithAIStatus === "started" ||
    gradeAllSubmissionsWithAIStatus === "in_progress";

  // const shouldPoll =
    // !isEmpty(gradeSubmissionsWithAI) && isGradeAllWithAIInProgress;

  const updateGradingStatusInExperience = (newGradeAllSubmissionsWithAI) => {
    dispatch(
      experienceActions.showSuccess({
        data: {
          experience: {
            ...activeExperience,
            custom_fields: {
              ...activeExperience.custom_fields,
              grade_submissions_with_ai: newGradeAllSubmissionsWithAI,
            },
          },
        },
      })
    );
  };

  const fetchUngradedEusWithResponses = (euIds) => {

    let ungradedEuIds = euIds.filter(
      (id) => !gradeSubmissionsWithAI.eu_ids.includes(id)
    );
    
    if (ungradedEuIds.length > 0) {
      dispatch(
        experienceUsersActions.fetch(
          {
            params: {
              by_ids: ungradedEuIds,
              with_all_eus_user_responses: true,
            },
          },
          {
            mergeFetchedData: true,
          }
        )
      );
    }

  };

  const handleGradingStatusUpdate = (data, clearIntervalCallback) => {
    const updatedGradeSubmissionsWithAI = data.grade_submissions_with_ai;
    const { status, eu_ids } = updatedGradeSubmissionsWithAI;

    const progressCompleted = status === "completed" || status === "failed" || status === "cancelled";

    updateGradingStatusInExperience(updatedGradeSubmissionsWithAI);
    fetchUngradedEusWithResponses(eu_ids)

    if (progressCompleted) {
      clearIntervalCallback();
    }
  };

  const checkGradeAllSubmissionsProgress = (clearIntervalCallback) => {
    dispatch(
      experienceActions.gradeAllSubmissionsWithAiStatus(
        { exp_uuid: activeExperience.uid },
        {
          successCallback: (data) =>
            handleGradingStatusUpdate(data, clearIntervalCallback),
          errorCallback: (error) => {
            console.error(
              "Failed to check the status of grading all submissions",
              error
            );
          },
        }
      )
    );
  };

  // usePollingEffect(shouldPoll, checkGradeAllSubmissionsProgress, 10000);

  const handleGradeAll = () => {
    const gradingOptions = {
      exp_uuid: activeExperience.uid,
    };

    if (ungradedOnly) {
      gradingOptions.only_ungraded = true;
    }

    dispatch(
      experienceUsersActions.gradeSubmissionsWithAi(gradingOptions, {
        successCallback: (data) => {
          let updatedGradeSubmissionsWithAI =
            data.experience.custom_fields.grade_submissions_with_ai;
          updateGradingStatusInExperience(updatedGradeSubmissionsWithAI);
        },
        errorCallback: (error) => {
          message.error(
            mrIntl("CommonText.something_went_wrong_please_try_again")
          );
          console.log("gradeAllWithAI errorCallback", error);
        },
      })
    );
  };

  const cancelGradeAll = () => {
    const url = `${import.meta.env.VITE_API_URL}cancel_grade_submission_with_ai`;
    const params = {
      exp_uuid: activeExperience.uid,
    };
    axiosInstance.instance.post(url, params)
      .then(response => {
        let updatedGradeSubmissionsWithAI = response.data.experience.custom_fields.grade_submissions_with_ai;
        updateGradingStatusInExperience(updatedGradeSubmissionsWithAI);
        // if(updatedGradeSubmissionsWithAI.eu_ids?.length > 0) {
        //   // fetching to merge if grading of some EUs is completed on cancellation 
        //   fetchUngradedEusWithResponses(updatedGradeSubmissionsWithAI.eu_ids);
        // } else {
          // Fetching all experience users since no specific eu_ids are available, making it unclear which experience users have been updated
          dispatch(experienceUsersActions.fetch({
            params: {
              by_experience_id: activeExperience.id,
              with_all_eus_user_responses: true
            }
          }));
        // }

      })
      .catch(error => {
        console.error('Failed to cancel grade all process:', error);
        message.error(mrIntl("CommonText.something_went_wrong_please_try_again"));
      });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    handleGradeAll();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      {/* TODO: show grading progress in real time */}
      <MagicButton
        shape="default"
        text={isGradeAllWithAIInProgress ? mrIntl("CommonText.stop") :  mrIntl("ExperienceAIGradingProgress.grade_all")}
        tooltipTitle={
          isGradeAllWithAIInProgress
            ? mrIntl("GradeAllSubmissions.stop_grading_all_submissions")
            : isExperienceUserGradingInProgress 
              ? mrIntl("GradeAllSubmissions.cannot_grade_all_submissions_while_ai_is_grading_a_submission")
              : mrIntl("ExperienceAIGradingProgress.grade_all_submissions")
        }
        feature_code="grade_with_ai"
        icon={isGradeAllWithAIInProgress ? <LoadingOutlined />: null}
        onClick={isGradeAllWithAIInProgress ? cancelGradeAll : showModal}
        disabled={isExperienceUserGradingInProgress}
      />
      <Modal
        title={mrIntl("GradeAllSubmissions.grade_all_submissions")}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Grade"
        okButtonProps={{
          className: "magic-button-primary",
          icon: <ImMagicWand />,
        }}
      >
        <Radio.Group
          value={ungradedOnly ? "ungraded" : "override"}
          style={{ display: "flex", flexDirection: "column", gap: 8 }}
          onChange={(e) => {
            const value = e.target.value;
            setUngradedOnly(value === "ungraded");
          }}
        >
          <Radio value="override">
            {mrIntl("GradeAllSubmissions.all_student_responses_this_will_override_existing_points_and_comments")}
          </Radio>
          <Radio value="ungraded">{mrIntl("GradeAllSubmissions.only_ungraded_student_responses")}</Radio>
        </Radio.Group>
      </Modal>
    </>
  );
};

export default GradeAllSubmissions;
