import React, { useEffect, useState } from "react";

import { MrFormComponent, FormRenderer } from "mr_react_framework";
import { Row, Col, TimePicker, Button, Select, Space, Tooltip } from "antd";
import { message } from "/src/components/UI/AntdAppHelper";
import {
  initialData,
  removeItemFromArray,
  getParsedJSONObject,
  // segmentConfig,
} from "/src/views/Segments/InteractiveHelpers";
import {
  Segment,
  SegmentData,
  CustomInput,
  AddButton,
  DeleteButton,
  CheckBox,
} from "/src/components/UI/Segment/UIHelper";
import { checkURLExist, checkURLSource, convertTimetoObject, getAIServiceBaseURL, getFromLS, getUniqueId } from "/src/lib/utils/helperMethods";
import { cloneDeep } from "lodash";
import MediaUI from "/src/components/UI/Media/MediaUI";
import VideoView from "./VideoView";
import TabularAttachments from "/src/components/UI/Attachments/TabularAttachments";

import { useSelector } from "react-redux";
import {
  appRegionSelector,
  currentUserSelector,
  enabledFeaturesSelector,
} from "/src/views/Auth/Login/selector";

// import moment from "moment";
import {
  processForConversion,
  uploadVideoToBackend,
} from "/src/components/UI/Media/MediaHelper";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import duration from "dayjs/plugin/duration";
import CKETextContent from "../CKETextContent/CKETextContent";
import { ImMagicWand } from "react-icons/im";
import axios from "axios";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import MagicButton from "../GenerateAI/MagicButton";
import { activeAdjustedExperienceSelector } from "../../Experiences/selector";
import { updateConfigHeaderForDemoUseStagingAI } from "../GenerateAI/generateAIHelperMethods";

const { RangePicker } = TimePicker;

const updateWithConversionURLs = (state) => {
  const cdn_url = import.meta.env.VITE_CDN_URL
  const newAttachments = state.segment_data.attachments.map((atc) => {
    let updatedAtc = atc
    const urlSource = checkURLSource(atc.url)
    console.log("attachment before update ==>", atc, urlSource)
    if ((urlSource === "vimeo" || 
      urlSource === "yt" || 
      urlSource === "s3") &&
      atc.convert_status !== "processing" && 
      atc.convert_status !== "completed") {
        const file_path = `direct_uploads/attachments/${atc.id}/${atc.id}.webm`;
        let s3_url = `${cdn_url}${file_path}`;
        let webm_url = s3_url;

        // if (!cdn_url) {
        //   let index = file.url.indexOf(file.id)
        //   s3_url = file.url.substring(0, index) + `${file.id}/${file.id}.webm`
        //   // s3_url = file.url.replace(file.name, `${file.id}/${file.id}.webm`)
        // }

        updatedAtc = {
          ...atc,
          file_path: file_path,
          s3_url: s3_url,
          webm_url: webm_url,
          convert_status: "processing",
          updated_at: Date.now()
        };

    } else if (checkURLSource(atc.url) === "youku") {
      updatedAtc = {
        ...atc,
        file_path: "",
        s3_url: "",
        webm_url: "",
        convert_status: "",
        updated_at: Date.now()
      }
    }
    console.log("updatedAtc ==>", updatedAtc)
    return updatedAtc
  })

  const newState = {
    ...state, 
    segment_data: {
      ...state.segment_data, 
      attachments: newAttachments
    }
  }
  console.log("updated state ==>", newState)
  return newState
}

const VideoForm = (props) => {
  let { segment_id } = props;
  let {
    createMode,
    answerMode,
    presentationMode,
    autoCheckMode,
  } = getParsedJSONObject(props);
  let parsedJSONObject;
  console.log("VideoForm props==>", props);
  
  dayjs.extend(utc);
  dayjs.extend(duration)
  const appRegion = useSelector(appRegionSelector());
  const currentUser = useSelector(currentUserSelector());
  const enabledFeatures = useSelector(enabledFeaturesSelector());
  const experience = useSelector(activeAdjustedExperienceSelector());

  const experienceSettings = experience.settings || {};
  const defaultPlaybackLimit = 2;
  

  if (createMode) {
    parsedJSONObject = props.value || initialData("resource", 1, 4);
  } else {
    parsedJSONObject = props.resource_json;
  }

  const [state, setState] = useState(updateWithConversionURLs(parsedJSONObject));
  const [stateUpdatedCount, setStateUpdatedCount] = useState(0);
  const [showTranscriptForm, setShowTranscriptForm] = useState(false)

  const [stateSetting, setStateSetting] = useState({});

  const defaultDuration = 86399
  const [mediaShowSetting, setMediaShowSetting] = useState({
    start: 0,
    start_time: "00:00:00",
    duration: defaultDuration,
    end_time: "23:59:59",
    segment_id: segment_id,
    custom_player: !createMode,
    createMode: createMode,
    showProcessingError: experienceSettings.mode === "offline"
  });  

  console.log("VideoForm mediaShowSetting and setMediaShowSetting ==>", mediaShowSetting, setMediaShowSetting);

  mediaShowSetting.onChange = (attachment) => {
    const newAttachments = [attachment]
    const newSegmentData = {
      ...segment_data,
      attachments: newAttachments,
    };

    const newState = {
      ...state,
      segment_data: newSegmentData,
    };

    saveState(newState);
  }

  const allowLargeFiles = enabledFeatures.allow_large_files
  const mrIntl = useTranslate()
  const mediaFormInitialState = {
    modal: false,
    loading: false,
    visible: false,
    title: mrIntl("CommonText.insert_video_title"),
    okText: mrIntl("CommonText.add_video_button"),
    cancelText: mrIntl("CommonText.cancel"),
    triggerSaveAction: 0,
    requireFooter: false,
    content_type: "video",
    uploadOnOk: true,
    disableUploadURLToS3: true,
    limit: 1,
    supportedTypes: appRegion == "china" ? "video/mp4" : "video/mp4, video/avi, video/flv, video/webm, video/wmv, video/quicktime, video/x-msvideo, video/x-flv, video/x-ms-wmv",
    supportedFormats: appRegion == "china" ? "mp4" : "mp4, avi, mov, flv, webm, wmv",
    // supportedURLs: "Only urls from youtube, vimeo and youku can be used here",
    supportedURLs: mrIntl("CommonText.support_url_from_youtube_only_message"),
    selectConfig: {
      multiple: false,
      // disabledButtonTooltip: currentUser.org_id != 99 && 'This feature is temporarily disabled. We working to enable this as soon as possible. In the mean time, you can upload any video directly to youtube and use the url here.',
      maxSize: allowLargeFiles ? "500 MB" : "100 MB",
      showUploadList: {
        showRemoveIcon: true,
      },
      uploadFile: appRegion == "china" ? null : uploadVideoToBackend,
      extra_data: {
        item_type: "Segment",
        item_id: segment_id,
      },
    },
    beforeLoadURL: (data) => {
      let updatedURL =
        data.match(/(https:\/\/\S*youtu\S*)\w+/g) ||
        // data.match(/(https:\/\/\S*vimeo\S*)\w+/g) ||
        data.match(/(https:\/\/\S*youku\S*)\w+/g);
      return updatedURL ? updatedURL[0] : updatedURL;
    },
  };

  // if (appRegion == "china") {
  //   mediaFormInitialState.selectConfig.uploadFile = null;
  // }
  const [mediaFormSetting, setMediaFormSetting] = useState(
    mediaFormInitialState
  );

  let currentJSON;
  if (presentationMode) {
    currentJSON = parsedJSONObject;
  } else {
    currentJSON = state;
  }
  const segment_data = currentJSON?.segment_data || {};
  const resource_setting = currentJSON.resource_setting || {};
  const attachments = segment_data.attachments || [];
  const attachmentsCount = attachments.length;
  const attachment = attachments[0] || {};
  const showTranscriptAIButton = (enabledFeatures.allow_generate_transcript || import.meta.env.VITE_MODE === "development") && checkURLSource(attachment.url) === "yt" && attachment.include_transcript

  useEffect(() => {
    if (attachment.include_transcript) {
      setShowTranscriptForm(true);
    } else {
      setShowTranscriptForm(false);
    }
  }, [attachment.include_transcript])

  const { Option } = Select;
  
  let timeFormat = "HH:mm:ss";
  let defaultSnippets = [
    {
      start: 0,
      start_time: "00:00:00",
      end: mediaShowSetting.duration,
      end_time: convertSecondsToTimeFormat(mediaShowSetting.duration),
    },
  ]; 

  console.log("defaultSnippets ===>", defaultSnippets);

  useEffect(() => {
    setMediaFormSetting({
      ...mediaFormSetting,
      modal: attachmentsCount > 0,
      visible: false,
    });
  }, [attachmentsCount]);

  useEffect(() => {
    if (createMode) {
      if (props.onChange) {
        let resource_json = cloneDeep(state);
        props.onChange(resource_json);
      }
    }
  }, [stateUpdatedCount]);

  const saveState = (state) => {
    setState(updateWithConversionURLs(state));
    setStateUpdatedCount((stateUpdatedCount) => stateUpdatedCount + 1);
  };

  const onTabDragEnd = (attachments) => {
    const newAttachments = attachments.slice();

    const newSegmentData = {
      ...segment_data,
      attachments: newAttachments,
    };

    const newState = {
      ...state,
      segment_data: newSegmentData,
    };

    saveState(newState);
  };

  const removeItem = (index) => {
    const extra = {
      noMinSize: true,
    };
    // removeVideoFromVimeo(attachments[index])
    // const newAttachments = attachments
    const newAttachments = removeItemFromArray(attachments, index, extra);
    const newSegmentData = {
      ...segment_data,
      attachments: newAttachments,
    };

    const newState = {
      ...state,
      segment_data: newSegmentData,
    };
    saveState(newState);
  };

  const removeFile = (index) => {
    const newAttachments = attachments;
    newAttachments[index] = { ...newAttachments[index], files: [] };

    const newSegmentData = {
      ...segment_data,
      attachments: newAttachments,
    };

    const newState = {
      ...state,
      segment_data: newSegmentData,
    };

    saveState(newState);
  };

  // const removeVideoFromVimeo = (attachment) => {
  // 	const videoId = attachment.id
  // 	fetch(`https://api.vimeo.com/me/videos/${videoId}`, {
  // 		method: "DELETE",
  // 		headers: {
  // 			Accept: "application/vnd.vimeo.*+json;version=3.4",
  // 			"Content-Type": "application/json",
  // 			Authorization: "bearer " + accessToken,
  // 			"Access-Control-Allow-Methods": "DELETE"
  // 		},
  // 	}).then((response) => {
  // 		console.log("Video deleted now ==>", response)
  // 	})
  // }

  const onSaveMedia = (files) => {
    let newAttachments = attachments.concat(files);

    const limit = mediaFormSetting.limit;
    if (limit) {
      newAttachments = newAttachments.slice(-limit);
    }

    const newSegmentData = {
      ...segment_data,
      attachments: newAttachments,
    };

    const newState = {
      ...state,
      segment_data: newSegmentData,
    };

    console.log("ON save Video ==>", newAttachments, newState);

    saveState(newState);
    setMediaFormSetting({
      ...mediaFormSetting,
      from: "",
      modal: false,
      visible: false,
      loading: false,
      triggerSaveAction: 0,
    });
  };
  
  const onTextChanged = (type, index, data) => {
    const newAttachments = attachments;
    newAttachments[index][type] = data;

    const newSegmentData = {
      ...segment_data,
      attachments: newAttachments,
    };

    const newState = {
      ...state,
      segment_data: newSegmentData,
    };
    
    console.log("onchange is getting fired ==>", newState, data);
    saveState(newState);
  };

  const setResourceSetting = (key, value) => {
    const newResourceSetting = {
      ...resource_setting,
      [key]: value,
    };

    const newState = {
      ...state,
      resource_setting: newResourceSetting,
    };

    saveState(newState);
  };

  const onTabEdit = (index, action) => {
    if (action == "add") {
      setMediaFormSetting({
        ...mediaFormSetting,
        from: "computer",
        modal: true,
        visible: true,
      });
    } else if (action == "remove") {
      removeItem(index);
    }
  };

  const handleTabPositionChange = (e) => {
    setResourceSetting("tab_position", e.target.value);
  };
  const handleViewTypeChange = (e) => {
    setResourceSetting("view_type", e.target.value);
  };

  const getMediaForm = () => {
    return (
      <MediaUI
        computer={true}
        // computer={appRegion !== "china" || (appRegion == "china" && parseInt(currentUser.org_id) == 85)}
        url={true}
        mediaFormSetting={mediaFormSetting}
        setMediaFormSetting={setMediaFormSetting}
        onSaveMedia={(files) => onSaveMedia(files)}
      ></MediaUI>
    );
  };

  function convertSecondsToTimeFormat(seconds) {
    // return moment.utc(seconds * 1000).format(timeFormat);
    console.log("convertSecondsToTimeFormat ==>", seconds, dayjs.utc(seconds * 1000).format(timeFormat))
    return dayjs.utc(seconds * 1000).format(timeFormat);
  }
  
  const saveSnippet = (type, index, time, timeString) => {
    const newAttachments = attachments;
    let snippets = newAttachments[0]["snippets"] || cloneDeep(defaultSnippets);

    if (type == "start_time") {
      let start_time = timeString;
      let end_time = snippets[index]["end_time"];
      // let startTimeInSeconds = moment.duration(start_time).asSeconds();
      // let endTimeInSeconds = moment.duration(end_time).asSeconds();

      let startTimeInSeconds = dayjs.duration(convertTimetoObject(start_time)).asSeconds();
      let endTimeInSeconds = dayjs.duration(convertTimetoObject(end_time)).asSeconds();

      snippets[index]["start"] = startTimeInSeconds;
      snippets[index]["start_time"] = timeString;
      if (startTimeInSeconds >= endTimeInSeconds) {
        snippets[index]["start"] = endTimeInSeconds - 1;
        snippets[index]["start_time"] = convertSecondsToTimeFormat(
          endTimeInSeconds - 1
        );

        message.warning(mrIntl("VideoForm.start_time_should_be_less_than_end_time"));
      }

      let last_snippet = snippets[index - 1];
      if (last_snippet) {
        // let lastEndTimeInSeconds = moment
        //   .duration(last_snippet["end_time"])
        //   .asSeconds();
        let lastEndTimeInSeconds = dayjs
          .duration(convertTimetoObject(last_snippet["end_time"]))
          .asSeconds();

        if (lastEndTimeInSeconds >= startTimeInSeconds) {
          snippets[index]["start"] = lastEndTimeInSeconds + 1;
          snippets[index]["start_time"] = convertSecondsToTimeFormat(
            lastEndTimeInSeconds + 1
          );

          message.warning(
            mrIntl("VideoForm.start_time_should_be_greater_than_end_time")
          );
        }
      }
    }

    if (type == "end_time") {
      let start_time = snippets[index]["start_time"];
      let end_time = timeString;
      // let startTimeInSeconds = moment.duration(start_time).asSeconds();
      // let endTimeInSeconds = moment.duration(end_time).asSeconds();
      let startTimeInSeconds = dayjs.duration(convertTimetoObject(start_time)).asSeconds();
      let endTimeInSeconds = dayjs.duration(convertTimetoObject(end_time)).asSeconds();
      
      snippets[index]["end"] = endTimeInSeconds;
      snippets[index]["end_time"] = end_time;
      if (startTimeInSeconds >= endTimeInSeconds) {
        snippets[index]["end"] = startTimeInSeconds + 1;
        snippets[index]["end_time"] = convertSecondsToTimeFormat(
          startTimeInSeconds + 1
        );

        message.warning(mrIntl("VideoForm.end_time_should_be_greater_than_start_time_msg"));
      }

      let next_snippet = snippets[index + 1];
      if (next_snippet) {
        let nextStartTimeInSeconds = next_snippet["start"];

        if (endTimeInSeconds >= nextStartTimeInSeconds) {
          snippets[index]["end"] = nextStartTimeInSeconds - 1;
          snippets[index]["end_time"] = convertSecondsToTimeFormat(
            nextStartTimeInSeconds - 1
          );

          message.warning(
            mrIntl("VideoForm.end_time_should_be_less_than_of_start_time")
          );
        }
      } else {
        let totalDuration = defaultSnippets[0].end;
        console.log(
          "end time in seconds vs totalDuration ==>",
          endTimeInSeconds,
          totalDuration
        );
        if (endTimeInSeconds > totalDuration) {
          snippets[index]["end"] = totalDuration;
          snippets[index]["end_time"] = defaultSnippets[0]["end_time"];

          message.warning(mrIntl("VideoForm.end_time_can_not_be_greater_than_total_duration"));
        }
      }
    }

    console.log("updated snippet value ==>", snippets[index]);
    newAttachments[0]["snippets"] = snippets;
    const newSegmentData = {
      ...segment_data,
      attachments: newAttachments,
    };

    const newState = {
      ...state,
      segment_data: newSegmentData,
    };

    saveState(newState);
  };    

  const changePlaybackLimit = (value) => {
    const newAttachments = attachments;
    newAttachments[0]["playback_limit"] = value;

    const newSegmentData = {
      ...segment_data,
      attachments: newAttachments,      
    };
    const newState = {
      ...state,
      segment_data: newSegmentData,
    };
    saveState(newState);
  };  
  

  const togglePlaybackLimit = () => {
    const newAttachments = attachments;
    newAttachments[0]["playback_limit_enabled"] = !newAttachments[0]["playback_limit_enabled"];
    if (newAttachments[0]["playback_limit_enabled"]) {
      newAttachments[0]["playback_limit"] = defaultPlaybackLimit;
    }

    const newSegmentData = {
      ...segment_data,
      attachments: newAttachments,      
    };

    const newState = {
      ...state,
      segment_data: newSegmentData,
    };
    saveState(newState);
  }; 

  const toggleIncludeTranscript = (value) => {
    console.log('toggleIncludeTranscript==>', value)
    const newAttachments = attachments;

    newAttachments[0]["include_transcript"] = value;
    if (!value) {
      if (newAttachments[0]["transcript"]) {
        newAttachments[0]["transcript"] = ""
      }
    }
    const newSegmentData = {
      ...segment_data,
      attachments: newAttachments,
    };
    const newState = {
      ...state,
      segment_data: newSegmentData,
    };
    saveState(newState);
    setShowTranscriptForm(true);
  };

    
  const toggleSnippet = () => {
    const newAttachments = attachments;

    newAttachments[0]["using_snippets"] = !newAttachments[0]["using_snippets"];
    newAttachments[0]["snippets"] = defaultSnippets;
    const newSegmentData = {
      ...segment_data,
      attachments: newAttachments,
    };

    const newState = {
      ...state,
      segment_data: newSegmentData,
    };

    saveState(newState);
  };

  const addSnippet = () => {
    const newAttachments = attachments;
    let snippets = newAttachments[0]["snippets"] || cloneDeep(defaultSnippets);
    let lastSnippet = snippets[snippets.length - 1];
    console.log("lastSnippet ==>", lastSnippet, snippets);

    let lastMargin = defaultSnippets[0].end - lastSnippet.end;
    let margin = 30 < lastMargin ? 30 : lastMargin;

    console.log("last margin ==>", lastMargin, margin);
    if (lastMargin <= 0) {
      message.warning(mrIntl("VideoForm.total_duration_is_already_covered"));
      return;
    }

    snippets.push({
      id: getUniqueId(),
      start: lastSnippet.end + 1,
      end: lastSnippet.end + margin,
      start_time: convertSecondsToTimeFormat(lastSnippet.end + 1),
      end_time: convertSecondsToTimeFormat(lastSnippet.end + margin),
    });

    console.log("updated snippets ==>", snippets);

    newAttachments[0]["snippets"] = snippets;
    const newSegmentData = {
      ...segment_data,
      attachments: newAttachments,
    };

    const newState = {
      ...state,
      segment_data: newSegmentData,
    };

    saveState(newState);
  };

  const removeSnippet = (index) => {
    const newAttachments = attachments;
    let snippets = newAttachments[0]["snippets"] || [];
    snippets.splice(index, 1);

    newAttachments[0]["snippets"] = snippets;
    const newSegmentData = {
      ...segment_data,
      attachments: newAttachments,
    };

    const newState = {
      ...state,
      segment_data: newSegmentData,
    };

    saveState(newState);
  };  

  const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const playBackLimitForm = () => {
    return (
      <Space className="playback-limit">
        <Select
          className="playback-limit-select"
          defaultValue="0"          
          value={attachment.playback_limit}
          onChange={(value) => changePlaybackLimit(value)}          
        >
          {numbers.map((number) => 
            <Option value={number}>{number}</Option> 
          )}
        
        </Select>
        <MrTranslate id={"VideoForm.limit_for_video_playback"}/>
      </Space>
    );
  };
  
  const transcriptForm = (props) => {
    console.log('trancsriptForm==>', props)
    return (
      <div>
        <MrTranslate id={"VideoForm.transcript"}/>
        <CKETextContent
          // ckeConfig={ckeConfig}
          value={props.transcript}
          onChange={(data) => onTextChanged('transcript', 0, data)}
        >
        </CKETextContent>
      </div>
    );
  };

  const selectSnippetsForm = (props) => {
    const { snippets = defaultSnippets } = props;

    // let start_time = moment(defaultSnippets[0].start_time, timeFormat);
    // let end_time = moment(defaultSnippets[0].end_time, timeFormat);

    // let start_time = dayjs(defaultSnippets[0].start_time, timeFormat);
    // let end_time = dayjs(defaultSnippets[0].end_time, timeFormat);

    // let total_duration_object = {
    //   start: {
    //     hours: start_time.hours(),
    //     minutes: start_time.minutes(),
    //     seconds: start_time.seconds(),
    //   },
    //   end: {
    //     hours: end_time.hours(),
    //     minutes: end_time.minutes(),
    //     seconds: end_time.seconds(),
    //   },
    // };

    return (
      <>
        {snippets.map((snippet, index) => {
          {/* let start_time = moment(snippet.start_time, timeFormat);
          let end_time = moment(snippet.end_time, timeFormat); */}

          let start_time = dayjs(snippet.start_time, timeFormat);
          let end_time = dayjs(snippet.end_time, timeFormat);          

          // let snippet_obj = {
          // 	start: {
          // 		hours: start_time.hours(),
          // 		minutes: start_time.minutes(),
          // 		seconds: start_time.seconds()
          // 	},
          // 	end: {
          // 		hours: end_time.hours(),
          // 		minutes: end_time.minutes(),
          // 		seconds: end_time.seconds()
          // 	}
          // }
          // // let startTimeInSeconds = moment.duration(start_time).asSeconds()
          // // let endTimeInSeconds = moment.duration(end_time).asSeconds()
          // const lastSnippet = snippets[index - 1]
          // let last_start_time = moment('00:00:00', timeFormat)
          // let last_end_time = moment('00:00:01', timeFormat)
          // if (lastSnippet) {
          // 	last_start_time = moment(lastSnippet.start_time, timeFormat)
          // 	last_end_time = moment(lastSnippet.end_time, timeFormat)
          // }

          // let last_snippet_obj = {
          // 	start: {
          // 		hours: last_start_time.hours(),
          // 		minutes: last_start_time.minutes(),
          // 		seconds: last_start_time.seconds()
          // 	},
          // 	end: {
          // 		hours: last_end_time.hours(),
          // 		minutes: last_end_time.minutes(),
          // 		seconds: last_end_time.seconds()
          // 	}
          // }

          // console.log("last snippet ==>", last_start_time, last_end_time)

          // const nextSnippet = snippets[index + 1]
          // let next_start_time = moment(moment.utc((mediaShowSetting.duration - 1) * 1000).format(timeFormat), timeFormat)
          // let next_end_time = moment(moment.utc(mediaShowSetting.duration * 1000).format(timeFormat), timeFormat)
          // if (nextSnippet) {
          // 	next_start_time = moment(nextSnippet.start_time, timeFormat)
          // 	next_end_time = moment(nextSnippet.end_time, timeFormat)
          // }

          // let next_snippet_obj = {
          // 	start: {
          // 		hours: next_start_time.hours(),
          // 		minutes: next_start_time.minutes(),
          // 		seconds: next_start_time.seconds()
          // 	},
          // 	end: {
          // 		hours: next_end_time.hours(),
          // 		minutes: next_end_time.minutes(),
          // 		seconds: next_end_time.seconds()
          // 	}
          // }

          // console.log("snippet objects ==>", snippet_obj, last_snippet_obj, next_snippet_obj)
          // console.log("start time and end time =>", startTimeInSeconds, endTimeInSeconds)
          console.log(
            "Media form setting duration ==>",
            mediaShowSetting.duration
          );
          return (
            <Row key={`time-snippet-${snippet.id}`}>
              <Col span={4}>Snippet {index + 1}</Col>
              <Col span={6}>
                <TimePicker
                  allowClear={false}
                  showNow={false}
                  needConfirm={false}
                  value={start_time}
                  disabled={mediaShowSetting.duration === defaultDuration}
                  // disabledHours={() => {
                  // 	let hours = last_snippet_obj.end.hours
                  // 	let hrs = []
                  // 	for (let i = 0; i < hours; i++) {
                  // 		hrs.push(i)
                  // 	}

                  // 	let end_hours = snippet_obj.end.hours
                  // 	for (let i = end_hours + 1; i < 24; i++) {
                  // 		hrs.push(i)
                  // 	}
                  // 	return hrs
                  // }}
                  // disabledMinutes={(h) => {
                  // 	let minutes = last_snippet_obj.end.minutes
                  // 	let mnts = []
                  // 	if (last_snippet_obj.end.hours >= snippet_obj.start.hours) {
                  // 		for (let i = 0; i < minutes; i++) {
                  // 			mnts.push(i)
                  // 		}
                  // 	}

                  // 	if (snippet_obj.start.hours >= snippet_obj.end.hours) {
                  // 		let end_minutes = snippet_obj.end.minutes
                  // 		for (let i = end_minutes + 1; i <= 60; i++) {
                  // 			mnts.push(i)
                  // 		}
                  // 	}
                  // 	return mnts
                  // }}
                  // disabledSeconds={(h, m) => {
                  // 	let seconds = last_snippet_obj.end.seconds
                  // 	let secs = []
                  // 	if (last_snippet_obj.end.hours >= snippet_obj.start.hours && last_snippet_obj.end.minutes >= snippet_obj.start.minutes) {
                  // 		for (let i = 0; i <= seconds; i++) {
                  // 			secs.push(i)
                  // 		}
                  // 	}

                  // 	if (snippet_obj.start.hours >= snippet_obj.end.hours && snippet_obj.start.minutes >= snippet_obj.end.minutes) {
                  // 		let end_seconds = snippet_obj.end.seconds
                  // 		for (let i = end_seconds + 2; i <= 60; i++) {
                  // 			secs.push(i)
                  // 		}
                  // 	}
                  // 	return secs
                  // }}
                  hideDisabledOptions={true}
                  onChange={(time, timeString) =>
                    saveSnippet("start_time", index, time, timeString)
                  }
                ></TimePicker>
                {/* Starts at
									<CustomInput
										type='number'
										maxLength={50}
										defaultValue={snippet.start}
										onChange={(data) => saveSnippet('start', index, data)}
									></CustomInput> */}
              </Col>
              <Col span={6} offset={1}>
                <TimePicker
                  allowClear={false}
                  showNow={false}
                  needConfirm={false}
                  value={end_time}
                  disabled={mediaShowSetting.duration === defaultDuration}
                  // disabledHours={() => {
                  // 	let start_hours = snippet_obj.start.hours
                  // 	let hrs = []
                  // 	for (let i = 0; i < start_hours; i++) {
                  // 		hrs.push(i)
                  // 	}

                  // 	let end_hours = next_snippet_obj.start.hours
                  // 	for (let i = end_hours + 1; i < 24; i++) {
                  // 		hrs.push(i)
                  // 	}
                  // 	return hrs
                  // }}
                  // disabledMinutes={(h) => {
                  // 	let start_minutes = snippet_obj.start.minutes
                  // 	let mnts = []
                  // 	if (snippet_obj.start.hours >= snippet_obj.end.hours) {
                  // 		for (let i = 0; i < start_minutes; i++) {
                  // 			mnts.push(i)
                  // 		}
                  // 	}

                  // 	if (snippet_obj.end.hours >= next_snippet_obj.start.hours) {
                  // 		let end_minutes = next_snippet_obj.start.minutes
                  // 		for (let i = end_minutes + 1; i <= 60; i++) {
                  // 			mnts.push(i)
                  // 		}
                  // 	}
                  // 	return mnts
                  // }}
                  // disabledSeconds={(h, m) => {
                  // 	let start_seconds = snippet_obj.start.seconds
                  // 	let secs = []

                  // 	if (snippet_obj.start.hours >= snippet_obj.end.hours && snippet_obj.start.minutes >= snippet_obj.end.minutes) {
                  // 		for (let i = 0; i <= start_seconds; i++) {
                  // 			secs.push(i)
                  // 		}
                  // 	}

                  // 	if (snippet_obj.end.hours >= next_snippet_obj.start.hours && snippet_obj.end.minutes >= next_snippet_obj.start.minutes) {
                  // 		let end_seconds = next_snippet_obj.start.seconds
                  // 		for (let i = end_seconds + 2; i <= 60; i++) {
                  // 			secs.push(i)
                  // 		}
                  // 	}
                  // 	return secs
                  // }}
                  hideDisabledOptions={true}
                  onChange={(time, timeString) =>
                    saveSnippet("end_time", index, time, timeString)
                  }
                ></TimePicker>
                {/* Ends at
									<CustomInput
										type='number'
										maxLength={50}
										defaultValue={snippet.end}
										onChange={(data) => saveSnippet('end', index, data)}
									></CustomInput> */}
              </Col>
              <Col span={2}>
                {snippets.length > 1 && (
                  <DeleteButton
                    clicked={(e) => removeSnippet(index)}
                  ></DeleteButton>
                )}
              </Col>
            </Row>
          );
        })}
        {snippets.length < 10 && <AddButton clicked={(e) => addSnippet()} />}
      </>
    );
  };

  console.log(
    "Video form state and stateSetting before render ==>",
    state,
    stateSetting
  );

  const [transcriptLoading, setTranscriptLoading] = useState(false);

  const generateTranscript = async () => {
    setTranscriptLoading(true)
    const token = getFromLS("token");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    config = updateConfigHeaderForDemoUseStagingAI(config)
    try {
      await axios.post(`${getAIServiceBaseURL()}/transcribe`, {
        "video_url": attachments[0].url,
        "timestamp": false
      }, config)
        .then(function (response) {
          setTranscriptLoading(false)
          console.log('generateTranscript==>', response);
          const data = response.data.text;
          if (data) {
            onTextChanged('transcript', 0, data)
            setShowTranscriptForm(false)
            let timeout = setTimeout(() => { setShowTranscriptForm(true); clearTimeout(timeout) }, 1)
          }
        })
        .catch(function (error) {
          setTranscriptLoading(false)
          console.log(error);
        });

    } catch (error) {
      console.error(error);
    }
  }

  
  return (
    <Segment>
      {/* {attachmentsCount > 0 && (
        <Space>
        <InputNumber
          size="small"
          min={0}
          max={10}
          value={attachments[0].playback_limit || 0}
          onChange={(value) => changePlaybackLimit(value)}
        />
        Limit for video playback</Space>)} */}
      <SegmentData>
        {createMode && getMediaForm()}
        {attachmentsCount > 0 && (
          <div>
            {createMode ? (
              <TabularAttachments
                createMode={createMode}
                hideAdd={mediaFormSetting.limit == 1}
                attachmentType="video"
                tabPrefix={mrIntl("CommonText.video")}
                titleRequired={false}
                attachments={attachments}
                resource_setting={resource_setting}
                onTabDragEnd={onTabDragEnd}
                onTabEdit={onTabEdit}
                onTextChanged={onTextChanged}
                mediaShowSetting={mediaShowSetting}
                setMediaShowSetting={setMediaShowSetting}
              ></TabularAttachments>
            ) : (
              <VideoView
                resource_json={state}
                mediaShowSetting={mediaShowSetting}
                setMediaShowSetting={setMediaShowSetting}
              ></VideoView>
            )}
          </div>
        )}

        {createMode &&
          attachmentsCount > 0 &&
            <div>
              <div>
                <CheckBox
                  checked={attachment.using_snippets}
                  onClick={(e) => toggleSnippet()}
                  disabled={mediaShowSetting.duration === defaultDuration}
                >
                  <MrTranslate id={"VideoForm.use_snippets"}/>
                </CheckBox>
                {attachment.using_snippets &&
                  selectSnippetsForm(attachment)}
              </div>

              {(import.meta.env.VITE_MODE === "development" ||
                import.meta.env.VITE_MODE === "staging") && 
                <div>
                  <CheckBox
                    checked={attachment.playback_limit_enabled}
                    onClick={(e) => togglePlaybackLimit()}
                  >
                    <MrTranslate id={"VideoForm.limit_play_checkbox"}/>
                  </CheckBox>
                  {attachment.playback_limit_enabled && 
                    playBackLimitForm(attachment)}
                </div>
              }

              <div>
                <CheckBox
                  checked={attachment.include_transcript}
                  onChange={(e) => toggleIncludeTranscript(e.target.checked)}
                >
                  <MrTranslate id={"VideoForm.include_transcript_checkbox"} />
                </CheckBox>

                {/* @SHIKHAR - This should be with transcript form? */}
                {showTranscriptAIButton &&
                  <MagicButton
                    tooltipTitle={mrIntl("VideoForm.auto_generate_transcript_title")}
                    loading={transcriptLoading}
                    type="text" 
                    feature_code="allow_generate_transcript"
                    onClick={() => generateTranscript()}
                  />}
                {showTranscriptForm && transcriptForm(attachment)}
              </div>
            </div>
        }        
      </SegmentData>
    </Segment>
  );
};
export default VideoForm;
