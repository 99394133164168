import React, { useEffect, useState } from "react";
import { Select, Row, Col, Form, Input, Radio, InputNumber, Space, Switch, Button, Modal, Card, Popconfirm, Tooltip, Checkbox, Typography } from "antd";
import {MrSelect,  FormRenderer } from "mr_react_framework";
// import moment from 'moment';
import dayjs from 'dayjs';
import {actions as genericActions} from "/src/App/genericRedux";
import { GenericWidgetCreator, SelectGrade as SelectOrgGrade, SelectSubject as SelectOrgSubject, SelectProgrammeSubject, SelectProgrammeGrade, SelectProgram, SelectRubric, SelectPaperType, SelectTeachingLevel, SelectStandardCollectionContentArea, SelectStandardCollection, SelectGradeGroup, SelectStandardCollectionOnly, SelectGradingScale }  from "/src/components/AppSpecific/SelectSubject/SelectSubject"
import MrTranslate, { mrTranslate, useTranslate } from "/src/lib/MrTranslate/MrTranslate"
import { buildOptionsArr, checkMob, createUpdateSelectAttrsBeforeSubmit, findStandardsByGrade } from "/src/lib/utils/helperMethods";
import { getAssessmentTypeOptionsForForm, getExperienceModeOptionsForForm } from "../ExperienceHelperMethods";
import { useDispatch, useSelector } from "react-redux";
import { currentUserSelector, enabledFeaturesSelector } from "/src/views/Auth/Login/selector";
import { activeAdjustedExperienceSelector } from "../selector";
import { rubricsSelector } from "/src/views/Rubrics/Selector";
import RubricCriteriaPointsForm from "/src/views/Segments/RubricCriteriaPointsForm/RubricCriteriaPointsForm";
import { flatten, isEmpty } from "lodash";
import { orgProgrammesSelector } from "/src/views/OrgProgrammes/selector";
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { actions as tagActions } from '/src/views/Tags/redux.js';
import DraggableList from "/src/components/UI/CrudDnD/DraggableList";
import { contentAreaSelector, gradeGroupSelector, standardCollectionsSelector } from "/src/App/genericSelector";
import { allTagsSelector } from "../../Tags/selector";
import { orgquestionbanksSelector, questionbanksFormRequestSelector, questionbanksSelector } from "../../Questionbanks/selector";
import { programmeSubjectsSelector } from "../../ProgrammeSubjects/selector";
import { orgsSelector } from "../../Orgs/selector";

const { Text, Link } = Typography;

const segmentConfig = {
  createMode: true,
  editMode: false,
  autoCheckMode: false,
}
const CustomSwitchWidget = (props) => {
  console.log("CustomSwitchWidget props", props);
  return (
    <Space>
      <Switch {...props} /> <label>{props.placeholder}</label>
    </Space>
  );
};

const SelectQuestionbank = MrSelect({
  actions: genericActions,
  resourceName: "questionbanks",
  config: {
    url: "/questionbanks",
    // params: { key: "value" },
    processData: (data, props) => {
      return data.map((item) => {
        return { label: item.title, value: item.id };
      });
    },
    widgetConfig: {
      showSearch: false, // if false, this will either load entire data from backend on widget load or onSearch
      // TBD: If false, need to be able to search. Also if can do regular select from redux with this component, then can use same everywhere.
      // placeholder: "Select questionbank",
      placeholder: <MrTranslate id={"CommonText.select_questionbank"} />
      // mode: "multiple",
      // style: { width: "300px" },
    },
  },
});

const SelectLearningOutcomes = MrSelect({
  actions: genericActions,
  resourceName: "learningoutcomes",
  config: {
    url: "/learningoutcomes",
    // params: { key: "value" },
    processData: (data, props) => {
      return data.map((item) => {
        return { label: item.title, value: item.id };
      });
    },
    searchApi: true,
    widgetConfig: {
      showSearch: true, // if false, this will either load entire data from backend on widget load or onSearch
      // TBD: If false, need to be able to search. Also if can do regular select from redux with this component, then can use same everywhere.
      placeholder: <MrTranslate id={"ExperienceCreateForm.select_los"} />,
      mode: "multiple",
      // style: { width: "300px" },
    },
  },
});

const SelectStandardCollectionContentAreaWidget = GenericWidgetCreator((props) => {
  console.log("SelectStandardCollectionContentAreaWidget", props)
  let params = {}
  const currentUser = useSelector(currentUserSelector())
  let subjectId = props.formInstance.getFieldValue(["relation_items", "0", "subject_id"])
  params = { by_subject_id: subjectId }
  if (currentUser.role === "qb_author") {
    params = { by_programme_subject_id: subjectId }
  }
  return {config: {forceReload: true, params: params}}
}, SelectStandardCollectionContentArea);

const getStandardCollectionContentAreaField = (sourceValues, mrIntl) => {
  console.log("getStandardCollectionContentAreaField sourceValues", sourceValues)
  return {
    type: "object",
    properties: {
      standard_collection_content_area_tag_ids: {
        type: "string",
        // title: "Standard content area",
        placeholder: "Standard content area",
        widget: SelectStandardCollectionContentAreaWidget,
        widgetConfig: {
          disabled: sourceValues.standard_collection_content_area_tag_ids
        },
        // rules: [
        //   {
        //     required: true,
        //     message: mrIntl("CommonText.required_msg"),
        //   },
        // ],
        setInitialValue: true,
      },
    }
  }
};

const SelectStandardCollectionContentAreaChildComponent = ({getFieldValue, sourceValues, formInstance, setFieldsValue}) => {
  console.log("SelectStandardCollectionContentAreaChildComponent sourceValues", sourceValues);
  const subjectId = getFieldValue(["relation_items", "0", "subject_id"]);
  const programmeId = getFieldValue(["relation_items", "0", "org_programme_id"]);
  const allProgrammes = useSelector(orgProgrammesSelector())
  const currentUser = useSelector(currentUserSelector())
  const currentProgramme = allProgrammes.find(prog => prog.id === parseInt(programmeId)) || {}
  const allOrgSubjects = currentProgramme.subjects || []
  const currentSubject = allOrgSubjects.find(sub => sub.id === parseInt(subjectId)) || {}
  const allowedSubjects = ["Biology", "Chemistry", "Physics"]
  
  let finalRender = null;
  const mrIntl = useTranslate()

  if ((currentUser.role === "qb_author" && subjectId) || (currentProgramme && currentProgramme.code === "diploma" && allowedSubjects.includes(currentSubject.name))) {
    if (!sourceValues.standard_collection_content_area_tag_ids || sourceValues.standard_collection_content_area_tag_ids) {
      finalRender = <FormRenderer
        sourceValues={sourceValues}
        formInstance={formInstance}
        formObj={getStandardCollectionContentAreaField(sourceValues, mrIntl)}
        propertyKey={("relation_items", "0")}
      />
    }
  }
  return finalRender;
}

const SelectStandardCollectionWidget = GenericWidgetCreator((props) => {
  console.log("SelectStandardCollectionWidget", props)
  let params = {}
  const currentUser = useSelector(currentUserSelector())
  let subjectId = props.formInstance.getFieldValue(["relation_items", "0", "subject_id"])
  // params = { by_subject_id: subjectId }
  // if (currentUser.role === "qb_author") {
  //   params = { by_programme_subject_id: subjectId }
  // }
  return {config: {forceReload: true, params: params}}
}, SelectStandardCollection);

const getStandardCollectionField = (sourceValues, mrIntl) => {
  console.log("getStandardCollectionField sourceValues", sourceValues)
  return {
    type: "object",
    properties: {
      standard_collection_content_area_tag_ids: {
        type: "string",
        // title: "Standard content area",
        placeholder: "Standard content area",
        widget: SelectStandardCollectionWidget,
        widgetConfig: {
          disabled: sourceValues.standard_collection_content_area_tag_ids
        },
        // rules: [
        //   {
        //     required: true,
        //     message: mrIntl("CommonText.required_msg"),
        //   },
        // ],
        setInitialValue: true,
      },
    }
  }
};

const SelectStandardCollectionChildComponent = ({getFieldValue, sourceValues, formInstance, setFieldsValue}) => {
  console.log("SelectStandardCollectionChildComponent sourceValues", sourceValues);
  const subjectId = getFieldValue(["relation_items", "0", "subject_id"]);
  const programmeId = getFieldValue(["relation_items", "0", "org_programme_id"]);
  const allProgrammes = useSelector(orgProgrammesSelector())
  const currentUser = useSelector(currentUserSelector())
  // const currentProgramme = allProgrammes.find(prog => prog.id === parseInt(programmeId)) || {}
  // const allOrgSubjects = currentProgramme.subjects || []
  // const currentSubject = allOrgSubjects.find(sub => sub.id === parseInt(subjectId)) || {}
  // const allowedSubjects = ["Biology", "Chemistry", "Physics"]
  
  let finalRender = null;
  const mrIntl = useTranslate()

  if (!sourceValues.standard_collection_content_area_tag_ids || sourceValues.standard_collection_content_area_tag_ids) {
    finalRender = <FormRenderer
      sourceValues={sourceValues}
      formInstance={formInstance}
      formObj={getStandardCollectionField(sourceValues, mrIntl)}
      propertyKey={("relation_items", "0")}
    />
  }
  return finalRender;
}


const SelectRubricWidget = GenericWidgetCreator((props) => {
  console.log("SelectRubricWidget", props)
  const enabledFeatures = useSelector(enabledFeaturesSelector())
  let allowRubricChange = enabledFeatures.allow_rubric_change
  let hideCommentsOnlyRubric = false
  if (allowRubricChange) {
    hideCommentsOnlyRubric = (props.expRubricType === "myp_achievement_level" || props.expRubricType === "criteria_with_points") ? true : false
  }
  let params = {}
  let subjectId = props.formInstance.getFieldValue(["relation_items", "0", "subject_id"])
  params = {by_subject_id: subjectId }
  if(hideCommentsOnlyRubric){
    params.by_type_c_not = "comments_only"
  }
  return {config: {forceReload: true, params: params}}
}, SelectRubric);


const getRubricField = (sourceValues, mrIntl, expRubricType) => {
  console.log("getRubricField sourceValues", sourceValues)
  return {
    type: "object",
    properties: {
      rubric_id: {
        type: "string",
        title: mrIntl("ExperiencesCreateForm.rubric"),
        // title: "Rubric [Cannot be changed later]",
        placeholder: mrIntl("CommonText.select_rubric"),
        // placeholder: "Select rubric",
        widget: SelectRubricWidget,
        widgetConfig: {
          disabled: sourceValues.rubric_id && (expRubricType !== "myp_achievement_level" && expRubricType !== "criteria_with_points"),
          expRubricType: expRubricType
        },
        rules: [
          {
            required: true,
            message: mrIntl("CommonText.required_msg"),
          },
        ],
        setInitialValue: true,
      },
    }
  }
};

const RubricChildComponent = ({getFieldValue, sourceValues, formInstance, setFieldsValue}) => {
  console.log("RubricChildComponent sourceValues", sourceValues);
  const subjectId = getFieldValue(["relation_items", "0", "subject_id"]);
  const expRubricType = sourceValues && sourceValues.rubric && sourceValues.rubric.type_c
  
  let finalRender = null;
  const mrIntl = useTranslate()
  const enabledFeatures = useSelector(enabledFeaturesSelector())

  useEffect(() => {
    if(!subjectId){
      setFieldsValue({rubric_id: null})
    }
  }, [subjectId,setFieldsValue])
  if (subjectId){
    if (!sourceValues.rubric_id || (sourceValues.rubric_id && enabledFeatures.allow_rubric_change && (expRubricType === "myp_achievement_level" || expRubricType === "criteria_with_points"))) {
      finalRender = <FormRenderer
        // formObj={getSelectOrgSubjectField(sourceValues)}
        sourceValues={sourceValues}
        formInstance={formInstance}
        formObj={getRubricField(sourceValues, mrIntl, expRubricType)}
        // propertyKey={"settings"}
      />
    } else {
      finalRender = <Col style={{"marginBottom":"20px"}} span={6}>
        <Space direction="vertical">
          <span>{mrIntl("CommonText.selected_rubric")}: <b>{sourceValues.rubric.title}</b></span>
          {/* {sourceValues.rubric.criteria && <span>Criteria: <b>{JSON.stringify(sourceValues.rubric.criteria)}</b></span>} */}
        </Space>
      </Col>
      // finalRender = ""
    }
    // Done if rubric_id exists then dont show dropdown, just show simple show 
  }
  return finalRender;
}

const SelectGradingScaleChildComponent = ({getFieldValue, sourceValues, formInstance, setFieldsValue}) => {
  const enablesStandardsBasedGrading = getFieldValue(["enable_standards_based_grading"])
  console.log("SelectGradingScaleChildComponent sourceValues", enablesStandardsBasedGrading);
  const allRubrics = useSelector(rubricsSelector())
  const rubricId = getFieldValue(["rubric_id"])
  const currentUser = useSelector(currentUserSelector())
  const currentOrg = currentUser.org
  console.log("currentOrg ====>", currentOrg);
  let rubric = {}
  if(allRubrics.length > 0){
    rubric = allRubrics.find(r => r.id === parseInt(rubricId))
  } else {
    rubric = sourceValues.rubric
  }

  const gradingScalesAttributes = currentOrg.grading_scales_attributes || []
  let finalRender = null

  if(enablesStandardsBasedGrading && rubricId && rubric?.type_c === "points") {
    finalRender = <FormRenderer
      formObj={{
        type: "object",
        properties: {
          grading_scale_id: {
            type: "string",
            placeholder: "Grading scale",
            widget: SelectGradingScale,
            widgetConfig: {
              config: {
                widgetConfig: {
                  options: gradingScalesAttributes
                }
              }
            }
          },
        }
      }}
      sourceValues={sourceValues}
    />
  }
  return finalRender
}

const CheckboxChildComponent = ({getFieldValue, sourceValues, formInstance, setFieldsValue}) => {
  const rubricId = getFieldValue(["rubric_id"])
  let finalRender = null
  const allRubrics = useSelector(rubricsSelector())
  let rubric = {}
  if(allRubrics.length > 0){
    rubric = allRubrics.find(r => r.id === parseInt(rubricId))
  } else {
    rubric = sourceValues.rubric
  }

  if(rubricId && rubric?.type_c === "points") {
    finalRender = <FormRenderer
      formObj={{
        type: "object",
        properties: {
          enable_standards_based_grading: {
            type: "string",
            valuePropName: "checked",
            widget: "CheckboxWidget",
            widgetConfig: {
              children: "Enable standards based grading",
            }
          },
        }
      }} 
      sourceValues={sourceValues}
    />
  }
  return finalRender
}

const SelectGradeWidget = GenericWidgetCreator((props) => {
  return {config: {forceReload: true, params: {by_questionbank_id: props.formInstance.getFieldValue(["relation_items", "0", "questionbank_id"])}}}
}, SelectProgrammeGrade);


const getSelectGradeField = (sourceValues, mrIntl) => {
  return {
    type: "object",
    properties: {
      grade_id: {
        placeholder: mrIntl("CommonText.select_grade"),
        type: "string",
        span: 24,
        setInitialValue: true,
        widget: SelectGradeWidget,
        widgetConfig: {
          // disabled: sourceValues.relation_items && sourceValues.relation_items[0].grade_id ? true : false
          allowClear: false
        },
        rules: [
          {
            required: true,
            message: mrIntl("CommonText.required_msg"),
          },
        ]
      },
    }
  }
};

const GradeChildComponent = ({getFieldValue, sourceValues, formInstance}) => {
  const currentQuestionbankId = getFieldValue(["relation_items", "0", "questionbank_id"]);
  let finalRender = null;
  const mrIntl = useTranslate()
  if(currentQuestionbankId){
    finalRender = <FormRenderer
      formObj={getSelectGradeField(sourceValues, mrIntl)}
      sourceValues={sourceValues}
      formInstance={formInstance}
      propertyKey={"relation_items", "0"}
    />
  }
  return finalRender;
}

const SelectSubjectWidget = GenericWidgetCreator((props) => {
  return {config: {forceReload: true, params: {by_type_c: "group", by_questionbank_id: props.formInstance.getFieldValue(["relation_items", "0", "questionbank_id"])}}}
}, SelectProgrammeSubject);


const getSelectSubjectField = (sourceValues, mrIntl) => {
  console.log("getSelectSubjectField sourceValues", sourceValues)
  let restrictEditWithinSubjectGroup = sourceValues.relation_items && sourceValues.relation_items[0].subject_id && sourceValues.rubric.type_c !== 'points' && sourceValues.rubric.type_c !== 'comments_only' ? true : false
  return {
    type: "object",
    properties: {
      subject_id: {
        placeholder: mrIntl("CommonText.select_subject"),
        type: "string",
        span: 24,
        setInitialValue: true,
        widget: SelectSubjectWidget,
        widgetConfig: {
          // disabled: sourceValues.relation_items && sourceValues.relation_items[0].subject_id ? true : false
          // TODO
          // disabled: sourceValues.relation_items && sourceValues.relation_items[0].subject_id && sourceValues.rubric.type_c != 'points' ? true : false
          // disabled is default false now but for myp restricting edit to within the same subject group
          restrictEditWithinSubjectGroup: restrictEditWithinSubjectGroup,
          allowClear: false,
        },
        rules: [
          {
            required: true,
            message: mrIntl("CommonText.required_msg"),
          },
        ]
      },
    }
  }
};

const SubjectChildComponent = ({getFieldValue, sourceValues, formInstance}) => {
  const questionbankId = getFieldValue(["relation_items", "0", "questionbank_id"]);
  let finalRender = null;
  const mrIntl = useTranslate()
  if(questionbankId){
    finalRender = <FormRenderer
      formObj={getSelectSubjectField(sourceValues, mrIntl)}
      sourceValues={sourceValues}
      formInstance={formInstance}
      propertyKey={"relation_items", "0"}
    />
  }
  return finalRender;
}


const SelectOrgGradeWidget = GenericWidgetCreator((props) => {
  return {config: {forceReload: true, params: {by_org_programme_id: props.formInstance.getFieldValue(["relation_items", "0", "org_programme_id"])}}}
}, SelectOrgGrade);


const getSelectOrgGradeField = (mrIntl) => {
  return {
    type: "object",
    properties: {
      grade_id: {
        title: mrIntl("CommonText.select_grade"),
        // title: mrIntl("CommonText.select_grade"),
        // title: "Select grade",
        type: "string",
        span: 24,
        setInitialValue: true,
        widget: SelectOrgGradeWidget,
        widgetConfig: {
          allowClear: false
        },
        rules: [
          {
            required: true,
            message: mrIntl("CommonText.required_msg"),
          },
        ]
      },
    }
  }
};

const OrgGradeChildComponent = ({getFieldValue, sourceValues, formInstance}) => {
  const orgProgrammeId = getFieldValue(["relation_items", "0", "org_programme_id"]);
  const mrIntl = useTranslate()
  // getIndex dynamically
  let finalRender = null;
  if(orgProgrammeId){
    finalRender = <FormRenderer
      formObj={getSelectOrgGradeField(mrIntl)}
      sourceValues={sourceValues}
      formInstance={formInstance}
      propertyKey={"relation_items", "0"}
    />
  }
  return finalRender;
}

const SelectOrgSubjectWidget = GenericWidgetCreator((props) => {
  return {config: {forceReload: true, params: {unarchived: true, by_org_programme_id: props.formInstance.getFieldValue(["relation_items", "0", "org_programme_id"])}}}
}, SelectOrgSubject);


const getSelectOrgSubjectField = (sourceValues, mrIntl) => {
  console.log("getSelectOrgSubjectField sourceValues", sourceValues)
  let restrictEditWithinSubjectGroup = sourceValues.relation_items && sourceValues.relation_items[0].subject_id && sourceValues.rubric.type_c !== 'points' && sourceValues.rubric.type_c !== 'comments_only' ? true : false
  return {
    type: "object",
    properties: {
      subject_id: {
        // title: `Select subject ${restrictEditWithinSubjectGroup ? "[within same subject group]" : ""}`,
        title: restrictEditWithinSubjectGroup ? mrIntl("ExperienceCreateForm.select_subject_title") : mrIntl("CommonText.select_subject"),
        // title: `Select subject ${selectSubjectTitle}`,
        // title: "Select subject",
        type: "string",
        span: 24,
        setInitialValue: true,
        widget: SelectOrgSubjectWidget,
        widgetConfig: {
          // disabled: sourceValues.relation_items && sourceValues.relation_items[0].subject_id ? true : false
          // TODO
          // disabled: sourceValues.relation_items && sourceValues.relation_items[0].subject_id && sourceValues.rubric.type_c != 'points' ? true : false,
          // disabled is default false now but for myp restricting edit to within the same subject group
          restrictEditWithinSubjectGroup: restrictEditWithinSubjectGroup,
          allowClear: false,
        },
        rules: [
          {
            required: true,
            message: mrIntl("CommonText.required_msg"),
          },
        ]
        
      },
    }
  }
};

const OrgSubjectChildComponent = ({getFieldValue, sourceValues, formInstance}) => {
  const mrIntl = useTranslate();
  const orgProgrammeId = getFieldValue(["relation_items", "0", "org_programme_id"]);
  let finalRender = null;
  if(orgProgrammeId){
    finalRender = <FormRenderer
      formObj={getSelectOrgSubjectField(sourceValues, mrIntl)}
      sourceValues={sourceValues}
      formInstance={formInstance}
      propertyKey={"relation_items", "0"}
    />
  }
  return finalRender;
}

const SelectExperienceGroup = MrSelect({
  actions: genericActions,
  resourceName: "experiences",
  config: {
    url: "/experiences",
    params: {by_experience_type: "group"},
    processData: (data, props) => {
      return data.map((item) => {
        return {label: item.access_code + " (" + item.id + ") - " + item.name + " - " + item.status, value: item.id};
      });
    },
    searchApi: true,
    widgetConfig: {
      showSearch: true, 
      filterOption: true,
      optionFilterProp: "label",
      title: <MrTranslate id={"ExperienceCreateForm.select_experience"} />,
      placeholder: <MrTranslate id={"ExperienceCreateForm.select_experience"} />,
      style: {width: "400px"},
      allowClear: true
    },
  }
});

const PointsChildComponent = (props) => {
  console.log("PointsChildComponent", props);
  const { getFieldValue, sourceValues } = props;
  // const segmentType = getFieldValue(["segment_type"]);
  // const questionType = getFieldValue(["question_segment_attributes", "question_type"]);
  const mrIntl = useTranslate()
  const questionbankId = getFieldValue([
    "relation_items",
    "0",
    "questionbank_id",
  ]);
  const rubricId = getFieldValue(["rubric_id"]);
  const gradingSettingFormValue = getFieldValue([
    "settings",
    "grading_setting",
  ]);
  console.log("PointsChildComponent", rubricId);
  let rubric;

  let finalRender = null;
  // get active experience rubric = use criteria to build form for points if rubric not Points. if points or if no experience/adding segment in library, then default to below. if grading for full_experience then hide altogether

  // const activeExperienceId = useSelector(activeAdjustedExperienceIdSelector())
  // const activeExperience = useSelector(state => state.experiences.get("experiences").find((item) => item.id == activeExperienceId))
  const activeExperience = useSelector(activeAdjustedExperienceSelector());
  const rubrics = useSelector(rubricsSelector());
  console.log(
    "PointsChildComponent",
    activeExperience,
    rubrics,
    questionbankId,
    rubricId,
    questionbankId && rubricId
  );

 
  if (activeExperience.rubric) {
    rubric = activeExperience.rubric;
  } else if (rubricId) {
    rubric = rubrics.find((r) => r.id === parseInt(rubricId));
  }
  if (!rubric) {
    return "";
  }
  if(gradingSettingFormValue !== "overall"){
    return "";
  }
  
  // // get active experience grading_setting = per_question/full_experience
  // if(activeExperience.settings.grading_setting == "overall"){
  //   finalRender = ""
  // }else{
  //   // if grading per question
  //   // change this to rubric_code

  if (rubric.type_c == "points") {
    finalRender = (
      <FormRenderer
        formObj={{
          type: "object",
          properties: {
            points: {
              type: "string",
              placeholder: mrIntl("CommonText.points"),
              setInitialValue: true,
              widget: InputNumber,
              rules: [
                {
                  validator(rule, value) {
                    if (value && value > 0) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(mrIntl("CommonText.not_allowed"));
                    }
                  },
                },
              ],
            },
          },
        }}
        // propertyKey={"section_segment_attributes"}
        sourceValues={sourceValues}
      />
    );
  } else if (
    rubric &&
    (rubric.type_c == "criteria_with_points" ||
      rubric.type_c == "myp_achievement_level" || rubric.type_c == "custom") &&
    rubric.criteria &&
    rubric.criteria.length != 0
  ) {
    finalRender = (
      <FormRenderer
        formObj={{
          type: "object",
          properties: {
            criterium_associations_attributes: {
              type: "string",
              placeholder: mrIntl("CommonText.points"),
              setInitialValue: true,
              widget: RubricCriteriaPointsForm,
              widgetConfig: {
                selectedRubric: rubric,
                associableType: "Experience",
              },
              rules: [
                // ({ getFieldValue }) => ({
                //   validator(rule, value) {
                //     if (!value || getFieldValue('password') === value) {
                //       return Promise.resolve();
                //     }
                //     return Promise.reject('The two passwords that you entered do not match!');
                //   },
                // }),
                {
                  validator(rule, value) {
                    console.log("Segment ca validator", value, typeof value);
                    let validationPassed = true;
                    let checkBoxValidationPassed = false;
                    let message = "Please select atleast one";
                    if (!value) {
                      validationPassed = false;
                    } else {
                      if (rubric.type_c == "criteria_with_points") {
                        for (let i = 0; i <= value.length - 1; i++) {
                          // check if atleast 1 is checked
                          let ca = value[i];
                          checkBoxValidationPassed = false;
                          // message = "Please select atleast one"
                          if (
                            ca.checked ||
                            (typeof ca.checked === "undefined" && ca.id)
                          ) {
                            // typeof and id condition for first time - when component isnt touched then checked doesn't get set since only on FE
                            checkBoxValidationPassed = true;
                            // console.log('Segment ca validator', validationPassed, ca.checked)
                            message = mrIntl("CommonText.passed_msg");
                            break;
                          }
                        }

                        // check if points valid
                        if (checkBoxValidationPassed) {
                          for (let i = 0; i <= value.length - 1; i++) {
                            let ca = value[i];
                            let updatedPoints = ca.points;
                            if (
                              updatedPoints &&
                              parseFloat(updatedPoints) > 0 &&
                              typeof parseFloat(updatedPoints) == "number" &&
                              updatedPoints.slice(-1) != "."
                            ) {
                              if (/[a-z]/gi.test(updatedPoints)) {
                                validationPassed = false;
                                message = mrIntl("ExperienceCreateForm.points_must_not_contain_any_letter");
                              }
                              // allowed
                            } else {
                              validationPassed = false;
                              message = mrIntl("ExperienceCreateForm.points_must_be_non_zero")
                              break;
                            }
                          }
                        } else {
                          validationPassed = false;
                          message = mrIntl("ExperienceCreateForm.please_select_atleast_one");
                        }
                      } else if (rubric.type_c == "myp_achievement_level") {
                        // console.log('Segment ca validator', ca, ca.checked)
                        for (let i = 0; i <= value.length - 1; i++) {
                          let ca = value[i];
                          // check if atleast 1 is checked
                          validationPassed = false;
                          message = mrIntl("ExperienceCreateForm.please_select_atleast_one");
                          if (
                            ca.checked ||
                            (typeof ca.checked === "undefined" && ca.id)
                          ) {
                            // typeof and id condition for first time - when component isnt touched then checked doesn't get set since only on FE
                            validationPassed = true;
                            // console.log('Segment ca validator', validationPassed, ca.checked)
                            message = mrIntl("CommonText.passed_msg");
                            break;
                          }
                        }
                      }
                      // })
                    }

                    console.log(
                      "UserResponses validator updatedPoints",
                      validationPassed
                    );
                    if (validationPassed) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(new Error(message));
                    }
                  },
                },
              ],
            },
          },
        }}
        // propertyKey={"section_segment_attributes"}
        sourceValues={sourceValues}
      />
    );
  }
  

  return finalRender;
};

const GradingSettingChildComponent = ({getFieldValue, sourceValues, formInstance, setFieldsValue}) => {
  const rubricId = getFieldValue(["rubric_id"]);
  console.log("GradingSettingChildComponent", rubricId);
  const rubrics = useSelector(rubricsSelector());
  const activeExperience = useSelector(activeAdjustedExperienceSelector())
  const mrIntl = useTranslate()
  let rubric, selectedRubric;
  if (!rubricId) {
    return "";
  }else{
    selectedRubric = rubrics.find((r) => r.id === parseInt(rubricId));
  }
  if(!isEmpty(activeExperience)){
    rubric = activeExperience.rubric
  }else{
    rubric = selectedRubric
  }

  if (!rubric) {
    return "";
  }

  let finalRender = null;
  
  if (rubricId && rubric.type_c === "custom"){
    finalRender = <FormRenderer
      sourceValues={sourceValues}
      formInstance={formInstance}
      propertyKey={"settings"}
      formObj={{
        type: "object",
        properties: {
          grading_setting: {
            type: "string",
            title: mrIntl("ExperienceCreateForm.apply_rubric_to_cannot_be_changed_later"),
            // placeholder: "Select rubric",
            widget: "RadioGroupWidget",
            default: "overall",
            // for now only supporting overall in custom
            // default: "per_question",
            widgetConfig: {
              disabled: sourceValues.settings && sourceValues.settings.grading_setting ? true : false,
              options: [
                // {
                //   label: "Each question",
                //   value: "per_question",
                // },
                {
                  label: mrIntl("ExperienceCreateForm.overall_assessment"),
                  value: "overall",
                },
              ],
            },
            rules: [
              {
                required: true,
                message: mrIntl("CommonText.required_msg"),
              },
            ],
            setInitialValue: true,
          },
        }
      }}
    />
  }
  return finalRender;
}

const StandardSetTagData = (props) => {
  console.log("StandardSetTagData =====>", props);
  const { value = [], onChange, formInstance: { getFieldValue, setFieldsValue } } = props
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dataList, setDataList] = useState(value);
  const [standardCollection, setStandardCollection] = useState({});
  const [contentArea, setContentArea] = useState({});
  const [gradeGroup, setGradeGroup] = useState({});
  const [editingIndex, setEditingIndex] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [filteredTags, setFilteredTags] = useState([])
  const currentProgrammeId = getFieldValue(["relation_items", "0", "org_programme_id"])
  const questionbankId = getFieldValue(["relation_items", "0", "questionbank_id"])
  const currentSubjectId = getFieldValue(["relation_items", "0", "subject_id"])
  const [previousProgrammeId, setPreviousProgrammeId] = useState(currentProgrammeId)
  const [previousSubjectId, setPreviousSubjectId] = useState(currentSubjectId)
  const allOrgProgramme = useSelector(orgProgrammesSelector())
  const allQuestionbanks = useSelector(questionbanksFormRequestSelector())
  const programmeSubjectsGroup = useSelector(programmeSubjectsSelector())
  const programmeSubjects = flatten(programmeSubjectsGroup.map((item) => item.children_attributes)) 
  console.log("allQuestionbanks =====>", programmeSubjectsGroup);
  
  const allStandardCollection = useSelector(standardCollectionsSelector())
  const allGradeGroups = useSelector(gradeGroupSelector())
  const currentUser = useSelector(currentUserSelector())
  const mrIntl = useTranslate()
  const dispatch = useDispatch();
  const selectedContentArea = useSelector(allTagsSelector(contentArea?.value))
  const isQbAuthor = currentUser?.role === "qb_author";
  const currentOrgProgramme = allOrgProgramme?.find((op) => op.id === currentProgrammeId)
  const currentOrgProgrammeName = currentOrgProgramme?.name || "";
  const currentSubjects = isQbAuthor ? programmeSubjects : currentOrgProgramme?.subjects || []
  const currentSubject = currentSubjects.find((sub) => sub.id === currentSubjectId)
  const subjectLabel = currentSubject?.name || "";
  const allowedProgrammes = ["IB DP", "IB Diploma", "IB MYP", "IB Middle Years"]
  const allowedQuestionbanks = ["AP MYP", "IB DP", "AP DP", "OSC DP"]
  const nonArchivedDataList = dataList.filter((v) => !v.archived)
  
  const currentQuestionbank = allQuestionbanks?.find((qb) => qb.id === questionbankId);
  const programmeLabel = currentOrgProgrammeName || "";
  const questionbankLabel = currentQuestionbank?.title || "";
  const labelExists = dataList.filter((item) => item?.content_area_label !== undefined).length > 0
  const selectedContentAreaIds = [];
  const selectedGradeGroupIds = []
  nonArchivedDataList.forEach((item) => {
    if (allowedProgrammes.includes(item?.standard_collection_label)) {
      selectedContentAreaIds.push(item?.content_area_id);
    }
    if(item?.grade_group_id) {
      selectedGradeGroupIds.push(item?.grade_group_id)
    }
  });

  const allGradeGroupsDisabled = selectedContentArea?.grade_groups?.length > 0 && selectedContentArea.grade_groups.every(group => selectedGradeGroupIds.includes(group.id));

  const disableAddButton = !standardCollection?.value || !contentArea?.value || (selectedContentArea?.grade_groups?.length > 0 && !allGradeGroupsDisabled && !gradeGroup?.value);
  const valueIncludesContentAreaLabel = value.some((item) => item?.content_area_label)
  const shouldShowDefaultValues = (allowedProgrammes.includes(programmeLabel) || allowedQuestionbanks.includes(questionbankLabel)) && (value.length === 0 || !valueIncludesContentAreaLabel)
  const currentOrg = currentUser.org;
  const enabledStandardCollections = currentOrg.enabled_standard_collections || [];
  
  useEffect(() => {
    const finalJsonData = selectedContentArea?.final_json?.data || [];
    let filteredStandards = findStandardsByGrade(finalJsonData, gradeGroup?.label, null, "grade_group");
    setFilteredTags(filteredStandards)
  }, [selectedContentArea?.id, gradeGroup?.label , isModalVisible])

  useEffect(() => {
    if (shouldShowDefaultValues && currentSubjectId && value.length === 0) {
      console.log("subjectId check =====>", value.length);
      setDataList([])
      const params = {
        by_type_c: "content_area",
        order_by: "created_at"
      }
      if(isQbAuthor) {
        params.by_programme_subject_id = currentSubjectId 
      } else {
        params.by_subject_id = currentSubjectId
      }
      dispatch(tagActions.fetch({
        params: params
      }, {
        successCallback: (response) => {
          console.log("content area fetched =====>", response);
          if(response.data.tags && response.data.tags.data.length > 0) {
            const contentArea = response.data.tags.data[0]?.attributes || {}
            const newDataList = [{
              standard_collection_id: contentArea.parent_id,
              standard_collection_label: contentArea.standard_collection_label,
              content_area_id: contentArea.id,
              content_area_label: contentArea.label
            }]
            setDataList(newDataList);
            onChange(newDataList);
          }
        },
      }));
    }
  }, [currentSubjectId, dataList.length])

  // This is to reset the data list when the subject or programme changes using previous value and current value is because when we refresh the page then i don't want to reset the data list
  useEffect(() => {
    if(currentSubjectId !== previousSubjectId || currentProgrammeId !== previousProgrammeId) {
      setDataList([])
      onChange([])
    }
    setPreviousProgrammeId(currentProgrammeId)
    setPreviousSubjectId(currentSubjectId)
  }, [currentSubjectId, currentProgrammeId])

  const resetForm = () => {
    setContentArea({});
    setGradeGroup({});
    setStandardCollection({});
    setIsEditing(false);
  };

  const showModal = () => setIsModalVisible(true);

  const handleCancel = () => {
    setIsModalVisible(false);
    resetForm();
  };

  const handleSave = () => {
    const newDataList = [...dataList];
    const newData = { 
      content_area_id: contentArea?.value,
      grade_group_id: gradeGroup?.value,
      standard_collection_id: standardCollection?.value,
      standard_collection_label: standardCollection?.label,
      content_area_label: contentArea?.label,
      grade_group_label: gradeGroup?.label 
    };

    if (isEditing && editingIndex !== null) {
      newDataList[editingIndex] = newData;
    } else {
      newDataList.push(newData);
    }

    setDataList(newDataList);
    onChange(newDataList);
    resetForm();
    setIsModalVisible(false);
  };

  const handleEdit = (index, item) => {
    const { content_area_id, grade_group_id, content_area_label, grade_group_label, standard_collection_id, standard_collection_label } = item;
    
    setContentArea({
      value: content_area_id, 
      label: content_area_label
    });
    setStandardCollection({
      value: standard_collection_id,
      label: standard_collection_label
    })
    setGradeGroup({
      value: grade_group_id,
      label: grade_group_label
    });
    setEditingIndex(index);
    setIsEditing(true);
    fetchContentArea(content_area_id);
    showModal();
  };

  const handleDelete = (content_area_id, grade_group_id) => {
    const newDataList = [...dataList]
    const index = newDataList.findIndex((data) =>
      !data.archived &&
      data.content_area_id === content_area_id &&
      (!grade_group_id || data.grade_group_id === grade_group_id)
    );
    const currentData = newDataList[index]
    if(currentData.id) {
      newDataList[index].archived = true
    } else {
      newDataList.splice(index, 1)
    }
    
    setDataList(newDataList);
    onChange(newDataList);
  };

  const fetchContentArea = (value) => {
    dispatch(tagActions.fetch({params: {by_ids: [value]}}))
  }

  return (
    <div>
      <Modal
        title={isEditing ? 'Edit standard set' : 'Add standard set'}
        visible={isModalVisible}
        onCancel={handleCancel}
        width={"50%"}
        footer={[
          <Button key="cancel" onClick={handleCancel}>Cancel</Button>,
          <Button key="save" type="primary" onClick={handleSave} disabled={disableAddButton}>
            {isEditing ? 'Save' : 'Add'}
          </Button>,
        ]}
      >
        <Row>
          {enabledStandardCollections.length === 0 && (
            <Col flex="auto" align="center">
              <Text type="secondary">
                {mrIntl("ExperienceCreateForm.standard_sets_are_not_enabled_please_enable_standard_sets_first")}&nbsp;
              </Text>

              <Link onClick={() => {
                window.location.href = "/u/settings/org/standard-sets";
              }}>
                {mrIntl("ExperienceCreateForm.click_here_to_enable_standard_sets")}
              </Link>
            </Col>
          )}
          {enabledStandardCollections.length > 0 && <SelectStandardCollectionOnly
            value={standardCollection?.value}
            onChange={(value, extra) => {
              setStandardCollection(extra)
              setContentArea({})
            }}
            config={{
              widgetConfig: {
                style: {
                  width: '250px', marginRight: '10px'
                },
                options: enabledStandardCollections,
                disabledStandardCollectionLabels: [currentOrgProgrammeName]
              },
              // params: {
              //   by_standard_collection_labels_not: [currentOrgProgrammeName],
              //   should_serialize: false,
              //   by_ids: enabledStandardCollectionIds
              // }
            }}
          />}
          
          {standardCollection?.value &&
            <SelectStandardCollectionContentArea
              value={contentArea?.value}
              onChange={(value, extra) => {
                setContentArea(extra)
                setGradeGroup({})
                setFilteredTags([])
                if (value) {
                  fetchContentArea(value)
                }
              }}
              config={{
                params: {
                  by_parent_id: standardCollection?.value,
                  // by_not_content_area_ids: isEditing ? [] : selectedContentAreaIds, 
                  with_final_json: false,
                  // should_serialize: false, - keeping true so that final_json is conditionally returned - children anyway not returned
                },
                widgetConfig: {
                  style: {
                    width: '250px', marginRight: '10px'
                  },
                  shouldDisableContentAreaIds: selectedContentAreaIds
                },
              }}
            />
          }
          {standardCollection?.value && contentArea?.value &&
            <SelectGradeGroup
              value={gradeGroup?.value}
              onChange={(value, extra) => setGradeGroup(extra)}
              popupClassName={"standard-set-tag-grade-group"}
              config={{
                params: {
                  by_parent_id: contentArea?.value,
                  should_serialize: false,
                },
                widgetConfig: {
                  style: {
                    width: '150px', marginRight: '10px'
                  },
                  shouldDisabledGradeGroupIds: selectedGradeGroupIds,
                  options: selectedContentArea?.grade_groups || [],
                },
              }}
            />
          }
        </Row>
        <Row>
          {filteredTags.length > 0 && ((contentArea?.value && allGradeGroups.length > 0 && gradeGroup?.value) || (contentArea?.value && allGradeGroups.length === 0)) && <DraggableList
            items={filteredTags}
            isEditable={false}
          />
          }
        </Row>
      </Modal>
      
      <label>Add standard set</label>
      <Row style={{ marginTop: '20px' }} gutter={50} className="add-standard-set-wrapper">
        {nonArchivedDataList.map((item, index) => (
          <Col span={6} key={index} style={{ marginBottom: '16px' }}>
            <Card
              // title={`Item ${index + 1}`}
              className="starndard-set-card"
              bordered={false}
              key={`item-${index}`}
              actions={[
                <Tooltip title={item.standard_collection_label !== currentOrgProgrammeName ? "Edit" : ''}>
                  <Button 
                    type="text" 
                    className="starndard-set-action-buttons starndard-set-edit-button"
                    icon={<EditOutlined />} 
                    onClick={() => handleEdit(index, item)} 
                    disabled={item.standard_collection_label === currentOrgProgrammeName} 
                  />
                </Tooltip>,
                <Tooltip title={item.standard_collection_label !== currentOrgProgrammeName ? "Delete" : ''}>
                  <Popconfirm 
                    title={"Confirm delete"} 
                    key={"delete"} 
                    onConfirm={() => handleDelete(item.content_area_id, item.grade_group_id)} 
                    okText={mrIntl("CommonText.yes")}
                    cancelText={mrIntl("CommonText.no")}
                  >
                    <Button 
                      type="text" 
                      className="starndard-set-action-buttons starndard-set-delete-button"
                      icon={<DeleteOutlined />} 
                      disabled={item.standard_collection_label === currentOrgProgrammeName}
                    />
                  </Popconfirm>
                </Tooltip>
              ]}
            >
              <Space direction="vertical">
                <b>{item?.standard_collection_label}</b>
                {item?.content_area_id && <span>{item?.content_area_label}</span>}
                {item?.grade_group_id && <span style={{color: "#aaa"}}>{item?.grade_group_label}</span>}
                {item?.programme_label && <span style={{color: "#aaa"}}>{item?.programme_label}</span>}
                {item?.subject_label && <span style={{color: "#aaa"}}>{item?.subject_label}</span>}
              </Space>
            </Card>
          </Col>
        ))}
        {nonArchivedDataList.length <= 3 && <Col>
          <Button type="primary" onClick={showModal}>
            <PlusOutlined /> Add
          </Button>
        </Col>}
      </Row>
    </div>
  );
};

const getStandardSetTagDataField = (sourceValues) => {
  console.log("getStandardSetTagDataField ==>", sourceValues)
  return {
    type: "object",
    properties: {
      standard_set_tag_data: {
        type: "string",
        placeholder: "",
        span: 24,
        setInitialValue: true,
        widget: StandardSetTagData,
      },
    },
  };
};

const StandardSetTagDataChildComponent = ({ getFieldValue, sourceValues, formInstance }) => {
  console.log("StandardSetTagDataChildComponent ==>", sourceValues)
  const orgProgrammeId = getFieldValue(["relation_items", "0", "org_programme_id"]);
  const subjectId = getFieldValue(["relation_items", "0", "subject_id"]);
  const allProgrammes = useSelector(orgProgrammesSelector())
  const currentUser = useSelector(currentUserSelector())
  const currentProgramme = allProgrammes.find((op) => op.id === orgProgrammeId) || {}
  const programmeNameIncludesIB = currentProgramme?.name?.includes("IB")
  
  let finalRender = null;
  if (subjectId) {
    finalRender = (
      <FormRenderer
        formObj={getStandardSetTagDataField(sourceValues)}
        sourceValues={sourceValues}
        formInstance={formInstance}
        propertyKey={("relation_items", "0")}
      />
    );
  }
  return finalRender;
};

export const nonPaperModeDefaultSettings = {
  allow_unmapped_students: true,
  allow_student_upload_files: false,
}

export const paperModeSettings = {
  with_join_code: false,
  allow_student_upload_files: true,
  calc_enabled: false,
  graphing_calc_enabled: false,
  spellcheck_enabled: false,
  include_video_call_link: false,
  peer_review_enabled: false,
  allow_unmapped_students: false,
  allow_guests: false
}

export const CustomRadioGroupWidget = ({
  options = [],
  optionType = "default",
  buttonStyle = "outline",
  disabled = false,
  value,
  formInstance,
  onChange,
}) => {
  console.log("CustomRadioGroupWidget ===>", options);
  const { setFieldsValue, getFieldValue } = formInstance || {};
  const prevDeliveryMode = getFieldValue(["settings", "mode"]);
  // Changing here and also in processFormData.
  // Here because, delivery settings do not update without refresh in deliver if just changed from processFormData 
  const handleOnChange = (e) => {
    const newSettings = 
      e.target.value === "paper" 
        ? paperModeSettings 
        : (prevDeliveryMode === "paper" 
          ? nonPaperModeDefaultSettings 
          : null
      );
    if (newSettings) {
      setFieldsValue({ settings: newSettings });
    }
    onChange(e);
  };

  return (
    <Radio.Group
      value={value}
      onChange={handleOnChange}
      optionType={optionType}
      buttonStyle={buttonStyle}
      disabled={disabled}
    >
      {options.map(({ value: optionValue, label }) => (
        <Radio key={optionValue} value={optionValue}>
          {label}
        </Radio>
      ))}
    </Radio.Group>
  );
};


const generateExperienceCreateForm = (resourceName, props) => {
  console.log("line number 332",props);
  const { mrIntl } = props;

  // based on whether resource name is libraryexperience or experience, build below form to return
  // const { resourceName } = props
  const isMobile = checkMob()
  let org = props.org
  let currentUser = props.currentUser
  let formValues = props.formState.values
  let enabledFeatures = props.enabledFeatures
  let qbMode = (currentUser.role == "qb_author" && resourceName == "libraryexperience") ? true : false
  let createUpdateTags = qbMode || (enabledFeatures.enable_standards_content_area_tag_multiple);
  let partnerName = props.partnerName
  const isDisableLockdownMode = enabledFeatures.disable_lockdown_mode
  const isPaperModeAllowed = enabledFeatures.paper_mode

  let processFormData = (dataProps, opts) => {
    console.log("dataProps", dataProps);
    console.log("opts", opts);
    // process and set values and then return changed values
    // opts.values["custom_field"] = "custom_value";
    // let experience_memberships_attributes = []
    
    // opts.values.experience_memberships_attributes = experience_memberships_attributes;


    let relationItems = opts.values.relation_items[0]

    // We are using opts.formFinishProps.values because when we clear the value of a single select, the correct value does not appear in opts.value. APL-3541
    let formFinishPropsRelationItem =
      opts.formFinishProps.values.relation_items && opts.formFinishProps.values.relation_items[0];
    // not supporting multiple right now
    if(opts.values.settings.start_at_datetime) {
      opts.values.settings = {
        ...opts.values.settings,
        start_condition: "scheduled_time"
      }
    } else {
      opts.values.settings = {
        ...opts.values.settings,
        start_condition: "anytime"
      }
    }
    // changing here because most formFields are not availble when creating an assessment
    if(opts.values.settings.mode === "paper") {
      opts.values.settings = {
        ...opts.values.settings, 
        ...paperModeSettings
      }
    }
    
    if(qbMode){
      let questionbank_items_attributes = []
      let qbi
      if(relationItems.questionbank_items && relationItems.questionbank_items.length != 0){
        qbi = relationItems.questionbank_items[0]
        qbi.questionbank_id = relationItems.questionbank_id
      }
      else{
        qbi = {
          itemable_type: "Experience",
          questionbank_id: relationItems.questionbank_id,
        }
      }
      questionbank_items_attributes.push(qbi)
      opts.values.questionbank_items_attributes = questionbank_items_attributes
    }else{
      let org_programme_items_attributes = []
      let opi
      if(relationItems.org_programme_items && relationItems.org_programme_items.length != 0){
        opi = relationItems.org_programme_items[0]
        opi.org_programme_id = relationItems.org_programme_id
      }
      else{
        opi = {
          itemable_type: "Experience",
          org_programme_id: relationItems.org_programme_id,
        }
      }
      org_programme_items_attributes.push(opi)
      opts.values.org_programme_items_attributes = org_programme_items_attributes
    }

    
    let subject_items_attributes = []
    let si
    if(relationItems.subject_items && relationItems.subject_items.length != 0){
      si = relationItems.subject_items[0]
      // si.subject_id = relationItems.subject_id
    }
    else{
      si = {
        itemable_type: "Experience",
        // subject_id: relationItems.subject_id,
      }
    }
    if(qbMode){
      si.programme_subject_id = relationItems.subject_id
    }else{
      si.subject_id = relationItems.subject_id
    }
    subject_items_attributes.push(si)
    opts.values.subject_items_attributes = subject_items_attributes

    let grade_items_attributes = []
    let gi
    if(relationItems.grade_items && relationItems.grade_items.length != 0){
      gi = relationItems.grade_items[0]
      // gi.grade_id = relationItems.grade_id
    }
    else{
      gi = {
        itemable_type: "Experience",
        // grade_id: relationItems.grade_id,
      }
    }
    if(qbMode){
      gi.programme_grade_id = relationItems.grade_id
    }else{
      gi.grade_id = relationItems.grade_id
    }
    grade_items_attributes.push(gi)
    opts.values.grade_items_attributes = grade_items_attributes


    if (createUpdateTags && relationItems) {
      // createUpdateSelectAttrsBeforeSubmit(<existing items>, <modified_ids>) // currently only for tag_items
      // let syllabus_items_attributes = createUpdateSelectAttrsBeforeSubmit(relationItems.syllabus_items, relationItems.syllabus_content_ids)
      // let difficulty_level_items_attributes = createUpdateSelectAttrsBeforeSubmit(relationItems.difficulty_level_items, relationItems.difficulty_level_tag_ids)
      let teaching_level_items_attributes = createUpdateSelectAttrsBeforeSubmit("Experience", formFinishPropsRelationItem.teaching_level_items, formFinishPropsRelationItem.teaching_level_tag_ids)
      let paper_type_items_attributes = createUpdateSelectAttrsBeforeSubmit("Experience", formFinishPropsRelationItem.paper_type_items, formFinishPropsRelationItem.paper_type_tag_ids)

      let standard_collection_content_area_tag_ids = []
      if(enabledFeatures.enable_standards_content_area_tag_multiple) {
        // const contentAreas = formFinishPropsRelationItem.standard_set_tag_data?.map((item) => item.content_area_id)
        // standard_collection_content_area_tag_ids = flatten(contentAreas)
      } else {
        standard_collection_content_area_tag_ids = formFinishPropsRelationItem.standard_collection_content_area_tag_ids
      }

      let standard_collection_content_area_items_attributes = createUpdateSelectAttrsBeforeSubmit("Experience", formFinishPropsRelationItem.standard_collection_content_area_items, standard_collection_content_area_tag_ids)
      let new_standard_collection_content_area_items_attributes = []
      if(enabledFeatures.enable_standards_content_area_tag_multiple) {
        const { standard_set_tag_data = [], standard_collection_content_area_items = [] } = formFinishPropsRelationItem;
        standard_set_tag_data.forEach((tag) => {
          const existingContentArea = standard_collection_content_area_items.find(
            (sc) => !sc.archived && sc.id === tag.id
          );

          const newCustomFields = {
            grade_group_id: tag.grade_group_id,
            standard_collection_label: tag.standard_collection_label,
            content_area_id: tag.content_area_id,
            standard_collection_id: tag.standard_collection_id,
            content_area_label: tag.content_area_label,
            grade_group_label: tag.grade_group_label,
          };

          if (existingContentArea) {
            existingContentArea.archived = tag.archived || existingContentArea.archived;
            
            if (!tag.archived) {
              existingContentArea.custom_fields = { 
                ...existingContentArea.custom_fields, 
                ...newCustomFields 
              };
            }

            new_standard_collection_content_area_items_attributes.push(existingContentArea);
          } else {
            new_standard_collection_content_area_items_attributes.push({
              tag_id: tag.content_area_id,
              custom_fields: newCustomFields,
            });
          }
        });

      } else {
        new_standard_collection_content_area_items_attributes = standard_collection_content_area_items_attributes
      }
      
      console.log("new_standard_collection_content_area_items_attributes ====>", new_standard_collection_content_area_items_attributes);
      
      // console.log("tag_items_attributes", syllabus_items_attributes, difficulty_level_items_attributes, teaching_level_items_attributes)

      // opts.values.tag_items_attributes = syllabus_items_attributes.concat(difficulty_level_items_attributes).concat(teaching_level_items_attributes) 
      opts.values.tag_items_attributes = paper_type_items_attributes.concat(teaching_level_items_attributes).concat(new_standard_collection_content_area_items_attributes)
    }
    
    // console.log("getting from state and finding selectedSubject",relationItems.subject_id);
    // let selectedSubject = props.subjects.find(sub => sub.id === parseInt(relationItems.subject_id))
    // console.log("getting from state and finding selectedSubject",selectedSubject);
    
    // opts.values.custom_fields = {
    //   subjectItemLabel: selectedSubject.label
    // }
    
    // setting default to 1
    console.log("opts.values.settings.attempts_no", opts.values.settings.attempts_no)
    if(opts.values.settings.attempts_no == undefined){
      opts.values.settings.attempts_no = 1
    }
    
    if(qbMode){
      let allowEmbed = currentUser.role === "qb_author" ? true : false
      console.log("qbMode allowEmbed", allowEmbed)
      opts.values.settings.allow_embed = allowEmbed;
      
      // We are using opts.formFinishProps.values because when we clear the value of a single select, the correct value does not appear in opts.value.
      if(opts.formFinishProps.values.parent_id === undefined) {
        opts.values.parent_id = null
      }
    }
    
    console.log("opts.values exp create form process form data", opts.values)
    return opts.values;
  }
 
  // separate each and add to properties as needed based on resourceName or override
  let properties = {


    name: {
      type: "string",
      title: mrIntl("CommonText.title"),
      // title: "Title",
      span: isMobile ? 24 : 8,
      // widget: CKETextContent,
      rules: [
        {
          required: true,
          message: mrIntl("CommonText.required_msg"),
          // message: 'Required',
        },
        {
          max: 50,
          message: mrIntl("ExperienceCreateForm.max_50_characters"),
        },
      ]
    },

    // Todo: add support for multiple select
    relation_items : {
      type: "array",
      span: 24,
      min: 1,
      max: 1,
      // max: qbMode ? 2 : 1, // allowing qb author to select multiple QB - not for now
      default: [{}],
      // default: [{org_programme_id: parseInt(getFromLS("activeOrgProgrammeId"))}],
      // set defaults from LS - hiding for now
      // title: "Relations",
      // widgetConfig: {
      //   disabled: props.formState.values.id ? true : false
      // },
      items: {
        type: "object",
        properties: {
          // questionbank_id: {
          //   placeholder: "Select questionbank",
          //   type: "string",
          //   span: 8,
          //   widget: SelectQuestionbank,
          // },

          // For schools to create test in school bank by default
          org_programme_id: {
            // title: "Select program",
            title: mrIntl("ExperiencesCreateForm.select_program"),
            type: "string",
            span: isMobile ? 24 : 6,
            widget: SelectProgram,
            widgetConfig: {
              disabled: formValues.relation_items && formValues.relation_items[0].org_programme_id ? true : false,
              allowClear: false
            },
            rules: [
              {
                required: true,
                message: mrIntl("CommonText.required_msg"),
                // message: 'Required',
              },
            ]
          },
          subject_id: {
            placeholder: mrIntl("CommonText.select_subject"),
            type: "string",
            span: isMobile ? 24 : 8,
            dependsOn: ["relation_items", "0", "org_programme_id"],
            children: OrgSubjectChildComponent,
            
          },
          grade_id: {
            placeholder: mrIntl("CommonText.select_grade"),
            type: "string",
            span: isMobile ? 24 : 4,
            dependsOn: ["relation_items", "0", "org_programme_id"],
            children: OrgGradeChildComponent,
            
          },
        },
        
      }
    },

   

    // IMP
    // 1. Grade full test or per question // comments on by default for full test and per question
    // if grade full test, then give points input in exp form

    // 2. rubric -> For others: points/other rubric -> single select
    // 2. rubric -> FOr MYP: myp criteria with points/myp achievement level/other rubric -> single select

    // only use to show per question -> attach only those that are used in questions. If other rubric, then show another dropnwod to select from rubrics of that prog/sub/grade -> 


    rubric_id: {
      type: "string",
      span: isMobile ? 24 : 24,
      title: mrIntl("ExperienceCreateForm.rubric"),
      widget: SelectRubric,
      dependsOn: ["relation_items", "0", "subject_id"],
      // shouldUpdate: decideIfUpdate(),
      // shouldUpdate: false,
      children: RubricChildComponent,
      widgetConfig: {
        disabled: formValues.rubric_id ? true : false
      },
      // rules: [
      //   {
      //     required: true,
      //     message: 'Required',
      //   },
      // ]
    },
    
    enable_standards_based_grading: {
      type: "string",
      span: 24,
      dependsOn: ["rubric_id"],
      wrapClassName: enabledFeatures.set_grading_scales_for_sbg ? "" : "hidden", 
      children: CheckboxChildComponent,
    },

    grading_scale_id: {
      type: "string",
      span: 24,
      title: mrIntl("ExperienceCreateForm.grading_scale"),
      wrapClassName: enabledFeatures.set_grading_scales_for_sbg ? "" : "hidden",
      dependsOn: ["enable_standards_based_grading"],
      children: SelectGradingScaleChildComponent,
    },

    // Comment for now update latter because of this when we edit the assessment the points show and need to check why points show REF. Ticket: APL-3458
    // points: {
    //   type: "string",
    //   placeholder: mrIntl("CommonText.points"),
    //   // span: 4,
    //   span: 16,
    //   wrapClassName: "wrap-segment-form-points",
    //   // dependsOn: ["segment_type"],
    //   // children: qbMode ? PointsChildComponentTempLibrary : PointsChildComponent,
    //   dependsOn: ["rubric_id"],
    //   children: PointsChildComponent,
    //   // // setInitialValue: true,
    //   rules: [
    //     {
    //       required: true,
    //       message: mrIntl("CommonText.required_msg"),
    //     },
    //   ],
    // },

    // criterium_associations: {
    //   type: "string",
    //   span: isMobile ? 24 : 24,
    //   title: "Criteria",
    //   // dependsOn: ["rubric_id"],
    //   // children: RubricChildComponent,
    //   // widgetConfig: {
    //   //   disabled: formValues.rubric_id ? true : false
    //   // },
    //   widget: "CheckboxGroupWidget",
    //   widgetConfig: {
    //     options: buildOptionsArr(['A: Knowledge', 'B: Application'])
    //   },
    //   // rules: [
    //   //   {
    //   //     required: true,
    //   //     message: 'Required',
    //   //   },
    //   // ]
    // },

    settings: {
      type: "object",
      properties: {
        grading_setting: {
          type: "string",
          span: 24,
          // title: "Apply rubric to",
          // widget: "RadioGroupWidget",
          // default: "per_question",
          // widgetConfig: {
          //   disabled: formValues.settings && formValues.settings.grading_setting ? true : false,
          //   options: [
          //     {
          //       label: "Each question",
          //       value: "per_question",
          //     },
          //     {
          //       label: "Overall assessment",
          //       value: "overall",
          //     },
          //   ],
          // },
          dependsOn: ["rubric_id"],
          children: GradingSettingChildComponent,
        },

        

        // grading_type: {
        //   type: "string",
        //   span: 24,
        //   title: "Grading",
        //   widget: "RadioGroupWidget",
        //   default: "points",
        //   widgetConfig: {
        //     options: [
        //       {
        //         label: "Points",
        //         value: "points",
        //       },
        //       // {
        //       //   label: "Achievement level",
        //       //   value: "level",
        //       // },
        //       // {
        //       //   label: "Other rubric",
        //       //   value: "other",
        //       // },
        //     ],
        //   },
        // },
        mode: {
          type: "string",
          title: mrIntl("ExperiencesCreateForm.delivery_mode"),
          // title: "Delivery mode",
          widget: CustomRadioGroupWidget,
          default: "online",
          span: isMobile ? 24 : 24,
          rules: [
            {
              required: true,
              message: mrIntl("CommonText.required_msg"),
            },
          ],
          widgetConfig: {
            options: getExperienceModeOptionsForForm({partnerName: partnerName, isDisableLockdownMode: isDisableLockdownMode, isPaperModeAllowed}, mrIntl),
            // disabled: true
            // optionType: "button",
            // buttonStyle: "solid",
          },
        },
        start_at_datetime: {
          // title: "Start date and time",
          title: mrIntl("CommonText.start_date_and_time"),
          type: "string",
          span: isMobile ? 24 : 12,
          widget: "DatePickerWidget",
          widgetConfig: {
            format: "YYYY-MM-DD h:mm a",
            // showTime: true,
            showTime: {use12Hours: true, format:"h:mm a", minuteStep: 15},
            needConfirm: false,
            disabledDate: (current) => {
              let dd = dayjs().startOf('day').isAfter(current, 'day') || dayjs().add(3, 'month').startOf('day').isBefore(current, 'day')
              return dd;
            }                        
          }
        },
      }
    },
  }

  if(qbMode || enabledFeatures.allow_select_assessment_type){
    properties.settings.properties.assessment_type = {
      type: "string",
      title: mrIntl("ExperienceCreateForm.assessment_type_for_pamoja_authors"),
      widget: "RadioGroupWidget",
      default: "exam",
      span: isMobile ? 24 : 24,
      widgetConfig: {
        options: getAssessmentTypeOptionsForForm(mrIntl),
        // disabled: true
        // optionType: "button",
        // buttonStyle: "solid",
      },
    }
  }
  if (enabledFeatures.allow_select_evaluation_type || import.meta.env.VITE_MODE !== "production") {
    properties.settings.properties.evaluation_type = {
      type: "string",
      title: mrIntl("CommonText.evaluation_type"),
      widget: "RadioGroupWidget",
      default: "formative",
      span: isMobile ? 24 : 24,
      rules: [
        {
          required: true,
          message: mrIntl("CommonText.required_msg")
        },
      ],
      widgetConfig: {
        options: [{ label: mrIntl("CommonText.formative"), value: 'formative' }, { label: mrIntl("CommonText.summative"), value: 'summative' }],
        // disabled: true
        // optionType: "button",
        // buttonStyle: "solid",
      },
    };
  }
  let standardCollectionContentAreaTagIds = {
    // placeholder: "Select teaching level",
    placeholder: mrIntl("ExperienceCreateForm.select_teaching_level"),
    type: "string",
    span: 8,
    dependsOn: ["relation_items", "0", "subject_id"],
    children: SelectStandardCollectionContentAreaChildComponent,
    widgetConfig: {
      type_c: "content_area"
    },
  };
  let standardCollectionNestedContentAreaTagIds = {
    // placeholder: "Select teaching level",
    // placeholder: mrIntl("ExperienceCreateForm.select_teaching_level"),
    type: "string",
    span: 8,
    dependsOn: ["relation_items", "0", "subject_id"],
    children: SelectStandardCollectionChildComponent,
    widgetConfig: {
      type_c: "standard_collection"
    },
  };
  // if((enabledFeatures.enable_standards_content_area_tag || import.meta.env.VITE_MODE !== 'production') && (currentUser.role === "admin" || currentUser.role === "teacher" )) {
  //   properties.relation_items.items.properties.standard_collection_content_area_tag_ids = standardCollectionContentAreaTagIds;
  // }
  if((enabledFeatures.enable_standards_content_area_tag_multiple) && (currentUser.role === "admin" || currentUser.role === "teacher" )) {
    properties.relation_items.items.properties.standard_set_tag_data = {
      type: "string",
      span: 24,
      dependsOn: ["relation_items", "0", "subject_id"],
      children: StandardSetTagDataChildComponent,
    }
  }
  // TEMP override to select any standard collection on local
  // if(enabledFeatures.set_grading_scales_for_sbg || import.meta.env.VITE_MODE === 'development') {
  //   properties.relation_items.items.properties.standard_collection_content_area_tag_ids = standardCollectionNestedContentAreaTagIds;
  // }
  if(qbMode){
    properties.relation_items.items.properties = {
      // For qb_author to create test in qb
      questionbank_id: {
        // placeholder: "Select questionbank",
        placeholder: mrIntl("CommonText.select_questionbank"),
        type: "string",
        span: 8,
        widget: SelectQuestionbank,
        widgetConfig: {
          disabled: formValues.relation_items && formValues.relation_items[0].questionbank_id ? true : false
        },
        rules: [
          {
            required: true,
            message: mrIntl("CommonText.required_msg"),
          },
        ]
      },
      subject_id: {
        placeholder: mrIntl("CommonText.select_subject"),
        type: "string",
        span: 8,
        dependsOn: ["relation_items", "0", "questionbank_id"],
        children: SubjectChildComponent,
      },
      grade_id: {
        placeholder: mrIntl("CommonText.select_grade"),
        type: "string",
        span: 4,
        dependsOn: ["relation_items", "0", "questionbank_id"],
        children: GradeChildComponent,
      },
    }
    properties.relation_items.items.properties.paper_type_tag_ids = {
      placeholder: mrIntl("ExperienceCreateForm.select_paper_type"),
      type: "string",
      span: 8,
      widget: SelectPaperType,
      widgetConfig: {
        type_c: "paper_type"
      },
    };
    properties.relation_items.items.properties.teaching_level_tag_ids = {
      // placeholder: "Select teaching level",
      placeholder: mrIntl("ExperienceCreateForm.select_teaching_level"),
      type: "string",
      span: 8,
      widget: SelectTeachingLevel,
      widgetConfig: {
        type_c: "teaching_level"
      },
    };
    if(enabledFeatures.enable_standards_content_area_tag_multiple) {
      properties.relation_items.items.properties.standard_set_tag_data = {
        type: "string",
        span: 24,
        dependsOn: ["relation_items", "0", "subject_id"],
        children: StandardSetTagDataChildComponent,
      }
    } else {
      properties.relation_items.items.properties.standard_collection_content_area_tag_ids = standardCollectionContentAreaTagIds;
    }

    // Only for pamoja authors
    // properties.settings.properties.assessment_type = {
    //   type: "string",
    //   title: "Assessment type [For Pamoja authors only - Quick check for auto-grading and auto-publishing, Exam for teaching-graded and manual publishing of submissions]",
    //   widget: "RadioGroupWidget",
    //   default: "exam",
    //   span: isMobile ? 24 : 24,
    //   widgetConfig: {
    //     options: getAssessmentTypeOptionsForForm(),
    //     // disabled: true
    //     // optionType: "button",
    //     // buttonStyle: "solid",
    //   },
    // }
    // Below being used for DP bank
    properties.experience_type = {
      type: "string",
      title: mrIntl("ExperienceCreateForm.experience_type"),
      widget: "RadioGroupWidget",
      default: "assessment",
      span: isMobile ? 24 : 24,
      rules: [
        {
          required: true,
          message: mrIntl("CommonText.required_msg"),
        },
      ],
      widgetConfig: {
        options: [
          {
            label: mrIntl("ExperienceCreateForm.assessment"),
            value: "assessment",
          },
          {
            label: mrIntl("ExperienceCreateForm.learning"),
            value: "learning",
            disabled: true
          },
          {
            label: mrIntl("CommonText.group"),
            value: "group",
          },
        ],
        // disabled: true
        // optionType: "button",
        // buttonStyle: "solid",
      },
    }
    properties.parent_id = {
      type: "string",
      span: 12,
      placeholder: mrIntl("ExperienceCreateForm.select_group"),
      title: "Select group",
      widget: SelectExperienceGroup,
      widgetConfig: {
        title: mrIntl("ExperienceCreateForm.select_group"),
        placeholder: mrIntl("ExperienceCreateForm.select_group"),
      }
    }
    properties.custom_fields = {
      type: "object",
      properties: {
        ib_reference_code: {
          type: "string",
          span: 24,
          placeholder: mrIntl("CommonText.ib_reference_code"),
          title: <MrTranslate id={"CommonText.ib_reference_code"}/>,
        },
        show_in_osc: {
          type: "string",
          span: 24,
          placeholder: mrIntl("ExperienceCreateForm.show_in_osc"),
          // title: "Show in OSC",
          widget: CustomSwitchWidget,
          valuePropName: "checked",
        },
        is_new: {
          type: "string",
          span: 24,
          placeholder: mrIntl("ExperienceCreateForm.is_new"),
          // title: "Show in OSC",
          widget: CustomSwitchWidget,
          valuePropName: "checked",
        }
      }
    }
  }

  let finalForm = {
    processFormData: processFormData,
    schema: {
      type: "object",
      properties: properties,
    }
  }

  return finalForm;

}




export default generateExperienceCreateForm;