import React, { useEffect, Suspense, lazy, useState, useMemo } from "react";
import PropTypes from "prop-types";
import {
  List,
  Card,
  Col,
  Space,
  Button,
  Tooltip,
  Skeleton,
  Select,
  Typography,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
// import ActionBtns from "/src/components/ActionBtns/ActionBtns";
import { Route, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { activeAdjustedExperienceSelector, experienceLoadingSelector } from "../selector";
import { actions as experienceReduxActions } from "/src/views/Experiences/redux";
import _ from "lodash";
import { topicsSelector } from "../../Segments/Topics/selector";
import RenderHtml from "/src/components/UI/RenderHtml/RenderHtml";
//import Segments from "../../Segments/Segments";
import DuplicateExperienceButton from "../ExperienceShow/Components/DuplicateExperienceButton";
import "./ExperienceItemPreview.scss";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import useIntersectionObserver from "./useIntersectionObserver";
const LazySegments = lazy(() => import("../../Segments/Segments"));

const ExperienceItemPreview = () => {
  const { path } = useRouteMatch();
  const experience = useSelector(activeAdjustedExperienceSelector());
  const topics = useSelector(topicsSelector());
  const experienceLoading = useSelector(experienceLoadingSelector());
  const dispatch = useDispatch();
  const isDraft = experience.status === "draft";
  const mrIntl = useTranslate();
  const { Title } = Typography;
  const initialSelectTopicValue = topics[0]?.id;
  let scrollTimeoutId;
  // Setting experience view mode to "" when status is draft
  const experienceViewMode = isDraft ? "" : "apEditTest";

  const finalRender = useMemo(() => {
    const renderItems = [];
    // Prepare the render items
    if (!_.isEmpty(topics)) {
      topics.forEach((topic, i) => {
        const topicId = topic.id;

        // Add topic title
        renderItems.push(
          <List.Item
            className={
              i === 0
                ? "segment-list-item unstyled-list-item first-topic-title"
                : "segment-list-item unstyled-list-item topic-title"
            }
            key={`topic-title-${topicId}`}
          >
            <h3>
              <b>
                <RenderHtml
                  text={topic.section_segment_attributes.title}
                  truncate
                />
              </b>
            </h3>
          </List.Item>
        );

        // Add segments component
        const segmentsComponent = (
          <Route
            path={`${path}`}
            render={(routeProps) => (
              <LazySegments
                doNotReloadOnUpdate={true}
                experienceViewMode={experienceViewMode}
                experiencePreview={true}
                experienceProps={{ item: experience }}
                embedded={false}
                mobileUploadMode={false}
                rearrangeMode={false}
                readingModeEnabled={false}
                printMode={false}
                topicProps={null}
                parentId={topicId}
                parentIdxs={[i]}
                nestingLevel={1}
                gradingConfig={{}}
                params={{
                  by_experience_id: experience.id,
                  by_parent_id: topicId,
                }}
                createUpdateParams={{
                  experience_id: experience.id,
                  parent_id: topicId,
                }}
                libraryModalProps={{}}
                isRoute={false}
                {...routeProps}
                config={{
                  list: {
                    shouldExecuteRequest: false,
                  },
                }}
              />
            )}
          />
        );

        renderItems.push(
          <div className="segments-list" key={`segments-${topicId}`}>
            {segmentsComponent}
          </div>
        );
      });
    }
    return renderItems;
  }, [topics, path, experienceViewMode, experience]);

  // Using custom hook for Intersection Observer API
  const { visibleCount, setVisibleCount, loadingDataThroughObserver } = useIntersectionObserver(finalRender);

  // Closing preview on unmount
  useEffect(() => {
    return () => {
      dispatch(experienceReduxActions.setShowPreviewSuccess({ value: false }));
      clearTimeout(scrollTimeoutId);
    };
  }, [dispatch]);

  const duplicateBtn = (
    <DuplicateExperienceButton
      item={experience}
      sourceId={experience.uid}
      isLibrary={true}
      withText={true}
      showOpenBtn={false}
      duplicateButtonPlacement="left"
      buttonShape="square"
    />
  );

  const closePreview = () => {
    dispatch(experienceReduxActions.setShowPreviewSuccess({ value: false }));
    dispatch(experienceReduxActions.setActiveSuccess({ id: null }));
  };

  const scrollToSection = (key) => {
    const sectionElement = document.getElementById(`section-${key}`);
    if (sectionElement) {
      sectionElement.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const handleSelectChange = (key) => {
    const selectedTopicIndex = topics.findIndex((topic) => topic.id === parseInt(key, 10));
    if (selectedTopicIndex !== -1) {
      // Calculate the number of elements in finalRender for the selected topic
      const updatedVisibleCount = (selectedTopicIndex + 1) * 2;
      const isScrollable = visibleCount >= finalRender.length;

      // Only update visibleCount if the new count is greater
      if (updatedVisibleCount > visibleCount) {
        setVisibleCount(updatedVisibleCount); // Ensure the section is visible
      }

      // Check if preview data is loaded
      isScrollable
        ? scrollToSection(key)
        : scrollTimeoutId = setTimeout(() => scrollToSection(key), 1500);
    }
  };

  if (_.isEmpty(experience)) {
    return null;
  }

  return (
    <Col span={24} className="experience-item-preview-container">
      <Card
        className="experience-item-preview-card"
        title={
          <Space className="experience-item-preview-card-space">
            <span>
              <Title level={4}>
                {experience.name.length > 25
                  ? `${experience.name.substring(0, 25)}...`
                  : experience.name}
              </Title>
            </span>
            {initialSelectTopicValue && (
              <Select
                className="experience-item-preview-select"
                onChange={handleSelectChange}
                defaultValue={initialSelectTopicValue}
              >
                {topics.map((topic) => (
                  <Select.Option
                    key={`${topic.section_segment_attributes.title}-${topic.id}`}
                    value={topic.id}
                  >
                    <RenderHtml
                      text={
                        topic.section_segment_attributes?.title?.length > 15
                          ? `${topic.section_segment_attributes.title.substring(0, 15)}...`
                          : topic.section_segment_attributes?.title
                      }
                    />
                  </Select.Option>
                ))}
              </Select>
            )}
            <Space size="middle">
              {duplicateBtn}
              <Tooltip
                title={mrIntl("ExperienceItemPreview.close_preview")}
                placement="top"
              >
                <Button
                  icon={<CloseOutlined />}
                  onClick={() => closePreview()}
                  shape="circle"
                />
              </Tooltip>
            </Space>
          </Space>
        }
      >
        <Col id="experince-preview-scroll" span={24}>
          {experienceLoading ? (
            <Skeleton paragraph={{ rows: 20 }} />
          ) : (
            <>
              {finalRender.slice(0, visibleCount).map((item, index) => {
                const isLastVisible = index === visibleCount - 1;
                const keyParts = item.key ? item.key.split("-") : [];
                const topicId = keyParts.length > 2 ? keyParts[2] : null;

                return (
                  <div
                    className={isLastVisible ? "last-visible" : ""}
                    key={item.key || `topic-key-${topicId}`}
                    id={topicId ? `section-${topicId}` : undefined}
                  >
                    <Suspense fallback={<Skeleton />}>{item}</Suspense>
                  </div>
                );
              })}
              {loadingDataThroughObserver && <Skeleton active/>}
            </>
          )}
        </Col>
      </Card>
    </Col>
  );
};

ExperienceItemPreview.defaultProps = {};

ExperienceItemPreview.propTypes = {};

export default ExperienceItemPreview;
