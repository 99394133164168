import { fromJS } from "immutable";
import { MrReduxCrud } from "mr_react_framework";
import { axiosInstance } from "/src/api/apiModule";
import { message } from "/src/components/UI/AntdAppHelper";
import OfflineAppService from "/src/lib/utils/offlineAppService";
import { delay, put, select } from "redux-saga/effects";
import { setToLS } from "/src/lib/utils/helperMethods";
import { appTypeSelector } from "./offlineAppSelectors";
import { getAppVersion, isNewAppVersion } from "./offlineAppHelper";
// console.log( "OfflineAppService", OfflineAppService );
const reduxCrud = new MrReduxCrud({
  axiosInstance,
  resourceName: "offline_app",
  message,
  actionNames: ["EXECUTE_METHOD", "QUITREQUESTED"],
});

export function* executeMethodSaga(action) {
  const {payload, options={}} = action;
  const appType = yield select(appTypeSelector());
  const currentAppVersion = getAppVersion(appType);
  
  let executeMethodArr = [];
  let executeMethodArgs;
  
  if(Array.isArray(payload)){
    executeMethodArr = payload;
  } else {
    executeMethodArr = 
      [{
        key: payload.key,
        value: payload.value
      }];
    // executeMethodArgs = {
    //   key: payload.key,
    //   value: payload.value
    // };
  }
  // let url = "sessions.json";
  try {
    if(executeMethodArr.length > 0){
      
      for(var element of executeMethodArr){  
        yield put(actions.executeMethodStart({key: element.key, value: element.value}));
        try {
          console.log("execute methods ==>", executeMethodArr, executeMethodArr.length)
          let finalResponse = null;

          finalResponse = yield OfflineAppService.executeAppMethod(element);

          if (!finalResponse) {
            // We should not set wrong information in finalResponse - after confirming cases remove this also
            finalResponse = element.value;
          }
          
          yield delay(1);
          console.log("executeMethodSuccess ==>", finalResponse);
          // yield localStorage.setItem("token", response.data.token);
          yield put(actions.executeMethodSuccess({ key: element.key, response: finalResponse }));
          // Call successCallback from element when getting multiple offline action in exececute method
          //For Example :
          // dispatch(
          //   offlineAppActions.executeMethod([
          //     { key: "changeSecurity", value: true },
          //     { key: "setWhitelistedUrls", value: whitelistedUrls },
          //   ])
          // );

          if (element && element.successCallback) {
            element.successCallback(finalResponse);
          }
        } catch (error) {
          // Failing even if one fails
          console.log( "executeMethodFail error", error );

          // HACK to fix - TODO: closeallapps is timing out - need to fix this. not triggering alert error for now
          // condition commented due now use noCallback true on offlineAppService method to avoid this
          //if(element.key !== "closeAllApps"){
            yield put(actions.executeMethodFail({...element, error: error}));
            if(element && element.errorCallback){
              element.errorCallback(error)
            }
            if (import.meta.env.VITE_MODE === "staging") {
              message.error(`error in executeMethodSaga ${element.key} - ${error.message}`);
            }
          //}
        }
      };
      
    } else {
      // const response = yield OfflineAppService.executeAppMethod(executeMethodArgs);
      // console.log( "executeMethodStart", response );
      // let finalResponse = response;
      // if(!finalResponse){
      //   finalResponse = payload.value;
      // }
      // // yield localStorage.setItem("token", response.data.token);
      // yield put(actions.executeMethodSuccess({key: payload.key, response: finalResponse}));
      // //call successCallback from payload when getting single offline action in exececute method
      // //For example :
      // //dispatch(
      // //   offlineAppActions.executeMethod({
      // //     key: "changeSecurity",
      // //     value: true,
      // //   })
      // // );
      // if (payload && payload.successCallback) {
      //   payload.successCallback(finalResponse);
      // }
    }
    if(options.successCallback){
      options.successCallback()
    }
  } catch (error) {
    console.log( "executeMethodFail error", error );
    if (import.meta.env.VITE_MODE === "staging") {
      message.error(`error in executeMethodSaga - ${error.message}`)
    }
    if(options.errorCallback){
      options.errorCallback(error)
    }
    yield put(actions.executeMethodFail({error: error}));
  }
}

// export function* executeMethodSaga(action) {
//   const {payload} = action;
//   const executeMethodArgs = {
//     key: payload.key,
//     value: payload.value
//   };
//   // let url = "sessions.json";
//   try {
//     yield put(actions.executeMethodStart());
//     const response = yield OfflineAppService.executeAppMethod(executeMethodArgs);
//     console.log( "executeMethodStart", response );
//     let finalResponse = response;
//     if(!finalResponse){
//       finalResponse = payload.value;
//     }
//     // yield localStorage.setItem("token", response.data.token);
//     yield put(actions.executeMethodSuccess({key: payload.key, response: finalResponse}));
//   } catch (error) {
//     console.log( "executeMethodFail error", error );
//     yield put(actions.executeMethodFail({error: error}));
//   }
// }

function executeMethodStart(state = initialState, action) {
  const { payload } = action;
  const methodLoading = `${payload.key?.toLowerCase()}loading`;
  return state.set(payload.key, null).set("loading", true).set("executemethodloading", true).set(methodLoading, true);
}

function executeMethodSuccess(state = initialState, action) {
  const { payload } = action;
  // TODO: update state here according to data
  console.log( "executeMethodSuccess", action );
  const methodLoading = `${payload.key?.toLowerCase()}loading`;

  let finalState = state.set(payload.key, payload.response).set("loading", false).set("executemethodloading", false).set(methodLoading, false);
  
  if (payload.key === "getSecurityStatus") {
    finalState = finalState.set("changeSecurity", payload.response);
  }
  
  return finalState;
}

function executeMethodFail(state = initialState, action) {
  const { payload } = action;
  const methodLoading = `${payload.key?.toLowerCase()}loading`;

  let finalState = state.set("error", payload.error).set("loading", false).set("executemethodloading", false).set(methodLoading, false);
  
  if (payload.key === "changeSecurity") {
    finalState = finalState.set("changeSecurity", "failed");
  }

  return finalState
}

function quitRequestedSuccess(state = initialState, action) {
  const { payload } = action;
  setToLS("quitRequested", payload.quitRequested);
  return state.set("quitRequested", payload.quitRequested);
}

const initialState = fromJS({
  offlineData: {},
  error: null,
  loading: false
});

export const actions = reduxCrud.getActions();
export const actionTypes = reduxCrud.getActionTypes();
export const reducer = reduxCrud.getReducer(initialState, {
  [actionTypes.EXECUTE_METHOD_OFFLINE_APP_START]: executeMethodStart,
  [actionTypes.EXECUTE_METHOD_OFFLINE_APP_SUCCESS]: executeMethodSuccess,
  [actionTypes.EXECUTE_METHOD_OFFLINE_APP_FAIL]: executeMethodFail,
  [actionTypes.QUITREQUESTED_OFFLINE_APP_SUCCESS]: quitRequestedSuccess,
});
export const watchOfflineApp = reduxCrud.generateWatchSaga({
  [actionTypes.EXECUTE_METHOD_OFFLINE_APP]: executeMethodSaga,
});
export default reduxCrud;