import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { actions as loginActions } from "/src/views/Auth/Login/redux";
import * as Sentry from "@sentry/react";
import queryString from "query-string";
import { getFromLS, setToLS } from "/src/lib/utils/helperMethods";
import { getDeviceInfo } from "./offlineAppHelper";
import { appTypeSelector } from "./offlineAppSelectors";

export const CheckAndSetAppType = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const appTypeFromState = useSelector(appTypeSelector());

  console.log("appTypeFromState ==>>", appTypeFromState);
  useEffect(() => {
    try {
      if (appTypeFromState === "") {

        let queryParams;
        if (location) {
          queryParams = queryString.parse(location.search);
        }

        console.log("window.navigator.userAgent ==>", window.navigator.userAgent, queryParams);

        let appType = "web";
        // let appType = "cros";
        // let appType = "mac";
        // let appType = "ios";
        let appValid = true;
        let userAgent = window.navigator.userAgent;
        // appType = "seb"
        if (userAgent && userAgent.indexOf("AssessPrepSEB_HDC5hjdqmjypb3qxn") > -1) {
          appType = "seb";
        }
        if (window.downloadHelper) {
          appType = "win";
        } else if (userAgent && userAgent.indexOf("AssessPrepMac") > -1) {
          // To do with custom Suffix in userAgent like SEB. current condition is setting appType to mac for ipad also
          appType = "mac";
        } else if (userAgent && userAgent.indexOf("AssessPrepIOS") > -1) {
          // To do with custom Suffix in userAgent like SEB. current condition is setting appType to mac for ipad also
          appType = "ios";
        } else if (userAgent && userAgent.indexOf("CrOS") > -1 && ((queryParams && queryParams.appType === "cros") || (getFromLS("appType") == "cros"))) {
          // CrOS for Chromebooks
          setToLS("appType", "cros")
          appType = "cros";
        }
        // Sentry.captureMessage(`CheckAndSetAppType appType ${appType} - ${JSON.stringify(queryParams)}`);

        if (appType !== "web") {
          Sentry.setTag("AssessPrepAppType", appType);
          Sentry.setContext("App device info", getDeviceInfo(appType));
        }
        dispatch(loginActions.initAppSuccess({ appType, appValid }));
        // throw new Error("Test device info tags");
      }
    }
    catch (error) {
      console.log("Sentry capture", error);
      Sentry.captureException(error);
    }
    
  }, [appTypeFromState]);

  return <></>;
};
