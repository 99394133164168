import React, { useCallback, useEffect, useState } from "react";
import { MrSelect } from "mr_react_framework";
import { useDispatch, useSelector } from "react-redux";
import _, { debounce } from "lodash";
import {
  Switch,
  Radio,
  List,
  Tag,
  Tooltip,
  Checkbox,
  Button,
  Row,
  Space,
  Alert,
  Col,
} from "antd";
import { actions as experienceActions } from "/src/views/Experiences/redux";
import { actions as genericActions } from "/src/App/genericRedux";
import CustomListItem from "/src/components/CustomListItem/CustomListItem";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { enabledFeaturesSelector } from "/src/views/Auth/Login/selector";
import { activeAdjustedExperienceSelector, experienceLoadingSelector } from "/src/views/Experiences/selector";
import { message } from "/src/components/UI/AntdAppHelper";

const SelectStudents = MrSelect({
  actions: genericActions,
  resourceName: "students",
  apiResourceName: "users",
  config: {
    url: "/users",
    // params: {by_role: "student", by_org_programme_id: org_programme_id, by_grade_id: grade_id}, //default is org_id on BE
    params: { by_role: "student", page_size: 300 }, //default is org_id on BE
    processData: (data, props) => {
      console.log("data select groups", data);
      console.log("props select groups", props);
      return data.map((item) => {
        console.log("class selector item", item);
        let label = item.name;
        const config = props.config || {};
        if(config.showGradeLabel && item?.grade) {
          label = (
            <Space className="grade-label-container">
              {item.name}
              <Tag>
                {item?.grade?.name}
              </Tag>
            </Space>
          );
        }
        return { label: label, value: item.id, name: item.name };
      });
    },
    searchApi: true,
    widgetConfig: {
      showSearch: true,
      showArrow: false,
      filterOption: true,
      optionFilterProp: "name",
      autoClearSearchValue: false, // If true then on select it removes filter and shows entire data
      maxTagCount: 'responsive',
      placeholder: <MrTranslate id={"CommonText.search_for_students"}/>,
      // placeholder: "Search for students",
      mode: "multiple",
      style: { width: "100%" },
    },
  },
});

const SelectGroups = MrSelect({
  actions: genericActions,
  resourceName: "groups",
  apiResourceName: "groups",
  config: {
    url: "/groups",
    // params: {org_id: "value"},
    params: { page_size: 100 },
    processData: (data, props) => {
      console.log("data select groups", data);
      console.log("props select groups ===>", props);
      return data.map((item) => {
        console.log("class selector item", item);
        let label = item.name;
        if(props?.config?.showGradeLabel) {
          label = (
            <Space className="grade-label-container">
              {item.name}
              <Tag>
                {item.grade_label}
              </Tag>
            </Space>
          );
        }
        return { label: label, value: item.id, name: item.name };
      });
    },
    searchApi: true,
    widgetConfig: {
      showSearch: true, // if false, this will either load entire data from backend on widget load or onSearch
      // TBD: If false, need to be able to search. Also if can do regular select from redux with this component, then can use same everywhere.
      showArrow: false,
      filterOption: true,
      optionFilterProp: "name",
      autoClearSearchValue: false, // If true then on select it removes filter and shows entire data
      placeholder: <MrTranslate id={"CommonText.search_for_classes"}/>,
      // placeholder: "Search for classes",
      mode: "multiple",
      style: { width: "100%" }
    },
  },
});

const AllowOnlineSwitch = (props) => {
  const { student, settings = {}, onMembershipChange } = props;
  const mrIntl = useTranslate()

  return (
    <>
      <Tooltip
        // title={"Allow taking the test online with security"}
        title={ mrIntl("ExperienceMemberships.allow_taking_the_test_online_with_security")}
        placement="left"
      >
        <Switch
          size={"small"}
          defaultChecked={settings.allow_online}
          // checked={settings.allow_online}
          onChange={(value) => onMembershipChange(student.id, 'student', "settings_changed", {allow_online: value})}
        />
      </Tooltip>
    </>
  );
};

// Student List
  const DisableCheckbox = (props) => {
    const { student, emStudents, onMembershipChange, experience } = props;
    const mrIntl = useTranslate();
    let checked = false;
    const emStudent = _.find(emStudents, { memberable_id: student.id });
    if (!emStudent || (emStudent && !emStudent.archived)) {
      checked = true;
    }
    return (
      <Tooltip title={checked ? mrIntl("CommonText.unselect") : mrIntl("CommonText.select")} placement="left">
        <Checkbox
          defaultChecked={checked}
          // checked={checked}
          disabled={experience.status !== "draft" || experience.is_mb_linked}
          onChange={(e) => onMembershipChange(student.id, 'student', e.target.checked ? "added" : "removed" )}
        />
      </Tooltip>
    );
  };

  const Title = (props) => {
    const { student, mapClassesOrStudents } = props
    let title = [];

    if (mapClassesOrStudents === "groups") {
      title.push(<DisableCheckbox key={`disable-checkbox-${student.id}`} {...props}/>);
    }
    title.push(
      <b>
        {" "}
        {student.first_name} {student.last_name}
      </b>
    );
    return title;
  };

const ExperienceMemberships = (props) => {
  console.log("ExperienceMemberships props", props);
  const {  formInstance: { getFieldValue, setFieldsValue, validateFields, submit } } = props;
  
  const mrIntl = useTranslate();
  const dispatch = useDispatch();

  // const allowGuests = getFieldValue(["settings", "allow_guests"])
  // const allowUnmappedStudents = getFieldValue(["settings", "allow_unmapped_students"])
  const mapClassesOrStudents = getFieldValue(["settings", "map_classes_or_students"])
  const currentGradeOnly = getFieldValue(["settings", "current_grade_only"]) === undefined ? true : getFieldValue(["settings", "current_grade_only"])

  console.log("mapClassesOrStudents ==>", mapClassesOrStudents)
  
  const enabledFeatures = useSelector(enabledFeaturesSelector())
  const experience = useSelector(activeAdjustedExperienceSelector())
  // const experienceLoading = useSelector(experienceLoadingSelector())
  console.log("experience ======>", experience);

  const experience_membership_group_ids = experience.experience_membership_group_ids || []
  const experience_membership_student_ids = experience.experience_membership_student_ids || []
  const [experienceMembershipGroupIds, setExperienceMembershipGroupIds] = useState(experience_membership_group_ids);
  const [experienceMembershipStudentIds, setExperienceMembershipStudentIds] = useState(experience_membership_student_ids);
  // Note: Merging both groups and students memberships so that we can iterate them for archiving if map_classess_or_students switched
  // Note: Not using from form fields because after submitting form does not rerender with new data - if we rerender form then Select drop down gets close on every onChange
  const experienceMembershipsGroups = experience.experience_memberships_groups || [];
  const experienceMembershipsStudents = experience.experience_memberships_students || [];
  const experienceMembershipsAttributes = [...experienceMembershipsGroups, ...experienceMembershipsStudents]

  // useEffect(() => {
  //   console.log("experience membership attributes updated");
  //   setFieldsValue({experience_memberships_attributes: experienceMembershipsAttributes})
  // }, [])
  
  // const possibleStudents = _.unionBy(experience.group_students, experience.students, (student) => student.id);

  const possibleStudents =
    (mapClassesOrStudents === "groups"
      ? experience.group_students
      : experience.students) || []  // when students, then mapped and possible list of students is the same. only different for when groups selected

  // const debounceSubmitUsingValidateFields = useCallback(debounce(submit, 1000), []);
  // const callMe = () => {
  //   console.log("called Me ======>");
  //   submit()
  // }

  const debouncedSubmitForm = useCallback(debounce(submit, 1000), []);

  // const updateExperience = debounce(() => 
  //   dispatch(experienceActions.update(experience)
  // ), 1000)

  // const getLatestExperience = useCallback(updateExperience, [experience.uid]);

  useEffect(() => {
    if (experienceMembershipGroupIds.length !== experience_membership_group_ids.length) {
      setExperienceMembershipGroupIds(experience_membership_group_ids)
    }
    if (experienceMembershipStudentIds.length !== experience_membership_student_ids.length) {
      setExperienceMembershipStudentIds(experience_membership_student_ids)
    }
  }, [experience_membership_group_ids, experience_membership_student_ids])

  const onMembershipChange = (memberable_id, memberable_type, action, settings, archiveDifferentMemberableType) => {
    // const experienceMembershipsAttributesFromField = getFieldValue(["experience_memberships_attributes"])
    // const newExperienceMembershipsAttributes = _.unionBy(experienceMembershipsAttributesFromField, experienceMembershipsAttributes, (ema) => ema.memberable_id + ema.memberable_type);

    const newExperienceMembershipsAttributes = experienceMembershipsAttributes
    console.log("onMembershipChange =====>", memberable_id, action, settings, newExperienceMembershipsAttributes);
    const archived = action === 'removed' ? true : false

    // // Note: doing this because when we are double clicking on same student or class then two request are send to backend for same student or class
    // const emaIndex = experienceMembershipsAttributesFromField.findIndex((ema) => ema.memberable_type === memberable_type && ema.memberable_id === memberable_id && ema.archived === archived)
    // console.log("foundIndex =====>", emaIndex);
    // if(emaIndex >= 0) {
    //   return
    // }

    const index = newExperienceMembershipsAttributes.findIndex((ema) => ema.memberable_id === memberable_id)
    const member = newExperienceMembershipsAttributes[index] || {}

    console.log("index, member, memberable_id ==>", index, member, memberable_id)
    console.log("experienceMembershipsAttributes ==>", experienceMembershipsAttributes);
    let updatedMember = {
      ...member,
      experience_id: experience.id,
      memberable_type: memberable_type,
      memberable_id: memberable_id,
      archive_different_memberable_type: archiveDifferentMemberableType,
      archived: archived
    }

    if (action === "settings_changed") {
      updatedMember = {
        ...updatedMember,
        settings: {
          ...updatedMember.settings,
          ...settings,
        }
      }
    }

    // if (index >= 0) {
    //   newExperienceMembershipsAttributes[index] = updatedMember
    // } else {
    //   newExperienceMembershipsAttributes.push(updatedMember)
    // }

    // // Note: which we switch from class to student or vice-versa then we need to archive the other mapped memberships
    // if (archiveDifferentMemberableType) {
    //   newExperienceMembershipsAttributes.forEach((ema, index) => {
    //     if (!ema.archived) {
    //       if ((mapClassesOrStudents === "groups" && ema.memberable_type === "student") || (mapClassesOrStudents === "students" && ema.memberable_type === "group")) {
    //         newExperienceMembershipsAttributes[index].archived = true
    //         // newExperienceMembershipsAttributes.push(ema)
    //       }
    //     }
    //   })
    // }

    // console.log("newExperienceMembershipsAttributes ==>", newExperienceMembershipsAttributes)

    // NOTE: Sending only experience_memberships_attributes which need to be updated
    // NOTE: Setting hidden form field and using validateFields trigger to submit form with changed values as this component is not Form.Item
    // setFieldsValue({experience_memberships_attributes: newExperienceMembershipsAttributes})
    
    dispatch(experienceActions.updateMembership(updatedMember, {
      // experience: experience,
      successCallback: () => {
        // message.success(`${mapClassesOrStudents === "groups" ? "Class" : "Student"} ${action === "settings_changed" ? "settings updated" : action} successfully`);
        debouncedSubmitForm()
      },
      errorCallback: () => {
        message.error(`Something went wrong. Please try again.`);
      }
    }));

  }

  let grade_id;
  let subject_id;
  let subject_label;
  const relationItems = experience.relation_items || [];
  if (relationItems[0]) {
    if (
      relationItems[0].grade_items &&
      relationItems[0].grade_items[0]
    ) {
      grade_id = relationItems[0].grade_items[0].grade_id;
    }
    if (
      relationItems[0].subject_items &&
      relationItems[0].subject_items[0]
    ) {
      subject_id = relationItems[0].subject_items[0].subject_id;
      subject_label = experience.custom_fields.subject_item_label;
    }
  }


  let finalRender = [];
  
  if (mapClassesOrStudents === "groups") {
    let groupSelectParams = {};
    if(currentGradeOnly) {
      groupSelectParams.by_grade_id = grade_id;
    }
    if (subject_id && subject_label != "IDU") {
      // for IDU subject show all classes
      groupSelectParams.by_subject_id = subject_id;
    }
    finalRender.push(
      <SelectGroups
        className="m-t-8"
        key={`deliver-select-${mapClassesOrStudents}`}
        // defaultValue={experience.experience_membership_group_ids}
        // value={experience.experience_membership_group_ids}
        value={experienceMembershipGroupIds}
        onChange={(value) => {
          // updateMembership(value, updateResource, experience);
          setExperienceMembershipStudentIds([])
          setExperienceMembershipGroupIds(value)
        }}
        // onBlur={(value) => validateFields()}
        onSelect={(value) => onMembershipChange(value, 'group', 'added', {}, true)}
        onDeselect={(value) => onMembershipChange(value, 'group', 'removed', {}, true)}
        config={{
          forceReload: false,
          clearValueOnParamsChange: false,
          showGradeLabel: !currentGradeOnly ? true : false,
          params: groupSelectParams,
          widgetConfig: {
            disabled:
              experience.status !== "draft" || experience.is_mb_linked
                ? true
                : false,
          },
        }}
      />
    );
  } else {
    let studentSelectParams = {}
    if(currentGradeOnly) {
      studentSelectParams.by_grade_id = grade_id;
    }
    finalRender.push(
      <SelectStudents
        className="m-t-8"
        key={`deliver-select-${mapClassesOrStudents}`}
        // defaultValue={experience.experience_membership_student_ids}
        // value={experience.experience_membership_student_ids}
        value={experienceMembershipStudentIds}
        onChange={(value) => {
          // updateMembership(value, updateResource, experience);
          setExperienceMembershipGroupIds([])
          setExperienceMembershipStudentIds(value)
        }}
        // onBlur={(value) => validateFields()}
        onSelect={(value) => onMembershipChange(value, 'student', 'added', {}, true)}
        onDeselect={(value) => onMembershipChange(value, 'student', 'removed', {}, true)}
        config={{
          forceReload: false,
          clearValueOnParamsChange: false,
          showGradeLabel: !currentGradeOnly ? true : false,
          params: studentSelectParams,
          widgetConfig: {
            // removeIcon: null,
            disabled:
              experience.status !== "draft" || experience.is_mb_linked
                ? true
                : false,
          },
        }}
      />
    );
  }

  // Student List
  const DisableCheckbox = (props) => {
    const { student, emStudents } = props;
    let checked = false;
    const emStudent = _.find(emStudents, { memberable_id: student.id });
    if (!emStudent || (emStudent && !emStudent.archived)) {
      checked = true;
    }
    return (
      <>
        <Tooltip title={checked ? mrIntl("CommonText.unselect") : mrIntl("CommonText.select")} placement="left">
          <Checkbox
            checked={checked}
            disabled={experience.status != "draft" || experience.is_mb_linked}
            onChange={(e) => onMembershipChange(student.id, 'student', e.target.checked ? "added" : "removed" )}
          />
        </Tooltip>
      </>
    );
  };

  const Title = ({student, emStudents }) => {
    let title = [];
    if (mapClassesOrStudents == "groups") {
      title.push(<DisableCheckbox student={student} emStudents={emStudents} />);
    }
    title.push(
      <b>
        {" "}
        {/* {student.first_name} {student.last_name} */}
        {student.name}
      </b>
    );
    return title;
  };

  const extraActions = (student, emStudents) => {
    const actions = [];
    if (experience.settings.mode == "offline" && !enabledFeatures.new_join_view_with_dynamic_config) {
      const emStudent = _.find(emStudents, { memberable_id: student.id });
      if (!emStudent || (emStudent && !emStudent.archived)) {
        let settings = (emStudent || {}).settings;
        actions.push(
          <AllowOnlineSwitch
            key={`allow-online-switch-${student.id}`}
            student={student}
            settings={settings}
            onMembershipChange={onMembershipChange}
          />
        );
      }
    }
    return actions;
  };
  // const ExtraInfo = ({ item }) => {
  //   const extra = [];
  //   extra.push(<Tag>{item.s_code}</Tag>);
  //   return extra;
  // };

  possibleStudents && possibleStudents.length !== 0
    ? finalRender.push(
        <List
          key={"mapped-students-list"}
          className="mapped-students-list m-t-25"
          header={
            <Row>
              <Col span={20}>
                {mrIntl("ExperienceMemberships.mapped_students")} ({possibleStudents.length})
              </Col>
              {/* <Col span={4} style={{textAlign: "right"}}><RestrictJoinToMappedStudents item={experience} updateResource={updateResource} /></Col> */}
            </Row>
          }
          dataSource={possibleStudents}
          rowKey={(item) => `mapped-students-list-item-${item.id}`}
          // loading={experienceLoading}
          // students are users not ems so don't have settings, need to send that in using experience.experience_memberships_students
          renderItem={(item, i) => (
            <CustomListItem
              type={"mapped-students"}
              item={item}
              title={
                <Title
                  key={`mapped-student-list-item-title-${item.id}`}
                  student={item}
                  emStudents={experience.experience_memberships_students}
                  mapClassesOrStudents={mapClassesOrStudents}
                  onMembershipChange={onMembershipChange} 
                  experience={experience}
                />
              }
              extraActions={extraActions(
                item,
                experience.experience_memberships_students
              )}
            />
          )}
        />
      )
    : experience.status == "published"
      ? // finalRender.push(
        //   <Alert
        //     className={"m-t-25"}
        //       message={mrIntl("ExperienceMemberships.published_warning_message")}
        //     // message="No students mapped. Students will have to find and join this assessment using the Join Code."
        //     type="warning"
        //     showIcon
        //   />
        // )
        finalRender.push("")
      : finalRender.push("");

  return finalRender;
};

export default ExperienceMemberships;
