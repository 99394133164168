import React, { useState } from "react";
import { Button, Tag, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { actions as experienceUserActions } from "/src/views/ExperienceUsers/redux";
import { formatJsonApiData } from "mr_react_framework";
import ResponseAttachments from "/src/views/Segments/ResponseAttachments/ResponseAttachments";
import { showConfirmModal } from "./Segment/UIHelper";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { getUniqueId } from "/src/lib/utils/helperMethods";
import { activeAdjustedExperienceSelector } from "/src/views/Experiences/selector";
import { usePollingEffect } from "/src/views/Segments/InteractiveHelpers";
import { enabledFeaturesSelector } from "/src/views/Auth/Login/selector";

export const useAutoMappingProgress = (experienceUser, shouldPoll, onSuccessCallback) => {
  console.log("useAutoMappingProgress ===>", {experienceUser, shouldPoll});
  const dispatch = useDispatch();
  const checkAutomappingProgress = (clearIntervalCallback) => {
    dispatch(experienceUserActions.show({ 
      id: experienceUser.id,
    }, {
      successCallback: (response) => {
        const updatedExperienceUser = formatJsonApiData(response.data.experience_user);        
        const updatedAutoMappingProgress = updatedExperienceUser.custom_fields?.["auto_mapping_progress"];
                
        if(onSuccessCallback) {
          onSuccessCallback(updatedExperienceUser)
        }
        const progressCompleted = ["completed", "failed"].includes(updatedAutoMappingProgress?.status);
        if (progressCompleted) {
          clearIntervalCallback();
          // message[updatedAutoMappingProgress.status === "completed" ? "success" : "error"](`${updatedAutoMappingProgress["message"]}. ${updatedAutoMappingProgress.status === "failed" ? "Please try again." : ""}`);
        }
      }
    }));
  };

  usePollingEffect(shouldPoll, checkAutomappingProgress, 10000);
};

const ViewUploadsModal = ({ count = null, item = {}, type = "", shape = "", size = "", buttonText = "", icon = null, disabled = false }) => {
  console.log("ViewUploadsModal ==>", { count, item, type, shape, size, icon })
  const [isModalOpen, setIsModalOpen] = useState(false);
  const mrIntl = useTranslate()
  const dispatch = useDispatch();

  const experience = useSelector(activeAdjustedExperienceSelector());

  
  const experienceSettings = experience.settings || {};
  const enabledFeatures = useSelector(enabledFeaturesSelector())
  const allowManualMapping = enabledFeatures.allow_manual_mapping;
  
  const experienceUser = item;
  const customFields = experienceUser.custom_fields || {};
  const autoMappingProgress = customFields["auto_mapping_progress"];
  const pdfConversionProgress = customFields["pdf_conversion_progress"];
  const isQuestionMappingEnabled = experienceSettings.mode === "paper" || allowManualMapping;

  const shouldPoll =
    (autoMappingProgress || pdfConversionProgress) &&
    ((autoMappingProgress &&
      !["failed", "completed"].includes(autoMappingProgress["status"])) ||
      (pdfConversionProgress &&
        !["failed", "completed"].includes(pdfConversionProgress["status"])));
  
  useAutoMappingProgress(experienceUser, shouldPoll);

  const uploadButtonStyle = {
    width: "100%", 
    height: "100%",
    textAlign: "center"
  }

  const submitAttachments = (
    attachments_json,
    options,
  ) => {
    const { submitAttachmentsStartCallback, submitAttachmentsCompletedCallback } = options;
    console.log(
      "submit attachments ==>",
      attachments_json,
      submitAttachmentsCompletedCallback
    )
    submitAttachmentsStartCallback();
    dispatch(
      experienceUserActions.submitAttachments(
        {
          id: item.id,
          experience_id: item.experience_id,
          user_id: item.user_id,
          // Disabling upload for students if teacher uploads first
          uploads_allowed: false,  
          attachments_count:
            attachments_json.attachments && attachments_json.attachments.length,
          attachments_json: attachments_json,
        },
        {
          success: {
            showMessage: true,
            message: "Files submitted successfully.",
          },
          successCallback: (response) => {
            const finalExperienceUser = formatJsonApiData(
              response.data.experience_user
            );
            const updatedExperienceUser = {
              ...item,
              ...finalExperienceUser,
            };
            console.log(
              "response after upload =>",
              item,
              response,
              finalExperienceUser,
              updatedExperienceUser
            );
            dispatch(
              experienceUserActions.showSuccess(
                { data: { experience_user: updatedExperienceUser } },
                { success: { showMessage: false } }
              )
            );
            // setTriggerCheckProgressInterval(getUniqueId())
            submitAttachmentsCompletedCallback(response);
            if (!options.doNotCloseModal) {
              setIsModalOpen(false);
            }
          },
          errorCallback: (error) => {
            submitAttachmentsCompletedCallback(error);
          },
        }
      )
    );
  };

  const handleSubmitAttachments = (
    attachmentsJson,
    options
  ) => {
    if (!options.doNotShowConfirmModal) {
      showConfirmModal({
        centered: true,
        title: mrIntl("ViewUploadsModal.submitting_attachments"),
        content: mrIntl("ViewUploadsModal.are_you_sure_you_want_to_submit_these_files_these"),
        onOk: () => {
          submitAttachments(
            attachmentsJson,
            options
          );
        },
        onCancel: () => {},
        mrIntl: mrIntl
      });
    } else {
      submitAttachments(
        attachmentsJson,
        options
      );
    }
  };

  const handleCloseModal = (changed) => {
    if (changed) {
      showConfirmModal({
        centered: true,
        title: mrIntl("ViewUploadsModal.cancel_submission"),
        content:
          mrIntl("ViewUploadsModal.some_attachments_are_not_saved_are_you_sure_you_wish"),
        onOk: () => {
          setIsModalOpen(false);
        },
        mrIntl: mrIntl
      });
    } else {
      setIsModalOpen(false);
    }
  };

  const responseAttachments = (
    <ResponseAttachments
      answerMode={!item.dup_from_id} // In case of peer review enabled, do not want to allow uploads for student reviewers who are reviewing - ISSUE: if we do then it will be complicated to track the uploads for the student some will be in reviewer eu and some will be in original submission eu
      listType={isQuestionMappingEnabled ? "text" : null}
      previewType={"modal"}
      maxAttachments={100}
      recordId={item.experience_id}
      userId={item.user_id}
      modalVisible={isModalOpen}
      attachments_json={item.attachments_json}
      deleteNotAllowedMessage={true}
      onSubmit={handleSubmitAttachments}
      closeModal={handleCloseModal}
      disableUploader={item.marked}
      disabledUploaderTooltip={mrIntl("ViewUploadsModal.please_unpublish_the_result_to_upload")}
      isQuestionMappingEnabled={isQuestionMappingEnabled}
      experienceSettings={experienceSettings}
    />
  );

  return (
    <React.Fragment>
      {isModalOpen && responseAttachments }

      <Button
        onClick={() => setIsModalOpen(true)}
        type={type}
        shape={shape}
        size={size}
        icon={icon}
        style={uploadButtonStyle}
        disabled={disabled}
      >
        <Tooltip title="Total uploads" placement="bottom">
          {count && <Tag>{count}</Tag>}
        </Tooltip>
        {buttonText}
      </Button>

    </React.Fragment>
  );
};

export default ViewUploadsModal;
