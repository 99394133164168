import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Modal,
  Input,
  Row,
  Col,
  Drawer,
  Empty,
  Timeline,
  Tooltip,
  Typography,
  Checkbox,
  Space,
  TreeSelect,
  DatePicker,
} from "antd";
import { ProfileOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { firestoreDB, firestoreFieldValue } from "/src/config/initializers";
import MrFirebase from "/src/lib/MrFirebase/MrFirebase";
import { checkMob, getUTCDate, removeHTMLTagsFromText } from "/src/lib/utils/helperMethods";
import dayjs from "dayjs";
import {
  appRegionSelector,
  currentUserSelector,
  enabledFeaturesSelector,
} from "/src/views/Auth/Login/selector";
import { useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import SegmentLogInfo from "../SegmentLogInfo";
import RenderHtml from "/src/components/UI/RenderHtml/RenderHtml";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import EllipsisContent from "/src/components/UI/EllipsisContent/EllipsisContent";
import ScreenshotLogs from "/src/views/Screenshots/ScreenshotLogs";
import LogsInTable from "../LogsInTable";
import { actions as genericActions } from "/src/App/genericRedux";
import { MrSelect } from "mr_react_framework";
// import moment from "moment";
import { isFirebaseAuthenticatedSelector } from "../../FirestoreInteractions/selector";
import Spinner from "/src/components/UI/Spinner/Spinner";
import AuditLogsFilter from "./AuditLogsFilter";

const { Text, Title } = Typography;
const { RangePicker } = DatePicker;
const { Search } = Input;

const SelectTeachers = MrSelect({
  actions: genericActions,
  resourceName: "users",
  config: {
    url: "/users",
    params: { by_role: "teacher_admin" }, //adding multiple roles and parsing on BE - want admin also here //default is org_id on BE
    processData: (data, props) => {
      console.log("updated data", data);
      return data.map((item) => {
        console.log("class selector item", item);
        return { label: item.name, value: item.id };
      });
    },
    // searchApi: false,
    searchApi: true,
    widgetConfig: {
      showSearch: true,
      filterOption: true,
      optionFilterProp: "label",
      placeholder: "Search by user",
      // mode: "multiple",
      allowClear: true,
      style: { width: "200px" },
    },
  },
});

const AuditLogs = (props) => {
  console.log("props in log modal==>", props);
  const { item, experience = {}, title, logView, log_type, itemId, org_id = null, filterOnSubmit } = props;
  const mrIntl = useTranslate()
  const currentUser = useSelector(currentUserSelector());
  const appRegion = useSelector(appRegionSelector());
  // const enabledFeatures = useSelector(enabledFeaturesSelector())
  const isFirebaseAuthenticated = useSelector(isFirebaseAuthenticatedSelector);

  const orgId = org_id ? org_id : currentUser.org_id
  const isChina = appRegion === "china" ? true : false;
  console.log("org_id =====>", org_id);
  let defaultLogParams = {}
  
  if(isChina) {
    defaultLogParams = {
      org_id: ["eq", orgId]
    }
    if(logView === "org_audit_logs") {
      defaultLogParams = {
        ...defaultLogParams,
        'category': ["in", ["experience_crud", "delivery_settings", "assign", "actions", "status" ]], 
        'created_at': [["gte", getUTCDate({ type: "startOf", daysDiff: -7 }).valueOf()], ["lte", getUTCDate({ type: "endOf" }).valueOf()]],
      }
    } else if (logView === "experience_audit_logs") {
      defaultLogParams = {
        ...defaultLogParams,
        'experience_id': ["eq", experience.id],
        // When we are passing item_id in params then we are not getting logs for segment because in case of segment item_id is segment 
        // 'item_id': ["eq", experience.id],
        'category': ["in", ["experience_crud", "delivery_settings", "assign", "actions", "status" ]], 
        'created_at': [["gte", getUTCDate({ type: "startOf", daysDiff: -90 }).valueOf()], ["lte", getUTCDate({ type: "endOf" }).valueOf()]], // Showing Experience logs for last 90 days only but filter not showing in UI
      }
    } else if (logView === "monitor_audit_logs") {
      delete defaultLogParams.org_id // Need to remove org_id from params for monitor logs as we are not saving org_id in monitor logs, experience_id will handle scope of logs here
      defaultLogParams = {
        ...defaultLogParams,
        // 'log_type': ["eq", 'experience'],
        'experience_id': ["eq", experience.id],
        'user_ids': ["containedBy", [parseInt(item.uid)]]
      }
    } else {
      // This case is not used currently
      defaultLogParams = {
        ...defaultLogParams,
        'log_type': ["eq", log_type],
        'experience_id': ["eq", experience.id],
      }
    }
  } else {
    /////////////////////////////////////////////////////////////
    //// NOTE: Remember to add index on firebase whenever you add new field in where clause
    /////////////////////////////////////////////////////////////
    defaultLogParams = {
      // org_id: ["==", org_id] // Need to index on firebase if we want to use this
    }
    if(logView === "org_audit_logs") {
      // Using user_id instead of user_ids here to filter Select Teachers - If we need teacher logs across actions then we need to use user_ids not finding any case now
      defaultLogParams = {
        ...defaultLogParams,
        'category': ["in", ["experience_crud", "delivery_settings", "assign", "actions", "status" ]], 
        'created_at': [[">=", getUTCDate({ type: "startOf", daysDiff: -1 }).valueOf()], ["<=", getUTCDate({ type: "endOf" }).valueOf()]],
      }
    }  else if (logView === "experience_audit_logs") {
      defaultLogParams = {
        ...defaultLogParams,
        'experience_id': ["==", experience.id],
        // When we are passing item_id in params then we are not getting logs for segment because in case of segment item_id is segment 
        // 'item_id': ["==", experience.id],
        'category': ["in", ["experience_crud", "delivery_settings", "assign", "actions", "status" ]], 
        'created_at': [[">=", getUTCDate({ type: "startOf", daysDiff: -90 }).valueOf()], ["<=", getUTCDate({ type: "endOf" }).valueOf()]], // Showing Experience logs for last 90 days only but filter not showing in UI
      }
    } else if (logView === "monitor_audit_logs") {
      defaultLogParams = {
        ...defaultLogParams,
        // 'log_type': ["==", 'experience'],
        // 'experience_id': ["==", experience.id],
        'user_ids': ["array-contains", parseInt(item.uid)]
      }
    } else {
      // This case is not used currently
      defaultLogParams = {
        ...defaultLogParams,
        'log_type': ["==", log_type],
        'experience_id': ["==", experience.id],
      }
    }
  }

  // if(isChina){
  //   if (logView === "monitor_audit_logs") {
  //     params["user_ids"] = ["containedBy", [parseInt(item.uid)]]
  //     // params["category"] = ["neq", "monitor_screenshot"] // TODO: neq was having some issue in china test thoroughly before using it
  //   }

  //   if(logView === "org_audit_logs") {
  //     params['org_id'] = ["eq", parseInt(currentUser.org_id)]
  //   } else {
  //     params['experience_id'] = ["eq", experience.id]
  //   }
  // } else {
  //   if (logView === "monitor_audit_logs") {
  //     params["user_ids"] = ["array-contains", parseInt(item.uid)]
  //     // params["category"] = ["not-in", ["monitor_screenshot"]]
  //   } else {
  //     if(logView === "org_audit_logs") {
  //       // params['org_id'] = ["==", parseInt(currentUser.org_id)]
  //     } else {
  //       params['experience_id'] = ["==", experience.id]
  //     }
      
  //   }
  // }

  const [logParams, setLogParams] = useState(defaultLogParams);
  const [isLogModalOpen, setIsLogModalOpen] = useState(false);
  const [showScreenshots, setShowScreenshots] = useState(false);
  const [localSearchValue, setLocalSearchValue] = useState("");

  const collectionPath = logView === "monitor_audit_logs" ? `experiences/${experience.id}/logs` : `orgs/${orgId}/logs`

  const showLogs = () => {
    setLogParams(defaultLogParams)
    setIsLogModalOpen(true);
    if(showScreenshots) {
      setShowScreenshots(false)
    }
  };

  const closeAuditLogs = () => {
    setIsLogModalOpen(false);
  };

  const LogsList = (props) => {
    console.log("log list props", props);
    const { data } = props
    const isMobile = checkMob();
    const filteredData = data.filter(item => item.log_type !== "monitor_screenshot");
    // return <List key={"logList"} dataSource={props.data} renderItem={(item) => {
    //   console.log("log item", item);
    //   return <CustomListItem key={item.uid} title={item.msg} item={item}/>
    // }} />
    if (!filteredData || filteredData.length === 0) {
      return <Empty />;
    }
   
    return (
      <Timeline mode={"left"} style={{ width: isMobile ? "100%" : "400px" }}>
        {filteredData.map((item) => {

          let messageToRender
          if(item.msg) {
            const finalMsg = removeHTMLTagsFromText(item.msg, true)
            if(item.values && item.values.hasOwnProperty('start_at_datetime')) {
              const newValue = dayjs(item.values.start_at_datetime).format(
                "MMM DD, hh:mm a"
              )
              // Add start_at_datetime in log message
              messageToRender = finalMsg.replace("{start_at_datetime_placeholder}", newValue)
            } else if(item.values && item.values.hasOwnProperty('close_at_datetime')) {
              const newValue = dayjs(item.values.close_at_datetime).format(
                "MMM DD, hh:mm a"
              )
              // Add start_at_datetime in log message
              messageToRender = finalMsg.replace("{close_at_datetime_placeholder}", newValue)
            } else {
              messageToRender = finalMsg
            }
          }

          return (
            <Timeline.Item
              key={item.uid}
              color={
                item.internet_status === "offline" ||
                item.internet_status === "network_issue"
                  ? "red"
                  : "blue"
              }
              label={
                <span style={{textAlign: "right"}}>
                  <span>{dayjs(item.created_at).format("DD-MM-YYYY")}</span><br/>
                  <span className="log-time">{dayjs(item.created_at).format("hh:mm:ss a")}</span>
                </span>
              }
            >
              {/* <Tooltip
                    color={"white"}
                    title={<LogsInfo itemId={item.item_id} setIsLogModalOpen={setIsLogModalOpen} />}
                  >
                    <span>
                      <InfoCircleOutlined />
                    </span>
                  </Tooltip> */}
                  {/* {item.msg} */}
              <EllipsisContent>
                <Text strong>{messageToRender}</Text>
                &nbsp;
                {logView === "experience_audit_logs" && 
                  experience.status === "published" &&
                  item.log_type === "segment" &&
                  (
                    <SegmentLogInfo
                      itemId={item.item_id}
                      setIsLogModalOpen={setIsLogModalOpen}
                    />
                  )}
                <br />
                {item.user_ip && <Text type="secondary"><MrTranslate id={"AuditLogs.ip"}/>: {item.user_ip}</Text>}
              </EllipsisContent>
            </Timeline.Item>
          );
        })}
      </Timeline>
    );
  };

  const FirebaseFilter = (props) => {
    console.log("AuditLogsFilter props ==>", props);
    return null;
  };

  let finalListToRender
  if(showScreenshots) {
    finalListToRender = ScreenshotLogs
  } else if(logView === "org_audit_logs"){
    finalListToRender = (data) => <LogsInTable {...data} localSearchValue={localSearchValue}/>
  } else {
    finalListToRender = LogsList
  }

  let mrFirebaseToRender =
    <MrFirebase
      firestoreDB={firestoreDB}
      mode={"not-realtime"}
      // default mode is realtime
      filter={FirebaseFilter}
      sort={{
        created_at: "desc",
      }}
      list={finalListToRender}
      currentUser={currentUser}
      // NOTE: for real time case MrFirebase is supporting only experience connection
      experience={experience}
      params={logParams}
      collectionPath={collectionPath}
      tableName={"logs"}
    />

  const logsFilter =  
    <AuditLogsFilter
      defaultLogParams={defaultLogParams}
      logParams={logParams}
      setLogParams={setLogParams}
      localSearchValue={localSearchValue}
      setLocalSearchValue={setLocalSearchValue}
      setShowScreenshots={setShowScreenshots}
      logView={logView}
      experience={experience}
      filterOnSubmit={filterOnSubmit}
      org_id={props.org_id}
    />

  console.log("audit logs params ==>", logParams);
  return (
    <>
      {logView !== "org_audit_logs" && 
        <Tooltip title={mrIntl("AuditLogs.logs")} placement="right">
          <Button
            style={{ width: "100%", textAlign: "left", paddingLeft: "0px", background: "none" }}
            // shape="circle"
            type={"text"}
            icon={<ProfileOutlined />}
            onClick={() => showLogs()}
          >
            {mrIntl("AuditLogs.logs")}
          </Button>
        </Tooltip>
      }

      {logView === "org_audit_logs" 
        ?
          <Row>
            <Col span={18}>{isFirebaseAuthenticated ? mrFirebaseToRender : <Spinner />}</Col>
            <Col span={6}>
              <Row className="logs-filters">
                {logsFilter}
              </Row>
            </Col>
          </Row>
        : isLogModalOpen &&
          <Modal
            // centered={true}
            bodyStyle={{ maxHeight: "500px", overflow: "auto" }}
            // wrapClassName={"height-centered-modal text-centered-modal"}
            wrapClassName={`logs-modal ${showScreenshots ? "full-screen-modal" :"height-centered-modal"}`}
            width={showScreenshots ? "100vw" : "600px"}
            title={title}
            open={isLogModalOpen}
            // okText={mrIntl("CommonText.ok")}
            // cancelText={mrIntl("CommonText.cancel")}
            // onOk={closeAuditLogs}
            onCancel={closeAuditLogs}
            footer={null}
          >
            {logView === "experience_audit_logs" && logsFilter}
            {isFirebaseAuthenticated ? mrFirebaseToRender : <Spinner />} 
          </Modal>
      }
    </>
  );
};

export default AuditLogs;
