import React from 'react';
import { Alert, Modal, Tag } from 'antd';
import { useTranslate } from '/src/lib/MrTranslate/MrTranslate';

const StudentQRCode = (props) => {
  console.log("AccessCode props ==>", props);
  const { setStudentQRCodeModal } = props

  return (
    <>
      <Modal
        title="Student QR Code"
        open={true}
        onCancel={() => setStudentQRCodeModal(null)}
        footer={null}
      >
      </Modal>
    </>
  );
};

export default StudentQRCode;


