import { fabric } from "fabric";
import { useCallback, useEffect, useRef, useState } from "react";
import { getUniqueId } from "/src/lib/utils/helperMethods";

const correctColor = "#389E0D";
const incorrectColor = "#FF0000";
const grayColor = "gray";

const NumberLineCanvas = (props) => {
  const [uniqueId] = useState(getUniqueId());
  const {
    autoCheckMode,
    options,
    setOptionCoords,
    presentationMode,
    minValue,
    maxValue,
    isGradingMode,
  } = props ?? {};
  const canvasRef = useRef(null);

  const canvasFill = useCallback(
    (isCorrect) => {
      if (!isGradingMode) return grayColor;

      return isCorrect ? correctColor : incorrectColor;
    },
    [isGradingMode]
  );

  useEffect(() => {
    const canvas = new fabric.Canvas(uniqueId, {
      selection: false,
    });
    canvasRef.current = canvas;

    // Canvas dimensions
    const canvasWidth = 600;
    const canvasHeight = 200;

    // Scale line configuration
    const scaleStart = 50;
    const scaleEnd = 550;
    const totalPoints = maxValue + 1 - minValue;
    const snapPoints = Array.from(
      { length: totalPoints },
      (_, i) => scaleStart + (i * (scaleEnd - scaleStart)) / (totalPoints - 1)
    );

    // Add gray background for the lower area
    const grayBackground = new fabric.Rect({
      left: 0,
      top: 140,
      width: canvasWidth,
      height: canvasHeight - 140,
      fill: "#f4f4f4",
      selectable: false,
      evented: false,
      originX: "left", // Ensure it starts from the left
      originY: "top", // Ensure it starts from the left
    });
    canvas.add(grayBackground);

    // Create the scale line
    const scaleLine = new fabric.Line([scaleStart, 100, scaleEnd, 100], {
      stroke: "black",
      strokeWidth: 2,
      selectable: false,
      evented: false,
    });
    canvas.add(scaleLine);

    // Add tick marks and labels
    snapPoints.forEach((x, i) => {
      const tick = new fabric.Line([x, 90, x, 110], {
        stroke: "black",
        strokeWidth: 1,
        selectable: false,
        evented: false,
      });
      canvas.add(tick);

      const label = new fabric.Text((minValue + i).toString(), {
        left: x - 5,
        top: 115,
        fontSize: 12,
        selectable: false,
        evented: false,
        originX: "left", // Ensure it starts from the left
        originY: "top", // Ensure it starts from the left
      });
      canvas.add(label);
    });

    // Add draggable choice
    const addDraggableChoice = (choice, initialX, initialY) => {
      // Create arrow
      const arrow = new fabric.Triangle({
        width: 20,
        height: 20,
        fill: canvasFill(choice?.isCorrect),
        angle: 180,
        originX: "center",
        originY: "top", // Arrow aligns with the top
      });

      // Create label
      const label = new fabric.Text(choice.value, {
        fontSize: 14,
        originX: "center",
        originY: "bottom", // Label aligns with the bottom
        top: -22, // Positioned above the arrow
        fill: canvasFill(choice?.isCorrect),
      });

      const groupBackground = new fabric.Rect({
        width: label.width + 10, // Adjust width as needed
        height: 20, // Adjust height as needed
        fill: "white",
        top: -20, // Positioned above the arrow
        originX: "center",
        originY: "bottom",
        stroke: "black", // Border color
        strokeWidth: 1, // Border width
      });

      // Create group for arrow and label
      const group = new fabric.Group([groupBackground, label, arrow], {
        left: initialX,
        top: initialY,
        hasControls: false,
        lockScalingX: true,
        lockScalingY: true,
        lockRotation: true,
        id: choice.id,
        lockMovementX: presentationMode, // disable movement when in presentation mode
        lockMovementY: presentationMode, // disable movement when in presentation mode
        selectable: !presentationMode, // disable selectable when in presentation mode
        originX: "left",
        originY: "top", // Arrow aligns with the top
      });

      canvas.add(group);

      // Restrict movement to valid points
      group.on("moving", () => {
        const pointer = group.getCenterPoint();

        // Check area
        const isInWhiteArea = pointer.y <= 140;
        const isInGrayArea = pointer.y > 140 && pointer.y <= canvasHeight;

        if (isInWhiteArea) {
          // Snap to nearest point on scale
          // const nearestX = snapPoints.reduce((prev, curr) =>
          //   Math.abs(curr - group.left) < Math.abs(prev - group.left)
          //     ? curr
          //     : prev
          // );
          // group.left = nearestX;
          // group.top = 50; // Align with scale
        } else if (isInGrayArea) {
          // Allow free movement in the gray area
          group.left = Math.max(
            0,
            Math.min(canvasWidth - group.width, group.left)
          );
          group.top = Math.max(
            140,
            Math.min(canvasHeight - group.height, group.top)
          );
        } else {
          // Reset position if outside allowed areas
          group.left = initialX;
          group.top = initialY;
        }

        // Ensure the group stays within the canvas boundaries
        group.left = Math.max(
          0,
          Math.min(canvasWidth - group.width, group.left)
        );
        group.top = Math.max(
          0,
          Math.min(canvasHeight - group.height, group.top)
        );

        group.setCoords();
      });

      // Snap to point on drop
      group.on("moved", () => {
        const pointer = group.getCenterPoint();

        // Check area
        const isInWhiteArea = pointer.y <= 140;
        const isInGrayArea = pointer.y > 140 && pointer.y <= canvasHeight;
        // Final snapping behavior
        let nearestPointIndex = 0; // Default to the first point
        for (let i = 0; i < snapPoints.length; i++) {
          if (pointer.x >= snapPoints[i] && pointer.x < snapPoints[i + 1]) {
            nearestPointIndex =
              pointer.x - snapPoints[i] < snapPoints[i + 1] - pointer.x
                ? i
                : i + 1;
            break;
          } else if (
            pointer.x >= snapPoints[i] &&
            i === snapPoints.length - 1
          ) {
            nearestPointIndex = snapPoints.length - 1;
          }
        }

        // Snap to the nearest point
        if (isInWhiteArea) {
          group.left =
            snapPoints[nearestPointIndex] - Math.floor(group.width / 2); // Snap horizontally
          group.top = 50; // Align vertically just above the tick mark
        }

        group.setCoords();

        setOptionCoords(group, isInWhiteArea && nearestPointIndex);
      });
    };

    options?.forEach((choice, index) => {
      if (!choice.value) return;

      const numberOfChoices = options?.length || 0;
      const spacing = (scaleEnd - scaleStart) / (numberOfChoices + 1); // Space out evenly

      const initialX = choice?.left ?? scaleStart + spacing * (index + 1);
      const initialY = choice?.top ?? 150;
      addDraggableChoice(choice, initialX, initialY);
    });

    return () => {
      canvas?.dispose();
    };
  }, [
    maxValue,
    minValue,
    options,
    presentationMode,
    setOptionCoords,
    autoCheckMode,
    uniqueId,
    canvasFill,
  ]);

  return (
    <canvas
      id={uniqueId}
      width={600}
      height={200}
      style={{ border: "1px solid #d9d9d9" }}
    />
  );
};

export default NumberLineCanvas;
