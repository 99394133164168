import React, { useEffect, useState } from "react";
import "./ToolsSidebar.scss";
import { Badge, Button, Space, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  appRegionSelector, currentUserSelector, enabledFeaturesSelector, getEmbeddedSelector,
} from "/src/views/Auth/Login/selector";
import { actions as experienceReduxActions } from "/src/views/Experiences/redux";
import { currentViewSelector, studentUnreadMessagesCountUserInfoSelector, ttsAllowedSelector, calcEnabledSelector, graphingCalcEnabledSelector } from "../FirestoreInteractions/selector";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { calcTypeSelector, experienceViewModeSelector } from "../../selector";
import TextToSpeech from "/src/views/Segments/TextToSpeech/TextToSpeech";
import ResourceModal from "./ResourceModal/ResourceModal";
import Notepad from "/src/components/Notepad/Notepad";
import StudentChat from "./StudentChat";
import Calculator from "./Calculator/Calculator";
import { checkMob } from "/src/lib/utils/helperMethods";
import ExternalVideoCallButton from "./ExternalVideoCallButton";
import { SolarCalculatorOutline, SolarChatRoundLineLinear, SolarClipboardTextLinear } from "/src/components/UI/Icons/SolarIcons";
import TextHighlighter from "/src/views/TextHighlighter/TextHighlighter";

const ToolsSidebar = (props) => {
  const { experience, experienceSettings, setUserInfo } = props;
  const dispatch = useDispatch();
  const mrIntl = useTranslate();

  const appRegion = useSelector(appRegionSelector());
  const enabledFeatures = useSelector(enabledFeaturesSelector())
  const ttsAllowed = useSelector(ttsAllowedSelector);
  const experienceViewMode = useSelector(experienceViewModeSelector());
  const embedded = useSelector(getEmbeddedSelector());
  const currentView = useSelector(currentViewSelector);
  const calcEnabledFromUserInfo = useSelector(calcEnabledSelector());
  const graphingCalcEnabledFromUserInfo = useSelector(graphingCalcEnabledSelector())
  
  console.log("calcEnabledFromUserInfo ==>", calcEnabledFromUserInfo);

  const studentUnreadCount = useSelector(studentUnreadMessagesCountUserInfoSelector);

  const isChina = appRegion === "china";
  const calcTypeFromRedux = useSelector(calcTypeSelector());
  const activeCalculatorType = calcEnabledFromUserInfo === true ? calcTypeFromRedux : "desmos_graphing_calc"
  const isStartView = currentView === "startTest"; 
  const [activeTool, setActiveTool] = useState(null);
  const [calcSettingSidebar, setCalcSettingSidebar] = useState({
    type: "",
    showCalc: false,
  });

  useEffect(() => {
    // Whenever a student turns on the calculator during an assessment and doesn't close it before the end test, the calculator still show on end view.
    // We hide the calculator button in the sidebar tools on the end view, so it should not be displayed in end view.. 
    if (calcSettingSidebar.showCalc && currentView === "endTest") {
      setCalcSettingSidebar({
        ...calcSettingSidebar,
        showCalc: false
      })
      setActiveTool(null)
    }
  }, [currentView])

  const isMobile = checkMob();
  const handleCalcSwitch = (calcType) => {
    setCalcSettingSidebar({
      type: calcType,
      showCalc: true,
    });
    dispatch(experienceReduxActions.setCalcTypeSuccess({ calcType }));
  };

  const handleToolClick = (tool) => {
    console.log("handleToolClick====>>>", tool);
    const newToolState = activeTool === tool ? null : tool;
    setActiveTool(newToolState);
    if (newToolState === 'calculator') {
      handleCalcSwitch(activeCalculatorType);
    }
  };

  const chatDisabled = experienceSettings?.chat_disabled;
      
  const renderActiveTool = () => {
    switch (activeTool) {
      case 'notepad':
        return <Notepad showNotepadInSidebar={true} handleCurrentTool={(value) => setActiveTool(value ? 'notepad' : null)} />;
      case 'chat':
        return <StudentChat setUserInfo={setUserInfo} experience={experience} openChat={true} handleCurrentTool={(value) => setActiveTool(value ? 'chat' : null)} />;
      case 'calculator':
        return <Calculator experienceSettings={experienceSettings} calcSettingSidebar={calcSettingSidebar} handleCurrentTool={(value) => setActiveTool(value ? 'calculator' : null)}
         />;
      default:
        return null;
    }
  };

  const getToolButton = (tool, icon, title, isActive) => (
    <Tooltip title={title} placement="left">
      <Button
        type="text"
        icon={icon}
        onClick={() => handleToolClick(tool)}
        className={isActive ? "sidebar-tool-button" : ""}
      />
    </Tooltip>
  );

  const resources = (experience.attachments_json && experience.attachments_json.resources) || [];
  
  const tools = [];
  
  if (ttsAllowed || experienceViewMode === "apPreviewTest") {
    tools.push(<TextToSpeech key="text-to-speech" />);
  }
  
  if (experienceSettings.assessment_type === "exam" && (currentView === "startTest" || currentView === "endTest" || experienceViewMode === "apPreviewTest") && resources.length) {
    tools.push(<ResourceModal key="resource-modal" attachments_json={experience.attachments_json} />);
  }
  
  if (experienceSettings.include_video_call_link && experienceSettings.video_call_link) {
    tools.push(<ExternalVideoCallButton key="video-call" videoCallLink={experienceSettings.video_call_link} experienceSettings={experienceSettings} buttonShape="" />);
  }

  if (enabledFeatures.text_highlight) {
    tools.push(<TextHighlighter />);
  }
  
  if (currentView === "startTest" || currentView === "endTest") {
    tools.push(getToolButton('notepad', <SolarClipboardTextLinear />, "Notepad", activeTool === 'notepad'));
  }
  
  if ((calcEnabledFromUserInfo || graphingCalcEnabledFromUserInfo) && !isMobile && (currentView === "startTest" || currentView === "endTest" || experienceViewMode === "apPreviewTest")) {
    tools.push(getToolButton('calculator', <SolarCalculatorOutline />, mrIntl("Calculator.calculator"), activeTool === 'calculator'));
  }
  
  if (!chatDisabled && experienceViewMode === "apTakeTest" && !embedded) {
    tools.push(
      <Badge key="chat" count={!isChina && studentUnreadCount > 0 ? studentUnreadCount : null} dot={isChina && studentUnreadCount}>
        {getToolButton('chat', <SolarChatRoundLineLinear />, "Chat", activeTool === 'chat')}
      </Badge>
    );
  }
  
  return (
    tools.length ?
      <div className="sidebar-tools-wrapper">
        <div className={`sidebar-tools ${isStartView ? 'start-view-active' : ''}`}>
          {tools}
        </div>
        {activeTool ? <div className="active-tool-container">{renderActiveTool()}</div> : null}
      </div> : null
  );
};

export default ToolsSidebar;
