import React, { useRef, useCallback, useEffect, useState } from 'react';
import { forceHideAnnotation, getUniqueId } from '/src/lib/utils/helperMethods';
import { useStore, useDispatch, useSelector } from "react-redux";
import { actions } from "/src/views/Annotations/redux";
import { formatJsonApiData } from "mr_react_framework";
import { annotationsFetchLoadingSelector, uriAnnoationsSelector } from '/src/views/Annotations/selector';
import { useTranslate } from '/src/lib/MrTranslate/MrTranslate';
import { printModeSelector } from '/src/views/Experiences/selector';
import { message } from '/src/components/UI/AntdAppHelper';
// let reduxStore = (options = {}) => {

//   return {
//     create: function (annotation) {
//       // console.log( "",  );
//       if (typeof annotation.id === 'undefined' ||
//         annotation.id === null) {
//         annotation.id = getUniqueId();
//       }


//       return new Promise((resolve, reject) => {

//         setTimeout(() => {
//           console.log("redux store create called", annotation)
//           resolve(annotation);
//           // return annotation;
//         }, 1000);
//       })
//     },

//     update: function (annotation) {
//       console.log("redux store update called", annotation)
//       return annotation;
//     },

//     delete: function (annotation) {
//       console.log("redux store delete called", annotation)
//       return annotation;
//     },

//     query: function (options) {
//       console.log("redux store query called", options)
//       return new Promise((resolve, reject) => {

//       });
//       return {
//         results: [{
//           id: 20,
//           text: 'The whole paragraph has too many spelling errors along with incorrect sentence formation.',
//           quote: 'This all start with a small dream where an independent women .Who see a dream to be business women she was all alone just three children and her husband died due to cancer and they were not having enough money so the doctors were not able to treate him . But the two children were able to handle themselve but the third on was too young he was toodler .  from that day the women thought that money is everthing to survie . So she started with a small beauty shop where she use to sell product to the beauty parlour but and there was no one to look at the child so she use to take him in the shop .',
//           uri: 'its custom uri',
//           category: 'incorrect',
//           ranges: [{
//             start: '',
//             end: '',
//             startOffset: 2,
//             endOffset: 30
//           }],
//           tags: ['Bad', 'Good', 'Cool'],
//           // user: 'current user changed',
//           // permissions: {
//           //   update: false,
//           //   delete: false,
//           // }
//         }]
//       };
//     },

//     configure: function (registry) {
//       registry.registerUtility(this, 'storage');
//     }
//   }
// }

const extractParagraphIndex = (xpath) => {  // getting paragraph index to sort annotations. para string getting from annotation arr is like : '/p[1]'
  const match = xpath.match(/\/p\[(\d+)\]/); 
  return match ? parseInt(match[1], 10) : 0;  // Return the paragraph index as an integer if matched, otherwise return 0
};

function sortAnnotationArr(annotationsArr) {
  if ( !Array.isArray(annotationsArr) && annotationsArr?.length === 0) {
    console.error("Invalid input: annotationsArr should not be empty");
    return [];
  }

  const sortedAnnotations = annotationsArr
    .slice()
    .sort((a, b) => {
      if (!a.ranges || !b.ranges || !a.ranges[0] || !b.ranges[0]) {
        console.error("Invalid annotation format: missing ranges");
      }

      const paraA = extractParagraphIndex(a.ranges[0].start);
      const paraB = extractParagraphIndex(b.ranges[0].start);

      if (paraA !== paraB) {
        return paraA - paraB; // Sort by paragraph index
      }
      return a.ranges[0].endOffset - b.ranges[0].endOffset;  // if annotations are in same paragraph then sort according range.
    });

  return sortedAnnotations;
}

const makeFinalAnnotationArr = (sortedAnnotations, printMode) => {

  console.log('these are annotations==>',sortedAnnotations);
  
  let finalAnnotations = [];

  if (printMode && sortedAnnotations?.length > 0) {
    const groupedTextsByUniqueKey = [];
    const tempArrObj = {};
    let i = 0;
    const generateRangeKey = ({ start, end, startOffset, endOffset }) => {  // generating unique range key for every annotation.
      const paraIndex = extractParagraphIndex(start);
      return `${paraIndex}-${start}-${end}-${startOffset}-${endOffset}`;
    };

    sortedAnnotations.forEach(({ quote, text, ranges }) => {
      if (!quote || !text || !ranges || !ranges[0]) {
        console.error("Invalid annotation format: missing quote, text, or ranges");
      }

      const rangeKey = generateRangeKey(ranges[0]);
      const uniqueKey = `${quote}-${rangeKey}`;   // creating unique key to group annotation by range 

      if (!tempArrObj[uniqueKey]) {
        tempArrObj[uniqueKey] = {
          index: i++,
        };
        groupedTextsByUniqueKey.push([text]);
      } else {
        let existingIndex = tempArrObj[uniqueKey].index;
        groupedTextsByUniqueKey[existingIndex].push(text);
      }
    });

    finalAnnotations = groupedTextsByUniqueKey.reduce((acc, texts, index) => {
      if (texts.length > 1) {
        const subAnnotations = texts.map(
          (text, subIndex) =>
            `${index + 1}${String.fromCharCode(97 + subIndex)}. ${text}`
        );
        return acc.concat(subAnnotations);
      } else {
        return acc.concat(`${index + 1}. ${texts[0]}`);
      }
    }, []);

    return finalAnnotations;
  } else {
    return [];
  }
};


const useAnnotationStore = (options = {}) => {
  console.log("useAnnotationStore options ==>", options);
  const { allowed, uri, user } = options
  // const annotationsFetchLoading = useSelector(annotationsFetchLoadingSelector())
  const uriAnnotations = useSelector(uriAnnoationsSelector(uri, user));
  const mrIntl = useTranslate()
  const queryResults = allowed ? {results: uriAnnotations} : null
  console.log("useAnnotationStore annotations from store ==>", queryResults)

  const dispatch = useDispatch();

  useEffect(() => {
    const modalBody = document.querySelector(".ant-modal-body")
    if (modalBody) {
      modalBody.addEventListener('scroll', handlePageScroll);

      return () => {
        modalBody.removeEventListener('scroll', handlePageScroll);
      };
    }
  }, []);

  const handlePageScroll = () => {
    console.log("handlePageScroll ====>");
    forceHideAnnotation()
  }

  // NOTE: create was not taking latest options so added this
  const createAnnotation = (annotation) => {
    const annotationParams = {
      ...annotation,
      ...options,
      annotator_ranges_attributes: annotation.ranges
    }
    return new Promise((resolve, reject) => {

      console.log("Annotation data ==>", annotation, annotationParams)
      dispatch(actions.create({
        annotation: {
          ...annotationParams,
        }
      }, {
        successCallback: (response) => {
          let apiAnnotation = formatJsonApiData(response.data.annotation)
          console.log("returned annotation ==>", apiAnnotation)
          resolve(apiAnnotation)
        },
        errorCallback: (error) => {
          // reject("Something went wrong, Not able to create annotation")
          reject(mrIntl("TextAnnotation.not_able_to_create_annotation_error_msg"))
        },
      }));
    })
  }

  const updateAnnotation = (annotation) => {
    const annotationParams = {
      ...annotation,
      ...options,
      annotator_ranges_attributes: annotation.ranges
    }

    return new Promise((resolve, reject) => {
      dispatch(actions.update({
        id: annotation.id,
        annotation: {
          ...annotationParams,
        }
      }, {
        successCallback: (response) => {
          let apiAnnotation = formatJsonApiData(response.data.annotation)
          resolve(apiAnnotation)
        },
        errorCallback: (error) => {
          // reject("Something went wrong, Not able to update annotation")
          reject(mrIntl("TextAnnotation.not_able_to_update_annotation_error_msg"))
        },
      }));
    })
  } 

  const deleteAnnotation = (annotation) => {
    console.log("redux store delete called", annotation)
    return new Promise((resolve, reject) => {
      dispatch(actions.delete({
        id: annotation.id,
      }, {
        successCallback: (response) => {
          let apiAnnotation = formatJsonApiData(response.data.annotation)
          resolve(apiAnnotation)
        },
        errorCallback: (error) => {
          // reject("Something went wrong, Not able to delete annotation")
          reject(mrIntl("TextAnnotation.not_able_to_delete_annotation_error_msg"))
        },
      }));
    })
  }

  const queryAnnotations = (options) => {
    console.log("redux store query called", options, queryResults);
    return queryResults;
  }

  function finalReduxAnnotationStore() {
    return {
      create: createAnnotation,
      update: updateAnnotation,
      delete: deleteAnnotation,
      query: queryAnnotations,
      configure: function (registry) {
        return registry.registerUtility(this, 'storage');
      },
    }
  }
  return finalReduxAnnotationStore
}

export const useAnnotation = (options, setAnnotationsListBySequence) => {
  const { allowed, uri, user_response_id, user } = options
  const annotationStore = useAnnotationStore(options);
  const annotationsFetchLoading = useSelector(annotationsFetchLoadingSelector())
  const annotations = useSelector(uriAnnoationsSelector(uri, user));
  const printMode = useSelector(printModeSelector())
  console.log("useAnnotation==>", options, annotationsFetchLoading)
  return useCallback((node) => {
    
    if (allowed && user_response_id && node && !annotationsFetchLoading) {
      let annotator = window.annotator;
      if (typeof annotator === 'undefined') {
        console.log("Annotator not available")
      } else {
        try {
          let app = new annotator.App();
          app.include(annotator.ui.main, {
            element: node,
            // viewerExtensions: [annotator.ui.tags.viewerExtension],
            // editorExtensions: [annotator.ui.tags.editorExtension],
          });
          app.include(annotationStore);
          app
            .start()
            .then(function () {
              app.annotations.load({ uri: uri }).then(function () {
                if (printMode) {

                  const sortedAnnotations = sortAnnotationArr(annotations);                 

                  setTimeout(() => {   // adding delay to wait for annotations load
                    const allAnnotatorHlSpans = node.querySelectorAll(
                      ".annotator-hl"
                    );
                    
                    
                    const spanGroupsByAnnotationId = {};
                    try {
                      allAnnotatorHlSpans.forEach(span => {   // grouping spans that have same annotation id to add annotation sequence only once
                        const annotationId = span.getAttribute('data-annotation-id');
                        if (!annotationId) {
                          console.error("Missing annotation ID on span element")
                        }
                        if (!spanGroupsByAnnotationId[annotationId]) {
                          spanGroupsByAnnotationId[annotationId] = [];
                        }
                        spanGroupsByAnnotationId[annotationId].push(span);
                      });
                    } catch (error) {
                      console.error("Error while grouping spans by annotation ID:", error);
                    }

                    console.log('Adding annotation==>',allAnnotatorHlSpans);
                    

                    let index = 1;

                    console.log('spanGroupsByAnnotationId==>',sortedAnnotations)
                    const uniqueQuoteAnnotations = sortedAnnotations?.length > 0 ? sortedAnnotations.reduce((acc, current) => {  // getting uniqueQuoteAnnotations to handle case in which we annotate inside the existing annotations.
                      const isDuplicateInPara = acc.some(obj =>
                        obj.quote === current.quote &&
                        obj.ranges.some(range =>
                          current.ranges.some(currRange =>   
                            range.start === currRange.start && range.end === currRange.end && 
                            range.startOffset === currRange.startOffset &&
                            range.endOffset === currRange.endOffset   
                          )
                        )
                      );
                    
                      if (!isDuplicateInPara) acc.push(current);
                      return acc;
                    }, []) : [];

                    uniqueQuoteAnnotations.forEach(annotation => {
                      let lastSpan = spanGroupsByAnnotationId[annotation.id][spanGroupsByAnnotationId[annotation.id].length - 1];
                      lastSpan.setAttribute("sequence-number", index++);  // adding sequence number at the last of the span
                    })
            
                  }, 1000);

                const finalAnnotations = makeFinalAnnotationArr(sortedAnnotations,printMode);
                setAnnotationsListBySequence(finalAnnotations)
                }
              })
              if (user === 'admin' || user === 'teacher') {
                app.ident.identity = 'teacher'
              }
            });
          node.addEventListener('mouseup', () => {
            const selection = window.getSelection();
            if (selection.rangeCount > 0) {
              const range = selection.getRangeAt(0);
              const selectedContent = range.cloneContents();
              const containsImage = selectedContent.querySelector('img') !== null;
              if ((containsImage || !selection.toString().trim()) && selection.isCollapsed === false) {
                message.error("Annotations are only allowed on text, not images.");
                selection.removeAllRanges();
              }
            }
          });
          console.log("Annotators ==>", app, node, uri)         
        } catch (error){
          console.error("error coming in annotations", error)
        }
      }
    }
  }, [user_response_id, annotationsFetchLoading, allowed]);
};
