import React, { useEffect } from "react";
import PropTypes from 'prop-types';

import { MrCrudView, MrForm as WrappedMrForm, FormRenderer } from "mr_react_framework";
import { actions } from "./redux";
import UserResponseListItem from "./UserResponseListItem/UserResponseListItem";
import UserResponsesFilter from "./UserResponsesFilter/UserResponsesFilter";
// import WrappedMrForm from "../../lib/utils/MrForm/MrForm";
import commonSelectors from "/src/App/commonSelectors";
import { InputNumber, Input } from "antd";
import RubricCriteriaPointsForm from "/src/views/Segments/RubricCriteriaPointsForm/RubricCriteriaPointsForm";
import { useSelector } from "react-redux";
import { activeAdjustedExperienceSelector, activeExperienceGradingDisabledSelector } from "/src/views/Experiences/selector";
import { CKETextCommentTools } from "/src/components/UI/CKEditor/CKEConfig";
import { displayPoints, focusElement, isStringEmpty } from "/src/lib/utils/helperMethods";
import MediaComments from "./MediaComments";
import { currentUserSelector, enabledFeaturesSelector } from "/src/views/Auth/Login/selector";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import dayjs from "dayjs";
import Comment from "./Comment";
import { getEmbeddedSelector } from "/src/views/Auth/Login/selector";

const TotalPointsWidget = (props) => {
  console.log("TotalPointsWidget props", props)
  // get max_points for props.getFieldValue
  // let maxPoints = parseFloat(props.formInstance.getFieldValue(["max_points"])).toFixed(1)
  let maxPoints = parseFloat(props.formInstance.getFieldValue(["max_points"])).toFixed(1)
  return `/ ${maxPoints}`
}

const PointsChildComponent =  (props) => {
  console.log("PointsChildComponent props", props)
  const { getFieldValue, sourceValues } = props
  // const segmentType = getFieldValue(["segment_type"]);
  let scas = sourceValues.submission_criterium_associations_attributes
  let finalRender = null;
  const currentUser = useSelector(currentUserSelector());
  const enabledFeatures = useSelector(enabledFeaturesSelector());
  // get active experience rubric = use criteria to build form for points if rubric not Points. if points or if no experience/adding segment in library, then default to below. if grading for full_experience then hide altogether

  const activeExperience = useSelector(activeAdjustedExperienceSelector())
  console.log("PointsChildComponent activeExperience", activeExperience)
  let rubricType = activeExperience.rubric && activeExperience.rubric.type_c;  
  const mrIntl = useTranslate();

  useEffect(() => {
    focusElement("form#userResponses_1 input")
  }, [])
  
  if (sourceValues.id){
    if(rubricType === "comments_only"){
      finalRender = ""
    }else{
      // if grading per question
      // change this to rubric_code
      console.log("scas ==>", scas)
      // if(!scas || scas.length == 0){
      if(rubricType === "points"){
        let maxPoints = displayPoints(sourceValues.max_points)
        finalRender = <FormRenderer
          formObj={{
            type: "object",
            properties: {
              points: {
                type: "string",
                placeholder: mrIntl("CommonText.points"),
                span: 14,
                wrapClassName: "points",
                widget: InputNumber,
                // widget: Input,
                widgetConfig:{
                  // min: 0,
                  // max: parseFloat(maxPoints),
                  // autoFocus: false,
                  controls: false,
                  keyboard: false,
                  // addonAfter: `/ ${parseFloat(maxPoints)}`
                  addonAfter: parseFloat(maxPoints)
                },
                rules: [
                  {
                    validator(rule, value) {
                      console.log('UserResponses validator', value, typeof(value))
                      // Done: fix this below - typeofvalue is coming to be string if only comments are given and points are not touched
                      if(value == "0" || value == null || (parseFloat(value) >= 0 && parseFloat(value) <= parseFloat(maxPoints) && typeof(parseFloat(value)) == "number") && value.toString().slice(-1) != "."){
                        return Promise.resolve()
                      }
                      else {
                        return Promise.reject(mrIntl("CommonText.not_allowed"))
                      }
                    }
                  },
                ]
              },  
              // total_points: {
              //   type: "string",
              //   // placeholder: "Points",
              //   span: 9,
              //   wrapClassName: "total-points",
              //   // widget: TotalPointsWidget
              //   widget: () => {
              //     return `/ ${maxPoints}`
              //   }
              // },
            }
          }}
          sourceValues={sourceValues}
        />
      } else if(scas && scas.length != 0){
        // any other rubric, show criteria wise here
        // show this form if rubric/achievement level and points both chosen. if only achievement level -> no points per question -> only level for full experienceUser 
        // form for criterium_associations of type "question" -> only for questions with criterium_associations of type experience (4 criteria for eg)
        // used criteria in all questions say A an B -> attach only those that are used in questions to experience
        let assignedPoints = sourceValues.points == null ? "__" : displayPoints(sourceValues.points)
        let maxPoints = displayPoints(sourceValues.max_points)
        // let maxPoints = displayPoints(sourceValues.max_points)
        const pointsTotalDisplay = `Total: ${assignedPoints}/${maxPoints}`
        const hidePerQuestionGradingClassName = (currentUser.permission && currentUser.permission.experience && currentUser.permission.experience.hide_per_question_grading_myp_al === true)  && rubricType === "myp_achievement_level" ? "hidden" : ""
        finalRender = <FormRenderer
        formObj={{
          type: "object",
          properties: {
            points_total_display: {
              type: "string",
              // placeholder: "Points",
              setInitialValue: true,
              // wrapClassName: rubricType === "myp_achievement_level" && "hidden",
              wrapClassName: (rubricType === "myp_achievement_level" || rubricType === "criteria_with_points") && "hidden",
              widget: (props) => {
                console.log("points_total_display", props)
                return pointsTotalDisplay
                // return "5/6"
              },
            },
            submission_criterium_associations_attributes: {
              type: "string",
              placeholder: mrIntl("CommonText.points"),
              setInitialValue: true,
              wrapClassName: hidePerQuestionGradingClassName,
              widget: RubricCriteriaPointsForm,
              widgetConfig: {
                segmentId: sourceValues.segment_id,
              },
              rules: [
                {
                  validator(rule, value) {
                    console.log('UserResponses validator====>', value)
                    let validationPassed = true
                    if(!value){
                      validationPassed = false
                    }else{
                      for(let i = 0; i <= value.length - 1; i++){
                      // value.map((ca, i) => {
                        let ca = value[i]
                        let updatedPoints = ca.points
                        console.log("updatedPoints===>",updatedPoints);
                        // console.log('UserResponses validator updatedPoints', ca, updatedPoints, typeof(updatedPoints), maxPoints, parseFloat(updatedPoints), typeof(parseFloat(updatedPoints)))
                        // console.log('UserResponses validator updatedPoints conditions', updatedPoints >= 0, updatedPoints <= parseFloat(ca.custom_fields.max_points), typeof(updatedPoints))
                        // if (updatedPoints >= 0 && updatedPoints <= parseFloat(ca.custom_fields.max_points) && typeof(parseFloat(updatedPoints)) == "number") {
                        let ca_max_points = rubricType === "myp_achievement_level" ? 8 : ca.custom_fields.max_points
                        if(updatedPoints == "0" || updatedPoints == null || ((parseFloat(updatedPoints) >= 0 && parseFloat(updatedPoints) <= parseFloat(ca_max_points) && typeof(parseFloat(updatedPoints)) == "number") && updatedPoints.toString().slice(-1) != ".")){
                          // allowed
                        }
                        else{
                          validationPassed = false
                          break;
                        }
                      }
                    }
                    
                    console.log('UserResponses validator updatedPoints', validationPassed)
                    if(validationPassed){
                      return Promise.resolve()
                    }
                    else {
                      return Promise.reject(mrIntl("CommonText.not_allowed"))
                    }
                  }
                },
              ]
            }
          }
          
        }}
        // propertyKey={"section_segment_attributes"}
        sourceValues={sourceValues}
      />
      }
    }
  }
  
  return finalRender;
}

const processInitialValues = (data) => {
  console.log('processInitialValues==>', data)
  let newData = {...data};
  if (data.custom_fields) {
    newData.media_comments = data.custom_fields.media_comments;
  }
  return newData;

} 

export const returnForm = (props) => {
  console.log("UserResponses form props", props)  
  const isEmbedded = props.embedded
  const activeExperienceGradingDisabled = props.activeExperienceGradingDisabled
  let maxPoints = parseFloat(props.formState.values.max_points).toFixed(1)
  const { activeExperience, mainProps } = props;
  let rubricType = activeExperience && activeExperience.rubric && activeExperience.rubric.type_c
  let userResponses = mainProps.userResponses || []
  
  let form = {
    processFormData: (dataProps, opts) => {
      console.log("dataProps  ==>", dataProps);
      console.log("opts  ==>", opts);
      // process and set values and then return changed values


      // TODO handle custom rubric later
      let scas = opts.values.submission_criterium_associations_attributes
      const commentsInfluenceMarked = rubricType === "comments_only"
      let mediaComments = opts.values.media_comments
      const commentsNull = isStringEmpty(opts.values.comments) && (!mediaComments || (mediaComments && !mediaComments.attachments.length))
      // const pointsNull = (rubricType === "points" && (opts.values.points == undefined || opts.values.points == null)) || (scas && scas.length > 0 && scas.find((sca) => sca.points == null || sca.points == undefined)) // Allow 0

      let pointsNull = false
      if(rubricType === "points" && (opts.values.points == undefined || opts.values.points == null)) {
        pointsNull = true
      } else if (rubricType !== "points" && ((!scas || !scas.length) || (scas && scas.length > 0 && scas.find((sca) => sca.points == null || sca.points == undefined)))) {
        // !scas.length to handle old AL submissions where we didn't create scas
        pointsNull = true
      }

      const isMarked = (!commentsInfluenceMarked && pointsNull) || (commentsInfluenceMarked && commentsNull) ? false : true
      opts.values.marked = isMarked


      if(mediaComments){
        let customFields = {
          ...opts.values.custom_fields,
          media_comments: mediaComments
        }
        opts.values.custom_fields = customFields;
      }
      console.log('opts.values', opts.values, pointsNull, commentsNull, isMarked)
      return opts.values;
    },
    schema: {
      type: "object",
      properties: {
        points: {
          type: "string",
          placeholder: <MrTranslate id={"CommonText.points"} />,
          span: 24,
          // wrapClassName: "wrap-segment-form-points",
          dependsOn: [""],
          children: PointsChildComponent,
          // // setInitialValue: true,
          rules: [
            {
              required: true,
              message: <MrTranslate id={"CommonText.required_msg"}/>,
            },
          ]
        },
        
        max_points: {
          // just to getFieldValue for above
          type: "string",
          wrapClassName: "hidden",
        },
        comments: {
          type: "string",
          // placeholder: "Comments",
          span: 24,
          widget: Comment,
          widgetConfig: {
            ckeConfig: {
              ...CKETextCommentTools,
              // isReadOnly: activeExperienceGradingDisabled, // allowing per question even in AL case - aim to allow overall also after MB integration change
              debounceInterval: 500, // the default value of 1000 was leading to APL-2496 and APL-2507
              autoFocus: true
            },
            writingAssistantConfig: {
              enabled: true
            },
            previousComments: userResponses
          },
          wrapClassName: "comments"
        },
        media_comments: {
          type: "string",
          span: 24,
          widget: MediaComments,
          wrapClassName: isEmbedded ? "hidden" : "user-responses-media-comments"
          // wrapClassName: "media-comments"
        },
        // updated_at: {
        //   type: "string",
        //   widget: (props) => {
        //     console.log("updated_at props", props, dayjs(props.value).format("MMM DD, hh:mm:ss a"));
        // // Value not updating on save so using the successCallback method
        //     return <span>Last saved {dayjs(props.value).format("MMM DD, hh:mm:ss a")}</span>
        //   }
        // },
      },
    },
  };
  return form;
};


const UserResponses = MrCrudView({
  resourceName: "userResponses",
  singleResourceName: "user_response",
  // displayName: "resourceName",
  resourceUrl: "/user_responses",
  // layout: OrgsLayout,
  filter: UserResponsesFilter,
  isRoute: true,
  selectorsObj: {
    ...commonSelectors,
    activeExperience: activeAdjustedExperienceSelector,
    embedded: getEmbeddedSelector,
    activeExperienceGradingDisabled: activeExperienceGradingDisabledSelector,
  },
  listItem: UserResponseListItem,
  forms: [{ name: "userResponses", form: returnForm, config: {processInitialValues} }],
  actions,
  actionNames: ["fetch", "create", "show", "update", "delete"],
  // firstTimeFetchParams: { with_users: true }
  
});

// UserResponses.defaultProps = {};

UserResponses.propTypes = {};
export default UserResponses;
