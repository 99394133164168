import React, { useRef, useState, useEffect } from 'react';
import { Button, Modal, Table, Tooltip } from 'antd';
import 'antd/dist/reset.css';
import { ProfileOutlined } from '@ant-design/icons';
import CKETextContent from '/src/views/Segments/CKETextContent/CKETextContent';
import { useDispatch, useSelector } from 'react-redux';
import RenderHtml from '/src/components/UI/RenderHtml/RenderHtml';
import { activeAdjustedExperienceSelector, experienceViewModeSelector, printExperienceSelector, printModeSelector } from '/src/views/Experiences/selector';
import "./TaskSpecificClarification.scss";
import { showConfirmModal } from '/src/components/UI/Segment/UIHelper';
import { cloneDeep, isEmpty, orderBy } from 'lodash';
import { useTranslate } from '/src/lib/MrTranslate/MrTranslate';
import { getAIServiceBaseURL, getFromLS, getStrandsFromRubricsWithUpdatedCA, mapStrandLabelsToLevelDescriptors, removeHTMLTagsFromText } from '/src/lib/utils/helperMethods';
import { Card, Skeleton, Space } from 'antd/es';
import MagicButton from '/src/views/Segments/GenerateAI/MagicButton';
import axios from 'axios';
import { FaRegStopCircle } from 'react-icons/fa';
import { message } from '/src/components/UI/AntdAppHelper';
import { actions as experienceReduxActions } from "/src/views/Experiences/redux";
import { enabledFeaturesSelector } from '/src/views/Auth/Login/selector';
import { tscTableConfig } from '/src/components/UI/CKEditor/CKEConfig';
import * as Sentry from "@sentry/react";
import { updateConfigHeaderForDemoUseStagingAI } from '/src/views/Segments/GenerateAI/generateAIHelperMethods';


const RenderLevelDescriptors = (props) => {
  const { item = {}, rowIndex, hl = '', onTextChanged, colIdx, editAllowed, status, errorIndexes, criteriaTitle, itemTitle, openTSCModal, caId, generateTSCAILoading } = props;
  const { level_descriptors = [] } = item;
  const levelDescriptor = level_descriptors.find(ld => ld.level == hl.charAt(hl.length - 1));
  const textContentToRender = levelDescriptor ? levelDescriptor.desc : '';
  const cellId = `${colIdx}-${rowIndex}-${criteriaTitle}-${itemTitle}-${openTSCModal}`
  const [editMode, setEditMode] = useState(false);

  return (
    generateTSCAILoading ? 
      <Card
        type="inner"
      >
        <Skeleton active />
      </Card>
      :
      editMode ?
        <div key={`ckeditor-task-specific-clarification-${cellId}`} className='ckeditor-task-specific-clarification'>
          <CKETextContent
            ckeConfig={tscTableConfig}
            placeholder={'*Required'}
            itemId={`${cellId}-ckeditor`}
            value={textContentToRender}
            onChange={(data) => {
              onTextChanged(data, item.label, colIdx, rowIndex, criteriaTitle, itemTitle, caId)
            }}
            onBlur={() => setEditMode(false) }
          />
        </div>
        : (
        <div key={`show-mode-${cellId}`} className={`${status === "published" ? 'cursor-default' : 'cursor-pointers'} column-read-only-mode`}
          onClick={() => {
            if (status !== "published" && editAllowed) {
              setEditMode(true);
            } 
          }}
        >
          {textContentToRender ? <RenderHtml text={textContentToRender} /> : <span className='required'>*Required</span>}
        </div>
      )
  )
}

const TaskSpecificClarification = (props) => {

  const { updateResource = null, editAllowed = false, className, showTSCInActions = false, tscInModal = true  } = props;
  
  let experience = useSelector(activeAdjustedExperienceSelector())
  const printMode = useSelector(printModeSelector());
  const printExperience = useSelector(printExperienceSelector());
  if(printMode){
    experience = printExperience.experience
  }
  const enabledFeatures = useSelector(enabledFeaturesSelector());
  const experienceViewMode = useSelector(experienceViewModeSelector());
  const [localCriteriumAssociations, setLocalCriteriumAssociations] = useState([]);
  const [openTSCModal, setOpenTSCModal] = useState(false);
  const [errorIndexes, setErrorIndexes] = useState({});
  const [generateTSCAILoading, setGenerateTSCAILoading] = useState(false)

  const { status, criterium_associations = [], settings = {}, rubric = {}, relation_items = [] } = experience
  const mrIntl = useTranslate();
  const sseRef = useRef(null);
  const dispatch = useDispatch();
  const experienceSettings = settings
  const tscEnabled = experienceSettings.tsc_enabled
  const showGenerateTSCWithAI = enabledFeatures.generate_tsc_with_ai && experienceViewMode === "apEditTest" && status === "draft";

  // To enable or disable a Task Specific Clarification button based on whether strands are present
  let isStrandsExist = false;
  criterium_associations.forEach(ca => {
    if (ca.strands.length > 0) {
      isStrandsExist = true;
      return true;
    }
    return false;
  });

  let tscButtonTooltip = ''
  
  if (!isStrandsExist) {
    tscButtonTooltip = 'Select a strand in any question to enable Task Specific Clarification'
  } else if (isStrandsExist && !showTSCInActions) {
    tscButtonTooltip = 'Task Specific Clarification'
  }

  let tscButtonTooltipPlacement = 'bottom'
  
  if (showTSCInActions && (!isStrandsExist || tscEnabled)) {
    tscButtonTooltipPlacement = 'left'
  }

  let tscButtonDisable = false

  if(!isStrandsExist || (tscEnabled && showTSCInActions)) {
    tscButtonDisable = true
  }

  const showTSCModal = (action) => {
    let newLocalCriteriumAssociations = action === "initialize" ? getStrandsFromRubricsWithUpdatedCA(criterium_associations, rubric.criteria, relation_items) : cloneDeep(criterium_associations)
    console.log("experience tsc===> cra", newLocalCriteriumAssociations);
    
    setLocalCriteriumAssociations(newLocalCriteriumAssociations)
    setOpenTSCModal(!openTSCModal);
  };

  useEffect(() => {
    if (printMode) {
      showTSCModal();
    }
  }, [tscInModal]);


  const handleRemove = () => {
    showConfirmModal({
      title: mrIntl("TaskSpecificClarification.remove_task_specific_clarification"),
      content: mrIntl("TaskSpecificClarification.remove_task_specific_clarification_from_this_assessment_are_you_sure"),
      onOk: () => {
        const updatedCriteriumAssociations = cloneDeep(localCriteriumAssociations)
        updatedCriteriumAssociations.forEach(ca => {
          ca.strands && ca.strands.forEach(s => {
            delete s.level_descriptors
            delete s.title
            delete s.desc
          })
        })
        let newExperienceSettings = { ...experienceSettings };
        if (tscEnabled) {
          newExperienceSettings.tsc_enabled = false;
        }

        updateResource(
          {
            uid: experience.uid,
            criterium_associations_attributes: updatedCriteriumAssociations,
            settings: newExperienceSettings
          },
          {
            successCallback: () => {
              handleCloseModal()
              setLocalCriteriumAssociations([])

            },
            success: {
              showMessage: true,
              message: mrIntl("TaskSpecificClarification.task_specific_clarification_removed_successfully"),
            },
            error: {
              showMessage: true,
              message: mrIntl("TaskSpecificClarification.error_in_removing_task_specific_clarification"),
            },
          }
        );
      },
      okText: 'Remove',
      cancelText: 'Cancel',
    });
  }

  const handleCloseModal = () => {
    setErrorIndexes({})
    if (sseRef.current) {
      sseRef.current.abortSse();
    }
    setOpenTSCModal(false)
    setGenerateTSCAILoading(false);
  }

  const onTextChanged = (data, label, colIdx, rowIndex, criteriaTitle, itemTitle, caId) => {
    console.log('onTextChanged ===>>', colIdx, label, data, errorIndexes, itemTitle);
    const errorKey = `${colIdx}-${rowIndex}-${criteriaTitle}-${itemTitle}`
    let newLocalCriteriumAssociations = [...localCriteriumAssociations]
    const currentCAIndex = newLocalCriteriumAssociations.findIndex(ca => ca.id === caId);
    const currentCA = newLocalCriteriumAssociations[currentCAIndex] || {};
    const currentCAStrands = currentCA.strands || [];
    const currentStrand = currentCAStrands.find(s => s.label === label) || {};
    const currentStrandLevelDescriptors = currentStrand.level_descriptors || [];

    if (data.trim() === '') {
      setErrorIndexes({ ...errorIndexes, [errorKey]: '*Required' })
    } else {
      const newErrorIndexes = { ...errorIndexes }
      if (newErrorIndexes.hasOwnProperty(errorKey)) {
        delete newErrorIndexes[errorKey];
        setErrorIndexes(newErrorIndexes)
      }
    }
    if (currentStrandLevelDescriptors.length > 0) {
      if (colIdx === 0) {
        currentStrandLevelDescriptors[colIdx].desc = data
      } else {
        // descriptor.desc both data should be update when i edit (like- ["0", "1-2", "3-4", "5-6", "7-8"])
        // we are showing indexes 0,2,4,6,8 in table and the description is same for index
        // The index descriptions are the same for indices: ["0", "1-2", "3-4", "5-6", "7-8"]. When updating index 2, we also need to update index 1 because the first index is not displayed in the table. In the table, only indices [0, 2, 4, 6, 8] are shown, so when updating these indices, we also need to update [1, 3, 5, 7].
        currentStrandLevelDescriptors[colIdx + colIdx].desc = data
        currentStrandLevelDescriptors[colIdx + colIdx - 1].desc = data
      }
    }

    setLocalCriteriumAssociations(newLocalCriteriumAssociations)
  }

  const renderColumn = (title, dataIndex, key, renderFunction) => ({
    title,
    dataIndex,
    key,
    scope: 'col',
    render: renderFunction
  });

  const renderCriterionColumns = (headerLabels, onTextChanged, criteriaTitle, caId) => {
    const gridColumns = [
      renderColumn("", "dataIndex", "key-1", (_, item) => (
        <p className='criterion-columns-row' key={`render-criterion-title-desc-${item.title}`} >{item.title}. {item.desc}</p>
      ))
    ];

    headerLabels.forEach((hl, colIdx) => {
      gridColumns.push(
        renderColumn(hl, colIdx, colIdx, (_, item, rowIndex) => (
          <RenderLevelDescriptors key={`render-level-descriptors-${colIdx}-${rowIndex}-${criteriaTitle}-${item.title}-${openTSCModal}`} status={status} item={item} rowIndex={rowIndex} hl={hl} onTextChanged={onTextChanged} colIdx={colIdx} editAllowed={editAllowed} errorIndexes={errorIndexes} criteriaTitle={criteriaTitle} itemTitle={item.title} openTSCModal={openTSCModal} caId={caId} generateTSCAILoading={generateTSCAILoading}/>
        ))
      );
    });

    return gridColumns;
  };

  const renderCriterionTable = (ca = {}, finalStrands, onTextChanged, index) => {
    const { label, title } = ca.custom_fields
    return finalStrands.length ? ( 
      <div className={ printMode ? "page-break-before-always page-break-inside-avoid task-specific-clarification-modal" : null} key={`render-table-criteria-${ca.id}`} >
      <div key={`render-table-criteria-inner-${ca.id}`}>
      {(index === 0 && printMode) && <h1 className='task-specific-clarification-heading'>{mrIntl("CommonText.task_specific_clarification")}</h1>}
        <h2 key={`criterion-title-label-${ca.id}`} className='criterion-title-label'>
            Criterion {title}: {label}
          </h2>
        </div>
        <Table
          key={`strand-show-table-${ca.id}`}
          className='task-specific-clarification-table'
          columns={renderCriterionColumns(["0", "1-2", "3-4", "5-6", "7-8"], onTextChanged, title, ca.id)}
          dataSource={finalStrands}
          pagination={false}
        />
      </div>
    ) : null

  }

  const renderCriteriaTables = (localCriteriumAssociations, onTextChanged) => {
    const tables = [];
    localCriteriumAssociations.forEach((ca,index) => {
      const orderedStrands = orderBy(ca.strands, ["label"], ["asc"])
      tables.push(renderCriterionTable(ca, orderedStrands, onTextChanged,index))
    });
    return tables;
  }

  const handleSave = () => {
    let newExperienceSettings = { ...experienceSettings };
    if (!tscEnabled) {

      newExperienceSettings.tsc_enabled = true;
    }

    updateResource(
      {
        uid: experience.uid,
        criterium_associations_attributes: localCriteriumAssociations,
        settings: newExperienceSettings
      },
      {
        successCallback: () => {
          setLocalCriteriumAssociations([])
          handleCloseModal()
        },
        success: {
          showMessage: true,
          message: mrIntl("TaskSpecificClarification.task_specific_clarification_updated_successfully"),
        },
        error: {
          showMessage: true,
          message: mrIntl("TaskSpecificClarification.error_in_updating_task_specific_clarification"),
        },
      }
    );
  }

  const getStrandWiseQuestions = (data) => {
    if (!data.topics || data.topics.length === 0) return [];

    const allSegments = data.topics.flatMap((topic) =>
      topic.children.filter((child) => child.segment_type === "question")
    );

    const allStrandData = {};

    allSegments.forEach((segment) => {
      const content = removeHTMLTagsFromText(
        segment.question_segment_attributes.content,
        true
      );

      segment.criterium_associations_attributes.forEach(
        ({ criterium_id, custom_fields, strands }) => {
          const criteriaLabel = `${custom_fields.title}: ${custom_fields.label}`;

          if (Array.isArray(strands)) {
            strands.forEach(({ label }) => {
              // Use label as a key in the object
              const strandData = allStrandData[label] || {
                criteria_id: criterium_id,
                criteria_label: criteriaLabel,
                strand_label: label,
                questions: [],
              };

              strandData.questions.push(content);
              allStrandData[label] = strandData;
            });
          }
        }
      );
    });

    return Object.values(allStrandData);
  };

  const generateTSCWithAI = async (data) => {
    try {
      const token = getFromLS("token");
      const abortController = new AbortController();

      if (sseRef) {
        sseRef.current = {
          abortSse: () => abortController.abort(),
        };
      }

      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        signal: abortController.signal,
      };
      config = updateConfigHeaderForDemoUseStagingAI(config)

      let experienceCustomFields = experience?.custom_fields || {};

      const subjectLabel = experienceCustomFields.subject_item_label;
      const gradeLabel = experienceCustomFields.grade_item_label;
      const programmeLabel = experienceCustomFields.org_programme_item_label;
      let strandWiseQuestions = getStrandWiseQuestions(data);

      const dataToPost = {
        subject: subjectLabel,
        grade: gradeLabel,
        programme: programmeLabel,
        strand_wise_questions: strandWiseQuestions,
      };

      const response = await axios.post(
        `${getAIServiceBaseURL()}/generate-tsc`,
        dataToPost,
        config
      );

      if (response.data?.tsc) {
        let aiGeneratedTSC = response.data.tsc;
        let newLocalCriteriumAssociations = [];
        if (localCriteriumAssociations) {
          newLocalCriteriumAssociations = cloneDeep(localCriteriumAssociations);
          if (newLocalCriteriumAssociations) {
            let strandLevelDescriptorsMap =
              mapStrandLabelsToLevelDescriptors(aiGeneratedTSC);
            newLocalCriteriumAssociations.forEach((ca) => {
              ca.strands &&
                ca.strands.forEach((strand) => {
                  strand.level_descriptors =
                    strandLevelDescriptorsMap[strand.label];
                });
            });
          }
        } else {
          newLocalCriteriumAssociations = getStrandsFromRubricsWithUpdatedCA(
            criterium_associations,
            rubric.criteria,
            relation_items,
            aiGeneratedTSC
          );
        }
        message.success(mrIntl("TaskSpecificClarification.task_specific_clarification_successfully_generated"))
        setLocalCriteriumAssociations(newLocalCriteriumAssociations);
      } else {
        throw new Error("Invalid response from server"); // Throw an error if response is not valid
      }
    } catch (error) {
      console.error("Error generating TSC:", error);

      if (error.message !== "canceled") {
        Sentry.captureException(error);
        message.error(mrIntl("TaskSpecificClarification.something_went_wrong_in_generating_tsc"));
      }
    } finally {
      setGenerateTSCAILoading(false); // Ensure loading is stopped in all cases
    }
  };

  const errorCallback = (error) => {
    console.error("error in fetching topics and segments", error);
    setGenerateTSCAILoading(false);
  }
  
  const handleGenerateTSCWithAI = () => {
    setGenerateTSCAILoading(true);

    dispatch(
      experienceReduxActions.topicsAndSegmentsFetch(
        { id: experience.id },
        { successCallback: (data) => generateTSCWithAI(data), errorCallback }
      )
    );
  };

  const generateTSCMagicButton = (
    <MagicButton
      shape="default"
      size="medium"
      ghost={true}
      tooltipTitle={generateTSCAILoading ? mrIntl("CommonText.stop") : ""}
      tooltipPlacement="bottom"
      text={
        generateTSCAILoading
          ? null
          : mrIntl("TaskSpecificClarification.generate_tsc_with_ai")
      }
      icon={generateTSCAILoading ? <FaRegStopCircle style={{paddingBottom: "1px"}} /> : null}
      feature_code="generate_tsc_with_ai"
      onClick={() => {
        if (generateTSCAILoading) {
          setGenerateTSCAILoading(false);
          if (sseRef.current) {
            sseRef.current.abortSse();
          }
        } else {
          handleGenerateTSCWithAI();
        }
      }}
    />
  );

  const modalTitle = (
    <Space className="p-b-10">
      {mrIntl("CommonText.task_specific_clarification")}
      {showGenerateTSCWithAI ? generateTSCMagicButton : null}
    </Space>
  );

  return (
    <>
      {(showTSCInActions || tscEnabled) && (
        <>
          <div key={'task-specific-clarification-modal'} className={printMode ? "print-tsc-table-landscape ": "task-specific-clarification" }>
          
          {tscInModal &&  <Tooltip placement={tscButtonTooltipPlacement} title={tscButtonTooltip}>
              <Button key={'task-specific-clarification-button'} icon={<ProfileOutlined />} disabled={tscButtonDisable} type={showTSCInActions ? 'text' : 'default'} shape={!showTSCInActions && 'circle'} className={`ant-btn-circle task-specific-clarification-button ${className}`} onClick={() => showTSCModal(showTSCInActions ? "initialize" : "")} >{showTSCInActions && 'Add Task Specific Clarification'}</Button>
            </Tooltip>}

            {tscInModal ? <Modal
              title={modalTitle}
              key={'task-specific-clarification-table-modal'}
              className='task-specific-clarification-modal'
              width="90%"
              height='80%'
              onCancel={() => handleCloseModal()}
              open={openTSCModal}
              okText='Save'
              footer={status === "draft" && (
                <>
                  <Button disabled={!tscEnabled || generateTSCAILoading} key="remove-tsc" onClick={() => handleRemove()}>
                    Remove
                  </Button>
                  <Button disabled={!isEmpty(errorIndexes) || generateTSCAILoading} key="add-tsc" type="primary" onClick={() => handleSave()}>
                    {tscEnabled ? 'Save' : 'Add'}
                  </Button>
                </>
              )}
            >
              {renderCriteriaTables(localCriteriumAssociations, onTextChanged)}
            </Modal> : renderCriteriaTables(localCriteriumAssociations, onTextChanged) }

          </div>
        </>
      )
      }
    </>
  );
};

export default TaskSpecificClarification;
