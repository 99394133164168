import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Popover, Space, Row, Col, Switch, Affix, Card, Popconfirm, Empty, Tooltip, Tag, Spin, Select } from "antd";
import { message } from "/src/components/UI/AntdAppHelper";
import { FabricCanvas, globalCanvasConfig, useFabric } from "/src/components/UI/Canvas/FabricCanvas"
import FabricTools from "/src/components/UI/Canvas/FabricTools"
import { updateImagesSRCForCanvas, getParsedJSONObject } from "/src/views/Segments/InteractiveHelpers"
import { getUniqueId, b64toBlob, getLabelFromIndexPath, buildOptionsArr } from "/src/lib/utils/helperMethods";
import axios from 'axios';
import { cloneDeep, debounce } from 'lodash';
import { Segment, SegmentData, showConfirmModal } from '/src/components/UI/Segment/UIHelper'
import CanvasComment from "/src/components/UI/Canvas/CanvasComment";
import ThumbnailAttachments from "/src/components/UI/Attachments/ThumbnailAttachments/ThumbnailAttachments";
import MediaShow from "/src/components/UI/Media/MediaShow";
import { actions as canvasAnnotationActions } from "/src/views/CanvasAnnotations/redux";
import { useDispatch, useSelector } from "react-redux";
import { currentUserSelector } from "/src/views/Auth/Login/selector";
// import MrSelect from "/src/components/UI/MrSelect/MrSelect";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { actions as segmentActions } from "/src/views/Segments/redux";
import { actions as topicActions } from "/src/views/Segments/Topics/redux";
import { activeSegmentIdSelector } from "../selector";
import { MrSelect } from "mr_react_framework";
import * as Sentry from "@sentry/react";
import { printModeSelector } from "/src/views/Experiences/selector";
import { activeAnnotateFileSelector } from "../../ExperienceUsers/selector";

const { shapeStyle } = globalCanvasConfig;

const SelectImages = MrSelect({
  config: {
    isJsx: true,
    processData: (data, props) => {
      console.log("processData =====>", data, props);
      let options = []
      return options
    },
  }
});

const FabricAnnotator = props => {
  console.log('Fabric Annotator props', props)
  const { attachments_json, gradingEnabled, updateAttachmentsJSONWithAnnoation, savingAnnotationDataPending, setSavingAnnotationDataPending = () => {}, segmentId, showOnlyMapped, showOnlyUnmapped} = props

  const dispatch = useDispatch();
  const currentUser = useSelector(currentUserSelector());
  const activeSegmentId = useSelector(activeSegmentIdSelector());
  const printMode = useSelector(printModeSelector())

  let parsedJSONObject = attachments_json;

  const experienceUserImages = (parsedJSONObject.images || parsedJSONObject.attachments || [])
  const images = experienceUserImages.filter((atc) => {
    if (showOnlyMapped) {
      return atc.segment_id === (activeSegmentId || segmentId)
    } else if (showOnlyUnmapped) {
      return !atc.segment_id
    } else {
      return atc.segment_id === (activeSegmentId || segmentId) || !atc.segment_id
    }
  });

  // const images = (parsedJSONObject.images || parsedJSONObject.attachments || []).filter((atc) => atc.segment_id == activeSegmentId || !atc.segment_id);
  const activeAnnotateFile = useSelector(activeAnnotateFileSelector())
  
  const initialAttachmentData = images[0] || {};
  console.log(
    "Annotator parsedJSONObject ",
    parsedJSONObject,
    initialAttachmentData
  );
  const mrIntl = useTranslate();
  // const [state, setState] = useState(parsedJSONObject)
  const [currentAttachmentData, setCurrentAttachmentData] = useState(initialAttachmentData)
  const [triggerSavingAnnotation, setTriggerSavingAnnotation] = useState()
  const [showLoader, setShowLoader] = useState(false)
  const [savingAnnotation, setSavingAnnotation] = useState(false)
  const [stateSetting, setStateSetting] = useState({
    updatedCount: 0,
    currentImageIndex: gradingEnabled ? 0 : "all",
  });
  const [canvasDataURL, setCanvasDataURL] = useState("");
  const [stateUpdatedCount, setStateUpdatedCount] = useState(0);
  const [showComments, setShowComments] = useState(true);
  const [annotationMode, setAnnotationMode] = useState(gradingEnabled);
  const [floatingCommentStyle, setFloatingCommentStyle] = useState({});
  const screenSettings = props.screenSettings || {};
  let mappedImageIndex = 0
  let unmappedImageIndex = 0
  const attachments_count =
    (parsedJSONObject.attachments && parsedJSONObject.attachments.length) || 0;
  const imagesCount = images.length;
  const currentAnnotation = currentAttachmentData.annotation || {};
  const currentAnnotationJSON = currentAnnotation.annotation_json || {};

  const comments =
    (currentAnnotationJSON && currentAnnotationJSON.comments) || [];
  const commentsCount = comments.length;
  const showAll = stateSetting.currentImageIndex === "all" || printMode;
  const annotationSupportedForFile =
    currentAttachmentData.type &&
    currentAttachmentData.type.indexOf("image") >= 0;
  console.log("currentAttachmentData  ==>", parsedJSONObject, experienceUserImages, activeSegmentId, showAll, stateSetting.currentImageIndex, printMode);

  useEffect(() => {
    updateAnnotationMode(
      !showAll && annotationSupportedForFile && gradingEnabled
    );
  }, [annotationSupportedForFile, showAll]);

  useEffect(() => {
    if (imagesCount > 0) {
      updateAnnotationMode(gradingEnabled, true);
      // saveState(parsedJSONObject)
    }
  }, [props.id, props.user_id, gradingEnabled]);

  useEffect(() => {
    if (activeSegmentId && activeSegmentId != "all") {
      const index = images.findIndex(imageId => imageId.id === activeAnnotateFile?.id)
        // Previously, we were only opening the first file of each question, 
        // and now whichever file we are opening, that file opens.
      if (index >= 0) {
        annotateImage("index", index)
      }
    }
  }, [activeSegmentId])

  // useEffect(() => {
  //   if (!printMode) {
  //     dispatch(
  //       topicActions.setActiveIdSuccess({
  //         id: currentAttachmentData.topic_id,
  //       })
  //     );
  //     dispatch(
  //       segmentActions.setActiveIdSuccess({
  //         id: currentAttachmentData.segment_id,
  //       })
  //     );
  //   }
  // }, [currentAttachmentData.segment_id])

  useEffect(() => {
    if(setSavingAnnotationDataPending) {
      setSavingAnnotationDataPending(savingAnnotation)
    }
  }, [savingAnnotation])

  // using triggerSavingAnnotation useEffect as using saveAnnotation function directly with canvas events not saves latest data

  useEffect(() => {
    // if (!showLoader) {
    //   setSavingAnnotationDataPending(true)
    // }
    if(triggerSavingAnnotation) {
      saveAnnotation(currentAttachmentData)
    }
  }, [triggerSavingAnnotation])

  // const { segmentId } = props
  // useEffect(() => {
  //   if (segmentId != 'all') {
  //     const index = images.findIndex((atc) => atc.segment_id == segmentId)
  //     if (index >= 0) {
  //       annotateImage('index', index)
  //     }
  //   } else {

  //   }
  // }, [segmentId])

  const [fabricReady, setCanvasFabricReady] = useState(false)
  const canvasRef = useRef({})
  const fabricRef = useFabric((currentCanvas) => {
    if (currentCanvas) {
      console.log("Current canvas in useFabric ==>", currentCanvas);
      canvasRef.current = currentCanvas;
      setCanvasFabricReady(true);
    }
  });
  let canvas = canvasRef.current || {}
  canvas.fabricReady = fabricReady;
  canvas.noUndo = true
  canvas.noRedo = true
  canvas.parentHeight = false
  canvas.noHeightUpdate = false
  canvas.allowObjectsToGoOutsideCanvas = true
  canvas.panningAllowed = true
  canvas.drawOnImageOnly = true
  canvas.orientation = "portrait"
  canvas.role = "teacher"
  canvas.shapeStyle = {
    ...shapeStyle,
    fill: "rgba(253,255,50, 0.1)",
    strokeColor: "rgba(253,255,50, 1)",
    fontSize: 25,
    strokeWidth: 1,
  }

  // const commentAllowedShapeNames = ['Rectangle', 'Circle', 'Path']

  const commentAddWithDebounce = debounce((options) => {
    let object = options.target
    if (commentAllowedShapeNames.indexOf(object.name) >= 0) {
      addCommentOnObjectAdded(object)
      showFloatingCommentOnMouseDown(object)
    } else {
      bringCommentIndicatorsInFront()
    }
    if (object && !canvas.commentIndicatorSavingStatus) {
      triggerSaveAnnotationWithDebounce()
      // saveAnnotation(currentAttachmentData)
    }
  }, 100)

  const commentAllowedShapeNames = ['Comment Indicator']
  canvas._customEventListeners = {
    onObjectAdded: (options) => {
      commentAddWithDebounce(options)
    },
    onUpdateFreeDrawPath: (object) => {
      if (commentAllowedShapeNames.indexOf(object.name) >= 0) {
        addCommentOnObjectAdded(object);
      } else {
        bringCommentIndicatorsInFront();
      }
    },
    onObjectRemoved: (options) => {
      let object = options.target;
      if (commentAllowedShapeNames.indexOf(object.name) >= 0) {
        removeCommentOnObjectRemoved(object);
      }
      if (object && !canvas.commentIndicatorSavingStatus) {
        triggerSaveAnnotationWithDebounce()
      // saveAnnotation(currentAttachmentData)
    }
    },
    onObjectModified: (options) => {
      let object = options.target
      console.log("options on mouse modified ==>", options, object, canvas)
      if (object && !canvas.commentIndicatorSavingStatus) {
        triggerSaveAnnotationWithDebounce()
        // saveAnnotation(currentAttachmentData)
      }
    },
    onMouseDown: (options) => {
      let object = options.target
    },
    onMouseUp: (object) => {
      if (object || canvas.isDrawingMode) {
        // triggerSaveAnnotationWithDebounce()
      }
    },
    onMouseOver: (options) => {
      let object = options.target;
      if (object && commentAllowedShapeNames.indexOf(object.name) >= 0) {
        highlightCommentCorrespondingToSelectedCommentIndicator(object);
      }
      if(object && object.name === "Text") {
        object.selected = true
        object.editingBorderColor = "yellow"
        object.borderColor =  "yellow"
        canvas.setActiveObject(object)
        canvas.renderAll();
      }
    },
    onObjectSelectionCreated: (options) => {
      let object = options.target;
      if (commentAllowedShapeNames.indexOf(object.name) >= 0) {
        highlightCommentCorrespondingToSelectedCommentIndicator(object);
      } else {
        removeHighlightCommentCorrespondingToSelectedCommentIndicator();
      }
    },
    onObjectSelectionUpdated: (options) => {
      let object = options.target;
      if (commentAllowedShapeNames.indexOf(object.name) >= 0) {
        highlightCommentCorrespondingToSelectedCommentIndicator(object);
      } else {
        removeHighlightCommentCorrespondingToSelectedCommentIndicator();
      }
    },
    onObjectSelectionClear: (options) => {
      let object = options.target;
      console.log("Object select clear ==>", object);
      if (commentAllowedShapeNames.indexOf(object.name) >= 0) {
        highlightCommentCorrespondingToSelectedCommentIndicator(object);
      } else {
        removeHighlightCommentCorrespondingToSelectedCommentIndicator();
      }
    },
  };

  console.log("fabric ready ==>", fabricReady);
  useEffect(() => {
    if (fabricReady) {
      canvas.resizeCanvas();
      updateCanvasData(currentAttachmentData);
    }
  }, [fabricReady]);

  let { json_uuid } = parsedJSONObject;
  // Presentation mode watching data change
  useEffect(() => {
    if (fabricReady && json_uuid) {
      // updateCanvasData(parsedJSONObject)
    }
  }, [json_uuid]);

  // useEffect(() => {
  //   if (stateUpdatedCount > 0) {
  //     if (props.onChange) {
  //       let annotations_json = cloneDeep(state)
  //       props.onChange(annotations_json)
  //     }
  //   }
  // }, [stateUpdatedCount]);

  // const saveState = (state) => {
  //   // setState(state)
  //   // setStateUpdatedCount((stateUpdatedCount) => stateUpdatedCount + 1)
  // }

  function addCommentOnObjectAdded(object) {
    let newComments =
      (currentAttachmentData.annotation &&
        currentAttachmentData.annotation.annotation_json.comments) ||
      [];
    let commentIndex = newComments.findIndex(
      (comment) => comment.id == object.id
    );

    console.log("comment index on object added ==>", commentIndex);
    if (commentIndex == -1) {
      setStateSetting({
        ...stateSetting,
        newAddedComment: true,
      });

      let commentIndex = newComments.length + 1;
      newComments = [
        ...newComments,
        {
          id: object.id,
          author: commentIndex,
          content: `${mrIntl("FabricAnnotator.comment_here")}...`,
        },
      ];

      setCurrentAttachmentData((currentAttachmentData) => ({
        ...currentAttachmentData,
        annotation: {
          ...currentAnnotation,
          annotation_json: {
            ...currentAnnotationJSON,
            comments: newComments,
          },
        },
      }));

      canvas.renderAll();
    }
  }

  const bringFrontObjects = ["Cross Tick", "Check Tick", "Comment Indicator"];
  function bringCommentIndicatorsInFront() {
    bringFrontObjects.forEach((objName) => {
      canvas.getObjectsByAttr({ name: objName }).forEach((object) => {
        object.bringToFront();
      });
    });
    canvas.requestRenderAll();
  }

  function removeCommentOnObjectRemoved(object) {
    const newComments = comments;
    const commentIndex = newComments.findIndex(
      (comment) => comment.id == object.id
    );
    newComments.splice(commentIndex, 1);
    setCurrentAttachmentData({
      ...currentAttachmentData,
      annotation: {
        ...currentAnnotation,
        annotation_json: {
          ...currentAnnotationJSON,
          comments: newComments,
        },
      },
    });

    newComments.forEach((comment, index) => {
      if (comment.id) {
        const commentObject = canvas.getObjectsByAttr({ id: comment.id })[0];
        if (commentObject) {
          let commentNumber = commentObject.getObjects()[1];
          commentNumber.set("text", (index + 1).toString());
        }
      }
    });
    canvas.renderAll();
  }

  function showFloatingCommentOnMouseDown(object) {
    // const event = options.e
    const topRight = object.oCoords.tr;
    setFloatingCommentStyle({
      ...floatingCommentStyle,
      left: topRight.x + 20,
      top: topRight.y + 20,
    });
  }

  function highlightCommentCorrespondingToSelectedCommentIndicator(object) {
    const newComments = comments;
    const commentIndex = newComments.findIndex(
      (comment) => comment.id == object.id
    );
    console.log("highlightCommentIndex ==>", commentIndex);
    setStateSetting({
      ...stateSetting,
      highlightCommentIndex: commentIndex,
    });
  }

  function removeHighlightCommentCorrespondingToSelectedCommentIndicator(
    object
  ) {
    if (stateSetting.highlightCommentIndex >= 0) {
      setStateSetting({
        ...stateSetting,
        highlightCommentIndex: -1,
      });
    }
  }

  function updateCanvasData(annotation_data = {}, successCallback) {
    if (canvas.fabricReady) {
      if (annotation_data.type && annotation_data.type.indexOf("image") == -1) {
        return;
      }
      console.log("annotation data ==>", annotation_data);
      const annotation = annotation_data.annotation;
      const cd = annotation && annotation.annotation_json.canvas_data;
      const canvas_data = (typeof cd == "string" ? JSON.parse(cd) : cd) || {};
      canvas.clearAll();
      updateViewportTransform();
      if (cd) {
        setShowLoader(canvas_data.objects ? true : false)
        setShowComments(false)
        console.log("show loader value ==>", showLoader, canvas_data.objects)
        canvas.loadFromJSON(canvas_data, function () {
          console.log("Canvas is loaded with canvas data ==>", canvas_data)
          canvas.resizeCanvas();
          canvas.renderAll();
          // setShowLoader(false)
          setShowComments(true)
          if (successCallback) {
            successCallback()
          } 
        }, function (o, object) {
          console.log("Loaded objects are ==>", o, object)
          // return false
          // console.log('Loaded Object ==>', object);
          // if (!props.answerMode && object) {
          //   object.hasControls = false;
          //   object.selectable = false;
          //   object.evented = false;
          // }
        });
      } else {
        insertImageForAnnotation(annotation_data, successCallback);
      }
    }
  }

  function insertImageForAnnotation(currentAttachmentData, successCallback) {
    console.log("insert image for annotation ==>", currentAttachmentData)
    setShowLoader(true)
    setShowComments(false)
    
    canvas.insertImages([currentAttachmentData], 'fullSizeBGImage', (image, index) => {
      console.log("current inserted image ==>", image)
      image.selectable = false
      canvas.renderAll()
      canvas.resizeCanvas()
      // setShowLoader(false)
      setShowComments(true)
      if (successCallback) {
        successCallback()
      }
    }
    );
  }

  function updateViewportTransform() {
    if (canvas && canvas.setViewportTransform) {
      // const viewportTransform = canvas.viewportTransform || [0, 0, 0, 0, 0, 0];
      canvas.setViewportTransform([
        1,
        0,
        0,
        1,
        0,
        0,
      ]);

      // Resize canvas will handle current zoom status
    }
  }

  const saveAnnotation = (currentAttachmentData, afterAnnotationSaved) => {
    if (showLoader) {
      setShowLoader(false)
      return
    }
    
    setSavingAnnotation(true)
    updateViewportTransform();
    canvas.resizeCanvas();

    const currentAnnotation = currentAttachmentData.annotation || {};
    const currentAnnotationJSON = currentAnnotation.annotation_json || {};

    const id = currentAnnotation.id;
    let action = "create";
    if (id) {
      action = "update";
    }
    dispatch(canvasAnnotationActions[`${action}Start`]())

    const base64Data = canvas.toDataURL({format: "png"});
    // console.log('Base64Data ===>', base64Data)

    const blob = b64toBlob(base64Data);
    const filename = `annotated_image_1_png`;

    console.log("b64toBlob ==>", blob);
    const formData = new FormData();
    formData.append("replace_file", true);
    formData.append("file_path", `${currentAttachmentData.id}`);
    formData.append("file", blob, filename);

    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        token: token,
      },
    };

    const url =
      (import.meta.env.VITE_API_URL || "/backend/api/v1/") + "attachments";
      axios.post(url, formData, config).then(
      (response) => {
        console.log("Returned response ==>", response);

        console.log(
          "currentAttachment data before save ==>",
          currentAnnotationJSON
        );
        if (response.data.url) {
          dispatch(
            canvasAnnotationActions[action](
              {
                id: id,
                canvas_annotation: {
                  ...currentAnnotation,
                  attachment_uuid: currentAttachmentData.uid,
                  user_id: currentUser.id,
                  experience_user_id: props.eu_id,
                  annotation_json: {
                    ...currentAnnotationJSON,
                    annotated_image_data: {
                      ...response.data,
                      s3_url: response.data.s3_url + `?${new Date().getTime()}`,
                    },
                    canvas_data: JSON.stringify(
                      canvas.toJSON(canvas.jsonPropToAdd)
                    ),
                  },
                },
              },
              {
                success: {
                  showMessage: false,
                  message: mrIntl("CommonText.saved_successfully_success_msg"),
                  duration: 1,
                },
                successCallback: (response) => {
                  // let apiAnnotation = formatJsonApiData(response.data.annotation)
                  console.log("returned annotation ==>", response);
                  let newImages = experienceUserImages;
                  let idx = experienceUserImages.findIndex(
                    (img) =>
                      img["uid"] ==
                      response.data.canvas_annotation.attachment_uuid
                  );
                  newImages[idx] = {
                    ...currentAttachmentData,
                    annotation: {
                      ...response.data.canvas_annotation,
                    },
                  };
                  console.log("index of image ==>", idx, newImages[idx]);
                  const newAttachmentsJSON = {
                    ...attachments_json,
                    images: newImages,
                  };
                  setCurrentAttachmentData(newImages[idx]);
                  updateAttachmentsJSONWithAnnoation(newAttachmentsJSON);
                  setSavingAnnotation(false);
                  if (afterAnnotationSaved) {
                    afterAnnotationSaved();
                  }
                },
              }
            )
          );
        } else {
          console.log("Canvas annotation file not saved returned response having no url ==>", response);
          message.error(mrIntl("FabricAnnotator.something_went_wrong_error_msg"));
          setSavingAnnotation(false);
          throw new Error(`Canvas annotation file not saved returned response having no url, URL - ${response.data.url}, Status - ${response.status}`);
        }
      },
      (error) => {
        console.log("Canvas annotation file not saved returned error ==>", error);
        message.error(mrIntl("FabricAnnotator.something_went_wrong_error_msg"));
        setSavingAnnotation(false);
        Sentry.captureException(error);
      }
    );
  };

  const triggerSaveAnnotationWithDebounce = useCallback(debounce(() => {
    setTriggerSavingAnnotation(getUniqueId())
  }, 500), []);

  const saveComments = (comments) => {
    let newAnnotationData = {
      ...currentAttachmentData,
      annotation: {
        ...currentAnnotation,
        annotation_json: {
          ...currentAnnotationJSON,
          comments: comments
        }
      }
    }
    
    setCurrentAttachmentData(newAnnotationData)
    setStateSetting({
      ...stateSetting,
      newAddedComment: false,
      highlightCommentIndex: -1
    })
    setSavingAnnotationDataPending(true)
    triggerSaveAnnotationWithDebounce()
    // saveAnnotation(currentAttachmentData)
    canvas.commentIndicatorSavingStatus = false
  }

  // let toolBar = ['move', 'rotateLeft', 'iText', 'checkTick', 'wrongTick', 'image', 'line', 'arrow', 'rectangle', 'circle', 'polygon', 'path', 'fillColor', 'strokeColor', 'undo', 'redo', 'delete', 'clearAll', 'resources']
  let toolBar = ['rotateLeft', 'rotateRight', 'move', 'zoomIn', 'zoomOut', 'checkTick', 'wrongTick', 'commentIndicator', 'rectangle', 'path', 'text', 'delete']

  const updateAnnotationData = (nextIndex, newImage) => {
    if (savingAnnotation || showLoader) {
      return;
    }
    console.log("542 next index ==>", savingAnnotationDataPending)
    setStateSetting({
      ...stateSetting,
      newAddedComment: false,
      highlightCommentIndex: -1,
      currentImageIndex: nextIndex
    })
    if (nextIndex >= 0) {
      if (savingAnnotationDataPending || canvas.ITextEditing || canvas.commentIndicatorSavingStatus) {
        saveAnnotation(currentAttachmentData, () => {
          setCurrentAttachmentData(newImage);
          updateCanvasData(newImage);
        });
        // showConfirmModal({
        //   centered: true,
        //   title: `Save annotation`,
        //   content: 'You have some unchanged data, please save.',
        //   onOk: () => {
        // saveAnnotation(() => {
        //   setCurrentAttachmentData(newImage)
        //   updateCanvasData(newImage)
        // })
        //   }
        // })
      } else {
        setCurrentAttachmentData(newImage);
        updateCanvasData(newImage);
      }
    }
  };

  function destroyCanvas() {
    if (canvas.dispose) {
      canvas.dispose();
      canvasRef.current = null;
      setCanvasFabricReady(false);
    }
  }

  function updateAnnotationMode(value, reinit) {
    if (annotationMode) {
      destroyCanvas();
    }
    let currentIndex = reinit ? 0 : stateSetting.currentImageIndex;
    if (currentIndex >= 0) {
      setStateSetting((stateSetting) => ({
        ...stateSetting,
        newAddedComment: false,
        highlightCommentIndex: -1,
        currentImageIndex: currentIndex,
      }));
      console.log("images while reinit ==>", reinit);
      const newCurrentAttachmentData = images[currentIndex] || {};
      setCurrentAttachmentData(newCurrentAttachmentData);
    }
    setAnnotationMode(value);
  }

  const annotateImage = (switchTo, index = 0) => {
    let nextIndex,
      newImage = {};
    let currentIndex =
      stateSetting.currentImageIndex != "all"
        ? stateSetting.currentImageIndex
        : 0;

    if (index == "all") {
      nextIndex = index;
      newImage = {
        ...images[0]
      }
      updateAnnotationData(nextIndex, newImage)
      updateAnnotationMode(false)
    }

    if (switchTo == "index") {
      nextIndex = index;
      newImage = {
        ...images[nextIndex],
      };

      updateAnnotationData(nextIndex, newImage);
    } else if (switchTo == "prev") {
      nextIndex = currentIndex - 1;
      if (nextIndex == -1) {
        return;
      }
      newImage = {
        ...images[nextIndex],
      };

      updateAnnotationData(nextIndex, newImage);
    } else if (switchTo == "next") {
      nextIndex = currentIndex + 1;
      if (nextIndex == imagesCount) {
        return;
      }
      newImage = {
        ...images[nextIndex],
      };

      updateAnnotationData(nextIndex, newImage);
    } else if (switchTo == "reset") {
      nextIndex = currentIndex;
      newImage = {
        ...images[nextIndex],
      };
      newImage = {
        ...newImage,
        annotation: {
          ...newImage.annotation,
          annotation_json: {},
        },
      };

      // updateAnnotationData(nextIndex, newImage)
      // setCurrentAttachmentData(newImage)
      // updateCanvasData(newImage)
      // const newAnnotatedImages = images
      // newAnnotatedImages[nextIndex] = {
      //   ...newImage
      // }

      // saveState({
      //   ...state,
      //   images: newAnnotatedImages
      // })
      setCurrentAttachmentData(newImage);
      updateCanvasData(newImage, () => saveAnnotation(newImage));
    }

    // if (newImage.topic_id) {
    //   dispatch(topicActions.setActiveIdSuccess({ id: newImage.topic_id }));
    //   dispatch(segmentActions.setActiveIdSuccess({ id: newImage.segment_id }));
    // }
  };

  const getFileDropdown = () => {
    // const options = [{ label: `All`, value: "all" }];
    const options = [];
    // const options = []
    const mappedOptions = []
    const unmappedOptions = []
    images.map((atc, index) => {
      // const index = i + 1

      if (atc.segment_id ==  activeSegmentId || !atc.segment_id ) {
        // let label = `Page ${index + 1}`;
        if (atc.segment_id && atc.topic_index >= 0) {
          mappedImageIndex += 1
          // let label = `Page ${mappedImageIndex}`;
          let label = mrIntl("FabricAnnotator.page_label", {mappedImageIndex : mappedImageIndex});
          if (atc.segment_index_path) {
            label += ` (Q. ${getLabelFromIndexPath(atc.segment_index_path)})`;
          } else {
            label += ` (Q. ${atc.topic_index + 1}.${atc.question_index + 1})`;
          }
          // mappedOptions.push({ label: label, value: index });
          mappedOptions.push({label: label, value: index});
        } else {
          unmappedImageIndex += 1
          let label = `Page ${unmappedImageIndex}`;
          // unmappedOptions.push({ label: label, value: index })
          unmappedOptions.push({label: label, value: unmappedImageIndex + mappedImageIndex - 1})
        }
      }
    });
    if(mappedOptions.length > 0) {
      options.push({label: mrIntl("FabricAnnotator.mapped"), options: mappedOptions})
    }
    if (unmappedOptions.length > 0) {
      options.push({label: mrIntl("FabricAnnotator.unmapped_files"), options: unmappedOptions})
    }
    console.log(
      "value and options ==>",
      stateSetting.currentImageIndex,
      options,
      images
    );
    return (
      <div className="annoatator-file-selector">
        <Button
          disabled={stateSetting.currentImageIndex == 0}
          // type="primary"
          onClick={() => annotateImage("prev")}
          icon={<LeftOutlined />}
        ></Button>
        <SelectImages
          value={stateSetting.currentImageIndex}
          onChange={(value) => annotateImage("index", value)}
          config={{
            widgetConfig: {
              options: options,
            },
          }} />
        <Button
          disabled={stateSetting.currentImageIndex == mappedImageIndex + unmappedImageIndex - 1}
          // type="primary"
          onClick={() => annotateImage("next")}
          icon={<RightOutlined />}
        ></Button>
      </div>
    );
  };

  const onAnnotationAreaScrolled = (e) => {
    let element = e.target;

    if (showLoader) {
      return;
    }
    console.log(
      "element props",
      element,
      element.scrollHeight,
      element.scrollTop,
      element.clientHeight
    );
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      annotateImage("next");
      element.scrollTop = 1;
      console.log("on scrolled event ==>", "Reached at end");
    } else if (element.scrollTop === 0) {
      annotateImage("prev");
      element.scrollTop = 5;
      console.log("on scrolled event ==>", "Reached at top");
    }
    console.log("on scrolled event ==>", "scrolling", e);
  };

  // <FileOutlined />
  const filesInfo = (
    <Tooltip title="Total uploads" placement="top">
      <Tag>
        {" "}
        {attachments_count} files | {imagesCount} pages
      </Tag>
    </Tooltip>
  );

  const annotatorTools = [];
  const annotatorActions = !printMode &&
    imagesCount > 0 && [getFileDropdown()];
    // imagesCount > 0 && [filesInfo, getFileDropdown()];

  if (annotationMode && imagesCount > 0 && canvas.fabricReady) {
    annotatorTools.push(
      <FabricTools
        canvas={canvas}
        toolBarConfig={{
          toolBar: toolBar,
          rectangle: {
            imageEvented: true,
          },
          drawMode: true,
          resources: true,
          toolStyle: {
            tooltipPlacement: "top",
          },
        }}
      />
    );
    
    annotatorTools.push(
      <Popconfirm
        key="annotate-reset"
        title={mrIntl("FabricAnnotator.confirm_reset_title")}
        onConfirm={() => annotateImage("reset")}
        okText={mrIntl("CommonText.yes")}
        cancelText={mrIntl("CommonText.no")}
      >
        <div><Button className="annotation-reset-button"> {mrIntl("CommonText.reset")} </Button></div>
      </Popconfirm>
    );

    annotatorActions.push(
      <Button
        loading={showLoader || savingAnnotation}
        disabled={showLoader || !annotationSupportedForFile || savingAnnotation}
        type="primary"
        className="save-annotation"
        onClick={() => saveAnnotation(currentAttachmentData)}
      >
        {savingAnnotation ? <MrTranslate id={"CommonText.saving"}/> : <MrTranslate id={"CommonText.save"}/>}
      </Button>
    );
  }

  console.log(
    "Annotator state before render ==>",
    stateSetting,
    currentAttachmentData
  );
  console.log("fabric annotator canvas before render ===>", printMode, images, attachments_json);
  return (
    <Segment style={{ width: "100%" }} className="fabric-annotator">
      {/* <ThumbnailAttachments
        attachments={attachments}
        draggableProps={{
          isDragDisabled: true,
        }}
        listProps={{
          listType: 'picture-card',
          showRemoveIcon: false,
          onPreview: (file) => {
            const index = images.findIndex((atc) => atc.id == file.id)
            annotateImage('index', index)
          }
        }}
      /> */}
      {/* <div className="page-break-before-always"></div> */}
      <SegmentData
        key={`segment-data-${props.id}`}
        className="annotator-canvas"
        tabIndex={-1}
        onKeyDown={(e) => {
          if (canvas.onKeyDown) {
            canvas.onKeyDown(e);
          }
        }}
      >
        <Card title={annotatorTools} extra={annotatorActions}>
          <div className={`annotation-area ${printMode ? "full-height" : ""}`}>
            {(showLoader) && <Spin size="large" style={{ "position": "absolute", "left": "50%", "top": "20px", "z-index": "1" }} />}

            {!imagesCount && <Empty description="No files" />}

            {imagesCount > 0 &&
              (annotationMode && annotationSupportedForFile ? (
                <Row>
                  <Col span={5} offset={19} className={"annoation-info"}>
                    <h3>
                      {currentAttachmentData.pdf_data &&
                        currentAttachmentData.pdf_data.name}
                    </h3>
                    {/* NOTE: for converted image it is showing time of conversion */}
                    {!printMode && (
                      <Tooltip
                        title={
                          currentAttachmentData.segment_id
                            ? mrIntl("FabricAnnotator.uploaded_during_exam_window_tooltip")
                            : mrIntl("FabricAnnotator.uploaded_after_exam_window")
                        }
                        placement="bottom"
                      >
                        <h5
                          className="fabric-annotator-updated-at"
                          style={{
                            color: currentAttachmentData.segment_id
                              ? "grey"
                              : "red",
                          }}
                        >
                          {mrIntl("FabricAnnotator.uploaded_at")}{" "}
                          {dayjs(currentAttachmentData.updated_at).format(
                            "MMM DD, hh:mm A"
                          )}
                        </h5>
                      </Tooltip>
                    )}
                  </Col>
                  <Col span={18} className={"annotation-canvas"}>
                    <FabricCanvas
                      fabricRef={fabricRef}
                      canvas={canvas}
                      // saveCanvasData={saveCanvasData}
                      maxAllowedComment={10}
                      showLoader={showLoader}
                    />

                    <div
                      className={"annotation-floating-comment"}
                      style={floatingCommentStyle}
                    >
                      <CanvasComment
                        createMode={true}
                        floatingComment={true}
                        canvas={canvas}
                        parentStateSetting={stateSetting}
                        comments={comments}
                        saveComments={saveComments}
                      />
                    </div>
                  </Col>

                  {showComments && commentsCount > 0 && (
                    <Col span={6}>
                      <div className={"annotation-comments"}>
                        <CanvasComment
                          createMode={true}
                          annotationMode={annotationMode}
                          canvas={canvas}
                          parentStateSetting={stateSetting}
                          comments={comments}
                          saveComments={saveComments}
                        />
                      </div>
                    </Col>
                  )}
                </Row>
              ) : !showAll ? (
                <Row>
                  <Col span={6} offset={18} className={"annoation-info"}>
                    <h3>
                      {currentAttachmentData.pdf_data &&
                        currentAttachmentData.pdf_data.name}
                    </h3>
                    {/* NOTE: for converted image it is showing time of conversion */}
                    <Tooltip
                      title={
                        currentAttachmentData.segment_id
                        ? mrIntl("FabricAnnotator.uploaded_during_exam_window_tooltip")
                        : mrIntl("FabricAnnotator.uploaded_after_exam_window")
                      }
                      placement="bottom"
                    >
                      <h5
                        style={{
                          color: currentAttachmentData.segment_id
                            ? "white"
                            : "red",
                        }}
                      >
                        <MrTranslate id={"FabricAnnotator.uploaded_at"} />{" "}
                        {dayjs(currentAttachmentData.updated_at).format(
                          "MMM DD, hh:mm A"
                        )}
                      </h5>
                    </Tooltip>
                  </Col>
                  <Col span={annotationSupportedForFile ? 18 : 24}>
                    <MediaShow
                      noCache={true}
                      file={
                        currentAnnotationJSON.annotated_image_data
                          ? currentAnnotationJSON.annotated_image_data
                          : currentAttachmentData
                      }
                    />
                  </Col>
                  <Col span={6} className={"annotation-comments"}>
                    <CanvasComment comments={comments} />
                  </Col>
                </Row>
              ) : null)}

            {imagesCount > 0 && showAll && (
              <div className={"annotated-images"}>
                {images.map((atc, index) => {
                  const annotation = atc.annotation || {};
                  const annotationJSON = annotation.annotation_json || {};
                  const annotatedImage = annotationJSON.annotated_image_data;
                  const comments = annotationJSON.comments || [];
                  const commentsCount = comments.length;
                  console.log("checking annotation data ==>", atc.topic_index, atc.question_index, atc.segment_index_path, getLabelFromIndexPath(
                                atc.segment_index_path
                              ))
                  return (
                    <Row className={"annotated-image"}>
                      {/* <div className="page-break-before-always"></div> */}
                      <Col span={18} className={"annotation-index"}>
                        {mrIntl("CommonText.page")} {index + 1}{" "}
                        {atc.segment_id && atc.topic_index >= 0
                          ? atc.segment_index_path
                            ? `(Q.${getLabelFromIndexPath(
                                atc.segment_index_path
                              )})`
                            : `(Q. ${atc.topic_index + 1}.${
                                atc.question_index + 1
                              })`
                          : ""}
                      </Col>
                      <Col span={6} className={"annoation-info"}>
                        <h3>{atc.pdf_data && atc.pdf_data.name}</h3>
                        {/* NOTE: for converted image it is showing time of conversion */}
                        {!printMode && (
                          <Tooltip
                            title={
                              atc.segment_id
                              ? mrIntl("FabricAnnotator.uploaded_during_exam_window_tooltip")
                              : mrIntl("FabricAnnotator.uploaded_after_exam_window")
                            }
                            placement="bottom"
                          >
                            <h5
                              style={{
                                color: atc.segment_id ? "white" : "red",
                              }}
                            >
                              <MrTranslate id={"FabricAnnotator.uploaded_at"} />{" "}
                              {dayjs(atc.updated_at).format("MMM DD, hh:mm A")}
                            </h5>
                          </Tooltip>
                        )}
                      </Col>
                      <Col span={18} className={"show-annotated-image"}>
                        <MediaShow
                          noCache={true}
                          file={
                            atc.type.indexOf("image") >= 0 && annotatedImage
                              ? annotatedImage
                              : atc
                          }
                        />
                      </Col>
                      <Col span={6} className={"annotation-comments"}>
                        {/* <span> File {index + 1} Comments </span> */}
                        <CanvasComment comments={comments} />
                      </Col>
                    </Row>
                  );
                })}
              </div>
            )}
          </div>
        </Card>
      </SegmentData>
    </Segment>
  );
};
export default FabricAnnotator;
