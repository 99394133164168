import React, { useEffect, useState } from "react"
import { getParsedJSONObject } from "../InteractiveHelpers";
import SelectFromComputer from "/src/components/UI/Media/SelectFromComputer";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { cloneDeep, isEmpty } from "lodash";
import PreviewAttachment from "/src/components/UI/Attachments/PreviewAttachment";
import { Alert, Upload } from "antd";
import { isImageOrPdfFileType, onDownload, getUniqueId } from "/src/lib/utils/helperMethods";
import SegmentItemFooter from "../SegmentItemDetail/SegmentItemFooter";
import { useSelector } from "react-redux";
import { currentUserSelector } from "/src/views/Auth/Login/selector";
import { activeSegmentSelector } from "../selector";
import { enabledFeaturesSelector } from "/src/views/Auth/Login/selector";
import { printModeSelector } from "../../Experiences/selector";
import { FileAddOutlined } from "@ant-design/icons";


const FileUpload = (props) => {
  console.log("FileUpload props===>", props);
  const { segmentSettings, setRenderMath, segmentFooterAttributes, screenSettings, parentIdxs, segment_id, experience_id, experienceViewMode } = props;
  let {
    parsedJSONObject,
    createMode,
    answerMode,
    presentationMode,
  } = getParsedJSONObject(props);
  const activeSegment = useSelector(activeSegmentSelector());
  const currentUser = useSelector(currentUserSelector())
  const enabledFeatures = useSelector(enabledFeaturesSelector());
  const printMode = useSelector(printModeSelector())

  const propsAttemptStatus = props.attempt_status || {};
  const [stateSetting, setStateSetting] = useState({
    updatedCount: 0,
    autoCheckMode: false,
    quickCheckModeEnabled: false,
    showCorrectAnswerSwitchValue: false,
  });
  console.log("parsedJSONObject===>", parsedJSONObject);
  const mrIntl = useTranslate();
  const allowLargeFiles = enabledFeatures.allow_large_files;
  if (createMode) {
    parsedJSONObject = {
      segment_data: {
        attachments: [],
      },
    };
    if (props.question_type == "file_upload") {
      parsedJSONObject = props.value || parsedJSONObject;
    }
  }
  let attemptStatusObject = {
    reset_count: propsAttemptStatus.reset_count || 0,
    json_attempted: propsAttemptStatus.json_attempted || false,
  };
  const [state, setState] = useState(parsedJSONObject);
  console.log("state file upload component====>", state);

  const [stateUpdatedCount, setStateUpdatedCount] = useState(0);
  const [attemptStatus, setAttemptStatus] = useState(attemptStatusObject);
  let attachments_json, attempt_status;
  let maxSize = "20 MB";
  if (allowLargeFiles) {
    maxSize = "100 MB";
  }
  if (presentationMode) {
    attachments_json = parsedJSONObject || {};
    attempt_status = attemptStatusObject;
  } else {
    attachments_json = state || {};
    attempt_status = attemptStatus;
  }
  console.log("attachments_json ui 98===>", attachments_json);
  const attachments = attachments_json.attachments || [];
  const user_id = props.userId || currentUser.id
  const mediaFormInitialState = {
    modal: false,
    loading: false,
    visible: false,
    title: mrIntl("CommonText.file_upload"),
    okText: mrIntl("CommonText.ok"),
    cancelText: mrIntl("CommonText.cancel"),
    triggerSaveAction: 0,
    requireFooter: false,
    limit: 10,
    extraParams: `save_record=true&user_id=${user_id}&record_type=Experience&record_id=${experience_id}&upload_type=${"QuestionWise"}`,
    supportedTypes: [
     "image/jpeg",
     "application/pdf",
    //  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    //  "application/msword",
    ],
    supportedFormats: [
     "jpeg",
     "jpg",
     "pdf",
    //  "doc",
    //  "docx",
    ],
    selectConfig: {
      defaultFileList: attachments,
      maxSize: maxSize,
      type: "pictures-wall",
      showUploadList: {
        showRemoveIcon: true,
        showPreviewIcon: true,
      },
    },
  };

  const [mediaFormSetting, setMediaFormSetting] = useState(
    mediaFormInitialState
  );
  useEffect(() => {
    if (createMode) {
      if (props.onChange) {
        let teacher_json = cloneDeep(state);
        props.onChange(teacher_json);
      }
      if (props.onSubmit) {
        console.log("On submit is available now", props.onSubmit);
      }
    } else if (answerMode && stateUpdatedCount > 0) {
      if (props.onChange) {
        let result = {
          attachments_json: cloneDeep(state),
          response_json: cloneDeep(state),
          attempt_status: cloneDeep(attemptStatus),
        };
        console.log("result======>", result);
        props.onChange(result);
      }
    }
  }, [stateUpdatedCount]);

  console.log("mediaFormSetting===>", mediaFormSetting);
  const saveState = (files) => {
    console.log("saveState files===>", files);
    setState(files);
    setStateUpdatedCount(stateUpdatedCount + 1);
  };

  const onRemoveFile = (file) => {
    console.log("onRemoveFile file===>", file);
    const newSegmentData = {
      ...attachments_json,
      attachments: attachments.filter((attachment) => attachment.uid !== file.uid),
    };
    saveState(newSegmentData);
  };

  const onSaveMedia = (files) => {
    const newSegmentData = {
      ...attachments,
      attachments: files,
    };
    saveState(newSegmentData);
    console.log("Files onSaveMedia==>", newSegmentData);

    const isFileUploded = files && files.length ? true : false;
    if (answerMode && isFileUploded) {
      setAttemptStatus({
        ...attemptStatus,
        file_upload: isFileUploded,
        json_attempted: isFileUploded || attemptStatus.caption_added,
      });
    }
    setMediaFormSetting({
      ...mediaFormSetting,
      visible: false,
    });
  };

  mediaFormSetting.onChangeSaveFiles = (files) => {
    console.log("upload file student file upload media form ===>", files);
    onSaveMedia(beforeSave(files));
  };

  mediaFormSetting.onRemoveFile = (file) => {
    console.log("after upload remove file ====>", file);
    onRemoveFile(file);
  }

  const onPreview = async (file) => {
    console.log("over ride code onPreview file===>", file);
    const fileResponse = file.response;
      if (fileResponse && fileResponse.status == "done") {
        file = fileResponse;
      }
    const contentType = isImageOrPdfFileType(file)
    if (contentType == "pdf" || contentType == "image") {
      let src = file.url;
      console.log("value and file=====>", file, src);
      if (!src) {
        src = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(file.originFileObj);
          reader.onload = () => resolve(reader.result);
        });
      }
      setMediaFormSetting({
        ...mediaFormSetting,
        previewCurrentFile: {
          ...file,
          url: src,
        },
      });
    } else {
      if (answerMode) {
        onDownload(file);
      } else {
        window.open(file.url, '_blank');
      }
    }
  }
  mediaFormSetting.onPreview = (file) => onPreview(file);
  mediaFormSetting.selectConfig.onPreview = (file) => onPreview(file);

  const beforeSave = (files) => {
    files.map((file) => {
      file["upload_type"] = "QuestionWise"
      file["segment_id"] = segment_id;
      file["segment_index_path"] = parentIdxs
    });
    return files;
  };

  const resetQuestion = () => {
    const data = cloneDeep(props.student_json);
    console.log("data file Upload===>", data);
    setAttemptStatus({
      ...attemptStatus,
      reset_count: attemptStatus.reset_count + 1,
      json_attempted: false,
    });
    saveState(data);

    if (setRenderMath) {
      (getUniqueId());
    }
    setMediaFormSetting((prevSetting) => ({
      ...prevSetting,
      triggerResetToDefault: getUniqueId(),
      selectConfig: {
        ...prevSetting.selectConfig,
        defaultFileList: [],
      },
    }));
  };

  const toggleAnswer = () => {
    setStateSetting({
      ...stateSetting,
      quickCheckModeEnabled: !stateSetting.quickCheckModeEnabled,
    });
  };

  return (
    <>
      {createMode && (
        <div>
          <Alert
            style={{ textAlign: "left" }}
            description={"Student will get option to upload file"}
            type="info"
            showIcon
          />
        </div>
      )}
      {answerMode ? (
        printMode ? (
          <div className="bordered-content text-center">
            <h3>Question type: {mrIntl("CommonText.file_upload")}</h3>
            <br />
            <FileAddOutlined className="print-content-icon" />
            <h4>This item includes interactive content. Please refer to the digital version of this assessment to view this item.</h4>
          </div>
        ) : (
          <>
            <SelectFromComputer
              computer={true}
              url={true}
              mediaFormSetting={mediaFormSetting}
              setMediaFormSetting={(data) => setMediaFormSetting(data)}
            ></SelectFromComputer>
            <p className="file-support-msg">
              {mrIntl("CommonText.supported_types")}:{" "}
              {mediaFormSetting.supportedFormats.join(", ")}
            </p>
          </>
        )
      ) : null}

      {experienceViewMode === "apGradeTest" && isEmpty(activeSegment) &&
        <Upload
          fileList={props.attachments_json?.attachments}
          listType="picture-card"
          showUploadList={{
            showRemoveIcon: false,
            showPreviewIcon: true,
            showDownloadIcon: false,
          }}
          onPreview={(file) => mediaFormSetting.onPreview(file)}
        ></Upload>
        }
      <PreviewAttachment
        type={"modal"}
        visible={mediaFormSetting.previewCurrentFile}
        file={mediaFormSetting.previewCurrentFile}
        files={attachments}
        onCancel={() =>
          setMediaFormSetting({
            ...mediaFormSetting,
            previewCurrentFile: null,
          })
        }
      />
      <SegmentItemFooter
        createMode={createMode}
        answerMode={answerMode}
        presentationMode={presentationMode}
        segmentFooterAttributes={segmentFooterAttributes}
        segmentSettings={segmentSettings}
        segmentStateSettings={stateSetting}
        screenSettings={screenSettings}
        setRenderMath={setRenderMath}
        toggleAnswer={toggleAnswer}
        resetQuestion={resetQuestion}
      ></SegmentItemFooter>
    </>
  )
}

export default FileUpload;
