import { fromJS } from "immutable";
import { call, put, takeEvery, select, all } from "redux-saga/effects";
import { MrReduxCrud, formatJsonApiData } from "mr_react_framework";
import { axiosInstance } from "/src/api/apiModule";
import { message } from "/src/components/UI/AntdAppHelper";
import { actions as experienceReduxActions } from "/src/views/Experiences/redux";
import { actions as topicActions } from "/src/views/Segments/Topics/redux";
import { topicsSelector, activeTopicIdSelector } from "./Topics/selector";
import { segmentsSelector } from "./selector";
import { activeAdjustedExperienceSelector } from "/src/views/Experiences/selector";
import _ ,{ upperFirst } from "lodash";
import { currentUserSelector } from "/src/views/Auth/Login/selector";
import { setToLS } from "/src/lib/utils/helperMethods";

const reduxCrud = new MrReduxCrud({
  axiosInstance,
  resourceName: "segment",
  actionNames: ["FETCH", "CREATE", "SHOW", "UPDATE", "DELETE", "REARRANGE", "DUPLICATE", "PINNED", "SET_LAYOUT", "SET_MODE", "SET_ACTIVE_MEDIA_ID", "SET_PARENTS_POINTS", "SET_ACTIVE_ID", "SET_ACTIVE_LOGS_HISTORY_FOR_SEGMENT_ID", "SET_ACTIVE_SEGMENT_PARENT_IDXS", "SET_EDIT_MODE_FOR_SEGMENT_ID", "SET_SHOW_SEGMENT_FORM", "SET_SPEECH_TO_TEXT_LANGUAGE"],
});

const initialState = fromJS({
  segments: [],
  page: 1,
  total_pages: 1,
  total_count: 0,
  page_size: 0,
  // action: {},
  error: null,
  loading: false,
  pinnedSegments: [],
  isPinned: false,
});

const successFunc = (state, action) => {
  // Using for duplicate - needed in case of add from library and duplicate of nested questions
  // console.log("action data", action);
  console.log("segemnts create, duplicate successFunc action, state", action, state.get("segments"));
  // done: need to get segments based on parent_id to handle nested. send parent_id in action payload
  let payload = action.payload;
  let options = action.options;
  // let stateSegments = state.get("segments").filter((item) => item.parent_id === parseInt(options.parentId))
  let stateSegments = state.get("segments")
  let segments = [...stateSegments];
  // console.log("action.payload", payload);


  let insertAtIndex
  if(action.type.indexOf("CREATE") !== -1){
    insertAtIndex = options && options.createAtIndex
  }
  if(action.type.indexOf("DUPLICATE") !== -1){
    insertAtIndex = options && options.duplicateAtIndex
  }
  let updatedSegment = formatJsonApiData(payload.data.segment)
  if(typeof(insertAtIndex) !== "undefined"){
    segments.splice(insertAtIndex, 0, updatedSegment);
  } else {
    segments.unshift(updatedSegment);
  }
  
  let descendants = updatedSegment.descendants
  let allSegments = _.concat(segments, _.flatten(descendants))

  console.log(" state after save", allSegments);
  if (options?.success?.showMessage !== false) {
    message.success(`Item successfully created.`);
  }
  // !causing re-rendering of all levels of SegmentLists
  return state.set("segments", allSegments).set("loading", false).set("duplicateloading", false).set("createloading", false)
  // return state.set("duplicateloading", false)

}

const rearrangeSuccessFunc = (state, action) => {
  // console.log("action data", action);
  console.log("action, state", action, state.get("segments"));
  // done: need to get segments based on parent_id to handle nested. send parent_id in action payload
  let payload = action.payload;
  let stateSegments = state.get("segments")
  let segments = [...stateSegments];
  let currentLevelSegments = segments.filter((item) => item.parent_id === parseInt(payload.parentId))
  console.log("action.payload", payload);


  const elmToMove = currentLevelSegments.splice(payload.sourceIdx, 1)[0];
  console.log('on drag end result elmToMove ==>', elmToMove);
  currentLevelSegments.splice(payload.destinationIdx, 0, elmToMove);
  // if(payload.callback){
  //   payload.callback(segments);
  // }

  let otherLevelSegments = _.difference(segments, currentLevelSegments); 
  let allSegments = _.union(currentLevelSegments, otherLevelSegments)
  console.log(" state after save", allSegments);
  return state.set("segments", allSegments)

}

function* rearrangeSegmentSaga(action){
  const currentUser = yield select(currentUserSelector());

  try{
    yield put(actions.rearrangeSuccess({
      ...action.payload
    }));
    // const msg = `Segments rearranged by ${
    //   currentUser.name
    // } (${upperFirst(currentUser.role)})`;
    // yield put(firestoreInteractionActions.setLogs({
    //   logging: true,
    //   log_type: "experience",
    //   msg: msg,
    //   itemId: action.payload.experienceId,
    //   experienceId: action.payload.experienceId
    // }))
    // const fullState = yield select(state => state);
    // console.log("fullState", fullState);
    const segments = yield select(state => state.segments.get("segments"));
    console.log("saga segments", segments,action);
    if(action.payload.callback){
      yield call(action.payload.callback, segments);
    }

  } catch(e) {
    yield put(actions.rearrangeFail({
      ...action.payload
    }));
  }

}


function* setParentsPointsSegmentSaga(action){

  console.log("setParentsPointsSegmentSaga action.payload", action.payload)
  try{

    console.log("setParentsPointsSegmentSaga action.payload", action.payload)

    let updatedSegment = action.payload.updatedSegment
    let updatedParentsPoints = updatedSegment.parents_points
    const topics = yield select(topicsSelector());
    // const activeTopicId = yield select(activeTopicIdSelector());
    // const topicSegments = yield select(segmentsSelector(activeTopicId));
    const allSegments = yield select(segmentsSelector());  //using allSegments because, topicSegments here only giving the main question data but we need the childrens also to update the parent points of immediate question
    const activeExperience = yield select(activeAdjustedExperienceSelector())
    // yield cannot be used inside loop - so we do this instead:
    yield all(updatedParentsPoints.map((parent) => {
      console.log("segment create callback  updatedParentsPoints parent", parent);
      let parentSegment, actionsToUse
      if(parent.segment_type === "section"){
        parentSegment = topics.find(t => t.id === parent.id)
        actionsToUse = topicActions
      }else{
        // to handle nested segments - points should bubble up
        parentSegment = allSegments.find(s => s.id === parent.id)
        actionsToUse = actions
      }
      // console.log("segment create callback  updatedParentsPoints parentSegment", topicSegments, parentSegment)
      if(parentSegment){
        return put(actionsToUse.showSuccess({data: {
          segment: {
            ...parentSegment, 
            points: parent.points, 
          }
        }}))
      }
    }))

    yield put(experienceReduxActions.showSuccess({data: {
      experience: {
        ...activeExperience, 
        points: updatedSegment.exp_points, 
        criterium_associations: updatedSegment.exp_criterium_associations, 
        questions_count: updatedSegment.exp_questions_count,
        attemptable_questions_count: updatedSegment.exp_attemptable_questions_count
      }
    }}))


    // yield put(actions.rearrangeSuccess({
    //   ...action.payload
    // }));
    // // const fullState = yield select(state => state);
    // // console.log("fullState", fullState);
    // const segments = yield select(state => state.segments.get("segments"));
    // console.log("saga segments", segments);
    // if(action.payload.callback){
    //   yield call(action.payload.callback, segments);
    // }

    
  } catch(e) {
    yield put(actions.parentsPointsFail({
      ...action.payload
    }));
  }

}

function setPinnedSegmentSuccess(state, action) {
  console.log("pinned segments success ==>", action, action.payload.pinnedSegments)
  const isPinned = action.payload.pinnedSegments.length ? true : false
  return state.set('pinnedSegments', action.payload.pinnedSegments).set('isPinned', isPinned)
}

function setActiveMediaIdSuccess(state, action){
  console.log("active media id", action.payload.activeMediaId)
  return state.set('activeMediaId',action.payload.activeMediaId)
}

function setLayoutSuccess(state, action) {
  return state.set('segmentLayout', action.payload.segmentLayout)
}

function setModeSuccess(state, action) {
  return state.set('segmentMode', action.payload.segmentMode)
}

function setActiveIdSuccess(state, action) {
  console.log("activeSegmentId action ==>", action)
  return state.set('activeSegmentId', action.payload.id)
}

function setActiveLogsHistoryForSegmentIdSuccess(state, action) {
  console.log("activeLogsHistoryForSegmentId action ==>", action)
  return state.set('activeLogsHistoryForSegmentId', action.payload.activeLogsHistoryForSegmentId)
}

function setActiveSegmentParentIdxsSuccess(state, action) {
  console.log("activeSegmentParentIdxs action ==>", action)
  return state.set('activeSegmentParentIdxs', action.payload.activeSegmentParentIdxs)
}

function setEditModeForSegmentIdSuccess(state, action) {
  console.log("setEditModeForSegmentIdSuccess action ==>", action, action.payload.editModeForSegmentId)
  return state.set('editModeForSegmentId', action.payload.editModeForSegmentId)
}

function setShowSegmentFormSuccess(state, action) {
  console.log("setShowSegmentFormSuccess action ==>", action, action.payload.showSegmentForm)
  return state.set('showSegmentForm', action.payload.showSegmentForm)
}
function setSpeechToTextLanguageSuccess(state, action) {
  setToLS(action.payload.speechToTextLanguage)
  return state.set('speechToTextLanguage', action.payload.speechToTextLanguage)
}

export const actions = reduxCrud.getActions();
export const actionTypes = reduxCrud.getActionTypes();
export const reducer = reduxCrud.getReducer(initialState,{
  REARRANGE_SEGMENT_SUCCESS: rearrangeSuccessFunc,
  PINNED_SEGMENT_SUCCESS: setPinnedSegmentSuccess,
  SET_LAYOUT_SEGMENT_SUCCESS: setLayoutSuccess,
  SET_MODE_SEGMENT_SUCCESS: setModeSuccess,
  SET_ACTIVE_MEDIA_ID_SEGMENT_SUCCESS: setActiveMediaIdSuccess,
  CREATE_SEGMENT_SUCCESS: successFunc, // we're doing this to accept the nested attributes coming from BE - sending the child_segment_attributes from the FE for ai creation of task/nested_question
  DUPLICATE_SEGMENT_SUCCESS: successFunc,
  SET_ACTIVE_ID_SEGMENT_SUCCESS: setActiveIdSuccess,
  SET_ACTIVE_LOGS_HISTORY_FOR_SEGMENT_ID_SEGMENT_SUCCESS: setActiveLogsHistoryForSegmentIdSuccess,
  SET_ACTIVE_SEGMENT_PARENT_IDXS_SEGMENT_SUCCESS: setActiveSegmentParentIdxsSuccess,
  SET_EDIT_MODE_FOR_SEGMENT_ID_SEGMENT_SUCCESS: setEditModeForSegmentIdSuccess,
  SET_SHOW_SEGMENT_FORM_SEGMENT_SUCCESS: setShowSegmentFormSuccess,
  SET_SPEECH_TO_TEXT_LANGUAGE_SEGMENT_SUCCESS: setSpeechToTextLanguageSuccess
});
export const watchSegments = reduxCrud.generateWatchSaga({
  REARRANGE_SEGMENT: rearrangeSegmentSaga,
  SET_PARENTS_POINTS_SEGMENT: setParentsPointsSegmentSaga,
});

export default reduxCrud;
